// Customizable Area Start
import React from "react";
import LeaseManagementIssueNewContractController, { Props, DataItem } from "./LeaseManagementIssueNewContractController.web";
import {
    Box,
    Breadcrumbs,
    Button,
    Chip,
    Divider,
    Grid,
    IconButton,
    Link,
    Paper,
    Popover,
    TextField,
    Typography,
    styled,
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { TemplateIcon } from "./assets";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import { withTranslation } from "react-i18next";
// Customizable Area End

// Customizable Area Start
type TFunction = (value: string) => string;
// Customizable Area End
class LeaseManagementIssueNewContract extends LeaseManagementIssueNewContractController {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area Start
    }

    // Customizable Area Start 
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { anchorEl, selectedId } = this.state;
        const open = Boolean(anchorEl);
        const id = open ? "simple-popover" : undefined;
        const { t }: { t: TFunction } = this.props;
        return (
            <MainScreen>
                <IssueNewContractContainer>
                    <Box>
                        <Breadcrumbs >
                            <Link color="inherit" href="/CompanyEmployee/LeaseManagment" className="notActiveBreadLink">
                                {t("Lease Management")}
                            </Link>
                            <Link
                                color="inherit"
                                href="/CompanyEmployee/IssueNewLease"
                                className="notActiveBreadLink"
                            >
                                {t("Issue New Lease")}
                            </Link>
                            <Typography color="textPrimary" className="activeBreadLink">
                            {t("Issue a New Contract")}
                            </Typography>
                        </Breadcrumbs>
                        <Typography className="issueNewcontractBreadHeading">
                        {t("Issue a New Contract")}
                        </Typography>
                    </Box>
                    <Paper elevation={1} className="issueNewContractContentContainer">
                        <Box className="issueNewContractHeaderContainer">
                            <Typography className="issueNewContractHeaderHeading">
                            {t("Select the template to be used for the new contract")}
                            </Typography>
                            <TextField
                                variant="outlined"
                                className="searchTextField"
                                InputProps={{
                                    startAdornment: (
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    ),
                                }}
                                placeholder="search by template name"
                            />
                        </Box>
                        <Divider />
                        <Box className="templateContainer">
                            <Grid container spacing={2}>
                                {this.state.selectedMarker.map(
                                    (item: DataItem, index: number) => (
                                        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                                            <Box className="singleTemplateContainer">
                                                <Box className="singleTemplateNameContainer">
                                                    <img
                                                        src={TemplateIcon}
                                                        alt=""
                                                        className="templateIcon"
                                                    />
                                                    <Typography className="templateName" data-testId={`templateName${index}`}>
                                                        Template 10.docx
                                                    </Typography>
                                                </Box>
                                                <Box className="templateMenuBtnContainer">
                                                    <Chip label="Default" />
                                                    <IconButton
                                                        data-testId={`templateMenubtn${index}`}
                                                        onClick={(event) => this.handleClick(event, item.id)}
                                                        className="dotIconBtn"
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Popover
                                                        open={open && selectedId === item.id}
                                                        id={id}
                                                        onClose={this.handleClose}
                                                        data-testId={`templateMenuContainer${index}`}
                                                        anchorEl={anchorEl}
                                                        anchorOrigin={{
                                                            horizontal: "left",
                                                            vertical: "bottom",
                                                        }}
                                                        transformOrigin={{
                                                            horizontal: "right",
                                                            vertical: "top",
                                                        }}
                                                    >
                                                        <PopoverContainer>
                                                            <Typography className="popoverText" data-testId={`templateMenuView${index}`}>
                                                            {t("View")}
                                                            </Typography>
                                                        </PopoverContainer>
                                                    </Popover>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        </Box>
                    </Paper>
                    <Box className="bottomButtonContainer">
                        <Button className="cancelBtn">CANCEL</Button>
                        <Button variant="contained" className="continueBtn">
                        {t("CONTINUE")}
                        </Button>
                    </Box>
                </IssueNewContractContainer>
            </MainScreen>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export default withTranslation()(LeaseManagementIssueNewContract);
export {LeaseManagementIssueNewContract}
const IssueNewContractContainer = styled(Box)({
    padding: "50px",
    backgroundColor: "#f4f7ff",

    "& .notActiveBreadLink": {
        color: "#40434c",
    },
    "& .activeBreadLink": {
        color: "#729be0",
    },
    "& .issueNewcontractBreadHeading": {
        color: "#40434c",
        fontWeight: 900,
        fontSize: "32px",
        margin: "10px 0px",
    },
    "& .issueNewContractContentContainer": {
        backgroundColor: "#fff",
        borderRadius: "10px",
        height: "63vh",
        margin: "20px 0px",
        overflow: "scroll",
    },
    "& .issueNewContractHeaderContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px",
        "@media(max-width:576px)": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
        },
    },
    "& .issueNewContractHeaderHeading": {
        width: "100%",
        fontSize: "20px",
        fontWeight: 900,
        color: "#40434c",
    },
    "& .searchTextField": {
        width: "370px",
        borderRadius: "10px !important",
        "@media(max-width:576px)": {
            width: "100%",
        },
    },
    "& .templateContainer": {
        margin: "20px 0px",
        padding: "20px",
    },
    "& .singleTemplateContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
        border: "1px solid #f3f3f3",
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
    },
    "& .singleTemplateNameContainer": {
        display: "flex",
        gap: "10px",
    },
    "& .templateIcon": {
        width: "20px",
    },
    "& .templateName": {
        color: "#242426",
        fontSize: "16px",
        fontWeight: 800,
    },

    "& .bottomButtonContainer": {
        display: "flex",
        justifyContent: "flex-end",
        gap: "30px",
        padding: "20px 0px",
    },
    "& .cancelBtn": {
        width: "200px",
        padding: "10px 30px",
        border: "1px solid #6686bb",
    },
    "& .continueBtn": {
        width: "200px",
        padding: "10px 30px",
        backgroundColor: "#2b6fec",
        color: "#fff",
        fontWeight: 900,
    },
    "& .templateMenuBtnContainer": {
        display: "flex",
        alignItems: "center",
    },
    "& .dotIconBtn":{
        padding:"10px 0px"
    }
});
const PopoverContainer = styled(Box)({
    width: "150px",
    "& .popoverText": {
        padding: "10px 20px ",
    },
});
// Customizable Area End