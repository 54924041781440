// Customizable Area Start
import React from "react";
import { Box, List, ListItem, ListItemText, ListItemIcon, Typography, Collapse } from "@material-ui/core";
import CECOOSidebarController, {
  Props,
} from "./CECOOSidebarController.web"

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { MyTeamImageComponent } from "./CMSidebarImages/CEMyTeamImage.web";
import { CommunityImageComponent } from "./CMSidebarImages/CECommunityImage.web";
import { DashboardImageComponent } from "./CMSidebarImages/DashboardImage.web";
import { PollImageComponent } from "./CMSidebarImages/PollImage.web";
import { LeaseImageComponent } from "./CMSidebarImages/CELeaseImage.web";
import { ManageSubImageComponent } from "./CMSidebarImages/ManageSubImage.web";
import { ClientTicketImageComponent } from "./CMSidebarImages/ClientTicketImage.web";
import { ComplexImageComponent } from "./CMSidebarImages/CEComplexImage.web";
import { DocumentImageComponent } from "./CMSidebarImages/CEDocumentsImage.web";
import { HelpImageComponent } from "./CMSidebarImages/ManageFAQImage.web";
import { NominationsImageComponent } from "./CMSidebarImages/NominationsImage.web";
import { ActivitiesImageComponent } from "./CMSidebarImages/ActivitiesImage.web";
import { Link } from 'react-router-dom';

export default class CECOOSidebar extends CECOOSidebarController {
  constructor(props: Props) {
    super(props);
  }

  checkColorCoo = (key:any) => {
    return this.getTabData(key) ? "CESingleLinkSize" : "blackCESingleLinkSize"
  }
  render() {

    const colorMap = {
      color: this.getColor1(this.getTabData('dashboard')),
      teamcolor: this.getColor1(this.getTabData('team')),
      Complexcolor1: this.getTabData('complex') ? "CESingleLinkSize" : "blackCESingleLinkSize",
      Communitycolor1: this.state.opencommunityManagement ? "CESingleLinkSize" : "blackCESingleLinkSize",
      documentcolor1: this.getTabData('document') ? "CESingleLinkSize" : "blackCESingleLinkSize",
      manageFAQcolor1: this.getTabData('faq') ? "CESingleLinkSize" : "blackCESingleLinkSize",
      Pollcolor: this.getTabData('realEstate')? "CESingleLinkSize" : "blackCESingleLinkSize",
      nominationscolor: this.checkColorCoo('nomination'),
      activitiescolor: this.getColor1(this.getTabData('activities')),
      leasecolor: this.checkColorCoo('LeaseManagment'),
      managesubcolor: this.getColor1(this.getTabData('subscription')),
      clientticketcolor: this.getColor1(this.state.openclientTicket),
      // ... Define other properties in a similar way
    };
    const getTypographyClassName = (selected: boolean) => `b ${selected ? 'selected' : ''}`;

    const getIconStyle = (paths: string[]) => ({ color: this.isActiveMenu(paths) ? '#2b6fed' : '' })
    
    const getTypography = (text: any, isOpen: any) => (
      <Typography
        style={{
          ...webStyle.dashboard_sidebar,
          color: isOpen ? '#2B6FED' : 'inherit',
        }}
        className="login-h1 bold-text"
      >
        {text}
      </Typography>
    );
    const dashboards = [
      ['General Dashboard', 'dashboard'],
      ['Actions Assigned to Me', "ActionAssignMe"],
      ['Actions Assigned to Team', "ActionAssignTeam"],
      ['Analysis Dashboard', "ComingSoon"],
      ['Approval Dashboard', "ComingSoon"],
      ['Granted Permissions', "ComingSoon"],
      ['Manage Jurisdiction', 'jurisdication', 'ViewJurisdiction']
    ];
    const communityItems = [
      ['User Profile', 'communityManagementProfile'],
      ['Request Management', 'RequestManagement',"TotalSentInvite"],

    ]
    const activityItems = [
      ['Incident Management', 'cooincidentListing', 'cooincident-details'],
      ['Poll/Survey', 'CEPoll', 'CEPollSurvey', 'CEPollSurveyList', 'CEPollSurveyDetails', 'CEPollSurveyReports'],
      ['Scheduled Meetings', "CEScheduleMeeting"],
      ['Meeting Minutes', "CEMeetingMinutes", "CEMeetingDetail"],
      ['Announcements', 'CompEmpAnnouncement']
    ];
    const documentItems = [
      ['Document', 'CompEmpDocument'],
      ['Report', 'ReportsStatistics','LoginReports','InvitationReport']
    ];
    return (
      <Box style={webStyle.container}>

        <List component="nav" disablePadding style={{ paddingLeft: '15px',marginTop:"25px" }} className="a">

          <ListItem className="a" button onClick={this.HandleDashboardClick} data-test-id="listItem" style={webStyle.ListSpacing}>
            <ListItemIcon>

              <DashboardImageComponent opendashboard={colorMap.color} />

            </ListItemIcon>
            <ListItemText primary={getTypography("My Dashboards", this.getTabData('dashboard'))} />
            <p style={webStyle.RightPadding1} className="b">{this.state.opendashboard ? <ExpandMore style={{ color: '#2b6fed' }} /> : <KeyboardArrowRightIcon style={getIconStyle(['dashboard', 'jurisdication', 'ViewJurisdiction'])} />}</p>
          </ListItem>
          <Collapse className="b" in={this.state.opendashboard} timeout="auto" unmountOnExit>
            <List style={{ paddingLeft: '55px' }} disablePadding>
              {dashboards.map((dashboard, index) => (
                <Link to={`/CompanyEmployee/${dashboard[1]}`} >
                  <ListItem

                    data-test-id="collapse"
                    button
                    onClick={() => this.HandleItemClick(index)}
                  >
                    <ListItemText primary={<Typography style={{ fontWeight: "bold",fontSize:'0.875rem' }} className={getTypographyClassName(this.isActiveMenu(dashboard))}>{dashboard[0]} </Typography>} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </Collapse>
          <Link to="/CompanyEmployee/CEMyTeam">     
          <ListItem className="a" button onClick={this.HandleteamMember} data-test-id="listItem" style={webStyle.ListSpacing}>
            <ListItemIcon>
              <MyTeamImageComponent openteammember={colorMap.teamcolor} />
            </ListItemIcon>
            <ListItemText primary={getTypography("Team Members", this.getTabData('team'))} />

          </ListItem>
          </Link>      
          <Link to="/CompanyEmployee/complexlisting">
            <ListItem className="a" button onClick={this.HandlecomplexBuilding} data-test-id="sidebar" style={webStyle.ListSpacing}>
              <ListItemIcon >
                <ComplexImageComponent opencomplexBuilding={colorMap.Complexcolor1} />
              </ListItemIcon>
              <ListItemText primary={getTypography("Complex/Building", this.getTabData('complex'))} />
            </ListItem>
          </Link>
          <Link to={'/CompanyEmployee/realestate-companies'}>
          <ListItem className="a"button onClick={this.HandlerealEstateCompany} data-test-id="realEstateCompanies" style={webStyle.ListSpacing}>
            <ListItemIcon>
              <PollImageComponent openrealEstateCompanies={colorMap.Pollcolor} />
            </ListItemIcon>
            <ListItemText primary={getTypography("Real Estate Companies", this.getTabData('realEstate'))} />
          </ListItem>
          </Link>
          <ListItem className="a"button onClick={this.Handlecommunity}
            data-test-id="communityManagement" style={webStyle.ListSpacing}>
            <ListItemIcon>
              <CommunityImageComponent opencommunityManagement={colorMap.Communitycolor1} />
            </ListItemIcon>
            <ListItemText primary={getTypography("Community Management", this.state.opencommunityManagement)} />
            <p style={webStyle.RightPadding1} className="b">{this.state.opencommunityManagement ? <ExpandMore style={{ color: '#2b6fed' }} /> : <KeyboardArrowRightIcon style={getIconStyle(["userProfile"])} />}</p>
          </ListItem>
          <Collapse className="b" in={this.state.opencommunityManagement} timeout="auto" unmountOnExit>
            <List style={{ paddingLeft: '55px' }} disablePadding>
              {communityItems.map((dashboard, index) => (
                <Link to={`/CompanyEmployee/${dashboard[1]}`} >
                <ListItem
                  data-test-id="collapse"
                  key={index}
                  onClick={() => this.HandleItemClick(index)}
                  button
                >
                  <ListItemText primary={<Typography style={{ fontWeight: 'bold',fontSize:'0.875rem'}} className={getTypographyClassName(this.isActiveMenu(dashboard))}>{dashboard[0]}</Typography>} />
                </ListItem>
                </Link>
              ))}
            </List>
          </Collapse>
          <ListItem className="a" button onClick={this.Handleactivities} data-test-id="activities" style={webStyle.ListSpacing}>
            <ListItemIcon>
              <ActivitiesImageComponent openactivities={colorMap.activitiescolor} />
            </ListItemIcon>
            <ListItemText primary={getTypography("Activities", this.getTabData('activities'))} />
            <p style={webStyle.RightPadding1} className="b">{this.state.openactivities ? <ExpandMore style={{ color: '#2b6fed' }} /> : <KeyboardArrowRightIcon style={getIconStyle(['cooincidentListing', 'cooincident-details', 'CompEmpAnnouncement', 'CEPoll', 'CEPollSurvey', 'CEPollSurveyList', 'CEPollSurveyDetails', 'CEPollSurveyReports'])} />}</p>
          </ListItem>
          <Collapse className="b" in={this.state.openactivities} timeout="auto" unmountOnExit>
            <List style={{ paddingLeft: '55px' }} disablePadding>
              {activityItems.map((dashboard, index) => (
                <Link to={`/CompanyEmployee/${dashboard[1]}`} >
                  <ListItem
                    data-test-id="collapse"
                    button
                    onClick={() => this.HandleItemClick(index)}
                  >
                    <ListItemText primary={<Typography style={{ fontWeight: "bold",fontSize:'0.875rem' }} className={getTypographyClassName(this.isActiveMenu(dashboard))}>{dashboard[0]} </Typography>} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </Collapse>
          <ListItem className="a" button onClick={this.HandledocumentReport} data-test-id="documents" style={webStyle.ListSpacing}>
            <ListItemIcon>
              <DocumentImageComponent opendocuments={colorMap.documentcolor1} />
            </ListItemIcon>
            <ListItemText primary={getTypography("Documents & Reports", this.getTabData('document'))} />
            <p style={webStyle.RightPadding1} className="b">{this.state.opendocuments ? <ExpandMore style={{ color: '#2b6fed' }} /> : <KeyboardArrowRightIcon style={getIconStyle(['CompEmpDocument'])} />}</p>
          </ListItem>
          <Collapse className="b" in={this.state.opendocuments} timeout="auto" unmountOnExit>
            <List style={{ paddingLeft: '55px' }} disablePadding>
              {documentItems.map((dashboard, index) => (
                <Link to={`/CompanyEmployee/${dashboard[1]}`} >
                  <ListItem
                    key={index}
                    data-test-id="collapse"
                    onClick={() => this.HandleItemClick(index)}
                    button
                  >
                    <ListItemText primary={<Typography style={{ fontWeight: 'bold',fontSize:'0.875rem' }} className={getTypographyClassName(this.isActiveMenu(dashboard))}>{dashboard[0]}</Typography>} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </Collapse>
          <Link to="/CompanyEmployee/CompEmpFaq">
            <ListItem className="a" button onClick={this.HandlemanageFAQ} data-test-id="manageFAQ" style={webStyle.ListSpacing}>
              <ListItemIcon>
                <HelpImageComponent openmanageFAQ={colorMap.manageFAQcolor1} />
              </ListItemIcon>
              <ListItemText primary={getTypography("Manage FAQ", this.getTabData('faq'))} />
            </ListItem>
          </Link>
          <Link to="/CompanyEmployee/CENomination">
            <ListItem className="a" button onClick={this.Handlenominations} data-test-id="nominations" style={webStyle.ListSpacing}>
              <ListItemIcon>
                <NominationsImageComponent opennominations={colorMap.nominationscolor} />
              </ListItemIcon>
              <ListItemText primary={getTypography("Nominations", this.state.opennominations)} />
            </ListItem>
          </Link>
          <Link to={"/CompanyEmployee/LeaseManagment"}>
          <ListItem className="a" button onClick={this.Handlelease} data-test-id="leasemanagement" style={webStyle.ListSpacing}>
            <ListItemIcon>
              <LeaseImageComponent openleasemanagement={colorMap.leasecolor} />
            </ListItemIcon>
            <ListItemText primary={getTypography("Lease Management", this.getTabData("LeaseManagment"))} />
          </ListItem>
          </Link>
          <Link to={"/CompanyEmployee/SubsciptionListing"}>
          <ListItem className="a" button onClick={this.Handlemanagesub} data-test-id="manageSub" style={webStyle.ListSpacing}>
            <ListItemIcon>
              <ManageSubImageComponent openmanageSub={colorMap.managesubcolor} />
            </ListItemIcon>
            <ListItemText primary={getTypography("Manage Subscriptions", this.getTabData('subscription'))} />
          </ListItem>
          </Link>
          <Link to={"/CompanyEmployee/ClientTicket"}>
          <ListItem className="a" button onClick={this.Handleclient} style={webStyle.ListSpacing}>
            <ListItemIcon>
              <ClientTicketImageComponent openclientTicket={colorMap.clientticketcolor} />
            </ListItemIcon>
            <ListItemText primary={getTypography("Client Tickets", this.state.openclientTicket)} />
          </ListItem>
          </Link>
        </List>
      </Box>

    );
  }
}

const webStyle = {
  container: {
    width: "100%", height: "100%",
    overflow: 'hidden',
    backgroundColor:"#fcfcfc",
  },
  dashboard_sidebar: {
    fontSize: "16px",
  },

  ListSpacing: {
    marginBottom: '10px'
  },
  RightPadding1: {
    paddingRight: '25px'
  }
};
// Customizable Area End


