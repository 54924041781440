//Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    InputLabel,
    FormControl,
    Select,
    Button,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableHead,
    Divider,
    TableRow,
    IconButton,
    TextField,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { CSSProperties } from '@material-ui/styles';
import { withRouter } from "react-router";
import Loader from "../../../../components/src/Loader.web";
import {Link} from 'react-router-dom'
import PaginationModule from "../../../StoreCredits/src/PaginationModule.web";
import RealEstateCompaniesTableController, {
    Props,
} from './RealEstateCompaniesTableController.web'
import { statusMapping } from "../../../../components/src/RealEstateFilter/index";
//@ts-ignore
import {RealEstateFilter} from '../../../../components/src/RealEstateFilter/index'
import {withTranslation} from "react-i18next";

class RealEstateCompaniesTable extends RealEstateCompaniesTableController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        //@ts-ignore
        const {t} = this.props

        const { anchorEl } = this.state;
        const openPopover = Boolean(anchorEl);

        return (
            <>
            <Box style={webStyle.companyBox}>
                <Box>
                <Typography style={{ fontSize: '18px' }}> {t("Real Estate Companies")}</Typography>
                </Box>
                <Box style={webStyle.titleBox}>
                    <Box>
                <Typography style={webStyle.bigTitle} className="bold-text">{t("Real Estate Companies")}</Typography>
                    </Box>
                    <Box>
                        <Button variant="contained" color="primary" style={webStyle.registerBtn} onClick={() => this.props.history.push("/CompanyEmployee/realestate-register-company")}><span className="bold-text">
                            {t("REGISTER NEW COMPANY")}
                        </span></Button>
                    </Box>
            </Box>
            <Box style={webStyle.selectBoxes} className="estate_table_select_boxes">
                <Box style={webStyle.selectBoxLeft} className="estate_table_select_boxes_left">
                <Box className={"top-bar-filter ChairmanInvoices"} id="ChairmanInvoices">
                {
                    this.state.accountType === "coo" ?  <RealEstateFilter t={t} classComponent={this} filterList="coo_real_estate_filter" /> :  <RealEstateFilter  classComponent={this} filterList="other_real_estate_filter" t={t} />
                }
                <Box className="filter-filter">

                <Button className="handleSearchFilterForInvoices search-btn-estate" onClick={this.handleFilterSubmit} startIcon={<SearchIcon style={{marginLeft:"10px"}} />}>{t("Search")}</Button>
                
                </Box>
                </Box>
                </Box>
            </Box>
            <Box style={webStyle.tableContainer} className="estate_table_select_boxes_container">
                <Box style={webStyle.tableHeader} className="estate_table_select_boxes_header">
                    <h2 style={webStyle.tableHeading} className="bold-text">
                        {t("Real Estate Companies")}</h2>
                    <TextField
                        label="Search"
                        variant="outlined"
                        className="table_search"
                        style={webStyle.searchInput}
                        placeholder={`${t("Search by Company ID or Name")}`}
                        onKeyUp={this.handleSearchKeyUp}
                        data-testid="searchquery"
                        InputProps={{
                            startAdornment: (
                                <IconButton 
                                style={webStyle.searchIconButton}>
                                    <SearchIcon />
                                </IconButton>
                            ),
                            style: webStyle.innerInput
                        }}
                    />
                </Box>
            
                        {
                          this.state.companies.length ?<Table className="table-box">
                          <TableHead>
                                  <TableRow>
                                    <TableCell className="bold-text">
                                      {t("Company ID")}</TableCell>
                                    <TableCell className="bold-text">{t("Company Name")}</TableCell>
                                    {
                                        this.state.accountType === "coo" ? <React.Fragment data-testid="cooDetails">
                                        <TableCell className="bold-text">{t("Sales Manager")}</TableCell>
                                    <TableCell className="bold-text">{t("Account Manager")}</TableCell>
                                        </React.Fragment> : <TableCell className="bold-text">{t("Managed By")}</TableCell>
                                    }
                                    <TableCell className="bold-text">{t("Country")}</TableCell>
                                    <TableCell className="bold-text">{t("Region")}</TableCell>
                                    <TableCell className="bold-text">{t("City")}</TableCell>
                                    <TableCell className="bold-text">{t("Status")}</TableCell>
                                    <TableCell className="bold-text"></TableCell>
                                  </TableRow>
                                </TableHead>
                          <TableBody>{this.state.companies.map((company:any, i:number) => {
                            return <TableRow key={company.id}>
                              <TableCell>{company.id}</TableCell>
                              <TableCell>{company.attributes.company_name}</TableCell>
                              {
                                        this.state.accountType === "coo" ? <><TableCell style={{textTransform: 'capitalize'}}>{company.attributes.sales_manager || '-'}</TableCell>
                                        <TableCell style={{textTransform: 'capitalize'}}>{company.attributes.account_manager || '-'}</TableCell></> : <TableCell style={{textTransform: 'capitalize'}}>{company.attributes.managed_by}</TableCell>
                                    }
                              <TableCell className="text_capital">{company.attributes.country}</TableCell>
                              <TableCell className="text_capital">{company.attributes.region}</TableCell>
                              <TableCell className="text_capital">{company.attributes.city}</TableCell>
                              <TableCell>
                                {
                                    company?.attributes?.status ? <span style={{
                                        ...webStyle.companyTag,
                                        ...webStyle[company?.attributes?.status],
                                    }} className="bold-text">{statusMapping[company?.attributes?.status]}</span> : 
                                    '--'
                                }
                                 </TableCell>
                                 <TableCell style={webStyle.moreOptions}><MoreVertIcon style={webStyle.moreIcon} data-testid="clickpopover" onClick={(e) => this.handlePopover(e, company.id)}/>
                                 <Popover
                    itemID="handleCloseID"
                      id="custom-menu"
                      open={openPopover}
                      anchorEl={this.state.anchorEl}
                      onClose={this.handleClosePopover}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      className="real_estate_popover"
                    >
                      <ul style={webStyle.rowList}>
                                  <li style={webStyle.option1}><Link to={`/CompanyEmployee/realestate-company-details/${this.state.activeId}`} style={webStyle.linkOption}>{t("View Details")}</Link></li>
                                  <li><Link to={`/CompanyEmployee/realestate-edit-company/${this.state.activeId}`} style={webStyle.linkOption}>{t("Edit Details")}</Link></li>
                                </ul>
                    </Popover>
                              </TableCell>
                            </TableRow>
                          })}</TableBody>
                          </Table> : <Box style={webStyle.noRecord}>
                            <Typography variant="body1">
                            {t("No Records Found")}
                            </Typography>
                            </Box>
                        }
                    <Divider />
                    <Box style={webStyle.tableFooter}>
                    <PaginationModule pagination={this.state.pagination} handlePagination={this.handleVistorPagination} page={this.state.page} />
                    </Box>
            </Box>
            </Box>
            <Loader loading={this.state.loading} />
            </>
        );
    }
}


const webStyle:any = {
    searchIcon:{
        fontSize:'14px'
    },
  selectFormControl:{
    width:'15%'
},
selectBigFormControl:{
    width:'20%'
},
    titleBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'flex-end'
    },
    companyBox:{
        padding:'30px',
    },
    smallTitle:{ fontSize: '18px' },
    bigTitle:{ fontSize: '32px', fontWeight:'700' as CSSProperties['fontWeight'] },
    registerBtn:{
        background:'#FC8434',
        borderColor:'#FC8434',
        boxShadow:'unset',
        borderRadius:'8px',
        fontWeight:'700' as CSSProperties['fontWeight'],
        height:'40px'
    },
    currentRecords:{
        color:'#FC8434'
    },
    selectBigForm:{
        minWidth:'20% ',
        color:'#181d25',
        border:'1px solid #f0f0f0'
    },
    noRecord:{
      padding:'25px 16px',
      display:'flex',
      textAlign:'center' as CSSProperties['textAlign']
    },
    moreIcon:{
        color:'rgba(136,136,136,0.6)',
        cursor:'pointer'
    },
    selectForm:{
        minWidth:'15%',
        color:'#181d25',
        border:'1px solid #f0f0f0'
    },
    linkOption:{
        fontSize:'14px'
    },
    companyTag:{
        background:'rgb(30,198,91,0.12)',
        color:'#1EC65B',
        textAlign:'center' as CSSProperties['textAlign'],
        padding:'5px 10px',
        borderRadius: '25px',
        fontSize:'0.875rem',
        whiteSpace: 'nowrap'
    },
    approved: {
        background:'rgb(30,198,91,0.2)',
        color:'rgb(30 198 91)'
    },
    rejected: {
        background: 'rgb(242,23,23, 0.2)',
        color: '#F21717',
    },
    pending_approval:{
        background: 'rgb(252, 132, 52, 0.2)',
        color: 'rgb(252, 132, 52)',
    },
    moreOptions:{
      position: 'relative' as CSSProperties['position']
    },
    rowOptions:{
      position:'absolute' as CSSProperties['position'],
      background:'#fff',
      width:'160px',
      left:'-130px',
      boxShadow: '0px 0px 6px rgba(87,148,201,0.18)',
      zIndex:'9999999' as CSSProperties['zIndex'],
      padding:'15px'
    },
    option1:{
      marginBottom:'15px' as CSSProperties['marginBottom']
    },
    rowList:{
      listStyle:'none'
    },
    selectBoxLeft:{
        width: '100%',
        display:'flex',
        flexWrap:'wrap'
    },
    selectBoxRight:{
        width: '25%',
        display:'flex',
        justifyContent:'flex-end',
    },
    selectBoxes:{
        marginTop:'30px',
        marginBottom:'15px',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    searchBtn:{
        marginTop:'5px',
        marginLeft:'15px',
        height: '35px',
        background:'#2B6FED',
        borderColor:'#2B6FED',
        fontSize:'14px',
        boxShadow:'unset',
        minHeight:'35px'
    },
    searchText:{
        marginLeft:'10px'
    },
    tableContainer:{
        background:'#fff'
    },
    tableHeader:{
        padding:'30px 16px',
        borderBottom:'1px solid rgba(0,0,0,0.1)',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    tableFooter:{
        padding:'30px 0px',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    tableHeading:{
        fontSize:'22px'
    },
    searchInput:{
        border:'1px solid rgba(0,0,0,0.2)',
        borderRadius:'8px',
        height:'40px',
        padding:'0px',
        color:'#212121'
    },
    searchIconButton:{
      padding: '0px'
    },
    innerInput:{
        padding:'0px',
        color:'#212121'
    }
};

export default withTranslation()(withRouter(RealEstateCompaniesTable))
//Customizable Area End


