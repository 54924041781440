import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { ApiCatchErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";


export interface Props {
    stepComplex: number,
    handleNext:any,
    handleBack:any,
    findPersonPopup: any,
    findPopupOpen: any,
    imgUplodeAvtar:any,
    previewImagesBuilding: any,
    handleClickOpenBuilding:any,
    handleRemoveBuildingDetails:any,
    handleCountryList:any,
    savedBuildings:any,
    countriesList:any,
    imagesBuilding:any,
    logoBuildingError:boolean,
    imagesBuildingError: boolean,
    handleBuildingDetails:any,
    buildingValues: any,
    deleteBuildingPopup:any,
    handleOpenDeleteBuildingPopup:any,
    handleCloseDeleteBuildingPopup:any,
    deleteBuildingIndex:any,
    t?: any
    i18n?: any
    match?: any
    location?: any
    // Customizable Area Start
    history?: any
    language?:any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CompEmpComplexBlockStep5Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
    exampleAPICallId: string = "";

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

        this.state = {
            // Customizable Area Start
            loading: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    //   }

    async componentDidMount(): Promise<void> {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
}
