import React from "react";
// Customizable Area Start
import {

    Box,
    Grid,
    MenuItem,
    Typography,
    Select,
    InputAdornment,
    Button,
    TableHead,
    Table,
    TableCell,
    TableRow,
    TableBody,
    Divider,
    TextField,
    styled,
    Link,
    IconButton,
    Paper,
    TableContainer,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import {logo} from "./assets";
import { withRouter } from "react-router-dom";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";
import SearchIcon from '@material-ui/icons/Search';
import Pagination from "@material-ui/lab/Pagination";
import RoomIcon from "@material-ui/icons/Room";
import ListIcon from "@material-ui/icons/List";
// @ts-ignore
import GoogleMapReact from "google-map-react";
// Customizable Area End

import OwnersReportsDetailsController, {
    Props,
} from "./OwnersReportsDetailsController.web";

// Customizable Area Start
export class OwnersReportsDetails extends OwnersReportsDetailsController {
    constructor(props: Props) {
        super(props);
    }

    renderReportData = () => {
        const BoxView = Box as any
        const { t, i18n }: any = this.props;
        const language = i18n.language;
        return (
            <BoxView>
                <BoxView style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant='h4' className="bold-text">{this.state.owenrReportData?.attributes?.full_name}</Typography>
                    <Typography className="bold-text downloadButton" onClick={() => this.handleDownloadReport()} data-test-id="downloadButtonId1">DOWNLOAD REPORT</Typography>
                </BoxView>
                <BoxView className="main">
                    <BoxView className="heading">
                        <img src={logo} />
                        <Typography style={{ fontWeight: 900 }}>Report Generated on 18 June 2023 9:00 AM By Peter Parker</Typography>
                    </BoxView>
                    <div className="detail">
                        <Typography variant="h5" className="bold-text">{this.state.owenrReportData?.attributes?.full_name} {t("Ownership Report")}</Typography>
                    </div>
                    <Divider />
                    <BoxView className="table-Data">
                        <TableContainer component={Paper} style={{ boxShadow: "none" }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align={languageCondition(language, "right", "left")}>#</TableCell>
                                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                                            {t("Complex Name")}
                                        </TableCell>
                                        <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                                            {t("Bulidings")}
                                        </TableCell>
                                        <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                                            {t("Unit Number")}
                                        </TableCell>
                                        <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                                            {t("Lease Contract")}
                                        </TableCell>
                                        <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                                            {t("Managed by PM")}
                                        </TableCell>
                                        <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                                            {t("Status")}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {this.state.ownerReportDetails?.map((ownerReportDetails:{ attributes: { complex_name: string; building_name: string; unit_list: { apartment_name: string; }[]; lease_contract: string; managed_by_Pm: string, status: {} | null | undefined; }; }, index: number) => (
                                        <TableRow>
                                            <TableCell align={languageCondition(language, "right", "left")}>{index + 1}</TableCell>
                                            <TableCell align={languageCondition(language, "right", "left")}>{ownerReportDetails.attributes.complex_name}</TableCell>
                                            <TableCell align={languageCondition(language, "right", "left")} className="ellipse-one">
                                                {ownerReportDetails.attributes.building_name}
                                            </TableCell>
                                            <TableCell align={languageCondition(language, "right", "left")}>
                                                {ownerReportDetails.attributes.unit_list.map((item: {apartment_name:string}) => item.apartment_name).join(', ')}
                                            </TableCell>
                                            <TableCell align={languageCondition(language, "right", "left")}>
                                                {ownerReportDetails.attributes.lease_contract}
                                            </TableCell>
                                            <TableCell align={languageCondition(language, "right", "left")}>
                                                {ownerReportDetails.attributes.managed_by_Pm}
                                            </TableCell>
                                            <TableCell align={languageCondition(language, "right", "left")}>
                                                <Button
                                                    style={{
                                                        background: ownerReportDetails.attributes.status === "Empty" ? "#E4F8EB" : "#FEF0E6",
                                                        color: ownerReportDetails.attributes.status === "Empty" ? "#22C75E" : "#FC8E44"
                                                    }}
                                                    className="statusBtn bold-text"
                                                >{ownerReportDetails.attributes.status}</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <BoxView className="total-page">
                            <Typography>TENANT INTERNATIONAL</Typography>
                            <Typography>Page 1 of 1</Typography>
                        </BoxView>
                    </BoxView>
                </BoxView>
            </BoxView>
        )
    }

// Customizable Area End

    render() {
        // Customizable Area Start
        const BoxView = Box as any
        const { t, i18n }: any = this.props;
        const language = i18n.language;
        // Customizable Area End
            return (
                // Customizable Area Start
                <>
                    <CEHeaderComponentWeb>
                        <OwnerReports>
                            <BoxView className="containerBox">
                                <BoxView className="navigation">
                                    <Typography variant="body1" className="">
                                        <Link href="/CompanyEmployee/dashboard">
                                            {t("Documents & Reports")} /
                                        </Link>{" "}
                                        <Link href="/CompanyEmployee/ReportsStatistics">
                                        {t("Reports")} /
                                        </Link>{" "}
                                        <Link href="/CompanyEmployee/OwnersReports">{t("Owners' Reports")}</Link> /{" "}
                                        <BoxView component="span" style={{ color: "rgb(43, 111, 237)" }}>
                                            {this.state.owenrReportData?.attributes?.full_name}
                                        </BoxView>
                                    </Typography>
                                </BoxView>
                                <br />

                                {!this.state.downloadReport && <BoxView >
                                    <BoxView style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography variant='h4' className="bold-text">{this.state.owenrReportData?.attributes?.full_name}</Typography>
                                        <Typography className="bold-text downloadButton" onClick={() => this.handleDownload()} data-test-id="downloadButtonId">DOWNLOAD REPORT</Typography>
                                    </BoxView>
                                    <Grid container xs={12} spacing={2} style={{ marginTop: "25px" }}>
                                        <Grid item>
                                            <BoxView>
                                                <Select
                                                    displayEmpty
                                                    disableUnderline
                                                    className="selectmenu"
                                                    data-test-id="selectCountryId"
                                                    placeholder="Select Country"
                                                    value={this.state.country}
                                                    onChange={(event) => this.handleCountryChange(event)}
                                                    id="select-Country"
                                                >
                                                    <MenuItem
                                                        id="selectStatusBudgetReport"
                                                        disabled
                                                        value=""
                                                    >
                                                        {t("Select Country")}
                                                    </MenuItem>
                                                    {this.state.selectCountry?.length > 0 &&
                                                        this.state.selectCountry?.map((item: string) => {
                                                            return (
                                                                <MenuItem key={item} value={item}>
                                                                    {item}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    <MenuItem
                                                        value="All"
                                                        id="selectStatusBudgetReportAll"
                                                    >
                                                        {t("All")}
                                                    </MenuItem>
                                                </Select>
                                            </BoxView>
                                        </Grid>
                                        <Grid item>
                                            <Select displayEmpty
                                                value={this.state.city}
                                                data-test-id="selectCityId"
                                                className="selectmenu"
                                                disableUnderline
                                                onChange={(event) => this.handleCityChange(event)}
                                                id="select-city1"
                                            >
                                                <MenuItem
                                                    id="selectStatusBudgetReport"
                                                    disabled
                                                    value=""
                                                >
                                                    {t("Select City")}
                                                </MenuItem>
                                                {this.state.selectCity?.length > 0 &&
                                                    this.state.selectCity?.map((item: string) => {
                                                        return (
                                                            <MenuItem key={item} value={item}>
                                                                {item}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </Grid>
                                        <Grid item>
                                            <Select
                                                className="selectmenu"
                                                displayEmpty
                                                value={this.state.individualbuilding}
                                                disableUnderline
                                                data-test-id="buildingsdropId"
                                                onChange={(event) => this.handleIndividualChange(event)}
                                                id="select-city1"
                                            >
                                                <MenuItem disabled value="" id="selectStatusBudgetReport">
                                                    {t("Complex/Individual Buildings")}
                                                </MenuItem>
                                                <MenuItem value="Complex" id="selectStatusBudgetReport">
                                                    {t("Complex")}
                                                </MenuItem>
                                                <MenuItem value="Individualbuilding" id="selectStatusBudgetReportAll">
                                                    {t("Individual building")}
                                                </MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item>
                                            <Select displayEmpty
                                                disableUnderline
                                                value={this.state.complex}
                                                onChange={(event) => this.handleComplexChange(event)}
                                                id="select-city1"
                                                data-test-id="selectComplexId"
                                                className="selectmenu"
                                            >
                                                <MenuItem
                                                    value=""
                                                    id="selectStatusBudgetReport"
                                                    disabled
                                                >
                                                    {t("Select Complex")}
                                                </MenuItem>
                                                {this.state.selectComplex?.length > 0 &&
                                                    this.state.selectComplex?.map((item: any) => {
                                                        return (
                                                            <MenuItem key={item} value={item.id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </Grid>
                                        <Grid item>
                                            <Select displayEmpty
                                                disableUnderline
                                                id="selectBuilding"
                                                value={this.state.building}
                                                className="selectmenu"
                                                data-test-id="slctBuildingId"
                                                onChange={(event) => this.handleBuildingChange(event)}
                                            >
                                                <MenuItem
                                                    id="selectStatusBudgetReport"
                                                    value=""
                                                    disabled
                                                >
                                                    {t("Select Building")}
                                                </MenuItem>
                                                {this.state.selectBuilding?.length > 0 &&
                                                    this.state.selectBuilding?.map((item: any) => {
                                                        return (
                                                            <MenuItem key={item} value={item.id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </Grid>

                                        <Grid item>
                                            <Button
                                                className="mybtn"
                                                variant="contained"
                                                onClick={() => this.ownerReportDetailsApi()}
                                                data-test-id="saveButton"
                                            >
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                                {t("Search")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid className="table-box-card">
                                        <Grid item sm={12} md={12} xs={12}>
                                            <BoxView className="table-top">
                                                <BoxView className="table-main-heading">
                                                    <BoxView className="map-list-icon-box">
                                                        <ListIcon className={this.state.isListVisible ? "map-list-active" : "map-list-inactive"} data-test-id="map-list-inactive" onClick={this.handleVisibleList} />
                                                        <RoomIcon className={this.state.isListVisible ? "map-list-inactive" : "map-list-active"} data-test-id="map-list-active" onClick={this.handleVisibleMap} />
                                                    </BoxView>
                                                    <h4 className="bold-text">{this.state.owenrReportData?.attributes?.full_name} {t("Ownership Report")}</h4>
                                                </BoxView>
                                                <BoxView className="filter-head-box" style={{ margin: "0" }}>
                                                    <BoxView className="sort-by">
                                                        <TextField
                                                            variant="outlined"
                                                            placeholder={t("Search by complex/building name")}
                                                            data-test-id="filterId"
                                                            className="searchButtons"
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <SearchIcon style={{ color: "gray" }} />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </BoxView>
                                                </BoxView>
                                            </BoxView>
                                            <Divider />
                                            {this.state.isListVisible ? (
                                                <>
                                                <TableContainer component={Paper} style={{boxShadow:"none"}}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell align={languageCondition(language, "right", "left")}>#</TableCell>
                                                                <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                                                                    {t("Complex Name")}
                                                                </TableCell>
                                                                <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                                                                    {t("Bulidings")}
                                                                </TableCell>
                                                                <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                                                                    {t("Unit Number")}
                                                                </TableCell>
                                                                <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                                                                    {t("Lease Contract")}
                                                                </TableCell>
                                                                <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                                                                    {t("Managed by PM")}
                                                                </TableCell>
                                                                <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                                                                    {t("Status")}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>

                                                            {this.state.ownerReportDetails?.map((ownerReport: { attributes: { complex_name: string; building_name: string; unit_list: { apartment_name: string; }[]; lease_contract: string; managed_by_Pm: string, status: {} | null | undefined; }; }, index: number) => (
                                                                <TableRow>
                                                                    <TableCell
                                                                        align={languageCondition(language, "right", "left")}
                                                                    >
                                                                        {index + 1}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align={languageCondition(language, "right", "left")}
                                                                    >
                                                                        {ownerReport.attributes.complex_name}
                                                                    </TableCell>
                                                                    <TableCell align={languageCondition(language, "right", "left")} className="ellipse-one">
                                                                        {ownerReport.attributes.building_name}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align={languageCondition(language, "right", "left")}
                                                                    >
                                                                        {ownerReport.attributes.unit_list.map((item: { apartment_name: string }) => item.apartment_name).join(', ')}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align={languageCondition(language, "right", "left")}
                                                                    >
                                                                        {ownerReport.attributes.lease_contract}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align={languageCondition(language, "right", "left")}
                                                                    >
                                                                        {ownerReport.attributes.managed_by_Pm}
                                                                    </TableCell>
                                                                    <TableCell className="bold-text"
                                                                        align={languageCondition(language, "right", "left")}
                                                                    >
                                                                        <Button
                                                                            style={{
                                                                                background: ownerReport.attributes.status === "Empty" ? "#E4F8EB" : "#FEF0E6",
                                                                                color: ownerReport.attributes.status === "Empty" ? "#22C75E" : "#FC8E44"
                                                                            }}
                                                                            className="statusBtn"
                                                                        >{ownerReport.attributes.status}</Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}

                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                    <Divider />
                                                    <BoxView className="table-bottom">
                                                    <p>
                                                    {t("Showing")} <span className="current-page">1</span> {t("of")}{" "}
                                                    <span className="total-page">1</span>{" "}
                                                    {t("results")}
                                                </p>

                                                        <Pagination
                                                            page={1}
                                                            siblingCount={2}
                                                            count={1}
                                                            variant="outlined"
                                                            shape="rounded"
                                                        />
                                                    </BoxView>
                                                </>
                                            ) : (
                                                <BoxView className="report-map-box">
                                                    <GoogleMapReact
                                                        key={new Date().getTime()}
                                                        defaultCenter={{
                                                            lat: 12,
                                                            lng: 12,
                                                        }}
                                                        defaultZoom={10}
                                                    >
                                                        {this.state.ownerReportDetails.map((unit: any, index: number) => {
                                                            return (
                                                                <>
                                                                    <IconButton>
                                                                        <RoomIcon style={{ color: "#DD946A" }} />
                                                                    </IconButton>
                                                                    <BoxView
                                                                        style={{
                                                                            width: "200px",
                                                                            background: "white",
                                                                            textAlign: "center",
                                                                            borderRadius: "8px",
                                                                            padding: "8px",
                                                                            fontSize: "14px",
                                                                        }}
                                                                    >
                                                                        <p>{unit.attributes.building_name}</p>
                                                                        <p>{unit.attributes.owner_name}</p>
                                                                        {unit.attributes.unit_list.map((data: { apartment_name: string }) => {
                                                                            return <p style={{ fontWeight: "bold" }}>Unit {data.apartment_name}</p>;
                                                                        })}
                                                                    </BoxView>
                                                                </>
                                                            );
                                                        })}
                                                    </GoogleMapReact>
                                                </BoxView>
                                            )}

                                        </Grid>
                                    </Grid>
                                </BoxView>}
                                {this.state.downloadReport && this.renderReportData()}
                            </BoxView>
                        </OwnerReports>
                    </CEHeaderComponentWeb>
                </>
                // Customizable Area End
            );
        }
}

// Customizable Area Start
const OwnerReports = styled(Box)({
    '& .containerBox': {
        padding: "0px 40px"
    },
    '& .subcontainer': {
        padding: '0px 40px',
    },
    '& .selectmenu': {
        border: '1px solid #F0F0F0',
        padding: '7px',
        background: '#fff',
        borderRadius: '15px',
        borderBottom: 'none',
        width: '100%',
        gap: '10px',
        marginTop: '10px',
    },
    '& .titleBox': {
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
    },
    '& .ButtonBox': {
        justifyContent: 'flex-end',
        display: 'flex',
        padding: 20,
        gap: 15,
    },
    '& .cancleButton': {
        border: '1px solid #2B6FED',
        padding: '5px 40px',
        fontWeight: 900,
        color: '#2B6FED',
    },
    '& .confirmButton': {
        background: '#2B6FED',
        padding: '5px 40px',
        fontWeight: 900,
        color: '#fff',
    },
    '& .inputType': {
        padding: '7px',
        border: '1px solid darkgray',
        background: '#fff',
        borderRadius: '15px',
        width: '100%',
        gap: '10px',
        marginTop: '10px',
    },
    '& .mybtn': {
        color: 'white',
        textTransform: 'capitalize',
        backgroundColor: '#2D6EED',
        marginTop: '11px',
        padding: '8px 16px',
    },
    '& .Box': {
        borderRadius: '10px',
        height: '200px',
        flexDirection: 'column',
        backgroundColor: '#fff',
        paddingLeft: '30px',
        display: 'flex',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',
        marginTop: '25px',
    },
    '& .lable': {
        borderRadius: '20px',
        padding: '5px 15px',
        color: '#EBA370',
        backgroundColor: 'rgba(252, 132, 52, 0.1)',
        fontWeight: 700,
        width: 'fit-content',
        marginTop: '5px',
    },
    '& .searchButtons': {
        borderRadius: '5px',
        background: 'white',
        border: '1px solid #f0f0f0',
        marginLeft: "20px"
    },
    '& .main': {
        height: '85%',
        backgroundColor: '#fff',
        padding: '15px',
        marginTop:"20px"
    },
    '& .heading': {
        display: 'flex',
        justifyContent: 'space-between',
    },
    '& .detail': {
        backgroundColor: 'rgba(221, 221, 221, 0.38)',
        marginTop: '35px',
        textAlign: 'center',
        color: '#191E26',
        borderRadius: '8px',
        padding: '10px 50px',
    },
    '& .table-Data': {
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '83%',
        display: 'flex',
        marginTop:"15px"
    },
    '& .table-container': {
        marginTop: '20px',
        boxShadow: 'none',
    },
    '& .total-page': {
        display: 'flex',
        justifyContent: 'space-between',
    },
    '& .selectTag': {
        padding: '10px 20px',
        background: '#2B6FED',
        color: '#fff',
        marginTop: '35px',
        cursor: 'pointer',
        borderRadius: '20px',
    },
    '& .unSelectTage': {
        background: '#EEF0F8',
        color: '#8D8F98',
        marginTop: '35px',
        borderRadius: '20px',
        cursor: 'pointer',
        padding: '10px 20px',
    },
    '& .downloadReports': {
        color: '#fff',
        background: '#FC8434',
        borderRadius: '8px',
        padding: '10px 20px',
        cursor: 'pointer',
    },
    '& .table-header': {
        padding: "20",
        display: "flex",
        background: "#fff",
        justifyContent: "space-between",
        border: "1px solid rgba(224, 224, 224, 1)",
        alignItems: "center",
    },
    '& .pagination': {
        '& .MuiPaginationItem-page': {
        },
        '& .Mui-selected': {
            background: 'red'
        }
    },
    '& .sort-by': {
        display: "flex",
    },
    '& .filter': {
        backgroundColor: "white",
        fontSize: "14px",
        border: "1px solid #F0F0F0",
        borderRadius: "8px",
        fontWeight: 500,
        padding: "8px 12px",
        color: "darkgray",
        maxWidth: "168px",
        minWidth: "168px",
    },'& .statusBtn': {
        borderRadius:"50px",
        backgroundColor:"#FEE4E2",
        color:"rgb(252, 132, 52)",
        fontSize:"13px",
        padding:"0px 20px !important",
        textTransform:"none",
        fontWeight:"bold"
    },
    "& .map-list-active": {
        color: "#376bd0",
        borderRadius: "6px",
        background: "#dbe7fc",
        padding: "5px",
      },
      "& .map-list-inactive": {
          padding: "5px",
          color: "#afb0b4",
        cursor: "pointer",
      },
      "& .map-list-icon-box": {
        border: "1px solid #f1f1f1",
        display: "flex",
        alignItems: "center",
        borderRadius: "10px",
      },
      "& .table-main-heading": {
          display: "flex",
          alignItems: "center",
          gap: "10px",
      },
      "& .downloadButton": {
          padding: "10px 20px",
          background: "#FC8434",
          color: "#fff",
          borderRadius: "8px",
          cursor: "pointer"
      }
});
export default withTranslation()(
    //@ts-ignore
    (withRouter(OwnersReportsDetails))
);
// Customizable Area End