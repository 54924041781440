//Customizable Area Start
import React from "react";
import ComplexAddUnitController, {
    Props,
} from './ComplexAddUnitController.web'
import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";
import {withRouter} from 'react-router-dom'
import ComplexAddUnitForm from './ComplexAddUnitForm.web'

class ComplexAddUnit extends ComplexAddUnitController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { navigation } = this.props;
        const match:any= this.props.match;
        const id = match?.params?.id;

        return (
            <>
            <MainScreen>
                <ComplexAddUnitForm companyId={id} navigation={navigation} id={"complex_add_unit"} />
            </MainScreen>
            </>
        );
    }
}

const webStyle = {
};

export default withRouter(ComplexAddUnit)
//Customizable Area End