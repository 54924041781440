//Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    Button,
    Grid,
    Tabs,
    Tab, 
    Table,
    InputLabel,
    MenuItem,
    TableCell,
    TableBody,
    TableRow,
    TableHead,
    TextField,
    Select,
    InputAdornment,
    TextareaAutosize
} from "@material-ui/core";
import { CSSProperties, withStyles } from '@material-ui/styles';
import {withTranslation} from "react-i18next";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { UploadIcon } from "./assets";
import {Link} from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik';
//@ts-ignore
import {Building1,Building2,Building3,Building4,Building5,Building6,nextIcon,previousIcon,Down} from './assets'
//@ts-ignore
import Slider from "react-slick";
import ComplexSharedFacilityDetailsController, {
    Props,
} from './ComplexSharedFacilityDetailsController.web'
//@ts-ignore
import {ModalWrapper} from '../../../../components/src/ModalWrapper/index'
import * as Yup from 'yup';

const buildingImages = [
    Building1,
    Building2,
    Building3,
    Building4,
    Building5,
    Building6,
]

const settings = {
    swipeToSlide: true,
    slidesToShow: 5,
    infinite: false,
  };

interface StyledTabProps {
    label: string;
  }

  const validationSchema = Yup.object().shape({
    unitNumber: Yup.string().min(3, 'Unit number must be at least 3 characters')
    .max(50, 'UNit number must be at most 50 characters').required('Unit Number is required'),
    floorNumber: Yup.string().required('Floor Number is required'),
    ownerId: Yup.string().required('Onwer Id is required'),
    unitStatus: Yup.string().required('Unit Status is required'),
    size: Yup.string().required('Size is required'),
    rentStatus: Yup.string().required('Rent Status is required'),
    rentAmount: Yup.string().required('Rent Amount is required'),
    rentTo: Yup.string().required('Rent To is required'),
    rentFrom: Yup.string().required('Rent From is required'),
  });

class ComplexSharedFacilityDetails extends ComplexSharedFacilityDetailsController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        //@ts-ignore
        const {t} = this.props

        return (
            <>
                <Box maxWidth='xl' style={webStyle.detailsBoxShared} >
                <Box>
                <Typography style={{ fontSize: '18px' }}> {t("Complex")} / {t("Buildings")} / {t("Complex Details")} / {t("Buildings")} / {t("Commercial Hall")}</Typography>
                </Box>
                <Box style={webStyle.titleBoxShared}>
                    <Typography style={webStyle.companyTitleComplexShared} className="bold-text">Commercial Hall</Typography>
                    <Box display="flex">
                        <Button variant="contained" data-testid="openEditModal" style={webStyle.editBtnComplexShared} className="bold-text" color="primary" onClick={this.handleOpenEditModal}><span className="bold-text">{t("EDIT DETAILS")}</span></Button>
                    </Box>
                </Box>
                <Box style={webStyle.detailsContainerComplexShared} className="complex_details_container shared_facility_slider">
                    <Box className="complex_page_slider shared_facility_slider_box">
                    <Slider ref={(c: any) => (this.slider = c)} {...settings}>
            {buildingImages.map((image: any, index: number) => {
              return (
                <div
                  className="slider-image-box shared_facility_slider_div"
                //   onClick={() => this.setState({ imageBox: true, photoIndex: index })}
                  key={index}
                >
                  <img className="shared_facility_slider_image" src={image} style={webStyle.sliderImageShared} alt="" />
                </div>
              );
            })}
          </Slider>
          <Box className="slick-bottom shared_facility_slider_btns" style={webStyle.slickButtons}>
                            <Box className="button prev shared_facility_slider_prev" style={webStyle.prevBtnShared} onClick={this.previousImage}>
                              <img src={previousIcon} alt="" className="shared_facility_slider_prev_image" />
                            </Box>
                            <Box className="button next shared_facility_slider_next" style={webStyle.nextBtnShared} onClick={this.nextImage}>
                              <img src={nextIcon} alt="" className="shared_facility_slider_next_image" />
                            </Box>
                          </Box>
                    </Box>
                </Box>
                <Box style={webStyle.aboutBox}>
                        <Typography style={webStyle.aboutHeadingShared} className="bold-text">{t("Details")}</Typography>
                        <Box style={webStyle.aboutPara}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                        </Box>
                        <Box style={webStyle.extraDetails}>
                            <Box>
                               <Box style={webStyle.extraDetailsBox}>
                                <Typography>Total Area:</Typography>
                                <Typography style={webStyle.detailsOrange} className="bold-text">1800 sqft</Typography>
                               </Box>
                               <Box style={webStyle.extraDetailsBox}>
                               <Typography>Reservation fees:</Typography>
                                <Typography style={webStyle.detailsOrange}  className="bold-text">SR 50 per hour</Typography>
                               </Box>
                            </Box>
                            <Box>
                            <Box style={webStyle.docInfoBox}>
                                            <Box style={webStyle.docTitle}>
                                            <DescriptionOutlinedIcon style={webStyle.docIcon}/>
                                            <Typography className="bold-text">Floor Plan</Typography>
                                            </Box>
                                            <Box style={webStyle.docCountShared}>
                                                    <Typography className="bold-text" style={webStyle.docCountTextShared}>04</Typography>
                                                </Box>
                                        </Box>
                            </Box>
                        </Box>
                </Box>
                <Box style={webStyle.documentTabs}>
                    <Box style={webStyle.documentBoxShared}>
                    <Box style={webStyle.documentBoxHeadingShared}>
                            <Typography style={webStyle.buildingHeadingShared} className="bold-text">{t("Upcoming Reservation")}</Typography>
                        </Box>
                        <Box style={webStyle.documentList}>
                        <Table className="table-box">
                    <TableHead>
                      <TableRow>
                      <TableCell className="bold-text shared_facility_tablecell">{t("#")}</TableCell>
                        <TableCell className="bold-text shared_facility_tablecell">{t("Reserved By")}</TableCell>
                        <TableCell className="bold-text shared_facility_tablecell">{t("Unit Number")}</TableCell>
                        <TableCell className="bold-text shared_facility_tablecell">{t("Reserved On")}</TableCell>
                        <TableCell className="bold-text shared_facility_tablecell">{t("Duration")}</TableCell>
                        <TableCell className="bold-text shared_facility_tablecell"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    <TableRow>
                              <TableCell className="shared_facility_tablecell">1</TableCell>
                            <TableCell className="shared_facility_tablecell">John Doe</TableCell>
                            <TableCell className="shared_facility_tablecell">A-101</TableCell>
                            <TableCell className="shared_facility_tablecell">12 july 2022</TableCell>
                            <TableCell className="shared_facility_tablecell">9:00 - 12:00</TableCell>
                            <TableCell className="shared_facility_tablecell" style={webStyle.moreOptionsShared}><MoreVertIcon style={webStyle.moreIconShared} 
                            // onClick={() => this.handleOpenOptions()} 
                            data-testid="more_options" />
                            {/* {
                              this.state.openOptions === 1 && <Box style={webStyle.rowOptions}>
                              <ul style={webStyle.rowList}>
                                <li style={webStyle.option1}><Link to={`/CompanyEmployee/realestate-company-details/${row.id}`}>{t("View location on map")}</Link></li>
                                <li><Link to={`/CompanyEmployee/ComplexDetails/${row.id}`}>{t("Go to Complex Page")}</Link></li>
                              </ul>
                            </Box>
                            } */}
                            </TableCell>
                          </TableRow>
                    </TableBody>
                  </Table>
                        </Box>
                    </Box>
                </Box>
                </Box>
                <ModalWrapper open={this.state.editModal} closeFn={this.handleCloseEditModal}>
                    <Box style={webStyle.juridBox}>
                        <Box style={webStyle.juridHeading}>
                            <Typography style={webStyle.juridText} className="bold-text">{t("Edit Details")}</Typography>
                            <CloseIcon data-testid="closeEditModal" style={webStyle.closeIcon} onClick={this.handleCloseEditModal} />
                        </Box>
                        <Box style={webStyle.editForm}>
                        <Formik
        initialValues={this.state.initialValues}
        validationSchema={validationSchema}
        onSubmit={() => {}}
        enableReinitialize={true}
      >
        {({ values, isSubmitting }) => (
          <Form translate={undefined}>
            <Box maxWidth='xl' className="dashboard_container field_placeholder" style={webStyle.formContainerUnit}>
            <Grid container spacing={4}>

            <Grid item xs={12} sm={12} className="shared_facility_grid">
                <Grid container className="shared_facility_grid_container">
                    <Grid item xs={12} className="shared_facility_grid_item">
                    <label htmlFor="uploadPhotos" className="shared_facility_grid_label" style={webStyle.label}>
                      {t("Upload Photos (optional)")}</label>
                    </Grid>
                    <Grid item xs={12} className="shared_facility_grid_item">
                    <Box style={webStyle.uploadBox} className="shared_facility_grid_upload_box">
                      <img src={UploadIcon} className="shared_facility_grid_upload_box_image" />
                    </Box>
              <Box style={webStyle.errorMsg} className="shared_facility_error_box">
              <ErrorMessage name="purchasePrice" className="shared_facility_error" component="div" data-testid="purchasePrice-error" />
                   </Box>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
                <Grid container>
                <Grid item xs={12}>
                    <label htmlFor="details" style={webStyle.label}>
                      {t("Details")}</label>
                    </Grid>
                    <Grid item xs={12}>
                    <Field
        as={TextareaAutosize}
        type="text"
        name="details"
        id="details"
        placeholder="Details"
        data-testid="details-input"
        style={webStyle.formikField}
        minRows={3}
      />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={6} sm={6}>
                <Grid container>
                    <Grid item xs={12}>
                    <label htmlFor="totalArea" style={webStyle.label}>
                      {t("Total Area")}</label>
                    </Grid>
                    <Grid item xs={12}>
                    <Field
                    as={TextField}
                type="text"
                name="totalArea"
                id="totalArea"
                placeholder={t("Total Area")}
                data-testid="totalArea-input"
                style={webStyle.formikField}
              />
              <Box style={webStyle.errorMsg}>
              <ErrorMessage name="totalArea" component="div" data-testid="totalArea-error" />
                   </Box>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={6} sm={6}>
                <Grid container className="countries_form_real_estate_grid_container shared_facility_grid_container">
                    <Grid item xs={12} className="countries_form_real_estate_grid_item shared_facility_grid_item"><label htmlFor="unitStatus" style={webStyle.label} className="countries_form_real_estate_grid_label shared_facility_grid_label">
                      {t("Unit of Measurement")}</label></Grid>
                    <Grid item xs={12} className="pos_relative countries_form_real_estate_grid_item_new shared_facility_grid_item">
                      {
                        values.country !== "" ? <React.Fragment data-testid="values_fragment"></React.Fragment> : <InputLabel htmlFor="demo-simple-select-label" className="form_select_placeholder shared_facility_grid_label_input">
                          {t("Select Unit Status")}</InputLabel>
                      }
                      <Field as={Select} name="unitStatus" 
                    id="unitStatus" className="estate_select countries_form_real_estate_grid_field shared_facility_grid_field"
                    labelId="demo-simple-select-label"
                    style={webStyle.formikField}
                    >
                        <MenuItem data-testid="countryOption" value={"Hello"} className="shared_facility_grid_option">Hello</MenuItem>
              </Field>
              <Box style={webStyle.errorMsg} className="shared_facility_error_box">
              <ErrorMessage name="country" component="div" className="shared_facility_error" />
              </Box>
              </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} className="shared_facility_reservation_grid">
                <Grid container className="shared_facility_reservation_grid_container">
                    <Grid item xs={12} className="shared_facility_reservation_grid_item">
                    <label htmlFor="purchasePrice" style={webStyle.label} className="shared_facility_reservation_grid_label">
                      {t("Reservation Fees (Per Hour)")}</label>
                    </Grid>
                    <Grid item xs={12} className="shared_facility_reservation_grid_item">
                    <Field
                    as={TextField}
                type="text"
                name="purchasePrice"
                id="purchasePrice"
                placeholder={t("Purchase Price")}
                data-testid="purchasePrice-input"
                style={webStyle.formikField}
                className="shared_facility_reservation_grid_textfield"
              />
              <Box style={webStyle.errorMsg} className="shared_facility_reservation_grid_error_box">
              <ErrorMessage name="purchasePrice" component="div" data-testid="purchasePrice-error" className="shared_facility_reservation_grid_error" />
                   </Box>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} className="shared_facility_upload_grid">
                <Grid container className="shared_facility_upload_grid_container">
                    <Grid item xs={12} className="shared_facility_upload_grid_item">
                    <label htmlFor="commercialRegistrationNumber" className="shared_facility_upload_grid_label" style={webStyle.label}>
                    {t("Registration Certificate (Government Proof)")}
              </label>
                    </Grid>
                    <Grid item xs={12} className="shared_facility_upload_grid_item">
                      {
                        this.state.fileName ? <Box style={webStyle.fileBox} data-testid="file_box" className="shared_facility_upload_file_box">
                            <span style={webStyle.fileNameBox} className="shared_facility_upload_span">
                              <span>{this.state.fileName}</span>
                              <CloseIcon className="shared_facility_upload_close" onClick={this.handleFileRemove} style={webStyle.removeIcon} />
                            </span>
                          </Box> : <Field name="file" className="shared_facility_upload_field">
                          {({ field }:{field:string}) => (
                          <TextField
                            type="file"
                            style={webStyle.formikField}
                            className="shared_facility_upload_textfield"
                            data-testid="proofDoc"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <img src={UploadIcon} /> 
                                </InputAdornment>
                              ),
                              inputProps: {
                                accept: ".jpg,.jpeg,.png,.pdf",
                                onChange: this.handleProofDoc,
                              },
                            }}
                          />
                        )}</Field>
                      }
                {
                  this.state.proofError && <p style={webStyle.errorMsg}>*
                  {t("Please upload proof document")}</p>
                }
                    </Grid>
                </Grid>
              
            </Grid>
            
            </Grid>
            </Box>
            <Box style={webStyle.btnContainerUnitShared} className="unit_btn_container">
            <Button variant="outlined" className="unit_cancel_btn" style={webStyle.cancelBtnUnitShared} data-testid="cancel_btn" 
            onClick={this.handleCloseEditModal}
            >
              {t("CANCEL")}
            </Button>
            <Button type="submit" className="unit_submit_btn" data-testid="submit-button" style={webStyle.submitBtnUnitShared}>
              {t("SAVE")}
            </Button>
            </Box>
          </Form>
        )}
      </Formik>
                        </Box>
                    </Box>
                </ModalWrapper>
            </>
        );
    }
}

const webStyle:any = {
    cancelBtnUnitShared:{
        borderColor:'#2B6FED',
        color:'#2B6FED',
        width:'180px',
        height:'45px',
        marginRight:'15px'
      },
      submitBtnUnitShared:{
        background:'#2B6FED',
        borderColor:'#2B6FED',
        color:'#fff',
        width:'250px',
        height:'45px'
      },
      btnContainerUnitShared:{
        textAlign:'right' as CSSProperties['textAlign'],
        marginTop:'15px'
      },
    documentTabs:{
        marginTop:'30px',
        boxShadow:'4px 0px 14px #e9e9e9'
    },
    extraDetails:{
        display:'flex',
        justifyContent:'space-between',
        marginTop:'15px'
    },
    extraDetailsBox:{
        display:'flex'
    },
    detailsOrange:{
    color:'rgb(252, 132, 52)'
    },
    juridGrid:{
        paddingTop:'25px'
    },
    label:{
        marginBottom:'10px',
        fontSize:'14px'
      },
    juridTitle:{
        color:'rgb(138, 138, 138)',
        fontSize:'12px'
    },
    slickButtons:{
        position:'absolute',
        width:'100%',
        top:'40%'
    },
    juridDoc:{
        color:'rgb(43, 111, 237)'
    },
    formContainerUnit:{
        overflow:'unset'
    },
    rejected: {
        background: 'rgb(242,23,23, 0.2)',
        color: '#F21717',
    },
    pending_approval:{
        background: 'rgb(252, 132, 52, 0.2)',
        color: 'rgb(252, 132, 52)',
    },
    aboutBox:{
        background:'#fff',
        borderRadius:'8px',
        padding:'20px 15px',
        marginTop:'30px',
        boxShadow: '4px 0px 14px #e9e9e9'
    },
    juridHeading:{
        display:'flex',
        justifyContent:'space-between'
    },
    closeIcon:{
        cursor:'pointer'
    },
    moreListOptions:{
        background:'#fff',
        borderRadius:'8px',
        position:'absolute',
        zIndex:'9999999',
        width:'250px',
        boxShadow: '0px 3px 10px 0px rgba(204,204,204,1)'
    },
    uploadBox:{
        borderRadius: '8px',
        border: '1px dashed rgba(143,146,161,0.2)',
        height:'100px',
        width:'175px',
        marginTop:'10px',
        background:'rgb(243, 243, 243)',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
      },
      errorMsg:{
        color:'red',
        fontSize: '12px'
      },
    documentList:{
        padding:'20px 15px'
    },
    docIcon:{
        color:'rgb(43, 111, 237)',
        marginRight:'5px'
    },
    addBuildingBtn:{
        background:'rgb(43, 111, 237)',
        marginRight:'15px',
        borderRadius: '8px',
        paddingLeft:'30px',
        paddingRight:'30px'
    },
    addSharedBtn:{
        background:'rgb(252, 132, 52)',
        marginRight:'15px',
        borderRadius: '8px',
        paddingLeft:'30px',
        paddingRight:'30px',
        boxShadow:'unset'
    },
    juridBox:{width:"50%",marginTop:'15px',backgroundColor:"white",padding:'20px',borderRadius:"20px", height: '80vh', overflowY:'scroll'},
    subBox:{width:"40%",marginTop:'15px',backgroundColor:"white",padding:'20px',borderRadius:"20px"},
    juridText:{
        fontSize:'20px'
    },
    docInfoBox:{
        width:'250px',
        border: '1px solid rgb(225, 225, 225)',
        padding:'15px',
        borderRadius:'8px',
        display:'flex',
        justifyContent:'space-between'
    },
    moreList:{
        position:'relative'
    },
    docTitle:{
        display:'flex'
    },
    documentBoxShared:{
        background:'#fff'
    },
    documentBoxHeadingShared:{
        padding:'20px 15px',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        borderBottom:'1px solid rgb(225, 225, 225)'
    },
    formikField:{
        width:'calc(100% - 15px)',
        background: 'rgb(243, 243, 243) none repeat scroll 0% 0%',
        border:'1px solid rgb(227, 227, 227)',
        padding:'5px',
        borderRadius:'8px',
        marginTop:'10px',
    },
    docCountShared:{
        background:'rgba(252, 132, 52, 0.1)',
        borderRadius:'10px',
        textAlign:'center',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        paddingLeft:'10px',
        paddingRight:'10px'
    },
    prevBtnShared:{
        background:'rgba(0,0,0,0.3)',
        height:'30px',
        width:'30px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        position:'absolute',
        cursor:'pointer'
    },
    nextBtnShared:{
        background:'rgba(0,0,0,0.3)',
        height:'30px',
        width:'30px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        position:'absolute',
        right:'6px',
        cursor:'pointer'
    },
    docCountTextShared:{
        color:'rgb(252, 132, 52)',
        fontSize:'12px',
        paddingRight:'0px'
    },
    buildingHeadingShared:{
        fontSize:'20px'
    },
    aboutHeadingShared:{
        fontSize:'20px',
        marginBottom:'10px'
    },
    detailsBoxShared:{
        padding:'30px'
    },
    companyTitleComplexShared:{
        fontSize:'32px'
    },
    moreIconShared:{
        color:'rgba(136,136,136,0.6)'
    },
    titleBoxShared:{
        marginTop:'15px',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    sliderImageShared:{
        height: '100px',
        width: '100%',
        borderRadius:'8px',
        marginRight:'15px',
        objectFit:'cover'
    },
    moreOptionsShared:{
        display:'flex',
        alignItems:'center'
    },
    editBtnComplexShared:{
        background:'#2B6FED',
        borderColor:'#2B6FED',
        fontWeight: 700
    },
    detailsContainerComplexShared:{
        background:'#fff',
        padding:'15px',
        marginTop:'30px',
        borderRadius:'8px',
        boxShadow:'4px 0px 14px #e9e9e9'
    },
};

export default withTranslation()(ComplexSharedFacilityDetails)
//Customizable Area End