// Customizable Area Start
import React from "react";
import {Container, Typography, withStyles, Divider, Box, Card, Button,TextField,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  IconButton,
  MenuItem
} from "@material-ui/core";
import { Menu} from "@szhsin/react-menu";
import InvitationReportController, {Props} from "./InvitationReportController.web";
import DashboardHeader from "../../dashboard/src/DashboardHeader.web";
import ChairmanSidebarWeb from "../../dashboard/src/ChairmanSidebar.web";
import {withTranslation} from "react-i18next";
import {ReportsStyleWeb} from "./ReportsStyle.web";
import {withRouter} from "react-router";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Link } from "react-router-dom";
import { t } from "i18next";
import FilterSelect from "../../../components/src/CommonComponents/FilterSelect.web";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import PaginationModule from "../../StoreCredits/src/PaginationModule.web";


class InvitationReport extends InvitationReportController {
    constructor(props: Props) {
        super(props);
    }

    tableBody=()=>(
        this.state.invitationData && this.state.invitationData.map((row: any, i: number) => {
            return <TableRow key={i}>
                <TableCell>{(this.state.pagination.current_page - 1) * 10 + i + 1}</TableCell>
                <TableCell>{row.attributes.subscriber_name}</TableCell>
                <TableCell>{row.attributes?.complex_name || "_"}</TableCell>
                <TableCell>{row.attributes?.complex_name || "_"}</TableCell>
                <TableCell>{row.attributes?.owner_name || "-"}</TableCell>
                <TableCell>{this.convertDate(row.attributes.expiry_date)}</TableCell>
                <TableCell className="view-page-menu buildid" id="detailsId">
                    <Menu
                        menuButton={
                            <IconButton>
                                <MoreVertIcon />
                            </IconButton>
                        }
                        className="buildid"
                        style={webStyle.btnMenu}
                    >
                        <MenuItem className="buildid">
                            <Link to={`${row.attributes.subscriber_id}`}>{t("View Profile")}</Link>
                        </MenuItem>

                        <MenuItem
                            className="sm-cancel-menu buildid"
                        >
                            <Link to={`${row.id}`}>
                                {t("View ")} 
                            </Link> 
                        </MenuItem>
                    </Menu>
                </TableCell>
            </TableRow>
        })
    )

    render() {
        const {t, classes,i18n}: any = this.props;
        const language = i18n.language;
        return (
            <>
                <CEHeaderComponentWeb>
                    <Container className={classes.reportDetails}>
                        <Box className="navigation">
                            <Typography variant="body1">
                                {t("Documents & Reports")} /
                                <Box
                                    component="span"
                                    className="reports-invitationReport breadcrumbInvitation" onClick={() => this.props.history.push("/CompanyEmployee/ReportsStatistics")}
                                    style={{cursor: "pointer"}}
                                >
                                    {" "}
                                    {t("Reports")}
                                </Box>{" "}
                                /{" "}
                                <Box className="reports-invitationReport" component="span" style={{color: "blue"}}>
                                    {t("Invitations Reports")}
                                </Box>
                            </Typography>
                            <Box className="sub-heading-box">
                                <Typography
                                    variant="h5"
                                    className="sub-heading bold-text reports-invitationReport"
                                    style={{fontSize: "28px"}}
                                >
                                    {t("Invitations Reports")}
                                </Typography>
                            </Box>
                            <Box className="filter-head-box">
                                <FilterSelect t={t} language={language} label="Complex" value="" />
                                <FilterSelect t={t} language={language} label="Select Country" value="" />
                                <FilterSelect t={t} language={language} label="Select City" value="" />
                                <FilterSelect t={t} language={language} label="Select Complex" value="" />
                                <FilterSelect t={t} language={language} label="Select Building" value="" />
                                <FilterSelect t={t} language={language} label="Year" value="" />
                                <Button className="action-search-btn" startIcon={<SearchIcon />}>
                                    {t("Search")}
                                </Button>
                            </Box>
                        </Box>

                        <Box className="budget-box audit-box">
                            <Card>
                                <Box className="heading">
                                    <h4 className="bold-text reports-invitationReport">{t("Invitations Reports")}</h4>
                                </Box>
                                <Divider/>
                                <Box className="budget-content-box">
                                    <Box className="head audit-line">
                                        <p className={"bold-text"}>{t("Title")}</p>
                                        <span className={"bold-text"}>{t("Count")}</span>
                                    </Box>
                                    <hr/>
                                    <Box className="audit-line">
                                        <p>{t("Total Sent Invitations")}</p>
                                        <span>{this.state.invitationData?.total_sent_invitation || 0} </span>
                                    </Box>
                                    <hr/>
                                    <Box className="audit-line">
                                        <p>{t("Accepted Invitations by users")}</p>
                                        <span>{this.state.invitationData?.member_invitation_accepted || 0}</span>
                                    </Box>
                                    <hr/>
                                    <Box className="audit-line">
                                        <p>{t("Rejected Invitation by users")}</p>
                                        <span>{this.state.invitationData?.rejected_invitation_by_users || 0}</span>
                                    </Box>
                                    <hr/>
                                    <Box className="audit-line">
                                        <p>{t("Total Received Invitations")}</p>
                                        <span>{this.state.invitationData?.total_receive_invitation || 0}</span>
                                    </Box>
                                    <hr/>
                                    <Box className="audit-line">
                                        <p>{t("Pending join requests")}</p>
                                        <span>{this.state.invitationData?.pending_join_requests || 0}</span>
                                    </Box>
                                    <hr/>
                                    <Box className="audit-line">
                                        <p>{t("Sent invitations awaiting acceptance")}</p>
                                        <span>{this.state.invitationData?.sent_invitation_awaiting_acceptence || 0}</span>
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                        <Box style={webStyle.tableContainerStyle} className="subscription_table_select_boxes_container">
                            <Box style={webStyle.tableHeaderStyle} className="subscription_table_select_boxes_header">
                                <h3 className="bold-text subscription_table_heading2">
                                    {
                                        t("Oldest Pending Invitations")
                                    }
                                </h3>
                                <TextField
                                    label="Search"
                                    variant="outlined"
                                    className="table_detail_search subscription_detail_search_new"
                                    style={webStyle.searchInputStyle}
                                    placeholder={t("Search")}
                                    InputProps={{
                                        startAdornment: (
                                            <IconButton
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        ),
                                        style: webStyle.innerInput
                                    }}
                                />
                            </Box>
                            {
                                <Table className="table-box">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="bold-text">{t("#")}</TableCell>
                                            <TableCell className="bold-text">{t("Complex")}</TableCell>
                                            <TableCell className="bold-text">{t("Building")}</TableCell>
                                            <TableCell className="bold-text">{t("Name")}</TableCell>
                                            <TableCell className="bold-text">{t("Role")}</TableCell>
                                            <TableCell className="bold-text">{t("Sent On")}</TableCell>
                                            <TableCell className="bold-text"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.tableBody()}
                                    </TableBody>
                                </Table> 
                            }
                            <Divider />
                            <Box style={webStyle.tableFooterStyle} className="paginate_details">
                                <PaginationModule
                                    pagination={this.state.pagination}
                                    handlePagination={""}
                                    page={1} />
                            </Box>
                        </Box>
                    </Container>
                </CEHeaderComponentWeb>
            </>
        );
    }
}
const webStyle: any = {
    tableContainerStyle: {
        background: '#fff'
    },
    tableHeaderStyle: {
        padding: '20px',
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    tableFooterStyle: {
        padding: '30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    searchInputStyle: {
        border: '1px solid rgba(0,0,0,0.2)',
        borderRadius: '8px',
        height: '40px',
        padding: '0px',
        color: '#212121'
    },
    innerInput: {
        padding: '0px',
        color: '#212121'
    },
}

export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(InvitationReport)));

// Customizable Area End
