import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Grid, Typography, MenuItem, FormControl, Select, InputAdornment, TextField, Modal, Fade, Backdrop
} from "@material-ui/core";

//@ts-ignore
import DeleteIcon from '../../assets/delete.png'
//@ts-ignore
import CompEmpComplexBlockStep5Controller from './CompEmpComplexBlockStep5Controller.web'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CloseIcon from '@material-ui/icons/Close';
import {Formik, Form, Field, ErrorMessage} from "formik";
import {numbersOnly, alphaNumericOnly} from "../../../../components/src/KeyBlockFunctions";
import "../../assets/commonForm.css"
import * as Yup from 'yup';
// Customizable Area End

const validationSchema = Yup.object().shape({
    aboutUs: Yup.string().required('About Us is required'),
    buildingName: Yup.string().min(3, 'Building name must be at least 3 characters')
        .max(50, 'Building name must be at most 50 characters').required('Building Name is required'),
    buildingArea: Yup.string().required('Building Area is required'),
    totalFloors: Yup.string().required('Total Floors is required'),
    googleMapLocation: Yup.string().required('Google Map Location is required'),
    country: Yup.string().required('Country is required'),
    logo: Yup.mixed().required('Please upload a logo'),
    images: Yup.array()
        .min(1, 'At least one image is required')
        .max(6, 'Maximum of 6 images allowed')
        .required('Please upload images')
});

export default class CompEmpComplexBlockStep5 extends CompEmpComplexBlockStep5Controller {
    render() {
        // Customizable Area Start

        const {t} = this.props;

        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <Formik
                    initialValues={this.props.buildingValues}
                    validationSchema={validationSchema}
                    validateOnMount={true}
                    data-testid="step5Form"
                    onSubmit={(values, actions) => {
                        this.props.handleBuildingDetails(values)
                        actions?.resetForm();
                    }}
                >
                    {({values, touched, errors, isValid, setFieldValue, handleChange}) => (
                        <Form translate="yes" className="commonForms">
                            <Box>
                                <Box style={{marginTop: '20px'}}>
                                    <Box style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <Box>
                                            <Typography className="Buildings bold-text" style={{
                                                fontSize: '22px',
                                                fontWeight: 'bold'
                                            }}>{t("Buildings")}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography className="stepBuildings"
                                                        style={{fontSize: '16px'}}>{t("Step")} {this.props.stepComplex + 1} {t("of 7")}</Typography>
                                        </Box>
                                    </Box>
                                    {
                                        this.props.savedBuildings.length ? <Grid container spacing={4} style={{
                                            marginTop: '15px'
                                        }}>
                                            {
                                                this.props.savedBuildings.map((building: any, i: number) => {
                                                    return <Grid item xs={4} key={i}><Box style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        border: '1px solid #E4E4E4',
                                                        borderRadius: '12px',
                                                        padding: '15px'
                                                    }}>
                                                                <span className="bold-text" style={{
                                                                    color: '#FC8434',
                                                                    fontSize: '18px',
                                                                    textTransform: 'capitalize'
                                                                }}>{building.buildingName}</span><CloseIcon
                                                        data-testid="openDeleteModal"
                                                        onClick={() => this.props.handleOpenDeleteBuildingPopup(i)}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}/>
                                                    </Box></Grid>
                                                })
                                            }
                                        </Grid> : <></>
                                    }
                                    <Box style={{display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between'}}>
                                        <Box style={{marginTop: '20px', border: '1px solid #2B6FED', borderRadius: '8px'}}>
                                            <Box style={{color: '#2B6FED', fontSize: '15px', fontWeight: 400, padding: '10px 25px', cursor: 'grabbing'}} data-testid="addBuilding" onClick={this.props.findPersonPopup} className="bold-text">+ {t("ADD NEW BUILDING")}</Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box style={{
                                            left: 0,
                                            display: this.props.findPopupOpen ? 'block' : 'none',
                                            position: 'fixed',
                                            top: 0,
                                            width: '100%',
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            height: '100%',
                                            zIndex: 9999,
                                        }}>
                                            <Box style={{
                                                width: '50%',
                                                backgroundColor: '#fff',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                position: 'absolute',
                                                height: '90%',
                                                overflow: 'auto',
                                                borderRadius: '5px',

                                            }}>
                                                <Box>
                                                    <Box className="topBarName New">
                                                        <Box style={{fontSize: '22px', fontWeight: 400}}><span
                                                            className="bold-text">{t("Add New Building")}</span></Box>
                                                        <Box onClick={this.props.findPersonPopup}
                                                             style={{cursor: "grabbing"}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14"
                                                                 height="14" viewBox="0 0 14 14" fill="none">
                                                                <path
                                                                    d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z"
                                                                    fill="black"/>
                                                            </svg>
                                                        </Box>
                                                    </Box>
                                                    <Box style={{borderBottom: '1px solid #e7e7e7'}}></Box>
                                                    <Box className="step5Formik" style={{padding: "25px"}}>
                                                        <Box className="step2forms step5Formik" style={{
                                                            marginTop: '28px', textAlign: 'center', display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}>
                                                            {
                                                                values.logo ? <img className="step5Formik"
                                                                                   data-testid="preview_img"
                                                                                   src={URL.createObjectURL(values.logo)}
                                                                                   alt="Preview" style={{
                                                                    width: '66px',
                                                                    height: '66px',
                                                                    borderRadius: '50%',
                                                                    background: '#dcdcdc'
                                                                }}/> : <Box className="step5Formik" style={{
                                                                    width: '66px',
                                                                    height: '66px',
                                                                    borderRadius: '50%',
                                                                    background: '#dcdcdc'
                                                                }}>
                                                                </Box>
                                                            }

                                                            <label htmlFor="logoChange"><span style={{
                                                                color: '#FC8434'
                                                            }} className="bold-text step5Formik">
                          {values.logo ? <>{t("Change Logo")}</> : <>{t("Add Logo")}</>}
                        </span></label>
                                                            <input type="file"
                                                                   accept="image/*"
                                                                   id="logoChange" className="step5Formik"
                                                                   name="logo" data-testid="logoUpload"
                                                                   onChange={(event: any) => {
                                                                       setFieldValue('logo', event.currentTarget.files[0])
                                                                   }}
                                                                   style={{display: 'none'}}/>
                                                            <Box style={webStyle.errorMsg}><ErrorMessage name="logo"
                                                                                                         component="div"
                                                                                                         data-testid="logo-error"/></Box>
                                                        </Box>
                                                        <Box className="step5Formik" style={{marginTop: '20px'}}>
                                                            <Typography className="typographyLable step5Formik">{t("Upload Photos")}</Typography>
                                                            <Box style={{display: 'flex'}}>
                                                                {values.images.length < 6 && (
                                                                    <>
                                                                        <label className="step5Formik"
                                                                               id="label-file-upload"
                                                                               htmlFor="input-file-upload" style={{
                                                                            marginRight: '23px',
                                                                            flexWrap: 'wrap'
                                                                        }}>
                                                                            <div className="step5Formik">
                                                                                <button
                                                                                    className="upload-button step5Formik">
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="20" height="20"
                                                                                        viewBox="0 0 20 20" fill="none">
                                                                                        <path opacity="0.4"
                                                                                              fill-rule="evenodd"
                                                                                              clip-rule="evenodd"
                                                                                              d="M9 3.40501L6.71 5.70501C6.45634 5.95867 6.08663 6.05774 5.74012 5.96489C5.39362 5.87205 5.12297 5.6014 5.03012 5.25489C4.93728 4.90839 5.03634 4.53867 5.29 4.28501L9.29 0.285014C9.3851 0.193973 9.49725 0.122608 9.62 0.0750135C9.86346 -0.0250045 10.1365 -0.0250045 10.38 0.0750135C10.5028 0.122608 10.6149 0.193973 10.71 0.285014L14.71 4.28501C14.8993 4.47278 15.0058 4.72838 15.0058 4.99501C15.0058 5.26165 14.8993 5.51725 14.71 5.70501C14.5222 5.89433 14.2666 6.00081 14 6.00081C13.7334 6.00081 13.4778 5.89433 13.29 5.70501L11 3.40501V12.995C11 13.5473 10.5523 13.995 10 13.995C9.44771 13.995 9 13.5473 9 12.995V3.40501ZM18 12.995C18 12.4427 18.4477 11.995 19 11.995C19.5523 11.995 20 12.4427 20 12.995V16.995C20 18.6519 18.6569 19.995 17 19.995H3C1.34315 19.995 0 18.6519 0 16.995V12.995C0 12.4427 0.447715 11.995 1 11.995C1.55228 11.995 2 12.4427 2 12.995V16.995C2 17.5473 2.44772 17.995 3 17.995H17C17.5523 17.995 18 17.5473 18 16.995V12.995Z"
                                                                                              fill="black"/>
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </label>

                                                                        <input type="file"
                                                                               accept="image/*"
                                                                               onChange={(event: any) => setFieldValue('images', [...values.images, ...event.currentTarget.files])}
                                                                               style={{display: 'none'}}
                                                                               id="input-file-upload"
                                                                               name="images"
                                                                               data-testid="step2Images"
                                                                               className="step5Formik"
                                                                               multiple
                                                                        />
                                                                    </>
                                                                )}

                                                                <div className="image-preview step5Formik" style={{
                                                                    display: 'flex'
                                                                }}>
                                                                    {values.images.map((image: any, index: number) => (
                                                                        <div className="step5Formik" style={{
                                                                            position: 'relative'
                                                                        }}>
                                                                            <img
                                                                                style={{
                                                                                    height: '104px',
                                                                                    width: '172px',
                                                                                    marginRight: '20px',
                                                                                    objectFit: 'cover',
                                                                                    border: '1px solid #ececec'
                                                                                }}
                                                                                className="step5Formik"
                                                                                key={index}
                                                                                src={URL.createObjectURL(image)}
                                                                                alt={`Image ${index}`}
                                                                            />
                                                                            <HighlightOffIcon
                                                                                className="faq_image_close_icon faq_image_close_icon2 step2forms step5Formik"
                                                                                style={{
                                                                                    right: '25px',
                                                                                    position: 'absolute',
                                                                                    fill: 'red',
                                                                                    top: '5px',
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                                data-testid="step2ImageRemove"
                                                                                onClick={() => {
                                                                                    const step5NewImages = [...values.images];
                                                                                    step5NewImages.splice(index, 1);
                                                                                    setFieldValue('images', step5NewImages);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </Box>
                                                            <Box style={webStyle.errorMsg}><ErrorMessage name="images"
                                                                                                         component="div"
                                                                                                         data-testid="images-error"/></Box>
                                                        </Box>
                                                        <Box style={{marginTop: '20px'}}>
                                                            <Box style={{width: '100%'}}>
                                                                <Typography className="typographyLable About">{t("About Us")}</Typography>
                                                                <Box className="formGroups About" style={{
                                                                    marginBottom: "0px",
                                                                    border: "1px solid #F0F0F0",
                                                                    borderRadius: "8px",
                                                                    backgroundColor: "#F0F0F0"
                                                                }}>
                                                                    <Field name="aboutUs" type="address" as="textarea"
                                                                           placeholder="About Us"
                                                                           className="formInputs1"/>
                                                                    <span className="frmLeftIcons">
                                                                        </span>
                                                                </Box>
                                                                <Box style={webStyle.errorMsg}><ErrorMessage
                                                                    name="aboutUs" component="div"
                                                                    data-testid="aboutUs-error"/></Box>
                                                            </Box>
                                                        </Box>
                                                        <Box style={{display: 'flex', padding: '0px'}}>
                                                            <Box className="formGroups customSelect Name"
                                                                 style={{marginRight: '25px', width: '50%'}}>
                                                                <Typography className="typographyLable Name">{t("Building Name")}</Typography>
                                                                <Box>
                                                                    <Box className="formGroups" style={{
                                                                        marginBottom: "0px",
                                                                        border: "1px solid #F0F0F0",
                                                                        borderRadius: "8px",
                                                                        backgroundColor: "#F0F0F0"
                                                                    }}>
                                                                        <Field name="buildingName"
                                                                               onKeyDown={alphaNumericOnly}
                                                                               type="text" placeholder={t("Building Name")}
                                                                               className="formInputs"/>
                                                                        <span className="frmLeftIcons Name">
                                                                            </span>
                                                                    </Box>
                                                                    <Box style={webStyle.errorMsg}><ErrorMessage
                                                                        name="buildingName" component="div"
                                                                        data-testid="buildingName-error"/></Box>
                                                                </Box>
                                                            </Box>
                                                            <Box className="formGroups customSelect step5CountrySelect"
                                                                 style={{marginRight: '', width: '50%'}}>
                                                                <Typography
                                                                    className="typographyLable step5CountryLabel">
                                                                    {t("Country")}
                                                                </Typography>
                                                                <FormControl className="step5CountryForm"
                                                                             variant="outlined">
                                                                    <Field as={Select} name="country"
                                                                           className="City step5CountryField"
                                                                           displayEmpty
                                                                           style={{
                                                                               backgroundColor: "#F0F0F0",
                                                                               color: "#BEBEBE",
                                                                               borderRadius: '8px'
                                                                           }}
                                                                    >
                                                                        <MenuItem disabled value="">{t("Select Country")}</MenuItem>
                                                                        {this.props.countriesList.map((variant: any, i: number) => (
                                                                            <MenuItem className="step5CountryMenu"
                                                                                      value={variant} key={i}>
                                                                                {variant}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Field>
                                                                </FormControl>
                                                                <Box className="step5CountryErrorBox"
                                                                     style={webStyle.errorMsg}><ErrorMessage
                                                                    className="step5CountryError" name="country"
                                                                    component="div" data-testid="country-error"/></Box>
                                                            </Box>
                                                        </Box>
                                                        <Box style={{display: 'flex', padding: '0px'}}>
                                                            <Box className="formGroups customSelect Area"
                                                                 style={{marginRight: '25px', width: '50%'}}>
                                                                <Typography className="typographyLable Area">{t("Building Area")}</Typography>
                                                                <Box>
                                                                    <Box className="formGroups" style={{
                                                                        marginBottom: "0px",
                                                                        border: "1px solid #F0F0F0",
                                                                        borderRadius: "8px",
                                                                        backgroundColor: "#F0F0F0"
                                                                    }}>
                                                                        <Field name="buildingArea">
                                                                            {({field}: { field: any }) => (
                                                                                <TextField
                                                                                    {...field}
                                                                                    InputProps={{
                                                                                        endAdornment: (
                                                                                            <InputAdornment
                                                                                                position="end">
                                                                                                Sqft
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                    onChange={handleChange}
                                                                                    type="text"
                                                                                    placeholder={t("Building Area")}
                                                                                    className="formInputs"
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        <span className="frmLeftIcons Area">
                                                                            </span>
                                                                    </Box>
                                                                    <Box style={webStyle.errorMsg}><ErrorMessage
                                                                        name="buildingArea" component="div"
                                                                        data-testid="buildingArea-error"/></Box>
                                                                </Box>
                                                            </Box>
                                                            <Box className="formGroups customSelect"
                                                                 style={{marginRight: '', width: '50%'}}>
                                                                <Typography className="typographyLable Floors">{t("Total Floors")}</Typography>
                                                                <Box>
                                                                    <Box className="formGroups Floors" style={{
                                                                        marginBottom: "0px",
                                                                        border: "1px solid #F0F0F0",
                                                                        borderRadius: "8px",
                                                                        backgroundColor: "#F0F0F0"
                                                                    }}>
                                                                        <Field name="totalFloors" type="numbers"
                                                                               placeholder={t("Total Floors")}
                                                                               className="formInputs"/>
                                                                        <span className="frmLeftIcons Floors">
                                                                            </span>
                                                                    </Box>
                                                                    <Box style={webStyle.errorMsg}><ErrorMessage
                                                                        name="totalFloors" component="div"
                                                                        data-testid="totalFloors-error"/></Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box style={{marginTop: '20px'}}>
                                                            <Box className="boxDisplayFlex">
                                                                <Box style={{width: '100%'}}>
                                                                    <Typography className="typographyLable Google">{t("Google Map Location")}</Typography>
                                                                    <Box className="formGroups Google" style={{
                                                                        marginBottom: "0px",
                                                                        border: "1px solid #F0F0F0",
                                                                        borderRadius: "8px",
                                                                        backgroundColor: "#F0F0F0"
                                                                    }}>
                                                                        <Field name="googleMapLocation" type="text"
                                                                               placeholder={t("Google Map Location")}
                                                                               className="formInputs"/>
                                                                        <span className="frmLeftIcons Google">
                                                                            </span>
                                                                    </Box>
                                                                    <Box style={webStyle.errorMsg}><ErrorMessage
                                                                        name="googleMapLocation" component="div"
                                                                        data-testid="googleMapLocation-error"/></Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box style={{
                                                            display: 'flex',
                                                            marginTop: '15px',
                                                            justifyContent: 'end'
                                                        }}>
                                                            <Grid style={{marginTop: "30px", marginRight: '20px'}}>
                                                                <Button onClick={this.props.findPersonPopup}
                                                                        className="buttonCancel cancel"><span
                                                                    className="bold-text">{t("CANCEL")}</span></Button>
                                                            </Grid>
                                                            <Grid style={{marginTop: "30px",}}>
                                                                <Button className="buttonSave save"
                                                                        type="submit"
                                                                        data-testid="saveBuilding"
                                                                    //  onClick={this.props.handleClickOpenBuilding} 
                                                                ><span className="bold-text">{t("SAVE")}</span></Button>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                            </Box>
                            <Box className="step5BtnBox"
                                 style={{display: 'flex', justifyContent: "end", flexWrap: 'wrap'}}>
                                <Grid className="step5Grid" style={{marginTop: "30px", marginRight: '20px'}}>
                                    <Box style={{}}>
                                        <Button className="step5Prev" style={{
                                            color: "#2B6FED",
                                            fontWeight: 'bold',
                                            padding: '0px 20.5px',
                                            textTransform: 'capitalize',
                                            width: '177px',
                                            height: '56px',
                                            border: '1px solid #2B6FED',
                                            borderRadius: "8px",
                                            display: 'flex',
                                            justifyContent: 'center',
                                            textAlign: 'center'
                                        }} onClick={this.props.handleBack}><span
                                            className="bold-text">{t("PREV")}</span></Button>
                                    </Box>
                                </Grid>
                                <Box>
                                    <Grid className="step5Grid" style={{marginTop: "30px",}}>
                                        <Button className="step5Next" style={{
                                            width: '177px',
                                            height: '56px',
                                            backgroundColor: '#2B6FED',
                                            borderRadius: "8px",
                                            display: 'flex',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            color: "#FFFFFF",
                                            fontWeight: 'bold',
                                            padding: '0px 20.5px',
                                            textTransform: 'capitalize'
                                        }} data-test-id="handleNextComplex"
                                                onClick={() => this.props.handleNext()}><span
                                            className="bold-text">{t("NEXT")}</span></Button>
                                    </Grid>
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>

                <Modal
                    itemID="docDeleteCloseID"
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modal"
                    open={this.props.deleteBuildingPopup}
                    onClose={this.props.handleCloseDeleteBuildingPopup}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.props.deleteBuildingPopup}>
                        <div className="paper paper_Delete_Category ">
                            <div className="Delete_Category_Container">
                                <img src={DeleteIcon} alt="Msg"/>
                            </div>
                            <p className="Delete_Category_Heading bold-text">Delete Building</p>
                            <p className="Delete_Category_Text">
                                Are you sure you want to delete this building?
                            </p>
                            <div className="Modal_Button_Container Delete_Category_Button_Div">
                                <div
                                    className="ModalCancelDiv Button_Size_Delete bold-text"
                                    onClick={this.props.handleCloseDeleteBuildingPopup}
                                >
                                    NO, DON’t DELETE
                                </div>
                                <div
                                    data-testid="deleteFacility"
                                    className="ModalConfirmDiv Button_Size_Delete bold-text"
                                    onClick={() => this.props.handleRemoveBuildingDetails(this.props.deleteBuildingIndex)}
                                >
                                    YES DELETE
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>

            </>

            // Customizable Area End
        );
    }
}

// export default withTranslation()(withRouter(CompEmpComplexBuildingBlock));
// Customizable Area Start
const webStyle = {
    complexPreviewStep5: {
        height: '104px',
        width: '172px',
        marginRight: '20px',
        objectFit: 'cover',
        border: '1px solid #ececec'
    },
    errorMsg: {
        color: 'red',
        fontSize: '12px',
        marginTop: '5px'
    },
    imageBoxStep5: {
        position: 'relative'
    },
    previewBoxStep5: {
        display: 'flex'
    },
    closeIconStep5: {
        right: '25px',
        position: 'absolute',
        fill: 'red',
        top: '5px',
        cursor: 'pointer'
    },
}
// Customizable Area End