// Customizable Area Start
import React from "react";
import GeneralHeaderController from "./GeneralHeaderController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Grid, Box } from "@material-ui/core";
import DashboardHeader from "../../../blocks/dashboard/src/Company Employee/DashboardHeader.web";
import CECOOSidebar from "../../../blocks/dashboard/src/Company Employee/CECOOSidebar.web";
import CEAccManSidebar from "../../../blocks/dashboard/src/Company Employee/CEAccManSIdebar.web";
import CESalesManSidebar from "../../../blocks/dashboard/src/Company Employee/CESalesManSidebar.web";

class CEHeaderComponent extends GeneralHeaderController {
  render() {
    const { i18n }: any = this.props;
    const language = i18n.language;
    const userType = localStorage.getItem("role");
    return (
      <>
        <Box style={{ background: "#F4F7FF" }} dir={language === "ar" ? "rtl" : "ltr"}>
          <DashboardHeader navigation={null} id="" />
          <Box style={{ display: "flex" }}>
            <Grid item xs={3} md={3} sm={3} className="SideBar">
              {userType === "coo" && <CECOOSidebar />}

              {userType === "account_manager" && <CEAccManSidebar />}

              {userType === "sales_manager" && <CESalesManSidebar />}
            </Grid>
            <Grid item xs={9} md={9} sm={9} style={{ paddingTop: 35, minHeight: "92vh" }}>
              {this.props.children}
            </Grid>
          </Box>
        </Box>
      </>
    );
  }
}

// @ts-ignore
export default withTranslation()(withRouter(CEHeaderComponent));

// Customizable Area End
