// Customizable Area Start
import React from "react";
import LeaseManagementIssueNewContractReviewController,{Props} from "./LeaseManagementIssueNewContractReviewController.web";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import { Box, Breadcrumbs, Button, Divider, Grid, Link, Modal, Paper, TextField, Typography, styled } from "@material-ui/core";
import { DownloadIcon, UnitIcon, BuildingIcon, CalenderIcon, ProfileIcon, InfoIcon2, Classified_CorrectIcon } from "./assets";
import { withTranslation } from "react-i18next";
// Customizable Area End

// Customizable Area Start
type TFunction = (value: string) => string;
// Customizable Area End

class LeaseManagementIssueNewContractReview extends LeaseManagementIssueNewContractReviewController {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area Start
    }

    // Customizable Area Start
    renderActivatdContractModal = () => {
        const { t }: { t: TFunction } = this.props;
        return <Modal open={this.state.activateContract}>
            <ActivateModalContainer>
                <Box className="activateModalContainer">
                    <img src={Classified_CorrectIcon} alt="" />
                    <Typography className="modalHeading" data-testId={"activateContractTestId"}>{t("Activate Contract")}</Typography>
                    <Typography className="modalSubHeading">{t("Your contract has been activated. You can access this from contracts section.")}</Typography>
                    <Box className=" activateContractBtn">
                        <Button variant="contained" className="ContractDetailsButton2"
                        onClick={this.handleCloseContractActivatedModal}
                        data-testId={"okayBtnTestId"}
                        >
                            {t("Okay")}
                        </Button>
                    </Box>
                </Box>
            </ActivateModalContainer>
        </Modal>
    }
   
    renderSaveContractModal = () => {
        const { t }: { t: TFunction } = this.props;
        return <Modal open={this.state.saveTemplateModal}>
            <ActivateModalContainer>
                <Box className="activateModalContainer">
                    <img src={Classified_CorrectIcon} alt="" />
                    <Typography className="modalHeading" data-testId={"saveTemplateModalHeadingTestID"}>{t("Save Contract Template")}</Typography>
                    <Typography className="modalSubHeading">{t("Your contract document will be saved as template. You can access this document from contracts section.")}</Typography>
                    <TextField variant="outlined" fullWidth className="nameTextField" placeholder="Template Name"/>
                    <Box className="modalBottomSection">
                        <Button variant="text" className="ContractDetailsButton1"
                        data-testId={"savedTemplateModalCancelBtnTestID"}
                        onClick={this.handleCloseSaveTemplateModal}>
                            {t("Cancel")}
                        </Button>
                        <Button variant="contained" className="ContractDetailsButton2" >
                        {t("Save")}
                        </Button>
                    </Box>
                </Box>
            </ActivateModalContainer>
        </Modal>
    }
    renderGenerateContractModal = () => {
        const { t }: { t: TFunction } = this.props;
        return <Modal open={this.state.generateContractModal}>
            <ActivateModalContainer>
                <Box className="activateModalContainer">
                    <img src={InfoIcon2} alt="" />
                    <Typography className="modalHeading" data-testId={"genrateContractModalHeading"}>{t("Contract Activated")}</Typography>
                    <Typography className="modalSubHeading">{t("By confirming the contract will be activated, the Tenant will be notified, and the system will be record it. Are you sure you want to Generate the report now?")}</Typography>
                    <Box className="modalBottomSection">
                        <Button variant="text" className="ContractDetailsButton1"
                        data-testId={"generatecontractCancelBtnTestID"}
                        onClick={this.handleCloseGenerateModal}>
                            {t("Cancel")}
                        </Button>
                        <Button variant="contained" className="ContractDetailsButton2"
                        data-testId={"confirmBtnTestId"}
                        onClick={this.handleOpenContractActivatedModal}>
                            {t("Confirm")}
                        </Button>
                    </Box>
                </Box>
            </ActivateModalContainer>
        </Modal>
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { t }: { t: TFunction } = this.props;
        return (
            <MainScreen>
                <ContactDetailsHeader>
                    <Box>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link href="/CompanyEmployee/LeaseManagment" className="ContactDetailsBreadcrumbs">
                            {t("Lease Management")}
                            </Link>
                            <Link href="/CompanyEmployee/IssueNewLease" className="ContactDetailsBreadcrumbs">
                                {t("Issue a new lease")}
                            </Link>
                            <Link href="/CompanyEmployee/IssueNewContract" className="ContactDetailsBreadcrumbs">
                                {t("Issue a new contract")}
                            </Link>
                            <Link className="ContactDetailsBreadcrumbsActive">{t("Review Contract")}</Link>
                        </Breadcrumbs>
                        <Typography className="ContactDetailsHeading" data-testId={"reviewContractTestID"}>
                            {t("Review Contract")}
                        </Typography>
                        <Paper elevation={2} className="ContactDetailsHeadingContainer">
                            <Box className="ContactDetailsHeadingBox">
                                <Typography className="ContactDetailsHeadingText">
                                    Contract 1
                                </Typography>
                                <Box>
                                    <img
                                        className="ContractDetailDownloadIconStyled"
                                        src={DownloadIcon}
                                        alt={DownloadIcon}
                                    />
                                </Box>
                            </Box>
                            <Divider />
                            <Box className="ContractDetailsContent">
                                <Grid container spacing={2}>
                                    <Grid item lg={3} xs={12} sm={6} md={4}  spacing={2}>
                                        <Box className="ContractDetailsChildReview">
                                            <img
                                                src={BuildingIcon}
                                                className="ContractDetailsChildImgReview"
                                            />
                                            <Box>
                                                <Typography className="ContractDetailsChildHeading">
                                                    {t("Complex")}
                                                </Typography>
                                                <Typography className="ContractDetailsChildSubHeadingReview">
                                                    {t("Complex Name")}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid  xs={12} sm={6} md={4} lg={3} item spacing={2}>
                                        <Box className="ContractDetailsChildReview">
                                            <img
                                                className="ContractDetailsChildImgReview"
                                                src={BuildingIcon}
                                            />
                                            <Box>
                                                <Typography className="ContractDetailsChildHeading">
                                                    {t("Building")}
                                                </Typography>
                                                <Typography className="ContractDetailsChildSubHeadingReview">
                                                    {t("Complex Name")}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item spacing={2} sm={6} md={4} lg={3} xs={12} >
                                        <Box className="ContractDetailsChildReview">
                                            <img
                                                src={UnitIcon}
                                                className="ContractDetailsChildImgReview"
                                            />
                                            <Box>
                                                <Typography className="ContractDetailsChildHeading">
                                                    {t("Unit")}
                                                </Typography>
                                                <Typography className="ContractDetailsChildSubHeadingReview">
                                                    {t("Complex Name")}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid  spacing={2} xs={12} sm={6} md={4} lg={3}item >
                                        <Box className="ContractDetailsChildReview">
                                            <img
                                                className="ContractDetailsChildImgReview"
                                                src={ProfileIcon}
                                            />
                                            <Box>
                                                <Typography className="ContractDetailsChildHeading">
                                                    {t("Owner")}
                                                </Typography>
                                                <Typography className="ContractDetailsChildSubHeadingReview">
                                                    {t("Complex Name")}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} sm={6} item spacing={2}  md={4} lg={3}>
                                        <Box className="ContractDetailsChildReview">
                                            <img
                                                className="ContractDetailsChildImgReview"
                                                src={ProfileIcon}
                                            />
                                            <Box>
                                                <Typography className="ContractDetailsChildHeading">
                                                    {t("Tenant")}
                                                </Typography>
                                                <Typography className="ContractDetailsChildSubHeadingReview">
                                                    {t("Complex Name")}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid  xs={12} sm={6} item spacing={2} md={4} lg={3}>
                                        <Box className="ContractDetailsChildReview">
                                            <img
                                                className="ContractDetailsChildImgReview"
                                                src={CalenderIcon}
                                            />
                                            <Box>
                                                <Typography className="ContractDetailsChildHeading">
                                                    {t("Contract Duration")}
                                                </Typography>
                                                <Typography className="ContractDetailsChildSubHeadingReview">
                                                    {"15-03-2022 14:21"}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid  spacing={2} xs={12} item sm={6} md={4} lg={3}>
                                        <Box className="ContractDetailsChildReview">
                                            <img
                                                className="ContractDetailsChildImgReview"
                                                src={CalenderIcon}
                                            />
                                            <Box>
                                                <Typography className="ContractDetailsChildHeading">
                                                    {t("Expiry Date")}
                                                </Typography>
                                                <Typography className="ContractDetailsChildSubHeadingReview">
                                                    {"15-03-2022 14:21"}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Box className="contractDetailContainer">
                                <Typography className="ContractDetailsContentHeading">
                                    Resident Rental Lease Agreement
                                </Typography>
                                <Typography className="ContractDetailsContentHeadingText">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                                    Exercitationem illo commodi, in itaque voluptas et repellat.
                                    Molestiae modi cupiditate quia repudiandae animi nesciunt at
                                    illum cum totam dolorum. Ipsum corrupti, ea tempora non, culpa
                                    itaque laborum dolorum totam quos doloribus, necessitatibus
                                    cumque. Dolorum numquam commodi suscipit quos quisquam, nam
                                    maxime excepturi officiis? Enim temporibus fuga eligendi harum
                                    repudiandae cumque culpa saepe recusandae illum mollitia
                                    laudantium excepturi magni quis nam voluptate officia in
                                    reiciendis iure exercitationem non odit corporis incidunt,
                                    consequatur inventore! Vel molestias mollitia non sapiente
                                    provident eaque iusto, nam debitis deserunt. Exercitationem
                                    culpa natus, sequi enim accusamus error aspernatur excepturi?
                                    Libero fuga nulla saepe modi, dolore eaque sunt facere cumque
                                    obcaecati, commodi ducimus, placeat aspernatur voluptatem
                                    reiciendis veniam ipsum doloribus possimus eius animi mollitia
                                    dolores fugit eligendi. Explicabo iure laboriosam officiis
                                    quis reiciendis sint qui deleniti accusamus officia omnis
                                    incidunt amet rem, voluptate nulla? Rem pariatur temporibus
                                    eaque amet vero, magnam obcaecati accusantium aspernatur culpa
                                    perspiciatis nihil harum perferendis maiores magni eveniet
                                    quisquam ad, totam ab iusto commodi. Sed distinctio numquam
                                    reprehenderit tempore in delectus sequi. Minima, tempore ipsam
                                    voluptatum iste error molestias incidunt dicta provident
                                    perferendis nulla, est necessitatibus officia atque quas
                                    inventore? Officia accusamus ab laboriosam, doloremque
                                    voluptas perferendis, quos nobis labore ea, dolorum libero.
                                    Cupiditate sit quaerat id fugit? Omnis, delectus nesciunt
                                    cupiditate, culpa optio repellendus aperiam sint dicta at
                                    sapiente reiciendis animi? Blanditiis eius doloremque autem
                                    sint aperiam omnis dignissimos labore ea tempora nulla,
                                    necessitatibus amet voluptatem repellat? Dicta sint beatae
                                    eius perspiciatis odio hic?
                                </Typography>
                            </Box>
                            <Box className="contractDetailContainer">
                                <Typography className="ContractDetailsContentHeading">
                                {t("Resident Rental Lease Agreement")}
                                </Typography>
                                <Typography className="ContractDetailsContentHeadingText">
                                    1. Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                    Pariatur, molestiae.
                                </Typography>
                                <Typography className="ContractDetailsContentHeadingText">
                                    2. Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                    Pariatur, molestiae.
                                </Typography>
                                <Typography className="ContractDetailsContentHeadingText">
                                    3. Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                    Pariatur, molestiae.
                                </Typography>
                            </Box>
                        </Paper>
                    </Box>
                    <Box className="ContractDetailsButtonGroup">
                        <Button variant="text" className="ContractDetailsButton1">
                        {t("save the contract as draft")}
                        </Button>
                        <Button variant="text" className="ContractDetailsButton1">
                        {t("Edit Document")}
                        </Button>
                        <Button variant="text" className="ContractDetailsButton1"
                        data-testId={"savedTemplateBtnTestID"}
                        onClick={this.handleOpenSaveTemplateModal}>
                            {t("save template")}
                        </Button>
                        <Button variant="contained" className="ContractDetailsButton2" onClick={this.handleOpenGenerateModal}
                        data-testId={"generateContractTestId"}
                        >
                            {t("Generate contract")}
                        </Button>
                    </Box>
                    {this.renderActivatdContractModal()}
                    {this.renderSaveContractModal()}
                    {this.renderGenerateContractModal()}
                </ContactDetailsHeader>
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(LeaseManagementIssueNewContractReview);
export { LeaseManagementIssueNewContractReview };
const ContactDetailsHeader = styled(Box)({
    padding: 30,
    background: "#f4f7ff",

    "& .ContactDetailsHeading": {
        fontWeight: 800,
        fontSize: "30px",
        padding: "20px 0px",

    },
    "& .ContactDetailsHeadingContainer": {
        background: "white",
    },
    "& .ContactDetailsBreadcrumbs": {
        color: "black",
    },
    "& .ContactDetailsBreadcrumbsActive": {
        color: "#5373bd !important"
    },
    "& .ContactDetailsHeadingBox": {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        marginBottom: 15,
        padding:"20px"
    },
    "& .ContactDetailsHeadingText": {
        fontSize: "25px",
        fontWeight: 800,
    },
    "& .ContractDetailDownloadIconStyled": {
        height: "30px",
        width: "33px",
        marginRight: 15,
    },
    "& .ContractDetailsButton1": {
        color: "#3274ce",
        fontWeight: 800,
        marginRight: 15,
        border: "1px solid #3274ce",
        backgroundColor:"#fff",
        padding:"10px 40px",
        cursor: "pointer",
    },
    "& .ContractDetailsButton2": {
        background: "#3274ce",
        color: "white",
        fontWeight: 800,
        cursor: "pointer",
    },
    "& .ContractDetailsContentHeading": {
        fontWeight: 800,
        marginTop: 30,
        marginBottom: 10,
    },
    "& .ContractDetailsContentHeadingText": {
        color: "gray",
    },
    "& .ContractDetailsButtonGroup": {
        marginTop: "20px",
        display: "flex",
        justifyContent: "flex-end",
        padding:"20px"
    },
    "& .ContractDetailPopover": {
        top: "300px",
        right: "700px",
        position: "absolute",
    },
    "& .ContractDetailsHeader": {
        color: "#fcd2ab",
        fontWeight: "700",
        marginLeft: 10,
    },
    "& .ContractDetailsHeader2": {
        marginLeft: 35,
        fontWeight: "bold",
    },
    "& .ContractDetailsContent": {
        display: "flex",
        flexWrap: "wrap",
        marginTop: 10,
        padding:"20px"
    },
    "& .ContractDetailsContentHeader": {
        display: "flex",
    },
    "& .ContractDetailsIcons": {
        width: 25,
        height: 25,
    },
    "& .ContractDetailsChildReview": {
        display: "flex",
        gap: "10px",
    },
    "& .ContractDetailsChildImgReview": {
        width: "30px",
        height: "30px",
    },
    "& .ContractDetailsChildHeading": {
        color: "#d9a582",
        fontSize: "17px",
    },
    "& .ContractDetailsChildSubHeadingReview": {
        color: "#2c2d32",
        fontSize: "19px",
        fontWeight: 900,
    },
    "& .contractDetailContainer":{
        padding:"20px"
    }
});

const ActivateModalContainer = styled(Box)({
    width: "100%",
    height: "100vh",
    overflow: "scroll",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .activateModalContainer": {
        borderRadius: "10px",
        backgroundColor: "#fff",
        padding: "50px",
        width: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "20px"
    },
    "& .modalHeading": {
        fontSize: "21px",
        fontWeight: 900,
        textAlign: "center",
        color: "#17171f"
    },
    "& .modalSubHeading": {
        fontSize: "14.5px",
        textAlign: "center",
        color: "#3d3e41"
    },
    "& .modalBottomSection": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "10px",
        width:"100%"
    },
    "& .ContractDetailsButton1": {
        color: "#3274ce",
        fontWeight: 800,
        border: "1px solid #3274ce",
        width:"180px",
        cursor: "pointer",
        height:"50px"
    },
    "& .ContractDetailsButton2": {
        background: "#3274ce",
        color: "white",
        fontWeight: 800,
        cursor: "pointer",
        width:"180px",
        height:"50px"
    },
    "& .MuiTextField-root":{
        backgroundColor:"#f9f9f9 !important",
        borderRadius:"10px",
        border:"1px solid #f0efef"
    },
    '& .MuiTextField-root::placeholder': {
        color: 'green',
      },
    "& activateContractBtn":{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        width:"100%"
    }
    
     
})

// Customizable Area End