import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { userProfile ,blanckImg} from "../assets";
import {
    Box,
    Button,
    Grid,
} from "@material-ui/core";


export interface Props {
    navigation: any;
    id: string;
    companyId:any;
    t?: any
    i18n?: any
    match?: any
    location?: any
    // Customizable Area Start
    history?: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    selectedImage: null,
    previewImage: string,
    imagesComplex: any,
    imagesBuilding: any,
    selectedFile: any,
    selectedFileBuliding: null,
    selectedFilePdfComplex: any,
    currencyList:any,
    timezoneList:any,
    measurementList:any,
    formulaList:any,
    countriesList:any,
    leasePopup:any,
    createConfirmModal:any,
    selectedFilePdfGuidelinesComplex: any,
    selectedFilePdfFloorPlanBuilding: any,
    imageURLComplex: string,
    imageURLBuilding: string,
    selectPdf: string,
    img: string,
    accountType:string,
    stepBuilding: number,
    countrySelected: any,
    currencySelected:any,
    timezoneSelected:any,
    measurementSelected:any,
    formulaSelected:any,
    multiPleButtonComplex: any
    selectedFilePdfRolesComplex: any
    deleteMemberPopupBuilding:any
    selectedFilePdfResolutionsComplex: any
    selectedFilePdfBuildingPlansComplex: any
    sampleData: any,
    buildingValues:any,
    locationValues:any,
    page: number,
    rowsPerPage: number,
    isPopupOpen: any,
    ownerPopup:any,
    findPopupOpen: any,
    jurisdictionList:any,
    jurisdictionSelected:any,
    facilityPopupBuilding: any,
    facilityValues:any,
    imagesSharedFacilityBuilding: any,
    coreMembers: any,
    createNewMember: any
    variants: any,
    selectedVariants: any
    coreMemberManager: any
    open: any
    openBuilding: any,
    leaseList:any,
    leaseSelected:any,
    rentalSelected:any,
    savedFacility:any,
    unitValues:any,
    ownerValues:any,
    unitRecords:any,
    memberValues:any,
    savedMemberBuilding:any,
    totalAreaUnit:any,
    userList:any,
    roleList:any,
    userValues:any,
    deleteFacilityPopup:any,
    deleteFacilityIndex:any,
    accountManagerListBuilding:any,
    deleteMemberIndexBuilding:any,
    buildingId:any;
    societyId:any;
    buildingList:any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CompEmpBuildingBlockController extends CommonApiCallForBlockComponent<Props, S, SS> {
    exampleAPICallId: string = "";
    GetJurisdictionListBuilding: any = "";
    GetCurrencyListBuilding: any = "";
    GetTimezoneList:any = "";
    GetMeasurementList:any = "";
    GetFormulaList:any = "";
    GetCountryList: any = "";
    GetLease:any="";
    GetUserList:any="";
    GetRoleList:any = "";
    GetAccountManagerListBuilding:any="";
    SaveBuilding:any = "";
    addJBasicDetailId:any;
    addLocationId:any;
    addUnitsApiCall:any;
    addSharedApiId:any;
    getUploadDocumentListId:any;
    getCoreMember:any;
    addCoreMemberApiId:any;
    GetBuildingList:any;

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];
        this.receive = this.receive.bind(this);
        this.state = {
            // Customizable Area Start
            coreMembers: false,
            createNewMember: false,
            loading: false,
            accountType:'',
            sampleData: [
                {
                    id: 1,
                    UnitNumber: "A503",
                    FloorNumber: 1,
                    Configuration: "5 BHK",
                    Owner: "Anaru Grootoonk",
                    ResidentName: "Andries  Hakopa",
                },
                {
                    id: 2,
                    UnitNumber: "A506",
                    FloorNumber: 15,
                    Configuration: "5 BHK",
                    Owner: "Florieke Krebber",
                    ResidentName: "Beatriz Brito",
                },
                {
                    id: 3,
                    UnitNumber: "A504",
                    FloorNumber: 85,
                    Configuration: "7 BHK",
                    Owner: "Gabriel Soares",
                    ResidentName: "Sakane Gabriel",
                },
                {
                    id: 4,
                    UnitNumber: "A505",
                    FloorNumber: 79,
                    Configuration: "2 BHK",
                    Owner: "Miriam Jes�s",
                    ResidentName: "Sakane Miikoes",
                },
                {
                    id: 5,
                    UnitNumber: "A506",
                    FloorNumber: 51,
                    Configuration: "10 BHK",
                    Owner: "Mikko Karbashewski",
                    ResidentName: "Miiko Slavcho",
                },
                {
                    id: 6,
                    UnitNumber: "A507",
                    FloorNumber: 12,
                    Configuration: "9 BHK",
                    Owner: "Somun AeRi",
                    ResidentName: "Sakane Ston",
                },
                {
                    id: 7,
                    UnitNumber: "A508",
                    FloorNumber: 109,
                    Configuration: "12 BHK",
                    Owner: "Andries man",
                    ResidentName: "hulk",
                },
                {
                    id: 8,
                    UnitNumber: "A509",
                    FloorNumber: 44,
                    Configuration: "6 BHK",
                    Owner: "Motish Davis ",
                    ResidentName: "Towan vill",
                },
                {
                    id: 9,
                    UnitNumber: "A510",
                    FloorNumber: 19,
                    Configuration: "9 BHK",
                    Owner: "Miiko West",
                    ResidentName: "Milanio stan",
                },
                {
                    id: 10,
                    UnitNumber: "A509",
                    FloorNumber: 29,
                    Configuration: "4 BHK",
                    Owner: "Sarah Kist ",
                    ResidentName: "Rivera New",
                },
                {
                    id: 11,
                    UnitNumber: "A511",
                    FloorNumber: 19,
                    Configuration: "2 BHK",
                    Owner: "Grootoonk Ban",
                    ResidentName: "Swapn Vill",
                },
                {
                    id: 12,
                    UnitNumber: "A512",
                    FloorNumber: 26,
                    Configuration: "5 BHK",
                    Owner: "Grootoonk",
                    ResidentName: "Sakane Andries",
                },
            ],
            selectedFile: [],
            selectedFileBuliding: null,
            selectedFilePdfFloorPlanBuilding: [],
            selectedImage: null,
            previewImage: "",
            imagesComplex: [],
            imagesBuilding: [],
            selectedFilePdfComplex: [],
            coreMemberManager: [
                { id: 0, label: "Slavcho Vice Chairman", name: "Marleah Slavcho", position: "Vice Chairman" },
                { id: 1, label: "Manager", name: "mark alson", position: "Vice " },
                { id: 2, label: "Vice", name: "Marleah Eagleston", position: "Vice Chairman" },
                { id: 3, label: "Real Estate Developer", name: "Marleah Slavcho Eagleston", position: "Vice admin" },
                { id: 4, label: "Vice Slavcho Chairman ", name: "Archer mon", position: "Vice Chairman" },
                { id: 5, label: "Vice Chairman", name: "stan stak", position: "Vice" },
                { id: 6, label: "Auditor", name: "Slavcho Marleah Eagleston", position: "Vice Chairman" }
            ],
            selectedFilePdfGuidelinesComplex: [],
            selectedFilePdfRolesComplex: [],
            selectedFilePdfResolutionsComplex: [],
            selectedFilePdfBuildingPlansComplex: [],
            leaseList:[],
            leaseSelected:'',
            rentalSelected:'',
            page: 0,
            unitRecords:[],
            currencyList:[],
            timezoneList:[],
            userList:[],
            roleList:[],
            userValues:{
                userName:'',
                role:''
            },
            leasePopup: false,
            createConfirmModal:false,
            measurementList:[],
            formulaList:[],
            countriesList:[],
            currencySelected:'',
            timezoneSelected:'',
            measurementSelected:'',
            countrySelected:'',
            formulaSelected:'',
            rowsPerPage: 10,
            isPopupOpen: false,
            ownerPopup: false,
            imagesSharedFacilityBuilding: [],
            imageURLComplex: '',
            multiPleButtonComplex: ['Jurisdiction', 'Basic Details', 'Documents', 'Location', 'Units', 'Shared Area', 'Core Members'],
            imageURLBuilding: '',
            selectPdf: '',
            img: userProfile,
            totalAreaUnit:'Sqft',
            selectedVariants: [],
            buildingValues:{
                buildingName: '',
                totalArea: '',
                totalFloors: '',
                totalUnits: '',
                aboutUs:'',
                currency: '',
                timezone: '',
                formula: '',
                measurement: '',
                language: '',
                logo:null,
                images: [],
                accountManager:''
            },
            accountManagerListBuilding:[],
            locationValues:{
                locationLink: '',
                country: '',
                state: '',
                city: '',
                zipCode: '',
                landmark: '',
                streetName: '',
                address:'',
            },
            facilityValues:{
                Details: '',
                sharedFacility: '',
                totalArea: '',
                fees: '',
                images:[],
                floorDocument:null,
            },
            unitValues:{
                UnitNumber: '',
                FloorNumber: '',
                ResidentName: '',
                OwnerName:'',
                Configuration: '',
                Size: '',
               PurchasePrice:'',
               PurchaseDate:'',
               CurrentValuation:''
            },
            ownerValues:{
                id:'',
                passport:'',
                email:'',
                phone:''
            },
            savedFacility:[],
            memberValues:{
                email:'',
                phone:'',
                name:'',
                role:'',
                buildingName:'',
                unit:''
            },
            savedMemberBuilding:[],
            stepBuilding: 0,
            findPopupOpen: false,
            jurisdictionList: [],
            jurisdictionSelected: '',
            facilityPopupBuilding: false,
            open: false,
            openBuilding: false,
            deleteFacilityIndex:null,
            deleteMemberIndexBuilding:null,
            deleteFacilityPopup:false,
            deleteMemberPopupBuilding: false,
            variants: [
                { id: 0, name: 'User Name' },
                {
                    id: 3,
                    name: 'mark Slavcho',
                },
                {
                    id: 1,
                    name: 'Slavcho',
                },
                {
                    id: 2,
                    name: 'Key',
                },
                {
                    id: 10,
                    name: 'Other mark Slavcho',
                },
                {
                    id: 12,
                    name: 'Nintendo',
                },
                {
                    id: 11,
                    name: 'Xbox',
                },
            ],
            buildingId:null,
            societyId:null,
            buildingList:[],
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount(): Promise<void> {
        // Customizable Area Start

        const accountRole = localStorage.getItem("role")

        if (accountRole) {
            const accountRoleName = accountRole.replace(/"/g, '')
            this.setState({ accountType:  accountRoleName})
        }

        await this.getJurisdictionList();
        // Customizable Area End
    }

    async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
        if (this.state.stepBuilding !== prevState.stepBuilding) {
            if (this.state.stepBuilding === 1) {
                if (this.state.accountType === 'coo') {
                    await this.getAccountManagerListBuilding()
                }
               await this.getCurrencyList();
               await this.getFormulaList();
               await this.getMeasurementList();
               await this.getTimezoneList();
               await this.getLease()
            }
            if (this.state.stepBuilding === 3) {
                await this.getCountryList();
            }
            if (this.state.stepBuilding === 6) {
                await this.getUserListBuilding();
                await this.getRoleListBuilding()
                await this.getBuildingList()
            }
        }
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
    
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
          let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
          switch (apiRequestCallId) {
            case this.GetJurisdictionListBuilding:
              this.handleJurisdictionList(responseJson);
              break;
            case this.GetCurrencyListBuilding:
              this.handleCurrencyList(responseJson);
              break;
            case this.GetMeasurementList:
                this.handleMeasurementList(responseJson);
                break;
            case this.GetFormulaList:
                this.handleFormulaList(responseJson);
                break;
            case this.GetTimezoneList:
                this.handleTimezoneList(responseJson);
                break;
            case this.GetCountryList:
                this.handleCountriesList(responseJson);
                break;
            case this.SaveBuilding:
                this.handleSaveBuilding(responseJson);
                break;
            case this.GetLease:
                this.handleLeaseListBuilding(responseJson);
                break;
            case this.GetUserList:
                this.handleUserListBuilding(responseJson);
                break;
            case this.GetRoleList:
                this.handleRoleListBuilding(responseJson);
                break;
            case this.GetAccountManagerListBuilding:
                this.handleAccountManagerListBuilding(responseJson);
                break;
            case this.addJBasicDetailId:
                return this.handleBasicSucc(responseJson)
            case this.addLocationId:
                return this.handleLocationSucc(responseJson)
            case this.addUnitsApiCall:
                return this.handleUnitSucc(responseJson)
            case this.addSharedApiId:
                return this.handleSharedSucc(responseJson)
            case this.getUploadDocumentListId:
                return this.handleDocumentSucc(responseJson)
            case this.getCoreMember:
                return this.handleCoreSucc(responseJson)
            case this.addCoreMemberApiId:
                return this.handleCoreMemberSucc(responseJson)
            case this.GetBuildingList:
                return this.handleBuildingListSucc(responseJson)
            default:
              break;
          }
        
          this.setState({loading:false})
    
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
          } else {
            ApiErrorResponse(responseJson);
          }
          ApiCatchErrorResponse(errorResponse);
        }
        // Customizable Area End
      }

      handleOpenDeleteFacilityPopup = (i:any) => {
        this.setState({
            deleteFacilityPopup: true,
            deleteFacilityIndex: i
        })
    }

    handleCloseDeleteFacilityPopup = () => {
        this.setState({
            deleteFacilityPopup: false
        })
    }

    handleChangeCurrency = (e: any) => {
        this.setState({ currencySelected: e.target.value });
    }

    handleChangeTimezone = (e: any) => {
        this.setState({ timezoneSelected: e.target.value });
    }

    handleOwnerPopup = () => {
        this.setState((prevState) => ({
            ownerPopup: !prevState.ownerPopup
        }))
    }

    handleChangeFormula = (e:any) => {
        this.setState({formulaSelected: e.target.value})
    }

    handleCountriesList = (responseJson: any) => {
        if (responseJson?.country_list) {
            this.setState({countriesList: responseJson.country_list})
        }
      };

    handleChangeMeasurement = (e:any) => {
        this.setState({measurementSelected: e.target.value})
    }

    handleSaveBuilding = (responseJson:any) => {
        if (responseJson.data) {
            this.setState({createConfirmModal: true})
        }
    }

    handleCurrencyList = (responseJson:any) => {
        if (responseJson?.currency_list.length) {
            this.setState({currencyList: responseJson.currency_list})
        }
    }

    handleTimezoneList = (responseJson:any) => {
        if (responseJson?.timezones.length) {
            this.setState({timezoneList: responseJson.timezones})
        }
    }

    handleMeasurementList = (responseJson:any) => {
        if (responseJson?.list_of_measurement.length) {
            this.setState({measurementList: responseJson.list_of_measurement})
        }
    }

    handleLeaseListBuilding = (responseJson:any) => {
        if (responseJson.data) {
            this.setState({leaseList: responseJson.data, rentalSelected: responseJson.data[0].attributes.id})
        }
    }

    handleUserListBuilding = (responseJson:any) => {
        if(responseJson?.status.length) {
            this.setState({userList: responseJson?.status})
        }
    }

    handleRoleListBuilding = (responseJson:any) => {
        if(responseJson?.data.length) {

           let status= responseJson?.data.map((data:any)=>({
            "label" : data.attributes.name,
            "value" : data.id
           }
            
            ))
               
            
            this.setState({roleList: status})
        }
    }

    handleOpenLeasePopupBuilding = (index:number) => {
        this.setState({leasePopup: true, leaseSelected:index})
    }

    handleCloseLeasePopupBuilding = () => {
        this.setState({leasePopup: false})
    }

    handleSelectLeaseBuilding = (id:any) => {
        this.setState({rentalSelected: id, leasePopup: false})
    }

    handleFormulaList = (responseJson:any) => {
        if (responseJson?.list_of_formula.length) {
            this.setState({formulaList: responseJson.list_of_formula})
        }
    }

    handleAccountManagerListBuilding = (responseJson:any) => {
        if (responseJson.account_managers) {
            this.setState({accountManagerListBuilding: responseJson.account_managers})
          }
      }
      handleBasicSucc=(responseJson:any)=>{
        if(responseJson.data){
            this.setState({buildingId:responseJson.data.attributes.is_building_list[0].id,societyId:responseJson.data.id,stepBuilding:2})
            // this.handleNextBuilding()
        }
      }
      handleLocationSucc=(responseJson:any)=>{
        if(responseJson.data){
            // this.handleNextBuilding()
            this.setState({stepBuilding:4})
        }
      }
      handleUnitSucc=(responseJson:any)=>{
        this.setState({stepBuilding:5})
      }
      handleSharedSucc=(responseJson:any)=>{
        this.setState({stepBuilding:6})
      }
      handleDocumentSucc=(responseJson:any)=>{
        this.setState({stepBuilding:3})
      }
      handleCoreSucc=(responseJson:any)=>{
        this.setState({savedMemberBuilding:[...this.state.savedMemberBuilding,responseJson.data]})
        
        
      }
      handleCoreMemberSucc=(responseJson:any)=>{
        this.setState({ memberValues:{
            email:'',
            phone:'',
            name:'',
            role:'',
            buildingName:'',
            unit:''
        },})
      }
      handleBuildingListSucc=(responseJson:any)=>{
      
        this.setState({buildingList:responseJson.buildings})
      }
    handleNextBuilding = () => {
        this.setState({ stepBuilding: this.state.stepBuilding + 1 });
    };

    handleJurisdictionList = (responseJson:any) => {
        if (responseJson?.jurisdictions?.data.length) {
            this.setState({jurisdictionList: responseJson.jurisdictions.data})
        }
    }

    handleCountryChange = (e:any) => {
        this.setState({countrySelected: e.target.value})
    }

    handleRemoveFacilityDetails = (index:number) => {
        const facilityArr = [...this.state.savedFacility]
        facilityArr.splice(index, 1)
        this.setState({savedFacility: facilityArr, deleteFacilityPopup:false})
    }

    handleRemoveMemberBuilding = (index:number) => {
        const memberArr = [...this.state.savedFacility]
        memberArr.splice(index, 1)
        this.setState({savedMemberBuilding: memberArr, deleteMemberPopupBuilding:false})
    }

    handleOpenDeleteMemberPopup = (i:any) => {
        this.setState({
            deleteMemberPopupBuilding: true,
            deleteMemberIndexBuilding: i
        })
    }

    handleCloseDeleteMemberPopupBuilding = () => {
        this.setState({
            deleteMemberPopupBuilding: false
        })
    }

    handleBackBuilding = () => {
        this.setState({ stepBuilding: this.state.stepBuilding - 1 });
    };

    handleSharedDetails = (values: any) => {
        let error=false

        if(!error) {
            const savedFacilityObj = {
                ...values
            }

            this.setState({facilityValues: values, savedFacility: [...this.state.savedFacility, savedFacilityObj]}, () => {
                this.handleSaveSharedFacility()
                this.addSharedFacility(values)
            })
        }
    }

    handleMemberDetailsBuilding = (values:any) => {
        if (values) {
            const savedMember = {
                ...values
            }
    
            this.setState({savedMemberBuilding: [...this.state.savedMemberBuilding, savedMember]}, () => {
                this.sharedCreateNewMember()
                this.sharedCoreMembers()
                this.addCoreMember(values)
            })
        }
    } 

    handleSaveSharedFacility = () => {
        this.setState({facilityPopupBuilding: false})
    }

    handleFieldChange = (fieldName:any, value:any) => {
        this.setState((prevState:any) => ({
            buildingValues: {
                ...prevState.buildingValues,
                [fieldName]: value
            }
        }))
    }

    handleLocationFieldChange = (fieldName:any, value:any) => {
        this.setState((prevState:any) => ({
            locationValues: {
                ...prevState.locationValues,
                [fieldName]: value
            }
        }))
    }

    getLease = async () => {
        this.GetLease = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_contract/lease_templates`,
          });
    }

    getUserListBuilding = async () => {
        this.GetUserList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_my_team/team_members/user_name_list`,
          });
    }

    getRoleListBuilding = async () => {
        this.GetRoleList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_roles_permissions/roles`,
          });
    }
    getBuildingList=async()=>{
        this.GetBuildingList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_announcement/announcements/building_list?society_management_id=${this.state.societyId}`,
          });
    }
    addSharedFacility=async(facility:any)=>{
        const data = new FormData();
       
            data.append(`common_area[name]`, facility.sharedFacility)
            data.append(`common_area[details]`, facility.Details)
            data.append(`common_area[total_area]`, facility.totalArea)
            data.append(`common_area[reservation_fee]`, facility.fees)
            data.append(`common_area[floor_plan]`, facility.floorDocument)
            data.append('common_area[society_management_id]',this.state.societyId)
            facility.images.length && facility.images.forEach((photo:any) => {
                data.append(`common_area[photos][]`, photo)
            
        })
  
    this.addSharedApiId = await apiCall({
      body:data,
      method: "POST",
      endPoint: `/bx_block_society_management/common_areas`,
    });
    }

    addCoreMember=async(values:any)=>{
        
        const data = new FormData();
        data.append(`team_member[society_management_id]`, this.state.societyId)
            data.append(`team_member[role_id]`, values.role.value)
            data.append(`team_member[name]`,values.name)
            data.append(`team_member[email]`, values.email)
           
            data.append(`team_member[phone_number]`, values.phone)
            data.append(`team_member[unit]`, values.unit)
            data.append(`team_member[account_type]`, "true")
            data.append('team_member[building_management_id]', values.buildingName.id)
            this.addCoreMemberApiId = await apiCall({
                body:data,
                method: "POST",
                endPoint: `/bx_block_my_team/team_members/create_team_member`,
              });
        
    }
    handleBasicDetailsData=async(values:any)=>{

        
        // this.setState({ unitRecords: [...this.state.unitRecords,  values] }, () => {
           
            this.handleBasicUserList(values)
        // })
        

    }
    handleBasicUserList=async(values:any)=>{
        this.sharedCoreMembers()
        this.getCoreMember = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_my_team/company_employee/employee_team_members/employee_details?employee_id=${values.userName.id}`,
          });
    }
    getAccountManagerListBuilding = async () => {
        this.GetAccountManagerListBuilding = await apiCall({
          contentType: "application/json",
          method: "GET",
          endPoint: '/bx_block_real_estate_companies/account_manager_list',
        });
      }
      uploadDocuments=async()=>{
        const data = new FormData();

        data.append("policy",this.state.selectedFilePdfComplex );
        data.append("roles",this.state.selectedFilePdfRolesComplex);
       
        data.append("building_plan", this.state.selectedFilePdfBuildingPlansComplex);
        data.append("guideline", this.state.selectedFilePdfGuidelinesComplex);
        data.append("resolution", this.state.selectedFilePdfResolutionsComplex);
      
          this.getUploadDocumentListId = await apiCall({
            
            method: "POST",
            endPoint: `/society_managements/67/bx_block_my_document/upload_document`,
            body:data
          });
      }
      handleLocationAdd=async(values:any)=>{
        const data = new FormData();
        data.append('address[country]', this.state.locationValues.country)
        data.append('address[locationlink]', this.state.locationValues.locationLink)
      
        data.append('address[zipcode]', this.state.locationValues.zipCode)
        data.append('address[name]', this.state.locationValues.streetName)
        data.append('address[landmark]', this.state.locationValues.landmark)
        // data.append('society_management[address_attributes][address]', `${this.state.locationValues.streetName}, ${this.state.locationValues.landmark}, ${this.state.locationValues.city}, ${this.state.locationValues.state}, ${this.state.locationValues.country} - ${this.state.locationValues.zipCode}`)
        data.append('address[state]', this.state.locationValues.state)
        data.append('address[city]', this.state.locationValues.city)
        data.append('address[address]', "Add")
        data.append('address[society_management_id]', this.state.societyId)
        this.addLocationId = await apiCall({
     
            method: "POST",
            endPoint: `/bx_block_address/create_address`,
            body:data
          });
      }
    addBasicDetails=async()=>{
        const data = new FormData();
        data.append('society_management[name]', this.state.buildingValues.buildingName)
        data.append('society_management[description]', this.state.buildingValues.aboutUs)
        data.append('society_management[complex_area]', this.state.buildingValues.totalArea)
        data.append('society_management[total_floor]', this.state.buildingValues.totalFloors)
        data.append('society_management[total_units]', this.state.buildingValues.totalUnits)
       
        data.append('society_management[language]', this.state.buildingValues.language)
        data.append('society_management[account_manger_id]', this.state.buildingValues.accountManager)
       
        data.append('society_management[logo]', this.state.buildingValues.logo)
       
        this.state.buildingValues.images.length && this.state.buildingValues.images.forEach((image:any) => {
            data.append('society_management[photos][]', image)
        })
        data.append('jurisdiction_id', this.state.jurisdictionSelected.attributes.id)
        this.addJBasicDetailId = await apiCall({
     
            method: "POST",
            endPoint: `/bx_block_settings/building_managements/create_individual_building`,
            body:data
          });
    }
    addUnitsId=async(unit:any)=>{
       
        const formData = new FormData();
       
            formData.append(`apartment_management[floor_number][]`, unit.FloorNumber)
            formData.append('apartment_management[apartment_name]',unit.UnitNumber)
            formData.append(`apartment_management[configuration][]`, unit.Configuration)
            formData.append(`apartment_management[size][]`, unit.Size)
            formData.append(`apartment_management[purchase_price][]`, unit.PurchasePrice)
            formData.append(`apartment_management[purchase_date][]`, unit.PurchaseDate)
            formData.append(`apartment_management[current_valuation][]`, unit.CurrentValuation)
            // formData.append('apartment_management[society_management_id]','13')
            formData.append('apartment_management[resident_name]',unit.ResidentName)
            formData.append('apartment_management[owner_name]',unit.ownerName)
            formData.append('apartment_management[building_management_id]',this.state.buildingId)
            formData.append('apartment_management[society_management_id]',this.state.societyId)
        
        this.addUnitsApiCall = await apiCall({
     
            method: "POST",
            endPoint: `/bx_block_settings/apartment_managements`,
            body:formData
          });
    }

    handleSave = async () => {
        const formData = new FormData();
        
        // step 1 data
        formData.append('jurisdiction_id', this.state.jurisdictionSelected.attributes.id)

        if (this.props.companyId) {
            formData.append('society_management[real_estate_company_id]', this.props.companyId)
        }

        // step 2 data
        formData.append('society_management[name]', this.state.buildingValues.buildingName)
        formData.append('society_management[description]', this.state.buildingValues.aboutUs)
        formData.append('society_management[complex_area]', this.state.buildingValues.totalArea)

        //@ts-ignore
        formData.append('society_management[logo]', this.state.buildingValues.logo)
        
        this.state.buildingValues.images.forEach((image:any) => {
            formData.append('society_management[photos][]', image)
        })

        // step 3 data
        

        // step 4 data
        formData.append('society_management[address_attributes][country]', this.state.locationValues.country)
        formData.append('society_management[address_attributes][address]', `${this.state.locationValues.streetName}, ${this.state.locationValues.landmark}, ${this.state.locationValues.city}, ${this.state.locationValues.state}, ${this.state.locationValues.country} - ${this.state.locationValues.zipCode}`)
        formData.append('society_management[address_attributes][state]', this.state.locationValues.state)
        formData.append('society_management[address_attributes][city]', this.state.locationValues.city)
        formData.append('society_management[address_attributes][currency_id]', '1')

        // step 5 data
        this.state.unitRecords.length && this.state.unitRecords.forEach((unit:any, i: number) =>{   
            formData.append(`society_management[apartment_managements_attributes][floor_number][]`, unit.FloorNumber)
            formData.append(`society_management[apartment_managements_attributes][configuration][]`, unit.Configuration)
            formData.append(`society_management[apartment_managements_attributes][size][]`, unit.Size)
            formData.append(`society_management[apartment_managements_attributes][purchase_price][]`, unit.PurchasePrice)
            formData.append(`society_management[apartment_managements_attributes][purchase_date][]`, unit.PurchaseDate)
            formData.append(`society_management[apartment_managements_attributes][current_valuation][]`, unit.CurrentValuation)
        })

        // step 6 data
        this.state.savedFacility.length && this.state.savedFacility.forEach((facility:any, i:number) => {
            formData.append(`society_management[common_areas_attributes][${i}][name]`, facility.sharedFacility)
            formData.append(`society_management[common_areas_attributes][${i}][details]`, facility.Details)
            formData.append(`society_management[common_areas_attributes][${i}][total_area]`, facility.totalArea)
            formData.append(`society_management[common_areas_attributes][${i}][reservation_fee]`, facility.fees)
            formData.append(`society_management[common_areas_attributes][${i}][currency_id]`, '1')
        })

        
        this.setState({createConfirmModal:true})
    };
    handleTotalAreaUnit=(e:any) => {
        this.setState({totalAreaUnit: e.target.value})
    }

    exampleAPICall = async () => {
        this.exampleAPICallId = await this.apiCallForFileUpload({
            method: "POST",
            endPoint: `endPoint`,
            body: { data: {} }
        });
    }

    getJurisdictionList = async () => {
        this.GetJurisdictionListBuilding = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_society_management/company_employee/jurisdictions`,
          });
    }

    getCountryList = async () => {
        this.GetCountryList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_real_estate_companies/list_country_name`,
          });
    }

    getCurrencyList = async () => {
        this.GetCurrencyListBuilding = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_society_management/company_employee/list_of_currency`,
          });
    }

    getTimezoneList = async () => {
        this.GetTimezoneList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_society_management/company_employee/list_of_timezone`,
          });
    }

    getMeasurementList = async () => {
        this.GetMeasurementList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_society_management/company_employee/list_of_measurement`,
          });   
    }

    getFormulaList = async () => {
        this.GetFormulaList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_society_management/company_employee/list_of_formula`,
          });  
    }

    handleImageChangeBuilding = (event: any) => {
        const inputPicherPortableBuildings = document.createElement('input');
        inputPicherPortableBuildings.type = 'file';
        inputPicherPortableBuildings.accept = "image/png, image/jpeg, iamge/jpg,  iamge/jpg2"
        inputPicherPortableBuildings.multiple = true
        inputPicherPortableBuildings.click();
        inputPicherPortableBuildings.onchange = (event: any) => {
            this.PicherPortableBuilding(event)
        }
    };

    handleCloseCreateModal = () => {
        this.setState({createConfirmModal: false}, () => {
            this.props.navigation?.history?.push('/CompanyEmployee/complexlisting')
        })
      }

    handleBasicDetails = (values: any) => {
        let error = false
        if(!error) {
            this.setState({buildingValues: values}, () => {
                // this.handleNextBuilding()
                this.addBasicDetails()
            })
        }
    }

    handleUnitDetails = (values: any) => {
       
        this.setState({ unitRecords: [...this.state.unitRecords,  values] }, () => {
            this.togglePopupBuilding()
            this.addUnitsId(values)
        })
    }

    PicherPortableBuilding = (event: any) => {
        const uplodeFilesBuilding = Array.from(event.target.files);
        uplodeFilesBuilding.forEach(async (file: any) => {
            if (file.size < 2000000) {
                const base64 = await this.convertBase64(file)
                this.setState({
                    imagesBuilding: [...this.state.imagesBuilding, base64],
                })
            }
        })
    }

    convertBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    handleLocationDetails = (values:any) => {
        this.setState({locationValues: values}, () => {
            // this.handleNextBuilding()
            this.handleLocationAdd(values)
        })
    }

    picherPortablesComplex = (event: any) => {
        const fileBuilding = event.target.files[0];
        if (fileBuilding) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                this.setState({
                    selectedFile: fileBuilding,
                    imageURLComplex: event.target.result,
                });
            };
            reader.readAsDataURL(fileBuilding);
        }
    }

    handleFilePortableComplex = (event: any) => {
        const file = event.target.files[0];
        const imagesArrays = [...this.state.selectedFilePdfComplex];
        imagesArrays.push(file);
        this.setState({ selectedFilePdfComplex: imagesArrays });
    }

    handleRemoveFileBuilding = (fileIndex: any) => {
        const updatedFilesComplex = this.state.selectedFilePdfComplex.filter((value: any, index: any) => index !== fileIndex);
        this.setState({ selectedFilePdfComplex: updatedFilesComplex });
    };

    handleFileChangesBuilding = (event: any) => {
        const inputPicherPortableComplex = document.createElement('input');
        inputPicherPortableComplex.type = 'file';
        inputPicherPortableComplex.accept = ".pdf, .doc, .docx"
        inputPicherPortableComplex.multiple = true
        inputPicherPortableComplex.click();
        inputPicherPortableComplex.onchange = (event: any) => {
            this.handleFilePortableComplex(event)
        }
    };

    handleFileChangesGuidelinesBuilding = (event: any) => {
        const inputPicherGuidelinesBuilding = document.createElement('input');
        inputPicherGuidelinesBuilding.type = 'file';
        inputPicherGuidelinesBuilding.accept = ".pdf, .doc, .docx"
        inputPicherGuidelinesBuilding.multiple = true
        inputPicherGuidelinesBuilding.click();
        inputPicherGuidelinesBuilding.onchange = (event: any) => {
            this.handleFileGuidelinesComplex(event)
        }
    };

    handleFileGuidelinesComplex = (event: any) => {
        const GuidelinesFileBuilding = event.target.files[0];
        const imagesArraysGuidelinesBuilding = [...this.state.selectedFilePdfGuidelinesComplex];
        imagesArraysGuidelinesBuilding.push(GuidelinesFileBuilding);
        this.setState({ selectedFilePdfGuidelinesComplex: imagesArraysGuidelinesBuilding });
    }

    handleRemoveFileGuidelinesBuilding = (fileIndex: any) => {
        const updatedFilesGuidelines = this.state.selectedFilePdfGuidelinesComplex.filter((value: any, index: any) => index !== fileIndex);
        this.setState({ selectedFilePdfGuidelinesComplex: updatedFilesGuidelines });
    };

    handleFileChangesRolesBuilding = (event: any) => {
        const inputPicherPortableRolesBuilding = document.createElement('input');
        inputPicherPortableRolesBuilding.type = 'file';
        inputPicherPortableRolesBuilding.accept = ".pdf, .doc, .docx"
        inputPicherPortableRolesBuilding.multiple = true
        inputPicherPortableRolesBuilding.click();
        inputPicherPortableRolesBuilding.onchange = (event: any) => {
            this.handleFileRolesComplex(event)
        }
    };

    handleFileRolesComplex = (event: any) => {
        const RolesFileBuilding = event.target.files[0];
        const imagesArraysRolesBuilding = [...this.state.selectedFilePdfRolesComplex];
        imagesArraysRolesBuilding.push(RolesFileBuilding);
        this.setState({ selectedFilePdfRolesComplex: imagesArraysRolesBuilding });
    }

    handleFileChangesResolutionsBuilding = (event: any) => {
        const inputPicherPortableResolutionsComplex = document.createElement('input');
        inputPicherPortableResolutionsComplex.type = 'file';
        inputPicherPortableResolutionsComplex.accept = ".pdf, .doc, .docx"
        inputPicherPortableResolutionsComplex.multiple = true
        inputPicherPortableResolutionsComplex.click();
        inputPicherPortableResolutionsComplex.onchange = (event: any) => {
            this.handleFileResolutionsComplex(event)
        }
    };

    handleRemoveFileRolesBuilding = (fileIndex: any) => {
        const updatedFilesRoles = this.state.selectedFilePdfRolesComplex.filter((value: any, index: any) => index !== fileIndex);
        this.setState({ selectedFilePdfRolesComplex: updatedFilesRoles });
    };

    handleFileResolutionsComplex = (event: any) => {
        const ResolutionsFile = event.target.files[0];
        const imagesArraysResolutions = [...this.state.selectedFilePdfResolutionsComplex];
        imagesArraysResolutions.push(ResolutionsFile);
        this.setState({ selectedFilePdfResolutionsComplex: imagesArraysResolutions });
    }

    handleFileChangesBuildingPlansBuilding = (event: any) => {
        const inputPicherPortableBuildingPlansComplex = document.createElement('input');
        inputPicherPortableBuildingPlansComplex.type = 'file';
        inputPicherPortableBuildingPlansComplex.accept = ".pdf, .doc, .docx"
        inputPicherPortableBuildingPlansComplex.multiple = true
        inputPicherPortableBuildingPlansComplex.click();
        inputPicherPortableBuildingPlansComplex.onchange = (event: any) => {
            this.handleFileBuildingPlansComplex(event)
        }
    };

    handleRemoveFileResolutionsBuilding = (fileIndex: any) => {
        const updatedFilesResolutions = this.state.selectedFilePdfResolutionsComplex.filter((value: any, index: any) => index !== fileIndex);
        this.setState({ selectedFilePdfResolutionsComplex: updatedFilesResolutions });
    };

    handleRemoveFileBuildingPlansBuilding = (fileIndex: any) => {
        const updatedFilesBuildingPlans = this.state.selectedFilePdfBuildingPlansComplex.filter((value: any, index: any) => index !== fileIndex);
        this.setState({ selectedFilePdfBuildingPlansComplex: updatedFilesBuildingPlans });
    };

    handleFileBuildingPlansComplex = (event: any) => {
        const BuildingPlansFile = event.target.files[0];
        const imagesArraysBuildingPlans = [...this.state.selectedFilePdfBuildingPlansComplex];
        imagesArraysBuildingPlans.push(BuildingPlansFile);
        this.setState({ selectedFilePdfBuildingPlansComplex: imagesArraysBuildingPlans });
    }

    // CoreMembers
    sharedCoreMembers = () => {
        this.setState(prevState => ({
            coreMembers: !prevState.coreMembers,
        }));
    };

    // createNewMember
    handleImageSharedChangeBuilding = (event: any) => {
        const inputPicherPortableComplex = document.createElement('input');
        inputPicherPortableComplex.type = 'file';
        inputPicherPortableComplex.accept = "image/png, image/jpeg, iamge/jpg"
        inputPicherPortableComplex.multiple = true
        inputPicherPortableComplex.click();
        inputPicherPortableComplex.onchange = (event: any) => {
            this.picherPortableSharedComplex(event)
        }
    };

    findPersonPopup = () => {
        this.setState(prevState => ({
            findPopupOpen: !prevState.findPopupOpen,
        }));
    };

    sharedCreateNewMember = () => {
        this.setState(prevState => ({
            createNewMember: !prevState.createNewMember,
        }));
    };

    // Add New Shared Facility
    picherPortableSharedComplex = (event: any) => {
        const uplodeFilesArrayComplex = Array.from(event.target.files);
        uplodeFilesArrayComplex.forEach(async (file: any) => {
            if (file.size < 2000000) {
                const base64 = await this.convertBase64(file)
                this.setState({
                    imagesSharedFacilityBuilding: [...this.state.imagesSharedFacilityBuilding, base64],
                })
            }
        })
    }

    handleFileChangeComplex = (event: any) => {
        const inputPicherBasicDetailsBuiding = document.createElement('input');
        inputPicherBasicDetailsBuiding.type = 'file';
        inputPicherBasicDetailsBuiding.accept = "image/png, image/jpeg, image/jpg, image/jpg4"
        inputPicherBasicDetailsBuiding.multiple = true
        inputPicherBasicDetailsBuiding.click();
        inputPicherBasicDetailsBuiding.onchange = (event: any) => {
            this.picherPortablesComplexBuilding(event)
        }
    };

    picherPortablesComplexBuilding = (event: any) => {
        const fileBuilding = event.target.files[0];
        if (fileBuilding) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                this.setState({
                    selectedFileBuliding: fileBuilding,
                    imageURLBuilding: event.target.result,
                });
            };
            reader.readAsDataURL(fileBuilding);
        }
    }

    handleChangePage = (event: any, newPage: any) => {
        this.setState({ page: newPage - 1 });
    };

    // FloorPlan
    handleFileChangesFloorPlanBuilding = (event: any) => {
        const inputPicherPortableComplex = document.createElement('input');
        inputPicherPortableComplex.type = 'file';
        inputPicherPortableComplex.accept = ".pdf, .doc, .docx"
        inputPicherPortableComplex.multiple = true
        inputPicherPortableComplex.click();
        inputPicherPortableComplex.onchange = (event: any) => {
            this.handleFileFloorPlanComplex(event)
        }
    };
    sharedFacilityPopupBuilding = () => {
        this.setState(prevState => ({
            facilityPopupBuilding: !prevState.facilityPopupBuilding,
        }));
    };

    handleChangeBuilding = (event: any) => {
        const selectedVariants = event?.target.value;
        this.setState({ jurisdictionSelected: selectedVariants, buildingValues:{
            ...this.state.buildingValues,
            currency: selectedVariants?.attributes?.currency || '-',
            timezone: selectedVariants?.attributes?.timezone || '-',
            measurement: selectedVariants?.attributes?.unit_of_measurement || '-',
            formula: selectedVariants?.attributes?.formula || '-'
        } });
    };

    handleFileFloorPlanComplex = (event: any) => {
        const file = event.target.files[0];
        const imagesArrays = [...this.state.selectedFilePdfFloorPlanBuilding];
        imagesArrays.push(file);
        this.setState({ selectedFilePdfFloorPlanBuilding: imagesArrays });
    }

    handleRemoveFileFloorPlanComplex = (fileIndex: any) => {
        const updatedFiles = this.state.selectedFilePdfFloorPlanBuilding.filter((value: any, index: any) => index !== fileIndex);
        this.setState({ selectedFilePdfFloorPlanBuilding: updatedFiles });
    };

    togglePopupBuilding = () => {
        this.setState(prevState => ({
            isPopupOpen: !prevState.isPopupOpen,
        }));
    };

    handleCloseOkBuilding = () => {
        this.setState({ open: false });
    };

    handleCloseBuilding = () => {
        this.setState({ openBuilding: false });
    };

    multipleButton = () => {
        return (
            <Box style={{ display: 'flex', justifyContent: "end", flexWrap: 'wrap' }}>
                {this.state.stepBuilding > 0 && <Grid style={{ marginTop: "30px", marginRight: '20px' }}>
                    <Box style={{}}>
                        <Button style={{ color: "#2B6FED", fontWeight: 'bold', padding: '0px 20.5px', textTransform: 'capitalize', width: '177px', height: '56px', border: '1px solid #2B6FED', borderRadius: "8px", display: 'flex', justifyContent: 'center', textAlign: 'center' }} onClick={this.handleBackBuilding}><span className="bold-text">PREV</span></Button>
                    </Box>
                </Grid>}
                <Box >
                    {this.state.stepBuilding < 6 ?
                        <Grid style={{ marginTop: "30px", }}>
                            <Button style={{ width: '177px', height: '56px', backgroundColor: '#2B6FED', borderRadius: "8px", display: 'flex', justifyContent: 'center', textAlign: 'center', color: "#FFFFFF", fontWeight: 'bold', padding: '0px 20.5px', textTransform: 'capitalize' }} data-test-id="handleNextComplex" onClick={this.handleNextBuilding}><span className="bold-text">NEXT</span></Button>
                        </Grid> : <Grid style={{ marginTop: "30px", }}>
                            <Button onClick={this.handleClickOpen} style={{ width: '177px', height: '56px', backgroundColor: '#2B6FED', borderRadius: "8px", display: 'flex', justifyContent: 'center', textAlign: 'center', color: "#FFFFFF", fontWeight: 'bold', padding: '0px 20.5px', textTransform: 'capitalize' }} ><span className="bold-text">Save</span></Button>
                        </Grid>}
                </Box>
            </Box>
        )
    }

    handleClickOpenBuilding = () => {
        this.setState({ openBuilding: true });
        this.findPersonPopup()
    };

    uploadNameBuilding = () => {
        return (
            <>
                {this.state.selectedFilePdfRolesComplex == 0 && "Upload Roles Documents"}
            </>
        )
    }

    uploadResolutionsBuilding = () => {
        return (
            <>
                {this.state.selectedFilePdfResolutionsComplex == 0 && "Upload Resolutions Documents"}
            </>
        )
    }

    uploadImageName = () => {
        return (
            <>
                    { this.state.selectedFile.name ? "Change Logo" :"Add Logo" }
            </>
        )
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleColor = (index:string|number) => {
        let color:string ="";
        if (this.state.stepBuilding === index) {
            color = "#FFFFFF";
        } else if (this.state.stepBuilding < index) {
            color = "#83878e";
        } else {
            color = "#2B6FED";
        }
        return color;
    }

    multiPleButtonHaderBuilding = () => {
        return (
            <>
                {this.state.multiPleButtonComplex.map((val: any, index: number) => {
                    return (<Box key={index} style={{ width: 'max-content', height: '43px', marginRight: '15px', backgroundColor: this.state.stepBuilding == index ? "#2B6FED" : "#C8C8C81F", borderRadius: "21.5px", display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                        <Button data-test-id="multiPleButtonHader" style={{color: this.handleColor(index), fontWeight: 'bold', padding: '0px 20.5px', textTransform: 'capitalize' }}>{val}</Button>
                    </Box>)
                })
                }
            </>
        )
    }


    imgUplodeAvtarBuilding = () => {
        return (
            <Box>
                <img style={{ border: '2px solid black', borderRadius: '100%', width: '100px', height: '100px' }} src={!this.state.imageURLComplex ? blanckImg : this.state.imageURLComplex} alt="" />
            </Box>
        )
    }
    // Add New Building

    imgUplodeAvtar = () => {
        return (
            <Box>
                <img style={{ width: '100px', border: '1px solid black', height: '100px', borderRadius: '100%', }} src={this.state.imageURLBuilding !== "" ? this.state.imageURLBuilding : userProfile} />
            </Box>
        )
    }

    uploadGuidelinesBuilding = () => {
        return (
            <Box style={{ fontWeight: 400, color: '#8d8d8d', paddingLeft: "16px", fontSize: '16px', }}>{this.state.selectedFilePdfGuidelinesComplex == 0 ? "Upload Guidelines Documents" : ""}</Box>
        )
    }
    // Customizable Area End
}