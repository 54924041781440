// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Divider,
  TableRow,
  IconButton,
  Modal,
  Fade,
  Backdrop,
  Tooltip,
  MenuItem,
  TableContainer,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CSSProperties } from "@material-ui/styles";
import { Menu } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import { withTranslation } from "react-i18next";
import ListIcon from "@material-ui/icons/List";
import PaginationModule from "../../../StoreCredits/src/PaginationModule.web";
import { ComplexMapComponent } from "../../../../components/src/MapComponent/index";
import RoomIcon from "@material-ui/icons/Room";
//@ts-ignore
import ComplexListingTableController, { Props } from "./ComplexListingTableController.web";
//@ts-ignore
import { RealEstateFilter } from "../../../../components/src/RealEstateFilter/index";
//@ts-ignore
import { TabsComponent } from "../../../../components/src/TabsComponent";
import { Link } from "react-router-dom";
//@ts-ignore
import { complexIcon, buildingIcon } from "./assets";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import SearchInput from "../../../../components/src/CommonComponents/SearchInput.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";

const registerOptions = [
  {
    icon: complexIcon,
    title: "Complex",
    link: "/CompanyEmployee/ComplexMainBlock",
  },
  {
    icon: buildingIcon,
    title: "Individual Building",
    link: "/CompanyEmployee/BuildingMainBlock",
  },
];

class ComplexListingTable extends ComplexListingTableController {
  constructor(props: Props) {
    super(props);
  }

  handleCooComplexListing = (t: any, language: any) => (
    <>
      <TableHead>
        <TableRow>
          {this.state.accountType === "coo" && (
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("Company Name")}
            </TableCell>
          )}
          {this.state.accountType === "account_manager" && (
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("Complex Id")}
            </TableCell>
          )}
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {t("Complex Name")}
          </TableCell>
          {this.state.accountType !== "account_manager" && (
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("Account Manager")}
            </TableCell>
          )}
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {t("Total Buildings")}
          </TableCell>
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {t("Total Units")}
          </TableCell>
          {this.state.accountType !== "coo" && (
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("Subscription Ends in")}
            </TableCell>
          )}
          {this.state.accountType !== "account_manager" && (
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("Subscription Type")}
            </TableCell>
          )}
          {this.state.accountType !== "account_manager" && (
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("Paying Month")}
            </TableCell>
          )}
          {this.state.accountType === "account_manager" && (
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("Country")}
            </TableCell>
          )}
          {this.state.accountType === "account_manager" && (
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("City")}
            </TableCell>
          )}
          {this.state.accountType === "account_manager" && (
            <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
              {t("Status")}
            </TableCell>
          )}
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {this.state.complexList.map((row: any, i: number) => {
          return (
            <TableRow key={i} style={{ borderBottom: "1px solid grey" }}>
              {this.state.accountType === "coo" && (
                <TableCell align={languageCondition(language, "right", "left")}>
                  <div>
                    <Tooltip title={row.attributes.company_name?.company_name}>
                      <div>{this.nerfNames(row.attributes.company_name?.company_name) || "-"}</div>
                    </Tooltip>
                  </div>
                </TableCell>
              )}
              {this.state.accountType === "account_manager" && (
                <TableCell align={languageCondition(language, "right", "left")}>{row.id}</TableCell>
              )}
              <TableCell align={languageCondition(language, "right", "left")}>
                <Tooltip title={row.attributes?.name}>
                  <div>{this.nerfNames(row.attributes?.name)}</div>
                </Tooltip>
              </TableCell>
              {this.state.accountType !== "account_manager" && (
                <TableCell align={languageCondition(language, "right", "left")}>
                  {row.attributes.account_manager || "-"}
                </TableCell>
              )}
              <TableCell align={languageCondition(language, "right", "left")}>
                {row.attributes.total_buildings}
              </TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>
                {row.attributes.total_units.length}
              </TableCell>
              {this.state.accountType !== "coo" && (
                <TableCell align={languageCondition(language, "right", "left")}>{12}</TableCell>
              )}
              {this.state.accountType !== "account_manager" && (
                <TableCell align={languageCondition(language, "right", "left")}>
                  {row.attributes.subscription_type || "-"}
                </TableCell>
              )}
              {this.state.accountType !== "account_manager" && (
                <TableCell align={languageCondition(language, "right", "left")}>
                  {row.attributes.paying_month || "-"}
                </TableCell>
              )}
              {this.state.accountType === "account_manager" && (
                <TableCell align={languageCondition(language, "right", "left")}>
                  {this.nerfNames(row.attributes.country)}
                </TableCell>
              )}
              {this.state.accountType === "account_manager" && (
                <TableCell align={languageCondition(language, "right", "left")}>
                  {this.nerfNames(row.attributes.city)}
                </TableCell>
              )}
              {this.state.accountType === "account_manager" && (
                <TableCell>
                  <span style={webStyle[row.attributes.status]} className="bold-text">
                    {row.attributes.status || "-"}
                  </span>
                </TableCell>
              )}
              <TableCell className="view-page-menu textid" align={languageCondition(language, "right", "left")}>
                <Menu
                  menuButton={
                    <IconButton>
                      <MoreVertIcon />
                    </IconButton>
                  }
                  className="textid"
                  style={webStyle.btnMenu}
                >
                  <MenuItem className="textid">
                    <Link to={`/CompanyEmployee/realestate-company-details/${row.id}`}>
                      {t("View location on map")}
                    </Link>
                  </MenuItem>
                  <MenuItem className="sm-cancel-menu textid">
                    <Link to={`/CompanyEmployee/ComplexDetails/${row.id}`}>{t("Go to Complex Page")}</Link>
                  </MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );

  handleCooBuildingListing = (t: any, language: any) => (
    <>
      <TableHead>
        <TableRow style={{ whiteSpace: "nowrap" }}>
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {this.state.accountType !== "coo" && <>{t("Building Id")}</>}
          </TableCell>
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {t("Building Name")}
          </TableCell>
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {this.state.accountType == "coo" && <>{t("Account Manager")}</>}
          </TableCell>
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {this.state.accountType == "coo" && <>{t("Chairman Name")}</>}
          </TableCell>
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {t("Total Units")}
          </TableCell>
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {t("Subscription Ends in")}
          </TableCell>
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {this.state.accountType == "coo" && <>{t("Subscription Type")}</>}
          </TableCell>
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {this.state.accountType === "coo" && <>{t("Paying Month")}</>}
          </TableCell>
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {this.state.accountType !== "coo" && <>{t("Country")}</>}
          </TableCell>
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {this.state.accountType !== "coo" && <>{t("City")}</>}
          </TableCell>
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
            {this.state.accountType !== "coo" && <>{t("Status")}</>}
          </TableCell>
          <TableCell className="bold-text" align={languageCondition(language, "right", "left")}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {this.state.buildingList?.map((row: any, i: number) => {
          return (
            <TableRow key={i}>
              <TableCell align={languageCondition(language, "right", "left")}>
                {this.state.accountType !== "coo" && <>{row.id}</>}
              </TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>
                <Tooltip title={row.attributes?.name}>
                  <div>{this.nerfNames(row.attributes?.name)}</div>
                </Tooltip>
              </TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>
                {this.state.accountType == "coo" && <>{row.attributes.account_manager || "-"}</>}
              </TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>
                {this.state.accountType == "coo" && <>{row.attributes.chairman_nominations || "-"}</>}
              </TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>
                {row.attributes.unit_name?.length}
              </TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>
                {row.attributes.subscription_end_in || "-"}
              </TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>
                {this.state.accountType == "coo" && <>{row.attributes.subscription_type || "-"}</>}
              </TableCell>

              <TableCell align={languageCondition(language, "right", "left")}>
                {this.state.accountType === "coo" && <>{row.attributes?.paying_month || "-"}</>}
              </TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>
                {this.state.accountType !== "coo" && (
                  <Tooltip title={row.attributes?.country}>
                    <div>{this.nerfNames(row.attributes?.country)}</div>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell align={languageCondition(language, "right", "left")}>
                {this.state.accountType !== "coo" && (
                  <Tooltip title={row.attributes?.city}>
                    <div>{this.nerfNames(row.attributes?.city)}</div>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell align={languageCondition(language, "right", "left")} className="buildStatus">
                {this.state.accountType !== "coo" && (
                  <span style={webStyle[row.attributes.status]}>{row.attributes.status || "-"}</span>
                )}
              </TableCell>

              <TableCell align={languageCondition(language, "right", "left")} className="view-page-menu buildid">
                <Menu
                  menuButton={
                    <IconButton>
                      <MoreVertIcon />
                    </IconButton>
                  }
                  className="buildid"
                  style={webStyle.btnMenu}
                >
                  <MenuItem className="buildid">
                    <Link to={`/CompanyEmployee/realestate-company-details/${row.id}`}>
                      {t("View location on map")}
                    </Link>
                  </MenuItem>
                  <MenuItem className="sm-cancel-menu buildid">
                    <Link to={`/CompanyEmployee/BuildingDetailsPage/${row.id}`}>{t("Go to Building Page")}</Link>
                  </MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );

  renderTableContent = (t: any, language: any) => {
    const commonContent = this.state.tabNo == 0 && (
      <TableContainer>
        <Table className="table-box">
          {this.state.complexList?.length ? (
            this.handleCooComplexListing(t, language)
          ) : (
            <Box style={webStyle.noRecord}>
              <Typography variant="body1">{t("No Records Found")}</Typography>
            </Box>
          )}
        </Table>
      </TableContainer>
    );
    const commonbuildContent = this.state.tabNo !== 0 && (
      <TableContainer>
        <Table className="table-box">
          {this.state.buildingList?.length ? (
            this.handleCooBuildingListing(t, language)
          ) : (
            <Box style={webStyle.noRecord}>
              <Typography variant="body1">{t("No Records Found")}</Typography>
            </Box>
          )}
        </Table>
      </TableContainer>
    );
    return this.state.tabNo == 0 ? commonContent : commonbuildContent;
  };

  handleTableOne = (t: any) => {
    if (this.state.accountType !== "coo") {
      return t("Complex");
    } else {
      return t("List of Complexes");
    }
  };

  handleTableTwo = (t: any) => {
    if (this.state.accountType !== "coo") {
      return t("Individual Buildings");
    } else {
      return t("List of Individual Buildings");
    }
  };

  render() {
    //@ts-ignore
    const { t, i18n } = this.props;
    const language = i18n.language;
    return (
      <>
        <Box maxWidth="xl" style={webStyle.detailsBox}>
          <Box>
            <Typography style={{ fontSize: "18px" }}>
              {t("Complex")} / {t("Buildings")}
            </Typography>
          </Box>
          <Box style={webStyle.titleBox}>
            <Typography style={webStyle.companyTitle} className="bold-text">
              {this.state.tabNo ? t("Individual Buildings") : t("Complex")}
            </Typography>
          </Box>
          <Box style={webStyle.tabsBox}>
            <Box>
              <TabsComponent
                tabNo={this.state.tabNo}
                handleChange={this.handleTabChange}
                tabs={[
                  {
                    label: t("Complex"),
                  },
                  {
                    label: t("Individual Buildings"),
                    testId: "tabcheck",
                  },
                ]}
              />
            </Box>
            <Button style={webStyle.registerBtn} onClick={this.handleOpenRegisterModal}>
              <span className="bold-text" data-testid="register_btn">
                {t("Register New Complex / Building")}
              </span>
            </Button>
          </Box>
          <Box style={webStyle.selectBoxes}>
            <Box style={webStyle.selectBoxLeft} className={languageCondition(language, "arabic-grid", "")}>
              <Box className="top-bar-filter">
                {this.state.accountType === "coo" ? (
                  <React.Fragment data-testid="check_coo">
                    <RealEstateFilter language={language} t={t} classComponent={this} filterList="coo_complex_filter" />
                  </React.Fragment>
                ) : (
                  <RealEstateFilter
                    language={language}
                    classComponent={this}
                    t={t}
                    filterList="other_real_estate_filter"
                  />
                )}
                <Box className="filter-filter">
                  <Button
                    className="handleSearchFilterForInvoices search-btn-estate"
                    onClick={this.handleFilterSubmit}
                    startIcon={<SearchIcon style={{ marginLeft: "10px" }} />}
                  >
                    {t("Search")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={webStyle.tableContainer} className="complex_table_select_boxes_container">
            <Box style={webStyle.tableHeader} className="complex_table_select_boxes_header">
              <h2 style={webStyle.tableHeading} className="bold-text complex_table_heading2">
                <Box style={webStyle.switchIcons} className="complex_table_swicthes">
                  <Box
                    className={
                      this.state.mapSwitch ? "complex_table_swicthes_map" : "active_switch complex_table_swicthes_map"
                    }
                    style={webStyle.listIcon}
                    onClick={(e) => this.handleMapSwitch(e, false)}
                    data-testid="click_list"
                  >
                    <ListIcon
                      className={
                        this.state.mapSwitch
                          ? "complex_table_swicthes_map_icon"
                          : "active_switch_icon complex_table_swicthes_map_icon"
                      }
                    />
                  </Box>
                  <Box
                    className={
                      this.state.mapSwitch
                        ? "active_switch complex_table_swicthes_map_icon"
                        : "complex_table_swicthes_map_icon"
                    }
                    style={webStyle.roomIcon}
                    data-testid="click_map"
                    onClick={(e) => this.handleMapSwitch(e, true)}
                  >
                    <RoomIcon
                      className={
                        this.state.mapSwitch
                          ? "active_switch_icon complex_table_swicthes_map_icon"
                          : "complex_table_swicthes_map_icon"
                      }
                    />
                  </Box>
                </Box>
                {this.state.tabNo === 0 ? this.handleTableOne(t) : this.handleTableTwo(t)}
              </h2>
              <Box className="filter-head-box" style={{ margin: "0" }}>
                {this.state.accountType !== "account_manager" && (
                  <FilterSelect
                    t={t}
                    language={language}
                    label="Sort By"
                    option={[
                      { label: "All", value: "all" },
                      { label: "Total Building", value: "building" },
                      { label: "Total Unit", value: "unit" },
                    ]}
                    value={this.state.sortBy}
                    onChange={this.handleSelectedSortBy}
                  />
                )}
                <SearchInput
                  onChange={this.handleComplexFilterDataKeyUp}
                  t={t}
                  language={language}
                  label={t("Search by complex name")}
                  data-testid="searchquery"
                />
              </Box>
            </Box>
            {this.state.mapSwitch ? (
              <Box style={webStyle.mapBox} className="complex_table_swicthes_map_switch">
                <ComplexMapComponent
                  defaultCenter={{
                    lat: 10.99835602,
                    lng: 77.01502627,
                  }}
                  defaultZoom={1}
                  mapPoints={this.state.mapPoints}
                  manageClick={this.handleMapPointClick}
                  currentPoint={this.state.mapPointClick}
                />
              </Box>
            ) : (
              <>
                {this.renderTableContent(t, language)}
                <Divider />
                <Box style={webStyle.tableFooter} className="paginate_details">
                  <PaginationModule
                    pagination={this.state.tabNo == 0 ? this.state.pagination : this.state.buildpagination}
                    handlePagination={
                      this.state.tabNo === 0 ? this.handleVistorPagination : this.buildingListPagination
                    }
                    page={this.state.tabNo === 0 ? this.state.page : this.state.buildpage}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modalStyle"
          // @ts-ignore
          open={this.state.registerModal}
          onClose={this.handleCloseRegisterModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          {/*@ts-ignore*/}
          <Fade in={this.state.registerModal}>
            <Box
              style={{
                width: "440px",
                marginTop: "15px",
                backgroundColor: "white",
                padding: "40px 20px",
                paddingBottom: "35px",
                borderRadius: "8px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "relative",
                  justifyContent: "center",
                }}
              >
                <Typography className="bold-text" style={webStyle.modalRegisterHeading}>
                  {t("Register New")}
                </Typography>
                <CloseIcon
                  style={webStyle.closeIcon}
                  data-testid="close_register"
                  onClick={this.handleCloseRegisterModal}
                />
              </Box>
              <Box style={webStyle.buildingOptions}>
                {registerOptions.map((icon, i) => {
                  return (
                    <Box style={webStyle.buildingOption} key={i}>
                      <Link to={icon.link} style={webStyle.buildingLink}>
                        <Box style={webStyle.buildingCircle}>
                          <img src={icon.icon} style={webStyle.iconSize} />
                        </Box>
                        <Typography>{t(icon.title)}</Typography>
                      </Link>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Fade>
        </Modal>
      </>
    );
  }
}

const webStyle: any = {
  approved: {
    background: "rgb(30,198,91,0.2)",
    color: "rgb(30 198 91)",
    textAlign: "center",
    padding: "5px 15px",
    borderRadius: "16px",
    fontSize: "12px",
  },
  btnMenu: {
    padding: "0px !important",
  },
  rejected: {
    background: "rgb(242,23,23, 0.2)",
    color: "red",
    textAlign: "center",
    padding: "5px 10px",
    borderRadius: "25px",
    fontSize: "12px",
  },
  Pending: {
    color: "#FC8434",
    background: "#FFEDE0",
    textAlign: "center",
    padding: "5px 10px",
    borderRadius: "25px",
    fontSize: "12px",
  },
  buildingOption: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
  },
  buildingLink: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "150px",
  },
  noRecord: {
    padding: "30px",
    textAlign: "center",
  },
  buildingOptions: {
    display: "flex",
    marginTop: "50px",
    justifyContent: "space-evenly",
  },
  buildingCircle: {
    border: "1px solid #ececec",
    borderRadius: "50%",
    height: "80px",
    width: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "15px",
  },
  pending_approval: {
    background: "rgb(252, 132, 52, 0.2)",
    color: "rgb(252, 132, 52)",
    padding: "5px 10px",
    borderRadius: "25px",
    fontSize: "12px",
  },
  modalRegisterHeading: {
    fontSize: "20px",
  },
  switchIcons: {
    display: "flex",
    marginRight: "18px",
    cursor: "pointer",
  },
  closeIcon: {
    position: "absolute",
    right: "-4px",
    top: "-5px",
    cursor: "pointer",
  },
  listIcon: {
    color: "#dcdcdc",
    border: "1px solid #dcdcdc",
    borderRadius: "8px 0px 0px 8px",
    padding: "4px",
    fontSize: "18px",
  },
  roomIcon: {
    color: "#dcdcdc",
    border: "1px solid #dcdcdc",
    borderRadius: "0px 8px 8px 0px",
    padding: "4px",
    borderLeft: "0px",
    fontSize: "18px",
  },
  mapBox: {
    width: "100%",
    height: "400px",
    overflow: "hidden",
  },
  selectBoxLeft: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  rowOptions: {
    position: "absolute" as CSSProperties["position"],
    background: "#fff",
    width: "160px",
    left: "-115px",
    boxShadow: "0px 0px 6px rgba(87,148,201,0.18)",
    zIndex: "9999999" as CSSProperties["zIndex"],
    padding: "15px",
  },
  option1: {
    marginBottom: "15px" as CSSProperties["marginBottom"],
  },
  rowList: {
    listStyle: "none",
  },
  complexNameBox: {
    background: "#fff",
    padding: "10px 15px",
    width: "120px",
    textAlign: "center",
  },
  complexName: {
    fontSize: "12px",
  },
  selectBoxRight: {
    width: "25%",
    display: "flex",
    justifyContent: "flex-end",
  },
  selectBoxes: {
    marginTop: "30px",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  currentRecords: {
    color: "#FC8434",
  },
  searchBtn: {
    marginTop: "5px",
    marginLeft: "15px",
    height: "calc(100% - 20px)",
    background: "#2B6FED",
    borderColor: "#2B6FED",
    fontSize: "14px",
    boxShadow: "unset",
  },
  searchText: {
    marginLeft: "10px",
  },
  detailsBox: {
    padding: "30px",
  },
  tabsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  registerBtn: {
    background: "#FC8434",
    color: "#fff",
    height: "40px",
    paddingLeft: "20px",
    paddingRight: "20px",
    borderRadius: "8px",
  },
  searchInput: {
    border: "1px solid rgba(0,0,0,0.2)",
    borderRadius: "8px",
    height: "40px",
    padding: "0px",
    color: "#212121",
  },
  moreOptions: {
    position: "relative" as CSSProperties["position"],
  },
  moreIcon: {
    color: "rgba(136,136,136,0.6)",
    cursor: "pointer",
  },
  tableHeader: {
    padding: "20px",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableContainer: {
    background: "#fff",
  } as React.CSSProperties,
  tableFooter: {
    padding: "15px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableHeading: {
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
  },
  menuOption: {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  innerInput: {
    padding: "0px",
    color: "#212121",
  },
  titleBox: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "15px",
  },
  companyTitle: {
    fontSize: "32px",
  },
  companyTag: {
    color: "#FC8434",
    background: "rgb(252, 132, 52, 0.1)",
    padding: "5px 15px",
    borderRadius: "12px",
  },
};

export default withTranslation()(ComplexListingTable);
// Customizable Area End
