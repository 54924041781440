//Customizable Area Start
import React from "react";
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  Typography,
  Grid,
  InputAdornment,
} from "@material-ui/core";
import { Link } from 'react-router-dom'
//@ts-ignore
import { ModalPopup } from '../../../../components/src/ModalPopup/index'
import { CSSProperties } from '@material-ui/styles';
import { CloudUpload } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
// @ts-ignore
import TickIcon from '../../assets/tick-circle-icon.png'
import RealEstateCompanyFormController, {
  Props,
} from './RealEstateCompanyFormController.web'
import { withTranslation } from "react-i18next";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Loader from "../../../../components/src/Loader.web";
import { alphaNumericOnly, alphabetsOnly, numbersOnly } from '../../../../components/src/KeyBlockFunctions'
import * as Yup from 'yup';

class RealEstateCompanyForm extends RealEstateCompanyFormController {
  constructor(props: Props) {
    super(props);
  }

  renderHeader = (t:any) => {
    return(
      <>
      <Box>
            <Typography style={{ fontSize: '18px' }}>
              <Link to="/CompanyEmployee/realestate-companies">{t("Real Estate Companies")}</Link> / <span style={webStyle.linkColor}>{t("Register Real Estate Company")}</span></Typography>
          </Box>
          <Box>
            <Typography style={webStyle.bigTitle} className="bold-text">{t("Register Real Estate Company")}</Typography>
          </Box>
      </>
    )
  }

  cooOption = (t:any, values:any) => {
    return(
      <>
      {
                        this.state.accountType === "coo" ? <React.Fragment data-testid="check_coo">
                          <Grid item xs={6} sm={6}>
                            <Grid container>
                              <Grid item xs={12}><label htmlFor="salesManager" style={webStyle.label}>
                                {t("Sales Manager")}</label></Grid>
                              <Grid item xs={12} className="pos_relative">
                                <Field as={Select} name="salesManager"
                                  id="salesManager" className={`${values.salesManager !== '' ? '' : 'estate_select_color'} estate_select text_capital`}
                                  labelId="demo-simple-select-label"
                                  style={webStyle.formikSelectField}
                                  displayEmpty
                                >
                                  <MenuItem value="" disabled style={{ color: '#848484' }}>
                                    Sales Manager
                                  </MenuItem>
                                  {
                                    this.state.salesManager.map((sales: any, i: number) => {
                                      return <MenuItem data-testid="salesOption" style={webStyle.menuCapital} value={sales.id} key={i}>{sales.name}</MenuItem>
                                    })
                                  }
                                </Field>
                                <Box style={webStyle.errorMsg}>
                                  <ErrorMessage name="salesManager" component="div" />
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6} sm={6}>
                            <Grid container>
                              <Grid item xs={12}><label htmlFor="accountManager" style={webStyle.label}>
                                {t("Account Manager")}</label></Grid>
                              <Grid item xs={12} className="pos_relative">
                                <Field as={Select} name="accountManager"
                                  id="accountManager" className={`${values.accountManager !== '' ? '' : 'estate_select_color'} estate_select text_capital`}
                                  labelId="demo-simple-select-label"
                                  style={webStyle.formikSelectField}
                                  displayEmpty
                                >
                                  <MenuItem value="" disabled>
                                  Account Manager
                                  </MenuItem>
                                  {
                                    this.state.accountManager.map((account: any, i: number) => {
                                      return <MenuItem data-testid="accountOption" style={webStyle.menuCapital} value={account.id} key={i}>{account.name}</MenuItem>
                                    })
                                  }
                                </Field>
                                <Box style={webStyle.errorMsg}>
                                  <ErrorMessage name="accountManager" component="div" />
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </React.Fragment> : <></>
                      }
      </>
    )
  }

  fileOption = () => {
    return(
      <>
      {
                              this.state.fileName ? <Box style={webStyle.fileBox} data-testid="file_box">
                                <span style={webStyle.fileNameBox}>
                                  <span>{this.state.fileName}</span>
                                  <CloseIcon onClick={this.handleFileRemove} style={webStyle.removeIcon} />
                                </span>
                              </Box> : <Field name="file">
                                {({ field }: { field: string }) => (
                                  <TextField
                                    type="file"
                                    style={webStyle.formikField}
                                    data-testid="proofDoc"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <CloudUpload />
                                        </InputAdornment>
                                      ),
                                      inputProps: {
                                        accept: ".pdf, .doc, .docx",
                                        onChange: this.handleProofDoc,
                                      },
                                    }}
                                  />
                                )}</Field>
                            }
      </>
    )
  }

  buttonOptions = (t:any) => {
    return(<>
    {
                      this.state.accountType === "coo" ? <Button type="submit" data-testid="submit-button" style={webStyle.submitBtnCoo}>
                      <span className="bold-text" style={webStyle.btnText}>{this.props.companyId ? t("EDIT DETAILS") : t("REGISTER") }</span>
                    </Button> : <Button type="submit" data-testid="submit-button" style={webStyle.submitBtn}>
                      <span className="bold-text" style={webStyle.btnText}>{t("SUBMIT FOR APPROVAL")}</span>
                    </Button>
                    }
    </>)
  }

  logoBox = (t:any) => {
    return(<>
      {
                          this.state.previewLogoUrl ? <img data-testid="preview_img" src={this.state.previewLogoUrl}
                            alt="Preview" style={webStyle.logoHolder} /> : <Box style={webStyle.logoHolder}>
                          </Box>
                        }

                        <label htmlFor="logoChange"><span style={webStyle.logoBtn} className="bold-text">
                          {
                            this.state.previewLogoUrl ? <>{t("Change Logo")}</> : <>{t("Add Logo")}</>
                          }
                        </span></label>
                        <input type="file" accept="image/*" id="logoChange" data-testid="logoUpload" onChange={this.handleImageChange} style={{ display: 'none' }} />
                        {
                          this.state.logoError && <p style={webStyle.errorMsg}>*{t("Please upload company logo")}</p>
                        }
    </>)
  }

  render() {
    //@ts-ignore
    const { t } = this.props

    const validationSchema = Yup.object().shape({
      companyName: Yup.string().min(3, 'Company name must be at least 3 characters')
        .max(50, 'Company name must be at most 50 characters').required('Company Name is required'),
      primaryContact: Yup.object().shape({
        contactName: Yup.string().min(2, 'Contact name must be at least 2 characters')
          .max(50, 'Contact name must be at most 50 characters').required('Contact Name is required'),
        emailAddress: Yup.string().min(3, 'Email must be at least 3 characters')
          .max(100, 'Email must be at most 100 characters').email('Invalid email').required('Email is required'),
        phoneNumber: Yup.string().matches(/^\d{10}$/, 'Phone number must be exactly 10 digits').required('Phone Number is required'),
      }),
      secondaryContact: Yup.object().shape({
        contactName: Yup.string().min(2, 'Contact name must be at least 2 characters')
          .max(50, 'Contact name must be at most 50 characters').required('Contact Name is required'),
        emailAddress: Yup.string().min(3, 'Email must be at least 3 characters')
          .max(100, 'Email must be at most 100 characters').email('Invalid email').required('Email is required'),
          phoneNumber: Yup.string().matches(/^\d{10}$/, 'Phone number must be exactly 10 digits').required('Phone Number is required'),
      }),
      managedBy: Yup.string().min(2, 'Name must be at least 2 characters')
        .max(50, 'Name must be at most 50 characters').required('Name is required'),
      country: Yup.string().required('Country is required'),
      region: Yup.string().required('Region is required'),
      city: Yup.string().required('City is required'),
      zipCode: Yup.string().min(6, 'Zip Code must be at least 6 characters')
        .max(7, 'Zip Code must be at most 7 characters').required('Zip Code is required'),
      googleMapLocation: Yup.string().required('Select Location is required'),
      hqLocationAddress: Yup.string().min(2, 'HQ Location Address must be at least 2 characters')
        .max(100, 'HQ Location Address must be at most 100 characters').required('HQ Location Address is required'),
      commercialRegistrationNumber: Yup.string().required(
        'Commercial Registration Number is required'
      ),
      ...(this.state.accountType === 'coo'
        ? {
          salesManager: Yup.string().required('Sales Manager is required'),
          accountManager: Yup.string().required('Account Manager is required'),
        }
        : {}),
    });

    return (
      <>
        <Box style={webStyle.registerContainer}>
          {this.renderHeader(t)}
          <Box>
            <Formik
              initialValues={this.state.initialValues}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmit}
              enableReinitialize={true}
            >
              {/* @ts-ignore */}
              {({ values, isSubmitting, formikProps, handleChange }) => (
                <Form translate={undefined}>
                  <Box maxWidth='xl' className="dashboard_container field_placeholder" style={webStyle.formContainer}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={12} style={webStyle.logoGrid}>
                        {this.logoBox(t)}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Grid container>
                          <Grid item xs={12}><label htmlFor="companyName" style={webStyle.label}>{t("Company Name")}</label></Grid>
                          <Grid item xs={12}>
                            <Field
                              as={TextField}
                              type="text"
                              name="companyName"
                              id="companyName"
                              placeholder={t("Company Name")}
                              style={webStyle.formikField}
                              data-testid="companyName-input"
                              onKeyDown={alphaNumericOnly}
                            />
                            <Box style={webStyle.errorMsg}>
                              <ErrorMessage name="companyName" component="div" data-testid="companyName-error" />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      {this.cooOption(t, values)}
                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} style={webStyle.labelPadding}><label style={webStyle.label}>
                            {t("Point of Contact")} <span className="bold-text">({t("Primary")})</span></label></Grid>
                          <Grid item xs={6} sm={6} style={webStyle.gridPadding}>
                            <Field
                              as={TextField}
                              type="text"
                              name="primaryContact.contactName"
                              placeholder={t("Contact Name")}
                              onKeyDown={alphabetsOnly}
                              style={webStyle.formikPrimaryField}
                              data-testid="primaryContactName-input"
                            />
                            <Box style={webStyle.errorMsg}>
                              <ErrorMessage name="primaryContact.contactName" component="div" />
                            </Box>
                          </Grid>
                          <Grid item xs={6} sm={6} style={webStyle.gridPadding}>
                            <Field
                              as={TextField}
                              type="email"
                              name="primaryContact.emailAddress"
                              placeholder={t("Email Address")}
                              data-testid="primaryemailAddress-input"
                              style={webStyle.formikPrimaryField}
                              onKeyUp={() => this.handleEmailValidation(values.primaryContact.emailAddress, values.secondaryContact.emailAddress)}
                            />
                            <Box style={webStyle.errorMsg}>
                              {
                                this.state.sameEmailValidation && <p style={webStyle.errorMsg}>*
                                  {t("Emails should be different")}</p>
                              }
                              <ErrorMessage name="primaryContact.emailAddress" component="div" />
                            </Box>
                          </Grid>
                          <Grid item xs={6} sm={6} style={webStyle.gridPadding}>
                            <Field
                              as={TextField}
                              type="text"
                              name="primaryContact.phoneNumber"
                              placeholder={t("Phone Number")}
                              data-testid="primaryPhoneNumber-input"
                              style={webStyle.formikPrimaryField}
                              onKeyDown={numbersOnly}
                              onKeyUp={() => this.handlePhoneValidation(values.primaryContact.phoneNumber, values.secondaryContact.phoneNumber)}
                            />
                            <Box style={webStyle.errorMsg}>
                              <ErrorMessage name="primaryContact.phoneNumber" component="div" />
                              {
                                this.state.samePhoneValidation && <p style={webStyle.errorMsg}>*
                                  {t("Phone Numbers should be different")}</p>
                              }
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} style={webStyle.labelPadding}><label style={webStyle.label}>{t("Point of Contact")}
                            {" "}<span className="bold-text">({t("Secondary")})</span></label></Grid>
                          <Grid item xs={6} sm={6} style={webStyle.gridPadding}>
                            <Field
                              as={TextField}
                              type="text"
                              name="secondaryContact.contactName"
                              placeholder={t("Contact Name")}
                              onKeyDown={alphabetsOnly}
                              data-testid="secondaryContactName-input"
                              style={webStyle.formikField}
                            />
                            <Box style={webStyle.errorMsg}>
                              <ErrorMessage name="secondaryContact.contactName" component="div" />
                            </Box>
                          </Grid>
                          <Grid item xs={6} sm={6} style={webStyle.gridPadding}>
                            <Field
                              as={TextField}
                              type="email"
                              name="secondaryContact.emailAddress"
                              placeholder={t("Email Address")}
                              data-testid="secondaryEmailAddress-input"
                              style={webStyle.formikField}
                              onKeyUp={() => this.handleEmailValidation(values.secondaryContact.emailAddress, values.primaryContact.emailAddress)}
                            />
                            <Box style={webStyle.errorMsg}>
                              {
                                this.state.sameEmailValidation && <p style={webStyle.errorMsg}>*
                                  {t("Emails should be different")}</p>
                              }
                              <ErrorMessage name="secondaryContact.emailAddress" component="div" />
                            </Box>
                          </Grid>
                          <Grid item xs={6} sm={6} style={webStyle.gridPadding}>
                            <Field
                              as={TextField}
                              type="text"
                              name="secondaryContact.phoneNumber"
                              placeholder={t("Phone Number")}
                              data-testid="secondaryPhoneNumber-input"
                              style={webStyle.formikField}
                              onKeyDown={numbersOnly}
                              onKeyUp={() => this.handlePhoneValidation(values.secondaryContact.phoneNumber, values.primaryContact.phoneNumber)}
                            />
                            <Box style={webStyle.errorMsg}>
                              <ErrorMessage name="secondaryContact.phoneNumber" component="div" />
                              {
                                this.state.samePhoneValidation && <p style={webStyle.errorMsg}>*
                                  {t("Phone Numbers should be different")}</p>
                              }
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Grid container>
                          <Grid item xs={12}><label htmlFor="managedBy" style={webStyle.label}>
                            {t("Managed By")}</label></Grid>
                          <Grid item xs={12}>
                            <Field
                              as={TextField}
                              type="text"
                              name="managedBy"
                              id="managedBy"
                              placeholder={t("Managed By")}
                              onKeyDown={alphabetsOnly}
                              style={webStyle.formikField}
                              data-testid="managedBy-input"
                            />
                            <Box style={webStyle.errorMsg}>
                              <ErrorMessage name="managedBy" component="div" data-testid="managedBy-error" />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                          <Grid container className="countries_form_real_estate_grid_container">
                            <Grid item xs={12} className="countries_form_real_estate_grid_item"><label htmlFor="country" style={webStyle.label} className="countries_form_real_estate_grid_label">
                              {t("Country")}</label></Grid>
                            <Grid item xs={12} className="pos_relative countries_form_real_estate_grid_item_new">
                              <Field as={Select} name="country"
                                id="country" className={`${values.country !== '' ? '' : 'estate_select_color'} estate_select countries_form_real_estate_grid_field`}
                                labelId="demo-simple-select-label"
                                style={webStyle.formikSelectField}
                                displayEmpty
                                onChange={(e: any) => {
                                  handleChange(e);
                                  this.handleCountryChange(e.target.value);
                                }}
                              >
                                <MenuItem value="" disabled>
                                    Select Country
                                  </MenuItem>
                                {
                                  this.state.countriesList.map((country: string, i: number) => {
                                    return <MenuItem data-testid="countryOption" className="text_capital" value={country} key={i}>{country}</MenuItem>
                                  })
                                }
                              </Field>
                              <Box style={webStyle.errorMsg}>
                                <ErrorMessage name="country" component="div" />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>

                      <Grid item xs={6} sm={6}>
                        <Grid container>
                          <Grid item xs={12}>
                            <label htmlFor="region" style={webStyle.label}>{t("Region")}</label>
                          </Grid>
                          <Grid item xs={12} className="pos_relative">
                            <Field as={Select} className={`${values.region !== '' ? '' : 'estate_select_color'} estate_select`} name="region" id="region" style={webStyle.formikSelectField} displayEmpty>
                            <MenuItem value="" disabled>
                                    Select Region
                                  </MenuItem>
                              {
                                this.state.cityList.map((region: any, i: number) => {
                                  return <MenuItem value={region.region} className="text_capital" key={i}>{region.region}</MenuItem>
                                })
                              }
                            </Field>
                            <Box style={webStyle.errorMsg}>
                              <ErrorMessage name="region" component="div" />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <Grid container>
                          <Grid item xs={12}>
                            <label htmlFor="city" style={webStyle.label}>{t("City")}</label>
                          </Grid>
                          <Grid item xs={12} className="pos_relative">
                            <Field as={Select} className={`${values.city !== '' ? '' : 'estate_select_color'} estate_select text_capital`} name="city" id="city" style={webStyle.formikSelectField} displayEmpty>
                            <MenuItem value="" disabled>
                                    Select City
                                  </MenuItem>
                              {
                                this.state.cityList.map((city: any, i: number) => {
                                  return <MenuItem value={city.city} className="text_capital" key={i}>{city.city}</MenuItem>
                                })
                              }
                            </Field>
                            <Box style={webStyle.errorMsg}>
                              <ErrorMessage name="city" component="div" />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <Grid container>
                          <Grid item xs={12}>
                            <label htmlFor="zipCode" style={webStyle.label}>{t("Zip Code")}</label>
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              as={TextField}
                              type="text"
                              name="zipCode"
                              id="zipCode"
                              placeholder={t("Enter zip code")}
                              style={webStyle.formikField}
                              data-testid="zipCode-input"
                              onKeyDown={numbersOnly}
                            />
                            <Box style={webStyle.errorMsg}>
                              <ErrorMessage name="zipCode" component="div" data-testid="zipCode-error" />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <Grid container>
                          <Grid item xs={12}>
                            <label htmlFor="googleMapLocation" style={webStyle.label}>
                              {t("Google Map Location")}</label>
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              as={TextField}
                              type="text"
                              name="googleMapLocation"
                              id="googleMapLocation"
                              placeholder={t("Enter Location")}
                              style={webStyle.formikField}
                              data-testid="googleMapLocation-input"
                            />
                            <Box style={webStyle.errorMsg}>
                              <ErrorMessage name="googleMapLocation" component="div" data-testid="googleMapLocation-error" />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <Grid container>
                          <Grid item xs={12}>
                            <label htmlFor="hqLocationAddress" style={webStyle.label}>
                              {t("HQ Location Address")}</label>
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              as={TextField}
                              type="text"
                              name="hqLocationAddress"
                              id="hqLocationAddress"
                              placeholder={t("Enter HQ location address")}
                              data-testid="hqLocationAddress-input"
                              style={webStyle.formikField}
                            />
                            <Box style={webStyle.errorMsg}>
                              <ErrorMessage name="hqLocationAddress" component="div" data-testid="hqLocationAddress-error" />
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <label htmlFor="commercialRegistrationNumber" style={webStyle.label}>
                              {t("Commercial Registration Number")}
                            </label>
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              as={TextField}
                              type="text"
                              name="commercialRegistrationNumber"
                              id="commercialRegistrationNumber"
                              placeholder={t("Commercial Registration Number")}
                              style={webStyle.formikField}
                              data-testid="commercialRegistrationNumber-input"
                              onKeyDown={alphaNumericOnly}
                            />
                            <Box style={webStyle.errorMsg}>
                              <ErrorMessage
                                name="commercialRegistrationNumber"
                                component="div"
                                data-testid="commercialRegistrationNumber-error"
                              />
                            </Box>
                          </Grid>
                        </Grid>

                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <label htmlFor="commercialRegistrationNumber" style={webStyle.label}>
                              {t("Registration Certificate (Government Proof)")}
                            </label>
                          </Grid>
                          <Grid item xs={12}>
                            {this.fileOption()}
                            {
                              this.state.proofError && <p style={webStyle.errorMsg}>*
                                {t("Please upload proof document")}</p>
                            }
                          </Grid>
                        </Grid>

                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={webStyle.btnContainer}>
                    <Button variant="outlined" style={webStyle.cancelBtn} data-testid="cancel_btn" onClick={this.handleCancel}>
                      <span className="bold-text" style={webStyle.btnText}>{t("CANCEL")}</span>
                    </Button>
                    {this.buttonOptions(t)}
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
        <ModalPopup
          title={t("New Company Created")}
          description={this.state.accountType === "coo" ? t("Company has been successfully created on the platform.") :
            t("Approval request has been sent to the COO once the request get approved. Company will be added.")}
          open={this.state.createConfirmModal}
          closeFn={this.handleCloseCreateModal}
          primaryAction={this.handleCloseCreateModal}
          primaryLabel={t("OKAY")}
          icon={TickIcon}
        />
        <ModalPopup
          title={t("Company Details Edited")}
          open={this.state.editConfirmModal}
          closeFn={this.handleCloseEditModal}
          primaryAction={this.handleCloseEditModal}
          primaryLabel={t("OKAY")}
          icon={TickIcon}
        />
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

const webStyle = {
  menuCapital: {
    textTransform: 'capitalize' as CSSProperties['textTransform']
  },
  btnText:{
    fontSize:'16px'
  },
  closeDeleteBtn: {
    borderColor: 'rgb(43, 111, 237) none repeat scroll 0% 0%',
    color: 'rgb(43, 111, 237)',
    width: '47.5%',
    height: '45px',
    borderRdaius: '12px',
    fontWeight: '700' as CSSProperties['fontWeight']
  },
  gridPadding: {
    padding: '5px 15px'
  },
  labelPadding: {
    paddingBottom: '0px'
  },
  linkColor: {
    color: 'rgb(43, 111, 237)'
  },
  bigTitle: { fontSize: '32px', fontWeight: '700' as CSSProperties['fontWeight'] },
  formContainer: {
    background: '#fff',
    padding: '15px',
    marginTop: '30px',
    boxShadow: '4px 0px 14px 0px #E9E9E9',
    borderRadius: '8px',
    overflow: 'hidden'
  },
  tickIcon: {
    color: 'rgb(252, 132, 52) none repeat scroll 0% 0%',
    fontSize: '45px'
  },
  formikPrimaryField: {
    width: 'calc(100% - 15px)',
    background: 'rgb(243, 243, 243) none repeat scroll 0% 0%',
    border: '1px solid rgb(227, 227, 227)',
    padding: '5px',
    borderRadius: '8px',
    marginTop: '10px',
  },
  formikField: {
    width: 'calc(100% - 15px)',
    background: 'rgb(243, 243, 243) none repeat scroll 0% 0%',
    border: '1px solid rgb(227, 227, 227)',
    padding: '5px',
    borderRadius: '8px',
    marginTop: '10px',
  },
  formikSelectField:{
    width: '100%',
    background: 'rgb(243, 243, 243) none repeat scroll 0% 0%',
    border: '1px solid rgb(227, 227, 227)',
    padding: '5px',
    borderRadius: '8px',
    marginTop: '10px',
    textTransform: 'capitalize'
  },
  registerContainer: {
    padding: '30px'
  },
  label: {
    marginBottom: '10px',
    fontSize: '14px'
  },
  cancelBtn: {
    borderColor: '#2B6FED',
    color: '#2B6FED',
    width: '180px',
    height: '56px',
    marginRight: '15px',
    borderRadius:'8px'
  },
  submitBtnCoo:{
    background: '#2B6FED',
    borderColor: '#2B6FED',
    color: '#fff',
    width: '180px',
    height: '56px',
    boxShadow: '0px 6px 14px rgba(43,111,237,0.22)',
    borderRadius:'8px'
  },
  submitBtn: {
    background: '#2B6FED',
    borderColor: '#2B6FED',
    color: '#fff',
    width: '250px',
    height: '56px',
    boxShadow: '0px 6px 14px rgba(43,111,237,0.22)',
    borderRadius:'8px'
  },
  btnContainer: {
    textAlign: 'right' as CSSProperties['textAlign'],
    marginTop: '30px'
  },
  logoHolder: {
    width: '66px',
    height: '66px',
    borderRadius: '50%',
    background: '#dcdcdc'
  },
  errorMsg: {
    color: 'red',
    fontSize: '12px'
  },
  fileBox: {
    marginTop: '15px',
    background: 'rgb(241, 241, 241) none repeat scroll 0% 0%',
    width: '100%',
    padding: '10px',
    borderRadius: '4px'
  },
  logoBtn: {
    color: '#FC8434'
  },
  logoGrid: {
    textAlign: 'center' as CSSProperties['textAlign'],
    display: 'flex',
    flexDirection: 'column' as CSSProperties['flexDirection'],
    justifyContent: 'center',
    alignItems: 'center'
  },
  fileNameBox: {
    background: '#fff',
    color: '#212121',
    padding: '5px 15px',
    display: 'flex',
    alignItems: 'center',
    width: 'max-content',
    borderRadius: '4px'
  },
  removeIcon: {
    cursor: 'pointer'
  }
};

export default withTranslation()(RealEstateCompanyForm)
//Customizable Area End