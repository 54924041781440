// Customizable Area Start
import React from "react";
import LeaseManagementIssueNewContractFormController, { DataItem,Props } from "./LeaseManagementIssueNewContractFormController.web";
import { Box, Breadcrumbs, Button, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, Link, MenuItem, Modal, Paper, Popover, Select, TextField, Typography, styled } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/SearchOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ClearIcon from "@material-ui/icons/Clear";
import { CalenderIconGray } from "./assets";
import { withTranslation } from "react-i18next";
// Customizable Area End

// Customizable Area Start
type TFunction = (value: string) => string;
// Customizable Area End

class LeaseManagementIssueNewContractForm extends LeaseManagementIssueNewContractFormController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area Start
  }

  // Customizable Area Start 
  renderFindPersonModal = () => {
    const { anchorEl, selectedId } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const { t }: { t: TFunction } = this.props;
    return (
      <Box>
        <Modal open={this.state.openFindPersonModal}>
          <ContactDetailsHeader>
            <Box className="modalContainer">
              <Box className="ContractDetailsModal">
                <Typography className="ContractDetailsModalHeading" data-testId={"findPersonModalHeadingTestId"}>
                  {t("Find Person")}
                </Typography>
                <IconButton onClick={this.handleCloseFindPersonModal} data-testId={"findPersonModalCrossIconTestId"}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Divider className="dividerLine" />
              <Typography className="ContractDetailsModalHeadingChild">
              {t("Fill one of more of the fields to find the person, if two fields are populated, any matching person will be shown.")}
              </Typography>
              <Box className="ContractDetailsModalTextfield">
                <Box>
                  <Typography className="ContractDetailsTextFieldHeading">
                  {t("ID Number")}
                  </Typography>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextFieldModal"
                    placeholder="ID Number"
                  />
                </Box>
                <Box>
                  <Typography className="ContractDetailsTextFieldHeading">
                  {t("Passport Number")}
                  </Typography>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextFieldModal"
                    placeholder="Passport Number"
                  />
                </Box>
                <Box>
                  <Typography className="ContractDetailsTextFieldHeading">
                  {t("Email ID")}
                  </Typography>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextFieldModal"
                    placeholder="Email ID"
                  />
                </Box>
                <Box>
                  <Typography className="ContractDetailsTextFieldHeading">
                  {t("Phone Number")}
                  </Typography>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextFieldModal"
                    placeholder="+12345667890"
                  />
                </Box>
                <Box>
                  <Button variant="contained" className="ContractDetailsButton">
                    <SearchIcon /> {t("Search")}
                  </Button>
                </Box>
              </Box>
              <Box className="modalTableContainer">
                <table style={{ width: "100%" }} className="tableContainer">
                  <thead>
                    <tr>
                      <th className="tableHeadItem">{t("Name")}</th>
                      <th className="tableHeadItem">{t("ID Number")}</th>
                      <th className="tableHeadItem">{t("Phone Number")}</th>
                      <th className="tableHeadItem">{t("Email")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.selectedMarker.map(
                      (item: DataItem, index: number) => {
                        return (
                          <tr key={index}>
                            <td className="tableDataItem">{"dfgdf"}</td>
                            <td className="tableDataItem">{"dfgdf"}</td>
                            <td className="tableDataItem">{"dfgdf"}</td>
                            <td className="tableDataItem">
                              <Box className="tableEmailContainer">
                                <Typography>{"dfgdf"}</Typography>
                                <Box>
                                  <IconButton
                                    onClick={(event) =>
                                      this.handleClick(event, item.id)
                                    }
                                    data-testId={`findShowPersonModalMenuIconTestId${index}`}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                  <Popover
                                    id={id}
                                    open={open && selectedId === item.id}
                                    anchorEl={anchorEl}
                                    onClose={this.handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                  >
                                    <PopoverContainer>
                                      <MenuItem onClick={this.handleShowPerson}
                                        data-testId={`findShowPersonModalMenuOptionBtnTestId${index}`}>
                                        <span className="popoverText" data-testId={`findShowPersonModalMenuOptionTestId${index}`}>{t("View Detail")}</span>
                                      </MenuItem>
                                    </PopoverContainer>
                                  </Popover>
                                </Box>
                              </Box>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </Box>
              <Box className="modalCancelBtnContainer">
                <Button
                  variant="text"
                  className="ContractDetailsButton1"
                  onClick={this.handleCloseFindPersonModal}
                >
                  {t("Cancel")}
                </Button>
              </Box>
            </Box>
          </ContactDetailsHeader>
        </Modal>
      </Box>
    );
  };
  renderFindShowPersonModal = () => {
    const { t }: { t: TFunction } = this.props;
    return (
      <Modal open={this.state.openFindShowPersonModal}>
        <OpenFindShowPersonModalContainer>
          <Box className="openFindShowPersonModalContainer">
            <Box className="showPersonHeadingContainer">
              <Typography className="showPersonHeading" data-testId={`findShowPersonModalHeadingTestId`}>{t("Find Person")}</Typography>
              <IconButton onClick={this.handleShowPerson}>
                <ClearIcon />
              </IconButton>
            </Box>
            <Divider />
            <Box className="showPersonContentContainer">
              <Grid container spacing={3}>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Typography className="showPersonFieldLable">{t("Name")}</Typography>
                  <Typography className="showPersonFieldValue">
                  {t("Json Astin")}
                  </Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Typography className="showPersonFieldLable">
                  {t("Complex")}
                  </Typography>
                  <Typography className="showPersonFieldValue">
                    Json Astin
                  </Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Typography className="showPersonFieldLable">
                  {t("ID Number")}
                  </Typography>
                  <Typography className="showPersonFieldValue">
                  {t("Json Astin")}
                  </Typography>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Typography className="showPersonFieldLable">
                  {t("Local ID")}
                  </Typography>
                  <img
                    src={
                      "https://cdn.zeebiz.com/sites/default/files/2023/12/15/272779-aadhar-card.jpg"
                    }
                    alt=""
                    className="personIdPic"
                  />
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Typography className="showPersonFieldLable">
                  {t("Email")}
                  </Typography>
                  <Typography className="showPersonFieldValue">
                    JsonAstin@gmail.com
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="showPersonFieldLable">
                  {t("Address")}
                  </Typography>
                  <Typography className="showPersonFieldValue">
                    JsonAstin@gmail.com,sfa,asdfsdf,asdfas
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box className="showPersonBtnContainer">
                    <Button
                      className="showPersonBtnOutlined"
                      onClick={this.handleShowPerson}
                      data-testId={`findShowPersonModalCancelBtnTestId`}
                    >
                      {t("Cancel")}
                    </Button>
                    <Button className="showPersonBtnContained">{t("Select")}</Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </OpenFindShowPersonModalContainer>
      </Modal>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { t }: { t: TFunction } = this.props;
    return (
      <>
        <IssueNewContractContainer>
          <Box>
            <Breadcrumbs aria-label="breadcrumb">
              <Link href="/" className="ContactDetailsBreadcrumbs">
                {t("Lease Management")}
              </Link>
              <Link href="/" className="ContactDetailsBreadcrumbs">
              {t("Issue A New Lease")}
              </Link>
              <Link className="ContactDetailsBreadcrumbsStyled">
                {t("Issue a New Contract")}
              </Link>
            </Breadcrumbs>
            <Typography className="ContactDetailsHeading">
              {t("Issue a New Contract")}
            </Typography>
            <Paper className="ContactDetailsHeadingContainer">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Box>
                    <InputLabel>{t("Tenant Name")}</InputLabel>
                    <TextField
                      variant="outlined"
                      className="ContractDetailsTextFieldMain"
                      label={t("+ Add Tenant")}
                      fullWidth
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Box>
                    <InputLabel>{t("Owner Name")}</InputLabel>
                    <TextField
                      variant="outlined"
                      className="ContractDetailsTextFieldMain"
                      fullWidth
                      onFocus={this.handlefocus}
                      data-testId={"ownerNameTestId"}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <img src="https://cdn.zeebiz.com/sites/default/files/2023/08/02/254366-screenshot-2023-08-02-154638.png?im=FitAndFill=(1200,900)" className="idCardImg" alt="" />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <img src="https://cdn.zeebiz.com/sites/default/files/2023/08/02/254366-screenshot-2023-08-02-154638.png?im=FitAndFill=(1200,900)" className="idCardImg" alt="" />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    className="ContractDetailsTextField"
                    variant="outlined"
                    fullWidth
                  >
                    <Select label="UAE" IconComponent={ExpandMoreIcon} value={this.state.country} displayEmpty>
                    <MenuItem value={""}><em>{t("country")}</em></MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    className="ContractDetailsTextField"
                    variant="outlined"
                    fullWidth
                  >
                    <Select label="Dubai" IconComponent={ExpandMoreIcon} value={this.state.country} displayEmpty>
                    <MenuItem value={""}><em>{t("City")}</em></MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    className="ContractDetailsTextField"
                    variant="outlined"
                    fullWidth
                  >
                    <Select label="Complex" IconComponent={ExpandMoreIcon} value={this.state.country} displayEmpty>
                    <MenuItem value={""}><em>{t("Complex")}</em></MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    className="ContractDetailsTextField"
                    variant="outlined"
                    fullWidth
                  >
                    <InputLabel>{t("Complex 1")}</InputLabel>
                    <Select label="Complex 1" IconComponent={ExpandMoreIcon} value={this.state.country} displayEmpty>
                    <MenuItem value={""}><em>{t("Select complex")}</em></MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    className="ContractDetailsTextField"
                    variant="outlined"
                    fullWidth
                  >
                    <InputLabel>Building 1</InputLabel>
                    <Select label="Building 1" IconComponent={ExpandMoreIcon} value={this.state.country} displayEmpty>
                    <MenuItem value={""}><em>{t("building")}</em></MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextField"
                    label="A 101"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextField"
                    label={t("Enter Agreement Duration (in months)")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>

                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextField"
                    InputProps={{
                      endAdornment: <img src={CalenderIconGray} className="calenderGrayIcon" />
                    }}
                    fullWidth
                    placeholder="Contract Start Date"
                    onFocus={this.handleCalendarClick}
                  />
                  <input
                    type="date"
                    ref={this.inputRef}
                    style={{ display: 'none' }}
                    onChange={this.handleDateChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <TextField
                    variant="outlined"
                    className="ContractDetailsTextField"
                    label={t("Enter Rent Amount (per month)")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    className="ContractDetailsTextField"
                    variant="outlined"
                    fullWidth
                  >
                    <InputLabel>Select Currency</InputLabel>
                    <Select label="Select country" IconComponent={ExpandMoreIcon} value={this.state.country} displayEmpty>
                    <MenuItem value={""}><em>{t("Currency")}</em></MenuItem>
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </Box>

          <Box className="ContractDetailsButtonGroup">
            <Button variant="text" className="ContractDetailsButton1" onClick={this.handleBack} data-testId={"backBtnTestId"}>
            {t("Back")}
            </Button>
            <Button variant="contained" className="ContractDetailsButton2">
              {t("Next")}
            </Button>
          </Box>
          {this.renderFindPersonModal()}
          {this.renderFindShowPersonModal()}
        </IssueNewContractContainer>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withTranslation()(LeaseManagementIssueNewContractForm);
export { LeaseManagementIssueNewContractForm };
const IssueNewContractContainer = styled(Box)({
  padding: 30,
  background: "#f4f7ff",
  height: "100vh",
  "& .calenderGrayIcon": {
    cursor: "pointer"
  },
  "& .ContactDetailsHeading": {
    fontWeight: 800,
    fontSize: "30px",
    padding: "20px 0px",
  },
  "& .ContactDetailsHeadingContainer": {
    background: "white",
    padding: 20,
  },
  "& .ContactDetailsBreadcrumbs": {
    color: "black",
  },
  "& .ContactDetailsBreadcrumbsStyled": {
    color: "#768ac2",
  },
  "& .ContactDetailsHeadingText": {
    fontSize: "25px",
    fontWeight: 800,
  },
  "& .ContractDetailDownloadIconStyled": {
    height: "30px",
    width: "33px",
    marginRight: 15,
  },
  "& .ContractDetailsButton1": {
    color: "#3274ce",
    fontWeight: 800,
    marginRight: 15,
    border: "1px solid #3274ce",
    paddingRight: 60,
    paddingLeft: 60,
    cursor: "pointer",
    height: "40px",
  },
  "& .ContractDetailsButton2": {
    background: "#3274ce",
    color: "white",
    fontWeight: 800,
    cursor: "pointer",
    paddingLeft: 70,
    paddingRight: 70,
    height: "40px",
  },
  "& .ContractDetailsContentHeading": {
    fontWeight: 800,
    marginTop: 30,
    marginBottom: 10,
  },
  "& .ContractDetailsContentHeadingText": {
    color: "gray",
  },
  "& .ContractDetailsButtonGroup": {
    float: "right",
    paddingTop: 50,
  },
  "& .ContractDetailsHeader": {
    color: "#fcd2ab",
    fontWeight: "700",
    marginLeft: 10,
  },
  "& .ContractDetailsHeader2": {
    marginLeft: 35,
    fontWeight: "bold",
  },
  "& .ContractDetailsContent": {
    marginTop: 10,
  },
  "& .ContractDetailsChild": {
    marginTop: 10,
  },
  "& .ContractDetailsTextFieldMain": {
    marginTop: 8,
    background: "rgb(249,249,249)",
    borderRadius: "10px",
    border: "1px solid #d3ac91",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px",
      border: "1px solid #d3ac91",
    },
  },
  "& .ContractDetailsTextField": {
    marginTop: 15,
    background: "#f9f8f8",
    borderRadius: "10px",
    border: "1px solid #f2f2f2",
    "& .MuiTextField-root": {
      marginTop: 15,
      background: "#f9f8f8",
      borderRadius: "10px",
      border: "1px solid #f2f2f2",
    }
  },
  "& .dividerLine": {
    width: "100%"
  },
  "& .idCardImg": {
    width: "130px",
    height: "100px"
  }
});
const ContactDetailsHeader = styled(Box)({
  height: "100vh",
  border: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "scroll",

  "& .modalContainer": {
    background: "white",
    width: "70%",
  },

  "& .ContractDetailsModal": {
    display: "flex",
    justifyContent: "space-between",
    padding: 20,
  },
  "& .ContractDetailsModalHeading": {
    fontWeight: 700,
  },
  "& .ContractDetailsModalHeadingChild": {
    color: "#c2c2c2",
    padding: 15,
    fontSize: 14,
  },
  "& .ContractDetailsTextFieldHeading": {
    fontSize: 14,
  },
  "& .ContractDetailsModalTextfield": {
    display: "flex",
    justifyContent: "space-between",
    padding: 20,
  },
  "& .ContractDetailsButton": {
    background: "#3274ce",
    color: "white",
    fontWeight: 800,
    height: 55,
    marginTop: 21,
    borderRadius: "10px",
  },
  "& .ContractDetailsTextFieldModal": {
    border: "1px solid #f1f1f1",
    borderRadius: "10px",
    background: "#f9f9f9",
    "& .MuiTextField-root": {
      borderRadius: "10px",
      background: "#f9f9f9",
      border: "1px solid #f1f1f1"
    }
  },
  "& .modalCancelBtnContainer": {
    padding: "20px",
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .ContractDetailsButton1": {
    color: "#3274ce",
    fontWeight: 800,
    border: "1px solid #3274ce",
    paddingRight: 40,
    paddingLeft: 40,
    cursor: "pointer",
  },
  "& .modalTableContainer": {
    padding: "20px",
  },
  "& .tableHeadItem": {
    fontSize: "16px",
    fontWeight: 900,
    textAlign: "left",
    borderBottom:"none",
    color:"#272a2b"
  },
  "& .tableEmailContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .tableDataItem": {
    textAlign: "left",
    borderBottom: "1px solid #f1f1f1",
  },
  "& .tableContainer": {
    borderCollapse: "collapse",
  },

});
const PopoverContainer = styled(Box)({
  width: "150px",
  "& .popoverText": {
    padding: "10px 20px ",
  },
});
const OpenFindShowPersonModalContainer = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  overflow: "scroll",
  "& .openFindShowPersonModalContainer": {
    borderRadius: "10px",
    backgroundColor: "#fff",
    width: "70%",
  },
  "& .showPersonHeadingContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
  },
  "& .showPersonHeading": {
    fontSize: "18px",
    fontWeight: 800,
  },
  "& .showPersonContentContainer": {
    padding: "20px",
  },
  "& .showPersonFieldLable": {
    fontSize: "16px",
    fontWeight: 800,
    marginBottom: "5px",
  },
  "& .showPersonFieldValue": {
    color: "#a8a8a8",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .personIdPic": {
    width: "100px",
    height: "70px",
    objectFit: "contain",
  },
  "& .showPersonBtnContainer": {
    display: "flex",
    justifyContent: "flex-end",
    gap: "20px",
    alignItems: "center",
  },
  "& .showPersonBtnOutlined": {
    width: "180px",
    height: "40px",
    color: "#2b6fec",
    fontWeight: 900,
    backgroundColor: "#fff",
    border: "1px solid #2b6fec",
    borderRadius: "6px",
  },
  "& .showPersonBtnContained": {
    width: "180px",
    height: "40px",
    color: "#fff",
    fontWeight: 900,
    backgroundColor: "#2b6fec",
    borderRadius: "6px",
  },
});
// Customizable Area End