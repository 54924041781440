// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  t:(value:string)=> string
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  selectedMarker: Array<DataItem>;
  anchorEl: HTMLElement | null;
  selectedId: number | null;
  // Customizable Area End
}

// Customizable Area Start
export interface DataItem {
  id: number;
  name: string;
}
// Customizable Area End

interface SS {
  id: string;
}
export default class LeaseManagementIssueNewContractController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      selectedMarker: [],
      anchorEl: null,
      selectedId: null,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount()
    this.setState({
      selectedMarker: [
        { id: 1, name: "Marker 1" },
        { id: 2, name: "Marker 2" },
        { id: 3, name: "Marker 3" },
        { id: 4, name: "Marker 4" },
        { id: 5, name: "Marker 5" },
        { id: 6, name: "Marker 6" },
        { id: 7, name: "Marker 7" },
        { id: 8, name: "Marker 8" },
      ],
    });
    // Customizable Area End
  }
  
  // Customizable Area Start
  handleClick = (event: React.MouseEvent<HTMLElement>, id: number) => {
    this.setState({ anchorEl: event.currentTarget, selectedId: id });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, selectedId: null });
  };
  // Customizable Area End
}