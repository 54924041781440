// Customizable Area Start
import React from "react";
import { Box, Card, CardMedia } from "@material-ui/core";
import { NoUserIcon } from "../assets";
import { Menu, MenuItem } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const AwaitAcceptCard = ({ t ,handleDelete,handleDialog,item,dateFormat }: any) => {
  return (
    <Card className="community__user_details">
      <Box className="community_card_top">
        <CardMedia component="img" height="140" image={NoUserIcon} alt="image" />
        <Box className="building-unit-box">
          <h4 className="bold-text">{item.attributes.apartment_management?.apartment_name}</h4>
        </Box>
        <p className="name_heading">{item.attributes.full_name}</p>
        <label>Invitation Sent On: {dateFormat(item.attributes?.created_at)}</label>
        <Box className="roles-box">
          <span className="role">{item.attributes.role.name}</span>
        </Box>
      </Box>
      <Box className="community__user_menu">
        <Menu menuButton={<MoreVertIcon />}>
          <MenuItem onClick={() => {handleDialog(item)}}>{t("Resend Request")}</MenuItem>
          <MenuItem onClick={() => {handleDelete(item)}}>{t("Delete Invitation Request")}</MenuItem>
        </Menu>
      </Box>
    </Card>
  );
};

export default AwaitAcceptCard;
// Customizable Area End
