// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import React from "react";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  t:(value:string)=> string
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  selectedMarker: Array<DataItem>;
  anchorEl: HTMLElement | null;
  selectedId: number | null;
  openFindPersonModal: boolean;
  openFindShowPersonModal: boolean;
  selectedDate:string;
  country:string;
  // Customizable Area End
}

// Customizable Area Start
export interface DataItem {
  id: number;
  name: string;
}
// Customizable Area End

interface SS {
  id: string;
}
export default class LeaseManagementIssueNewContractFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  public inputRef: React.RefObject<HTMLInputElement>;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      selectedMarker: [
        { id: 1, name: "Marker 1" },
        { id: 2, name: "Marker 2" },
      ],
      anchorEl: null,
      selectedId: null,
      openFindPersonModal: false,
      openFindShowPersonModal: false,
      selectedDate:"",
      country:"",
      // Customizable Area End
    };
    // Customizable Area Start
    this.inputRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount()
    // Customizable Area End
  }
  
  // Customizable Area Start
  handleClick = (event: React.MouseEvent<HTMLElement>, id: number) => {
    this.setState({ anchorEl: event.currentTarget, selectedId: id });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, selectedId: null });
  };
  handleShowPerson = () => {
    this.setState({
      openFindShowPersonModal: !this.state.openFindShowPersonModal,
      openFindPersonModal: !this.state.openFindPersonModal,
      anchorEl: null,
      selectedId: null
    });
  };
  handleCloseFindPersonModal = () => {
    this.setState({ openFindPersonModal: false });
  };
  handlefocus=()=>{
    this.setState({openFindPersonModal:true})
  }
  handleBack=()=>{
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "IssueNewContractForm"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  }
  handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedDate: e.target.value });
  };
  handleCalendarClick = () => {
    if (this.inputRef.current) {
      this.inputRef.current.click();
    }
  };
  // Customizable Area End
}