// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  Tabs,
  Tab,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  Link,
  Input,
} from "@material-ui/core";
import { CSSProperties, withStyles } from "@material-ui/styles";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import CloseIcon from "@material-ui/icons/Close";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { nextIcon, previousIcon, UploadIcon, Close, uploadbw } from "./assets";
import { Close_Icon, CloseIcon as DeleteImageIcon, TickIcon, mapLocation, uploadDocIcon } from "../assets";
import Slider from "react-slick";
import ComplexDetailsPageController, { Props } from "./ComplexDetailsPageController.web";
import { ModalWrapper } from "../../../../components/src/ModalWrapper/index";
import AlertErrorWeb from "../../../../components/src/AlertError.web";
import Loader from "../../../../components/src/Loader.web";
import SearchInput from "../../../../components/src/CommonComponents/SearchInput.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";
import { orange } from "@material-ui/core/colors";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import NormalTextArea from "../../../../components/src/CommonComponents/NormalTextArea.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import { ErrorHandler, LanguageAttributeHandler } from "../../../../components/src/UtilFunction.web";
import GoogleMapReact from "google-map-react";

const settings = {
  swipeToSlide: true,
  slidesToShow: 5,
  infinite: false,
};

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#2c6fed",
    background: "#fff",
    borderRadius: "8px 8px 0px 0px",
    fontWeight: 1400,
    fontSize: "15px",
    width: "fit-content",
    opacity: 1,
    marginRight: "0px",
    "&:focus": {
      opacity: 1,
    },
    "&.Mui-selected": {
      background: "#2B6FED",
      color: "#fff",
      fontWeight: 600,
    },
  },
}))((props: { label: string }) => <Tab disableRipple {...props} />);

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
    },
  },
})((props: { value: number; onChange: (event: React.ChangeEvent<{}>, newValue: number) => void }) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

const validationSchemaObj = Yup.object().shape({
  roles: Yup.string(),
});

const validationSchema = Yup.object().shape({
  Details: Yup.string().required("Details are required"),
  sharedFacility: Yup.string().required("Shared Facility required"),
  totalArea: Yup.string().required("Total Area is required"),
  fees: Yup.string().required("Reservation fees is required"),
  floorDocument: Yup.mixed().required("Please upload a Floor Document File"),
});

const OrangeRadio: any = withStyles({
  root: {
    "&$checked": {
      color: orange[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} checkedIcon={<CircleCheckedFilled style={{ color: "#FC8434" }} />} />);

const LocationPin: React.FC<{ mapLocation: any; lat: string | number; lng: string | number }> = ({
  mapLocation,
  lat,
  lng,
}) => <img src={mapLocation} alt="Location Pin" />;

class ComplexDetailsPage extends ComplexDetailsPageController {
  constructor(props: Props) {
    super(props);
  }

  menuOptions = [
    this.state.subId && {
      title: "Extend Subscription Duration",
      clickFn: this.handleOpenSubscription,
      testId: "subscription",
    },
    this.state.subId && {
      title: "Upgrade Plan",
      clickFn: this.handleUpgradePlan,
    },
    {
      title: "View Plan Details",
      clickFn: this.handleOpenJurisdication,
    },
    {
      title: "Deactivate Complex",
      clickFn: this.handleDeactivateComplexDialog,
    },
    {
      title: "Manage Jurisdiction",
      clickFn: this.handleOpenJurisdication,
      testId: "jurid",
    },
  ].filter(Boolean);

  sharedArea = (t: (val: string) => string) => (
    <Grid container spacing={4}>
      {this.state.companyDetails?.shared_area?.map((document: any, i: any) => {
        return (
          <Grid item xs={4} key={i}>
            <Box
              className="shared-navigation"
              style={webStyleD.docInfoBoxs}
              onClick={() => this.handleSharedAreaNavigation(document.id)}
            >
              <Box style={webStyleD.docTitles}>
                <Typography className="bold-text">{document.name}</Typography>
              </Box>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );

  renderManageJurisdiction = (t: (val: string) => string, language: string) => (
    <ModalWrapper open={this.state.jurisdicationModal} closeFn={this.handleCloseJurisdication} className="manage-jur">
      <Box style={webStyleD.juridBoxes}>
        <Box style={webStyleD.juridHeadingd}>
          <Typography style={webStyleD.juridTexts} className="bold-text manage-head">
            {t("Manage Jurisdiction")}
          </Typography>
          <CloseIcon
            data-testid="close_jurid"
            style={webStyleD.closeIconD}
            onClick={this.handleCloseJurisdication}
            className="manage-close"
          />
        </Box>
        <Box style={{ marginTop: "14px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <NormalTextField
                t={t}
                language={language}
                label="Current Jurisdiction"
                value={this.state.currentJurisdiction.name}
                name="title"
                isLabel={true}
              />
            </Grid>
            <Grid item xs={6}>
              <NormalSelect
                t={t}
                language={language}
                value={this.state.jId}
                name="country"
                label="New Jurisdiction"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({ jId: e.target.value }, () => this.getJurisdictionById(e.target.value))
                }
                option={this.state.allJurisdiction}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography style={webStyleD.juridTitled}>{t("Currency")}</Typography>
              <Typography style={webStyleD.juridTitleValue} className="jurisdiction-value">
                {this.state.currentJurisdiction.currency}
              </Typography>
              <Typography style={webStyleD.juridTitled}>{t("Timezone")}</Typography>
              <Typography style={webStyleD.juridTitleValue} className="jurisdiction-value">
                {this.state.currentJurisdiction.timezone}
              </Typography>
              <Typography style={webStyleD.juridTitled}>{t("Unit of Measurement")}</Typography>
              <Typography style={webStyleD.juridTitleValue} className="jurisdiction-value">
                {this.state.currentJurisdiction.measurement}
              </Typography>
              <Typography style={webStyleD.juridTitled}>{t("Management Fee Calculation Formula")}</Typography>
              <Typography style={webStyleD.juridTitleValue} className="jurisdiction-value">
                {this.state.currentJurisdiction.formula}
              </Typography>
              <Typography style={webStyleD.juridTitled}>{t("Mobile No length")}</Typography>
              <Typography style={webStyleD.juridTitleValue} className="jurisdiction-value">
                {this.state.currentJurisdiction.mobileLength}
              </Typography>
              <Typography style={webStyleD.juridTitled}>{t("Default Rent Contract")}</Typography>
              <Typography style={webStyleD.juridTitleValue} className="jurisdiction-value">
                {this.state.currentJurisdiction.contract}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={webStyleD.juridTitled} className="jurisdiction-title">
                {t("Currency")}
              </Typography>
              <Typography style={webStyleD.juridTitleValue}>{this.state.newJurisdiction.currency || "-"}</Typography>
              <Typography style={webStyleD.juridTitled} className="jurisdiction-title">
                {t("Timezone")}
              </Typography>
              <Typography style={webStyleD.juridTitleValue}>{this.state.newJurisdiction.timezone || "-"}</Typography>
              <Typography style={webStyleD.juridTitled} className="jurisdiction-title">
                {t("Unit of Measurement")}
              </Typography>
              <Typography style={webStyleD.juridTitleValue}>{this.state.newJurisdiction.measurement || "-"}</Typography>
              <Typography style={webStyleD.juridTitled} className="jurisdiction-title">
                {t("Management Fee Calculation Formula")}
              </Typography>
              <Typography style={webStyleD.juridTitleValue}>{this.state.newJurisdiction.formula || "-"}</Typography>
              <Typography style={webStyleD.juridTitled} className="jurisdiction-title">
                {t("Mobile No length")}
              </Typography>
              <Typography style={webStyleD.juridTitleValue}>
                {this.state.newJurisdiction.mobileLength || "-"}
              </Typography>
              <Typography style={webStyleD.juridTitled} className="jurisdiction-title">
                {t("Default Rent Contract")}
              </Typography>
              <Typography style={webStyleD.juridTitleValue}> {this.state.newJurisdiction.contract || "-"}</Typography>
            </Grid>
          </Grid>
          <Box style={webStyleD.juridActionsData} className="jurid_modal_actions">
            <Button
              variant="outlined"
              color="primary"
              data-testid="secondary_btn"
              className="jurid_modal_cancel_btn manage-jur-close"
              style={webStyleD.closeDeleteBtn}
              onClick={this.handleCloseJurisdication}
            >
              <span className="bold-text">{"Cancel"}</span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              data-testid="primary_btn"
              style={webStyleD.confirmDeleteBtnData}
              onClick={this.handleManageJurisdiction}
              className="jurid_modal_save_btn manage-save-jur"
            >
              <span className="bold-text">{"Save"}</span>
            </Button>
          </Box>
        </Box>
      </Box>
    </ModalWrapper>
  );

  formValue = {};
  uploadImages: any;

  handleShareClose = () => {
    this.setState({ openShared: false });
  };

  renderSharedArea = (t: (val: string) => string, language: string) => (
    <Dialog
      className="edit-profile complex-shared-area-dialog"
      open={this.state.openShared}
      dir={languageCondition(language, "rtl", "ltr")}
      scroll="paper"
      fullWidth
      maxWidth="md"
    >
      <MuiDialogTitle disableTypography className="dialog-heading">
        <Typography variant="h6" className="bold-text">
          {t("Add New Shared Area")}
        </Typography>
        <IconButton onClick={this.handleShareClose}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <Formik
        enableReinitialize={true}
        initialValues={this.state.sharedAreaList}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          this.handleShareClose();
          this.handleSharedDetails(values, resetForm);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit} translate>
              <DialogContent dividers>
                <Grid container spacing={2} className="edit-building">
                  <Grid item md={12}>
                    <NormalTextField
                      t={t}
                      language={language}
                      label="Shared Facility's Name"
                      value={values.sharedFacility}
                      name="sharedFacility"
                      isLabel={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {ErrorHandler(t, touched.sharedFacility, errors.sharedFacility)}
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel>{t("Upload Photos")}</InputLabel>
                    <Grid container spacing={4}>
                      <Grid item md={3}>
                        <Box
                          className="upload-photo shared-area-upload-images"
                          onClick={() => this.uploadImages.click()}
                        >
                          <img src={uploadbw} alt="shared-area-images" />
                        </Box>
                        <input
                          type="file"
                          multiple
                          onBlur={handleBlur}
                          accept="image/*"
                          className="complex-shred-area-images"
                          ref={(ref: any) => (this.uploadImages = ref)}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const files = e.target.files;
                            if (files) {
                              for (let file of files) {
                                let reader = new FileReader();
                                reader.onloadend = () => {
                                  values.images = [...values.images, reader.result];
                                  setFieldValue("images", values.images);
                                };
                                reader.readAsDataURL(file);
                              }
                            }
                          }}
                          name="photos"
                          style={{ display: "none" }}
                        />
                      </Grid>
                      {values?.images?.map((image: any, index: number) => {
                        return (
                          <Grid item md={3} key={index} className="shared-area-images-grid">
                            <Box className="building-image shared-area-image-box">
                              <img
                                src={DeleteImageIcon}
                                className="delete-image shared-area-delete-cross-image"
                                onClick={() => {
                                  const remainImage = values.images.filter((img: any, idx: number) => idx !== index);
                                  setFieldValue("images", remainImage);
                                }}
                              />
                              <img src={image} alt="shared-area-image-photos" />
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                    {ErrorHandler(t, touched.images, errors.images)}
                  </Grid>
                  <Grid item md={12}>
                    <NormalTextArea
                      t={t}
                      language={language}
                      label={t("Details")}
                      value={values.Details}
                      isLabel={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="Details"
                    />
                    {ErrorHandler(t, touched.Details, errors.Details)}
                  </Grid>
                  <Grid item md={12}>
                    <NormalTextField
                      t={t}
                      language={language}
                      label={t("Total Area")}
                      value={values.totalArea}
                      name="totalArea"
                      isLabel={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {ErrorHandler(t, touched.totalArea, errors.totalArea)}
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel>{t("Reservation Fees (Per hour)")}</InputLabel>
                    <Box className="measurement-modal-box">
                      <Input
                        className="input-with-icon"
                        fullWidth
                        placeholder={t("Reservation Fees (Per hour)")}
                        value={values.fees}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="fees"
                      />
                      <Box
                        className={`${languageCondition(
                          language,
                          "measurement-arabic-value",
                          "measurement-modal-value"
                        )}`}
                      >
                        {LanguageAttributeHandler(
                          language,
                          this.state.newJurisdictionDetails?.currency,
                          this.state.newJurisdictionDetails?.currency_ar
                        )}
                      </Box>
                    </Box>
                    {ErrorHandler(t, touched.fees, errors.fees)}
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel>{t("Floor Plan")}</InputLabel>
                    <Box className="floor-plan-box">
                      <input
                        type="file"
                        ref={(ref: any) => (this.uploadFile = ref)}
                        style={{ display: "none" }}
                        accept=".pdf"
                        className="floor-plan-pdf"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const files = e.target.files;
                          if (files && files.length > 0) {
                            const file: File = files[0];
                            setFieldValue("floorDocument", file);
                          }
                        }}
                        onBlur={handleBlur}
                        name="floorPlan"
                      />
                      {!values.floorDocument ? (
                        <span className="placeholder">{t("Floor Plan")}</span>
                      ) : (
                        <Chip
                          label={(values as any).floorDocument?.name}
                          onDelete={() => {
                            setFieldValue("floorDocument", null);
                          }}
                          deleteIcon={<img src={Close_Icon} style={{ margin: "0 12px 0 12px" }} />}
                        />
                      )}
                      <img className="shared-floor-upload" onClick={() => this.uploadFile.click()} src={uploadbw} />
                    </Box>
                    {ErrorHandler(t, touched.floorDocument, errors.floorDocument)}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                <Button className="cancel-button" onClick={this.handleShareClose}>
                  {t("Cancel")}
                </Button>
                <Button type="submit" className="add-button" style={{ margin: "0" }}>
                  {t("Save")}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );

  renderDocumentTab = () => {
    return (
      <Grid container spacing={4}>
        {this.state.document.map((document: { title: string; count: string | number }, i: number) => {
          return (
            <Grid item xs={4} key={i}>
              <Box style={webStyleD.docInfoBoxs}>
                <Box style={webStyleD.docTitles}>
                  <DescriptionOutlinedIcon style={webStyleD.docIcons} />
                  <Typography className="bold-text">{document.title}</Typography>
                </Box>
                <Box style={webStyleD.docCounts}>
                  <Typography className="bold-text" style={webStyleD.docCountTexts}>
                    {document.count}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  renderTabComponent = (t: (val: string) => string) => {
    const { tabNo } = this.state;
    switch (tabNo) {
      case 0:
        return this.renderDocumentTab();
      default:
        return this.sharedArea(t);
    }
  };

  renderExtendedSub = (t: (val: string) => string, language: string) => (
    <ModalWrapper
      open={this.state.subscriptionModal}
      closeFn={this.handleCloseSubscription}
      className="complex-extend-sub-modal"
    >
      <Box style={webStyleD.subBoxes}>
        <Box style={webStyleD.juridHeadingd}>
          <Typography style={webStyleD.juridTexts} className="bold-text ExtendSubscriptionDuration">
            {t("Extend Subscription Duration")}
          </Typography>
          <CloseIcon
            style={webStyleD.closeIconD}
            onClick={this.handleCloseSubscription}
            data-testid="close_subscription"
          />
        </Box>
        <Box style={webStyleD.subContentData}>
          <Formik
            enableReinitialize={true}
            initialValues={this.state.subscriptionExtendDetails}
            onSubmit={(values, { resetForm }) => {
              this.setState({ loading: true, subscriptionModal: false }, () => {
                this.upgradeSubscription(values);
                resetForm();
              });
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit} translate>
                  <Grid container spacing={2}>
                    {this.commonExtendedSubscription(t)}
                    <Grid item xs={12} style={webStyleD.selectGridData}>
                      <NormalTextField
                        t={t}
                        language={language}
                        label="Extend Duration to"
                        value={values.date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isLabel={true}
                        onFocus={(e: React.ChangeEvent<HTMLInputElement>) => (e.target.type = "date")}
                        type="text"
                        placeholder="Select Date"
                        name="date"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <RadioGroup
                          style={{ flexDirection: "row" }}
                          value={values.isPaid}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="isPaid"
                        >
                          <FormControlLabel
                            value="true"
                            control={<OrangeRadio />}
                            label={t("Paid Extension")}
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="false"
                            control={<OrangeRadio />}
                            label={t("Free Extension")}
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} style={webStyleD.selectGridData}>
                      {values.isPaid !== "true" ? (
                        <NormalTextArea
                          t={t}
                          language={language}
                          label={t("Justifications for free extension")}
                          value={values.reason}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="reason"
                          isLabel={true}
                          type="text"
                        />
                      ) : (
                        <NormalTextField
                          t={t}
                          language={language}
                          label={t("Payment Reference Number")}
                          value={values.refNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="refNumber"
                          isLabel={true}
                          type="text"
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Box style={webStyleD.juridActionsData} className="sub_modal_actions">
                    <Button
                      variant="outlined"
                      color="primary"
                      data-testid="secondary_btn"
                      style={webStyleD.closeDeleteBtn}
                      onClick={this.handleCloseSubscription}
                      className="sub_modal_cancel_btn"
                    >
                      <span className="bold-text">{t("CANCEL")}</span>
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className="sub_modal_save_btn"
                      style={webStyleD.confirmDeleteBtnData}
                      type="submit"
                    >
                      <span className="bold-text">{t("Submit")}</span>
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </ModalWrapper>
  );

  renderUpgradeSub = (t: (val: string) => string, language: string) => (
    <ModalWrapper
      open={this.state.upgradesubscriptionModal}
      closeFn={this.handleCloseUpgradeSubscription}
      className="complex-upgrade-sub-modal"
    >
      <Box style={webStyleD.subBoxes}>
        <Box style={webStyleD.juridHeadingd}>
          <Typography style={webStyleD.juridTexts} className="bold-text">
            {t("Upgrade Plan")}
          </Typography>
          <CloseIcon
            style={webStyleD.closeIconD}
            onClick={this.handleCloseUpgradeSubscription}
            data-testid="close_subscription"
          />
        </Box>
        <Box style={webStyleD.subContentData}>
          <Typography style={webStyleD.subExtraInfodata}>
            {t("The request will be submitted to the COO for his review and approval")}
          </Typography>
          <Formik
            enableReinitialize={true}
            initialValues={this.state.planDetail}
            onSubmit={(values, { resetForm }) => {
              this.setState({ loading: true, upgradesubscriptionModal: false }, () => {
                this.handleExtendSub(values);
                resetForm();
              });
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit} translate>
                  <Box>
                    <Grid container spacing={2}>
                      {this.commonExtendedSubscription(t)}
                      <Grid item xs={12} style={webStyleD.selectGridData}>
                        <NormalSelect
                          t={t}
                          language={language}
                          label="Upgrade Plan to"
                          option={[]}
                          value={values.planId}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="planId"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl>
                          <RadioGroup
                            style={{ flexDirection: "row" }}
                            value={values.isPaid}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="isPaid"
                          >
                            <FormControlLabel
                              value="true"
                              control={<OrangeRadio />}
                              label={t("Paid")}
                              labelPlacement="end"
                            />
                            <FormControlLabel
                              value="false"
                              control={<OrangeRadio />}
                              label={t("Free")}
                              labelPlacement="end"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={webStyleD.selectGridData}>
                        <NormalTextField
                          t={t}
                          language={language}
                          label={t("Payment Reference Number")}
                          isLabel={true}
                          type="text"
                          value={values.refNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="refNumber"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box style={webStyleD.juridActionsData} className="sub_modal_actions">
                    <Button
                      variant="outlined"
                      color="primary"
                      data-testid="secondary_btn"
                      style={webStyleD.closeDeleteBtn}
                      onClick={this.handleCloseUpgradeSubscription}
                      className="sub_modal_cancel_btn"
                    >
                      <span className="bold-text">{"Cancel"}</span>
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      data-testid="primary_btn"
                      className="sub_modal_save_btn"
                      type="submit"
                      style={webStyleD.confirmDeleteBtnData}
                    >
                      <span className="bold-text">{"Submit"}</span>
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </ModalWrapper>
  );

  renderUploadDocuments = (t: (val: string) => string) => (
    <Dialog
      className="edit-profile edit-share-area-modal complex-all-document-modal"
      open={this.state.uploadModal}
      scroll="paper"
      fullWidth
      maxWidth="md"
    >
      <MuiDialogTitle disableTypography className="dialog-heading">
        <Typography variant="h6" className="bold-text">
          {"Upload Documents"}
        </Typography>
        <IconButton onClick={this.handleCloseUploadModal} id="closeUploadModal">
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <Formik
        enableReinitialize={true}
        initialValues={this.state.initialValues}
        validationSchema={validationSchemaObj}
        onSubmit={(values, { resetForm }) => {
          this.setState({ uploadModal: false }, () => {
            this.handleSubmit(values, resetForm);
          });
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit} translate>
              <DialogContent dividers>
                <Grid container spacing={2} className="edit-building">
                  <Grid item md={12}>
                    <InputLabel>{t("Policy")}</InputLabel>
                    <Box className="floor-plan-box">
                      <input
                        type="file"
                        ref={(ref: any) => (this.uploadFile = ref)}
                        style={{ display: "none" }}
                        accept=".pdf"
                        className="testPolicyLoad"
                        onChange={(e: any) => {
                          const file = e.target.files[0];
                          setFieldValue("policyFile", file);
                          setFieldValue("policyName", file.name);
                        }}
                        onBlur={handleBlur}
                        name="policyName"
                      />
                      {!values.policyFile ? (
                        <span className="placeholder">{t("Upload Policy Document")}</span>
                      ) : (
                        <Chip
                          label={values.policyName}
                          onDelete={() => {
                            setFieldValue("policyFile", null);
                            setFieldValue("policyName", "");
                          }}
                          deleteIcon={<img src={Close} style={{ margin: "0 12px 0 12px" }} />}
                        />
                      )}
                      <img onClick={() => this.uploadFile.click()} src={UploadIcon} id="policyUp" />
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel>{t("Guidelines")}</InputLabel>
                    <Box className="floor-plan-box">
                      <input
                        type="file"
                        ref={(ref: any) => (this.uploadGuideFile = ref)}
                        style={{ display: "none" }}
                        accept=".pdf"
                        className="guide-upload-data"
                        onChange={(e: any) => {
                          const file = e.target.files[0];
                          setFieldValue("guideFile", file);
                          setFieldValue("guideName", file.name);
                        }}
                        onBlur={handleBlur}
                        name="guideName"
                      />
                      {!values.guideFile ? (
                        <span className="placeholder">{t("Upload Guidelines Document")}</span>
                      ) : (
                        <Chip
                          label={values.guideName}
                          onDelete={() => {
                            setFieldValue("guideFile", null);
                            setFieldValue("guideName", "");
                          }}
                          deleteIcon={<img src={Close} style={{ margin: "0 12px 0 12px" }} />}
                        />
                      )}
                      <img onClick={() => this.uploadGuideFile.click()} src={UploadIcon} id="guideUp" />
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel>{t("Roles")}</InputLabel>
                    <Box className="floor-plan-box">
                      <input
                        type="file"
                        ref={(ref: any) => (this.uploadRoleFile = ref)}
                        style={{ display: "none" }}
                        accept=".pdf"
                        className="role-plan-pdf"
                        onChange={(e: any) => {
                          const file = e.target.files[0];
                          setFieldValue("roleFile", file);
                          setFieldValue("roleName", file.name);
                        }}
                        onBlur={handleBlur}
                        name="roleName"
                      />
                      {!values.roleFile ? (
                        <span className="placeholder">{t(" Upload Role Document")}</span>
                      ) : (
                        <Chip
                          label={values.roleName}
                          onDelete={() => {
                            setFieldValue("roleFile", null);
                            setFieldValue("roleName", "");
                          }}
                          deleteIcon={<img src={Close} style={{ margin: "0 12px 0 12px" }} />}
                        />
                      )}
                      <img onClick={() => this.uploadRoleFile.click()} src={UploadIcon} id="roleUp" />
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel>{t("Resolutions")}</InputLabel>
                    <Box className="floor-plan-box">
                      <input
                        type="file"
                        ref={(ref: any) => (this.uploadResolveFile = ref)}
                        style={{ display: "none" }}
                        accept=".pdf"
                        className="floor-plan-pdf resolve-plan-pdf"
                        onChange={(e: any) => {
                          const file = e.target.files[0];
                          setFieldValue("resolveFile", file);
                          setFieldValue("resolveName", file.name);
                        }}
                        onBlur={handleBlur}
                        name="resolveName"
                      />
                      {!values.resolveFile ? (
                        <span className="placeholder">{t("Upload Resolution Document")}</span>
                      ) : (
                        <Chip
                          label={values.resolveName}
                          onDelete={() => {
                            setFieldValue("resolveFile", null);
                            setFieldValue("resolveName", "");
                          }}
                          deleteIcon={<img src={Close} style={{ margin: "0 12px 0 12px" }} />}
                        />
                      )}
                      <img onClick={() => this.uploadResolveFile.click()} src={UploadIcon} id="resolveUp" />
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel>{t("Building Plans")}</InputLabel>
                    <Box className="floor-plan-box">
                      <input
                        type="file"
                        ref={(ref: any) => (this.uploadBuildingFile = ref)}
                        style={{ display: "none" }}
                        accept=".pdf"
                        className="floor-plan-pdf build-plan-pdf"
                        onChange={(e: any) => {
                          const file = e.target.files[0];
                          setFieldValue("buildingFile", file);
                          setFieldValue("buildingName", file.name);
                        }}
                        onBlur={handleBlur}
                        name="buildingName"
                      />
                      {!values.buildingFile ? (
                        <span className="placeholder">{t("Upload Building Plan Document")}</span>
                      ) : (
                        <Chip
                          label={values.buildingName}
                          onDelete={() => {
                            setFieldValue("buildingFile", null);
                            setFieldValue("buildingName", "");
                          }}
                          deleteIcon={<img src={Close} style={{ margin: "0 12px 0 12px" }} />}
                        />
                      )}
                      <img onClick={() => this.uploadBuildingFile.click()} src={UploadIcon} id="buildUp" />
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                <Button type="submit" className="add-button" style={{ margin: "0" }} id="uploadSaveData">
                  {t("Save")}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );

  returnTabHeading = (t: (val: string) => string) => {
    const { tabNo } = this.state;
    let headingText, buttonContent, buttonAction;

    switch (tabNo) {
      case 0:
        headingText = t("Documents");
        buttonContent = t("Upload");
        buttonAction = this.handleOpenUploadModal;
        break;
      default:
        headingText = t("List of Shared Facilities");
        buttonContent = t("+ADD NEW SHARED FACILITY");
        buttonAction = this.handleSharedArea;
        break;
    }

    return (
      <Box style={webStyleD.documentBoxHeadings}>
        <Typography style={webStyleD.buildingHeadings} className="bold-text">
          {headingText}
        </Typography>
        <Box style={webStyleD.uploadButtons} onClick={buttonAction} data-testid="upload_doc">
          <>
            {tabNo === 0 && (
              <>
                <img src={uploadDocIcon} />
                <Typography style={webStyleD.uploadTexts} className="bold-text">
                  {buttonContent}
                </Typography>
              </>
            )}
            {tabNo === 1 && (
              <Button style={webStyleD.addSharedBtnn} color="primary" variant="contained">
                <span className="bold-text" style={webStyleD.btnHighBtn}>
                  {buttonContent}
                </span>
              </Button>
            )}
          </>
        </Box>
      </Box>
    );
  };

  commonExtendedSubscription = (t: (val: string) => string) => {
    return (
      <>
        <Grid style={webStyleD.juridGrid} item xs={4} className="mainJur-grid">
          <Typography style={webStyleD.juridTitled} className="mainJur-grid">
            {t("Subscription Expiration Date")}:
          </Typography>
          <Typography style={webStyleD.subValueData} className="bold-text mainJur-gridData">
            {this.state.planExtendDetail.expiryData}
          </Typography>
        </Grid>
        <Grid style={webStyleD.juridGrid} item xs={4} className="mainJur-grid">
          <Typography style={webStyleD.juridTitled}>{t("Subscription Status")}:</Typography>
          <Typography style={webStyleD.subValueData} className="bold-text mainJurGrid">
            {this.state.planExtendDetail.status}
          </Typography>
        </Grid>
        <Grid style={webStyleD.juridGrid} item xs={4} className="mainJur-grid">
          <Typography style={webStyleD.juridTitled}>{t("Days to expiration")}:</Typography>
          <Typography style={webStyleD.subValueData} className="bold-text">
            {this.state.planExtendDetail.day}
          </Typography>
        </Grid>
      </>
    );
  };

  complexDetailMapDialog = (t: (val: string) => string, language: string) => {
    return (
      <Dialog
        scroll="paper"
        className="edit-profile chairman-map-modal"
        fullWidth
        dir={languageCondition(language, "rtl", "ltr")}
        maxWidth="sm"
        open={this.state.isOpenMapOpen}
      >
        <MuiDialogTitle disableTypography className="dialog-heading">
          <Typography variant="h6" className="bold-text">
            {t("Location")}
          </Typography>
          <IconButton onClick={this.handleToggleMapDialog}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        {this.state.location.lat && this.state.location.long ? (
          <Box className="google-map-box">
            <GoogleMapReact
              defaultCenter={{
                lng: this.state.location.long,
                lat: this.state.location.lat,
              }}
              defaultZoom={15}
            >
              <LocationPin mapLocation={mapLocation} lat={this.state.location.lat} lng={this.state.location.long} />
            </GoogleMapReact>
          </Box>
        ) : (
          <Box className="no-google-map-box">{t("No Location Available")}</Box>
        )}
      </Dialog>
    );
  };

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        {this.renderSharedArea(t, language)}

        <Box maxWidth="xl" style={webStyleD.detailsBoxs}>
          <Box style={{ display: "flex" }}>
            <Link href="/CompanyEmployee/complexlisting">
              <Typography style={{ fontSize: "16px" }}>
                {t("Complex")} / {t("Buildings")} /
              </Typography>
            </Link>
            <Typography style={webStyleD.headColor}> {t("Complex Details")}</Typography>
          </Box>
          <Box style={webStyleD.titleBoxes}>
            <Typography style={webStyleD.companyTitleComplexs} className="bold-text">{`${t("Jurisdiction ID: ")}${
              this.state.jId || "-"
            }`}</Typography>
            <Box display="flex">
              <Box style={webStyleD.moreLists}>
                <Button
                  style={webStyleD.deleteBtns}
                  data-testid="open_options"
                  id="open_options1"
                  className="bold-text mangeGrid"
                  onClick={this.handleOpenOptions}
                >
                  <span className="bold-text" style={webStyleD.moreOption}>
                    {t("MORE OPTIONS")}{" "}
                    <ExpandMoreIcon
                      className="moreData-icon"
                      style={this.state.openOptions ? webStyleD.rotateIcons : {}}
                    />{" "}
                  </span>
                </Button>
                {this.state.openOptions && (
                  <Box style={webStyleD.moreListOptionsdata}>
                    {this.menuOptions.map((option, i) => {
                      return (
                        <Box
                          style={webStyleD.optionBoxD}
                          key={i}
                          onClick={option.clickFn}
                          data-testid={option.testId || ""}
                        >
                          <Typography style={webStyleD.optionTitleD}>{option.title}</Typography>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
              <Button
                variant="contained"
                style={webStyleD.editBtnComplex}
                className="bold-text manageJur"
                color="primary"
              >
                <span className="bold-text">{t("EDIT DETAILS")}</span>
              </Button>
            </Box>
          </Box>
          <Box style={webStyleD.detailsContainerComplexs} className="complex_details_container">
            <Box style={webStyleD.companyDetailsHeaderComplex} className="complex_details_container_header">
              <Box style={webStyleD.companyTextComplex} className="complex_details_container_company_text">
                <Box style={webStyleD.logoHolderComplexs}>
                  {" "}
                  <img src={this.state.companyDetails?.logo?.url} alt="" style={webStyleD.logoHolderComplexs} />
                </Box>
                <Box style={webStyleD.companyInfoComplexs} className="complex_details_container_company_info">
                  <Box style={webStyleD.companyLocationComplexs} className="complex_details_container_company_location">
                    <Typography variant="h4" style={webStyleD.companyInfoTitleComplexs} className="bold-text">
                      {this.state.companyDetails?.name}
                    </Typography>
                  </Box>
                  <Typography variant="body1" style={webStyleD.companyInfoLocationComplexs}>
                    {this.state.companyDetails?.city}
                  </Typography>
                </Box>
              </Box>
              <Box onClick={this.handleToggleMapDialog} style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                <LocationOnOutlinedIcon style={webStyleD.locationIconComplexs} />
                <Typography style={webStyleD.locationLabelComplexs} className="bold-text">
                  {t("See complex on map")}
                </Typography>
              </Box>
            </Box>
            <Box className="complex_page_slider">
              <Slider ref={(c: any) => (this.slider = c)} {...settings}>
                {this.state.companyDetails?.photos.map((image: any, index: number) => {
                  return (
                    <div className="slider-image-box" key={index}>
                      <img src={image.url} style={webStyleD.sliderImages} alt="" />
                    </div>
                  );
                })}
              </Slider>
              {this.state.companyDetails?.photos.length > 0 && (
                <Box className="slick-bottom" style={webStyleD.slickButtonss}>
                  <Box className="button prev" style={webStyleD.prevBtns} onClick={this.previousImage}>
                    <img src={previousIcon} alt="" />
                  </Box>
                  <Box className="button next" style={webStyleD.nextBtns} onClick={this.nextImage}>
                    <img src={nextIcon} alt="" />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box style={webStyleD.infoBox} className="mainJur-grid">
            <Grid container spacing={4} className="mainJur-grid">
              {this.state.gridData?.map((data: any, i: number) => {
                return (
                  <Grid item xs={4} key={i} className="mainJur-gridD">
                    <Box style={webStyleD.gridInfos} className="mainJur-gridD">
                      <Typography style={webStyleD.gridTitle}>{data.title}</Typography>
                      {data.value && (
                        <Box style={webStyleD.gridValueBoxs} className="mainJur-grid">
                          <Typography style={webStyleD.gridValues} className="bold-text">
                            {data.value}
                          </Typography>
                          {data.extraTag && (
                            <Typography style={webStyleD.gridTags} className="mainJur-grid">
                              ({data.extraTag})
                            </Typography>
                          )}
                        </Box>
                      )}
                      {data.tags && (
                        <Box style={webStyleD.tagBoxess}>
                          {data.tags.map((tag: string, i: number) => {
                            return (
                              <Box key={i} style={webStyleD.tagBoxes}>
                                <Typography style={webStyleD.tagNames}>{tag}</Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Box style={webStyleD.aboutBoxData}>
            <Typography style={webStyleD.aboutHeadings} className="bold-text">
              {t("About Complex")}
            </Typography>
            <Box style={webStyleD.aboutPara}>{this.state.companyDetails?.description || "-"}</Box>
          </Box>
          <Box style={webStyleD.buildingBoxs}>
            <Box style={webStyleD.buildingHeadingBoxs}>
              <Typography style={webStyleD.buildingHeadings} className="bold-text">
                {t("Buildings")}
              </Typography>
              <Box style={webStyleD.buildingAction}>
                <Button
                  onClick={this.handleAddBuilding}
                  variant="contained"
                  color="primary"
                  style={webStyleD.addBuildingBtn}
                >
                  <span className="bold-text">+ {t("ADD NEW BUILDING")}</span>
                </Button>
                <SearchInput
                  onChange={this.handleSearchKeyUp}
                  t={t}
                  language={language}
                  label={t("Search by building name")}
                  data-testid="searchquery"
                />
              </Box>
            </Box>
            <Box style={webStyleD.buildingsInfos}>
              <Grid container spacing={4}>
                {this.state.companyDetails != null &&
                  this.state.companyDetails?.building_list?.map((building: any, i: number) => {
                    return (
                      <Grid item xs={4} key={i}>
                        <Box style={webStyleD.buildingInfoBoxs}>
                          <Typography className="bold-text" style={webStyleD.buildingNames}>
                            {building?.building_name}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          </Box>
          <Box style={webStyleD.extraInfoBoxes}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Box style={webStyleD.gridInfos}>
                  <Typography style={webStyleD.gridTitle}>Complex Area</Typography>
                  <Box style={webStyleD.gridValueBoxs}>
                    <Typography style={webStyleD.gridExtraValues} className="bold-text">
                      {this.state.companyDetails?.complex_area || "-"} {this.state.companyDetails?.measurement_unit}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box style={webStyleD.gridInfos}>
                  <Typography style={webStyleD.gridTitle}>Total Buildings</Typography>
                  <Box style={webStyleD.gridValueBoxs}>
                    <Typography style={webStyleD.gridExtraValues} className="bold-text">
                      {this.state.companyDetails?.total_buildings || "-"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box style={webStyleD.gridInfos}>
                  <Typography style={webStyleD.gridTitle}>Total Units</Typography>
                  <Box style={webStyleD.gridValueBoxs}>
                    <Typography style={webStyleD.gridExtraValues} className="bold-text">
                      {this.state.companyDetails?.total_units.length || "-"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box style={webStyleD.documentTabs} className="tabs-selected">
            <StyledTabs value={this.state.tabNo} onChange={this.handleTabChange}>
              <StyledTab label={t("Documents")} />
              <StyledTab label={t("Shared Facilities")} />
            </StyledTabs>
            <Box style={webStyleD.documentBoxs}>
              {this.returnTabHeading(t)}
              <Box style={webStyleD.documentLists}>{this.renderTabComponent(t)}</Box>
            </Box>
          </Box>
        </Box>

        <AlertErrorWeb
          show={this.state.showError}
          handleClose={() => this.setState({ showError: false, error: null })}
          message={this.state.error}
        />

        <Loader loading={this.state.loading} />

        {this.renderManageJurisdiction(t, language)}
        {this.renderExtendedSub(t, language)}
        {this.renderUploadDocuments(t)}
        {this.renderUpgradeSub(t, language)}
        {this.complexDetailMapDialog(t, language)}

        <AlertDialog
          Image={TickIcon}
          IsOpen={this.state.isDeleteComplexOpen}
          Header={t("Delete Complex")}
          CloseDialog={this.handleDeactivateComplexDialog}
          Content={`<p>Upon confirming the deletion of the complex, the request will be sent to the COO for his approval</p>`}
          AcceptText={t("Confirm")}
          DeclineText={t("Close")}
          AcceptFun={this.handleDeleteComplex}
          DeclineFun={this.handleDeactivateComplexDialog}
        />
      </>
    );
  }
}

const webStyleD: any = {
  headColor: {
    color: "rgb(43, 111, 237)",
    fontSize: "16px",
  },
  uploadFieldD: {
    background: "rgb(243, 243, 243) none repeat scroll 0% 0%",
    border: "1px solid #F0F0F0",
    padding: "5px",
    borderRadius: "8px",
  },
  juridActionsData: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px",
  },
  juridFormControlData: {
    width: "100%",
    marginBottom: "15px",
    marginTop: "30px",
  },
  selectGridData: {
    marginTop: "8px",
  },
  subFormControlData: {
    width: "100%",
    marginBottom: "15px",
  },
  cloudIconData: {
    color: "#848484",
  },
  closeDeleteBtn: {
    borderColor: "rgb(43, 111, 237) none repeat scroll 0% 0%",
    color: "rgb(43, 111, 237)",
    width: "150px",
    height: "45px",
    borderRadius: "8px",
    fontWeight: "700" as CSSProperties["fontWeight"],
    marginRight: "15px",
  },
  selectTextData: {
    fontSize: "14px",
    marginBottom: "10px",
  },
  subContentData: {
    marginTop: "15px",
  },
  uploadContentData: {
    marginTop: "15px",
  },
  subValueData: {
    fontSize: "14px",
    marginTop: "10px",
  },
  subExtraInfodata: {
    color: "rgb(252, 132, 52)",
    fontSize: "14px",
  },
  btnHighBtn: {
    fontFamily: "Gotham_Bold",
  },
  confirmDeleteBtnData: {
    background: "rgb(43, 111, 237)",
    borderColor: "rgb(43, 111, 237)",
    color: "#fff",
    boxShadow: "unset",
    width: "150px",
    height: "45px",
    borderRadius: "8px",
    fontWeight: "700" as CSSProperties["fontWeight"],
  },
  juridGrid: {
    paddingTop: "25px",
  },
  juridTitled: {
    color: "rgb(138, 138, 138)",
    fontSize: "14px",
    marginTop: "5px",
  },
  juridTitleValue: {
    color: "black",
    fontSize: "14px",
    marginTop: "5px",
    marginBottom: "10px",
    fontWeight: "bold",
  },
  slickButtonss: {
    position: "absolute",
    width: "100%",
    top: "40%",
  },
  aboutBoxData: {
    background: "#fff",
    borderRadius: "8px",
    padding: "20px 15px",
  },
  juridHeadingd: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeIconD: {
    cursor: "pointer",
  },
  moreListOptionsdata: {
    background: "#fff",
    borderRadius: "8px",
    position: "absolute",
    zIndex: "9999999",
    width: "250px",
    boxShadow: "0px 3px 10px 0px rgba(204,204,204,1)",
  },
  optionTitleD: {
    fontSize: "14px",
  },
  optionBoxD: {
    padding: "10px 15px",
    borderBottom: "1px solid #ececec",
    cursor: "pointer",
  },
  extraInfoBoxes: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  uploadButtons: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    gap: "8px",
  },
  uploadIcons: {
    color: "rgb(252, 132, 52)",
    marginRight: "10px",
  },
  uploadTexts: {
    color: "rgb(252, 132, 52)",
  },
  errorMsgs: {
    color: "red",
    fontSize: "12px",
    marginTop: "5px",
  },
  buildingsInfos: {
    padding: "20px",
  },
  documentLists: {
    padding: "20px 15px",
  },
  docIcons: {
    color: "rgb(43, 111, 237)",
    marginRight: "5px",
  },
  addBuildingBtn: {
    background: "rgb(43, 111, 237)",
    marginRight: "15px",
    paddingLeft: "20px",
    paddingRight: "20px",
    boxShadow: "none",
    borderRadius: 8,
  },
  addSharedBtnn: {
    background: "rgb(252, 132, 52)",
    marginRight: "15px",
    borderRadius: "8px",
    paddingLeft: "30px",
    paddingRight: "30px",
    boxShadow: "unset",
  },
  juridBoxes: {
    width: "70%",
    marginTop: "15px",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
  },
  uploadBoxes: {
    width: "60%",
    marginTop: "15px",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "20px",
  },
  subBoxes: {
    width: "50%",
    marginTop: "15px",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
  },
  juridTexts: {
    fontSize: "20px",
  },
  docInfoBoxs: {
    border: "1px solid rgb(225, 225, 225)",
    padding: "15px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
  },
  moreLists: {
    position: "relative",
  },
  docTitles: {
    display: "flex",
    textTransform: "capitalize",
  },
  buildingBoxs: {
    background: "#fff",
    marginTop: "30px",
    borderRadius: "8px",
  },
  buildingHeadingBoxs: {
    padding: "20px 15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid rgb(225, 225, 225)",
  },
  documentBoxs: {
    background: "#fff",
  },
  documentBoxHeadings: {
    padding: "20px 15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid rgb(225, 225, 225)",
  },
  buildingNames: {
    color: "rgb(252, 132, 52)",
    fontSize: "18px",
  },
  docCounts: {
    background: "rgba(252, 132, 52, 0.1)",
    borderRadius: "10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  prevBtns: {
    background: "rgba(0,0,0,0.3)",
    height: "30px",
    width: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    cursor: "pointer",
  },
  nextBtns: {
    background: "rgba(0,0,0,0.3)",
    height: "30px",
    width: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: "6px",
    cursor: "pointer",
  },
  docCountTexts: {
    color: "rgb(252, 132, 52)",
    fontSize: "12px",
    paddingRight: "0px",
  },
  buildingInfoBoxs: {
    border: "1px solid rgb(225, 225, 225)",
    padding: "15px",
    borderRadius: "8px",
    textAlign: "center",
  },
  buildingHeadings: {
    fontSize: "20px",
  },
  aboutHeadings: {
    fontSize: "20px",
    marginBottom: "10px",
  },
  buildingAction: {
    display: "flex",
    alignItems: "center",
  },
  detailsBoxs: {
    padding: "30px",
  },
  locationTags: {
    display: "flex",
  },
  searchInputs: {
    border: "1px solid rgba(0,0,0,0.2)",
    borderRadius: "8px",
    height: "40px",
    padding: "0px",
    color: "#212121",
  },
  infoBox: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  companyTitleComplexs: {
    fontSize: "30px",
  },
  companyInfoLocationComplexs: {
    fontSize: "16px",
    fontWeight: "400" as CSSProperties["fontWeight"],
  },
  rotateIcons: {
    transform: "rotate(180deg)",
  },
  gridInfos: {
    background: "#fff",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px",
  },
  tagBoxess: {
    marginTop: "5px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  tagBoxes: {
    background: "rgba(43, 111, 237, 0.2)",
    borderRadius: "8px",
    padding: "2px 10px",
    textAlign: "center",
  },
  tagNames: {
    fontSize: "12px",
    color: "rgb(43, 111, 237)",
    paddingRight: "0px",
  },
  gridValues: {
    color: "rgb(43, 111, 237)",
    fontSize: "24px",
    marginTop: "5px",
  },
  gridExtraValues: {
    color: "rgb(252, 132, 52)",
    fontSize: "24px",
    marginTop: "5px",
  },
  gridTags: {
    color: "rgb(164, 164, 164)",
  },
  companyLocationComplexs: {
    display: "flex",
    alignItems: "center",
  },

  titleBoxes: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sliderImages: {
    height: "100px",
    width: "100px",
    borderRadius: "8px",
    marginRight: "15px",
    objectFit: "cover",
  },
  deleteBtns: {
    color: "rgb(43, 111, 237)",
    marginRight: "15px",
    fontWeight: 700,
  },
  moreOption: {
    display: "flex",
    alignItems: "center",
  },
  companyTextComplex: {
    display: "flex",
    alignItems: "center",
  },
  companyInfoComplexs: {
    marginLeft: "30px",
  },
  companyInfoTitleComplexs: {
    fontSize: "22px",
    fontWeight: "400" as CSSProperties["fontWeight"],
  },
  editBtnComplex: {
    background: "#2B6FED",
    borderColor: "#2B6FED",
    fontWeight: 700,
    boxShadow: "none",
    borderRadius: 8,
  },
  locationIconComplexs: {
    color: "#fc8434",
    height: "22px",
    marginLeft: "10px",
  },
  locationLabelComplexs: {
    color: "#FC8434",
    fontSize: "16px",
    fontWeight: "400" as CSSProperties["fontWeight"],
    marginLeft: "10px",
  },
  gridValueBoxs: {
    display: "flex",
    alignItems: "center",
  },
  detailsContainerComplexs: {
    background: "#fff",
    padding: "15px",
    marginTop: "30px",
    borderRadius: "8px",
  },
  companyDetailsHeaderComplex: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
    alignItems: "center",
  },
  logoHolderComplexs: {
    background: "#dcdcdc",
    width: "70px",
    height: "70px",
    borderRadius: "50%",
    objectFit: "cover",
  },
  fileBox: {
    marginTop: "15px",
    background: "rgb(241, 241, 241) none repeat scroll 0% 0%",
    width: "100%",
    padding: "10px",
    borderRadius: "4px",
  },
  fileNameBox: {
    background: "#fff",
    color: "#212121",
    padding: "5px 15px",
    display: "flex",
    alignItems: "center",
    width: "max-content",
    borderRadius: "4px",
  },
  removeIcon: {
    cursor: "pointer",
  },
};

export default withTranslation()(ComplexDetailsPage);
// Customizable Area End
