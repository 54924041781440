// Customizable Area Start
import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@material-ui/core";

const AlertDialog = ({
  IsOpen,
  CloseDialog,
  Image,
  Header,
  Content,
  DeclineText,
  AcceptText,
  DeclineFun,
  AcceptFun,
}: any) => {
  return (
    <Dialog
      className="delete-document delete-document-dialog"
      fullWidth
      maxWidth="sm"
      onClose={CloseDialog}
      open={IsOpen}
    >
      <DialogContent>
        <Box textAlign="center">
          <img src={Image} alt="delete" />
          <Typography variant="h6" className="bold-text">
            {Header}
          </Typography>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: Content,
            }}
          />
          <DialogActions className="dialog-button-group">
            {DeclineText && (
              <Button className="cancel-button" onClick={DeclineFun} style={{ height: "50px" }}>
                {DeclineText}
              </Button>
            )}
            {AcceptText && (
              <Button style={{ height: "50px" }} className="add-button" onClick={AcceptFun}>
                {AcceptText}
              </Button>
            )}
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AlertDialog;
// Customizable Area End
