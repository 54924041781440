// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  MenuItem,
  Typography,
  Grid,
  InputLabel,
  InputAdornment,
  Card,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  List,
  ListItem,
  ListItemText,
  Input,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import { CSSProperties } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import { UploadIcon } from "./assets";
import ComplexAddUnitFormController, { Props } from "./ComplexAddUnitFormController.web";
import { withTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import { ModalWrapper } from "../../../../components/src/ModalWrapper";
import Loader from "../../../../components/src/Loader.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import "./incidents.css";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import { CloseIcon as Close_Icon } from "../assets";
import { Menu } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import CommonButton from "../../../../components/src/CommonComponents/Button.web";
import AlertError from "../../../../components/src/AlertError.web";

class ComplexAddUnitForm extends ComplexAddUnitFormController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        <Box style={webStyle.registerContainerUnit}>
          <Box className="unit_form_top_headline">
            <Typography style={{ fontSize: "16px" }}>
              <Link to="/CompanyEmployee/complex-listing">
                {t("Complex")} / {t("Buildings")}
              </Link>{" "}
              / <Link to={`/CompanyEmployee/BuildingDetailsPage/${this.props.companyId}`}>{t("Buildings")}</Link> /{" "}
              <Link to={`/CompanyEmployee/BuildingDetailsPage/${this.props.companyId}`}>{t("Complex Details")}</Link> /{" "}
              <span style={webStyle.linkColorUnit}>{t("Add New Unit")}</span>
            </Typography>
          </Box>
          <Box className="unit_form_action_buttons">
            <Typography style={{ fontSize: "30px" }} className="bold-text">
              {t("Add New Unit")}
            </Typography>
          </Box>
          <Box className="unit_form_formik_box">
            <Formik
              initialValues={this.state.formValue}
              enableReinitialize={true}
              validationSchema={this.validationSchema}
              onSubmit={(values, { resetForm }) => {
                this.setState({ loading: true }, () => {
                  this.addNewUnit(values);
                  resetForm();
                });
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
                return (
                  <Form onSubmit={handleSubmit} translate={undefined}>
                    <Box style={webStyle.formContainerUnit}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <NormalTextField
                            values={values.unitNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="unitNumber"
                            t={t}
                            language={language}
                            label={t("Unit Number")}
                            isLabel
                          />
                          {ErrorHandler(t, touched.unitNumber, errors.unitNumber)}
                        </Grid>
                        <Grid item xs={6}>
                          <NormalTextField
                            values={values.floorNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="floorNumber"
                            t={t}
                            language={language}
                            label={t("Floor Number")}
                            isLabel
                          />
                          {ErrorHandler(t, touched.floorNumber, errors.floorNumber)}
                        </Grid>
                        <Grid item xs={6}>
                          <Box className="input_icon__form_box">
                            <InputLabel>{t("Owner ID")}</InputLabel>
                            <Input
                              className="input_box"
                              value={this.state.ownerId}
                              name="time"
                              type="text"
                              placeholder={t("Owner ID")}
                              fullWidth
                              disabled
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <label htmlFor="ownerName" style={webStyle.label}>
                            {t("Owner Name")}
                          </label>
                          <Box
                            className="add-owner-dialog-btn"
                            style={webStyle.modalField}
                            onClick={() => this.handleOpenOwnerModal("ownerId")}
                          >
                            <Typography style={webStyle.modalFieldTitle}>+ Add Owner</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <NormalSelect
                            displayEmpty
                            values={values.unitStatus}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="unitStatus"
                            t={t}
                            language={language}
                            label={t("Unit Status")}
                            option={[
                              { label: "Rented", value: "Rented" },
                              { label: "Vacant", value: "Non-Rented" },
                            ]}
                          />
                          {ErrorHandler(t, touched.unitStatus, errors.unitStatus)}
                        </Grid>
                        <Grid item xs={6}>
                          <NormalTextField
                            values={values.size}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="size"
                            t={t}
                            language={language}
                            label={t("Size")}
                            isLabel
                            endAdornment={
                              <InputAdornment position="end">
                                <Typography className="bold-text" style={webStyle.unitText}>
                                  Sqft
                                </Typography>
                              </InputAdornment>
                            }
                          />
                          {ErrorHandler(t, touched.size, errors.size)}
                        </Grid>
                        <Grid item xs={6}>
                          <NormalSelect
                            values={values.config}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="config"
                            t={t}
                            language={language}
                            label={t("Configuration (optional)")}
                            option={this.state.configList}
                          />
                          {ErrorHandler(t, touched.config, errors.config)}
                        </Grid>
                        <Grid item xs={6}>
                          <NormalTextField
                            values={values.price}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="price"
                            t={t}
                            language={language}
                            label={t("Purchase Price (optional)")}
                            isLabel
                          />
                          {ErrorHandler(t, touched.price, errors.price)}
                        </Grid>
                        <Grid item xs={6}>
                          <NormalTextField
                            values={values.date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="date"
                            t={t}
                            language={language}
                            label={t("Purchase Date (optional)")}
                            onFocus={(e: React.ChangeEvent<{ type: string }>) => (e.target.type = "date")}
                            isLabel
                            type="text"
                          />
                          {ErrorHandler(t, touched.date, errors.date)}
                        </Grid>
                        <Grid item xs={6}>
                          <label htmlFor="ownerName" style={webStyle.label}>
                            {t("Resident Name (optional)")}
                          </label>
                          <Box
                            className="add-resident-dialog-btn"
                            style={webStyle.modalField}
                            onClick={() => this.handleOpenOwnerModal("residentId")}
                          >
                            <Typography style={webStyle.modalFieldTitle}>+ Add Resident</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <NormalTextField
                            t={t}
                            language={language}
                            label={t("Current Valuation (optional)")}
                            values={values.valuation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="valuation"
                            isLabel
                          />
                          {ErrorHandler(t, touched.valuation, errors.valuation)}
                        </Grid>

                        <Grid item xs={12}>
                          <label htmlFor="uploadPhotos" style={webStyle.label}>
                            {t("Upload Photos (optional)")}
                          </label>
                          <Grid container spacing={4}>
                            <Grid item md={3} style={{ marginBottom: "5px" }} className="edit-building">
                              <Box className="upload-photo" onClick={() => this.uploadImages.click()}>
                                <img src={UploadIcon} alt="edit-complex-upload-icon" />
                              </Box>
                              <input
                                multiple
                                className="complex-details-image"
                                style={{ display: "none" }}
                                name="photos"
                                ref={(ref: unknown) => (this.uploadImages = ref)}
                                onBlur={handleBlur}
                                type="file"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  const files = e.target.files;
                                  if (files) {
                                    for (let file of files) {
                                      let reader = new FileReader();
                                      reader.onloadend = () => {
                                        values.images = [...values.images, reader.result];
                                        setFieldValue("images", values.images);
                                      };
                                      reader.readAsDataURL(file);
                                    }
                                  }
                                }}
                                accept="image/*"
                              />
                            </Grid>
                            {values.images.map((image: string | ArrayBuffer | null, index: number) => {
                              return (
                                <Grid item md={3} key={index}>
                                  <Box className="building-image" style={{ position: "relative" }}>
                                    <img
                                      style={{
                                        position: "absolute",
                                        top: "5px",
                                        right: "5px",
                                        width: "35px",
                                        height: "35px",
                                        cursor: "pointer",
                                      }}
                                      src={Close_Icon}
                                      className="delete-image"
                                      onClick={() => {
                                        const remainImage = values.images.filter(
                                          (img: string | ArrayBuffer | null, idx: number) => idx !== index
                                        );
                                        setFieldValue("images", remainImage);
                                      }}
                                    />
                                    <img
                                      style={{ height: "124px", width: "100%", borderRadius: "8px" }}
                                      src={typeof image === "string" ? image : undefined}
                                      alt=""
                                    />
                                  </Box>
                                </Grid>
                              );
                            })}
                          </Grid>
                          {ErrorHandler(t, touched.images, errors.images)}
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography style={webStyle.familyLabel}>
                              <span style={webStyle.familyLabel} className="bold-text">
                                Family Members
                              </span>{" "}
                              (optional)
                            </Typography>
                            {(this.state.ownerId || this.state.residentId) && (
                              <Typography
                                style={webStyle.familyAddBtn}
                                className="bold-text"
                                onClick={this.handleFamilyDialog}
                              >
                                {t("+ ADD")}
                              </Typography>
                            )}
                          </Box>
                          <Grid container spacing={2}>
                            <Grid item xs={6} sm={6}>
                              <Box className="unit-family-container">
                                <Card className="user-details">
                                  <Box className="heading">
                                    <h4 className="bold-text">NAme</h4>
                                    <Box className="">
                                      <Menu menuButton={<MoreVertIcon />}>
                                        <MenuItem>{t("Delete")}</MenuItem>
                                      </Menu>
                                    </Box>
                                  </Box>
                                  <p className="label">{t("Relation")}:</p>
                                  <Box className="user-info">
                                    <p>Name</p>
                                    <p>id</p>
                                  </Box>
                                </Card>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <Typography style={webStyle.rentLabel} className="bold-text">
                            Rent Status
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <NormalSelect
                            displayEmpty
                            values={values.rentStatus}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="rentStatus"
                            t={t}
                            language={language}
                            label={t("Rent Status")}
                            option={[
                              { label: "Rented", value: "Rented" },
                              { label: "Vacant", value: "Non-Rented" },
                            ]}
                            disabled={this.state.tenantId === ""}
                          />
                          {ErrorHandler(t, touched.rentStatus, errors.rentStatus)}
                        </Grid>
                        <Grid item xs={6}>
                          <label htmlFor="ownerName" style={webStyle.label}>
                            {t("Tenant Name")}
                          </label>
                          <Box
                            className="add-tenant-dialog-btn"
                            style={webStyle.modalField}
                            onClick={() => this.handleOpenOwnerModal("tenantId")}
                          >
                            <Typography style={webStyle.modalFieldTitle}>+ Add Tenant</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <NormalTextField
                            t={t}
                            language={language}
                            label={t("Rent Amount")}
                            values={values.rentAmount}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="rentAmount"
                            isLabel
                            disabled={this.state.tenantId === ""}
                          />
                          {ErrorHandler(t, touched.rentAmount, errors.rentAmount)}
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel style={{ marginBottom: "10px", fontSize: "14px", color: "black" }}>
                            {t("Rent Tenure")}
                          </InputLabel>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <NormalTextField
                                t={t}
                                language={language}
                                label={t("From")}
                                values={values.rentFrom}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="rentFrom"
                                onFocus={(e: React.ChangeEvent<{ type: string }>) => (e.target.type = "date")}
                                disabled={this.state.tenantId === ""}
                              />
                              {ErrorHandler(t, touched.rentFrom, errors.rentFrom)}
                            </Grid>
                            <Grid item xs={6}>
                              <NormalTextField
                                t={t}
                                language={language}
                                label={t("To")}
                                values={values.rentTo}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="rentTo"
                                onFocus={(e: React.ChangeEvent<{ type: string }>) => (e.target.type = "date")}
                                disabled={this.state.tenantId === ""}
                              />
                              {ErrorHandler(t, touched.rentTo, errors.rentTo)}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box style={{ textAlign: "right", marginTop: "20px" }} className="unit_btn_container">
                      <Button
                        variant="outlined"
                        className="unit_cancel_btn"
                        style={webStyle.cancelBtnUnit}
                        data-testid="cancel_btn"
                        onClick={this.handleCancel}
                      >
                        {t("CANCEL")}
                      </Button>
                      <Button
                        type="submit"
                        className="unit_submit_btn"
                        data-testid="submit-button"
                        style={webStyle.submitBtnUnit}
                      >
                        {t("SUBMIT FOR APPROVAL")}
                      </Button>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>

        <ModalWrapper
          className="find-person-unit-dialog"
          open={this.state.ownerModal}
          closeFn={this.handleCloseOwnerModal}
        >
          <Box style={webStyle.subBox}>
            <Box style={webStyle.juridHeading}>
              <Typography style={webStyle.juridText} className="bold-text">
                {t("Find Person")}
              </Typography>
              <CloseIcon
                style={webStyle.closeIcon}
                onClick={this.handleCloseOwnerModal}
                data-testid="close_owner_modal"
              />
            </Box>
            {!this.state.isUserDetailOpen && (
              <Box style={webStyle.subContent}>
                <Box style={{ display: "flex", alignItems: "flex-end", gap: "10px", justifyContent: "space-between" }}>
                  <NormalTextField
                    t={t}
                    language={language}
                    label={t("ID Number")}
                    name="name"
                    isLabel
                    value={this.state.findPersonSearch.id}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.setState({ findPersonSearch: { ...this.state.findPersonSearch, id: e.target.value } })
                    }
                  />
                  <NormalTextField
                    t={t}
                    language={language}
                    label={t("Passport Number")}
                    name="name"
                    isLabel
                    value={this.state.findPersonSearch.passport}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.setState({ findPersonSearch: { ...this.state.findPersonSearch, passport: e.target.value } })
                    }
                  />
                  <NormalTextField
                    t={t}
                    language={language}
                    label={t("Email ID")}
                    name="name"
                    isLabel
                    value={this.state.findPersonSearch.email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.setState({ findPersonSearch: { ...this.state.findPersonSearch, email: e.target.value } })
                    }
                  />
                  <NormalTextField
                    t={t}
                    language={language}
                    label={t("Phone Number")}
                    name="name"
                    isLabel
                    value={this.state.findPersonSearch.phone}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      this.setState({ findPersonSearch: { ...this.state.findPersonSearch, phone: e.target.value } })
                    }
                  />
                  <CommonButton
                    t={t}
                    label={t("Search")}
                    startIcon={<SearchIcon />}
                    color="blue"
                    onClick={this.getFindPersonList}
                  />
                </Box>
              </Box>
            )}
            {this.state.userList.length > 0 && !this.state.isUserDetailOpen && (
              <>
                <Divider />
                <Box style={webStyle.subContent} className="select-meeting">
                  <List>
                    <ListItem dense className="list-heading">
                      <ListItemText primary={t("Name")} className="bold-text" />
                      <ListItemText primary={t("ID Number")} className="bold-text" />
                      <ListItemText primary={t("Phone Number")} className="bold-text" />
                      <ListItemText primary={t("Email")} className="bold-text" />
                      <ListItemText />
                    </ListItem>
                    {this.state.userList.map((user) => {
                      return (
                        <ListItem key={user.id} className="meeting-minute-row">
                          <ListItemText primary={user.attributes.full_name} />
                          <ListItemText primary={user.id} />
                          <ListItemText primary={user.attributes.full_phone_number} />
                          <ListItemText primary={user.attributes.email} />
                          <ListItemText style={{ textAlign: "right" }}>
                            <Menu menuButton={<MoreVertIcon />}>
                              <MenuItem onClick={() => this.getFindPersonDetail(user.id)}>{t("View Detail")}</MenuItem>
                            </Menu>
                          </ListItemText>
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              </>
            )}
            {this.state.isUserDetailOpen && (
              <Box style={webStyle.subContent} className="select-meeting">
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography variant="body2">Name</Typography>
                    <Typography variant="body2" style={{ color: "grey" }}>
                      {this.state.userDetail.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">Complex</Typography>
                    <Typography variant="body2" style={{ color: "grey" }}>
                      {this.state.userDetail.complex}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">ID Number</Typography>
                    <Typography variant="body2" style={{ color: "grey" }}>
                      {this.state.userDetail.idNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">Local ID</Typography>
                    <img src={this.state.userDetail.idUrl} />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">Email</Typography>
                    <Typography variant="body2" style={{ color: "grey" }}>
                      {this.state.userDetail.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="body2">Address</Typography>
                    <Typography variant="body2" style={{ color: "grey" }}>
                      {this.state.userDetail.address}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
            <DialogActions className="dialog__button_group">
              <Button className="cancel-button" onClick={this.handleCloseOwnerModal}>
                {t("Cancel")}
              </Button>
              {this.state.isUserDetailOpen && (
                <Button type="submit" className="add-button" onClick={() => this.handleSelectUser()}>
                  {t("Select")}
                </Button>
              )}
            </DialogActions>
          </Box>
        </ModalWrapper>

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          maxWidth="sm"
          open={this.state.isFamilyDialogOpen}
          scroll="paper"
          fullWidth
        >
          <DialogHeader t={t} title="Add Family Member" onClose={this.handleFamilyDialog} />
          <Formik
            enableReinitialize={true}
            initialValues={this.state.familyFormValue}
            validationSchema={this.editFamilyMemberValidation}
            onSubmit={(values, { resetForm }) => {
              this.setState({ loading: true });
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit} translate>
                  <DialogContent dividers>
                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <NormalTextField
                          t={t}
                          language={language}
                          label={t("Family Member Name")}
                          name="name"
                          values={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isLabel
                        />
                        {ErrorHandler(t, touched.name, errors.name)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalSelect
                          values={values.relation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="relation"
                          t={t}
                          language={language}
                          label={t("Relation")}
                          option={this.state.relationList}
                        />
                        {ErrorHandler(t, touched.relation, errors.relation)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalSelect
                          values={values.idProof}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="idProof"
                          t={t}
                          language={language}
                          label={t("Type of ID Proof")}
                          option={this.state.idProofList}
                        />
                        {ErrorHandler(t, touched.idProof, errors.idProof)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalTextField
                          t={t}
                          language={language}
                          label={t("ID Number")}
                          name="idNumber"
                          values={values.idNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isLabel
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog__button_group">
                    <Button className="cancel-button" onClick={this.handleFamilyDialog}>
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" className="add-button">
                      {t("Save")}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>

        <AlertError
          show={this.state.showError}
          handleClose={() => this.setState({ showError: false })}
          message={this.state.error}
        />

        <Loader loading={this.state.loading} />
      </>
    );
  }
}

const webStyle = {
  infoHeading: { fontSize: "14px" },
  modalLabel: { marginBottom: "10px" },
  juridFormControl: { width: "100%" },
  confirmDeleteBtn: {
    background: "rgb(43, 111, 237)",
    borderColor: "rgb(43, 111, 237)",
    color: "#fff",
    boxShadow: "unset",
    width: "150px",
    height: "45px",
    borderRdaius: "12px",
    fontWeight: "700" as CSSProperties["fontWeight"],
  },
  divider: { borderBottom: "1px solid #dcdcdc" },
  juridActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "45px",
  },
  subContent: { padding: "20px 30px" },
  juridText: { fontSize: "20px" },
  closeIcon: { cursor: "pointer" },
  juridHeading: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #dcdcdc",
    padding: "20px",
    borderRadius: "20px 20px 0px 0px",
  },
  subBox: { width: "80%", marginTop: "15px", backgroundColor: "white", borderRadius: "8px" },
  closeDeleteBtn: {
    borderColor: "rgb(43, 111, 237) none repeat scroll 0% 0%",
    color: "rgb(43, 111, 237)",
    width: "150px",
    height: "45px",
    borderRadius: "8px",
    fontWeight: "700" as CSSProperties["fontWeight"],
    marginRight: "15px",
  },
  familyLabel: { fontSize: "20px" },
  familyAddBtn: { cursor: "pointer", color: "rgb(43, 111, 237)" },
  linkColorUnit: { color: "rgb(43, 111, 237)", fontSize: "16px" },
  formContainerUnit: {
    background: "#fff",
    padding: "30px 15px",
    marginTop: "30px",
    boxShadow: "4px 0px 14px 0px #E9E9E9",
    borderRadius: "8px",
    overflow: "hidden",
  },
  registerContainerUnit: { padding: "30px" },
  label: { marginBottom: "10px", fontSize: "14px" },
  cancelBtnUnit: {
    borderColor: "#2B6FED",
    color: "#2B6FED",
    width: "180px",
    height: "45px",
    marginRight: "15px",
    borderRadius: "8px",
  },
  submitBtnUnit: {
    background: "#2B6FED",
    borderColor: "#2B6FED",
    color: "#fff",
    width: "250px",
    height: "45px",
    borderRadius: "8px",
  },
  unitText: { color: "rgb(140, 140, 140)" },
  modalField: {
    border: "1px solid rgb(252, 132, 52)",
    marginTop: "8px",
    borderRadius: "8px",
    padding: "12px 15px",
    cursor: "pointer",
  },
  rentLabel: { fontSize: "20px" },
  modalFieldTitle: { color: "rgb(252, 132, 52)", fontSize: "14px !important" },
};

export default withTranslation()(ComplexAddUnitForm);
// Customizable Area End
