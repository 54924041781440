import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { t } from "i18next";

interface Filter {
  page: number;
}
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  totalSentInvitationList:any;
  pagination:any;
  filter: Filter;
  search:any;
  complexName:string;
  complexBuildingFilterList:any
  complexDataList:any[];
  cityDataList:any[];
  buildingsDataList:any[];
  countryDataList:any[];
  statusData:any[];
  searchQuery:any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CETotalSentInviteController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  GetTotalSentInvitationList:any;
  GetFilterSentInvitationList:any;
  GetPageSentInvitationList:any;
  GetCountryListID:any;
  GetCityListID:any;
  GetAllBuildingsDataId:any;
  GetComplexListCallId:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.state = {
      loading: false,
      totalSentInvitationList:[],
      pagination:"",
      search:"",
      complexName:"",
      searchQuery:"",
      complexDataList:[],
      cityDataList:[],
      buildingsDataList:[],
      countryDataList:[],
      statusData:[{
        label: t("Pending"),
        value: 'Pending'
      },
      {
        label: t("Accept"),
        value: 'Accept'
      },],
      filter: {
        page: 1,
      },
      complexBuildingFilterList: [
        {
          label: t("Complex"),
          value: 'complex'
        },
        {
          label: t("Indiviual Building"),
          value: 'indiviualBuilding'
        },
      ]
    };

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getTotalSentInvitationList();
    this.getCountryListTable();
  }
  getTotalSentInvitationList = async () => {
    this.setState({ loading: true })
    const { page } = this.state.filter;
    this.GetTotalSentInvitationList = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getTotalSentInvitationListEndPoint+`?page=${page}`,
    });
  }
  getTotalSentInvitationFilterList = async () => {
    this.setState({ loading: true })
    const { page } = this.state.filter;
    this.GetTotalSentInvitationList = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getTotalSentInvitationListEndPoint+`?page=${page}&search_term=${this.state.searchQuery}&country=${this.state.search.selectedCountry}&city=${this.state.search.selectedCity}&complex_id=${this.state.search.selectedComplex}&building_id=${this.state.search.selectedBuilding}`,
    });
  }
  onChange = (e: any) => {
    this.setState({ searchQuery: e.target.value },()=> this.getTotalSentInvitationFilterList());  
  }
  handleChangeValue=(event:any,keyState:any)=>{
    
    if(keyState==="selectedCountry"){
      this.getCityList(event.target.value)
    }else if(keyState==="selectedComplex"){
      const id=event.target.value?.id;
      this.setState({complexName:event.target.value?.name})
      this.getBuildingsDataList(id);
    }else if(keyState==="selectedCity"){
      const id=event.target.value;
      this.getComplexList(id);
    }
    
    this.setState({ search: { ...this.state.search, [keyState]:  event.target.value} })
  }
  getCountryListTable = async () => {
    this.setState({ loading: true })
    this.GetCountryListID = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.createMemberCountryListEndPoint,
    });
  }
  getCityList = async (country: any) => {
    this.GetCityListID = await apiCall({
      method: configJSON.getSellersAPIMethod,
      contentType:configJSON.sellerDetailsApiContentType,
      endPoint:configJSON.getCityListEndPoint+`?country=${country}`
    })
  }
  getBuildingsDataList = async (id:any) => {
    this.GetAllBuildingsDataId = await apiCall({
      method: configJSON.getSellersAPIMethod,
      contentType: configJSON.sellerDetailsApiContentType,
      endPoint: `society_managements/${id}/bx_block_meeting/find_building`,
    });
  };
  getComplexList = async (city:any ) => {
    this.GetComplexListCallId = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getComplexListEndPoint+`?city=${city}`
    });
  };
  dateFormat=(date:any)=>{
    const createdAt = new Date(date);
    return `${createdAt.getDate()}/${createdAt.getMonth() + 1}/${createdAt.getFullYear()}`;
  }
  handleTotalSentInvitationList = (responseJson: any) => {
    this.setState({ loading: false })
    if (responseJson) {
      this.setState({
        totalSentInvitationList: responseJson.member_invitations.data ||[],
        pagination: responseJson.meta.pagination,
      })
    }
  }
  handleBuildingList=(responseJson:any)=>{
    if (responseJson && responseJson.buildings) {
      this.setState({
        buildingsDataList: responseJson.buildings.map((item: any) => ({ label: item.name, value: item.id }))}
      )
    }
  }
  handleCountryResponse = (responseJson: any) => {
    if (responseJson && responseJson.country_list) {
      this.setState({
         countryDataList: responseJson.country_list.map((item: any) => ({ label: item, value: item }))
      });
    }
  };
  handleCityResponse = (responseJson: any) => {
    if (responseJson && responseJson.region_city_list) {
      this.setState({
         cityDataList: responseJson.region_city_list.map((item: any) => ({ label: item.city, value: item.city })) });
    }
  };
  handleComplexList=(responseJson:any)=>{
    if (responseJson && responseJson.data) {
      this.setState({ complexDataList: responseJson.data.map((item: any) => ({ label: item.attributes.name, value:{ id:item.id ,name: item.attributes.name} }))}
      )
    }
  }
  handleFilterPage=(value:any)=>{
    this.setState({
      ...this.state,
      filter: {
        ...this.state.filter,
        page: Number(value),
      },
    },()=>{
      this.getTotalSentInvitationList()
      
    });
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      if(apiRequestCallId===this.GetTotalSentInvitationList){
        this.handleTotalSentInvitationList(responseJson);
      }
      switch (apiRequestCallId) {
        case this.GetTotalSentInvitationList:
          this.handleTotalSentInvitationList(responseJson);
          break;
        case this.GetCountryListID:
          this.handleCountryResponse(responseJson)
          break;
        case this.GetComplexListCallId:
          this.handleComplexList(responseJson)
          break;
        case this.GetCityListID:
          this.handleCityResponse(responseJson)
          break;
        case this.GetAllBuildingsDataId:
          this.handleBuildingList(responseJson)
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }
}
