import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { t } from "i18next";
import * as Yup from "yup";
interface FormValue {
  country:string;
  type:string;
  individualBuilding:string;
  city:string;
  complex:string;
  building:string;
  fullName:string;
  unit:string;
  countryCode:string;
  phoneNumber:string;
  emailID:string;
}
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  formValue: FormValue;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  requestsManagementList:any;
  initialValues:any;
  dataList:any;
  inviteMemberModelOpen:boolean;
  complexBuildingFilterList:any;
  countryList: any[],
  cityList: any[],
  complexList:any[];
  buildingsList:any[];
  unitList:any[];
  userList:any[];
  formValue: FormValue;
  tabComplex:boolean;
  filter: {
    country: string;
    city: string;
    complex: string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CERequestManagementController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  GetRequestsManagementList:any;
  GetMemberInvitationList:any;
  GetCountryFilterList: any;
  GetCityFilterList: any;
  GetComplexCallId:any;
  GetAllBuildingsCallId:any;
  GetAllUnitCallId:any;
  showAllUserAPICallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      requestsManagementList:[],
      dataList:[],
      unitList:[],
      userList:[],
      initialValues:{
        userType:"",
        fullName:"",
        emailId:"",
        phoneNumber:"",
        countryCode:"",
        selectedCountry:"",
        selectedCity:"",
        selectedComplex:"",
        selectedBuilding:"",
        selectedUnit:"",
        selectedIndividualBuilding:"",
      },
      filter:{
        country:"",
        complex:"",
        city:""
      },
      tabComplex:true,
      buildingsList:[],
      countryList: [],
      cityList: [],
      complexList:[],
      complexBuildingFilterList: [
        {
          label: t("Complex"),
          value: 'complex'
        },
        {
          label: t("Indiviual Building"),
          value: 'indiviualBuilding'
        },
      ],
      formValue: {
        type:"",
        country:"",
        city:"",
        individualBuilding:"",
        complex:"",
        building:"",
        unit:"",
        fullName:"",
        emailID:"",
        countryCode:"+1",
        phoneNumber:""
      },
      inviteMemberModelOpen:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getRequestsManagementList();
    this.getAllUser();
    this.getComplexList();
    this.getFilterListTable();
  }
  getFilterListTable = async () => {
    this.setState({ loading: true })
    this.GetCountryFilterList = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.createMemberCountryListEndPoint,
    });
  }
  meetingValidation: any = Yup.object().shape({
    type: Yup.string().required("Required").matches(/\S/, "Required"),
    fullName: Yup.string().required("Required").max(100, "Maximum length of Name should be 100 character").matches(/\S/, "Required"),
    country: Yup.string().required("Required").matches(/\S/, "Required"),
    complex: Yup.string().required("Required").matches(/\S/, "Required"),
    city: Yup.string().required("Required").matches(/\S/, "Required"),
    unit: Yup.string().required("Required").matches(/\S/, "Required"),
    building: Yup.string().required("Required").matches(/\S/, "Required"),
    individualBuilding: Yup.string().required("Required").matches(/\S/, "Required"),
    phoneNumber: Yup.string().required("Required").matches(/\S/, "Required"),
    emailID: Yup.string().email("Invalid email format").required("Required"),
  });
  async handleFormSubmit(value:any) {
    const formData = new FormData();
    
    formData.append("member_invitation[full_name]]", value.fullName);
    formData.append("member_invitation[phone_number]", value.countryCode + " " + value.phoneNumber);
    formData.append("member_invitation[email_address]",value.emailID.toLowerCase());
    formData.append("member_invitation[building_management_id]", value.unit?.building_management_id);
    formData.append("member_invitation[role_id]", value?.type);
    formData.append("member_invitation[society_management_id]", value.unit?.society_management_id);
    formData.append("member_invitation[apartment_management_id]", value.unit?.id);

    this.GetMemberInvitationList = await apiCall({
      method: configJSON.sellerDetailsAPIMethodPOST,
      endPoint: configJSON.createMemberInvitationEndPoint,
      body:formData
    });
   
  }
  getRequestsManagementList = async () => {
    this.setState({ loading: true })
    this.GetRequestsManagementList = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getRequestsManagementListEndPoint+`?is_building=${!this.state.tabComplex}`,
    });
  }
  getRequestsManagementFilterList = async () => {
    this.setState({ loading: true })
    this.GetRequestsManagementList = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getRequestsManagementListEndPoint+`?country=${this.state.filter.country}&city=${this.state.filter.city}&complex_id=${this.state.filter.complex}`,
    });
  }
  handleRequestsManagementList = (responseJson: any) => {
    this.setState({ loading: false })
    if (responseJson) {
      this.setState({
        requestsManagementList: responseJson.data ||[]
      })
    }
  }
  handleFiltersList = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({  userList: 
        responseJson.data.map((item: any) => ({
                label: item.attributes.title, value: item.id
           }))
        })
    }
  }
  handleComplexList=(responseJson:any)=>{
    if (responseJson && responseJson.data) {
      this.setState({ complexList:
         responseJson.data.map((item: any) => ({
           label: item.attributes.name, value: item.id
           })
           )}
      )
    }
  }
  handleUnitList = (responseJson: any) => {
    if (responseJson && responseJson.data.apartments) {
      this.setState({
        unitList: responseJson.data.apartments.map((item: any) => ({
          label: item.apartment_name,
          value: {
            id: item?.id,
            society_management_id: item?.society_management_id,
            building_management_id: item?.building_management_id
          }
        })
        )
      })
    }
  }
  handleBuildingList=(responseJson:any)=>{
    if (responseJson && responseJson.buildings) {
      this.setState({ buildingsList: responseJson.buildings.map((item: any) => ({ label: item.name, value: item.id }))}
      )
    }
  }
  handleCountryResponse = (responseJson: any) => {
    if (responseJson && responseJson.country_list) {
      this.setState({ countryList: responseJson.country_list.map((item: any) => ({ label: item, value: item })) });
    }
  };
  handleCityResponse = (responseJson: any) => {
    if (responseJson && responseJson.region_city_list) {
      this.setState({ cityList: responseJson.region_city_list.map((item: any) => ({ label: item.city, value: item.city })) });
    }
  };
  handleCountryCodeValue=(value:any,keyState:any)=>{
    this.setState({ initialValues: { ...this.state.initialValues, [keyState]: value} })
  }
  handleTab=()=>{
    this.setState({tabComplex:!this.state.tabComplex},()=>{
      this.getRequestsManagementList();
    })
  }
  handleFilterChange=(event:any,keyState:any)=>{
    if(keyState==="country"){
      this.getCityList(event.target.value)
    }else if(keyState==="selectedComplex"){
      const id=event.target.value.id;
      this.getBuildingsList(id);
      this.getUnitList(id)
    }
    
    this.setState({ filter: { ...this.state.filter, [keyState]:  event.target.value} })
  }
  getAllUser = async () => {
    this.showAllUserAPICallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: "bx_block_interactive_faqs/list_faq_cat_dashbord",
    });
  };
  getBuildingsList = async (id:any) => {
    this.GetAllBuildingsCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `society_managements/${id}/bx_block_meeting/find_building`,
    });
  };
  getUnitList = async (id:any) => {
    this.GetAllUnitCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_form/incident_managements/unit_list?building_management_id=${id}`,
    });
  };
  getComplexList = async () => {
    this.GetComplexCallId = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_society_management/society_managements`,
    });
  };
  getCityList = async (country: string) => {
    this.GetCityFilterList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_real_estate_companies/region_city_by_country?country=${country}`
    })
  }
  handleClose=()=>{
    this.setState({inviteMemberModelOpen:false})
  }
  handleInviteMember=()=>{
    this.setState({inviteMemberModelOpen:true})
  }
  handleMemberInvitation=(responseJson:any)=>{
    if (responseJson&& responseJson.data) {
      this.handleClose()
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      switch (apiRequestCallId) {
        case this.GetRequestsManagementList:
          this.handleRequestsManagementList(responseJson);
          break;
        case this.GetMemberInvitationList:
          this.handleMemberInvitation(responseJson);
          break;
        case this.GetCountryFilterList:
          this.handleCountryResponse(responseJson);
          break;
        case this.GetCityFilterList:
          this.handleCityResponse(responseJson);
          break;
        case this.GetComplexCallId:
            this.handleComplexList(responseJson);
            break;
        case this.GetAllBuildingsCallId:
          this.handleBuildingList(responseJson);
          break;
        case this.GetAllUnitCallId:
          this.handleUnitList(responseJson)
          break;
        case this.showAllUserAPICallId:
          this.handleFiltersList(responseJson)
        default:
            break;
      }
    }
    // Customizable Area End
  }
}
