import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { ApiCatchErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";


export interface Props {
    stepComplex: number,
    handleNext:any,
    handleBack:any,
    sharedFacilityPopupComplex: any,
    facilityPopupComplex:any,
    imagesFacility:any,
    previewImagesFacility:any,
    handleRemoveFacility:any,
    savedFacility:any,
    imagesFacilityError: boolean,
    selectedFilePdfFloorPlanComplex:any,
    handleRemoveFileFloorPlanComplex:any,
    handleFileChangesFloorPlanComplex:any,
    facilityValues:any,
    handleSharedDetails:any,
    deleteFacilityPopup:any,
    handleOpenDeleteFacilityPopup:any,
    handleCloseDeleteFacilityPopup:any,
    handleRemoveFacilityDetails:any,
    deleteFacilityIndex:any,
    t?: any
    i18n?: any
    match?: any
    location?: any
    // Customizable Area Start
    history?: any
    language?:any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CompEmpComplexBlockStep6Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
    exampleAPICallId: string = "";

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

        this.state = {
            // Customizable Area Start
            loading: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    // Customizable Area End
}
