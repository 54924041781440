import React from "react";
// Customizable Area Start
import {
    Box, Button, Grid, Typography, 
} from "@material-ui/core";
//@ts-ignore
import CompEmpComplexBuildingBlockStep2Controller from './CompEmpComplexBuildingBlockStep2Controller.web'
import "../../assets/commonForm.css"
// Customizable Area End

export default class CompEmpComplexBuildingBlockStep2 extends CompEmpComplexBuildingBlockStep2Controller {
    render() {
        // Customizable Area Start
        
        
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
            <Box>
                                    <Box>
                                        <Box className="complex">
                                            <Typography style={{ fontSize: '20px', fontWeight: 'bold', paddingBottom: '25px', borderBottom: '1px solid #F0F0F0' }}>Complex 1</Typography>
                                        </Box>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '25px' }}>
                                            <Box >
                                                <Typography className="bold-text" style={{ fontSize: '25px', fontWeight: 'bold' }}>Documents</Typography>
                                            </Box>
                                            <Box>
                                                <Typography style={{ fontSize: '16px' }}>Step {this.props.step + 1} of 5</Typography>
                                            </Box>
                                        </Box>
                                        <Box style={{ marginTop: '30px' }}>
                                            <Box style={{ width: '100%' }}>
                                                <Box >
                                                    <Typography className="typographyLable">Policy</Typography>
                                                    <Box style={{ marginBottom: "0px", border: "1px solid #F0F0F0", borderRadius: "8px", backgroundColor: "#F0F0F0", padding: "8px 25px 8px 8px" }}>
                                                        <Box style={{ display: 'flex', justifyContent: 'space-between' }} >
                                                            <Box style={{ display: 'flex', alignItems: 'center', flexWrap: "wrap" }}>
                                                                {this.props.selectedFilePdf.map((text: any, index: number) => (
                                                                    <Box key={index} style={{ border: "1px solid #F0F0F0", marginRight: '15px', display: 'flex', alignItems: 'center', borderRadius: "8px", background: "#FFF", padding: "13px 30px 13px 13px" }}>{text.name}
                                                                        <Box style={{ paddingLeft: '22px' }} data-test-id="handleRemoveFile" onClick={() => this.props.handleRemoveFile(index)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                                <path d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z" fill="black" />
                                                                            </svg>
                                                                        </Box>
                                                                    </Box>
                                                                ))}
                                                                <Box style={{ paddingLeft: "16px", color: '#8d8d8d', fontSize: '16px', fontWeight: 400 }}>{this.props.selectedFilePdf == 0 && "Upload Policy Documents"}</Box>
                                                            </Box>
                                                            <Box className="upload-button" onClick={this.props.handleFileChanges} style={{ margin: '8px 0px' }} ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M9 3.40501L6.71 5.70501C6.45634 5.95867 6.08663 6.05774 5.74012 5.96489C5.39362 5.87205 5.12297 5.6014 5.03012 5.25489C4.93728 4.90839 5.03634 4.53867 5.29 4.28501L9.29 0.285014C9.3851 0.193973 9.49725 0.122608 9.62 0.0750135C9.86346 -0.0250045 10.1365 -0.0250045 10.38 0.0750135C10.5028 0.122608 10.6149 0.193973 10.71 0.285014L14.71 4.28501C14.8993 4.47278 15.0058 4.72838 15.0058 4.99501C15.0058 5.26165 14.8993 5.51725 14.71 5.70501C14.5222 5.89433 14.2666 6.00081 14 6.00081C13.7334 6.00081 13.4778 5.89433 13.29 5.70501L11 3.40501V12.995C11 13.5473 10.5523 13.995 10 13.995C9.44771 13.995 9 13.5473 9 12.995V3.40501ZM18 12.995C18 12.4427 18.4477 11.995 19 11.995C19.5523 11.995 20 12.4427 20 12.995V16.995C20 18.6519 18.6569 19.995 17 19.995H3C1.34315 19.995 0 18.6519 0 16.995V12.995C0 12.4427 0.447715 11.995 1 11.995C1.55228 11.995 2 12.4427 2 12.995V16.995C2 17.5473 2.44772 17.995 3 17.995H17C17.5523 17.995 18 17.5473 18 16.995V12.995Z" fill="black" />
                                                            </svg></Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box style={{ marginTop: '20px' }}>
                                            <Box style={{ width: '100%' }}>
                                                <Box >
                                                    <Typography className="typographyLable">Guidelines</Typography>
                                                    <Box style={{ marginBottom: "0px", border: "1px solid #F0F0F0", borderRadius: "8px", backgroundColor: "#F0F0F0", padding: "8px 25px 8px 8px" }}>
                                                        <Box style={{ display: 'flex', justifyContent: 'space-between' }} >
                                                            <Box style={{ display: 'flex', alignItems: 'center', flexWrap: "wrap" }}>
                                                                {this.props.selectedFilePdfGuidelines.map((text: any, index: number) => (
                                                                    <Box key={index} style={{ border: "1px solid #F0F0F0", marginRight: '15px', display: 'flex', alignItems: 'center', borderRadius: "8px", background: "#FFF", padding: "13px 30px 13px 13px" }}>{text.name}
                                                                        <Box style={{ paddingLeft: '22px' }} data-test-id="handleRemoveFileGuidelines" onClick={() => this.props.handleRemoveFileGuidelines(index)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                                <path d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z" fill="black" />
                                                                            </svg>
                                                                        </Box>
                                                                    </Box>
                                                                ))}
                                                                {(this.props.uploadGuidelines())}
                                                            </Box>
                                                            <Box className="upload-button" onClick={this.props.handleFileChangesGuidelines} style={{ margin: '8px 0px' }} ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M9 3.40501L6.71 5.70501C6.45634 5.95867 6.08663 6.05774 5.74012 5.96489C5.39362 5.87205 5.12297 5.6014 5.03012 5.25489C4.93728 4.90839 5.03634 4.53867 5.29 4.28501L9.29 0.285014C9.3851 0.193973 9.49725 0.122608 9.62 0.0750135C9.86346 -0.0250045 10.1365 -0.0250045 10.38 0.0750135C10.5028 0.122608 10.6149 0.193973 10.71 0.285014L14.71 4.28501C14.8993 4.47278 15.0058 4.72838 15.0058 4.99501C15.0058 5.26165 14.8993 5.51725 14.71 5.70501C14.5222 5.89433 14.2666 6.00081 14 6.00081C13.7334 6.00081 13.4778 5.89433 13.29 5.70501L11 3.40501V12.995C11 13.5473 10.5523 13.995 10 13.995C9.44771 13.995 9 13.5473 9 12.995V3.40501ZM18 12.995C18 12.4427 18.4477 11.995 19 11.995C19.5523 11.995 20 12.4427 20 12.995V16.995C20 18.6519 18.6569 19.995 17 19.995H3C1.34315 19.995 0 18.6519 0 16.995V12.995C0 12.4427 0.447715 11.995 1 11.995C1.55228 11.995 2 12.4427 2 12.995V16.995C2 17.5473 2.44772 17.995 3 17.995H17C17.5523 17.995 18 17.5473 18 16.995V12.995Z" fill="black" />
                                                            </svg></Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box style={{ marginTop: '20px' }}>
                                            <Box style={{ width: '100%' }}>
                                                <Box >
                                                    <Typography className="typographyLable">Roles</Typography>
                                                    <Box style={{ marginBottom: "0px", border: "1px solid #F0F0F0", borderRadius: "8px", backgroundColor: "#F0F0F0", padding: "8px 25px 8px 8px" }}>
                                                        <Box style={{ display: 'flex', justifyContent: 'space-between' }} >
                                                            <Box style={{ display: 'flex', alignItems: 'center', flexWrap: "wrap" }}>
                                                                {this.props.selectedFilePdfRoles.map((text: any, index: number) => (
                                                                    <Box key={index} style={{ border: "1px solid #F0F0F0", marginRight: '15px', display: 'flex', alignItems: 'center', borderRadius: "8px", background: "#FFF", padding: "13px 30px 13px 13px" }}>{text.name}
                                                                        <Box style={{ paddingLeft: '22px' }} data-test-id="handleRemoveFileRoles" onClick={() => this.props.handleRemoveFileRoles(index)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                                <path d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z" fill="black" />
                                                                            </svg>
                                                                        </Box>
                                                                    </Box>
                                                                ))}
                                                                <Box style={{ paddingLeft: "16px", color: '#8d8d8d', fontSize: '16px', fontWeight: 400 }}>{this.props.selectedFilePdfRoles == 0 && "Upload Roles Documents"}</Box>
                                                            </Box>
                                                            <Box className="upload-button" onClick={this.props.handleFileChangesRoles} style={{ margin: '8px 0px' }} ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M9 3.40501L6.71 5.70501C6.45634 5.95867 6.08663 6.05774 5.74012 5.96489C5.39362 5.87205 5.12297 5.6014 5.03012 5.25489C4.93728 4.90839 5.03634 4.53867 5.29 4.28501L9.29 0.285014C9.3851 0.193973 9.49725 0.122608 9.62 0.0750135C9.86346 -0.0250045 10.1365 -0.0250045 10.38 0.0750135C10.5028 0.122608 10.6149 0.193973 10.71 0.285014L14.71 4.28501C14.8993 4.47278 15.0058 4.72838 15.0058 4.99501C15.0058 5.26165 14.8993 5.51725 14.71 5.70501C14.5222 5.89433 14.2666 6.00081 14 6.00081C13.7334 6.00081 13.4778 5.89433 13.29 5.70501L11 3.40501V12.995C11 13.5473 10.5523 13.995 10 13.995C9.44771 13.995 9 13.5473 9 12.995V3.40501ZM18 12.995C18 12.4427 18.4477 11.995 19 11.995C19.5523 11.995 20 12.4427 20 12.995V16.995C20 18.6519 18.6569 19.995 17 19.995H3C1.34315 19.995 0 18.6519 0 16.995V12.995C0 12.4427 0.447715 11.995 1 11.995C1.55228 11.995 2 12.4427 2 12.995V16.995C2 17.5473 2.44772 17.995 3 17.995H17C17.5523 17.995 18 17.5473 18 16.995V12.995Z" fill="black" />
                                                            </svg></Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box style={{ marginTop: '20px' }}>
                                            <Box style={{ width: '100%' }}>
                                                <Box >
                                                    <Typography className="typographyLable">Resolutions</Typography>
                                                    <Box style={{ marginBottom: "0px", border: "1px solid #F0F0F0", borderRadius: "8px", backgroundColor: "#F0F0F0", padding: "8px 25px 8px 8px" }}>
                                                        <Box style={{ display: 'flex', justifyContent: 'space-between' }} >
                                                            <Box style={{ display: 'flex', alignItems: 'center', flexWrap: "wrap" }}>
                                                                {this.props.selectedFilePdfResolutions.map((text: any, index: number) => (
                                                                    <Box key={index} style={{ border: "1px solid #F0F0F0", marginRight: '15px', display: 'flex', alignItems: 'center', borderRadius: "8px", background: "#FFF", padding: "13px 30px 13px 13px" }}>{text.name}
                                                                        <Box style={{ paddingLeft: '22px' }} data-test-id="handleRemoveFileResolutions" onClick={() => this.props.handleRemoveFileResolutions(index)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                                <path d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z" fill="black" />
                                                                            </svg>
                                                                        </Box>
                                                                    </Box>
                                                                ))}
                                                                <Box style={{ paddingLeft: "16px", color: '#8d8d8d', fontSize: '16px', fontWeight: 400 }}>{this.props.selectedFilePdfResolutions == 0 && "Upload Resolutions Documents"}</Box>
                                                            </Box>
                                                            <Box className="upload-button" onClick={this.props.handleFileChangesResolutions} style={{ margin: '8px 0px' }} ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M9 3.40501L6.71 5.70501C6.45634 5.95867 6.08663 6.05774 5.74012 5.96489C5.39362 5.87205 5.12297 5.6014 5.03012 5.25489C4.93728 4.90839 5.03634 4.53867 5.29 4.28501L9.29 0.285014C9.3851 0.193973 9.49725 0.122608 9.62 0.0750135C9.86346 -0.0250045 10.1365 -0.0250045 10.38 0.0750135C10.5028 0.122608 10.6149 0.193973 10.71 0.285014L14.71 4.28501C14.8993 4.47278 15.0058 4.72838 15.0058 4.99501C15.0058 5.26165 14.8993 5.51725 14.71 5.70501C14.5222 5.89433 14.2666 6.00081 14 6.00081C13.7334 6.00081 13.4778 5.89433 13.29 5.70501L11 3.40501V12.995C11 13.5473 10.5523 13.995 10 13.995C9.44771 13.995 9 13.5473 9 12.995V3.40501ZM18 12.995C18 12.4427 18.4477 11.995 19 11.995C19.5523 11.995 20 12.4427 20 12.995V16.995C20 18.6519 18.6569 19.995 17 19.995H3C1.34315 19.995 0 18.6519 0 16.995V12.995C0 12.4427 0.447715 11.995 1 11.995C1.55228 11.995 2 12.4427 2 12.995V16.995C2 17.5473 2.44772 17.995 3 17.995H17C17.5523 17.995 18 17.5473 18 16.995V12.995Z" fill="black" />
                                                            </svg></Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box style={{ marginTop: '20px' }}>
                                            <Box style={{ width: '100%' }}>
                                                <Box >
                                                    <Typography className="typographyLable">Building Plans</Typography>
                                                    <Box style={{ marginBottom: "0px", border: "1px solid #F0F0F0", borderRadius: "8px", backgroundColor: "#F0F0F0", padding: "8px 25px 8px 8px" }}>
                                                        <Box style={{ display: 'flex', justifyContent: 'space-between' }} >
                                                            <Box style={{ display: 'flex', alignItems: 'center', flexWrap: "wrap" }}>
                                                                {this.props.selectedFilePdfBuildingPlans.map((text: any, index: number) => (
                                                                    <Box key={index} style={{ border: "1px solid #F0F0F0", marginRight: '15px', display: 'flex', alignItems: 'center', borderRadius: "8px", background: "#FFF", padding: "13px 30px 13px 13px" }}>{text.name}
                                                                        <Box style={{ paddingLeft: '22px' }} data-test-id="handleRemoveFileBuildingPlans" onClick={() => this.props.handleRemoveFileBuildingPlans(index)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                                <path d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z" fill="black" />
                                                                            </svg>
                                                                        </Box>
                                                                    </Box>
                                                                ))}
                                                                <Box style={{ paddingLeft: "16px", color: '#8d8d8d', fontSize: '16px', fontWeight: 400 }}>{this.props.selectedFilePdfBuildingPlans == 0 && "Upload Building Plans Documents"}</Box>
                                                            </Box>
                                                            <Box className="upload-button" onClick={this.props.handleFileChangesBuildingPlans} style={{ margin: '8px 0px' }} ><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M9 3.40501L6.71 5.70501C6.45634 5.95867 6.08663 6.05774 5.74012 5.96489C5.39362 5.87205 5.12297 5.6014 5.03012 5.25489C4.93728 4.90839 5.03634 4.53867 5.29 4.28501L9.29 0.285014C9.3851 0.193973 9.49725 0.122608 9.62 0.0750135C9.86346 -0.0250045 10.1365 -0.0250045 10.38 0.0750135C10.5028 0.122608 10.6149 0.193973 10.71 0.285014L14.71 4.28501C14.8993 4.47278 15.0058 4.72838 15.0058 4.99501C15.0058 5.26165 14.8993 5.51725 14.71 5.70501C14.5222 5.89433 14.2666 6.00081 14 6.00081C13.7334 6.00081 13.4778 5.89433 13.29 5.70501L11 3.40501V12.995C11 13.5473 10.5523 13.995 10 13.995C9.44771 13.995 9 13.5473 9 12.995V3.40501ZM18 12.995C18 12.4427 18.4477 11.995 19 11.995C19.5523 11.995 20 12.4427 20 12.995V16.995C20 18.6519 18.6569 19.995 17 19.995H3C1.34315 19.995 0 18.6519 0 16.995V12.995C0 12.4427 0.447715 11.995 1 11.995C1.55228 11.995 2 12.4427 2 12.995V16.995C2 17.5473 2.44772 17.995 3 17.995H17C17.5523 17.995 18 17.5473 18 16.995V12.995Z" fill="black" />
                                                            </svg></Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="step3BtnBox" style={{ display: 'flex', justifyContent: "end", flexWrap: 'wrap' }}>
                                <Grid className="step3Grid" style={{ marginTop: "30px", marginRight: '20px' }}>
                    <Box style={{}}>
                        <Button className="step3Prev" style={{ color: "#2B6FED", fontWeight: 'bold', padding: '0px 20.5px', textTransform: 'capitalize', width: '177px', height: '56px', border: '1px solid #2B6FED', borderRadius: "8px", display: 'flex', justifyContent: 'center', textAlign: 'center' }} onClick={this.props.handleBackDoc}><span className="bold-text">PREV</span></Button>
                    </Box>
                </Grid>
                <Box>
                <Grid className="step3Grid" style={{ marginTop: "30px", }}>
                            <Button className="step3Next" style={{ width: '177px', height: '56px', backgroundColor: '#2B6FED', borderRadius: "8px", display: 'flex', justifyContent: 'center', textAlign: 'center', color: "#FFFFFF", fontWeight: 'bold', padding: '0px 20.5px', textTransform: 'capitalize' }} data-testid="handleNextStep3" onClick={this.props.handleNextDoc}><span className="bold-text">NEXT</span></Button>
                        </Grid>
                </Box>
            </Box>
                                </>

            // Customizable Area End
        );
    }
}

// export default withTranslation()(withRouter(CompEmpComplexBuildingBlock));
// Customizable Area Start
const webStyle = {
    buttonSave: {
        width: '177px',
        height: '56px',
        backgroundColor: '#2B6FED',
        borderRadius: "8px",
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        color: "#FFFFFF",
        fontWeight: 'bold',
        padding: '0px 20.5px',
        textTransform: 'capitalize'
    },
    step6:{
        margin: '11px 0px',
        color: 'black',
        fontSize: '16px', 
        fontWeight: 400
    }
}
// Customizable Area End