import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { format } from 'date-fns';
import { t } from "i18next";
import * as Yup from "yup";
interface FormValue {
  type:string;
  country:string;
  city:string;
  individualBuilding:string;
  complex:string;
  building:string;
  unit:string;
  fullName:string;
  emailID:string;
  countryCode:string;
  phoneNumber:string;
}

// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  formValue: FormValue;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isResendDialogOpen: boolean;
  isDeleteDialogOpen:boolean;
  waitingAcceptenceList:any;
  selectedForResend:any;
  filter:any;
  countryList: any[],
  cityList: any[],
  complexList:any[];
  buildingsList:any[];
  unitList:any[];
  userList:any[];
  complexBuildingFilterList:any[];
  formValue: FormValue;
  deleteItemId:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CEAwaitAcceptController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  GetWaitingAcceptList:any;
  GetMemberInvitationList:any;
  showAllUserAPICallId:any;
  GetCountryList:any;
  GetCityList:any;
  GetComplexListId:any;
  GetAllBuildingsDataCallId:any;
  GetAllUnitDataCallId:any;
  DeleteWaitingAcceptCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      isResendDialogOpen: false,
      isDeleteDialogOpen:false,
      waitingAcceptenceList:[],
      selectedForResend:"",
      filter: "",
      countryList: [],
      cityList: [],
      complexList: [],
      buildingsList: [],
      unitList: [],
      userList: [],
      formValue: {
        type:"",
        country:"",
        city:"",
        individualBuilding:"",
        complex:"",
        building:"",
        unit:"",
        fullName:"",
        emailID:"",
        countryCode:"+1",
        phoneNumber:""
      },
      complexBuildingFilterList: [
        {
          label: t("Complex"),
          value: 'complex'
        },
        {
          label: t("Indiviual Building"),
          value: 'indiviualBuilding'
        },
      ],
      deleteItemId:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      switch (apiRequestCallId) {
        case this.GetWaitingAcceptList:
          this.handlegetWaitingAcceptenceList(responseJson);
          break;
        case this.GetCountryList:
          this.handleCountryResponse(responseJson)
          break;
        case this.showAllUserAPICallId:
          this.handleFiltersList(responseJson)
          break;
        case this.GetComplexListId:
          this.handleComplexList(responseJson)
          break;
        case this.GetCityList:
          this.handleCityResponse(responseJson)
          break;
        case this.GetAllBuildingsDataCallId:
          this.handleBuildingList(responseJson)
          break;
        case this.GetAllUnitDataCallId:
          this.handleUnitList(responseJson)
          break;
        case this.GetMemberInvitationList:
          this.handleMemberResendInvitation(responseJson);
          break;
        case this.DeleteWaitingAcceptCallId:
          this.handleMemberDeleteInvitation(responseJson);
          break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getWaitingAcceptenceList();
    this.getAllUser();
    this.getCountryListTable();
    this.getComplexList();
  }
  handleMemberResendInvitation=(responseJson:any)=>{
    if (responseJson&& responseJson.data) {
      this.handleClose()
    }
  }
  handleFormSubmit=async(value:any)=>{
    console.log("handleFormSubmit::",value);
    let formData = new FormData();
    
    formData.append("member_invitation[full_name]", value.fullName);
    formData.append("member_invitation[email_address]", value.emailID);
    formData.append("member_invitation[phone_number]", `${value.countryCode}${value.phoneNumber}`);
    formData.append("member_invitation[role_id]", value.type);
    formData.append("member_invitation[building_management_id]", value?.building);
    formData.append("member_invitation[apartment_management_id]",  value?.unit?.id);
    formData.append("member_invitation[society_management_id]","32");
    
    this.GetMemberInvitationList = await apiCall({
      method: configJSON.sellerDetailsAPIMethodPOST,
      endPoint: configJSON.createMemberInvitationEndPoint,
      body:formData
    });
  }
  getComplexList = async ( ) => {
    this.GetComplexListId = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getComplexListEndPoint
    });
  };
  getCountryListTable = async () => {
    this.setState({ loading: true })
    this.GetCountryList = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.createMemberCountryListEndPoint,
    });
  }
  getAllUser = async () => {
    this.showAllUserAPICallId = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getUserTypeEndPoint
    });
  };
  handleMemberDeleteInvitation=(responseJson:any)=>{
    this.setState({isDeleteDialogOpen:false, loading:false})
    if(responseJson && responseJson.message){
      this.getWaitingAcceptenceList()
    }
  }
  handleAccept =async () => {
    this.setState({ loading: true })
    this.DeleteWaitingAcceptCallId = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.sellerDetailsAPIMethodDELETE,
      endPoint: configJSON.deletedApiEndPoint+`/${this.state.deleteItemId}`,
    });
  }
  handleFiltersList = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({  userList: responseJson.data.map((item: any) => ({ label: item.attributes.title, value: item.id }))})
    }
  }
  handleComplexList=(responseJson:any)=>{
    if (responseJson && responseJson.data) {
      this.setState({ complexList: responseJson.data.map((item: any) => ({ label: item.attributes.name, value: item.id }))}
      )
    }
  }
  handleUnitList=(responseJson:any)=>{
    if (responseJson && responseJson.data.apartments) {
      this.setState({ unitList: responseJson.data.apartments.map((item: any) => ({
        label: item.apartment_name,
        value: {
          id: item?.id,
          society_management_id: item?.society_management_id,
          building_management_id: item?.building_management_id
        }
      })
      )})
    }
  }
  handleBuildingList=(responseJson:any)=>{
    if (responseJson && responseJson.buildings) {
      this.setState({
         buildingsList: responseJson.buildings.map((item: any) => ({ label: item.name, value: item.id }))}
      )
    }
  }
  handleCountryResponse = (responseJson: any) => {
    if (responseJson && responseJson.country_list) {
      this.setState({
         countryList: responseJson.country_list.map((item: any) => ({ label: item, value: item }))
      });
    }
  };
  handleCityResponse = (responseJson: any) => {
    if (responseJson && responseJson.region_city_list) {
      this.setState({
         cityList: responseJson.region_city_list.map((item: any) => ({ label: item.city, value: item.city })) });
    }
  };
  getWaitingAcceptenceList = async () => {
    this.setState({ loading: true })
    this.GetWaitingAcceptList = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getwaitingAcceptenceEndPoint,
    });
  }
  getWaitingAcceptenceFiletrList = async () => {
    this.setState({ loading: true })
    this.GetWaitingAcceptList = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getwaitingAcceptenceEndPoint + `?role=${this.state.filter.selectedType}&country=${this.state.filter.selectedCountry}&city=${this.state.filter.selectCity}&complex_id=${this.state.filter.selectedComplex}&building_id=${this.state.filter.selectedBuilding}&apartment_management_id=${this.state.filter.selectedBuilding}`,
    })
  }
  getBuildingsDataList = async (id:any) => {
    this.GetAllBuildingsDataCallId = await apiCall({
      method: configJSON.getSellersAPIMethod,
      contentType: configJSON.sellerDetailsApiContentType,
      endPoint: `society_managements/${id}/bx_block_meeting/find_building`,
    });
  };
  getCityList = async (country: any) => {
    this.GetCityList = await apiCall({
      method: configJSON.getSellersAPIMethod,
      contentType:configJSON.sellerDetailsApiContentType,
      endPoint:configJSON.getCityListEndPoint+`?country=${country}`
    })
  }
 
  getUnitDataList = async (id:any) => {
    this.GetAllUnitDataCallId= await apiCall({
      method: configJSON.getSellersAPIMethod,
      contentType: configJSON.sellerDetailsApiContentType,
      endPoint: `bx_block_custom_form/incident_managements/unit_list?building_management_id=${id}`,
    });
  };
  
  handleChangeValue=(event:any,keyState:any)=>{
    
    if(keyState==="selectedCountry"){
      this.getCityList(event.target.value)
    }else if(keyState==="selectedComplex"){
      const id=event.target.value;
      this.getBuildingsDataList(id);
      this.getUnitDataList(id)
    }
    
    this.setState({ filter: { ...this.state.filter, [keyState]:  event.target.value} })
  }
  handlegetWaitingAcceptenceList = (responseJson: any) => {
    this.setState({ loading: false })
    if (responseJson) {
      this.setState({
        waitingAcceptenceList: responseJson.data || []
      })
    }
  }
  meetingValidation: any = Yup.object().shape({
    type: Yup.string().required("Required").matches(/\S/, "Required"),
    country: Yup.string().required("Required").matches(/\S/, "Required"),
    fullName: Yup.string().required("Required").max(100, "Maximum length of Name should be 100 character").matches(/\S/, "Required"),
    city: Yup.string().required("Required").matches(/\S/, "Required"),
    complex: Yup.string().required("Required").matches(/\S/, "Required"),
    building: Yup.string().required("Required").matches(/\S/, "Required"),
    unit: Yup.string().required("Required").matches(/\S/, "Required"),
    phoneNumber: Yup.string().required("Required").matches(/\S/, "Required"),
    individualBuilding: Yup.string().required("Required").matches(/\S/, "Required"),
    emailID: Yup.string().email("Invalid email format").required("Required"),
  });
 
  handleClose=()=>{
    this.setState({isResendDialogOpen:false})
  }
  handleCountryCodeValue=(value:any,keyState:any)=>{
    this.setState({ filter: { ...this.state.filter, [keyState]: value} })
  }
  handleDateFormat = (dateString: string) => {
      const p = dateString.split(/\D/g);
      return [p[2], p[1], p[0]].join("-")
  };
  handleDelete=(item?:any)=>{
    this.setState({ isDeleteDialogOpen: !this.state.isDeleteDialogOpen,  deleteItemId:item?.id,});
  }
  handleDialog = (item?:any) => {
    this.setState({
      selectedForResend :item ,
      isResendDialogOpen: !this.state.isResendDialogOpen,
      formValue: {
      type:item?.attributes?.role.id,
      country:"",
      city:"",
      individualBuilding:"",
      complex:"",
      building:item?.attributes?.building_management?.id || "",
      unit:item?.attributes?.apartment_management?.id || "",
      fullName:item?.attributes?.full_name,
      emailID:item?.attributes?.email_address,
      countryCode:item?.attributes?.phone_number?.split(" ")[0],
      phoneNumber:item?.attributes?.phone_number?.split(" ")[1]
    }})
  };
  // Customizable Area End
}
