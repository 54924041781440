// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
export const configJSON = require("./config");
import { Message } from "../../../framework/src/Message";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  t:(value:string)=> string
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  country: string,
  city: string,
  complex: string,
  selectComplex: string,
  building: string,
  unit: string,
  countryList: Array<string>;
  cityList: Array<string>;
  hasSuccessError: HasSuccessErrorType;
  findPersonModal: boolean;
  addNewPersonModal: boolean;
  anchorEl: HTMLElement | null;
  selectedId: number | null;
  selectedMarker: Array<DataItem>;
  countryAndFlagList: Array<CountryCodeAndFlag>;
  buildingList: Array<SingleBuilding>;
  complexList: Array<SingleComplexDataItem>;
  unitNumberList: Array<AllUnitNumberListDatainterface>;
  findNewPersonUserType:string;
  // Customizable Area End
}

// Customizable Area Start
export interface DataItem {
  id: number;
  name: string;
}
export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}

interface HasSuccessErrorType {
  isOpen: boolean,
  isSeverity: "success" | "info" | "warning" | "error",
  isMessage: string,
}
export interface ApiFailureResponse {
  errors: Array<ApiFailureResponseError>
}

export interface ApiFailureResponseError {
  token: string
}
export interface GetAllCountrySuccessResponse {
  country_list: Array<string>
}

export interface GetAllCitySuccessResponse {
  city_list: Array<string>
}
export interface CountryCodeAndFlagSuccessResponse {
  data: Array<CountryCodeAndFlag>
}
export interface CountryCodeAndFlag {
  id: string;
  type: string;
  attributes: {
    name: string;
    emoji_flag: string;
    country_code: number
  }
}

export interface GetBuildingSuccessResponse {
  data: Array<SingleBuilding>
}
interface Unit {
  id: number;
  unit: string;
}

export interface SingleBuilding {
  id: string;
  type: string;
  attributes: {
    name: string;
    description: string;
    per_floor_unit: number;
    generation_methods: string;
    number_of_floor: number;
    building_area: string;
    society_management: SocietyManagement;
    logo: Logo;
    photos: Photo[];
    total_floors: number;
    total_units: number;
    unit_name: Unit[];
    shared_area: SharedArea[];
    googlemaplocation: string;
  };
}

interface SharedArea {
  id: number;
  name: string;
  // Add other properties as needed
}
interface SocietyManagement {
  id: number;
  name: string;
  description: string;
  total_floor: number;
  total_unit: number;
}

interface Logo {
  url: string;
  content_type: string;
  file_name: string;
}
interface Photo {
  url: string;
  content_type: string;
  file_name: string;
}

interface ComplexListData {
  code: number;
  society_management: {
    data: Array<SingleComplexDataItem>;
  };
  meta: {
    pagination: {
      current_page: number;
      next_page: number | null;
      prev_page: number | null;
      total_pages: number;
      total_count: number;
    };
  };
}

export interface SingleComplexDataItem {
  id: string;
  type: string;
  attributes: SocietyAttributesData;
}
interface SocietyAttributesData {
  name: string;
  description: string;
  complex_area: string;
  measurement_unit: string | null;
  maintenance_per_square_feet: number | null;
  is_building: boolean;
  total_floor: number | null;
  total_unit: number | null;
  currency_id: number | null;
  account_manager_id: number | null;
  language: string | null;
  logo: SocietyPhotoData;
  photos: SocietyPhotoData[];
  subscription_end_in: string | null;
  subscription_id: string | null;
  is_building_list: boolean | null;
  building_list: any[];
  shared_area: any[];
  documents: any[];
  building_documents: any[];
  incident_categories: Record<string, number>;
  total_buildings: number;
  active_users: number | null;
  inactive_users: number | null;
  pending_ownership_requests: number;
  collected_management_fee: number | null;
  status: string;
  company_name: string;
  rented: number;
  total_polls: number;
  last_date_of_polls: string | null;
  total_surveys: number;
  last_date_of_surveys: string | null;
  building_ownership_rate: number | null;
  ownership_onboarded: {
    ownership_percent: string;
    ownership_count: number;
  };
  tenant_resquests: {
    tenant_percent: number;
    tenant_count: number;
  };
  total_units: any[];
  account_manager: any;
  sales_manager: any;
  country: string;
  city: string;
  lat: number;
  long: number;
  streetname: string | null;
  zipcode: string;
  landmark: string;
  locationlink: string;
  complex_lat: number | null;
  complex_long: number | null;
  currency: string | null;
  subscription_type: string | null;
  plan_id: string | null;
  paying_month: string | null;
  googlemaplocation: string | null;
  jurisdiction: JurisdictionDataItem;
}

interface SocietyPhotoData {
  url: string;
  content_type: string;
  file_name: string;
}

interface JurisdictionDataItem {
  data: {
    id: string;
    type: string;
    attributes: JurisdictionAttributesData;
  };
}
interface JurisdictionAttributesData {
  id: number;
  jurisdiction_id: number;
  jurisdiction_name: string;
  currency: string;
  country: string | null;
  timezone: string;
  unit_of_measurement: string;
  mobile_number_length: string;
  formula: string;
  value_added_tax: string | null;
  zone_multiplier: number | null;
  complex: AllComplexListItemData[];
  total_complex: number;
  total_buildings: number;
  total_units: number;
  contracts: any[];
}

interface AllComplexListItemData {
  id: number;
  dataId:string;
  name: string;
  created_at: string;
  description: string;
  updated_at: string;
  complex_area: string;
  measurement_unit: string | null;
  maintenance_per_square_feet: number | null;
  contigency: number | null;
  ticket_days_configuration: number;
  late_charge_percentage: string | null;
  zone_multiplier: number | null;
  longitude: number | null;
  latitude: number | null;
  value_added_tax: string | null;
  real_estate_company_id: number | null;
  is_building: boolean;
  total_unit: number | null;
  total_floor: number | null;
  account_manager_id: number | null;
  language: string | null;
  currency_id: number | null;
}

export interface UnitNumberListData {
  data: Array<AllUnitNumberListDatainterface>
}
export interface AllUnitNumberListDatainterface {
  id: string;
  type: string;
  attributes: {
    apartment_name: string;
    floor_number: number;
    dataId:string,
    purchase_price: null | any;
    configuration: null | any;
    size: null | any;
    current_valuation: null | any;
    purchase_date: null | any;
    monthly_renting_income: null | any;
    society_management: {
      id: number;
      status: string;
      name: string;
      created_at: string;
      description: string;
      complex_area: string;
      updated_at: string;
      measurement_unit: string;
      ticket_days_configuration: number;
      maintenance_per_square_feet: number;
      zone_multiplier: number;
      contigency: number;
      latitude: null | any;
      late_charge_percentage: string;
      value_added_tax: string;
      longitude: null | any;
      is_building: boolean;
      total_floor: null | any;
      real_estate_company_id: null | any;
      currency_id: null | any;
      account_manager_id: null | any;
      total_unit: null | any;
      language: null | any;
    };
    building_management: {
      name: string;
      id: number;
      description: null | any;
      society_management_id: number;
      updated_at: string;
      created_at: string;
      generation_methods: string;
      per_floor_unit: number;
      building_area: string;
      number_of_floor: number;
      lat: null | any;
      account_id: null | any;
      city: null | any;
      long: null | any;
      googlemaplocation: null | any;
      real_estate_company_id: null | any;
      country: null | any;
    };
    photos: any[];
    owner: null | any;
    resident: null | any;
    phone_number: null | any;
    family_members: number;
    address: {
      city: string;
      currency: string;
    };
  };
}

// Customizable Area End

interface SS {
  id: string;
}
export default class LeaseManagementIssueNewLeaseController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  countryApiCallId: string = "";
  cityListApiCallId: string = "";
  countryCodeAndFlagApiCallId: string = "";
  buildingListApiCallId: string = "";
  complexListApiCallId: string = "";
  unitListApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      country: "",
      city: "",
      complex: "",
      selectComplex: "",
      building: "",
      unit: "",
      countryList: [],
      cityList: [],
      hasSuccessError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      findPersonModal: false,
      addNewPersonModal: false,
      anchorEl: null,
      selectedId: null,
      selectedMarker: [
        { id: 1, name: "Marker 1" },
        { id: 2, name: "Marker 2" },

      ],
      countryAndFlagList: [],
      buildingList: [],
      complexList: [],
      unitNumberList: [],
      findNewPersonUserType:"",
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount()
    this.getCountryList();
    this.getCityList();
    this.getCountryAndFlagList();
    this.getBuildingList();
    this.getComplexList();
    this.getUnitNumberList();
    // Customizable Area End
  }

  // Customizable Area Start
  apiCall = async (data: APIPayloadType) => {
    let { method, endPoint, body, type = "", contentType } = data;
    let token = localStorage.getItem("loginSuccessToken")
    const header = {
      "Content-Type": contentType,
      token: token
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.responseSucessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.responseFailureCall(apiRequestCallId, responseJson);
      }
    }
  }

  responseSucessCell = async (
    apiRequestCallId: string,
    responseJson: GetAllCountrySuccessResponse &
      GetAllCitySuccessResponse & CountryCodeAndFlagSuccessResponse & GetBuildingSuccessResponse &
      ComplexListData &
      UnitNumberListData) => {

    if (apiRequestCallId === this.countryApiCallId) {
      this.getCountryListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.cityListApiCallId) {
      this.getCityListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.countryCodeAndFlagApiCallId) {
      this.getCountryCodeAndFlagListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.buildingListApiCallId) {
      this.getBuildingListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.complexListApiCallId) {
      this.getComplexListSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.unitListApiCallId) {
      this.getUnitNumberListSucessCallBack(responseJson);
    }

  };

  responseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {

    if (apiRequestCallId === this.countryApiCallId) {
      this.getCountryListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.cityListApiCallId) {
      this.getCityListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.countryCodeAndFlagApiCallId) {
      this.getCountryCodeAndFlagListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.buildingListApiCallId) {
      this.getBuildingListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.complexListApiCallId) {
      this.getComplexListFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.unitListApiCallId) {
      this.getUnitListNumberFailureCallBack(responseJson);
    }
  };

  getCountryList = async () => {
    this.countryApiCallId = await this.apiCall({
      method: configJSON.countryListMethod,
      endPoint: configJSON.countryEndPoint,
    });
  };

  getCountryListSucessCallBack = (response: GetAllCountrySuccessResponse) => {
    this.setState({ countryList: response.country_list })
  };

  getCountryListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: response.errors[0].token
      },
    })
  };

  getCityList = async () => {
    this.cityListApiCallId = await this.apiCall({
      method: configJSON.cityListMethod,
      endPoint: configJSON.cityEndPoint,
    });
  };

  getCityListSucessCallBack = (response: GetAllCitySuccessResponse) => {
    this.setState({ cityList: response.city_list })
  };

  getCityListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: response.errors[0].token
      },
    })
  };

  getCountryAndFlagList = async () => {
    this.countryCodeAndFlagApiCallId = await this.apiCall({
      method: configJSON.countryCodeAndFlagMethod,
      endPoint: configJSON.countryCodeAndFlagEndPoint,
    });
  };

  getCountryCodeAndFlagListSucessCallBack = (response: CountryCodeAndFlagSuccessResponse) => {
    this.setState({ countryAndFlagList: response.data }, () => {
    })
  };

  getCountryCodeAndFlagListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: response.errors[0].token
      },
    })
  };

  getBuildingList = async () => {
    this.buildingListApiCallId = await this.apiCall({
      method: configJSON.buildingListMethod,
      endPoint: configJSON.buildingListEndPoint,
    });
  };

  getBuildingListSucessCallBack = (response: GetBuildingSuccessResponse) => {
    this.setState({ buildingList: response.data })
  };

  getBuildingListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: response.errors[0].token
      },
    })
  };

  getComplexList = async () => {
    this.complexListApiCallId = await this.apiCall({
      method: configJSON.complexListMethod,
      endPoint: configJSON.complexListEndPoint,
    });
  };

  getComplexListSucessCallBack = (response: ComplexListData) => {
    this.setState({ complexList: response.society_management.data })
  };

  getComplexListFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: response.errors[0].token
      },
    })
  };

  getUnitNumberList = async () => {
    this.unitListApiCallId = await this.apiCall({
      method: configJSON.unitNumberListMethod,
      endPoint: configJSON.unitNumberListEndPoint,
    });
  };

  getUnitNumberListSucessCallBack = (response: UnitNumberListData) => {
    this.setState({ unitNumberList: response.data })
  };
  
  getUnitListNumberFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: response.errors[0].token
      },
    })
  };

  handleCloseSnack = () => {
    this.setState({
      hasSuccessError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      }
    })
  };
  handleClick = (event: React.MouseEvent<HTMLElement>, id: number) => {
    this.setState({ anchorEl: event?.currentTarget, selectedId: id });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, selectedId: null });
  };
  handleOpenFindPersonModal = () => {
    this.setState({ findPersonModal: true })
  }
  handleCloseFindPersonModal = () => {
    this.setState({ findPersonModal: false, })
  }
  handleAddNewPerson = () => {
    this.setState({ addNewPersonModal: true, findPersonModal: false })
  }

  handleCloseAddNewPersonModal = () => {
    this.setState({ addNewPersonModal: false })
  }

  // Customizable Area End
}