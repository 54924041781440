// Customizable Area Start
import React from "react";
import {
    Box,
    Breadcrumbs,
    Button,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    MenuItem,
    Modal,
    Paper,
    Popover,
    Select,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    styled,
} from "@material-ui/core";
import LeaseManagementIssueNewLeaseController, { Props, CountryCodeAndFlag, DataItem, SingleBuilding, SingleComplexDataItem } from "./LeaseManagementIssueNewLeaseController.web";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { BuildingIcon, LocationIcon, TanentProfileIcon, UnitIcon, email_icon, user_icon } from "./assets";
import Alert from "@material-ui/lab/Alert";
import Search from "@material-ui/icons/Search";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withTranslation } from "react-i18next";
// Customizable Area End

// Customizable Area Start
type TFunction = (value: string) => string;
// Customizable Area End

class LeaseManagementIssueNewLease extends LeaseManagementIssueNewLeaseController {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area Start
    }

    // Customizable Area Start 
    renderIssueLeaseForm = () => {
        const { t }: { t: TFunction } = this.props;
        return (
            <Paper elevation={2} className="issueLeaseFormPaper">
                <FormControl variant="outlined" fullWidth>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label={t("Select Country")}
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        className="leaseIssueLeaseSelect"
                        value={this.state.country}
                        displayEmpty
                    >
                        <MenuItem value={""}><em>{t("Select Country")}</em></MenuItem>
                        {this.state.countryList.map((country: string, index: number) => {
                            return <MenuItem value={country} key={index}>{country}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label={t("Select City")}
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        className="leaseIssueLeaseSelect"
                        displayEmpty
                        value={this.state.city}
                    >
                        <MenuItem value={""}><em>{t("Select City")}</em></MenuItem>
                        {this.state.cityList.map((city: string, index: number) => {
                            return <MenuItem value={city} key={index}>{city}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label={t("Complex")}
                        IconComponent={ExpandMoreIcon}
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        className="leaseIssueLeaseSelect"
                        displayEmpty
                        value={this.state.complex}
                    >
                        <MenuItem value={""}><em>{t("Complex")}</em></MenuItem>
                        <MenuItem value={"Complex"}>{t("Complex")}</MenuItem>
                        <MenuItem value={"Building"}>{t("Building")}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                    <Select
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        id="demo-simple-select-outlined"
                        label={t("Select Complex")}
                        labelId="demo-simple-select-outlined-label"
                        IconComponent={ExpandMoreIcon}
                        className="leaseIssueLeaseSelect"
                        displayEmpty
                        value={this.state.selectComplex}
                    >
                    <MenuItem value={""}><em>{t("Select Complex")}</em></MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                    <Select
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        id="demo-simple-select-outlined"
                        labelId="demo-simple-select-outlined-label"
                        IconComponent={ExpandMoreIcon}
                        label={t("Select Building")}
                        className="leaseIssueLeaseSelect"
                        displayEmpty
                        value={this.state.building}
                    >
                    <MenuItem value={""}><em>{t("Select Building")}</em></MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth>
                    <Select
                        MenuProps={{
                            style: {
                                marginTop: "50px",
                            },
                        }}
                        id="demo-simple-select-outlined"
                        label={t("Select Unit")}
                        labelId="demo-simple-select-outlined-label"
                        IconComponent={ExpandMoreIcon}
                        className="leaseIssueLeaseSelect"
                        displayEmpty
                        value={this.state.unit}
                    >
                    <MenuItem value={""}><em>{t("Select Unit")}</em></MenuItem>
                    </Select>
                </FormControl>
            </Paper>
        );
    };

    renderTenantDetails = () => {
        const { t }: { t: TFunction } = this.props;
        return <Box className="tenantDetailsContainer">
            <Box className="tenantheadingContainer">
                <Typography className="tenantHeading">{t("Tenant Details")}</Typography>
                <Box>
                    <Typography className="addsubHeading" onClick={this.handleOpenFindPersonModal} data-testId={"addAnotherTenantTestId"}>+ {t("ADD ANOTHER TENANT")}</Typography>
                </Box>
            </Box>
            <Divider />
            <Box className="tanentProfileContainer">
                <Box className="tanetprofileNameContainer">
                    <img src={TanentProfileIcon} className="tanentProfileIcon" />
                    <Box>
                        <Typography className="tanentNameLabel">{t("Tenant Name")}</Typography>
                        <Typography className="tanentName">Mr. Ali khan</Typography>

                    </Box>
                </Box>
                <Box>
                    <Button className="issueNewContract"
                    >
                        {t("Issue a New Contract")}
                    </Button>
                </Box>
            </Box>
        </Box>
    }

    renderNoIssuedContracts = () => {
        const { t }: { t: TFunction } = this.props;

        return <Box className="noIssuesContractsContainer">
            <Typography className="noIssuesContractsMessage">{t("Tanent registered. No issued contracts")}</Typography>
        </Box>
    }

    renderActiveContracts = () => {
        const { t }: { t: TFunction } = this.props;

        return <Box className="activeContractsContainer">
            <Box className="activeContractHeadingContainer">
                <Typography className="activeContractHeading">{t("Active Contract")}</Typography>
                <Box>
                    <Button className="viewDetailsBtn">View Details</Button>
                </Box>
            </Box>
            <Divider />
            <Box className="activeContractListContainer">
                <Box className="activeContractListItem">
                    <Typography className="activeContractListItemLabel">{t("Expired On")}: </Typography>
                    <Typography className="activeContractListItemTitle">01-04-2023</Typography>
                </Box>
                <Divider />
                <Box className="activeContractListItem">
                    <Typography className="activeContractListItemLabel">{t("Complex")}: </Typography>
                    <Typography className="activeContractListItemTitle">Complex</Typography>
                </Box>
                <Divider />
                <Box className="activeBuildingContainer">
                    <Box className="activeContractListItem">
                        <Typography className="activeContractListItemLabel">{t("Building")}: </Typography>
                        <Typography className="activeContractListItemTitle">Building 1</Typography>
                    </Box>
                    <Box className="activelocationContainer">
                        <img src={LocationIcon} alt="" />
                        <Typography className="buildingOnMapText">{t("See building on map")}</Typography>
                    </Box>
                </Box>
                <Divider />
                <Box className="activeContractListItem">
                    <Typography className="activeContractListItemLabel">{t("Unit")}: </Typography>
                    <Typography className="activeContractListItemTitle">104</Typography>
                </Box>
                <Divider />
                <Box className="activeContractListItem">
                    <Typography className="activeContractListItemLabel">{t("Tenant Name")}: </Typography>
                    <Typography className="activeContractListItemTitle">Mr. Ali Khan</Typography>
                </Box>
                <Divider />
                <Box className="activeContractListItem">
                    <Typography className="activeContractListItemLabel">{t("Owner Name")}: </Typography>
                    <Typography className="activeContractListItemTitle">Jasun Astun</Typography>
                </Box>
                <Divider />
                <Box className="activeContractListItem">
                    <Typography className="activeContractListItemLabel">{t("Contract Type")}: </Typography>
                    <Typography className="activeContractListItemTitle">Rent Contract</Typography>
                </Box>
                <Divider />

            </Box>
            <Box className="activeContractFooterContainer">
                <Typography className="activeContractFooterText">
                    {t("Contract is already assigned to")} <span className="activeContractFooterBoldText">Mr. Ali Khan</span> {t("for")} <span className="activeContractFooterBoldText">Building 1</span>, <span className="activeContractFooterBoldText">Unit 1</span>. {t("You will have to end or terminate contract in order to issue a new contract.")}
                </Typography>
            </Box>
        </Box>
    }

    renderSnackbar = () => {
        return (
            <>
                {this.state.hasSuccessError.isOpen && (<>
                    <Snackbar
                        data-testId="snackBarCloseTestId"
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        open={this.state.hasSuccessError.isOpen}
                        onClose={this.handleCloseSnack}
                        autoHideDuration={3000}
                    >
                        <Alert onClose={this.handleCloseSnack} severity={this.state.hasSuccessError.isSeverity}>
                            {this.state.hasSuccessError.isMessage}
                        </Alert>
                    </Snackbar>
                </>
                )}
            </>
        );
    };

    renderFindPersonModal = () => {
        const { anchorEl, selectedId } = this.state;
        const open = Boolean(anchorEl);
        const id = open ? "simple-popover" : undefined;
        const { t }: { t: TFunction } = this.props;
        return <Modal open={this.state.findPersonModal}>
            <IssueNewLeaseModalContainer>
                <Box className="issueNewModalContainer">
                    <Box className="issueNewLeaseModalHeader">
                        <Typography className="issueNewLeaseModalHeading" data-testId={"findPersonModalTestId"}>{t("Find Person")}</Typography>
                        <Typography className="issueNewLeaseModalHeading">X</Typography>
                    </Box>
                    <Divider />
                    <Box className="issueNewModalContentcontainer">
                        <Typography className="issueNewModalContentheading">{t("Fill one of the more of the fields to find the person, if two fields are populated, any matching person will be shown.")}</Typography>
                        <Box className="issueNewLeasefieldsContiner">
                            <Box className="issueNewLeasefieldsRow">
                                <Box className="issueNewLeasefieldsRowItem">
                                    <InputLabel className="issueNewLeasefieldsLabel">{t("ID Number")}</InputLabel>
                                    <TextField variant="outlined" fullWidth placeholder={t("ID Number")} />
                                </Box>
                                <Box className="issueNewLeasefieldsRowItem">
                                    <InputLabel className="issueNewLeasefieldsLabel">{t("Passport Number")}</InputLabel>
                                    <TextField variant="outlined" fullWidth placeholder="Passport Number" />
                                </Box>
                                <Box className="issueNewLeasefieldsRowItem">
                                    <InputLabel className="issueNewLeasefieldsLabel">{t("Email ID")}</InputLabel>
                                    <TextField variant="outlined" fullWidth placeholder={t("Email ID")} />
                                </Box>
                                <Box className="issueNewLeasefieldsRowItem">
                                    <InputLabel className="issueNewLeasefieldsLabel">{t("Phone Number")}</InputLabel>
                                    <TextField variant="outlined" fullWidth placeholder={t("Phone Number")} />
                                </Box>
                                <Box className="searchbtnContainer">
                                    <Button
                                        variant="contained"
                                        startIcon={<Search />}
                                        className="modalSearchBtn"
                                        fullWidth
                                    >
                                        {t("Search")}
                                    </Button>
                                </Box>
                            </Box>
                            <Box className="tableContainer">
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left" className="tableHead">{t("Name<")}</TableCell>
                                                <TableCell align="left" className="tableHead">{t("ID Number")}</TableCell>
                                                <TableCell align="left" className="tableHead">{t("Phone Number")}</TableCell>
                                                <TableCell align="left" className="tableHead">{t("Email")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.selectedMarker.map((item: DataItem, index: number) => {
                                                return <TableRow key={index}><TableCell align="left">{item.name}</TableCell>
                                                    <TableCell align="left">{item.name}</TableCell>
                                                    <TableCell align="left">{item.name}</TableCell>
                                                    <TableCell align="left">{item.name}</TableCell>
                                                    <TableCell align="left">
                                                        <Box className="templateMenuBtnContainer">
                                                            <IconButton
                                                                data-testId={`templateMenubtn${index}`}
                                                                onClick={(event) => this.handleClick(event, item.id)}
                                                            >
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            <Popover
                                                                open={open && selectedId === item.id}
                                                                id={id}
                                                                onClose={this.handleClose}
                                                                anchorEl={anchorEl}
                                                                data-testId={`menuPopoverCloseTestId${index}`}
                                                                transformOrigin={{
                                                                    horizontal: "right",
                                                                    vertical: "top",
                                                                }}
                                                                anchorOrigin={{
                                                                    horizontal: "left",
                                                                    vertical: "bottom",
                                                                }}

                                                            >
                                                                <PopoverContainer>
                                                                    <Typography className="popoverText" data-testId={`isssueNewLease${index}`}  >
                                                                        {t("View")}
                                                                    </Typography>
                                                                </PopoverContainer>
                                                            </Popover>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box className="noRecordContainer">
                                <Typography className="noRecordHeading">{t("No Record Found")}</Typography>
                                <Button className="noRecordBtn" onClick={this.handleAddNewPerson} data-testId={"addnewPersonTestId"}>{t("Add New Person")}</Button>
                            </Box>
                            <Box className="issueNewLeaseModalBtnContainer" >
                                <Button className="issueNewLeaseModalBtn" onClick={this.handleCloseFindPersonModal} data-testId={"cancelBtnTestId"}>{t("Cancel")}</Button>
                            </Box>
                            <Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

            </IssueNewLeaseModalContainer>
        </Modal>
    }

    renderAdddnewPersonModa = () => {
        const { t }: { t: TFunction } = this.props;

        return <Modal open={this.state.addNewPersonModal}>
            <IssueNewLeaseModalContainer>
                <Box className="issueNewModalContainer">
                    <Box className="issueNewLeaseModalHeader">
                        <Typography className="issueNewLeaseModalHeading" data-testId={"addnewPersonModalHeading"} >{t("Find Person")}</Typography>
                        <Typography className="issueNewLeaseModalHeading">X</Typography>
                    </Box>
                    <Divider />
                    <Box className="addNuwPersonModalFormContainer">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel" id="demo-simple-select-outlined-label">{t("Select User Type")}</InputLabel>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        label={t("Recident")}
                                        IconComponent={ExpandMoreIcon}
                                        labelId="demo-simple-select-outlined-label"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <img src={user_icon} alt="" />
                                            </InputAdornment>
                                        }
                                        displayEmpty
                                        value={this.state.findNewPersonUserType}
                                    >
                                        <MenuItem value="">
                                            <em>{t("Select User Type")}</em>
                                        </MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel">{t("Member full name")}</InputLabel>
                                <TextField fullWidth variant="outlined" InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <img src={user_icon} alt="" />
                                        </InputAdornment>

                                }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel">{t("Email Address")}</InputLabel>
                                <TextField fullWidth variant="outlined" InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <img src={email_icon} alt="" />
                                        </InputAdornment>

                                }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel">{t("Phone Number")}</InputLabel>
                                <TextField
                                    select
                                    label={t("Country")}
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <TextField
                                                    select
                                                    variant="standard"

                                                >
                                                    {this.state.countryAndFlagList.map((country: CountryCodeAndFlag, index: number) => (
                                                        <MenuItem key={country.attributes.name} value={country.attributes.emoji_flag + country.attributes.country_code}>
                                                            {country.attributes.emoji_flag} {country.attributes.country_code}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel">{t("Select Country")}</InputLabel>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        IconComponent={ExpandMoreIcon}
                                        label="Country"
                                        displayEmpty
                                        value={this.state.findNewPersonUserType}
                                    >
                                        <MenuItem value={""}>{t("Country")}</MenuItem>
                                        {this.state.countryList.map((country: string, index: number) => (
                                            <MenuItem key={index} value={country}>
                                                {country}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel" >{t("Select City")}</InputLabel>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        label={t("City")}
                                        IconComponent={ExpandMoreIcon}
                                        displayEmpty
                                        value={this.state.findNewPersonUserType}
                                    >
                                        <MenuItem value={""}>{t("City")}</MenuItem>
                                        {this.state.cityList.map((country: string, index: number) => (
                                            <MenuItem key={index} value={country}>
                                                {country}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel" >{t("Select Complex/Indivisual Building")}</InputLabel>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        IconComponent={ExpandMoreIcon}
                                        label={t("Recident")}
                                        displayEmpty
                                        value={this.state.findNewPersonUserType}
                                    >
                                        <MenuItem value={""}>{t("City")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel" >{t("Select Complex")}</InputLabel>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        label={t("Recident")}
                                        id="demo-simple-select-outlined"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <img src={BuildingIcon} alt="" />
                                            </InputAdornment>
                                        }
                                        IconComponent={ExpandMoreIcon}
                                        displayEmpty
                                        value={this.state.findNewPersonUserType}
                                    >
                                        <MenuItem value={""}><em>{t("Select Unit")}</em></MenuItem>
                                        {this.state.complexList.map((complex: SingleComplexDataItem, index: number) => (
                                            <MenuItem key={index} value={complex.attributes.name}>
                                                {complex.attributes.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel" >{t("Select Building")}</InputLabel>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        id="demo-simple-select-outlined"
                                        label={t("Recident")}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <img src={BuildingIcon} alt="" />
                                            </InputAdornment>
                                        }
                                        labelId="demo-simple-select-outlined-label"
                                        IconComponent={ExpandMoreIcon}
                                        displayEmpty
                                        value={this.state.findNewPersonUserType}
                                    >
                                        <MenuItem value={""}><em>{t("Select Unit")}</em></MenuItem>
                                        {this.state.buildingList.map((building: SingleBuilding, index: number) => (
                                            <MenuItem key={index} value={building.attributes.name}>
                                                {building.attributes.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <InputLabel className="issueNewLeasefieldsLabel" >{t("Select Unit")}</InputLabel>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        id="demo-simple-select-outlined"
                                        labelId="demo-simple-select-outlined-label"
                                        label={t("Recident")}
                                        IconComponent={ExpandMoreIcon}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <img src={UnitIcon} alt="" />
                                            </InputAdornment>
                                        }
                                        displayEmpty
                                        value={this.state.findNewPersonUserType}
                                    >
                                        <MenuItem value={""}><em>{t("Select Unit")}</em></MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className="findPersonModalBtnContainer">
                        <Button className="findPersonModalBtn" onClick={this.handleCloseAddNewPersonModal} data-testId={"modalCancelBtnTestId"}>{t("Cancel")}</Button>
                        <Button className="findPersonModalSendBtn">{t("Send Invitation")}</Button>
                    </Box>
                </Box>
            </IssueNewLeaseModalContainer>
        </Modal>
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { t }: { t: TFunction } = this.props;
        return (
            <MainScreen>
                <IssuLeeaseContainer>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="/CompanyEmployee/LeaseManagment" data-testId="issueNewLeaseTestID">
                            {t("Lease Management")}
                        </Link>
                        <Typography className="issueNewLeaseText">{t("Issue a New Lease")}</Typography>
                    </Breadcrumbs>
                    <Typography className="issueNewLeaseHeading">
                        {t("Issue a New Lease")}
                    </Typography>
                    {this.renderIssueLeaseForm()}
                    {this.renderTenantDetails()}
                    {this.renderSnackbar()}
                    {this.renderNoIssuedContracts()}
                    {this.renderActiveContracts()}
                    {this.renderFindPersonModal()}
                    {this.renderAdddnewPersonModa()}
                </IssuLeeaseContainer>
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(LeaseManagementIssueNewLease);
export { LeaseManagementIssueNewLease };

const IssuLeeaseContainer = styled(Box)({
    padding: "50px",
    backgroundColor: "#f4f7ff",
    "& .issueNewLeaseHeading": {
        fontSize: "35px",
        fontWeight: 800,
    },
    "& .issueLeaseFormPaper": {
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        borderRadius: "8px",
        padding: "20px",
        marginTop: "40px",
    },
    "& .leaseIssueLeaseSelect": {
        "& .MuiSelect-outlined.MuiSelect-outlined": {
            backgroundColor: "#f9f9f9 !important",
            border: "1px solid #f2f1f1 !important",
            borderRadius: "8px !important",
        },
    },
    "& .tenantDetailsContainer": {
        marginTop: "20px",
        borderRadius: "20px",
        backgroundColor: "#ffffff",
        padding: "20px",
        display: "flex",
        justifyContent: "space-between",
        gap: "10px",
        flexDirection: "column"

    },
    "& .tanentProfileIcon": {
        marginTop: "5px"
    },
    "& .tenantHeading": {
        fontWeight: 700,
        fontSize: "20px",
        color: "#2e2f35"
    },
    "& .addsubHeading": {
        fontWeight: 900,
        color: "#da8d6d",
        fontSize: "16px",
        cursor:"pointer"
    },
    "& .tenantheadingContainer": {
        display: "flex",
        justifyContent: "space-between",

    },
    "& .tanentProfileContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "20px"
    },
    "& .tanetprofileNameContainer": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "10px"
    },
    "& .issueNewContract": {
        color: "#fff !important",
        fontWeight: 800,
        backgroundColor: "#f88538",
        height: "50px",
        padding: "0px 20px",
        borderRadius: "8px",
        width: "220px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "uppercase"
    },
    "& .renewContractBtn": {
        color: "#3170da !important",
        fontWeight: 800,
        backgroundColor: "#fff",
        height: "50px",
        padding: "0px 20px",
        borderRadius: "8px",
        width: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #3170da",
        textTransform: "uppercase"
    },
    "& .tanentNameLabel": {
        color: "#b6b6b6",
        fontSize: "16px"
    },
    "& .tanentName": {
        color: "#1c1d20",
        fontSize: "18px",
        fontWeight: 900
    },
    "& .noIssuesContractsContainer": {
        height: "200px",
        borderRadius: "20px",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        marginTop: "20px"
    },
    "& .noIssuesContracts": {
        color: "#a7a7a7",
        fontSize: "16px",
    },
    "& .activeContractsContainer": {
        padding: "20px",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor: "#fff",
        marginTop: "20px",
        flexDirection: "column"

    },
    "& .activeContractHeadingContainer": {
        display: 'flex',
        justifyContent: "space-between",
        marginBottom: "20px",
        borderRadius: "20px",
        width: "100%",
        alignItems: "center"

    },
    "& .viewDetailsBtn": {
        width: "250px",
        color: "#fff",
        borderRadius: "8px",
        backgroundColor: "#2b6fec",
        padding: "10px 0px",
        fontSize: "17px",
        textTransform: "capitalize"
    },
    "& .activeContractHeading": {
        fontSize: "32px",
        fontWeight: 900,
        color: "#1b1c21"
    },
    "& .activeBuildingContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .activelocationContainer": {
        display: "flex",
        gap: "20px",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    "& .activeContractListContainer": {
        width: "100%"
    },
    "& .activeContractListItem": {
        display: "flex",
        gap: "10px",
        justifyContent: "flex-start",
        margin: "20px 0px"
    },
    "& .activeContractListItemLabel": {
        color: "#272829",
        fontSize: "21px",
        fontWeight: 500
    },
    "& .activeContractListItemTitle": {
        color: "#484b4c",
        fontWeight: 900,
        fontSize: "21px"
    },
    "& .buildingOnMapText": {
        color: "#eb9456",
        fontSize: "19px",
    },
    "& .activeContractFooterContainer": {
        marginTop: "20px"
    },
    "& .activeContractFooterText": {
        fontSize: "18px",
        color: "#a7a7a8"
    },
    "& .activeContractFooterBoldText": {
        fontSize: "18px",
        color: "#8f8f91",
        fontWeight: 900
    },
    "& .issueNewLeaseText": {
        color: "#537ad2"
    }

});

const IssueNewLeaseModalContainer = styled(Box)({
    width: "100%",
    height: "100vh",
    overflow: "scroll",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .issueNewModalContainer": {
        backgroundColor: "#fff",
        borderRadius: "10px",
        width: "70%"
    },
    "& .issueNewLeaseModalHeader": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px"
    },
    "& .issueNewLeaseModalHeading": {
        color: "#1b1f27",
        fontSize: "20px",
        fontWeight: 900,
        cursor: "pointer"
    },
    "& .issueNewModalContentcontainer": {
        padding: "20px",
    },
    "& .issueNewModalContentheading": {
        color: "#949494",
        fontSize: "17px",
        marginBottom: "20px"
    },
    "& .issueNewLeasefieldsContiner": {
        padding: "10px 0px"
    },
    "& .issueNewLeasefieldsLabel": {
        color: "#474747",
        fontSize: "16px",
        fontWeight: 800,
        marginBottom: "8px"
    },
    "& .MuiTextField-root": {
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
        border: "1px solid #efefef"
    },
    "& .MuiFormControl-fullWidth": {
        backgroundColor: "#f9f9f9",
        borderRadius: "10px",
        border: "1px solid #efefef"
    },
    "& .issueNewLeaseModalBtnContainer": {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "10px 0px",
        width: "100%"
    },
    "& .issueNewLeaseModalBtn": {
        width: "180px",
        border: "1px solid #3e6de6",
        color: "#3e6de6",
        background: "#fff",
        height: "50px"
    },
    "& .searchbtnContainer": {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        height: "80px",
    },
    "& .modalSearchBtn": {
        backgroundColor: "#3e6de6",
        color: "#fff",
        height: "50px",
        fontSize: "17px",
        width: "150px"
    },
    "& .issueNewLeasefieldsRow": {
        display: "flex",
        gap: "20px"
    },
    "& .tableEmailRowcontainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    "& .tableContainer": {
        margin: "10px 0px"
    },
    "& .noRecordContainer": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "200px",
        gap: "20px",
        flexDirection: "column"

    },
    "& .noRecordHeading": {
        color: "#474649",
        fontSize: "16px",
        textAlign: "center",
    },
    "& .noRecordBtn": {
        color: "#fff",
        backgroundColor: "#fe8435",
        padding: "10px 20px",
        fontWeight: 900
    },
    "& .addNuwPersonModalFormContainer": {
        margin: "10px 0px",
        padding: "20px"
    },
    "& .findPersonModalBtnContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px"
    },
    "& .findPersonModalBtn": {
        width: "180px",
        border: "1px solid #3e6de6",
        color: "#3e6de6",
        background: "#fff",
        height: "50px",
        fontWeight: 900,
        borderRadius: "10px"
    },
    "& .findPersonModalSendBtn": {
        width: "180px",
        color: "#fff",
        background: "#2c6df3",
        height: "50px",
        fontWeight: 900,
        borderRadius: "10px"
    },
    "& .tableHead": {
        borderBottom: "none",
        color: "#272a2b",
        fontWeight: 800,
    }
})

const PopoverContainer = styled(Box)({
    width: "150px",
    "& .popoverText": {
        padding: "10px 20px ",
    },
});
// Customizable Area End