//Customizable Area Start
import React from "react";
import ComplexMainBlockController, {
    Props,
} from "./ComplexMainBlockController.web";
import '../../../dashboard/src/Company Employee/global.css';
import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";
import CompEmpComplexBlock from "./CompEmpComplexBlock.web"
import {withRouter} from 'react-router-dom'

class ComplexMainBlock extends ComplexMainBlockController {
    constructor(props: Props) {
        super(props);
    }
    render() {

        const { navigation } = this.props;
        const match:any= this.props.match;
        const id = match?.params?.id;

        if (localStorage.loginSuccessToken) {
            return (
                <>
                    <MainScreen>
                        <CompEmpComplexBlock navigation={navigation} id={"complexBlock"} location={this.props.location} history={this.props.history} companyId={id} />
                    </MainScreen>
                </>
            );
        }
        else {
            window.location.href = '/CompanyEmployee/Login'
            return null
        }

    }
}

const webStyle = {
};

//@ts-ignore
export default withRouter(ComplexMainBlock)
//Customizable Area End


