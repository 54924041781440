import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { ApiCatchErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { userProfile } from "../assets";
import {
    Box,
    Button,
    Grid,
} from "@material-ui/core";


export interface Props {
    jurisdictionList: any,
    jurisdictionSelected: any,
    handleChangeComplex: any,
    stepComplex: number,
    handleNext:any,
    handleBack:any,
    t?: any
    i18n?: any
    match?: any
    location?: any
    // Customizable Area Start
    history?: any
    language?: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CompEmpComplexBlockStep1Controller extends CommonApiCallForBlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
        this.state = {
        };
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
}
