import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { CEApiCall } from "../../../../components/src/APICallComponent/index.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { handleFilterMainComplexList } from "../../../../components/src/UtilFunction.web";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isListVisible: boolean;

  managerId: string;
  name: string;

  pmUnitList: any[];
  pagination: any;
  page: number;

  search: string;
  sortBy: string;

  complexList: any[];
  filterComplexList: any[];
  buildingList: any[];
  accountManagerList: any[];

  filter: {
    isComplex: number;
    complex: string;
    building: string;
    accountManager: string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CEPropertyManagerReportDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  GetManagerDetailsListCallId: any;
  GetComplexListCallId: any;
  GetBuildingListCallId: any;
  GetAccountManagerListCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,
      isListVisible: true,

      managerId: "",
      name: "",

      pmUnitList: [],
      pagination: null,
      page: 1,

      search: "",
      sortBy: "",

      complexList: [],
      filterComplexList: [],
      buildingList: [],
      accountManagerList: [],

      filter: {
        isComplex: 1,
        complex: "",
        building: "",
        accountManager: "",
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      switch (apiRequestCallId) {
        case this.GetManagerDetailsListCallId:
          return this.handleManagerDetailsResponse(responseJson);
        case this.GetComplexListCallId:
          return this.handleComplexListResponse(responseJson);
        case this.GetBuildingListCallId:
          return this.handleUnitBuildingListResponse(responseJson);
        case this.GetAccountManagerListCallId:
          return this.handleAmListResponse(responseJson);
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    const user_id = this.props.navigation.getParam("id");
    this.setState({ managerId: user_id }, () => {
      this.getManagerDetailsById();

      this.getComplexList();
      this.getAmList();
    });
  }

  getManagerDetailsById = () => {
    this.setState({ loading: true }, async () => {
      const { managerId, page, search } = this.state;
      const { building, complex, accountManager } = this.state.filter;
      this.GetManagerDetailsListCallId = await CEApiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: `bx_block_report/property_manager_report/property_report_details?manager_id=${managerId}?search=${search}&account_manager=${this.handleAllEmptyFilter(
          accountManager
        )}&page=${page}&per_page=10&building=${this.handleAllEmptyFilter(building)}&complex=${this.handleAllEmptyFilter(complex)}&sort_by=${this.state.sortBy}`,
      });
    });
  };

  handleManagerDetailsResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.setState({
          pmUnitList: responseJson.data,
          pagination: responseJson.meta.pagination,
          name: responseJson.meta.managed_by,
        });
      }
    });
  };

  getComplexList = async () => {
    this.GetComplexListCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_dashboard/assign_action_team/complex_list`,
    });
  };

  handleComplexListResponse = (responseJson: any) => {
    if (responseJson && responseJson.complexes) {
      this.setState({ complexList: responseJson.complexes }, () => {
        this.setState({
          filterComplexList: handleFilterMainComplexList(1, this.state.complexList),
        });
      });
    }
  };

  getUnitBuildingList = async (complexId: any) => {
    this.GetBuildingListCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_announcement/announcements/building_list?society_management_id=${complexId}`,
    });
  };

  handleUnitBuildingListResponse = (responseJson: any) => {
    if (responseJson && responseJson.buildings) {
      this.setState({
        buildingList: responseJson.buildings.map((item: any) => ({ label: item.name, value: item.id })),
      });
    }
  };

  getAmList = async () => {
    this.GetAccountManagerListCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_report/property_manager_report/account_manager_listing`,
    });
  };

  handleAmListResponse = (responseJson: any) => {
    if (responseJson && responseJson.account_manager_listing) {
      this.setState({
        accountManagerList: responseJson.account_manager_listing.map((item: any) => ({
          label: item.first_name + " " + item.last_name,
          value: item.id,
        })),
      });
    }
  };

  handleUnitFilterChange = (event: any, stateParam: string) => {
    const value = event.target.value;

    if (stateParam === "complex") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            complex: value,
            building: "",
            accountManager: "",
          },
        },
        () => this.getUnitBuildingList(value)
      );
    } else if (stateParam === "building") {
      this.setState({
        filter: {
          ...this.state.filter,
          building: value,
          accountManager: "",
        },
      });
    } else if (stateParam === "accountManager") {
      this.setState({
        filter: {
          ...this.state.filter,
          accountManager: value,
        },
      });
    } else if (stateParam === "isComplex") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            isComplex: value,
          },
        },
        () =>
          this.setState({
            filterComplexList: handleFilterMainComplexList(value, this.state.complexList),
          })
      );
    }
  };

  handleVisibleList = () => {
    this.setState({ isListVisible: true });
  };

  handleVisibleMap = () => {
    this.setState({ isListVisible: false });
  };

  handleAllEmptyFilter = (value: string) => (value === "all" ? "" : value);

  handleUnitPagination = (e: any, value: any) => {
    this.setState({ page: value });
  };
  // Customizable Area End
}
