import React from "react";
// Customizable Area Start
import {
    Box, Typography, Grid, Button, Table, TableContainer, TableHead, TableRow, TableCell, TableBody,
    TextField, InputAdornment
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from 'yup';
import {alphaNumericOnly, numbersOnly, alphabetsOnly} from "../../../../components/src/KeyBlockFunctions";
//@ts-ignore
import CompEmpBuildingBlockStep5Controller from "./CompEmpBuildingBlockStep5Controller.web";
import "../../assets/commonForm.css"
// Customizable Area End

export default class CompEmpBuildingBlockStep5 extends CompEmpBuildingBlockStep5Controller {

    validationSchema = Yup.object().shape({
        UnitNumber: Yup.string().required('Unit Number is required'),
        FloorNumber: Yup.string().required('Floor Numer is required'),
        ResidentName: Yup.string().required('Resident Name is required'),
        Size: Yup.string().required('Size is required'),
    });

    render() {
        // Customizable Area Start

        const {t} = this.props;
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <Box>
                    <Box>
                        <Box style={{justifyContent: 'space-between', display: 'flex', alignItems: 'center'}}>
                            <Box>
                                <Typography className="Units bold-text"
                                            style={{fontSize: '22px', fontWeight: 'bold'}}>{t("Units")}</Typography>
                            </Box>
                            <Box>
                                <Typography className="stepUnits" style={{fontSize: '16px'}}>{t("Step")}{this.props.stepBuilding + 1} {t("of 7")}</Typography>
                            </Box>
                        </Box>
                        <Box style={{
                            width: '100%',
                            display: 'flex',
                            marginTop: '30px',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                        }}>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="# bold-text"
                                                       style={{fontWeight: 'bold'}}>#</TableCell>
                                            <TableCell className="Unit bold-text" style={{fontWeight: 'bold'}}>{t("Unit Number")}</TableCell>
                                            <TableCell className="Floor bold-text" style={{fontWeight: 'bold'}}>{t("Floor Number")}</TableCell>
                                            {this.props.accountType === "coo" && <TableCell className="Floor bold-text" style={{fontWeight: 'bold'}}>{t("Configuration")}</TableCell>}
                                            <TableCell className="Resident bold-text" style={{fontWeight: 'bold'}}>{t("Resident Name")}</TableCell>
                                            <TableCell className="Owner bold-text"
                                                       style={{fontWeight: 'bold'}}>{t("Owner")}</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {this.props.unitRecords.map((index: any, i: number) => (
                                            <TableRow key={i}>
                                                <TableCell className="#">{i + 1}</TableCell>
                                                <TableCell className="Unit">{index.UnitNumber}</TableCell>
                                                <TableCell className="Floor">{index.FloorNumber}</TableCell>
                                                {this.props.accountType === "coo" &&
                                                    <TableCell className="Floor">{index.Configration}</TableCell>
                                                }
                                                <TableCell className="Resident">{index.ResidentName}</TableCell>
                                                <TableCell className="Owner">{index.OwnerName}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box style={{borderRadius: '8px', marginTop: '20px', border: '1px solid #2B6FED',}}>
                                <Box style={{
                                    color: '#2B6FED',
                                    padding: '10px 25px',
                                    cursor: "grabbing",
                                    fontSize: '15px',
                                    fontWeight: 'bold',
                                }} onClick={this.props.togglePopupBuilding} className="bold-text">{t("+ADD NEW UNIT")}</Box>
                            </Box>
                            <Box>
                                <Box style={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    display: this.props.isPopupOpen ? 'block' : 'none',
                                    top: 0,
                                    left: 0,
                                    position: 'fixed',
                                    width: '100%',
                                    zIndex: 9999,
                                    height: '100%',
                                }}>
                                    <Box style={{
                                        top: '50%',
                                        width: '50%',
                                        position: 'absolute',
                                        left: '50%',
                                        borderRadius: '5px',
                                        height: '90%',
                                        backgroundColor: '#fff',
                                        overflow: 'auto',
                                        transform: 'translate(-50%, -50%)',
                                    }}>
                                        <Box>
                                            <Box className="topBarName NewUnit">
                                                <Box style={{fontSize: '22px', fontWeight: 'bold'}}><span
                                                    className="bold-text">{t("Add New Unit")}</span></Box>
                                                <Box onClick={this.props.togglePopupBuilding}
                                                     style={{cursor: "grabbing"}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                         viewBox="0 0 14 14" fill="none">
                                                        <path
                                                            d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z"
                                                            fill="black"/>
                                                    </svg>
                                                </Box>
                                            </Box>
                                            <Box style={{borderBottom: '1px solid #e7e7e7',}}></Box>
                                            <Formik
                                                initialValues={this.props.unitValues}
                                                validationSchema={this.validationSchema}
                                                validateOnMount={true}
                                                onSubmit={(values, actions) => {

                                                    this.props.handleUnitDetails(values)
                                                    actions.resetForm();
                                                }}
                                            >
                                                {({values, touched, errors, isValid, setFieldValue, handleChange}) => (
                                                    <Form translate="yes" className="commonForms">
                                                        <Box style={{padding: '25px',}}>
                                                            <Box>
                                                                <Box style={{
                                                                    display: 'flex',
                                                                    width: '100%',
                                                                    justifyContent: 'space-between'
                                                                }}>
                                                                    <Box style={{marginRight: '25px', width: '50%'}}>
                                                                        <Typography className="typographyLable Unit">{t("Unit Number")}</Typography>
                                                                        <Box className="formGroups" style={{
                                                                            marginBottom: "0px",
                                                                            border: "1px solid #F0F0F0",
                                                                            borderRadius: "8px",
                                                                            backgroundColor: "#F0F0F0"
                                                                        }}>
                                                                            <Field
                                                                                onKeyDown={alphaNumericOnly}
                                                                                name="UnitNumber" type="text"
                                                                                placeholder={t("Unit Number")}
                                                                                className="formInputs"/>
                                                                        </Box>
                                                                        <Box style={webStyle.errorMsg}>
                                                                            <ErrorMessage name="UnitNumber"
                                                                                          component="div"
                                                                                          data-testid="UnitNumber-error"/></Box>
                                                                    </Box>
                                                                    <Box style={{width: '50%'}}>
                                                                        <Typography className="typographyLable Floor">{t("Floor Number")}</Typography>
                                                                        <Box className="formGroups" style={{
                                                                            border: "1px solid #F0F0F0",
                                                                            marginBottom: "0px",
                                                                            borderRadius: "8px",
                                                                            backgroundColor: "#F0F0F0"
                                                                        }}>
                                                                            <Field
                                                                                onKeyDown={numbersOnly}
                                                                                name="FloorNumber" type="text"
                                                                                placeholder={t("Floor Number")}
                                                                                className="formInputs"/>
                                                                        </Box>
                                                                        <Box style={webStyle.errorMsg}>
                                                                            <ErrorMessage name="FloorNumber"
                                                                                          component="div"
                                                                                          data-testid="FloorNumber-error"/></Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <Box style={{marginTop: '20px'}}>
                                                                <Box style={{
                                                                    width: '100%',
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between'
                                                                }}>
                                                                    <Box style={{width: '50%', marginRight: '25px'}}>
                                                                        <Typography className="typographyLable Owner">{t("Owner Name")}</Typography>

                                                                        <Field name="ownerName" type="text"
                                                                               placeholder="Configuration (BHK)"
                                                                               className="formInputs "/>
                                                                    </Box>
                                                                    <Box style={{width: '50%',}}>
                                                                        <Typography
                                                                            className="typographyLable Resident">{t("Resident Name (Optional)")}</Typography>

                                                                        <Field name="ResidentName" type="text"
                                                                               placeholder="Configuration (BHK)"
                                                                               className="formInputs "/>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <Box style={{marginTop: '20px',}}>
                                                                <Box style={{
                                                                    justifyContent: 'space-between',
                                                                    display: 'flex',
                                                                    width: '100%',
                                                                }}>
                                                                    {
                                                                        this.props.accountType !== "account_manager" ?
                                                                            <Box style={{
                                                                                marginRight: '25px',
                                                                                width: '50%',
                                                                            }}>
                                                                                <Typography
                                                                                    className="typographyLable Configuration">{t("Configuration")}</Typography>
                                                                                <Box
                                                                                    className="formGroups Configuration"
                                                                                    style={{
                                                                                        marginBottom: "0px",
                                                                                        borderRadius: "8px",
                                                                                        backgroundColor: "#F0F0F0",
                                                                                        border: "1px solid #F0F0F0",
                                                                                    }}>
                                                                                    <Field name="Configuration"
                                                                                           type="text"
                                                                                           placeholder={t("Configuration (BHK)")}
                                                                                           className="formInputs "/>
                                                                                </Box>
                                                                                <Box style={webStyle.errorMsg}>
                                                                                    <ErrorMessage name="Configuration"
                                                                                                  component="div"
                                                                                                  data-testid="Configuration-error"/></Box>
                                                                            </Box> : <></>
                                                                    }

                                                                    <Box style={{width: '50%'}}>
                                                                        <Typography
                                                                            className="typographyLable Size">{t("Size")}</Typography>
                                                                        <Box className="formGroups" style={{
                                                                            marginBottom: "0px",
                                                                            borderRadius: "8px",
                                                                            backgroundColor: "#F0F0F0",
                                                                            border: "1px solid #F0F0F0",
                                                                        }}>
                                                                            <Field
                                                                                name="Size"
                                                                                type="number"
                                                                                placeholder={t("Size")}
                                                                                className="formInputs"
                                                                                validate={(value: any) => {
                                                                                    // Add any validation logic here if needed
                                                                                }}
                                                                            >
                                                                                {({field, form}: {
                                                                                    field: any,
                                                                                    form: any
                                                                                }) => (
                                                                                    <TextField
                                                                                        {...field}
                                                                                        name="Size"
                                                                                        type="text"
                                                                                        placeholder="Size"
                                                                                        className="formInputs"
                                                                                        InputProps={{
                                                                                            endAdornment:
                                                                                                <InputAdornment
                                                                                                    position="end">sqft</InputAdornment>
                                                                                        }}
                                                                                        inputProps={{
                                                                                            maxLength: 20
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </Field>

                                                                        </Box>
                                                                        <Box style={webStyle.errorMsg}>
                                                                            <ErrorMessage name="Size" component="div"
                                                                                          data-testid="Size-error"/></Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <Box style={{marginTop: '20px',}}>
                                                                <Box style={{
                                                                    justifyContent: 'space-between',
                                                                    display: 'flex',
                                                                    width: '100%',
                                                                }}>
                                                                    <Box style={{width: '50%', marginRight: '25px'}}>
                                                                        <Typography
                                                                            className="typographyLable Purchase">{t("Purchase Price (Optional)")}</Typography>
                                                                        <Box className="formGroups" style={{
                                                                            borderRadius: "8px",
                                                                            marginBottom: "0px",
                                                                            border: "1px solid #F0F0F0",
                                                                            backgroundColor: "#F0F0F0"
                                                                        }}>
                                                                            <Field type="text"
                                                                                   onKeyDown={numbersOnly}
                                                                                   name="PurchasePrice"
                                                                                   placeholder="Purchase Price"
                                                                                   className="formInputs"/>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box style={{width: '50%',}}>
                                                                        <Typography
                                                                            className="typographyLable Purchase">{t("Purchase Date (Optional)")}</Typography>
                                                                        <Box className="formGroups" style={{
                                                                            border: "1px solid #F0F0F0",
                                                                            borderRadius: "8px",
                                                                            backgroundColor: "#F0F0F0",
                                                                            marginBottom: "0px",
                                                                        }}>
                                                                            <Field name="PurchaseDate"
                                                                                   placeholder="Purchase Date"
                                                                                   className="formInputs" type="date"/>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <Box style={{marginTop: '20px',}}>
                                                                <Box style={{width: '100%',}}>
                                                                    <Box>
                                                                        <Typography className="typographyLable">{t("Current Valuation (Optional)")}</Typography>
                                                                        <Box className="formGroups" style={{
                                                                            marginBottom: "0px",
                                                                            borderRadius: "8px",
                                                                            border: "1px solid #F0F0F0",
                                                                            backgroundColor: "#F0F0F0"
                                                                        }}>
                                                                            <Field
                                                                                onKeyDown={alphaNumericOnly}
                                                                                placeholder="Current Valuation"
                                                                                name="CurrentValuation" type="text"
                                                                                className="formInputs"/>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <Box style={{
                                                                marginTop: '15px',
                                                                display: 'flex',
                                                                justifyContent: 'end'
                                                            }}>
                                                                <Grid style={{marginRight: '20px', marginTop: "30px",}}>
                                                                    <Button onClick={this.props.togglePopupBuilding}
                                                                            className="buttonCancel"><span
                                                                        className="bold-text">{t("CANCEL")}</span></Button>
                                                                </Grid>
                                                                <Grid style={{marginTop: "30px"}}>
                                                                    <Button
                                                                        type="submit"
                                                                        className="buttonSave"><span
                                                                        className="bold-text">{t("SAVE")}</span></Button>
                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    </Form>)}
                                            </Formik>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box>
                                <Box style={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    display: this.props.ownerPopup ? 'block' : 'none',
                                    top: 0,
                                    left: 0,
                                    position: 'fixed',
                                    width: '100%',
                                    zIndex: 9999,
                                    height: '100%',
                                }}>
                                    <Box style={{
                                        top: '50%',
                                        width: '70%',
                                        position: 'absolute',
                                        left: '50%',
                                        borderRadius: '5px',
                                        backgroundColor: '#fff',
                                        overflow: 'auto',
                                        transform: 'translate(-50%, -50%)',
                                    }}>
                                        <Box>
                                            <Box className="topBarName NewUnit">
                                                <Box style={{fontSize: '22px', fontWeight: 'bold'}}><span
                                                    className="bold-text">Find Person</span></Box>
                                                <Box onClick={this.props.handleOwnerPopup} style={{cursor: "grabbing"}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                                         viewBox="0 0 14 14" fill="none">
                                                        <path
                                                            d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z"
                                                            fill="black"/>
                                                    </svg>
                                                </Box>
                                            </Box>
                                            <Box style={{borderBottom: '1px solid #e7e7e7',}}></Box>
                                            <Formik
                                                initialValues={this.props.ownerValues}
                                                validateOnMount={true}
                                                onSubmit={(values, actions) => {
                                                    this.props.handleUnitDetails(values)
                                                    actions.resetForm();
                                                }}
                                            >
                                                {({values, touched, errors, isValid, setFieldValue, handleChange}) => (
                                                    <Form translate="yes" className="commonForms">
                                                        <Box style={{padding: '25px',}}>
                                                            <Typography style={{
                                                                color: '#8c8e92',
                                                                fontSize: '16px',
                                                                marginBottom: '15px'
                                                            }}>
                                                                Fill one of more of the fields to find the person, if
                                                                two fields are populated, any matching person will be
                                                                shown
                                                            </Typography>
                                                            <Box>
                                                                <Box style={{
                                                                    display: 'flex',
                                                                    width: '100%',
                                                                    justifyContent: 'space-between',
                                                                    alignItems: 'flex-end'
                                                                }}>
                                                                    <Box style={{width: '20%'}}>
                                                                        <Typography className="Unit" style={{
                                                                            fontSize: '14px',
                                                                            color: '#212121',
                                                                            marginTop: '11px',
                                                                            marginBottom: '10px'
                                                                        }}>ID Number</Typography>
                                                                        <Box className="formGroups" style={{
                                                                            marginBottom: "0px",
                                                                            border: "1px solid #F0F0F0",
                                                                            borderRadius: "8px",
                                                                            backgroundColor: "#F0F0F0"
                                                                        }}>
                                                                            <Field
                                                                                onKeyDown={alphaNumericOnly}
                                                                                name="id" type="text"
                                                                                placeholder="ID Number"
                                                                                className="formInputs"/>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box style={{width: '20%'}}>
                                                                        <Typography className=" Floor" style={{
                                                                            fontSize: '14px',
                                                                            color: '#212121',
                                                                            marginTop: '11px',
                                                                            marginBottom: '10px'
                                                                        }}>Passport Number</Typography>
                                                                        <Box className="formGroups" style={{
                                                                            border: "1px solid #F0F0F0",
                                                                            marginBottom: "0px",
                                                                            borderRadius: "8px",
                                                                            backgroundColor: "#F0F0F0"
                                                                        }}>
                                                                            <Field
                                                                                onKeyDown={numbersOnly}
                                                                                name="passport" type="text"
                                                                                placeholder="Passport Number"
                                                                                className="formInputs"/>
                                                                        </Box>
                                                                    </Box>

                                                                    <Box style={{width: '20%'}}>
                                                                        <Typography className=" Floor" style={{
                                                                            fontSize: '14px',
                                                                            color: '#212121',
                                                                            marginTop: '11px',
                                                                            marginBottom: '10px'
                                                                        }}>Email ID</Typography>
                                                                        <Box className="formGroups" style={{
                                                                            border: "1px solid #F0F0F0",
                                                                            marginBottom: "0px",
                                                                            borderRadius: "8px",
                                                                            backgroundColor: "#F0F0F0"
                                                                        }}>
                                                                            <Field
                                                                                onKeyDown={numbersOnly}
                                                                                name="email" type="text"
                                                                                placeholder="Email ID"
                                                                                className="formInputs"/>
                                                                        </Box>
                                                                    </Box>

                                                                    <Box style={{width: '20%'}}>
                                                                        <Typography className="Floor" style={{
                                                                            fontSize: '14px',
                                                                            color: '#212121',
                                                                            marginTop: '11px',
                                                                            marginBottom: '10px'
                                                                        }}>Phone Number</Typography>
                                                                        <Box className="formGroups" style={{
                                                                            border: "1px solid #F0F0F0",
                                                                            marginBottom: "0px",
                                                                            borderRadius: "8px",
                                                                            backgroundColor: "#F0F0F0"
                                                                        }}>
                                                                            <Field
                                                                                onKeyDown={numbersOnly}
                                                                                name="phone" type="text"
                                                                                placeholder="Phone Number"
                                                                                className="formInputs"/>
                                                                        </Box>
                                                                    </Box>
                                                                    <Button
                                                                        className="handleSearchFilterForInvoices search-btn-estate"
                                                                        style={{
                                                                            marginLeft: "10px", background: '#2B6FED',
                                                                            color: 'white',
                                                                            height: '60px',
                                                                            padding: '10px 20px',
                                                                            borderRadius: '8px',
                                                                            //@ts-ignore
                                                                            fontWeight: '600'
                                                                        }} startIcon={<SearchIcon/>}>Search</Button>
                                                                </Box>
                                                            </Box>
                                                            <Box style={{
                                                                marginTop: '15px',
                                                                display: 'flex',
                                                                justifyContent: 'end'
                                                            }}>
                                                                <Grid style={{marginTop: "30px",}}>
                                                                    <Button onClick={this.props.handleOwnerPopup}
                                                                            className="buttonCancel"><span
                                                                        className="bold-text">CANCEL</span></Button>
                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    </Form>)}
                                            </Formik>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                </Box>
                {this.props.multipleButton()}
            </>

            // Customizable Area End
        );
    }
}

// export default withTranslation()(withRouter(CompEmpComplexBuildingBlock));
// Customizable Area Start
const webStyle = {
    errorMsg: {
        color: 'red',
        fontSize: '12px',
        marginTop: '5px'
    },
}
// Customizable Area End