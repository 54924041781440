// Customizable Area Start
import React from "react";
import { Box, InputLabel, MenuItem, OutlinedInput, Select } from "@material-ui/core";
import { languageCondition } from "../ConditionManager.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const NormalSelect = ({ t, language, label, icon, option, value, ...props }: any) => {
  return (
    <Box className={`select_icon__form_box ${icon ? "" : "select__form_box"}`}>
      {label && <InputLabel>{t(label)}</InputLabel>}
      <Box className="select__select_box">
        {icon && <img src={icon} alt="icon" />}
        <Select
          displayEmpty
          fullWidth
          variant="filled"
          input={<OutlinedInput />}
          className="select__input"
          value={value === undefined ? "" : value}
          style={value !== "" && value !== undefined ? { color: "black" } : { color: "#adadad" }}
          IconComponent={() => <ExpandMoreIcon className="select__select_icon" />}
          {...props}
        >
          <MenuItem value="" className={languageCondition(language, "select-arabic-menu", "")}>
            {t("Select " + label)}
          </MenuItem>
          {option &&
            option.map((item: any) => {
              return (
                <MenuItem className={languageCondition(language, "select-arabic-menu", "")} value={item.value}>
                  {t(item.label)}
                </MenuItem>
              );
            })}
        </Select>
      </Box>
    </Box>
  );
};

export default NormalSelect;
// Customizable Area End
