import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

export interface S {
    // Customizable Area Start
    individualbuilding: string
    selectCountry: string[]
    selectedCountry: string
    selectCity: string[]
    selectComplex: string[]
    selectBuilding: string[]
    selectUnit: string[]
    selectedUnit: string
    selectUserType: string
    token: string;
    selectCompany: string[]
    selectedCompany: string
    selectedCity: string
    selectedComplex: string
    selectedBuilding: string
    login: boolean
    cardDetails: {
        login_report:number | null
        owners_reports:number | null
        lease_reports:number | null
        invitation_reports:number | null
        property_manager_reports:number | null
        subscription_reports:number | null
    }
    title: string;
    topMenu: string[]
    
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ReportsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    cardApiId: string = ""
    getCountryApiId: string = ""
    getCityApiId: string = ""
    getComplexApiId: string = ""
    getBuildingApiId: string = ""

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            individualbuilding: "",
            selectCountry: [],
            selectedCountry: "",
            selectCity: [],
            selectComplex: [],
            selectBuilding: [],
            selectUnit: [],
            selectUserType: "",
            token: "",
            selectCompany: [],
            selectedCompany: "",
            selectedCity: "",
            selectedComplex: "",
            selectedBuilding: "",
            selectedUnit: "",
            login:false,
            cardDetails: {
                login_report:null,
                owners_reports:null,
                lease_reports:null,
                invitation_reports:null,
                property_manager_reports:null,
                subscription_reports:null,
            },
            title: "",
            topMenu: ["Documents & Reports", "Reports"],
        };
        // Customizable Area End


    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId === this.cardApiId) {
                this.setState({ cardDetails: responseJson.data })
            } else if (apiRequestCallId === this.getCountryApiId) {
                this.setState({ selectCountry: responseJson.data.countries })
            } else if (apiRequestCallId === this.getCityApiId) {
                this.setState({ selectCity: responseJson.data.cities })
            } else if (apiRequestCallId === this.getComplexApiId) {
                this.setState({ selectComplex: responseJson.societies })
            } else if (apiRequestCallId === this.getBuildingApiId) {
                this.setState({ selectBuilding: responseJson.data.building_list })
            } 
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        let token  = await getStorageData("loginSuccessToken");
        this.setState({token:token})
        this.handleCardApi()
        this.handleCountryApi()
        this.handleCityApi()
        this.handleComplexApi()
        this.handleBuildingApi()
      }

    handleCountryFilterChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ individualbuilding: value as string })
    };

    handleSelectCountryChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ selectedCountry: value as string })
    };


    handleSelectCityChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ selectedCity: value as string })
    };

    handleSelectComplexChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ selectedComplex: value as string })
    };

    handleSelectBuildingChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ selectedBuilding: value as string })
    };
    
    handleLogin = () => {
        this.props.navigation.navigate("LoginReports");
    }

    handleSearch = () => {
        this.handleCardApi()
      }

    handleCardApi = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.cardApiId = requestMessage.messageId;
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.cardApiEndPoint}?complex=${this.state.selectedComplex}&country=${this.state.selectedCountry}&city=${this.state.selectedCity}&building=${this.state.selectedBuilding}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleCountryApi = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCountryApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.countryApiEndpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleCityApi = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCityApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.cityApiEndpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleComplexApi = () => {

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getComplexApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.complexApiEndpoin}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleBuildingApi = () => {

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBuildingApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.buildingApiEndpoin
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }


      
    // Customizable Area End
}
