// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Link,
  Typography,
  withStyles
} from "@material-ui/core";
import CEAwaitAcceptController, { Props } from "./CEAwaitAcceptController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { CERequestManagementStyle } from "./CERequestManagement.web";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import SearchIcon from "@material-ui/icons/Search";
import AwaitAcceptCard from "../../../../components/src/CommunityManagementCard/AwaitAcceptCard.web";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";
import { DeleteIcon, buildingIcon, emailIcon, phoneIcon, userIcon } from "../assets";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import { Form, Formik } from "formik";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import PhoneInput from "react-phone-input-2";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
// Customizable Area End

class CEAwaitAccept extends CEAwaitAcceptController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: Props = this.props;
    const {waitingAcceptenceList}=this.state;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />
        <CEHeaderComponentWeb>
          <Container className={classes.RequestManagement}>
            <Box className="navigation await-heading-box">
              <Typography variant="body1" className="await-breadcrumb-box">
                <Link href="/CompanyEmployee/dashboard">{t("Community Management")}</Link> /{" "}
                <Link href="/CompanyEmployee/RequestManagement">{t("Requests Management")}</Link> /{" "}
                <Box component="span" style={{ color: "blue" }} className="await-head-text">
                  {t("Sent Invitations Awaiting Acceptance")}
                </Box>
              </Typography>
              <Box className="sub-heading-box await-heading-box">
                <Typography variant="h5" className="bold-text await-heading-text">
                  {t("Sent Invitations Awaiting Acceptance")}
                </Typography>
              </Box>
            </Box>

            <Box className="filter-head-box">
              <FilterSelect t={t}
                language={language}
                value={this.state.filter.selectedCompany}
                label="Company Name"
              />
              <FilterSelect
                t={t}
                language={language}
                value={this.state.filter.selectedIndividualBuilding}
                onChange={(e: any) => this.handleChangeValue(e, "selectedIndividualBuilding")}
                label={t("Complex / Individual building")}
                option={[{ label: "All", value: "all" }, ...this.state.complexBuildingFilterList]}
              />
              <FilterSelect
                t={t}
                language={language}
                label={t("Select Country")}
                onChange={(e: any) => this.handleChangeValue(e, "selectedCountry")}
                value={this.state.filter.selectedCountry}
                option={[{ label: "All", value: "all" }, ...this.state.countryList]}
              />
              <FilterSelect
                t={t}
                language={language}
                label={t("Select City")}
                onChange={(e: any) => this.handleChangeValue(e, "selectedCity")}
                value={this.state.filter.selectedCity}
                option={[{ label: "All", value: "all" }, ...this.state.cityList]}
              />
              <FilterSelect
                t={t}
                language={language}
                label={t("Select Complex")}
                onChange={(e: any) => this.handleChangeValue(e, "selectedComplex")}
                value={this.state.filter.selectedComplex}
                option={[{ label: "All", value: "all" }, ...this.state.complexList]}
              />
              <FilterSelect
                t={t}
                language={language}
                label={t("Select Building")}
                onChange={(e: any) => this.handleChangeValue(e, "selectedBuilding")}
                value={this.state.filter.selectedBuilding}
                option={[{ label: "All", value: "all" }, ...this.state.buildingsList]}
              />
              <FilterSelect
                t={t}
                language={language}
                label={t("Select Unit")}
                value={this.state.filter.selectedUnit}
                onChange={(e: any) => this.handleChangeValue(e, "selectedUnit")}
                option={[{ label: "All", value: "all" }, ...this.state.unitList]}
              />
              <FilterSelect
                t={t}
                language={language}
                label={t("Select user type")}
                value={this.state.filter.selectedType}
                option={[{ label: "All", value: "all" }, ...this.state.userList]}
                onChange={(e: any) => this.handleChangeValue(e, "selectedType")}
              />
              <Button startIcon={<SearchIcon />} data-testId="search-filter-btn" className="await-search-filter-btn" onClick={() => { this.getWaitingAcceptenceFiletrList() }}>
                {t("Search")}
              </Button>
            </Box>

            <Grid container spacing={2} style={{ margin: "15px 0 30px" }}>
            {waitingAcceptenceList.length > 0 &&  waitingAcceptenceList.map((item:any, index:any) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <AwaitAcceptCard item={item} t={t} dateFormat={this.handleDateFormat} handleDialog={this.handleDialog} handleDelete={this.handleDelete}/>
              </Grid>
            ))}
            </Grid>
          </Container>
        </CEHeaderComponentWeb>

        <AlertDialog
          IsOpen={this.state.isDeleteDialogOpen}
          Image={DeleteIcon}
          CloseDialog={() => {this.handleDelete()}}
          Header={t("Delete Invitation Confirmation")}
          Content={`<p>${t("Are you sure you want to delete invitation request")} <br/> ${t(
            "sent to"
          )} <b>${"Name"}</b> ${t("for Unit")} <b>${"Unit"}</b></p>`}
          DeclineText={t("Close")}
          AcceptText={t("Yes, Delete")}
          DeclineFun={() => {this.handleDelete()}}
          AcceptFun={() => {this.handleAccept()}}
        />

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          open={this.state.isResendDialogOpen}
          scroll="paper"
          fullWidth
          maxWidth="md"
        >
          <DialogHeader t={t} title="Resend Request"  />
          <Formik
            onSubmit={(values, { resetForm }) => {
              this.handleFormSubmit(values);
              resetForm();
            }}
            initialValues={this.state.formValue} // Ensure `formValue` is properly initialized
            enableReinitialize={true}
            validationSchema={this.meetingValidation}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit} translate>
                  <DialogContent dividers className={languageCondition(language, "arabic-grid", "")}>
                    <Grid container spacing={3}>
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          icon={userIcon}
                          language={language}
                          name="type"
                          label="User Type"
                          option={this.state.userList}
                          data-testid="user-type"
                          value={values.type}
                          onChange={(e: any) => {
                            setFieldValue("type", e.target.value);
                          }}
                        />
                        {ErrorHandler(t, touched.type, errors.type)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalTextField
                          t={t}
                          icon={userIcon}
                          language={language}
                          value={values.fullName}
                          isLabel={true}
                          label="Member Full Name"
                          data-testid="member-full-name"
                          id="fullName"
                          onChange={(e: any) => {
                            setFieldValue("fullName", e.target.value);
                          }}
                        />
                        {ErrorHandler(t, touched.fullName, errors.fullName)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalTextField
                          t={t}
                          icon={emailIcon}
                          language={language}
                          value={values.emailID}
                          isLabel={true}
                          data-testid="email-address"
                          id="emailId"
                          label="Email Address"
                          onChange={(e: any) => {
                            setFieldValue("emailID", e.target.value);
                          }}
                        />
                        {ErrorHandler(t, touched.emailID, errors.emailID)}
                      </Grid>
                      <Grid item md={6}>
                        <Box className="form__phone_number" display="flex" alignItems="center">
                          <PhoneInput
                            inputProps={{ name: "selectCode" }}
                            value={values.countryCode}
                            enableSearch={true}
                            country="us"
                            data-testid="country-code"
                            onChange={(value: any) => {
                              setFieldValue("countryCode", `+${value}`);
                            }}
                          />
                          <NormalTextField
                            t={t}
                            language={language}
                            value={values.phoneNumber}
                            icon={phoneIcon}
                            isLabel={true}
                            id="phoneNumber"
                            label="Phone Number"
                            data-testid="phone-number"
                            onChange={(e: any) => {
                              setFieldValue("phoneNumber", e.target.value);
                            }}
                          />
                        </Box>
                        {ErrorHandler(t, touched.phoneNumber, errors.phoneNumber)}
                      </Grid>
                      <Grid item md={6}>
                         <NormalSelect
                            t={t}
                            label="Country"
                            value={values.country}
                            language={language}
                            name="country"
                            data-testid="select-country"
                            onBlur={handleBlur}
                            onChange={(e: any) => {
                              const value = e.target.value;
                              setFieldValue("country", value);
                              this.getCityList(value);
                            }}
                            option={[...this.state.countryList]}
                          />
                          {ErrorHandler(t, touched.country, errors.country)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          label="City"
                          key="city"
                          data-testid="select-city"
                          value={values.city}
                          language={language}
                          option={this.state.cityList}
                          onChange={(e: any) => {
                            const value = e.target.value;
                            setFieldValue("city", value);
                          }}
                        />
                        {ErrorHandler(t, touched.city, errors.city)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          id="selectedIndividualBuilding"
                          data-testid="individual-building"
                          label="Select Complex/Individual Building"
                          value={values.individualBuilding}
                          option={this.state.complexBuildingFilterList}
                          onChange={(e: any) => {
                            const value = e.target.value;
                            setFieldValue("individualBuilding", value);
                          }}
                        />
                        {ErrorHandler(t, touched.individualBuilding, errors.individualBuilding)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          label="Select Complex"
                          data-testid="select-complex"
                          id="selectedComplex"
                          language={language}
                          icon={buildingIcon}
                          value={values.complex}
                          option={this.state.complexList}
                          onChange={(e: any) => {
                            const value = e.target.value;
                            setFieldValue("complex", value);
                             this.getBuildingsDataList(value);
                             this.getUnitDataList(value)
                          }}
                        />
                        {ErrorHandler(t, touched.complex, errors.complex)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          label="Select Building"
                          data-testid="select-building"
                          id="selectedBuilding"
                          icon={buildingIcon}
                          value={values.building}
                          option={this.state.buildingsList}
                          onChange={(e: any) => {
                            const value = e.target.value;
                            setFieldValue("building", value);
                          }}
                        />
                        {ErrorHandler(t, touched.building, errors.building)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          icon={buildingIcon}
                          value={values.unit}
                          label="Select Unit"
                          data-testid="select-unit"
                          id="selectedUnit"
                          option={this.state.unitList}
                          onChange={(e: any) => {
                            const value = e.target.value;
                            setFieldValue("unit", value);
                          }}
                        />
                        {ErrorHandler(t, touched.unit, errors.unit)}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog__button_group">
                    <Button  data-testid="cancel-button" className="cancel-button" onClick={() => { this.handleClose() }}>
                      {t("Cancel")}
                    </Button>
                    <Button className="add-button" type="submit"  data-testid="add-button">
                      {t("Send Invitation")}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: any = {
  formikSelectField: {
    width: 'calc(100% - 20px)',
    background: 'rgb(243, 243, 243) none repeat scroll 0% 0%',
    border: '1px solid rgb(227, 227, 227)',
    padding: '5px',
    borderRadius: '8px',
    marginTop: '10px',
  },
}
// @ts-ignore
export default withTranslation()(withStyles(CERequestManagementStyle)(withRouter(CEAwaitAccept)));
// Customizable Area End
