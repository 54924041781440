import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { ApiCatchErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { userProfile } from "../assets";
import {
    Box,
    Button,
    Grid,
} from "@material-ui/core";
import * as Yup from "yup";


export interface Props {
    stepComplex: number,
    imgUplodeAvtarComplex: any,
    imagesComplex:any,
    handleChangeComplex:any,
    previewImagesComplex:any,
    currencyList: any,
    currencySelected: any,
    handleCurrencyList:any,
    handleChangeCurrency: any,
    complexValues: any,
    handleNext:any,
    handleBack:any,
    currencyError: boolean,
    handleOpenLeasePopup:any,
    handleCloseLeasePopup:any,
    leaseSelected:any,
    rentalSelected:any,
    leasePopup:any,
    leaseList:any,
    handleSelectLease:any,
    imagesError: boolean,
    logoError: boolean,
    handleBasicDetails: any,
    handleFieldChange:any,
    handleTotalAreaUnit:any,
    totalAreaUnit:any,
    accountManagerList:any,
    accountType:any,
    t?: any
    i18n?: any
    match?: any
    location?: any
    // Customizable Area Start
    history?: any
    language?: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CompEmpComplexBlockStep1Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
    exampleAPICallId: string = "";
   

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

        this.state = {
            // Customizable Area Start
            loading: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

   

    async componentDidMount(): Promise<void> {
        // Customizable Area Start
       
        // Customizable Area End
    }

    // Customizable Area Start
    validationSchema = Yup.object().shape({
        complexName: Yup.string().min(3, this.props.t('Company name must be at least 3 characters'))
            .max(50, this.props.t('Complex name must be at most 50 characters')).required(this.props.t('Complex Name is required')),
        totalArea: Yup.string().required(this.props.t('Total Area is required')),
        totalFloors: Yup.string().required(this.props.t('Total Floor is required')),
        totalUnits: Yup.string().required(this.props.t('Total Units is required')),
        aboutUs: Yup.string().required(this.props.t('About Us is required')),
        currency: Yup.string().required(this.props.t('Currency is required')),
        logo: Yup.mixed().required(this.props.t('Please upload a logo')),
        images: Yup.array()
            .min(1, this.props.t('At least one image is required'))
            .max(6, this.props.t('Maximum of 6 images allowed'))
            .required(this.props.t('Please upload images')),
    });
    // Customizable Area End
}
