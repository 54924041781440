import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Grid, Typography, MenuItem, FormControl, Select, TextField, InputAdornment,
} from "@material-ui/core";
import {desigenImg} from "../assets"
//@ts-ignore
import CompEmpComplexBlockStep2Controller from './CompEmpComplexBlockStep2Controller.web'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {Formik, Form, Field, ErrorMessage} from "formik";
import {alphaNumericOnly, numbersOnly} from "../../../../components/src/KeyBlockFunctions";
import * as Yup from 'yup';
import "../../assets/commonForm.css"
// Customizable Area End

export default class CompEmpComplexBlockStep2 extends CompEmpComplexBlockStep2Controller {

    logoDisplay = (values: any) => {
        return (
            <>
                {
                    values.logo ? <img data-testid="preview_img"
                                       src={URL.createObjectURL(values.logo)}
                                       alt="Preview" className="step2Formik" style={{
                        width: '66px',
                        height: '66px',
                        borderRadius: '50%',
                        background: '#dcdcdc'
                    }}/> : <Box className="step2Formik" style={{
                        width: '66px',
                        height: '66px',
                        borderRadius: '50%',
                        background: '#dcdcdc'
                    }}>
                    </Box>
                }
            </>
        )
    }

    photoDisplay = (values: any, setFieldValue: any) => {
        return (
            <>
                {values?.images?.length < 6 && (
                    <>
                        <label id="label-file-upload" htmlFor="input-file-upload"
                               style={{marginRight: '23px', flexWrap: 'wrap'}}>
                            <div className="step2Formik">
                                <div className="upload-button step2Formik">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                         fill="none">
                                        <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd"
                                              d="M9 3.40501L6.71 5.70501C6.45634 5.95867 6.08663 6.05774 5.74012 5.96489C5.39362 5.87205 5.12297 5.6014 5.03012 5.25489C4.93728 4.90839 5.03634 4.53867 5.29 4.28501L9.29 0.285014C9.3851 0.193973 9.49725 0.122608 9.62 0.0750135C9.86346 -0.0250045 10.1365 -0.0250045 10.38 0.0750135C10.5028 0.122608 10.6149 0.193973 10.71 0.285014L14.71 4.28501C14.8993 4.47278 15.0058 4.72838 15.0058 4.99501C15.0058 5.26165 14.8993 5.51725 14.71 5.70501C14.5222 5.89433 14.2666 6.00081 14 6.00081C13.7334 6.00081 13.4778 5.89433 13.29 5.70501L11 3.40501V12.995C11 13.5473 10.5523 13.995 10 13.995C9.44771 13.995 9 13.5473 9 12.995V3.40501ZM18 12.995C18 12.4427 18.4477 11.995 19 11.995C19.5523 11.995 20 12.4427 20 12.995V16.995C20 18.6519 18.6569 19.995 17 19.995H3C1.34315 19.995 0 18.6519 0 16.995V12.995C0 12.4427 0.447715 11.995 1 11.995C1.55228 11.995 2 12.4427 2 12.995V16.995C2 17.5473 2.44772 17.995 3 17.995H17C17.5523 17.995 18 17.5473 18 16.995V12.995Z"
                                              fill="black"/>
                                    </svg>
                                </div>
                            </div>
                        </label>

                        <input type="file"
                               accept="image/*"
                               onChange={(event: any) => {
                                   const images = [...values.images, ...event.currentTarget.files]
                                   setFieldValue('images', images)
                                   this.props.handleFieldChange('images', images)
                               }}
                               style={{display: 'none'}}
                               id="input-file-upload"
                               className="step2Formik"
                               name="images"
                               data-testid="step2Images"
                               multiple
                        />
                    </>
                )}
            </>
        )
    }

    styleCard = (lease: any) => {
        return this.props.rentalSelected === lease.attributes.id ? {
            marginRight: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
            height: "127",
            width: "168",
            border: '2px solid #2b6fed',
        } : {
            marginRight: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
            height: "127",
            width: "168",
            border: '1px dashed #e4e4e4',
            boxShadow: '0px 6px 14px rgba(43,111,237,0.12)'
        }
    }

    styleTitle = (lease: any) => {
        return this.props.rentalSelected === lease.attributes.id ? {
            marginBottom: '10px',
            color: '#2B6FED',
            textAlign: 'center',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal'
        } : {
            marginBottom: '10px',
            color: '#181d25',
            textAlign: 'center',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal'
        }
    }

    render() {
        // Customizable Area Start

        const {t} = this.props;
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <Formik
                    initialValues={this.props.complexValues}
                    validationSchema={this.validationSchema}
                    validateOnMount={true}
                    enableReinitialize={true}
                    data-testid="step2Form"
                    onSubmit={(values) => {

                        this.props.handleBasicDetails(values)
                    }}
                >
                    {({values, touched, errors, isValid, setFieldValue, handleChange}) => (

                        <Form translate="yes" className="commonForms">
                            <Box>
                                <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <Box>
                                        <Typography className="bold-text" style={{
                                            fontSize: '22px',
                                            fontWeight: 'bold'
                                        }}>{t("Basic Details")}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography className="Step"
                                                    style={{fontSize: '16px'}}>{t("Step")} {this.props.stepComplex + 1} {t("of 7")}</Typography>
                                    </Box>
                                </Box>
                                <Box className="step2forms step2Formik" style={{
                                    marginTop: '28px', textAlign: 'center', display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    {this.logoDisplay(values)}

                                    <label htmlFor="logoChange"><span style={{
                                        color: '#FC8434'
                                    }} className="bold-text step2Formik">
                          {values.logo ? <>{t("Change Logo")}</> : <>{t("Add Logo")}</>}
                        </span></label>
                                    <input type="file"
                                           accept="image/*"
                                           id="logoChange"
                                           className="step2Formik"
                                           name="logo" data-testid="logoUpload" onChange={(event: any) => {
                                        setFieldValue('logo', event.currentTarget.files[0])
                                        this.props.handleFieldChange('logo', event.currentTarget.files[0])
                                    }}
                                           style={{display: 'none'}}/>
                                    <Box style={webStyle.errorMsg}><ErrorMessage name="logo" component="div"
                                                                                 data-testid="logo-error"/></Box>
                                </Box>
                                <Box style={{marginTop: '30px'}}>
                                    <Box className="boxDisplayFlex">
                                        <Box style={{width: '49%', marginRight: '10px'}}>
                                            <Typography className="typographyLable">{t("Complex Name")}</Typography>
                                            <Box>
                                                <Box className="formGroups" style={{
                                                    marginBottom: "0px",
                                                    border: "1px solid #F0F0F0",
                                                    borderRadius: "8px",
                                                    backgroundColor: "#F0F0F0"
                                                }}>
                                                    <Field name="complexName" type="text" placeholder="Complex Name"
                                                           className="formInputs" id="complexName"
                                                           onKeyDown={alphaNumericOnly}
                                                           data-testid="fieldChange"
                                                           onChange={
                                                               (e: any) => {
                                                                   handleChange(e)
                                                                   this.props.handleFieldChange('complexName', e.target.value)
                                                               }
                                                           }
                                                    />
                                                    <span className="frmLeftIcons step3">
                                                        </span>
                                                </Box>
                                                <Box style={webStyle.errorMsg}><ErrorMessage name="complexName"
                                                                                             component="div"
                                                                                             data-testid="complexName-error"/></Box>
                                            </Box>
                                        </Box>
                                        <Box style={{width: '49%'}}>
                                            <Typography className="typographyLable">{t("Total Area")}</Typography>
                                            <Box className="formGroups" style={{
                                                marginBottom: "0px",
                                                border: "1px solid #F0F0F0",
                                                borderRadius: "8px",
                                                backgroundColor: "#F0F0F0"
                                            }}>
                                                <Field name="totalArea" className="newField">
                                                    {({field}: { field: any }) => (
                                                        <TextField
                                                            {...field}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Select style={{
                                                                            color: '#BEBEBE'
                                                                        }} className="newField"
                                                                                value={this.props.totalAreaUnit}
                                                                                onChange={this.props.handleTotalAreaUnit}>
                                                                            <MenuItem value="Sqft"
                                                                                      className="bilding_step2 step2Formik"
                                                                                      selected>
                                                                                {t("Sqft")}
                                                                            </MenuItem>
                                                                            <MenuItem value="m2"
                                                                                      className="bilding_step2 step2Formik">
                                                                                m<sup>2</sup>
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                            onChange={(e: any) => {
                                                                handleChange(e);
                                                                this.props.handleFieldChange('totalArea', e.target.value);
                                                            }}
                                                            type="number"
                                                            placeholder={t("Total Area")}
                                                            className="formInputs step3"
                                                            id="totalArea"
                                                        />
                                                    )}
                                                </Field>
                                                <span className="frmLeftIcons step3">
                                                    </span>
                                            </Box>
                                            <Box style={webStyle.errorMsg}><ErrorMessage name="totalArea"
                                                                                         component="div"
                                                                                         data-testid="totalArea-error"/></Box>
                                        </Box>
                                    </Box>
                                </Box>


                                <Box style={{marginTop: '20px'}} className="step2Formik">
                                    <Typography className="typographyLable step2Formik">{t("Upload Photos")}</Typography>
                                    <Box className="step2Formik" style={{display: 'flex'}}>
                                        {this.photoDisplay(values, setFieldValue)}

                                        <div className="image-preview step2Formik" style={{
                                            display: 'flex'
                                        }}>
                                            {values?.images?.length ? values.images.map((image: any, index: number) => (
                                                <div style={{
                                                    position: 'relative'
                                                }} className="step2Formik">
                                                    <img
                                                        style={{
                                                            height: '104px',
                                                            width: '172px',
                                                            marginRight: '20px',
                                                            objectFit: 'cover',
                                                            border: '1px solid #ececec'
                                                        }}
                                                        className="step2Formik"
                                                        key={index}
                                                        src={URL.createObjectURL(image)}
                                                        alt={`Image ${index}`}
                                                    />
                                                    <HighlightOffIcon
                                                        className="faq_image_close_icon faq_image_close_icon2 step2forms step2Formik"
                                                        style={{
                                                            right: '25px',
                                                            position: 'absolute',
                                                            fill: 'red',
                                                            top: '5px',
                                                            cursor: 'pointer'
                                                        }}
                                                        data-testid="step2ImageRemove"
                                                        onClick={() => {
                                                            const newImages = [...values.images];
                                                            newImages.splice(index, 1);
                                                            setFieldValue('images', newImages);
                                                            this.props.handleFieldChange('images', newImages)
                                                        }}
                                                    />
                                                </div>
                                            )) : <></>}
                                        </div>
                                    </Box>
                                    <Box style={webStyle.errorMsg}><ErrorMessage name="images" component="div"
                                                                                 data-testid="images-error"/></Box>
                                </Box>
                                <Box style={{marginTop: '30px'}}>
                                    <Box className="boxDisplayFlex">
                                        <Box style={{width: '49%', marginRight: '10px'}}>
                                            <Typography className="typographyLable">{t("Total Floors")}</Typography>
                                            <Box>
                                                <Box className="formGroups" style={{
                                                    marginBottom: "0px",
                                                    border: "1px solid #F0F0F0",
                                                    borderRadius: "8px",
                                                    backgroundColor: "#F0F0F0"
                                                }}>
                                                    <Field name="totalFloors" type="number" placeholder={t("Total Floors")}
                                                           className="formInputs" id="totalFloors"
                                                           data-testid="fieldChange"
                                                           onChange={
                                                               (e: any) => {
                                                                   handleChange(e)
                                                                   this.props.handleFieldChange('totalFloors', e.target.value)
                                                               }
                                                           }/>
                                                    <span className="frmLeftIcons">
                                                        </span>
                                                </Box>
                                                <Box style={webStyle.errorMsg}><ErrorMessage name="totalFloors"
                                                                                             component="div"
                                                                                             data-testid="totalFloors-error"/></Box>
                                            </Box>
                                        </Box>
                                        <Box style={{width: '49%'}}>
                                            <Typography className="typographyLable">{t("Total Units")}</Typography>
                                            <Box className="formGroups" style={{
                                                marginBottom: "0px",
                                                border: "1px solid #F0F0F0",
                                                borderRadius: "8px",
                                                backgroundColor: "#F0F0F0"
                                            }}>
                                                <Field name="totalUnits" type="number" placeholder={t("Total Units")}
                                                       id="totalUnits"
                                                       data-testid="fieldChange"
                                                       className="formInputs" onChange={
                                                    (e: any) => {
                                                        handleChange(e)
                                                        this.props.handleFieldChange('totalUnits', e.target.value)
                                                    }
                                                }/>
                                                <span className="frmLeftIcons step1">
                                                    </span>
                                            </Box>
                                            <Box style={webStyle.errorMsg}><ErrorMessage name="totalUnits"
                                                                                         component="div"
                                                                                         data-testid="totalUnits-error"/></Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box style={{marginTop: '20px'}}>
                                    <Box style={{width: '100%'}}>
                                        <Typography className="typographyLable">{t("About Us")}</Typography>
                                        <Box className="formGroups step" style={{
                                            marginBottom: "0px",
                                            border: "1px solid #F0F0F0",
                                            borderRadius: "8px",
                                            backgroundColor: "#F0F0F0"
                                        }}>
                                            <Field name="aboutUs" id="aboutUs" placeholder={t("About Us")} type="address"
                                                   as="textarea"
                                                   data-testid="fieldChange"
                                                   className="formInputs1" onChange={
                                                (e: any) => {
                                                    handleChange(e)
                                                    this.props.handleFieldChange('aboutUs', e.target.value)
                                                }
                                            }/>
                                            <span className="frmLeftIcons step">
                                                </span>
                                        </Box>
                                        <Box style={webStyle.errorMsg}><ErrorMessage name="aboutUs" component="div"
                                                                                     data-testid="aboutUs-error"/></Box>
                                    </Box>
                                </Box>
                                <Box style={{display: 'flex'}}>
                                    <Box className="formGroups customSelect step2Formik"
                                         style={{marginRight: '25px', width: '50%', marginTop: '20px'}}>
                                        <Typography className="typographyLable bilding_step2 step2Formik">
                                            {t("Currency")}
                                        </Typography>
                                        <FormControl variant="outlined" className="bilding_step2 step2Formik">
                                            <Field as={Select} name="currency"
                                                   id="country"
                                                   className={`${values.currency !== '' ? 'estate_select_color_building_value' : 'estate_select_color_building'} estate_select countries_form_real_estate_grid_field step2Formik`}

                                                   style={{
                                                       backgroundColor: "#F0F0F0",
                                                       borderRadius: '8px'
                                                   }}
                                                   data-testid="fieldChange"
                                                   displayEmpty

                                                   onChange={(e: any) => {
                                                       handleChange(e);
                                                       this.props.handleFieldChange('currency', e.target.value);
                                                   }}
                                            >
                                                {/* <InputLabel id="demo-simple-select-placeholder">Select Currency</InputLabel> */}
                                                <MenuItem disabled value="">{t("Select Currency")}</MenuItem>
                                                {this.props.currencyList &&
                                                    this.props.currencyList.map((item: any) => {
                                                        return (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                {item?.attributes?.currency}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                {/* <MenuItem value={values.currency} className="bilding_step2 step2Formik">
                                                        {values.currency}
                                                    </MenuItem> */}
                                            </Field>
                                            <Box style={webStyle.errorMsg}><ErrorMessage name="currency" component="div"
                                                                                         data-testid="currency-error"/></Box>
                                        </FormControl>
                                    </Box>

                                </Box>
                                <Box style={{marginTop: '20px'}} className="leaseTemplate">
                                    <Box style={{width: '100%'}} className="leaseTemplate">
                                        <Typography className="typographyLable leaseTemplate">{t("Default Renting Contract:")}</Typography>
                                        <Box className="leaseTemplate" style={{display: 'flex', flexWrap: 'wrap'}}>
                                            {
                                                this.props.leaseList.length && this.props.leaseList.map((lease: any, i: number) => {
                                                    return (
                                                        <Box className="leaseTemplate" data-testid="leaseOpen" key={i}
                                                             style={{
                                                                 cursor: 'pointer'
                                                             }} onClick={() => this.props.handleOpenLeasePopup(i)}>
                                                            <Box className="leaseTemplate"
                                                                //@ts-ignore
                                                                 style={this.styleCard(lease)}>
                                                                <Box className="leaseTemplate" style={{
                                                                    marginBottom: '10px',
                                                                    border: '5px solid #f7f9fe',
                                                                    padding: "20px",
                                                                    width: 'max-content',
                                                                    borderRadius: '100%'
                                                                }}>
                                                                    <img className="leaseTemplate" src={desigenImg}/>
                                                                </Box>
                                                                <Box className="leaseTemplate"
                                                                    //@ts-ignore
                                                                     style={this.styleTitle(lease)}>
                                                                    {lease.attributes?.title}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box className="step2BtnBox"
                                 style={{display: 'flex', justifyContent: "end", flexWrap: 'wrap'}}>
                                <Grid className="step2Grid" style={{marginTop: "30px", marginRight: '20px'}}>
                                    <Box style={{}}>
                                        <Button className="step2Prev bold-text" style={{
                                            color: "#2B6FED",
                                            fontWeight: 'bold',
                                            padding: '0px 20.5px',
                                            textTransform: 'capitalize',
                                            width: '177px',
                                            height: '56px',
                                            border: '1px solid #2B6FED',
                                            borderRadius: "8px",
                                            display: 'flex',
                                            justifyContent: 'center',
                                            textAlign: 'center'
                                        }} onClick={this.props.handleBack}><span
                                            className="bold-text">{t("PREV")}</span></Button>
                                    </Box>
                                </Grid>
                                <Box>
                                    <Grid className="step2Grid" style={{marginTop: "30px",}}>
                                        <Button className="step2Next bold-text" style={{
                                            width: '177px',
                                            height: '56px',
                                            backgroundColor: '#2B6FED',
                                            borderRadius: "8px",
                                            display: 'flex',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            color: "#FFFFFF",
                                            fontWeight: 'bold',
                                            padding: '0px 20.5px',
                                            textTransform: 'capitalize'
                                        }} data-testid="step2next" type="submit"
                                            // onClick={this.props.handleNext}
                                        ><span className="bold-text">{t("NEXT")}</span></Button>
                                    </Grid>
                                </Box>
                            </Box>

                        </Form>)}
                </Formik>

                <Box className="leaseTemplate">
                    <Box className="leaseTemplate" style={{
                        zIndex: 9999,
                        display: this.props.leasePopup ? 'block' : 'none',
                        position: 'fixed',
                        top: 0,
                        width: '100%',
                        left: 0,
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    }}>
                        <Box className="leaseTemplate" style={{
                            width: '50%',
                            backgroundColor: '#fff',
                            top: '50%',
                            borderRadius: '5px',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            height: '90%',
                            overflow: 'auto',
                            position: 'absolute',
                        }}>
                            <Box className="leaseTemplate">
                                <Box className="topBarName leaseTemplate">
                                    <Box className="bold-text leaseTemplate"
                                         style={{fontSize: '22px', fontWeight: 400}}>Lease Template Preview</Box>
                                    <Box className="leaseTemplate" onClick={this.props.handleCloseLeasePopup}
                                         style={{cursor: "grabbing"}}>
                                        <svg className="leaseTemplate" xmlns="http://www.w3.org/2000/svg" width="14"
                                             height="14" viewBox="0 0 14 14" fill="none">
                                            <path
                                                d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z"
                                                fill="black"/>
                                        </svg>
                                    </Box>
                                </Box>
                                <Box className="leaseTemplate" style={{borderBottom: '1px solid #e7e7e7'}}></Box>
                                <Box className="leaseTemplate" style={{padding: '25px'}}>
                                    <Box className="leaseTemplate"
                                         style={{padding: '30px', border: '1px solid #dcdcdc', borderRadius: '4px'}}>
                                        <div className="leaseTemplate"
                                             dangerouslySetInnerHTML={{__html: this.props.leaseList[this.props.leaseSelected]?.attributes?.template}}
                                        />
                                    </Box>
                                    <Box className="leaseTemplate"
                                         style={{display: 'flex', marginTop: '15px', justifyContent: 'end'}}>
                                        <Grid className="leaseTemplate"
                                              style={{marginTop: "30px", marginRight: '20px'}}>
                                            <Button onClick={this.props.handleCloseLeasePopup}
                                                    className="buttonCancel leaseTemplate"><span
                                                className="bold-text">{t("CANCEL")}</span></Button>
                                        </Grid>
                                        <Grid className="leaseTemplate" style={{marginTop: "30px",}}>
                                            <Button
                                                data-testid="selectLease"
                                                className="buttonSave leaseTemplate"
                                                type="submit"
                                                onClick={() => this.props.handleSelectLease(this.props.leaseList[this.props.leaseSelected]?.attributes?.id)}
                                            ><span className="bold-text">{t("SELECT")}</span></Button>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// export default withTranslation()(withRouter(CompEmpComplexBuildingBlock));
// Customizable Area Start
const webStyle = {
    closeIcon: {
        right: '25px',
        position: 'absolute',
        fill: 'red',
        top: '5px',
        cursor: 'pointer'
    },
    imageBox: {
        position: 'relative'
    },
    complexPreview: {
        height: '104px',
        width: '172px',
        marginRight: '20px',
        objectFit: 'cover',
        border: '1px solid #ececec'
    },
    errorMsg: {
        color: 'red',
        fontSize: '12px',
        marginTop: '5px'
    },
    buttonSave: {
        width: '177px',
        height: '56px',
        backgroundColor: '#2B6FED',
        borderRadius: "8px",
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        color: "#FFFFFF",
        fontWeight: 'bold',
        padding: '0px 20.5px',
        textTransform: 'capitalize'
    },
    step6: {
        margin: '11px 0px',
        color: 'black',
        fontSize: '16px',
        fontWeight: 400
    },
    previewBox: {
        display: 'flex'
    }
}
// Customizable Area End