import React from "react";
// Customizable Area Start
import {
    Box,
    Button, DialogContentText, DialogTitle,
    Grid, Typography, Dialog, DialogActions, DialogContent
} from "@material-ui/core";
import { profile } from "../assets"
import CompEmpComplexBlockController from "./CompEmpComplexBlockController.web";
import { Formik, Form, Field } from "formik";
//@ts-ignore
import CompEmpComplexBlockStep1 from './CompEmpComplexBlockStep1.web'
//@ts-ignore
import CompEmpComplexBlockStep2 from './CompEmpComplexBlockStep2.web'
//@ts-ignore
import CompEmpComplexBlockStep3 from './CompEmpComplexBlockStep3.web'
//@ts-ignore
import CompEmpComplexBlockStep4 from './CompEmpComplexBlockStep4.web'
//@ts-ignore
import CompEmpComplexBlockStep5 from './CompEmpComplexBlockStep5.web'
//@ts-ignore
import CompEmpComplexBlockStep6 from './CompEmpComplexBlockStep6.web'
//@ts-ignore
import CompEmpComplexBlockStep7 from './CompEmpComplexBlockStep7.web'
import { ModalPopup } from "../../../../components/src/ModalPopup";
import { withTranslation } from "react-i18next";
// @ts-ignore
import TickIcon from '../../assets/tick-circle-icon.png'
import "../../assets/commonForm.css"
// Customizable Area End

class CompEmpComplexBlock extends CompEmpComplexBlockController {
    render() {
        // Customizable Area Start
        const { t,i18n } = this.props
        const language = i18n.language
        
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
            <Box style={{ padding: '40px', paddingRight: '40px', backgroundColor: 'rgb(244, 247, 255)' }}>
                <Box style={{display:"flex"}}>
                <Typography style={{ fontSize: '18px' }}> Real Estate Companies  / Company Details /</Typography>
               
                <Typography style={webStyle.headColor}>Create New Complex</Typography>
                </Box>
                <br />
                <Typography style={{ fontSize: '32px', }} className="bold-text"> Create New Complex</Typography>
                <Box style={{ marginTop: "25px", display: 'flex', flexWrap: 'wrap' ,gap:"12px"}}>
                    {(this.multiPleButtonHaderComplex())}
                </Box>
                <Grid className="topBarFormik" >
                                {this.state.stepComplex === 0 && <CompEmpComplexBlockStep1 
                                                jurisdictionList={this.state.jurisdictionList} jurisdictionSelected={this.state.jurisdictionSelected} handleChangeComplex={this.handleChangeComplex}
                                                stepComplex={this.state.stepComplex}
                                                handleNext={this.handleNextComplexData}
                                                handleBack={this.handleBackComplex}
                                                t={t}
                                                language={language}
                                                // companyId={this.props.companyId}
                                />}
                                {this.state.stepComplex === 1 && <CompEmpComplexBlockStep2 stepComplex={this.state.stepComplex}
                                 imgUplodeAvtarComplex={this.imgUplodeAvtarComplex}
                                 imagesComplex={this.state.imagesComplex}
                                 previewImagesComplex={this.state.previewImagesComplex}
                                 handleChangeComplex={this.handleChangeComplex}
                                 currencyList={this.state.currencyList}
                                 currencySelected={this.state.currencySelected}
                                 handleCurrencyList={this.handleCurrencyList}
                                 handleChangeCurrency={this.handleChangeCurrency}
                                 complexValues={this.state.complexValues}
                                 handleNext={this.handleNextComplexData}
                                 handleBack={this.handleBackComplex}
                                 currencyError={this.state.currencyError}
                                 imagesError={this.state.imagesError}
                                 logoError={this.state.logoError}
                                 handleBasicDetails={this.handleBasicDetails}
                                 handleFieldChange={this.handleFieldChange}
                                 handleTotalAreaUnit={this.handleTotalAreaUnit}
                                 totalAreaUnit={this.state.totalAreaUnit}
                                 leaseList={this.state.leaseList}
                                 handleOpenLeasePopup={this.handleOpenLeasePopup}
                                 handleCloseLeasePopup={this.handleCloseLeasePopup}
                                 leasePopup={this.state.leasePopup}
                                 leaseSelected={this.state.leaseSelected}
                                 rentalSelected={this.state.rentalSelected}
                                 handleSelectLease={this.handleSelectLease}
                                 accountManagerList={this.state.accountManagerList}
                                 accountType={this.state.accountType}
                                 t={t}
                                 language={language}
                                />}
                                {this.state.stepComplex === 2 && <CompEmpComplexBlockStep3 
                                    stepComplex={this.state.stepComplex} 
                                    handleNext={this.handleNextComplexUpload}
                                    handleBack={this.handleBackComplex}
                                    selectedFilePdfComplex={this.state.selectedFilePdfComplex}
                                    handleFileChangesComplex={this.handleFileChangesComplex}
                                    selectedFilePdfGuidelinesComplex={this.state.selectedFilePdfGuidelinesComplex}
                                    handleFileChangesGuidelinesComplex={this.handleFileChangesGuidelinesComplex}
                                    selectedFilePdfRolesComplex={this.state.selectedFilePdfRolesComplex}
                                    handleFileChangesRolesComplex={this.handleFileChangesRolesComplex}
                                    selectedFilePdfResolutionsComplex={this.state.selectedFilePdfResolutionsComplex}
                                    handleFileChangesResolutionsComplex={this.handleFileChangesResolutionsComplex}
                                    selectedFilePdfBuildingPlansComplex={this.state.selectedFilePdfBuildingPlansComplex}
                                    handleFileChangesBuildingPlansComplex={this.handleFileChangesBuildingPlansComplex}
                                    handleRemoveFileComplex={this.handleRemoveFileComplex}
                                    handleRemoveFileGuidelinesComplex={this.handleRemoveFileGuidelinesComplex}
                                    uploadGuidelinesComplex={this.uploadGuidelinesComplex}
                                    handleRemoveFileRolesComplex={this.handleRemoveFileRolesComplex}
                                    uploadName={this.uploadName}
                                    handleRemoveFileResolutionsComplex={this.handleRemoveFileResolutionsComplex}
                                    uploadResolutions={this.uploadResolutions}
                                    handleRemoveFileBuildingPlansComplex={this.handleRemoveFileBuildingPlansComplex}
                                    t={t}
                                    language={language}
                                    />}
                                {this.state.stepComplex === 3 && <CompEmpComplexBlockStep4
                                                stepComplex={this.state.stepComplex}
                                                handleNext={this.handleNextComplexData}
                                                handleBack={this.handleBackComplex}
                                                countriesList={this.state.countriesList}
                                                countrySelected={this.state.countrySelected}
                                                handleCountryChange={this.handleCountryChange}
                                                locationDetails={this.state.locationDetails}
                                                handleLocationFieldChange={this.handleLocationFieldChange}
                                                handleLocationDetails={this.handleLocationDetails}
                                                t={t}
                                                language={language}
                                />}
                                {this.state.stepComplex === 4 && <CompEmpComplexBlockStep5
                                                stepComplex={this.state.stepComplex}    
                                                handleNext={()=>this.setState({stepComplex:5})}
                                                handleBack={this.handleBackComplex}
                                                findPersonPopup={this.findPersonPopup}
                                                findPopupOpen={this.state.findPopupOpen}
                                                imgUplodeAvtar={this.imgUplodeAvtar}
                                                previewImagesBuilding={this.state.previewImagesBuilding}
                                                countriesList={this.state.countriesList}
                                                handleClickOpenBuilding={this.handleClickOpenBuilding}
                                                handleCountryList={this.handleCountriesList}
                                                imagesBuilding={this.state.imagesBuilding}
                                                logoBuildingError={this.state.logoBuildingError}
                                                buildingValues={this.state.buildingValues}
                                                savedBuildings={this.state.savedBuildings}
                                                handleRemoveBuildingDetails={this.handleRemoveBuildingDetails}
                                                imagesBuildingError={this.state.imagesBuildingError}
                                                handleBuildingDetails={this.handleBuildingDetails}
                                                deleteBuildingPopup={this.state.deleteBuildingPopup}
                                                handleOpenDeleteBuildingPopup={this.handleOpenDeleteBuildingPopup}
                                                handleCloseDeleteBuildingPopup={this.handleCloseDeleteBuildingPopup}
                                                deleteBuildingIndex={this.state.deleteBuildingIndex}
                                                t={t}
                                                language={language}
                                />}
                                {this.state.stepComplex === 5 && <CompEmpComplexBlockStep6
                                                stepComplex={this.state.stepComplex}
                                                handleNext={()=>this.setState({stepComplex:6})}
                                                handleBack={this.handleBackComplex}
                                                sharedFacilityPopupComplex={this.sharedFacilityPopupComplex}
                                                facilityPopupComplex={this.state.facilityPopupComplex}
                                                imagesFacility={this.state.imagesFacility}
                                                previewImagesFacility={this.state.previewImagesFacility}
                                                imagesFacilityError={this.state.imagesFacilityError}
                                                selectedFilePdfFloorPlanComplex={this.state.selectedFilePdfFloorPlanComplex}
                                                handleRemoveFileFloorPlanComplex={this.handleRemoveFileFloorPlanComplex}
                                                handleFileChangesFloorPlanComplex={this.handleFileChangesFloorPlanComplex}
                                                handleRemoveFacility={this.handleRemoveFacilityDetails}
                                                savedFacility={this.state.savedFacility}
                                                facilityValues={this.state.facilityValues}
                                                handleSharedDetails={this.handleSharedDetails}
                                                deleteFacilityPopup={this.state.deleteFacilityPopup}
                                                handleOpenDeleteFacilityPopup={this.handleOpenDeleteFacilityPopup}
                                                handleCloseDeleteFacilityPopup={this.handleCloseDeleteFacilityPopup}
                                                handleRemoveFacilityDetails={this.handleRemoveFacilityDetails}
                                                deleteFacilityIndex={this.state.deleteFacilityIndex}
                                                t={t}
                                                language={language}
                                />}
                                {this.state.stepComplex === 6 && <CompEmpComplexBlockStep7
                                                stepComplex={this.state.stepComplex}
                                                handleNext={this.handleNextComplex}
                                                handleBack={this.handleBackComplex}
                                                coreMemberManager={this.state.coreMemberManager}
                                                sharedCoreMembers={this.sharedCoreMembers}
                                                coreMembers={this.state.coreMembers}
                                                sharedCreateNewMember={this.sharedCreateNewMember}
                                                handleAddMember={this.handleAddMember}
                                                createNewMember={this.state.createNewMember}
                                                handleSave={()=>this.setState({createConfirmModal:true})}
                                                userList={this.state.userList}
                                                handleUserSelect={this.handleUserSelect}
                                                userValues={this.state.userValues}
                                                roleList={this.state.roleList}
                                                memberValues={this.state.memberValues}
                                                handleMemberDetails={this.handleMemberDetails}
                                                savedMembers={this.state.savedMembers}
                                                handleRemoveMember={this.handleRemoveMember}
                                                deleteMemberPopup={this.state.deleteMemberPopup}
                                                handleCloseDeleteMemberPopup={this.handleCloseDeleteMemberPopup}
                                                handleOpenDeleteMemberPopup={this.handleOpenDeleteMemberPopup}
                                                deleteMemberIndex={this.state.deleteMemberIndex}
                                                buildingListData={this.state.buildingListData}
                                                handleBasicDetailsData={this.handleBasicDetailsData}
                                                t={t}
                                                language={language}
                                />}
                </Grid>
                <Dialog
                    open={this.state.open}
                    aria-describedby="alert-dialog-description"
                    aria-labelledby="alert-dialog-title"
                >
                    <Box style={{ padding: '50px 0px' }}>
                        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
                            <svg viewBox="0 0 61 61" height="61" xmlns="http://www.w3.org/2000/svg" width="61"   fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.03853 30.0835C6.03853 16.5273 17.0278 5.53804 30.584 5.53804C44.1402 5.53804 55.1294 16.5273 55.1294 30.0835C55.1294 43.6397 44.1402 54.6289 30.584 54.6289C17.0278 54.6289 6.03853 43.6397 6.03853 30.0835ZM30.584 0.0834961C14.0153 0.0834961 0.583984 13.5148 0.583984 30.0835C0.583984 46.6522 14.0153 60.0835 30.584 60.0835C47.1527 60.0835 60.584 46.6522 60.584 30.0835C60.584 13.5148 47.1527 0.0834961 30.584 0.0834961ZM42.7706 25.8643C41.6857 24.8232 39.9268 24.8232 38.8418 25.8643L29.6951 34.6456L23.3262 28.531C22.2412 27.4899 20.4823 27.4899 19.3973 28.531C18.3129 29.5726 18.3129 31.2611 19.3973 32.3027L27.7307 40.3027C28.8156 41.3438 30.5745 41.3438 31.6595 40.3027L42.7706 29.636C43.8551 28.5944 43.8551 26.9059 42.7706 25.8643Z" fill="#FC8434" />
                            </svg>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center', color: 'black', fontSize: '22px', fontWeight: 700 }} >
                                New Building Created
                            </DialogContentText>
                            <DialogContentText style={{ textAlign: 'center', color: 'black', fontSize: '16px', fontWeight: 400 }} id="alert-dialog-description">
                                Approval Request has been sent to the COO. Once the request is approved the building will be added to the platform
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="buttonCancel buttonOkay" onClick={this.handleCloseComplex}>Okay</Button>
                        </DialogActions>
                    </Box>
                </Dialog>

                <Dialog
                    aria-labelledby="alert-dialog-title"
                    open={this.state.openBuilding}
                    aria-describedby="alert-dialog-description"
                >
                   
                    <Box style={{ padding: '50px 0px' }}>
                        <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.03853 30.0835C6.03853 16.5273 17.0278 5.53804 30.584 5.53804C44.1402 5.53804 55.1294 16.5273 55.1294 30.0835C55.1294 43.6397 44.1402 54.6289 30.584 54.6289C17.0278 54.6289 6.03853 43.6397 6.03853 30.0835ZM30.584 0.0834961C14.0153 0.0834961 0.583984 13.5148 0.583984 30.0835C0.583984 46.6522 14.0153 60.0835 30.584 60.0835C47.1527 60.0835 60.584 46.6522 60.584 30.0835C60.584 13.5148 47.1527 0.0834961 30.584 0.0834961ZM42.7706 25.8643C41.6857 24.8232 39.9268 24.8232 38.8418 25.8643L29.6951 34.6456L23.3262 28.531C22.2412 27.4899 20.4823 27.4899 19.3973 28.531C18.3129 29.5726 18.3129 31.2611 19.3973 32.3027L27.7307 40.3027C28.8156 41.3438 30.5745 41.3438 31.6595 40.3027L42.7706 29.636C43.8551 28.5944 43.8551 26.9059 42.7706 25.8643Z" fill="#FC8434" />
                            </svg>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{ textAlign: 'center', color: 'black', fontSize: '22px', fontWeight: 700 }} id="alert-dialog-description">
                                New Building Added
                            </DialogContentText>
                            <DialogContentText style={{ textAlign: 'center', color: 'black', fontSize: '16px', fontWeight: 400 }} id="alert-dialog-description">
                                New building with basic information has been added to the complex. Do you want to complete the details of the building now, or later ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Box style={{ display: 'flex', marginTop: '15px', justifyContent: 'center' }}>
                                <Grid style={{ marginTop: "30px", marginRight: '20px' }}>
                                    <Button className="buttonCancel" onClick={this.handleNavigateBuildingBlock}><span className="bold-text">NOW</span></Button>
                                </Grid>
                                <Grid style={{ marginTop: "30px", }}>
                                    <Button className="buttonSave" onClick={()=>this.handleCloseBuilding()} ><span className="bold-text">LATER</span></Button>
                                </Grid>
                            </Box>
                        </DialogActions>
                    </Box>
                </Dialog>
            </Box>
            <ModalPopup
          title={t("New Complex Created")}
          description={this.state.accountType === "coo" ? t("Complex has been successfully created on the platform.") :
            t("Approval request has been set to the COO for approval. Upon approval the complex will be added to the platform.")}
          open={this.state.createConfirmModal}
          closeFn={this.handleCloseCreateModal}
          primaryAction={this.handleCloseCreateModal}
          primaryLabel={t("OKAY")}
          icon={TickIcon}
        />
            </>
            // Customizable Area End
        );
    }
}

//@ts-ignore
export default withTranslation()(CompEmpComplexBlock);
// Customizable Area Start
const webStyle = {
    buttonSave: {
        width: '177px',
        height: '56px',
        backgroundColor: '#2B6FED',
        borderRadius: "8px",
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        color: "#FFFFFF",
        fontWeight: 'bold',
        padding: '0px 20.5px',
        textTransform: 'capitalize'
    },
    headColor:{
        color: 'rgb(43, 111, 237)',
        fontSize:"18px"
       },
    step6:{
        margin: '11px 0px',
        color: 'black',
        fontSize: '16px', 
        fontWeight: 400
    }
}
// Customizable Area End