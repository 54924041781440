
//Customizable area start

import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
export interface Props {
  navigation: any;
  id: string;


}
interface S {
  open: boolean,
  open2: boolean,
  field: any,
  drawer: boolean,
  isLogoutModalOpen: boolean,
  role: any
  actualrole: any
}interface SS {
  id: any;
}
export default class DashboardHeaderController extends CommonApiCallForBlockComponent<Props, S, SS> {
  ConfirmPassCallID: any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),

    ];
    this.state = {
      open: false,
      open2: false,
      field: "",
      drawer: false,
      isLogoutModalOpen: false,
      role: '',
      actualrole: ''
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

  }

  async componentDidMount() {
    await super.componentDidMount()

    const storedRole = localStorage.getItem('role');

   this.formatBackendString(storedRole)
    

  }
   formatBackendString=(storedRole:string|null)=> {
    if (storedRole !== null) {
      if(storedRole == "coo"){
        this.setState({actualrole:"COO"})
      }else{
        const words = storedRole.split('_');
        const formattedWords = words.map(word => {
          const cleanedWord = word.replace(/"/g, ''); // Remove double quotes
          const capitalizedWord = cleanedWord.charAt(0).toUpperCase() + cleanedWord.slice(1).toLowerCase();
          return capitalizedWord;
        });
        const rolee = formattedWords.join(' ');
        
        this.setState({ actualrole: rolee });
      }
      
    }
  
  }
 
  handleDrawerOpen = () => {
    this.setState({
      drawer: true,
    });
  }
  handleDrawerClose = () => {
    this.setState({
      drawer: false,
    });
  }
  handleOpen = (e: any) => {
    const ExpandElement = document.querySelector("#myExpand");
    this.setState({
      open: true,
      field: ExpandElement

    });

  }
  handleLogoutModal = () => {
    this.setState({ isLogoutModalOpen: false });
    console.log("abc", this.props.navigation)
  };
  logout = () => {
    localStorage.clear();
    window.location.href = '/CompanyEmployee/Login'
  }





}
// Customizable Area End

