export const Building1=require('../../assets/building.jpg')
export const Building2=require('../../assets/building1.jpg')
export const Building3=require('../../assets/building2.jpg')
export const Building4=require('../../assets/building3.jpg')
export const Building5=require('../../assets/building4.jpeg')
export const Building6=require('../../assets/building5.jpeg')
export const Down=require('../../assets/image_dropdown.png')
export const nextIcon = require("../../assets/right-arrow.png");
export const previousIcon = require("../../assets/left-arrow.png");
export const complexIcon=require('../../assets/complex.png')
export const buildingIcon=require('../../assets/building.png')
export const UploadIcon=require('../../assets/upload.png')
export const DeleteIcon=require('../../assets/delete_icon.png');
export const downloadIcon = require('../../assets/download.png');
export const Document = require("../../assets/document.png");
export const Close=require("../../assets/icon-close.png");
export const uploadbw=require("../../assets/upload-icon.png");