import React from "react";
// Customizable Area Start
import {
    Box,
    Button, DialogContentText, DialogTitle,
    Grid, Typography, Dialog, DialogActions, DialogContent,
} from "@material-ui/core";
import { desigenImg } from "../assets"
import CompEmpBuildingBlockController from "./CompEmpBuildingBlockController.web";
import { withTranslation } from "react-i18next";
import {withRouter} from 'react-router-dom'
import { Formik, Form } from "formik";
// @ts-ignore
import TickIcon from '../../assets/tick-circle-icon.png'
import { ModalPopup } from "../../../../components/src/ModalPopup";
import "../../assets/commonForm.css"
//@ts-ignore
import CompEmpBuildinglockStep1 from './CompEmpBuildingBlockStep1.web'
//@ts-ignore
import CompEmpBuildingBlockStep2 from "./CompEmpBuildingBlockStep2.web";
//@ts-ignore
import CompEmpBuildingBlockStep3 from "./CompEmpBuildingBlockStep3.web";
//@ts-ignore
import CompEmpBuildingBlockStep4 from "./CompEmpBuildingBlockStep4.web";
//@ts-ignore
import CompEmpBuildingBlockStep5 from "./CompEmpBuildingBlockStep5.web"
//@ts-ignore
import CompEmpBuildingBlockStep6 from "./CompEmpBuildingBlockStep6.web"
//@ts-ignore
import CompEmpBuildingBlockStep7 from "./CompEmpBuildingBlockStep7.web"

// Customizable Area End

class CompEmpBuildingBlock extends CompEmpBuildingBlockController {
    render() {
        // Customizable Area Start
        const { t,i18n } = this.props
        const {language} = i18n
        const { page, rowsPerPage, sampleData } = this.state;
        const startIndex = page * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        const displayedData = sampleData.slice(startIndex, endIndex);
        // Customizable Area End

        return (
            // Customizable Area Start
            <Box style={{ padding: '40px', paddingRight: '40px', backgroundColor: 'rgb(244, 247, 255)' }}>
                <Typography style={{ fontSize: '16px', display: 'flex' }}> Real Estate Companies /Company Details / <p style={{ color: '#2B6FED' }}>{t("Create New Individual Building")}</p></Typography>
                <br />
                <Typography style={{ fontSize: '32px', fontWeight: 'bold' }} className="bold-text"> {t("Create New Individual Building")}</Typography>
                <Box style={{ marginTop: "25px", display: 'flex', flexWrap: 'wrap' }}>
                    {(this.multiPleButtonHaderBuilding())}
                </Box>
                <Grid className="topBarFormik" >
                    <Formik
                        initialValues={{
                            email: "",
                            password: "",
                            userType: ' ',
                            showPassword: false,
                            stayIn: false,
                            commonArea: " ",
                        }}
                        validateOnMount={true}
                        onSubmit={(values) => {
                        }}
                    >
                        {({ values, touched, errors, isValid, setFieldValue, handleChange }) => (
                            <Form translate="yes" className="commonForms">
                                {this.state.stepBuilding === 0 && <CompEmpBuildinglockStep1 
                                                jurisdictionList={this.state.jurisdictionList} jurisdictionSelected={this.state.jurisdictionSelected} handleChangeBuilding={this.handleChangeBuilding}
                                                stepBuilding={this.state.stepBuilding}
                                                handleNext={this.handleNextBuilding}
                                                handleBack={this.handleBackBuilding}
                                                multipleButton={this.multipleButton}
                                                t={t}
                                                language={language}
                                />}
                                {this.state.stepBuilding === 1 && <CompEmpBuildingBlockStep2
                                stepBuilding={this.state.stepBuilding}
                                leaseList={this.state.leaseList}
                                handleBasicDetails={this.handleBasicDetails}
                                imgUplodeAvtarBuilding={this.imgUplodeAvtarBuilding}
                                uploadImageName={this.uploadImageName}
                                buildingValues={this.state.buildingValues}
                                imagesComplex={this.state.imagesComplex}
                                handleChangeBuilding={this.handleChangeBuilding}
                                desigenImg={desigenImg}
                                currencyList={this.state.currencyList}
                                handleChangeCurrency={this.handleChangeCurrency}
                                currencySelected={this.state.currencySelected}
                                measurementList={this.state.measurementList}
                                handleChangeMeasurement={this.handleChangeMeasurement}
                                measurementSelected={this.state.measurementSelected}
                                multipleButton={this.multipleButton}
                                timezonelist={this.state.timezoneList}
                                handleChangeTimezone={this.handleChangeTimezone}
                                timezoneSelected={this.state.timezoneSelected}
                                formulaList={this.state.formulaList}
                                handleChangeFormula={this.handleChangeFormula}
                                formulaSelected={this.state.formulaSelected}
                                handleBackBuilding={this.handleBackBuilding}
                                handleNextBuilding={this.handleNextBuilding}
                                handleFieldChange={this.handleFieldChange}
                                handleTotalAreaUnit={this.handleTotalAreaUnit}
                                totalAreaUnit={this.state.totalAreaUnit}
                                handleOpenLeasePopup={this.handleOpenLeasePopupBuilding}
                                 handleCloseLeasePopup={this.handleCloseLeasePopupBuilding}
                                 leasePopup={this.state.leasePopup}
                                leaseSelected={this.state.leaseSelected}
                                 rentalSelected={this.state.rentalSelected}
                                 handleSelectLease={this.handleSelectLeaseBuilding}
                                 accountType={this.state.accountType}
                                 accountManagerList={this.state.accountManagerListBuilding}
                                 t={t}
                                 language={language}
                                />}
                                {this.state.stepBuilding === 2 && <CompEmpBuildingBlockStep3 
                                    stepBuilding={this.state.stepBuilding} 
                                    handleFileChangesBuilding={this.handleFileChangesBuilding}
                                    selectedFilePdfComplex={this.state.selectedFilePdfComplex}
                                    handleRemoveFileBuilding={this.handleRemoveFileBuilding}
                                    selectedFilePdfGuidelinesComplex={this.state.selectedFilePdfGuidelinesComplex}
                                    handleRemoveFileGuidelinesBuilding={this.handleRemoveFileGuidelinesBuilding}
                                    uploadGuidelinesBuilding={this.uploadGuidelinesBuilding}
                                    handleFileChangesGuidelinesBuilding={this.handleFileChangesGuidelinesBuilding}
                                    selectedFilePdfRolesComplex={this.state.selectedFilePdfRolesComplex}
                                    handleRemoveFileRolesBuilding={this.handleRemoveFileRolesBuilding}
                                    uploadNameBuilding={this.uploadNameBuilding}
                                    handleFileChangesRolesBuilding={this.handleFileChangesRolesBuilding}
                                    selectedFilePdfResolutionsComplex={this.state.selectedFilePdfResolutionsComplex}
                                    handleRemoveFileResolutionsBuilding={this.handleRemoveFileResolutionsBuilding}
                                    uploadResolutionsBuilding={this.uploadResolutionsBuilding}
                                    handleFileChangesResolutionsBuilding={this.handleFileChangesResolutionsBuilding}
                                    selectedFilePdfBuildingPlansComplex={this.state.selectedFilePdfBuildingPlansComplex}
                                    handleRemoveFileBuildingPlansBuilding={this.handleRemoveFileBuildingPlansBuilding}
                                    multipleButton={this.multipleButton}
                                    handleNext={this.uploadDocuments}
                                    handleBackBuilding={this.handleBackBuilding}
                                    handleFileChangesBuildingPlansBuilding={this.handleFileChangesBuildingPlansBuilding}
                                    t={t}
                                    language={language}
                                    />}
                                {this.state.stepBuilding === 3 && <CompEmpBuildingBlockStep4 
                                stepBuilding={this.state.stepBuilding}
                                handleChangeBuilding={this.handleChangeBuilding}
                                countriesList={this.state.countriesList}
                                countrySelected={this.state.countrySelected}
                                multipleButton={this.multipleButton}
                                handleCountryChange={this.handleCountryChange}
                                locationValues={this.state.locationValues}
                                handleLocationDetails={this.handleLocationDetails}
                                handleBackBuilding={this.handleBackBuilding}
                                handleLocationFieldChange={this.handleLocationFieldChange}
                                accountType={this.state.accountType}
                                t={t}
                                language={language}
                                />}
                                {this.state.stepBuilding === 4 && <CompEmpBuildingBlockStep5
                                stepBuilding={this.state.stepBuilding}
                                sampleData={this.state.sampleData}
                                togglePopupBuilding={this.togglePopupBuilding}
                                multipleButton={this.multipleButton}
                                isPopupOpen={this.state.isPopupOpen}
                                unitValues={this.state.unitValues}
                                unitRecords={this.state.unitRecords}
                                handleUnitDetails={this.handleUnitDetails}
                                accountType={this.state.accountType}
                                ownerValues={this.state.ownerValues}
                                ownerPopup={this.state.ownerPopup}
                                handleOwnerPopup={this.handleOwnerPopup}
                                t={t}
                                language={language}
                                /> }
                                {this.state.stepBuilding === 5 && <CompEmpBuildingBlockStep6 
                                stepBuilding={this.state.stepBuilding}
                                multipleButton={this.multipleButton}
                                sharedFacilityPopupBuilding={this.sharedFacilityPopupBuilding}
                                facilityValues={this.state.facilityValues}
                                facilityPopupBuilding={this.state.facilityPopupBuilding}
                                handleImageSharedChangeBuilding={this.handleImageSharedChangeBuilding}
                                imagesSharedFacilityBuilding={this.state.imagesSharedFacilityBuilding}
                                selectedFilePdfFloorPlanBuilding={this.state.selectedFilePdfFloorPlanBuilding}
                                handleRemoveFileFloorPlanComplex={this.handleRemoveFileFloorPlanComplex}
                                handleFileChangesFloorPlanBuilding={this.handleFileChangesFloorPlanBuilding}
                                savedFacility={this.state.savedFacility}
                                handleSharedDetails={this.handleSharedDetails}
                                handleRemoveFacilityDetails={this.handleRemoveFacilityDetails}
                                deleteFacilityPopup={this.state.deleteFacilityPopup}
                                                handleOpenDeleteFacilityPopup={this.handleOpenDeleteFacilityPopup}
                                                handleCloseDeleteFacilityPopup={this.handleCloseDeleteFacilityPopup}
                                                deleteFacilityIndex={this.state.deleteFacilityIndex}
                                />}
                                {this.state.stepBuilding === 6 && <CompEmpBuildingBlockStep7 
                                stepBuilding={this.state.stepBuilding}
                                multipleButton={this.multipleButton}
                                coreMemberManager={this.state.coreMemberManager}
                                sharedCoreMembers={this.sharedCoreMembers}
                                coreMembers={this.state.coreMembers}
                                sharedCreateNewMember={this.sharedCreateNewMember}
                                createNewMember={this.state.createNewMember}
                                handleChangeBuilding={this.handleChangeBuilding}
                                handleSave={this.handleSave}
                                handleNext={this.handleNextBuilding}
                                handleBack={this.handleBackBuilding}
                                userList={this.state.userList}
                                userValues={this.state.userValues}
                                roleList={this.state.roleList}
                                buildingList={this.state.buildingList}
                                memberValues={this.state.memberValues}
                                savedMemberBuilding={this.state.savedMemberBuilding}
                                handleMemberDetailsBuilding={this.handleMemberDetailsBuilding}
                                handleCloseDeleteMemberPopupBuilding={this.handleCloseDeleteMemberPopupBuilding}
                                handleOpenDeleteMemberPopupBuilding={this.handleOpenDeleteMemberPopup}
                                handleRemoveMemberBuilding={this.handleRemoveMemberBuilding}
                                deleteMemberIndexBuilding={this.state.deleteMemberIndexBuilding}
                                deleteMemberPopupBuilding={this.state.deleteMemberPopupBuilding}
                                handleBasicDetailsData={this.handleBasicDetailsData}
                                />}
                            </Form>
                        )}
                    </Formik>
                </Grid >

                <ModalPopup
          title={t("New Individual Building Created")}
          description={this.state.accountType === "coo" ? t("Individual Building has been successfully created on the platform.") :
            t("Approval request has been set to the COO for approval. Upon approval the complex will be added to the platform.")}
          open={this.state.createConfirmModal}
          closeFn={this.handleCloseCreateModal}
          primaryAction={this.handleCloseCreateModal}
          primaryLabel={t("OKAY")}
          icon={TickIcon}
        />
            </Box >
            // Customizable Area End
        );
    }
}

//@ts-ignore
export default withTranslation()(CompEmpBuildingBlock);
// Customizable Area Start
const webStyle = {
    buttonSave: {
        width: '177px',
        height: '56px',
        backgroundColor: '#2B6FED',
        borderRadius: "8px",
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        color: "#FFFFFF",
        fontWeight: 'bold',
        padding: '0px 20.5px',
        textTransform: 'capitalize'
    },
}
// Customizable Area End