// Customizable Area Start
import React from "react";
import { Box, Input, InputAdornment } from "@material-ui/core";
import { SearchIcon } from "../assets";

const SearchInput = ({ t, language, label, ...props }: any) => {
  return (
    <Box className="input_icon__form_box input_box__search_filter">
      <Input
        className="input_box"
        fullWidth
        placeholder={t(label)}
        {...props}
        startAdornment={
          <InputAdornment position="start">
            <img src={SearchIcon} />
          </InputAdornment>
        }
      />
    </Box>
  );
};

export default SearchInput;
// Customizable Area End
