// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import CEPropertyManagerReportController, { Props } from "./CEPropertyManagerReportController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import SearchInput from "../../../../components/src/CommonComponents/SearchInput.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import { Menu } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import ReportCard from "../../../../components/src/DashboardCard/ReportCard.web";
import { MultiUserImage } from "../assets";
// Customizable Area End

class CEPropertyManagerReport extends CEPropertyManagerReportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />

        <CEHeaderComponentWeb>
          <Container className={classes.PropertyManager}>
            <Box className="navigation">
              <Typography variant="body1" className="">
                <Link href="/CompanyEmployee/dashboard">
                  {t("Documents & Reports")} / {t("Reports")}
                </Link>{" "}
                /{" "}
                <Box component="span" style={{ color: "blue" }}>
                  {t("Property Managers Reports")}
                </Box>
              </Typography>
              <Box className="sub-heading-box">
                <Typography variant="h5" className="bold-text">
                  {t("Property Managers Reports")}
                </Typography>
              </Box>
            </Box>

            <Grid container spacing={2}>
              <Grid item sm={5}>
                <ReportCard
                  image={MultiUserImage}
                  heading={t("Average units per property manager")}
                  value={`${this.state.avgPMUnit}`}
                />
              </Grid>
              <Grid item sm={5}>
                <ReportCard
                  image={MultiUserImage}
                  heading={t("Active property manager")}
                  value={`${this.state.activePM}%`}
                />
              </Grid>
            </Grid>

            <Box className="top-bar-filter">
              <Box className="filter-head-box">
                <FilterSelect
                  t={t}
                  language={language}
                  label="Select Country"
                  value={this.state.filter.country}
                  onChange={(e: any) => this.handleReportFilterChange(e, "country")}
                  option={[{ label: "All", value: "all" }, ...this.state.countriesList]}
                />
                <FilterSelect
                  t={t}
                  language={language}
                  label="Select City"
                  value={this.state.filter.city}
                  onChange={(e: any) => this.handleReportFilterChange(e, "city")}
                  option={[{ label: "All", value: "all" }, ...this.state.citiesList]}
                />
                <FilterSelect
                  t={t}
                  language={language}
                  option={[
                    { label: "All", value: 0 },
                    { label: "Complex", value: 1 },
                    { label: "Individual Building", value: 2 },
                  ]}
                  value={this.state.filter.isComplex}
                  onChange={(e: any) => this.handleReportFilterChange(e, "isComplex")}
                  style={{ fontWeight: "bold", color: "black" }}
                />
                <FilterSelect
                  t={t}
                  value={this.state.filter.complex}
                  language={language}
                  label={this.state.filter.isComplex !== 2 ? "Select Complex" : "Select Individual Building"}
                  option={[{ label: "All", value: "all" }, ...this.state.filterReportComplexList]}
                  onChange={(e: any) => this.handleReportFilterChange(e, "complex")}
                />
                {this.state.filter.isComplex !== 2 && (
                  <FilterSelect
                    t={t}
                    language={language}
                    value={this.state.filter.building}
                    label="Select Building"
                    option={[{ label: "All", value: "all" }, ...this.state.buildingList]}
                    onChange={(e: any) => this.handleReportFilterChange(e, "building")}
                  />
                )}
                <FilterSelect
                  t={t}
                  language={language}
                  label="Select Property Manager"
                  option={[{ label: "All", value: "all" }, ...this.state.propertyManagerList]}
                  value={this.state.filter.propertyManager}
                  onChange={(e: any) => this.handleReportFilterChange(e, "propertyManager")}
                />
                <FilterSelect
                  t={t}
                  language={language}
                  label="Select Sales Manager"
                  option={[{ label: "All", value: "all" }, ...this.state.salesManagerList]}
                  value={this.state.filter.salesManager}
                  onChange={(e: any) => this.handleReportFilterChange(e, "salesManager")}
                />
                <FilterSelect
                  t={t}
                  language={language}
                  label="Select Account Manager"
                  option={[{ label: "All", value: "all" }, ...this.state.accountManagerList]}
                  value={this.state.filter.accountManager}
                  onChange={(e: any) => this.handleReportFilterChange(e, "accountManager")}
                />
                <Button
                  className="action-search-btn"
                  startIcon={<SearchIcon />}
                  onClick={this.getPropertyManagerReportList}
                >
                  {t("Search")}
                </Button>
              </Box>
            </Box>

            <Grid className="table-box-card">
              <Grid item sm={12} md={12} xs={12}>
                <Box className="table-top">
                  <h4 className="bold-text">{t("List of property managers")}</h4>
                  <Box className="filter-head-box" style={{ margin: "0" }}>
                    <FilterSelect
                      t={t}
                      value={this.state.sortBy}
                      language={language}
                      label="Sort By"
                      option={[
                        { label: "Total Owners", value: "total_owner" },
                        { label: "Total Units", value: "unit" },
                        { label: "Total Rent Generated", value: "rent" },
                      ]}
                      onChange={(e: any) =>
                        this.setState({ sortBy: e.target.value }, () => this.getPropertyManagerReportList())
                      }
                    />
                    <SearchInput
                      onChange={(e: any) =>
                        this.setState({ search: e.target.value }, () => this.getPropertyManagerReportList())
                      }
                      t={t}
                      value={this.state.search}
                      language={language}
                      label="Search by name"
                    />
                  </Box>
                </Box>
                <Divider />
                <Table className="table-box">
                  <TableHead>
                    <TableRow>
                      <TableCell align={languageCondition(language, "right", "left")}>#</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Unique ID")}
                      </TableCell>
                      <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                        {t("Property Manager Name")}
                      </TableCell>
                      <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                        {t("Total Owners")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} className="bold-text">
                        {t("Total Units")}
                      </TableCell>
                      <TableCell className="bold-text" align={languageCondition(language, "right", "left")}>
                        {t("Total Rent Generated")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.pmReportList.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={5}>{t("No Report Available!!")}</TableCell>
                      </TableRow>
                    )}
                    {this.state.pmReportList.map((manager: any, index: number) => {
                      return (
                        <TableRow key={manager.id}>
                          <TableCell align={languageCondition(language, "right", "left")}>{index + 1}</TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>{manager.id}</TableCell>
                          <TableCell align={languageCondition(language, "right", "left")} className="ellipse-one">
                            {manager.attributes.full_name}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {manager.attributes.total_owners}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {manager.attributes.total_units}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {manager.attributes.currency} {manager.attributes.total_rents}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")} className="ce-pm-report-menu">
                            <Menu
                              menuButton={
                                <IconButton>
                                  <MoreVertIcon />
                                </IconButton>
                              }
                            >
                              <MenuItem
                                className="ce-pm-view-report"
                                onClick={() =>
                                  this.props.navigation.navigate("CEPropertyManagerReportDetails", { id: manager.id })
                                }
                              >
                                {t("View Details")}
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <Divider />
                <Box className="table-bottom">
                  <p>
                    {t("Showing")} <span className="current-page">{this.state.pmReportList.length}</span> {t("of")}{" "}
                    <span className="total-page">{this.state.pagination ? this.state.pagination.total_count : 0}</span>{" "}
                    {t("results")}
                  </p>
                  {this.state.pagination && (
                    <Pagination
                      count={this.state.pagination.total_pages}
                      page={this.state.pagination.current_page}
                      siblingCount={2}
                      variant="outlined"
                      shape="rounded"
                      onChange={this.handleReportPagination}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </CEHeaderComponentWeb>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const CEReportStyle: any = {
  PropertyManager: {
    background: "rgb(244, 247, 255)",
    "& .navigation": {
      flexDirection: "column",
      alignItems: "flex-start",
      display: "flex",
      "& .sub-heading-box": {
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
        marginTop: "10px",
        display: "flex",
        "& h5": {
          fontWeight: "600",
          fontSize: "28px",
        },
      },
    },
    "& .table-main-heading": {
      alignItems: "center",
      display: "flex",
      gap: "10px",
      "& .map-list-icon-box": {
        border: "1px solid #f1f1f1",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        "& .map-list-active": {
          background: "#dbe7fc",
          color: "#376bd0",
          borderRadius: "6px",
          padding: "5px",
        },
        "& .map-list-inactive": {
          color: "#afb0b4",
          padding: "5px",
          cursor: "pointer",
        },
      },
    },
    "& .report-map-box": {
      width: "100%",
      height: "500px",
    },
  },
};

export default withTranslation()(withStyles(CEReportStyle)(withRouter(CEPropertyManagerReport)));
// Customizable Area End
