// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Link,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import CETotalSentInviteController, { Props } from "./CETotalSentInviteController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { CERequestManagementStyle } from "./CERequestManagement.web";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import { LanguageAttributeHandler } from "../../../../components/src/UtilFunction.web";
import { Menu } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Pagination from "@material-ui/lab/Pagination";
// Customizable Area End

class CETotalSentInvite extends CETotalSentInviteController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <CEHeaderComponentWeb>
          <Container className={classes.RequestManagement}>
            <Box className="navigation total-invite-head-box">
              <Typography variant="body1" className="total-invite-heading">
                <Link href="/CompanyEmployee/dashboard">{t("Community Management")}</Link> /{" "}
                <Link href="/CompanyEmployee/RequestManagement">{t("Requests Management")}</Link> /{" "}
                <Box component="span" style={{ color: "blue" }} className="total-invite-head">
                  {t("Total Sent Invitations")}
                </Box>
              </Typography>
              <Box className="sub-heading-box total-invite-sub-head">
                <Typography variant="h5" className="bold-text total-invite-text">
                  {t("Total Sent Invitations")}
                </Typography>
              </Box>
            </Box>

            <Box className="filter-head-box total-invite-filter-box">
             <FilterSelect
                t={t}
                language={language}
                value={this.state.search.selectedIndividualBuilding}
                onChange={(e: any) => this.handleChangeValue(e, "selectedIndividualBuilding")}
                label={t("Complex")}
                option={[{ label: "All", value: "all" }, ...this.state.complexBuildingFilterList]}
              />
              <FilterSelect
                t={t}
                language={language}
                value={this.state.search.selectedCountry}
                onChange={(e: any) => this.handleChangeValue(e, "selectedCountry")}
                label={t("Select Country")}
                option={[{ label: "All", value: "all" }, ...this.state.countryDataList]}
              />
             <FilterSelect
                t={t}
                language={language}
                value={this.state.search.selectedCity}
                onChange={(e: any) => this.handleChangeValue(e, "selectedCity")}
                label={t("Select City")}
                option={[{ label: "All", value: "all" }, ...this.state.cityDataList]}
              />
              <FilterSelect
                t={t}
                language={language}
                value={this.state.search.selectedComplex}
                onChange={(e: any) => this.handleChangeValue(e, "selectedComplex")}
                label={t("Select Complex")}
                option={[{ label: "All", value: "all" }, ...this.state.complexDataList]}
              />
              <FilterSelect
                t={t}
                language={language}
                value={this.state.search.selectedBuilding}
                onChange={(e: any) => this.handleChangeValue(e, "selectedBuilding")}
                label={t("Select Building")}
                option={[{ label: "All", value: "all" }, ...this.state.buildingsDataList]}
              />
              <FilterSelect
                t={t}
                language={language}
                value={this.state.search.selectedStatus}
                onChange={(e: any) => this.handleChangeValue(e, "selectedStatus")}
                label={t("Select Status")}
                option={[{ label: "All", value: "all" }, ...this.state.statusData]}
              />
              <Button className="action-search-btn action-user-search" startIcon={<SearchIcon />} onClick={() => {this.getTotalSentInvitationFilterList()}}>
                {t("Search")}
              </Button>
            </Box>

            <Grid className="table-box-card">
              <Grid item sm={12} md={12} xs={12}>
                <Box className="table-top">
                  <h4 className="bold-text">{t("Complex")} {this.state.complexName && `: ${this.state.complexName}`}</h4>
                  <div className="search-box">
                    <SearchIcon />
                    <InputBase
                      placeholder={t("Search")}
                      className="search meeting-minute-search-title"
                      onChange={(e)=>{this.onChange(e)}}
                    />
                  </div>
                </Box>
                <Divider />
                <Table className="table-box">
                  <TableHead>
                    <TableRow>
                      <TableCell align={languageCondition(language, "right", "left")}>#</TableCell>
                      <TableCell 
                      align={languageCondition(language, "right", "left")}
                       className="bold-text">
                         {t("Name")}
                      </TableCell>
                      <TableCell 
                      align={languageCondition(language, "right", "left")}
                       className="bold-text">
                         {t("Unit Number")}
                      </TableCell>
                      <TableCell 
                      align={languageCondition(language, "right", "left")} 
                      className="bold-text">
                         {t("Invitation sent on")}
                      </TableCell>
                      <TableCell 
                      align={languageCondition(language, "right", "left")} className="bold-text">
                         {t("Phone Number")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}
                       className="bold-text">
                         {t("Onboarding Status")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.totalSentInvitationList.map((item: any, i: number) => (
                      <TableRow>
                        <TableCell align={languageCondition(language, "right", "left")}>{i+1}</TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="ellipse-one" title="">
                          {item.attributes.full_name}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")} className="ellipse-one">
                        {item.attributes?.apartment_management?.apartment_name||"-"}
                        </TableCell>
                        <TableCell align={languageCondition(language, "right", "left")}>{this.dateFormat(item.attributes.created_at)}</TableCell>
                        <TableCell align={languageCondition(language, "right", "left")}>{item.attributes.phone_number}</TableCell>
                        <TableCell align={languageCondition(language, "right", "left")}>{item.attributes.status}</TableCell>
                        <TableCell
                          align={languageCondition(language, "right", "left")}
                          className={LanguageAttributeHandler(language, "meeting-menu", "")}
                        >
                          <Menu
                            menuButton={
                              <IconButton>
                                <MoreVertIcon />
                              </IconButton>
                            }
                          >
                            <MenuItem>{t("View")}</MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Divider />
                <Box className="table-bottom">
                  <p>
                    {t("Showing")} <span className="current-page">{this.state.totalSentInvitationList.length}</span> {t("of")}{" "}
                    <span className="total-page">{this.state.pagination?.total_count}</span> {t("results")}
                  </p>
                  {this.state.pagination && (
                  <Pagination
                  onChange={(event: any, value: any) => {
                   this.handleFilterPage(value)
                  }}
                    siblingCount={2}
                    variant="outlined"
                    shape="rounded"
                    count={this.state.pagination?.total_pages}
                    page={this.state.pagination?.current_page}
                  />)}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </CEHeaderComponentWeb>
        <Loader loading={this.state.loading} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// @ts-ignore
export default withTranslation()(withStyles(CERequestManagementStyle)(withRouter(CETotalSentInvite)));
// Customizable Area End
