import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Grid, Typography
} from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {Formik, Form, Field, ErrorMessage} from "formik";
import * as Yup from 'yup';
import "../../assets/commonForm.css"
//@ts-ignore
import CompEmpComplexBuildingBlockStep1Controller from "./CompEmpComplexBuildingBlockStep1Controller.web";
// Customizable Area End

const validationSchema = Yup.object().shape({
    buildingName: Yup.string().min(3, 'Company name must be at least 3 characters')
        .max(50, 'Complex name must be at most 50 characters').required('Complex Name is required'),
    totalArea: Yup.string().required('Total Area is required'),
    totalFloors: Yup.string().required('Total Floor is required'),
    totalUnits: Yup.string().required('Total Units is required'),
    aboutUs: Yup.string().required('About Us is required'),
    locationLink: Yup.string().required('Location Link is required'),

    logo: Yup.mixed().required('Please upload a logo'),
    images: Yup.array()
        .min(1, 'At least one image is required')
        .max(6, 'Maximum of 6 images allowed')
        .required('Please upload images')
});

export default class CompEmpComplexBuildingBlockStep1 extends CompEmpComplexBuildingBlockStep1Controller {
    render() {
        // Customizable Area Start

        const {t} = this.props;

        // Customizable Area End

        return (
            // Customizable Area Start
            <Formik
                initialValues={this.props.buildingValues}
                validationSchema={validationSchema}
                validateOnMount={true}
                onSubmit={(values) => {

                    this.props.handleBasicDetails(values)
                }}
            >
                {({values, touched, errors, isValid, setFieldValue, handleChange}) => (

                    <Form translate="yes" className="commonForms complex_building_block_step1">
                        <Box>
                            <Box className="complex complex_building_block_step1">
                                <Typography className="complex_building_block_step1" style={{
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    paddingBottom: '25px',
                                    borderBottom: '1px solid #F0F0F0'
                                }}>Complex 1</Typography>
                            </Box>
                            <Box className="complex_building_block_step1" style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingTop: '25px'
                            }}>
                                <Box className="complex_building_block_step1">
                                    <Typography className="complex_building_block_step1 bold-text"
                                                style={{fontSize: '25px', fontWeight: 'bold'}}>{t("Basic Details")}</Typography>
                                </Box>
                                <Box className="complex_building_block_step1">
                                    <Typography className="complex_building_block_step1"
                                                style={{fontSize: '16px'}}>Step {this.props.step + 1} of 5</Typography>
                                </Box>
                            </Box>
                            <Box className="step2forms complex_building_block_step1" style={{
                                marginTop: '28px', textAlign: 'center', display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                {
                                    values.logo ? <img data-testid="preview_img"
                                                       src={URL.createObjectURL(values.logo)}
                                                       className="complex_building_step1"
                                                       alt="Preview" style={{
                                        width: '66px',
                                        height: '66px',
                                        borderRadius: '50%',
                                        background: '#dcdcdc'
                                    }}/> : <Box className="complex_building_step1" style={{
                                        width: '66px',
                                        height: '66px',
                                        borderRadius: '50%',
                                        background: '#dcdcdc'
                                    }}>
                                    </Box>
                                }

                                <label htmlFor="logoChange" className="complex_building_step1"><span style={{
                                    color: '#FC8434'
                                }} className="bold-text complex_building_block_step1">
                          {values.logo ? <>Change Logo</> : <>Add Logo</>}
                        </span></label>
                                <input type="file"
                                       accept="image/*"
                                       id="logoChange"
                                       className="complex_building_step1"
                                       name="logo" data-testid="logoUpload" onChange={(event: any) => {
                                    setFieldValue('logo', event.currentTarget.files[0])
                                }}
                                       style={{display: 'none'}}/>
                                <Box style={webStyle.errorMsg}><ErrorMessage name="logo" component="div"
                                                                             data-testid="logo-error"/></Box>
                            </Box>
                            <Box style={{marginTop: '30px'}} className="complex_building_block_step1">
                                <Box className="boxDisplayFlex boxDisplayFlexNoWrap complex_building_block_step1"
                                     style={{
                                         display: 'flex',
                                         justifyContent: 'space-between'
                                     }}>
                                    <Box className="complex_building_block_step1" style={{width: '49%'}}>
                                        <Typography className="typographyLable complex_building_block_step1">Building
                                            Name</Typography>
                                        <Box>
                                            <Box className="formGroups complex_building_block_step1" style={{
                                                marginBottom: "0px",
                                                border: "1px solid #F0F0F0",
                                                borderRadius: "8px",
                                                backgroundColor: "#F0F0F0"
                                            }}>
                                                <Field name="buildingName" type="text" placeholder="Building Name"
                                                       className="formInputs complex_building_block_step1"/>
                                                <span className="frmLeftIcons complex_building_block_step1 step2">
                                                        </span>
                                            </Box>
                                        </Box>
                                        <Box style={webStyle.errorMsg}><ErrorMessage name="buildingName" component="div"
                                                                                     data-testid="buildingName-error"/></Box>
                                    </Box>
                                    <Box style={{width: '49%'}}>
                                        <Typography className="typographyLable complex_building_block_step1">Total
                                            Area</Typography>
                                        <Box>
                                            <Box className="formGroups complex_building_block_step1" style={{
                                                marginBottom: "0px",
                                                border: "1px solid #F0F0F0",
                                                borderRadius: "8px",
                                                backgroundColor: "#F0F0F0"
                                            }}>
                                                <Field name="totalArea" type="text" placeholder="Total Area"
                                                       className="formInputs complex_building_block_step1"/>
                                                <span className="frmLeftIcons complex_building_block_step1 step2">
                                                        </span>
                                            </Box>
                                        </Box>
                                        <Box style={webStyle.errorMsg}><ErrorMessage name="totalArea" component="div"
                                                                                     data-testid="totalArea-error"/></Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{marginTop: '20px'}}>
                                <Typography className="typographyLable complex_building_block_step1">Upload
                                    Photos</Typography>
                                <Box style={{display: 'flex'}}>
                                    {values.images.length < 6 && (
                                        <>
                                            <label id="label-file-upload" htmlFor="input-file-upload"
                                                   style={{marginRight: '23px', flexWrap: 'wrap'}}>
                                                <div>
                                                    <button className="upload-button complex_building_block_step1">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                             viewBox="0 0 20 20" fill="none">
                                                            <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd"
                                                                  d="M9 3.40501L6.71 5.70501C6.45634 5.95867 6.08663 6.05774 5.74012 5.96489C5.39362 5.87205 5.12297 5.6014 5.03012 5.25489C4.93728 4.90839 5.03634 4.53867 5.29 4.28501L9.29 0.285014C9.3851 0.193973 9.49725 0.122608 9.62 0.0750135C9.86346 -0.0250045 10.1365 -0.0250045 10.38 0.0750135C10.5028 0.122608 10.6149 0.193973 10.71 0.285014L14.71 4.28501C14.8993 4.47278 15.0058 4.72838 15.0058 4.99501C15.0058 5.26165 14.8993 5.51725 14.71 5.70501C14.5222 5.89433 14.2666 6.00081 14 6.00081C13.7334 6.00081 13.4778 5.89433 13.29 5.70501L11 3.40501V12.995C11 13.5473 10.5523 13.995 10 13.995C9.44771 13.995 9 13.5473 9 12.995V3.40501ZM18 12.995C18 12.4427 18.4477 11.995 19 11.995C19.5523 11.995 20 12.4427 20 12.995V16.995C20 18.6519 18.6569 19.995 17 19.995H3C1.34315 19.995 0 18.6519 0 16.995V12.995C0 12.4427 0.447715 11.995 1 11.995C1.55228 11.995 2 12.4427 2 12.995V16.995C2 17.5473 2.44772 17.995 3 17.995H17C17.5523 17.995 18 17.5473 18 16.995V12.995Z"
                                                                  fill="black"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </label>

                                            <input type="file"
                                                   accept="image/*"
                                                   onChange={(event: any) => setFieldValue('images', [...values.images, ...event.currentTarget.files])}
                                                   style={{display: 'none'}}
                                                   id="input-file-upload"
                                                   name="images"
                                                   data-testid="step2Images"
                                                   multiple
                                            />
                                        </>
                                    )}

                                    <div className="image-preview complex_building_block_step1" style={{
                                        display: 'flex'
                                    }}>
                                        {values.images.map((image: any, index: number) => (
                                            <div style={{
                                                position: 'relative'
                                            }}>
                                                <img
                                                    style={{
                                                        height: '104px',
                                                        width: '172px',
                                                        marginRight: '20px',
                                                        objectFit: 'cover',
                                                        border: '1px solid #ececec'
                                                    }}
                                                    key={index}
                                                    src={URL.createObjectURL(image)}
                                                    alt={`Image ${index}`}
                                                />
                                                <HighlightOffIcon
                                                    className="faq_image_close_icon faq_image_close_icon2 step2forms complex_building_block_step1"
                                                    style={{
                                                        right: '25px',
                                                        position: 'absolute',
                                                        fill: 'red',
                                                        top: '5px',
                                                        cursor: 'pointer'
                                                    }}
                                                    data-testid="step2ImageRemove"
                                                    onClick={() => {
                                                        const newImagesStep1 = [...values.images];
                                                        newImagesStep1.splice(index, 1);
                                                        setFieldValue('images', newImagesStep1);
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </Box>
                                <Box style={webStyle.errorMsg}><ErrorMessage name="images" component="div"
                                                                             data-testid="images-error"/></Box>
                            </Box>
                            <Box style={{marginTop: '20px'}}>
                                <Box className="boxDisplayFlex boxDisplayFlexNoWrap complex_building_block_step1">
                                    <Box style={{width: '49%'}}>
                                        <Typography className="typographyLable complex_building_block_step1">Total
                                            Floors</Typography>
                                        <Box>
                                            <Box className="formGroups complex_building_block_step1" style={{
                                                marginBottom: "0px",
                                                border: "1px solid #F0F0F0",
                                                borderRadius: "8px",
                                                backgroundColor: "#F0F0F0"
                                            }}>
                                                <Field name="totalFloors" type="text" placeholder="Total Floors"
                                                       className="formInputs complex_building_block_step1"/>
                                                <span className="frmLeftIcons step2">
                                                        </span>
                                            </Box>
                                        </Box>
                                        <Box style={webStyle.errorMsg}><ErrorMessage name="totalArea" component="div"
                                                                                     data-testid="totalArea-error"/></Box>
                                    </Box>
                                    <Box style={{width: '49%'}}>
                                        <Typography className="typographyLable complex_building_block_step1">Total
                                            Units</Typography>
                                        <Box>
                                            <Box className="formGroups complex_building_block_step1" style={{
                                                marginBottom: "0px",
                                                border: "1px solid #F0F0F0",
                                                borderRadius: "8px",
                                                backgroundColor: "#F0F0F0"
                                            }}>
                                                <Field name="totalUnits" type="text" placeholder="Total Units"
                                                       className="formInputs complex_building_block_step1"/>
                                                <span className="frmLeftIcons step2 complex_building_block_step1">
                                                        </span>
                                            </Box>
                                        </Box>
                                        <Box style={webStyle.errorMsg}><ErrorMessage name="totalUnits" component="div"
                                                                                     data-testid="totalUnits-error"/></Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{marginTop: '20px'}}>
                                <Box className="boxDisplayFlex boxDisplayFlexNoWrap complex_building_block_step1">
                                    <Box style={{width: '100%'}}>
                                        <Typography className="typographyLable complex_building_block_step1">Location
                                            Link</Typography>
                                        <Box>
                                            <Box className="formGroups complex_building_block_step1" style={{
                                                marginBottom: "0px",
                                                border: "1px solid #F0F0F0",
                                                borderRadius: "8px",
                                                backgroundColor: "#F0F0F0"
                                            }}>
                                                <Field name="locationLink" type="text" placeholder="Location Link"
                                                       className="formInputs complex_building_block_step1"/>
                                                <span className="frmLeftIcons complex_building_block_step1 step2">
                                                        </span>
                                            </Box>
                                        </Box>
                                        <Box style={webStyle.errorMsg}><ErrorMessage name="locationLink" component="div"
                                                                                     data-testid="locationLink-error"/></Box>
                                    </Box>

                                </Box>
                            </Box>

                            <Box style={{marginTop: '20px'}}>
                                <Box style={{width: '100%'}}>
                                    <Typography className="typographyLable About complex_building_block_step1">About
                                        Us</Typography>
                                    <Box className="formGroups step2 complex_building_block_step1" style={{
                                        marginBottom: "0px",
                                        border: "1px solid #F0F0F0",
                                        borderRadius: "8px",
                                        backgroundColor: "#F0F0F0"
                                    }}>
                                        <Field placeholder="About Us" name="aboutUs" type="address"
                                               className="complex_building_block_step1 formInputs1" as="textarea"/>
                                        <span className="frmLeftIcons step2 complex_building_block_step1">
                                                </span>
                                    </Box>
                                    <Box style={webStyle.errorMsg}><ErrorMessage name="aboutUs" component="div"
                                                                                 data-testid="aboutUs-error"/></Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box style={{display: 'flex', justifyContent: "end", flexWrap: 'wrap'}}
                             className="complex_building_block_step1">
                            <Box className="complex_building_block_step1">
                                <Grid style={{marginTop: "30px",}} className="complex_building_block_step1">
                                    <Button className="complex_building_block_step1" type="submit" style={{
                                        width: '177px',
                                        height: '56px',
                                        backgroundColor: '#2B6FED',
                                        borderRadius: "8px",
                                        display: 'flex',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        color: "#FFFFFF",
                                        fontWeight: 'bold',
                                        padding: '0px 20.5px',
                                        textTransform: 'capitalize'
                                    }} data-test-id="handleNext"><span className="bold-text">NEXT</span></Button>
                                </Grid>
                            </Box>
                        </Box>

                    </Form>)}
            </Formik>

            // Customizable Area End
        );
    }
}

// export default withTranslation()(withRouter(CompEmpComplexBuildingBlock));
// Customizable Area Start
const webStyle = {
    errorMsg: {
        color: 'red',
        fontSize: '12px',
        marginTop: '5px'
    }
}
// Customizable Area End