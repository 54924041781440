// Customizable Area Start
import React from "react";
import LeaseManagementIssueNewContractAddTermsController, { AddMoreConditionData, Props } from "./LeaseManagementIssueNewContractAddTermsController.web";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import { Box, Breadcrumbs, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, Link, MenuItem, Modal, Paper, Select, TextField, Typography, styled } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withTranslation } from "react-i18next";
// Customizable Area End

// Customizable Area Start
type TFunction = (value: string) => string;
// Customizable Area End

class LeaseManagementIssueNewContractAddTerms extends LeaseManagementIssueNewContractAddTermsController {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area Start
    }

    // Customizable Area Start 
    renderAddMoreCondtion = () => {
        const { t }: { t: TFunction } = this.props;
        return <Modal
            open={this.state.addMoreConditionModal}
        >
            <ModalContainer >
                <Box className="addMoreConditionModalContainer">
                    <Box className="addMoreConditionModalHeader">
                        <Typography className="addMoreConditionModalHeading">{t("Add More Conditions")}</Typography>
                        <Button className="crossIcon" onClick={this.handleCloseAddMoreCondition}>X</Button>
                    </Box>
                    <Divider />
                    <Box className="addMoreConditionModalContent">
                        {this.state.addMoreConditionData.map((item: AddMoreConditionData, index: number) => {
                            return <Box key={index}>
                                <Typography className="addMoreConditionModalContentHeading">{item.title}</Typography>
                                {item.options.map((item: string, index: number) => {
                                    return <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label={item}
                                    />
                                })}
                            </Box>
                        })}
                        <Button className="addMoreCondtionBtn" onClick={this.handleOpenMoreTermsModal}>{t("Add more terms")}</Button>
                        <Box className="modalBottomSection">
                            <Button className="cancelBtn" onClick={this.handleCloseAddMoreCondition}>{t("Close")}</Button>
                            <Button variant="contained" className="continueBtn">
                                {t("Confirm")}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </ModalContainer>
        </Modal>
    }

    renderAddMoreTermsModal = () => {
        const { t }: { t: TFunction } = this.props;
        return <Modal open={this.state.addMoreTermsModal}>
            <ModalAddMoreTermsContainer>
                <Box className="addMoreTermsModalContainer">
                    <Box className="addMoreTermsModalHeader">
                        <Typography className="addMoreConditionModalHeading">{t("Add More Terms")}</Typography>
                        <Button className="crossIcon">X</Button>
                    </Box>
                    <Divider />
                    <Box className="addMoreTermsModalContentContainer">
                        <textarea placeholder="Add terms" className="addMoreTermsModalTextarea" />
                        <Box className="modalBottomSection">
                            <Button className="cancelBtn" onClick={this.handleCloseMoreTermsModal}>{t("Cancel")}</Button>
                            <Button variant="contained" className="continueBtn">
                                {t("Add")}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </ModalAddMoreTermsContainer>
        </Modal>
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { t }: { t: TFunction } = this.props;
        return (
            <MainScreen>
                <IssueNewContractContainer>
                    <Box>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href="/CompanyEmployee/LeaseManagment" className="notActiveBreadLink">
                                {t("Lease Management")}
                            </Link>
                            <Link
                                color="inherit"
                                className="notActiveBreadLink"
                                href="/CompanyEmployee/IssueNewLease"
                            >
                                {t("Issue New Lease")}
                            </Link>
                            <Typography
                                className="activeBreadLink">
                                {t("Issue a New Contract")}
                            </Typography>
                        </Breadcrumbs>
                        <Typography className="issueNewcontractHeading">
                            {t("Issue a New Contract")}
                        </Typography>
                    </Box>
                    <Paper
                        className="issueNewContractContentContainer" elevation={1} >

                        <Box className="issueNewContractHeaderContainer">
                            <Typography className="issueNewContractHeaderHeading">
                                {t("Add Terms")}
                            </Typography>

                        </Box>
                        <Divider />
                        <Box className="templateDetailsContainer">
                            <Typography className="templateHeading">Lorem Ipsum</Typography>
                            <Typography className="addTermsContent">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facere culpa perspiciatis iure amet, voluptatum a sed nostrum minus laudantium deleniti mollitia animi quisquam eveniet sequi quidem dicta porro? Facere, officia. Consequatur necessitatibus similique libero ducimus voluptatem assumenda reprehenderit tempore aperiam natus non, mollitia voluptatum ipsam provident quod fuga est ipsa facere voluptates architecto veritatis laborum aliquam laboriosam reiciendis minus! Dignissimos nemo molestiae possimus perferendis vero tenetur corporis deserunt minus culpa assumenda placeat excepturi distinctio, dicta perspiciatis, quo quidem! Quibusdam officia et tempora omnis ratione sunt a distinctio molestias placeat incidunt est fugiat ullam sequi, aut harum aperiam. Rerum, consectetur veritatis?</Typography>
                            <Box className="addTermsCheckboxContainer">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Penalty for late rent payments"
                                />
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl fullWidth variant="outlined">
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                IconComponent={ExpandMoreIcon}
                                                className="addTermstextfield"
                                                value={this.state.fixedPercentageOfRentAmount}
                                                displayEmpty
                                            >
                                                <MenuItem value={""}><em>Fixed percentage of rend amount</em></MenuItem>
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <TextField variant="outlined" fullWidth className="addTermstextfield" />
                                    </Grid>
                                </Grid>
                                <Button className="addMoreCondtionBtn" onClick={this.handleOpenAddMoreCondition}>{t("Add more conditions")}</Button>
                            </Box>
                        </Box>
                    </Paper>
                    <Box className="bottomButtonContainer">
                        <Button className="cancelBtn" onClick={this.handleBack} data-testId={"backBtnTestId"}>{t("Back")}</Button>
                        <Button variant="contained" className="continueBtn"
                            data-testId={"reviewContractBtnTestId"}
                            onClick={this.handleNext}
                        >
                            {t("Review Contract")}
                        </Button>
                    </Box>
                    {this.renderAddMoreCondtion()}
                    {this.renderAddMoreTermsModal()}
                </IssueNewContractContainer>
            </MainScreen>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(LeaseManagementIssueNewContractAddTerms);
export { LeaseManagementIssueNewContractAddTerms };
const IssueNewContractContainer = styled(Box)({
    backgroundColor: "#f4f7ff",
    padding: "50px",
    "& .notActiveBreadLink": {
        color: "#40434c",
    },
    "& .activeBreadLink": {
        color: "#729be0",
    },
    "& .issueNewcontractHeading": {
        color: "#40434c",
        fontSize: "32px",
        fontWeight: 900,
        margin: "10px 0px",
    },
    "& .issueNewContractContentContainer": {
        backgroundColor: "#fff",
        height: "63vh",
        borderRadius: "10px",
        margin: "20px 0px",
        overflow: "scroll",
    },
    "& .issueNewContractHeaderContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "20px",
        "@media(max-width:576px)": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
            padding: "20px",
        },
    },
    "& .issueNewContractHeaderHeading": {
        width: "100%",
        fontSize: "20px",
        fontWeight: 900,
        color: "#40434c",
    },

    "& .templateName": {
        color: "#242426",
        fontWeight: 800,
        fontSize: "14px",
    },

    "& .bottomButtonContainer": {
        display: "flex",
        gap: "30px",
        justifyContent: "flex-end",
        padding: "20px 0px",
    },
    "& .cancelBtn": {
        border: "1px solid #6686bb",
        padding: "10px 30px",
        color: "#557dc8 !important",
        fontWeight: "900",
        width: "250px"
    },
    "& .continueBtn": {
        padding: "10px 30px",
        backgroundColor: "#2b6fec",
        color: "#fff",
        fontWeight: 900,
        width: "250px"
    },
    "& .templateHeading": {
        fontSize: "17px",
        fontWeight: 900,
        color: "#40434c",
    },
    "& .templateDetailsContainer": {
        padding: "20px"
    },
    "& .addTermsCheckboxContainer": {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        flexDirection: "column",
        marginTop: "20px",
        gap: "20px"
    },
    "& .addMoreCondtionBtn": {
        color: "#ec924b",
        fontWeight: 800,
        fontSize: "16px",
    },
    "& .addTermsContent": {
        color: "#a2a2a2",
        fontSize: "16px",
    },
    "& .addTermstextfield": {
        borderRadius: "10px",
        background: "#f9f9f9 !important",
        border: "1px solid #f1f1f1",

    }
});
const ModalContainer = styled(Box)({
    width: "100%",
    height: "100vh",
    oveflow: "scroll",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .addMoreConditionModalContainer": {
        backgroundColor: "#fff !important",
        borderRadius: "20px",
        width: "650px",
    },
    "& .addMoreConditionModalHeader": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px"
    },
    "& .addMoreConditionModalHeading": {
        color: "#1a1b20",
        fontSize: "20px",
        fontWeight: 900
    },
    "& .crossIcon": {
        color: "1c1c1c",
        fontSize: "18px"
    },
    "& .addMoreConditionModalContent": {
        padding: "0px 20px"
    },
    "& .addMoreConditionModalContentHeading": {
        color: "1a1b20",
        fontSize: "18px",
        fontWeight: 800,
        padding: "10px"
    },
    "& .addMoreCondtionBtn": {
        color: "#ec924b",
        fontSize: "16px",
        fontWeight: 800
    },

    "& .cancelBtn": {
        border: "1px solid #6686bb",
        padding: "10px 30px",
        color: "#557dc8 !important",
        fontWeight: "900",
        width: "250px"
    },
    "& .continueBtn": {
        width: "200px",
        padding: "10px 30px",
        backgroundColor: "#2b6fec",
        color: "#fff",
        fontWeight: 900,
    },
    "& .modalBottomSection": {
        display: "flex",
        justifyContent: "flex-end",
        padding: "10px 0px",
        gap: "20px"
    },



})
const ModalAddMoreTermsContainer = styled(Box)({
    width: "100%",
    height: "100vh",
    oveflow: "scroll",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .addMoreTermsModalContainer": {
        backgroundColor: "#fff !important",
        borderRadius: "20px",
        width: "650px",
    },
    "& .addMoreTermsModalHeader": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px"
    },
    "& .addMoreConditionModalHeading": {
        color: "#1a1b20",
        fontSize: "20px",
        fontWeight: 900
    },
    "& .crossIcon": {
        color: "1c1c1c",
        fontSize: "18px"
    },
    "7 .addMoreTermsModalContainer": {
        padding: "20px",

    },
    "& .addMoreTermsModalTextarea": {
        height: "100px",
        width: "100%",
        padding: "10px"
    },
    "& .addMoreTermsModalContentContainer": {
        padding: "20px"
    },
    "& .cancelBtn": {
        border: "1px solid #6686bb",
        padding: "10px 30px",
        color: "#557dc8 !important",
        fontWeight: "900",
        width: "250px"
    },
    "& .continueBtn": {
        width: "200px",
        padding: "10px 30px",
        backgroundColor: "#2b6fec",
        color: "#fff",
        fontWeight: 900,
    },
    "& .modalBottomSection": {
        display: "flex",
        justifyContent: "flex-end",
        padding: "10px 0px",
        gap: "20px"
    },
})

// Customizable Area End