//Customizable Area Start
import React from "react";
import BuildingMainBlockController, {
    Props,
} from "./BuildingMainBlockController.web";
import '../../../dashboard/src/Company Employee/global.css';
import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";
import CompEmpBuildingBlock from "./CompEmpBuildingBlock.web"
import {withRouter} from 'react-router-dom'
import {withTranslation} from "react-i18next";

class BuildingMainBlock extends BuildingMainBlockController {
    constructor(props: Props) {
        super(props);
    }
    render() {

        const {navigation} = this.props
        const match:any= this.props.match;
        const id = match?.params?.id;
        const {i18n} = this.props;
        const language = i18n.language;

        if (localStorage.loginSuccessToken) {
            return (
                <>
                    <MainScreen language={language}>
                        <CompEmpBuildingBlock navigation={navigation} id={""} location={undefined} history={undefined} companyId={id} />
                    </MainScreen>
                </>
            );
        }
        else {
            window.location.href = '/CompanyEmployee/Login'
            return null
        }

    }
}

const webStyle = {
};

export default withTranslation()(withRouter(BuildingMainBlock))
//Customizable Area End


