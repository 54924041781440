import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

import { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}
// Customizable Area End

export interface S {
    // Customizable Area Start
    individualbuilding:string
    country:string
    selectCountry:string[]
    city:string
    selectCity:string[]
    complex:string
    selectComplex:string[]
    building:string
    selectBuilding:string[]
    sales:string
    selectSales:string[]
    accountManager:string
    selectAccountManager:string[]
    sort:string
    token: string
    ownerReports:any
    pagination: any
    count:number
    totalPage:number
    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class OwnersReportsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCountryApiId: string = "";
    getCityApiId: string = "";
    getComplexApiId: string = "";
    getBuildingApiId: string = "";
    ownerReportApiId: string = ""
    getSalesManagerApiId: string =""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            individualbuilding:'',
            country:'',
            selectCountry:[],
            city:'',
            selectCity:[],
            complex:'',
            selectComplex:[],
            building:'',
            selectBuilding:[],
            sales:'',
            selectSales:[],
            accountManager:'',
            selectAccountManager:[],
            sort:'',
            token:'',
            ownerReports:[],
            pagination:null,
            totalPage:0,
            count:1

        };
        // Customizable Area End


    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
             if (apiRequestCallId === this.getCountryApiId) {
                this.setState({ selectCountry: responseJson.data.countries })
            } else if (apiRequestCallId === this.getCityApiId) {
                this.setState({ selectCity: responseJson.data.cities })
            } else if (apiRequestCallId === this.getComplexApiId) {
                this.setState({ selectComplex: responseJson.societies })
            } else if (apiRequestCallId === this.getBuildingApiId) {
                this.setState({ selectBuilding: responseJson.data.building_list })
            } else if (apiRequestCallId === this.ownerReportApiId) {
                this.setState({ownerReports:responseJson.data,pagination:responseJson.meta.pagination,totalPage:responseJson.meta.pagination?.total_pages})
            } else if (apiRequestCallId === this.getSalesManagerApiId){
                this.setState({selectSales:responseJson.sales_manager_listing})
            } 
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        let token  = await getStorageData("loginSuccessToken");
        this.setState({token:token});
        this.handleCountryApi();
        this.handleCityApi();
        this.handleComplexApi();
        this.handleBuildingApi();
        this.ownerReportApi("",1)
        this.handleSalseApi()
      }
    handleIndividualChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        this.setState({ individualbuilding:  event.target.value as string })
    };

    handleCountryChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        this.setState({ country: event.target.value as string })
    };

    handleCityChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        this.setState({ city:  event.target.value as string })
    };

    handleComplexChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        this.setState({ complex:  event.target.value as string })
    };

    handleBuildingChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ building: value as string })
    };

    handleSalesChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ sales: value as string })
    }

    handleAccountChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ accountManager: value as string })
    }
    handleSortChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ accountManager: value as string })
    }

    handleCountryApi = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessaged = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCountryApiId = requestMessaged.messageId;

        requestMessaged.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.countryApiEndpoint
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessaged.id, requestMessaged);
        return true;
    };

    handleCityApi = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };

        const requestMessaged = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCityApiId = requestMessaged.messageId;

        requestMessaged.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.cityApiEndpoint
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessaged.id, requestMessaged);
        return true;
    };

    handleComplexApi = () => {

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessages = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getComplexApiId = requestMessages.messageId;

        requestMessages.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.complexApiEndpoin}`
        );
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessages.id, requestMessages);
        return true;
    };

    handleBuildingApi = () => {

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };

        const requestMessages = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBuildingApiId = requestMessages.messageId;

        requestMessages.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.buildingApiEndpoin
        );
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessages.id, requestMessages);
        return true;
    };

    handleSalseApi = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessaged = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSalesManagerApiId = requestMessaged.messageId;

        requestMessaged.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.salesManagerApiEndPoint
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessaged.id, requestMessaged);
        return true;
    }

    ownerReportApi = (value?:string,page?:number) => {

        const { city,  complex, building, country,sales,accountManager  } = this.state
        let userFilters: any = {
            city: city,
            complex_id: complex === "all" ? "" : complex,
            building_id: building === "all" ? "" : building,
            country: country === "all" ? "" : country,
            account_manager: accountManager === "all" ? "" : accountManager,
            sales_manager: sales === "all" ? "" : sales,
            page:page,
            per_page:"10",
            search:value
        }

        let initialEndPoint = `${configJSON.ownerReportsApiEndPoint}?`;
        let isFirstParam = true;

        for (let filter in userFilters) {
            if (userFilters[filter]) {
                if (!isFirstParam) {
                    initialEndPoint += '&';
                }
                initialEndPoint += `${filter}=${userFilters[filter]}`;
                isFirstParam = false;
            }
        }
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessaged = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.ownerReportApiId = requestMessaged.messageId;

        requestMessaged.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            initialEndPoint
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessaged.id, requestMessaged);
        return true;
    }

    handlePageChange = (_event:any,page:number) => {
        this.setState({count:page})
        this.ownerReportApi("",page)
    }

    handleFilter = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.ownerReportApi(value as string,1)
    }

   
    // Customizable Area End
}
