//Customizable Area Start
import React from "react";
import {
    Container,
    Box,
    Grid,
    Hidden,
} from "@material-ui/core";
import RealEstateCreateCompanyController, {
    Props,
} from './RealEstateCreateCompanyController.web'
import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";
import RealEstateCompanyForm from "./RealEstateCompanyForm.web";
import {withRouter} from 'react-router-dom'

class RealEstateCreateCompany extends RealEstateCreateCompanyController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { navigation } = this.props;
        const match:any= this.props.match;
        const id = match?.params?.id;

        return (
            <>
            <MainScreen>
            <RealEstateCompanyForm navigation={navigation} id={"company-register-form"}
                                    companyId={id}
                                    />
            </MainScreen>
            </>
        );
    }
}

const webStyle = {
};

export default withRouter(RealEstateCreateCompany)
//Customizable Area End