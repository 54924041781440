import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Grid, Typography, FormControl, Select, Checkbox, MenuItem, Modal, Fade, Backdrop
} from "@material-ui/core";
import { profile } from "../assets"
//@ts-ignore
import DeleteIcon from '../../assets/delete.png'
//@ts-ignore
import CompEmpComplexBlockStep7Controller from './CompEmpComplexBlockStep7Controller.web'
import { Formik, Form, Field, ErrorMessage } from "formik";
import "../../assets/commonForm.css"
import * as Yup from 'yup';
// Customizable Area End

const validationSchema = Yup.object().shape({
    userName: Yup.string().required('Username is required'),
    role: Yup.string().required('Role is required'),
  });

  const validationCreateSchema = Yup.object().shape({
    email: Yup.string().required('Email Address is required'),
    phone: Yup.string().required('Phone Number is required'),
    name: Yup.string().required('Full Name is required'),
    role: Yup.string().required('Phone Number is required'),
    buildingName: Yup.string().required('Building Name is required'),
    unit: Yup.string().required('Unit is required'),
  });

export default class CompEmpComplexBlockStep7 extends CompEmpComplexBlockStep7Controller {
    render() {
        // Customizable Area Start
        
        
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                                <Box>
                                    <Box style={{ marginTop: '20px' }}>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Box >
                                                <Typography className="Core bold-text" style={{ fontSize: '22px', fontWeight: 'bold' }}>Core Members</Typography>
                                            </Box>
                                            <Box>
                                                <Typography className="Corestep" style={{ fontSize: '16px' }}>Step {this.props.stepComplex + 1} of 7</Typography>
                                            </Box>
                                        </Box>
                                        <Box style={{ marginTop: '30px' }}>
                                            <Box >
                                                <Box style={{alignItems: 'center', display: 'flex',  flexWrap: "wrap", }}>
                                                    {this.props.savedMembers.length ? this.props.savedMembers?.map((value: any, index: any) => (
                                                        <Box style={index%3===0 ? { border: '1px solid #E4E4E4', height: '192px', width: '229px', borderRadius: '8px', padding: '20px', marginBottom: '20px' } : { border: '1px solid #E4E4E4', height: '192px', width: '229px', borderRadius: '8px', padding: '20px', marginLeft: '12px', marginBottom: '20px' }}>
                                                            <Box key={index} style={{}}>
                                                                <Box data-test-id="handleRemoveFileBuildingPlans" onClick={() => this.props.handleOpenDeleteMemberPopup(index)}  style={{ display: 'flex', justifyContent: 'end', cursor:'pointer' }} >
                                                                    <svg height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" width="14"  fill="none">
                                                                        <path d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z" fill="black" />
                                                                    </svg>
                                                                </Box>
                                                                <Box style={{ textAlign: 'center' }}>
                                                                    <Box>
                                                                        <img src={profile} />
                                                                    </Box>
                                                                    <Box style={webStyle.step7}>{value.label || '-'}</Box>
                                                                    <Box style={ webStyle.step7 }>{value.name || '-'}</Box>
                                                                    <Box style={{ justifyContent: 'center',display: 'flex'}}>
                                                                        <Box style={{ margin: '11px 0px', color: '#2B6FED', fontSize: '16px', fontWeight: 400, background: 'rgba(43, 111, 237, 0.08)', borderRadius: '14px', padding: '8px 22px', width: 'max-content' }}>{value.position || '-'}</Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    )) : <></>}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className="">
                                            <Box style={{ justifyContent: 'space-between', flexWrap: 'wrap', display: 'flex', width: '100%' }}>
                                                <Box style={{ marginTop: '20px', borderRadius: '8px',border: '1px solid #2B6FED', }}>
                                                    <Box style={{  cursor: "grabbing" , fontSize: '15px', fontWeight: 400, color: '#2B6FED', padding: '10px 25px',}} onClick={this.props.sharedCoreMembers} className="bold-text">+ ADD NEW MEMBER</Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box style={{
                                                position: 'fixed',
                                                height: '100%',
                                                display: this.props.coreMembers ? 'block' : 'none',
                                                top: 0,
                                                width: '100%',
                                                left: 0,
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                zIndex: 9999,
                                            }}>
                                                <Box style={{
                                                    overflow: 'auto',
                                                    borderRadius: '5px',
                                                    left: '50%',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    backgroundColor: '#fff',
                                                    transform: 'translate(-50%, -50%)',
                                                    width: '50%',
                                                }}>
                                                    <Formik
                        initialValues={this.props.userValues}
                        validationSchema={validationSchema}
                        validateOnMount={true}
                        enableReinitialize={true}
                        data-testid="step2Form"
                        onSubmit={(values) => {
                            this.props.handleBasicDetailsData(values)
                        }}
                    >
                        {({ values, touched, errors, isValid, setFieldValue, handleChange }) => (
                            <Form translate="yes" className="commonForms">
                                                    <Box>
                                                        <Box className="topBarName Member">
                                                            <Box style={{ fontSize: '22px', fontWeight: 400 }}>Add New Member</Box>
                                                            <Box onClick={this.props.sharedCoreMembers} style={{ cursor: "grabbing" }}>
                                                                <svg width="14"  xmlns=  "http://www.w3.org/2000/svg" fill="none" height="14" viewBox="0 0 14 14" >
                                                                    <path d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z" fill="black" />
                                                                </svg>
                                                            </Box>
                                                        </Box>
                                                        <Box style={{ borderBottom: '1px solid #e7e7e7' }}></Box>
                                                        <Box style={{ display: 'flex', padding: '0px 25px' }}>
                                                            <Box className="formGroups customSelect" style={{ marginRight: '25px', width: '50%' }}>
                                                                <Typography className="typographyLable Name">
                                                                   Select User
                                                                </Typography>
                                                                <FormControl variant="outlined" className="step4CountryFormControl">
                                                    <Field as={Select} name="userName" 
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        className="userName step4CountryField customSelect fieldChange"
                                                        style={{backgroundColor:"#F0F0F0", borderRadius:'8px'}}
                                                        data-testid="fieldChange"
                                                        renderValue={(val:any) => val.name}
                                                        displayEmpty
                                                    >
                                                        <MenuItem disabled value="">User Name</MenuItem>
                                                        {this.props.userList.length && this.props.userList.map((variant: any, i: number) => (
                                                                <MenuItem className="step4CountryMenu" value={variant} key={i}>
                                                                    {variant.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Field>
                                                    </FormControl>
                                                    <Box style={webStyle.errorMsg} className="step4CountryErrorBox"><ErrorMessage name="userName" component="div" data-testid="country-error" className="step4CountryError" /></Box>
                                                            </Box>
                                                            <Box className="formGroups customSelect createMemerStep7complex" style={{ marginRight: '25px', width: '50%' }}>
                                                                <Typography className="typographyLable role createMemerStep7complex">
                                                                    Role
                                                                </Typography>
                                                                <FormControl variant="outlined" className="step4CountryFormControl createMemerStep7complex">
                                                    <Field as={Select} name="role" 
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        className="userName step4CountryField customSelect fieldChange createMemerStep7complex"
                                                        style={{backgroundColor:"#F0F0F0", borderRadius:'8px'}}
                                                        data-testid="fieldChange"
                                                        renderValue={(val:any) => val.label}
                                                        displayEmpty
                                                    >
                                                       
                                                        <MenuItem disabled value="">Select Role</MenuItem>
                                                        {this.props.roleList.length && this.props.roleList.map((variant: any, i: number) => (
                                                                <MenuItem className="step4CountryMenu createMemerStep7complex" value={variant} key={i}>
                                                                    {variant.label}
                                                                </MenuItem>
                                                            ))}
                                                    </Field>
                                                    </FormControl>
                                                    <Box style={webStyle.errorMsg} className="step4CountryErrorBox createMemerStep7complex"><ErrorMessage name="role" component="div" data-testid="country-error" className="step4CountryError createMemerStep7complex" /></Box>
                                                            </Box>
                                                        </Box>
                                                        <Box style={{ color: '#FC8434', padding: '15px 25px', fontWeight: 400, fontSize: '15px', cursor: "grabbing" }} onClick={this.props.sharedCreateNewMember}>+ CREATE NEW MEMBER</Box>
                                                        <Box style={{ display: 'flex', justifyContent: 'end', padding: '15px 25px' }}>
                                                            <Grid style={{ marginRight: '20px' }}>
                                                                <Button onClick={this.props.sharedCoreMembers} className="buttonCancel" >CANCEL</Button>
                                                            </Grid>
                                                            <Grid >
                                                                <Button className="buttonSave"  type="submit">ADD</Button>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                    </Form>)}
                                </Formik>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Formik
                        initialValues={this.props.memberValues}
                        validationSchema={validationCreateSchema}
                        validateOnMount={true}
                        enableReinitialize={true}
                        data-testid="memberForm"
                        onSubmit={(values, actions) => {
                            this.props.handleMemberDetails(values)
                            actions?.resetForm();
                        }}
                    >
                        {({ values, touched, errors, isValid, setFieldValue, handleChange }) => (
                            <Form translate="yes" className="commonForms">
                                        <Box style={{
                                            height: '100%',
                                            width: '100%',
                                            display: this.props.createNewMember ? 'block' : 'none',
                                            position: 'fixed',
                                            top: 0,
                                            left: 0,
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            zIndex: 9999,

                                        }}>
                                            <Box style={{
                                                left: '50%',
                                                backgroundColor: '#fff',
                                                width: '50%',
                                                position: 'absolute',
                                                top: '50%',
                                                overflow: 'auto',
                                                transform: 'translate(-50%, -50%)',
                                                borderRadius: '5px',
                                            }}>
                                                <Box className="topBarName">
                                                    <Box style={{ fontSize: '22px', fontWeight: 400 }}>Create New Member</Box>
                                                    <Box onClick={this.props.sharedCreateNewMember} style={{ cursor: "grabbing" }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                            <path d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z" fill="black" />
                                                        </svg>
                                                    </Box>
                                                </Box>
                                                <Box style={{ borderBottom: '1px solid #e7e7e7' }}></Box>
                                                <Box style={{ padding: "25px " }}>
                                                    <Box style={{ marginTop: '20px' }}>
                                                        <Box style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                            <Box style={{ width: '50%', marginRight: '25px' }}>
                                                                <Typography className="typographyLable">Email Address</Typography>
                                                                <Box className="formGroups" style={{ marginBottom: "0px", border: "1px solid #F0F0F0", borderRadius: "8px", backgroundColor: "#F0F0F0" }}>
                                                                    <Field name="email" type="text" placeholder="Email Address" className="formInputs" />
                                                                    <span className="frmLeftIcons">
                                                                    </span>
                                                                </Box>
                                                                <Box style={webStyle.errorMsg} className="step4CountryErrorBox"><ErrorMessage name="email" component="div" data-testid="country-error" className="step4CountryError" /></Box>
                                                            </Box>
                                                            <Box style={{ width: '50%' }}>
                                                                <Typography className="typographyLable">Phone Number</Typography>
                                                                <Box className="formGroups" style={{ marginBottom: "0px", border: "1px solid #F0F0F0", borderRadius: "8px", backgroundColor: "#F0F0F0" }}>
                                                                    <Field name="phone" type="text" placeholder="Phone Number" className="formInputs" />
                                                                    <span className="frmLeftIcons">
                                                                    </span>
                                                                </Box>
                                                                <Box style={webStyle.errorMsg} className="step4CountryErrorBox"><ErrorMessage name="phone" component="div" data-testid="country-error" className="step4CountryError" /></Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box style={{ marginTop: '20px' }}>
                                                        <Box style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                            <Box style={{ width: '49%', marginRight: '25px' }}>
                                                                <Typography className="typographyLable">Full Name</Typography>
                                                                <Box className="formGroups" style={{ marginBottom: "0px", border: "1px solid #F0F0F0", borderRadius: "8px", backgroundColor: "#F0F0F0" }}>
                                                                    <Field name="name" type="text" placeholder="Full Name" className="formInputs" />
                                                                    <span className="frmLeftIcons">
                                                                    </span>
                                                                </Box>
                                                                <Box style={webStyle.errorMsg} className="step4CountryErrorBox"><ErrorMessage name="name" component="div" data-testid="country-error" className="step4CountryError" /></Box>
                                                            </Box>
                                                            <Box className="createMemerStep7complex2" style={{ width: '49%' }}>
                                                                <Box className="formGroups customSelect createMemerStep7complex2" >
                                                                    <Typography className="typographyLable createMemerStep7complex2">
                                                                        Role
                                                                    </Typography>
                                                                    <FormControl variant="outlined" className="step4CountryFormControl createMemerStep7complex2">
                                                    <Field as={Select} name="role" 
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        className="userName step4CountryField customSelect fieldChange createMemerStep7complex2"
                                                        style={{backgroundColor:"#F0F0F0", borderRadius:'8px'}}
                                                        data-testid="fieldChange"
                                                        renderValue={(val:any) => val.label}
                                                        displayEmpty
                                                    >
                                                        <MenuItem disabled value="">Select Role</MenuItem>
                                                        {this.props.roleList.length && this.props.roleList.map((variant: any, i: number) => (
                                                                <MenuItem className="step4CountryMenu createMemerStep7complex2" value={variant} key={i}>
                                                                    {variant.label}
                                                                </MenuItem>
                                                            ))}
                                                    </Field>
                                                    </FormControl>
                                                    <Box style={webStyle.errorMsg} className="step4CountryErrorBox createMemerStep7complex2"><ErrorMessage name="role" component="div" data-testid="country-error" className="step4CountryError createMemerStep7complex2" /></Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box style={{ marginTop: '20px' }}>
                                                        <Box style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                            <Box className="formGroups customSelect" style={{ marginRight: '25px', width: '50%' }}>
                                                                <Typography className="typographyLable Building">
                                                                    Building
                                                                </Typography>
                                                                <FormControl variant="outlined" className="step4CountryFormControl">
                                                    <Field as={Select} name="buildingName" 
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        className="userName step4CountryField customSelect fieldChange"
                                                        style={{backgroundColor:"#F0F0F0", borderRadius:'8px'}}
                                                        data-testid="fieldChange"
                                                        renderValue={(val:any) => val.name}
                                                        displayEmpty
                                                    >
                                                        <MenuItem disabled value="">Select Building</MenuItem>
                                                        {this.props.buildingListData.length && this.props.buildingListData.map((variant: any, i: number) => (
                                                                <MenuItem className="step4CountryMenu" value={variant} key={i}>
                                                                    {variant.name}
                                                                </MenuItem>
                                                            ))}
                                                    </Field>
                                                    </FormControl>
                                                    <Box style={webStyle.errorMsg} className="step4CountryErrorBox"><ErrorMessage name="buildingName" component="div" data-testid="country-error" className="step4CountryError" /></Box>
                                                            </Box>
                                                            <Box style={{ width: '50%' }}>
                                                                <Box style={{}}>
                                                                    <Box style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                                                                        <Typography className="typographyLable">Unit</Typography>
                                                                        <Typography className=""><Checkbox /> Owner</Typography>
                                                                    </Box>
                                                                    <Box className="formGroups" style={{ marginBottom: "0px", border: "1px solid #F0F0F0", borderRadius: "8px", backgroundColor: "#F0F0F0" }}>
                                                                        <Field name="unit" type="text" placeholder="Unit" className="formInputs" />
                                                                        <span className="frmLeftIcons">
                                                                        </span>
                                                                    </Box>
                                                                    <Box style={webStyle.errorMsg} className="step4CountryErrorBox"><ErrorMessage name="unit" component="div" data-testid="country-error" className="step4CountryError" /></Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box style={{ display: 'flex', justifyContent: 'end', padding: '30px 0px' }}>
                                                        <Grid style={{ marginRight: '20px' }}>
                                                            <Button onClick={this.props.sharedCreateNewMember} className="buttonCancel" >CANCEL</Button>
                                                        </Grid>
                                                        <Grid >
                                                            <Button type="submit" className="buttonSave" >CREATE</Button>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        </Form>)}
                                </Formik>
                                    </Box>
                                </Box>
                                <Box className="step7BtnBox" style={{ display: 'flex', justifyContent: "end", flexWrap: 'wrap' }}>
                                <Grid className="step7Grid" style={{ marginTop: "30px", marginRight: '20px' }}>
                    <Box style={{}}>
                        <Button className="step7Prev" style={{ color: "#2B6FED", fontWeight: 'bold', padding: '0px 20.5px', textTransform: 'capitalize', width: '177px', height: '56px', border: '1px solid #2B6FED', borderRadius: "8px", display: 'flex', justifyContent: 'center', textAlign: 'center' }} onClick={this.props.handleBack}><span className="bold-text">PREV</span></Button>
                    </Box>
                </Grid>
                <Box>
                <Grid className="step7Grid" style={{ marginTop: "30px", }}>
                            <Button className="step7Save" data-testid="saveData" onClick={this.props.handleSave} style={{ width: '177px', height: '56px', backgroundColor: '#2B6FED', borderRadius: "8px", display: 'flex', justifyContent: 'center', textAlign: 'center', color: "#FFFFFF", fontWeight: 'bold', padding: '0px 20.5px', textTransform: 'capitalize' }} ><span className="bold-text">Save</span></Button>
                        </Grid>
                </Box>
            </Box>

            <Modal
                itemID="docDeleteCloseID"
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className="modal compEmpComplexStep6Modal"
                  open={this.props.deleteMemberPopup}
                  onClose={this.props.handleCloseDeleteMemberPopup}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={this.props.deleteMemberPopup}>
                    <div className="paper paper_Delete_Category compEmpComplexStep6Modal">
                      <div className="Delete_Category_Container compEmpComplexStep6Modal">
                        <img src={DeleteIcon} alt="Msg" style={{
                            cursor:'pointer'
                        }} className="compEmpComplexStep6Modal" />
                      </div>
                      <p className="Delete_Category_Heading bold-text compEmpComplexStep6Modal">Delete Core Member</p>
                      <p className="Delete_Category_Text compEmpComplexStep6Modal">
                        Are you sure you want to delete this core member?
                      </p>
                      <div className="Modal_Button_Container Delete_Category_Button_Div compEmpComplexStep6Modal">
                        <div
                          className="ModalCancelDiv Button_Size_Delete bold-text compEmpComplexStep6Modal"
                          onClick={this.props.handleCloseDeleteMemberPopup}
                        >
                          NO, DON’t DELETE
                        </div>
                        <div className="ModalConfirmDiv Button_Size_Delete bold-text compEmpComplexStep6Modal" 
                        data-testid="deleteFacility"
                        onClick={() => this.props.handleRemoveMember(this.props.deleteMemberIndex)}
                        >
                          YES DELETE
                        </div>
                      </div>
                    </div>
                  </Fade>
                </Modal>

                                </>

            // Customizable Area End
        );
    }
}

// export default withTranslation()(withRouter(CompEmpComplexBuildingBlock));
// Customizable Area Start
const webStyle = {
    errorMsg:{
        color:'red',
        fontSize: '12px',
        marginTop:'5px'
      },
    step7:{
        margin: '11px 0px',
        color: 'black',
        fontSize: '16px', 
        fontWeight: 400
    },
}
// Customizable Area End