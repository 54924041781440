// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
const navigation = require("react-navigation")
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: typeof navigation;
    t:(value:string)=> string
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    tabItem: string;
    complex: string;
    country: string;
    city: string;
    selectComplex: string;
    company:string;
    building: string;
    unitNumber: string;
    contractStatus: string;
    owner: string;
    contractIssuer: string;
    contractListData: Array<ContractListItem>;
    savedTemplatesListData: Array<SavedTemplateItem>;
    draftListData: Array<DraftListItem>;
    anchorEl: HTMLElement | null;
    contractListPage: number;
    savedTemnplatePage: number;
    draftListPage: number;
    hasSuccessError: HasSuccessErrorType;
    countryList: Array<string>;
    cityList: Array<string>;
    buildingList: Array<SingleBuilding>;
    complexList: Array<SingleComplex>;
    unitNumberList: Array<UnitNumberinterface>;
    contractListTotalPage: number;
    savedTemplatesTotalPage: number;
    draftListTotalPage: number
    // Customizable Area End
}

// Customizable Area Start

export interface ContractListItem {
    type: string,
    id: string,
    attributes: {
        account_id: number,
        apartment_management_id: number,
        society_management_id: number,
        landlord_name: string,
        country: null,
        region: null,
        building_management_id: number,
        agreement_duration: string,
        start_date: string,
        city: null,
        currency: string,
        expires_on: string,
        state: null,
        penanlty_late_payment: boolean,
        tenant_name: string,
        rent_amount: number,
        lease_template_id: number,
        status: string,
        duration: null,
        custom_contract: boolean,
        contract_template: string,
        owner_name: null,
        updated_at: string,
        created_at: string,
        owner: {
            id: number,
            full_phone_number: string,
            last_name: null,
            first_name: null,
            email: string,
            activated: boolean,
            country_code: null,
            phone_number: number,
            device_id: null,
            created_at: string,
            password_digest: string,
            unique_auth_id: null,
            updated_at: string,
            user_type: null,
            user_name: null,
            last_visit_at: null,
            full_name: string,
            app_language_id: null,
            is_paid: boolean,
            owner_full_name: null,
            manager_full_name: null,
            owner_phone_number: null,
            company_name: null,
            is_blacklisted: boolean,
            platform: null,
            status: string,
            suspend_until: null,
            public_field: null,
            stripe_subscription_id: null,
            stripe_id: null,
            role_id: null,
            owner_email: null,
            gender: null,
            date_of_birth: null,
            age: null,
            disable_chat: boolean,
            building_management_id: null,
            stripe_subscription_date: null,
            language_type: null,
            ticket_days_configuration: number
        },
        lease_template_pdf: {
            url: string
        },
        contract_template_pdf: {
            url:string
        },
        
        building_name: string,
        contract_type: string,
        lease_template_text: string,
        expire_on: number,
        society_management: {
            name: string,
            id: number,
            created_at: string,
            updated_at: string,
            complex_area: string,
            contigency: number,
            maintenance_per_square_feet: number,
            description: string,
            measurement_unit: string,
            ticket_days_configuration: number,
            zone_multiplier: number,
            late_charge_percentage: string,
            latitude: null,
            longitude: null,
            value_added_tax: string,
            is_building: boolean,
            real_estate_company_id: null,
            total_floor: null,
            total_unit: null,
            status: null
            account_manager_id: null,
            language: null,
            currency_id: null,
        },
        terms: null,
        custom_term_condition_added: boolean,
        conditions: null,
        unit_name: string,
        custom_term_condition: null,
        tenant: {
            id: number,
            full_phone_number: string,
            last_name: null,
            first_name: null,
            country_code: null,
            suspend_until: null,
            is_blacklisted: boolean,
            stripe_id: null,
            status: string,
            stripe_subscription_date: null,
            phone_number: null,
            full_name: string,
            role_id: null,
            date_of_birth: null,
            gender: null,
            is_paid: boolean,
            age: null,
            activated: boolean,
            email: string,
            stripe_subscription_id: null,
            owner_full_name: null,
            password_digest: string,
            created_at: string,
            device_id: null,
            user_name: null,
            updated_at: string,
            user_type: null,
            company_name: null,
            platform: null,
            unique_auth_id: null,
            owner_email: null,
            manager_full_name: null,
            owner_phone_number: null,
            app_language_id: null,
            building_management_id: null,
            disable_chat: boolean,
            public_field: null,
            language_type: null,
            ticket_days_configuration: number
            last_visit_at: null,
        },
        custom_contract_image: null,
        penanlty_late_payments: {
            penanlty_counted: string,
            penanlty_type: null,
            tenant_id: number
            amount: string,
            account_id: number,
            id: number,
        }
    }
};
export interface DraftListItem {
    type: string,
    id: string,
    attributes: {
        account_id: number,
        building_management_id: number,
        society_management_id: number,
        apartment_management_id: number,
        country: null,
        region: null,
        city: null,
        landlord_name: string,
        start_date: string,
        rent_amount: number,
        duration: null,
        currency: string,
        expires_on: string,
        state: null,
        tenant_name: string,
        owner_name: null,
        lease_template_id: number,
        status: string,
        penanlty_late_payment: boolean,
        custom_contract: boolean,
        contract_template: string,
        agreement_duration: string,
        created_at: string,
        updated_at: string,
        owner: {
            first_name: null,
            last_name: null,
            full_phone_number: string,
            id: number,
            phone_number: number,
            email: string,
            activated: boolean,
            country_code: null,
            unique_auth_id: null,
            password_digest: string,
            created_at: string,
            updated_at: string,
            device_id: null,
            user_name: null,
            platform: null,
            user_type: null,
            app_language_id: null,
            last_visit_at: null,
            is_blacklisted: boolean,
            suspend_until: null,
            status: string,
            stripe_id: null,
            stripe_subscription_id: null,
            stripe_subscription_date: null,
            role_id: null,
            gender: string,
            date_of_birth: null,
            age: null,
            full_name: string,
            company_name: null,
            manager_full_name: null,
            owner_full_name: null,
            ticket_days_configuration: number
            owner_email: null,
            owner_phone_number: null,
            public_field: null,
            disable_chat: boolean,
            building_management_id: null,
            is_paid: boolean,
            language_type: null,
        },
        lease_template_pdf: {
            url: string
        },
        contract_template_pdf: {
            url: string
        },
        
        building_name: string,
        contract_type: null,
        expire_on: number,
        lease_template_text: string,
        society_management: {
            name: string,
            description: string,
            created_at: string,
            updated_at: string,
            complex_area:string,
            maintenance_per_square_feet: number,
            id: number,
            ticket_days_configuration: number,
            contigency: number,
            zone_multiplier: number,
            measurement_unit: string,
            latitude: null,
            longitude: null,
            status: null
            late_charge_percentage: string,
            is_building: boolean,
            real_estate_company_id: null,
            total_floor: null,
            total_unit: null,
            value_added_tax: string,
            account_manager_id: null,
            language: null,
            currency_id: null,
        },
        custom_term_condition_added: boolean,
        terms: null,
        unit_name: string,
        conditions: null,
        custom_term_condition: null,
        tenant: {
            first_name: null,
            last_name: null,
            full_phone_number: string,
            country_code: null,
            phone_number: number,
            email: string,
            activated: boolean,
            device_id: null,
            id: number,
            password_digest: string,
            created_at: string,
            updated_at: string,
            user_name: null,
            platform: null,
            user_type: null,
            app_language_id: null,
            unique_auth_id: null,
            is_blacklisted: boolean,
            suspend_until: null,
            status: string,
            stripe_id: null,
            stripe_subscription_id: null,
            stripe_subscription_date: null,
            role_id: null,
            full_name: string,
            gender: null,
            date_of_birth: null,
            age: null,
            is_paid: boolean,
            language_type: null,
            company_name: null,
            manager_full_name: null,
            owner_full_name: null,
            last_visit_at: null,
            owner_phone_number: null,
            public_field: null,
            disable_chat: boolean,
            building_management_id: null,
            owner_email: null,
            ticket_days_configuration: number
        },
        custom_contract_image: null
        penanlty_late_payments: null,
    }
}
interface HasSuccessErrorType {
    isOpen: boolean,
    isSeverity: "success" | "info" | "warning" | "error",
    isMessage: string,
};

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
    baseURL?: string;
};

export interface ContractSuccessResponse {
    message: string
};

export interface ContractListSuccessResponsewithData {
    code: number,
    contracts: {
        data: Array<ContractListItem>
    },
    meta: {
        pagination: {
            current_page: number,
            next_page: number,
            prev_page: null,
            total_pages: number,
            total_count: number
        }
    }
};
export interface SavedTemplateItem{
    id: string,
    type: string,
    attributes: {
        templates: number
        id: number,
        owner_name: string,
        unit: [
            {
                id: number,
                society_management_id: number,
                building_management_id: number,
                created_at: string,
                updated_at: string,
                apartment_name: string,
                floor_number: number,
                size: string,
                purchase_price: string,
                configuration: string,
                purchase_date: string,
                unit_type: null,
                status: string,
                current_valuation: string,
                monthly_renting_income: null,
                account_id: null,
                owner_name: null,
                resident_name: null
            }
        ],
        building: [
            {
                id: number,
                name: string,
                society_management_id: number,
                description: null,
                created_at: string,
                updated_at: string,
                per_floor_unit: number,
                generation_methods: string,
                number_of_floor: number,
                building_area: string,
                account_id: null,
                lat: null,
                long: null,
                city: null,
                country: null,
                googlemaplocation: null,
                real_estate_company_id: null
            }
        ],
        complex: [
            {
                id: number,
                name: string,
                description: string,
                created_at: string,
                updated_at: string,
                complex_area: string,
                maintenance_per_square_feet: number,
                measurement_unit: string,
                ticket_days_configuration: number,
                contigency: number,
                zone_multiplier: number,
                late_charge_percentage: string,
                latitude: null,
                longitude: null,
                value_added_tax: string,
                is_building: boolean,
                real_estate_company_id: null,
                total_floor: null,
                total_unit: null,
                currency_id: null,
                account_manager_id: null,
                language: null,
                status: null
            }
        ],
    }
};
export interface GetsavedTemplatesSuccessResponse {
    account: {
        data: Array<SavedTemplateItem>
    },
    meta: {
        pagination: {
            current_page: number,
            next_page: null,
            prev_page: null,
            total_pages: number,
            total_count: number
        }
    }
}

export interface GetdraftsSuccessResponse {
    code: number,
    contracts: {
        data: Array<DraftListItem>
    },
    meta: {
        pagination: {
            current_page: number,
            next_page: null,
            prev_page: null,
            total_pages: number,
            total_count: number
        }
    }
};

export interface ApiFailureResponse {
    errors: Array<ApiFailureResponseError>
};

export interface ApiFailureResponseError {
    token: string
};
export interface GetCountryListSuccessResponse {
    data: {
        countries: string[];
    };
}


export interface GetCityListSuccessResponse {
    data: {
        cities: Array<string>
    }
}

export interface GetBuildingSuccessResponse {
    data: Array<SingleBuilding>
};
interface SocietyManagement {
    id: number;
    name: string;
    description: string;
    total_floor: number;
    total_unit: number;
};

interface Logo {
    url: string;
    content_type: string;
    file_name: string;
};

interface Photo {
    url: string;
    content_type: string;
    file_name: string;
};

interface Unit {
    id: number;
    unit: string;
};

interface SharedArea {
    id: number;
    name: string;
    // Add other properties as needed
};

export interface SingleBuilding {
    id: string;
    type: string;
    attributes: {
        name: string;
        description: string;
        per_floor_unit: number;
        generation_methods: string;
        number_of_floor: number;
        building_area: string;
        society_management: SocietyManagement;
        logo: Logo;
        photos: Photo[];
        total_floors: number;
        total_units: number;
        unit_name: Unit[];
        shared_area: SharedArea[];
        googlemaplocation: string;
    };
};

interface ComplexData {
    code: number;
    society_management: {
        data: SingleComplex[];
    };
    meta: {
        pagination: {
            current_page: number;
            next_page: number | null;
            prev_page: number | null;
            total_pages: number;
            total_count: number;
        };
    };
};

export interface SingleComplex {
    id: string;
    type: string;
    attributes: SocietyAttributes;
};

interface SocietyAttributes {
    name: string;
    description: string;
    complex_area: string;
    measurement_unit: string | null;
    maintenance_per_square_feet: number ;
    is_building: boolean;
    total_floor: number | null;
    total_unit: number | null;
    currency_id: number | null;
    account_manager_id: number | null;
    language: string | null;
    logo: SocietyPhoto;
    photos: SocietyPhoto[];
    subscription_end_in: string | null;
    subscription_id: string | null;
    is_building_list: boolean | null;
    building_list: [];
    shared_area: [];
    documents: [];
    building_documents: [];
    incident_categories: Record<string, number>;
    total_buildings: number;
    active_users: number | null;
    inactive_users: number | null;
    pending_ownership_requests: number;
    collected_management_fee: number | null;
    status: string;
    company_name: string;
    rented: number;
    total_polls: number;
    last_date_of_polls: string | null;
    total_surveys: number;
    last_date_of_surveys: string | null;
    building_ownership_rate: number | null;
    ownership_onboarded: {
        ownership_percent: string;
        ownership_count: number;
    };
    tenant_resquests: {
        tenant_percent: number;
        tenant_count: number;
    };
    total_units: [];
    account_manager: null;
    sales_manager: null;
    country: string;
    city: string;
    lat: number;
    long: number;
    streetname: string | null;
    zipcode: string;
    landmark: string;
    locationlink: string;
    complex_lat: number | null;
    complex_long: number | null;
    currency: string | null;
    subscription_type: string | null;
    plan_id: string | null;
    paying_month: string | null;
    googlemaplocation: string | null;
    jurisdiction: JurisdictionData;
};

interface SocietyPhoto {
    url: string;
    content_type: string;
    file_name: string;
};

interface JurisdictionData {
    data: {
        id: string;
        type: string;
        attributes: JurisdictionAttributes;
    };
};

interface JurisdictionAttributes {
    id: number;
    jurisdiction_id: number;
    jurisdiction_name: string;
    currency: string;
    country: string | null;
    timezone: string;
    unit_of_measurement: string;
    mobile_number_length: string;
    formula: string;
    value_added_tax: string | null;
    zone_multiplier: number | null;
    complex: Complex[];
    total_complex: number;
    total_buildings: number;
    total_units: number;
    contracts: [];
};

interface Complex {
    id: number;
    name: string;
    description: string;
    created_at: string;
    updated_at: string;
    complex_area: string;
    maintenance_per_square_feet: number;
    measurement_unit: string | null;
    ticket_days_configuration: number;
    contigency: number | null;
    zone_multiplier: number | null;
    late_charge_percentage: string | null;
    latitude: number | null;
    longitude: number | null;
    value_added_tax: string | null;
    is_building: boolean;
    real_estate_company_id: number | null;
    total_floor: number | null;
    total_unit: number | null;
    currency_id: number | null;
    account_manager_id: number | null;
    language: string | null;
};

export interface UnitNumberList {
    data: Array<UnitNumberinterface>
};
export interface UnitNumberinterface {
    id: string;
    type: string;
    attributes: {
        apartment_name: string;
        floor_number: number;
        size: null;
        purchase_price: null;
        configuration: null;
        purchase_date: null;
        current_valuation: null;
        status: string;
        monthly_renting_income: null;
        society_management: {
            id: number;
            name: string;
            description: string;
            created_at: string;
            updated_at: string;
            complex_area: string;
            maintenance_per_square_feet: number;
            measurement_unit: string;
            ticket_days_configuration: number;
            contigency: number;
            zone_multiplier: number;
            late_charge_percentage: string;
            latitude: null;
            longitude: null;
            value_added_tax: string;
            is_building: boolean;
            real_estate_company_id: null;
            total_floor: null;
            total_unit: null;
            currency_id: null;
            account_manager_id: null;
            language: null;
        };
        building_management: {
            id: number;
            name: string;
            society_management_id: number;
            description: null;
            created_at: string;
            updated_at: string;
            per_floor_unit: number;
            generation_methods: string;
            number_of_floor: number;
            building_area: string;
            account_id: null;
            lat: null;
            long: null;
            city: null;
            country: null;
            googlemaplocation: null;
            real_estate_company_id: null;
        };
        photos: [];
        owner: null;
        resident: null;
        phone_number: null;
        family_members: number;
        address: {
            city: string;
            currency: string;
        };
    };
};

// Customizable Area End

interface SS {
    id: string;
}
export default class LeaseManagementMainController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    contractListingApiCallId: string = "";
    savedTemplatesApiCallId: string = "";
    draftsApiCallId: string = "";
    countryApiCallId: string = "";
    cityListApiCallId: string = "";
    buildingListApiCallId: string = "";
    complexListApiCallId: string = "";
    unitNumberApiCallID: string = "";
    contractListingNextPageApiCallId: string = "";
    savedTemplateListingNextPageApiCallId: string = "";
    draftListingNextPageApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        // Customizable Area End
        this.state = {
            // Customizable Area Start
            tabItem: "Contract Listing",
            complex: "",
            country: "",
            company:"",
            city: "",
            selectComplex: "",
            building: "",
            unitNumber: "",
            contractStatus: "",
            owner: "",
            contractIssuer: "",
            anchorEl: null,
            contractListPage: 1,
            savedTemnplatePage: 1,
            draftListPage: 1,
            contractListData: [],
            savedTemplatesListData: [],
            draftListData: [],
            hasSuccessError: {
                isOpen: false,
                isSeverity: "success",
                isMessage: ""
            },
            countryList:[],
            cityList:[],
            buildingList:[],
            complexList:[],
            unitNumberList:[],
            contractListTotalPage: 1,
            savedTemplatesTotalPage: 1,
            draftListTotalPage: 1,            // Customizable Area End
        };
        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();
        this.getContractListing();
        this.getCountryList();
        this.getCityList();
        this.getBuildingList();
        this.getComplexList();
        this.getUnitNumberList();
        this.getsavedTemplates();
        this.getdrafts();
        // Customizable Area End
    }
    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.apiResponseSucessCell(apiRequestCallId, responseJson);
            } else if (responseJson && responseJson.errors) {
                this.apiResponseFailureCall(apiRequestCallId, responseJson);
            }
        }
    };

    leaseManagmentMain = async (data: APIPayloadType) => {
        let { method, endPoint, body, type = "", contentType } = data;
        let token = localStorage.getItem("loginSuccessToken")
        const header = {
            token: token,
            "Content-Type": contentType,
        };
        let contractDetailsrequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && type !== "formData"
            ? contractDetailsrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : contractDetailsrequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(contractDetailsrequestMessage.id, contractDetailsrequestMessage);
        return contractDetailsrequestMessage.messageId;
    };

    apiResponseSucessCell = async (
        apiRequestCallId: string,
        responseJson: ContractListSuccessResponsewithData &
            GetsavedTemplatesSuccessResponse &
            GetdraftsSuccessResponse &
            GetCountryListSuccessResponse &
            GetCityListSuccessResponse &
            GetBuildingSuccessResponse &
            ComplexData &
            UnitNumberList & ContractSuccessResponse) => {
        if (apiRequestCallId === this.contractListingApiCallId) {
            this.contractListingSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.savedTemplatesApiCallId) {
            this.savedTemplatesSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.draftsApiCallId) {
            this.draftsSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.countryApiCallId) {
            this.getCountryListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityListApiCallId) {
            this.getCityListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.buildingListApiCallId) {
            this.getBuildingListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.complexListApiCallId) {
            this.getComplexListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.unitNumberApiCallID) {
            this.getUnitNumberListSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.contractListingNextPageApiCallId) {
            this.contractListingSucessNextPageCallBack(responseJson);
        }
        if (apiRequestCallId === this.savedTemplateListingNextPageApiCallId) {
            this.savedTemplatesNextPageSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.draftListingNextPageApiCallId) {
            this.draftsNextPageSucessCallBack(responseJson);
        }
    };

    apiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
        if (apiRequestCallId === this.contractListingApiCallId) {
            this.contractListingFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.savedTemplatesApiCallId) {
            this.savedTemplatesFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.draftsApiCallId) {
            this.draftsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.countryApiCallId) {
            this.getCountryListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.cityListApiCallId) {
            this.getCityListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.buildingListApiCallId) {
            this.getBuildingListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.complexListApiCallId) {
            this.getComplexListFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.unitNumberApiCallID) {
            this.getUnitListNumberFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.contractListingNextPageApiCallId) {
            this.contractListingFailureNextPageCallBack(responseJson);
        }
        if (apiRequestCallId === this.savedTemplateListingNextPageApiCallId) {
            this.savedTemplatesNextPageFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.draftListingNextPageApiCallId) {
            this.draftsNextPageFailureCallBack(responseJson);
        }
    };

    getContractListing = async () => {
        this.contractListingApiCallId = await this.leaseManagmentMain({
            method: configJSON.contractListingMethod,
            endPoint: configJSON.contractListingEndPoint,
        });
    };

    contractListingSucessCallBack = (response: ContractListSuccessResponsewithData) => {
        this.setState({contractListData:response.contracts.data, contractListTotalPage: response.meta.pagination.total_pages })
    };

    contractListingFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({
            hasSuccessError: {
                isOpen: true,
                isSeverity: "error",
                isMessage: response.errors[0].token
            },
        });
    };

    getsavedTemplates = async () => {
        this.savedTemplatesApiCallId = await this.leaseManagmentMain({
            method: configJSON.saveTemplatesMethod,
            endPoint: configJSON.saveTemplatesEndPoint,
        });
    };

    savedTemplatesSucessCallBack = (response: GetsavedTemplatesSuccessResponse) => {
        this.setState({ savedTemplatesListData: response.account.data,savedTemplatesTotalPage:response.meta.pagination.total_pages })

    };

    savedTemplatesFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({
            hasSuccessError: {
                isOpen: true,
                isSeverity: "error",
                isMessage: response.errors[0].token
            },
        });
    };

    getdrafts = async () => {
        this.draftsApiCallId = await this.leaseManagmentMain({
            method: configJSON.draftListMethod,
            endPoint: configJSON.draftListEndPoint,
        });
    };

    draftsSucessCallBack = (response: GetdraftsSuccessResponse) => {
        this.setState({draftListData:response.contracts.data,draftListTotalPage:response.meta.pagination.total_pages })
    };

    draftsFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({
            hasSuccessError: {
                isOpen: true,
                isSeverity: "error",
                isMessage: response.errors[0].token
            },
        });
    };

    getCountryList = async () => {
        this.countryApiCallId = await this.leaseManagmentMain({
            method: configJSON.countryListMethod,
            endPoint: configJSON.countryEndPoint,
        });
    };

    getCountryListSucessCallBack = (response: GetCountryListSuccessResponse) => {
        this.setState({ countryList: response.data.countries })
    };

    getCountryListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({
            hasSuccessError: {
                isOpen: true,
                isSeverity: "error",
                isMessage: response.errors[0].token
            },
        });
    };

    getCityList = async () => {
        this.cityListApiCallId = await this.leaseManagmentMain({
            method: configJSON.cityListMethod,
            endPoint: configJSON.cityEndPoint,
        });
    };

    getCityListSucessCallBack = (response: GetCityListSuccessResponse) => {
        this.setState({ cityList: response.data.cities })
    };

    getCityListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({
            hasSuccessError: {
                isOpen: true,
                isSeverity: "error",
                isMessage: response.errors[0].token
            },
        });
    };

    getBuildingList = async () => {
        this.buildingListApiCallId = await this.leaseManagmentMain({
            method: configJSON.buildingListMethod,
            endPoint: configJSON.buildingListEndPoint,
        });
    };

    getBuildingListSucessCallBack = (response: GetBuildingSuccessResponse) => {
        this.setState({ buildingList: response.data })
    };

    getBuildingListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({
            hasSuccessError: {
                isOpen: true,
                isSeverity: "error",
                isMessage: response.errors[0].token
            },
        });
    };

    getComplexList = async () => {
        this.complexListApiCallId = await this.leaseManagmentMain({
            method: configJSON.complexListMethod,
            endPoint: configJSON.complexListEndPoint,
        });
    };

    getComplexListSucessCallBack = (response: ComplexData) => {
        this.setState({ complexList: response.society_management.data })
    };

    getComplexListFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({
            hasSuccessError: {
                isOpen: true,
                isSeverity: "error",
                isMessage: response.errors[0].token
            },
        });
    };

    getUnitNumberList = async () => {
        this.unitNumberApiCallID = await this.leaseManagmentMain({
            method: configJSON.unitNumberListMethod,
            endPoint: configJSON.unitNumberListEndPoint,
        });
    };

    getUnitNumberListSucessCallBack = (response: UnitNumberList) => {
        this.setState({ unitNumberList: response.data })
    };

    getUnitListNumberFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({
            hasSuccessError: {
                isOpen: true,
                isSeverity: "error",
                isMessage: response.errors[0].token
            },
        });
    };
    getContractListingNextPage = async (page: number) => {
        this.contractListingNextPageApiCallId = await this.leaseManagmentMain({
            method: configJSON.contractListingMethod,
            endPoint: `${configJSON.contractListingEndPoint}?page=${page}`,
        });
    };

    contractListingSucessNextPageCallBack = (response: ContractListSuccessResponsewithData) => {
        this.setState({ contractListData: response.contracts.data })
    };

    contractListingFailureNextPageCallBack = (response: ApiFailureResponse) => {
        this.setState({
            hasSuccessError: {
                isOpen: true,
                isSeverity: "error",
                isMessage: response.errors[0].token
            },
        });
    };
    getsavedTemplatesNextPage = async (page: number) => {
        this.savedTemplateListingNextPageApiCallId = await this.leaseManagmentMain({
            method: configJSON.saveTemplatesMethod,
            endPoint: `${configJSON.saveTemplatesEndPoint}?page=${page}`,
        });
    };

    savedTemplatesNextPageSucessCallBack = (response: GetsavedTemplatesSuccessResponse) => {
        this.setState({ savedTemplatesListData: response.account.data })

    };

    savedTemplatesNextPageFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({
            hasSuccessError: {
                isOpen: true,
                isSeverity: "error",
                isMessage: response.errors[0].token
            },
        });
    };

    getdraftsNextPage = async (page: number) => {
        this.draftListingNextPageApiCallId = await this.leaseManagmentMain({
            method: configJSON.draftListMethod,
            endPoint: `${configJSON.draftListEndPoint}?page=${page}`,
        });
    };

    draftsNextPageSucessCallBack = (response: GetdraftsSuccessResponse) => {
        this.setState({ draftListData: response.contracts.data })
    };

    draftsNextPageFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({
            hasSuccessError: {
                isOpen: true,
                isSeverity: "error",
                isMessage: response.errors[0].token
            },
        });
    };

    handleCloseSnack = () => {
        this.setState({
            hasSuccessError: {
                isOpen: false,
                isSeverity: "success",
                isMessage: ""
            }
        });
    };

    handleContractTab = () => this.setState({ tabItem: "Contract Listing" });

    handleSavedTemplate = () => {
        this.setState({ tabItem: "Saved Templates" });
    };

    handleDraft = () => {
        this.setState({ tabItem: "Drafts" });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleViewContract = (id: string) => {
        this.props.navigation.navigate("ContractDetails", { id: id });
    };

    handleViewDraft = (id: string) => {
        this.props.navigation.navigate("ReviewDraft", { id: id });
    };

    handleContractListPagination = (event: React.ChangeEvent<unknown>, value: number) => {        
        const newPageCount = Number(value);
        this.setState({ contractListPage: newPageCount }, () => {
            this.getContractListingNextPage(newPageCount);
        });
    };

    handleSavedTemplateListPagination = (event: React.ChangeEvent<unknown>, value: number) => {
        const newPageCount = Number(value);
        this.setState({ savedTemnplatePage: newPageCount }, () => {
            this.getsavedTemplatesNextPage(newPageCount);
        });
    };

    handleDraftListPagination = (event: React.ChangeEvent<unknown>, value: number) => {
        const newPageCount = Number(value);
        this.setState({ savedTemnplatePage: newPageCount }, () => {
            this.getdraftsNextPage(newPageCount);
        });
    };

    // Customizable Area End
}