// Customizable Area Start
import React from "react";
import CompEmpDocumentController, { Props } from "./CompEmpDocumentController.web";
import { DocIcon, DeleteIcon, UploadImage, Document, PdfImage, DownloadImage, ShareImage } from "../assets";
import Loader from "../../../../components/src/Loader.web";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { withTranslation } from "react-i18next";
import "./CompEmpDocument.web.css";
import {
  Button,
  Box,
  Grid,
  Typography,
  Dialog,
  IconButton,
  DialogContent,
  FormControl,
  DialogActions,
  Link,
  Container,
  Card,
  withStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import { Form, Formik } from "formik";
import { handleFormError } from "../../../../components/src/ErrorHandle";
import AlertError from "../../../../components/src/AlertError.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu, MenuItem } from "@szhsin/react-menu";
import ShareDocumentModal from "../../../../components/src/DocumentComponent/ShareModal.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { downloadFileFromURL } from "../../../../components/src/APICallComponent/FileDownload.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import { withRouter } from "react-router";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import MultipleSelect from "../../../../components/src/CommonComponents/MultiSelect.web";
import { DocumentReportStyleWeb } from "../DocumentReportStyle.web";
import moment from "moment";

class CompEmpDocument extends CompEmpDocumentController {
  constructor(props: Props) {
    super(props);
  }

  firstDocumentHead = (t: any, i18n: any) => {
    const language = i18n.language;
    return (
      <>
        <Loader loading={this.state.loading} />
        <div>
          <p className="ManageFAQ_Link">
            <span className="ManageFAQ_Link_Grey">{t("Documents")}</span>
          </p>
          <p className="ManageFAQ_Tag bold-text">{t("Documents")}</p>
        </div>
        <Box className="top-bar-filter ChairmanInvoices" id="ChairmanInvoices">
          <Box className="filter-head-box">
            <FilterSelect
              t={t}
              language={language}
              label="Select Company"
              value={this.state.companyFilter}
              option={[{ label: "All", value: "all" }, ...this.state.teamReactStateList]}
              onChange={(e: any) => this.handleFilterChange(e, "companyFilter")}
            />
            <FilterSelect
              t={t}
              language={language}
              value={this.state.isComplex}
              option={[
                { label: "All", value: 0 },
                { label: "Complex", value: 1 },
                { label: "Individual Building", value: 2 },
              ]}
              onChange={(e: any) =>
                this.setState({ isComplex: e.target.value, complexFilter: "" }, () => {
                  this.handleFilterComplexList();
                })
              }
              style={{ fontWeight: "bold", color: "black" }}
            />
            <FilterSelect
              t={t}
              language={language}
              label="Select Country"
              value={this.state.countryFilter}
              option={[{ label: "All", value: "all" }, ...this.state.countriesList]}
              onChange={(e: any) => this.handleFilterChange(e, "countryFilter")}
            />
            <FilterSelect
              t={t}
              language={language}
              label="Select City"
              value={this.state.cityFilter}
              option={[{ label: "All", value: "all" }, ...this.state.cityList]}
              onChange={(e: any) => this.handleFilterChange(e, "cityFilter")}
            />
            <FilterSelect
              t={t}
              language={language}
              label={this.state.isComplex !== 2 ? "Select Complex" : "Select Individual Building"}
              value={this.state.complexFilter}
              option={[{ label: "All", value: "all" }, ...this.state.filterComplexList]}
              onChange={(e: any) => this.handleFilterChange(e, "complexFilter")}
            />
            {this.state.isComplex !== 2 && (
              <FilterSelect
                t={t}
                language={language}
                label="Select Building"
                value={this.state.buildingFilter}
                option={[{ label: "All", value: "all" }, ...this.state.buildingList]}
                onChange={(e: any) => this.handleFilterChange(e, "buildingFilter")}
              />
            )}
            <Button className="action-search-btn" startIcon={<SearchIcon />} onClick={this.getDocumentCounts}>
              {t("Search")}
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  firstDocumentBody = () => {
    return (
      <Grid container spacing={4} className="second-body-grid">
        {this.state.Document.map((item: any, key: number) => {
          return (
            <Grid item xs={12} sm={6} md={4} itemID="handleDocumentID" className="Documents_Div" onClick={() => this.handleDocument(key,item.Doc)} key={key}>
              <div className="second-body-card">
                <div className="Documents">
                  <img src={DocIcon} alt="doc-icon" className="Documents_Div_Img" />
                  <p className="Documents_Div_Text bold-text">{item.Doc}</p>
                </div>
                <p className={item.Num && item.Num !== 0 ? "Documents_Number" : "Hide"}>{item.Num}</p>
              </div>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  secondDocumentHead = (t: any, i18n: any) => {
    const language = i18n.language;
    return (
      <>
        {this.state.Index !== null && (
          <div>
            <p className="ManageFAQ_Link ">
              <span className="ManageFAQ_Link_Grey" onClick={this.backToDoc}>
                Documents
              </span>{" "}
              / <span className="ManageFAQ_Link_Blue">{this.state.Document[this.state.Index]?.Doc}</span>
            </p>
            <div className="Document_Tag_Container">
              <p className="ManageFAQ_Tag Margin_Doc_Policy bold-text">{this.state.Document[this.state.Index]?.Doc}</p>
              <button className="Doc_Upload_Button bold-text" onClick={this.newDocOpen}>
                {this.state.Index !== -1 && this.state.Document[this.state.Index].docType === "Resolutions"
                  ? "Add New Resolution"
                  : "UPLOAD DOCUMENTS"}
              </button>
            </div>
          </div>
        )}

        <Dialog
          fullWidth
          dir={languageCondition(language, "rtl", "ltr")}
          onClose={this.newDocClose}
          open={this.state.newDoc}
          className="add-document"
        >
          <DialogHeader t={t} title="Add New Document" onClose={this.newDocClose} />
          <Formik
            initialValues={{
              title: "",
              country: "",
              city: "",
              complex: [],
              file: null,
              meetingComplex:"",
              meeting: "",
              isMeeting:this.state.documentType === "Resolutions",
            }}
            validationSchema={this.validationAddForm}
            onSubmit={(values, { resetForm }) => {
              this.handleAddDocument({
                title: values.title,
                file: values.file,
                complex: this.state.documentType === "Resolutions" ? values.meetingComplex : values.complex.map((item: any) => item.value),
                meeting:values.meeting,
              });
              resetForm();
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit} translate>
                  <DialogContent dividers>
                    <FormControl fullWidth>
                      <NormalTextField
                        t={t}
                        language={language}
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="title"
                        placeholder={t("Title")}
                      />
                      {handleFormError(t, errors.title, touched.title)}
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: "20px" }}>
                      <NormalSelect
                        t={t}
                        language={language}
                        label="Country"
                        option={this.state.countriesList}
                        value={values.country}
                        name="country"
                        onChange={(e: any) => {
                          const value = e.target.value;
                          setFieldValue("country", value);
                          this.getCityFilterList(value);
                        }}
                        onBlur={handleBlur}
                      />
                      {handleFormError(t, errors.country, touched.country)}
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: "20px" }}>
                      <NormalSelect
                        t={t}
                        language={language}
                        label="City"
                        option={this.state.cityList}
                        value={values.city}
                        name="city"
                        onChange={(e: any) => {
                          this.setState({ isComplex: 0 }, () => {
                            const value = e.target.value;
                            setFieldValue("city", value);
                            this.getAddFormComplexFilterList(value, values.country);
                          });
                        }}
                        onBlur={handleBlur}
                      />
                      {handleFormError(t, errors.city, touched.city)}
                    </FormControl>
                    <FormControl fullWidth style={{ marginTop: "20px" }}>
                      {
                        this.state.Document[this.state.Index]?.Doc === "Resolutions" ?
                            <>
                              <NormalSelect
                                  t={t}
                                  language={language}
                                  label="Complex"
                                  option={this.state.addComplexList}
                                  value={values.meetingComplex}
                                  name="meetingComplex"
                                  onChange={(e: any) => {
                                      const value = e.target.value;
                                      setFieldValue("meetingComplex", value);
                                      this.getmeetingListAPI(e.target.value)
                                  }}
                                  onBlur={handleBlur}
                              />
                              {handleFormError(t, errors.meetingComplex, touched.meetingComplex)}
                            </>
                            :
                            <>
                            <MultipleSelect
                                t={t}
                                language={language}
                                label="Select Complex"
                                option={this.state.addComplexList}
                                name="complex"
                                value={values.complex}
                                onBlur={handleBlur}
                                onChange={(value: any) => {
                                  setFieldValue("complex", value);
                                }}
                            />
                            {handleFormError(t, errors.complex, touched.complex)}
                            </>
                      }
                    </FormControl>
                    {
                        this.state.Document[this.state.Index]?.Doc === "Resolutions" &&
                        <FormControl fullWidth style={{ marginTop: "20px" }}>
                            <NormalSelect
                                t={t}
                                language={language}
                                label="Meeting Minute"
                                option={this.state.meetingList}
                                value={values.meeting}
                                name="meeting"
                                onChange={(e: any) => {
                                    const value = e.target.value;
                                    setFieldValue("meeting", value);
                                }}
                                onBlur={handleBlur}
                            />
                            {handleFormError(t, errors.meeting, touched.meeting)}
                        </FormControl>
                    }
                    {values.file ? (
                      <Box className="modal-document-box">
                        <Box className="left-side-document">
                          <img src={Document} alt="pdf-img" />
                          {/* @ts-ignore */}
                          <p className="upload-file-name">{values.file.name}</p>
                        </Box>
                        <Box className="doc-upload-close-icon right-side-document">
                          <CloseIcon onClick={() => setFieldValue("file", null)} />
                        </Box>
                      </Box>
                    ) : (
                      <FormControl fullWidth>
                        <div
                          onClick={() => {
                            this.upload.click();
                          }}
                          className="image-box"
                        >
                          <img src={UploadImage} />
                          <p>{t("Upload File")}</p>
                        </div>
                        <input
                          ref={(ref: any) => (this.upload = ref)}
                          type="file"
                          accept="application/pdf, .pdf"
                          style={{ display: "none" }}
                          onChange={(e: any) => {
                            if (!this.validateSize(e, e.currentTarget)) {
                              setFieldValue("file", e.currentTarget.files[0]);
                            }
                          }}
                          id="myInput"
                          name="file"
                          onBlur={handleBlur}
                          className="comp-upload-input"
                        />
                        {handleFormError(t, errors.file, touched.file)}
                      </FormControl>
                    )}
                  </DialogContent>
                  <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                    <Button onClick={this.newDocClose} className="cancel-button">
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" className="add-button" style={{ margin: "0px" }}>
                      {t("Create")}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>
      </>
    );
  };

  secondResolutionDocumentBody = (t: any, i18n: any) => {
    return (
      <Box className="document-box resolutions" style={{ marginBottom: "85px" }}>
        {this.state.File.length > 0 ? (
          <Grid container spacing={2}>
            {this.state.File.map((item: any, key: any) => {
              return (
                <Grid item xs={12} md={6} lg={4} key={item.id}>
                  <Card className="card-item resolution-card comp-emp-card">
                    <div className="heading comp-emp-heading-box">
                      <h4 className="bold-text comp-emp-heading">{item.attributes.title}</h4>
                      <div className="menu resolution-menu comp-emp-menu">
                        <Menu
                          menuButton={
                            <IconButton>
                              <MoreVertIcon />
                            </IconButton>
                          }
                        >
                          <MenuItem>
                            <Link
                              title="Download Attached Document"
                              download={item.attributes.title}
                              href={item.attributes.attachments && item.attributes.attachments[0]?.url}
                              target="_blank"
                            >
                              {t("Download")}
                            </Link>
                          </MenuItem>
                          <MenuItem
                            className="comp-emp-delete-resolution"
                            onClick={() => {
                              this.setState({ ...this.state, deleteDocId: item.id }, () => {
                                this.docDeleteClose();
                              });
                            }}
                          >
                            {t("Delete")}
                          </MenuItem>
                          <MenuItem
                            className="comp-emp-share-menu"
                            onClick={() => {
                              if (item.attributes.attachments) {
                                this.setState({ shareUrl: item.attributes.attachments[0].url }, () => {
                                  this.handleShareModal();
                                });
                              }
                            }}
                          >
                            {t("Share")}
                          </MenuItem>
                        </Menu>
                      </div>
                    </div>
                    <div className="res-info comp-emp-info-item">
                      <div className="info-item">
                        <p>{t("Date & Time")}</p>
                        <span>{moment(item.attributes.created_at).format("DD MMM YYYY HH:mm")}</span>
                      </div>
                      <div className="info-item comp-emp-info-item">
                        <p>{t("Building")}</p>
                        <span>{"All"}</span>
                      </div>
                    </div>
                    <div className="item comp-emp-item">
                      <div className="item-title">
                        <img src={PdfImage} style={{ width: "28px", height: "34px" }} />
                        <h6 className="bold-text comp-emp-info-item">
                          {t("Meeting Minute")} {moment(item.attributes.meeting_date_time, "DD-MM-YYYY HH:mm").format("DD MMM YYYY")}
                        </h6>
                      </div>
                      <div className="icons comp-emp-icon-box">
                        {item.attributes.meeting_mins_pdf && (
                          <>
                            <img
                              className="comp-emp-share-icon"
                              src={ShareImage}
                              onClick={() => {
                                this.setState({ shareUrl: item.attributes.meeting_mins_pdf.url }, () => {
                                  this.handleShareModal();
                                });
                              }}
                            />
                            <Link
                              className="comp-emp-download-icon"
                              download={item.attributes.title}
                              href={item.attributes.meeting_mins_pdf.url}
                              target="_blank"
                            >
                              <img src={DownloadImage} />
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="item comp-emp-item">
                      <div className="item-title">
                        <img src={PdfImage} style={{ width: "28px", height: "34px" }} />
                        <h6 className="bold-text">{t("Attached Document")} </h6>
                      </div>
                      {item.attributes.attachments && (
                        <div className="icons ce-meeting-icons">
                          <img
                            onClick={() => {
                              this.setState({ shareUrl: item.attributes.attachments[0].url }, () => {
                                this.handleShareModal();
                              });
                            }}
                            className="comp-emp-meeting-share"
                            src={ShareImage}
                          />
                          <Link href={item.attributes.attachments[0]?.url} target="_blank" download={item.attributes.title}>
                            <img src={DownloadImage} />
                          </Link>
                        </div>
                      )}
                    </div>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <div className="Documents_Lists_Container" style={{ width: "unset" }}>
            <Typography style={{ marginBottom: "15px" }}>No Data Available!</Typography>
          </div>
        )}
      </Box>
    );
  };

  secondDocumentBody = (t: any, i18n: any) => {
    const language = i18n.language;

    return (
      <>
        <div className="Documents_Lists_Container">
          {this.state.File.length > 0 ? (
            this.state.File.map((item: any, key: any) => {
              return (
                <>
                  <div className="Documents_List_Value" key={item.id}>
                    <div className="Documents">
                      <img src={DocIcon} alt="doc-icon" className="Documents_Div_Img" />
                      <p itemID="readDocumentID" className="Documents_Value_Txt bold-text" onClick={() => this.readDocument(item, key)} key={key}>
                        {item.attributes.title}
                      </p>
                    </div>

                    <div className="menu document-menu">
                      <Menu
                        menuButton={
                          <IconButton>
                            <MoreVertIcon />
                          </IconButton>
                        }
                      >
                        <MenuItem>
                          <Link download={item.attributes.title} href={item.attributes.images[0].download_url} target="_blank">
                            {t("Download")}
                          </Link>
                        </MenuItem>
                        <MenuItem
                          className="document-delete-btn"
                          onClick={() => {
                            this.setState({ deleteDocId: item.id }, () => {
                              this.docDeleteClose();
                            });
                          }}
                        >
                          {t("Delete")}
                        </MenuItem>
                        <MenuItem
                          className="document-share-btn"
                          onClick={() => {
                            this.setState({ shareUrl: item.attributes.images[0].url }, () => {
                              this.handleShareModal();
                            });
                          }}
                        >
                          {t("Share")}
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <Typography style={{ marginBottom: "15px" }}>No Data Available!</Typography>
          )}
        </div>
      </>
    );
  };

  thirdDocumentHead = () => {
    const document = this.state.File[this.state.fileIndex];
    if (document) {
      return (
        <div>
          <p className="ManageFAQ_Link ">
            <span className="ManageFAQ_Link_Grey" onClick={this.backToDoc}>
              Documents
            </span>{" "}
            /{" "}
            {this.state.Index !== -1 && (
              <span className="ManageFAQ_Link_Grey" itemID="backToPolicyID" onClick={this.backToPolicy}>
                {this.state.Document[this.state.Index].Doc}
              </span>
            )}{" "}
            / <span className="ManageFAQ_Link_Blue">{document.attributes.title}</span>
          </p>
          <div className="Document_Tag_Container">
            <p className="ManageFAQ_Tag Margin_Doc_Policy bold-text">{document.attributes.title}</p>
            <button
              className="Doc_Upload_Button Doc_Download_Btn bold-text"
              onClick={() => downloadFileFromURL(document.attributes.images[0].url, document.attributes.title)}
            >
              Download
            </button>
          </div>
        </div>
      );
    }
  };

  thirdDocumentBody = (t: any) => {
    const document = this.state.File[this.state.fileIndex];
    if (document && document.attributes?.images?.length > 0) {
      return (
        <div className="File_Details">
          {document.attributes.images[0].url.includes(".pdf") ? (
            <iframe src={document.attributes.images[0].url + "#toolbar=0&navpanes=0&scrollbar=0&view=FitH"} />
          ) : (
            <div>{t("To view the document, Please download the document.")}</div>
          )}
        </div>
      );
    }
  };

  render() {
    //@ts-ignore
    const { t, i18n, classes } = this.props;
    const language = i18n.language;

    return (
      <CEHeaderComponentWeb>
        <Container>
          <div className={this.state.first ? "Show" : "Hide"}>
            {this.firstDocumentHead(t, i18n)}
            {this.firstDocumentBody()}
          </div>
          <div className={this.state.second ? "Show" : "Hide"}>
            {this.secondDocumentHead(t, i18n)}
            <Box className={classes.documentChairman}>
              {this.state.Index !== -1 && this.state.Document[this.state.Index].docType === "Resolutions"
                ? this.secondResolutionDocumentBody(t, i18n)
                : this.secondDocumentBody(t, i18n)}
            </Box>
          </div>
          <div className={this.state.third ? "Show" : "Hide"}>
            {this.thirdDocumentHead()}
            {this.thirdDocumentBody(t)}
          </div>
        </Container>

        <AlertError show={this.state.showError} handleClose={() => this.setState({ showError: false, error: "" })} message={this.state.error} />

        <AlertDialog
          IsOpen={this.state.docDelete}
          CloseDialog={this.docDeleteClose}
          Image={DeleteIcon}
          Header="Delete Document"
          Content="Are you sure you want to delete this document?"
          DeclineText="No, Don't Delete"
          AcceptText="Yes, Delete"
          DeclineFun={this.docDeleteClose}
          AcceptFun={this.handleDeleteDocument}
        />

        <ShareDocumentModal
          language={language}
          isOpen={this.state.isShareModalOpen}
          handleClose={this.handleShareModal}
          heading={t("Share")}
          documentURL={this.state.shareUrl}
        />

        <Dialog
          scroll="paper"
          dir={languageCondition(language, "rtl", "ltr")}
          maxWidth="md"
          className="select-meeting meeting-minute-dialog"
          fullWidth
          open={false}
        >
          <DialogHeader t={t} title="Select Meeting Minutes" onClose={this.newDocClose} />
          <DialogContent dividers>
            <List>
              <ListItem dense className="list-heading">
                <ListItemIcon />
                <ListItemText primary={t("Title")} className="bold-text" />
                <ListItemText primary={t("Agenda")} className="bold-text" />
                <ListItemText primary={t("Date & Time")} className="bold-text" />
              </ListItem>
            </List>
          </DialogContent>
          <DialogActions className="dialog-button-group">
            <div className="selected-meeting"></div>
            <div className="button-group">
              <Button className="cancel-button">
                {t("Cancel")}
              </Button>
              <Button className="add-button" disabled={false}>
                {t("Create")}
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </CEHeaderComponentWeb>
    );
  }
}

export default withTranslation()(withStyles(DocumentReportStyleWeb)(withRouter(CompEmpDocument)));
// Customizable Area End
