//Customizable Area Start
import React from "react";
import {
    Container,
    Box,
    Grid,
    Hidden,
} from "@material-ui/core";
import RealEstateCompanyEmployeeController, {
    Props,
} from './RealEstateCompanyEmployeeController'
import RealEstateCompaniesTable from './RealEstateCompaniesTable.web'
import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";
import {withRouter} from 'react-router-dom'

class RealEstateCompanies extends RealEstateCompanyEmployeeController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { navigation } = this.props;

        return (
            <>
            <MainScreen>
                <RealEstateCompaniesTable navigation={navigation} id={"real_estate_companies_table"} />
            </MainScreen>
            </>
        );
    }
}

const webStyle = {
};

export default withRouter(RealEstateCompanies)
//Customizable Area End


