// Customizable Area Start
import React from "react";
import { Box, Button, Container, Grid, Link, Typography, withStyles } from "@material-ui/core";
import CEPendingJoinRequestController, { Props } from "./CEPendingJoinRequestController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { CERequestManagementStyle } from "./CERequestManagement.web";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import PendingReqCard from "../../../../components/src/CommunityManagementCard/PendingReqCard.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";
import { CheckIcon, CrossIcon } from "../assets";
import SearchIcon from "@material-ui/icons/Search";
// Customizable Area End

class CEPendingJoinRequest extends CEPendingJoinRequestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes,language }: any = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />
        <CEHeaderComponentWeb>
          <Container className={classes.RequestManagement}>
            <Box className="navigation">
              <Typography variant="body1" className="pending-join-heading-box">
                <Link href="/CompanyEmployee/dashboard">{t("Community Management")}</Link> /{" "}
                <Link href="/CompanyEmployee/RequestManagement">{t("Requests Management")}</Link> /{" "}
                <Box component="span" style={{ color: "blue" }} className="pending-join-head-text">
                  {t("Pending Join Request")}
                </Box>
              </Typography>
              <Box className="sub-heading-box pending-join-head-box">
                <Typography variant="h5" className="bold-text pending-join-heading">
                  {t("Pending Join Request")}
                </Typography>
              </Box>
            </Box>

            <Box className="filter-head-box">
              <FilterSelect t={t}
                language={language}
                label="Company Name"
                value={this.state.filter.selectedCompany}
              />
              <FilterSelect
                t={t}
                language={language}
                label={t("Complex / Individual building")}
                value={this.state.filter.selectedIndividualBuilding}
                onChange={(e: any) => this.handleChangeValue(e, "selectedIndividualBuilding")}
                option={[{ label: "All", value: "all" }, ...this.state.complexBuildingFilterList]}
              />
              <FilterSelect
                t={t}
                language={language}
                label={t("Select Country")}
                value={this.state.filter.selectedCountry}
                onChange={(e: any) => this.handleChangeValue(e, "selectedCountry")}
                option={[{ label: "All", value: "all" }, ...this.state.countryList]}
              />
              <FilterSelect
                t={t}
                language={language}
                label={t("Select City")}
                value={this.state.filter.selectedCity}
                onChange={(e: any) => this.handleChangeValue(e, "selectedCity")}
                option={[{ label: "All", value: "all" }, ...this.state.cityList]}
              />
              <FilterSelect
                t={t}
                language={language}
                label={t("Select Complex")}
                value={this.state.filter.selectedComplex}
                option={[{ label: "All", value: "all" }, ...this.state.complexList]}
                onChange={(e: any) => this.handleChangeValue(e, "selectedComplex")}
              />
              <FilterSelect
                t={t}
                language={language}
                label={t("Select Building")}
                value={this.state.filter.selectedBuilding}
                option={[{ label: "All", value: "all" }, ...this.state.buildingsList]}
                onChange={(e: any) => this.handleChangeValue(e, "selectedBuilding")}
              />
              <FilterSelect
                t={t}
                language={language}
                label={t("Select Unit")}
                value={this.state.filter.selectedUnit}
                option={[{ label: "All", value: "all" }, ...this.state.unitList]}
                onChange={(e: any) => this.handleChangeValue(e, "selectedUnit")}
              />
              <FilterSelect
                t={t}
                language={language}
                label={t("Select user type")}
                value={this.state.filter.selectedType}
                option={[{ label: "All", value: "all" }, ...this.state.userList]}
                onChange={(e: any) => this.handleChangeValue(e, "selectedType")}
              />
              <Button startIcon={<SearchIcon />} className="await-search-filter-btn" onClick={() => {this.getPandingFilterList() }}>
                {t("Search")}
              </Button>
            </Box>

            <Grid container spacing={2} style={{ margin: "15px 0 30px" }}>
              {this.state.pendingJoinRequestList.map((item:any, index:any) => (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                  <PendingReqCard item={item} t={t} handleReject={this.handleReject} handleAccept={this.handleAccept} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </CEHeaderComponentWeb>

        <AlertDialog
          IsOpen={this.state.isRejectDialogOpen}
          Image={CrossIcon}
          CloseDialog={() => {}}
          Header={t("Reject Join Request")}
          Content={`<p>${t("Are you sure you want to reject invitation request")} <br/> ${t(
            "received from"
          )} <b>${this.state.pendingJoinRequest.name}</b> ${t("for Unit")} <b>${this.state.pendingJoinRequest.unit}</b></p>`}
          DeclineText={t("Close")}
          AcceptText={t("Yes, Reject")}
          DeclineFun={() => {this.handleReject()}}
          AcceptFun={() => {this.handleSubmit("Rejected",this.state.pendingJoinRequest?.id)}}
        />

        <AlertDialog
          IsOpen={this.state.isAcceptDialogOpen}
          Image={CheckIcon}
          CloseDialog={() => {}}
          Header={t("Accept Join Request")}
          Content={`<p>${t("Are you sure you want to accept the join request")} <br/> ${t(
            "received from"
          )} <b>${this.state.pendingJoinRequest.name}</b> ${t("for Unit")} <b>${this.state.pendingJoinRequest.unit}</b></p>`}
          DeclineText={t("Close")}
          AcceptText={t("Yes, Accept")}
          DeclineFun={() => {this.handleAccept()}}
          AcceptFun={() => {this.handleSubmit("Accepted",this.state.pendingJoinRequest?.id)}}
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// @ts-ignore
export default withTranslation()(withStyles(CERequestManagementStyle)(withRouter(CEPendingJoinRequest)));
// Customizable Area End
