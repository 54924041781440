// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Popover,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@material-ui/core";
import { withTranslation } from "react-i18next";
import LeaseManagementMainController, { DraftListItem, Props, SingleBuilding, SingleComplex, UnitNumberinterface, SavedTemplateItem } from "./LeaseManagementMainController.web";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Pagination from "@material-ui/lab/Pagination";
import { NoDataImg, TemplateIcon } from "./assets";
import { Link } from 'react-router-dom'
import Alert from "@material-ui/lab/Alert";
type TFunction = (value: string) => string;
// Customizable Area End

class LeaseManagementMain extends LeaseManagementMainController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area Start
  }

  // Customizable Area Start 
  renderTopFilterContainer = () => {
    const { t }: { t: TFunction } = this.props;
    return (
      <Box className="topFiltersContainer">
        {this.state.tabItem === "Saved Templates" &&
          <FormControl variant="outlined" className={"selectCompanySelect"}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label={t("Complex")}
              variant="outlined"
              IconComponent={ExpandMoreIcon}
              MenuProps={{
                style: {
                  marginTop: "50px",
                },
              }}
              displayEmpty
              value={this.state.company}
            >
              <MenuItem value={""}><em>{t("Select Company")}</em></MenuItem>
            </Select>
          </FormControl>
        }
        <FormControl variant="outlined" className={"complexSelect"}>
          
          <Select
            labelId="complexLabel"
            id="demo-simple-select-outlined"
            label={t("Complex")}
            variant="outlined"
            IconComponent={ExpandMoreIcon}
            MenuProps={{
              style: {
                marginTop: "50px",
              },
            }}
            displayEmpty
            value={this.state.complex}
          >
              <MenuItem value=""><em>{t("Complex")}</em></MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={"selectCountry"}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            variant="outlined"
            label={t("Select Country")}
            IconComponent={ExpandMoreIcon}
            MenuProps={{
              style: {
                marginTop: "50px",
              },
            }}
            displayEmpty
            value={this.state.country}
          >
            <MenuItem value=""><em>{t("Select Country")}</em></MenuItem>
            {this.state.countryList.map((country: string, index: number) => {
              return <MenuItem key={index} value={country}>{country}</MenuItem>
            })}

          </Select>
        </FormControl>
        <FormControl variant="outlined" className={"selectCity"}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            variant="outlined"
            label={t("Select City")}
            IconComponent={ExpandMoreIcon}
            MenuProps={{
              style: {
                marginTop: "50px",
              },
            }}
            displayEmpty
            value={this.state.city}
          >
            <MenuItem value=""><em>{t("Select City")}</em></MenuItem>
            {this.state.cityList.map((city: string, index: number) => {
              return <MenuItem key={index} value={city}>{t(city)}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={"selectCountry"}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            variant="outlined"
            label={t("Select Complex")}
            IconComponent={ExpandMoreIcon}
            MenuProps={{
              style: {
                marginTop: "50px",
              },
            }}
            displayEmpty
            value={this.state.city}
          >
            <MenuItem value=""><em>{t("Select City")}</em></MenuItem>
            {this.state.complexList.map((complex: SingleComplex, index: number) => {
              return <MenuItem key={index} value={complex.attributes.name}>{complex.attributes.name}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={"selectBuilding"}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            variant="outlined"
            label={t("Select Building")}
            IconComponent={ExpandMoreIcon}
            MenuProps={{
              style: {
                marginTop: "50px",
              },
            }}
            displayEmpty
            value={this.state.building}
          >
            <MenuItem value=""><em>{t("Select Building")}</em></MenuItem>
            {this.state.buildingList.map((buildingName: SingleBuilding, index: number) => {
              return <MenuItem key={index} value={buildingName.attributes.name}>{buildingName.attributes.name}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={"selectUnitNumber"}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            variant="outlined"
            IconComponent={ExpandMoreIcon}
            MenuProps={{
              style: {
                marginTop: "50px",
              },
            }}
            displayEmpty
            value={this.state.unitNumber}
          >
            <MenuItem value=""><em>{t("Select Unit Number")}</em></MenuItem>
            {this.state.unitNumberList.map((unit: UnitNumberinterface, index: number) => {
              return <MenuItem key={index} value={unit.attributes.apartment_name}>{unit.attributes.apartment_name}</MenuItem>
            })}
          </Select>
        </FormControl>
        {this.state.tabItem === "Contract Listing" &&
          <>
            <FormControl variant="outlined" className={"selectContractStatus"}>
              <Select
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                label={t("Select Contract Status")}
                MenuProps={{
                  style: {
                    marginTop: "50px",
                  },
                }}
                variant="outlined"
                IconComponent={ExpandMoreIcon}
                value={this.state.contractStatus}
                displayEmpty
              >
                <MenuItem value=""><em>{t("Select Contract Status")}</em></MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={"selectCountry"}>
              <Select
                MenuProps={{
                  style: {
                    marginTop: "50px",
                  },
                }}
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                IconComponent={ExpandMoreIcon}
                label={t("Owner")}
                variant="outlined"
                displayEmpty
                value={this.state.owner}
              >
                <MenuItem value=""><em>{t("Owner")}</em></MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={"selectContractStatus"}>
             
              <Select
                MenuProps={{
                  style: {
                    marginTop: "50px",
                  },
                }}
                id="demo-simple-select-outlined"
                labelId="demo-simple-select-outlined-label"
                variant="outlined"
                label={t("Contract Issuer")}
                IconComponent={ExpandMoreIcon}
                value={this.state.contractIssuer}
                displayEmpty
              >
                <MenuItem value=""><em>{t("Contract Issuer")}</em></MenuItem>
              </Select>
            </FormControl>
          </>
        }
        {this.state.tabItem === "Saved Templates" &&
          <>
            <FormControl variant="outlined" className={"selectContractStatus"}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                variant="outlined"
                label={t("Select Contract Status")}
                IconComponent={ExpandMoreIcon}
                MenuProps={{
                  style: {
                    marginTop: "50px",
                  },
                }}
                displayEmpty
                value={this.state.contractStatus}
              >
                <MenuItem value={""}><em>{t("Select Contract Status")}</em></MenuItem>
              </Select>
            </FormControl>


          </>
        }
        <Button
          variant="contained"
          className="topNavFilterSearchBtn"
          startIcon={<SearchIcon />}
        >
          {t("Search")}
        </Button>
      </Box>
    );
  };
  renderTopNav = () => {
    const { t }: { t: TFunction } = this.props;
    return (
      <Box className="topNavContainer">
        <Typography className="topNavtitle">{t("Lease Management")}</Typography>
        <Typography className="topNavHeading">{t("Lease Management")}</Typography>
        <Box className="tabIssueLeaseContainer">
          <Box className="topNavTabContainer">
            <Box
              data-testId="contractTabTestID"
              className={
                this.state.tabItem === "Contract Listing"
                  ? "topNavTabItemActive"
                  : "topNavTabItem"
              }
              onClick={this.handleContractTab}
            >

              {t("Contracts Listing")}
            </Box>
            <Box
              data-testId="SavedTemplateTestID"
              className={
                this.state.tabItem === "Saved Templates"
                  ? "topNavTabItemActive"
                  : "topNavTabItem"
              }
              onClick={this.handleSavedTemplate}
            >
              {t("Saved Templates")}
            </Box>
            <Box
              data-testId="DraftTestID"
              className={
                this.state.tabItem === "Drafts"
                  ? "topNavTabItemActive"
                  : "topNavTabItem"
              }
              onClick={this.handleDraft}
            >
              {t("Drafts")}
            </Box>
          </Box>
          <Link to="/CompanyEmployee/IssueNewLease" className="issueaNewLeaseBtn">{t("Issue a New Lease")}</Link>
        </Box>
        {this.renderTopFilterContainer()}
      </Box>
    );
  };
  renderContractListing = () => {
    const open = Boolean(this.state.anchorEl);
    const { t }: { t: TFunction } = this.props;
    return (
      <>
        <Box className="contractListingContainer">
          <Box className="contractListHeadingContainer">
            <Typography className="contractListingHeading">
              {t("Contracts Listing")}
            </Typography>
            <Box className="contractListHeadingSearchContainer">
              <FormControl variant="outlined" className={"selectCountry"}>
                <InputLabel
                  id="demo-simple-select-filled-label"
                  className="complexSelectLabel2"
                >
                  {t("Sort By")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  variant="outlined"
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    style: {
                      marginTop: "50px",
                    },
                  }}
                >
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                InputProps={{
                  startAdornment: <SearchIcon style={{ color: "#8b8b8b" }} />,
                }}
                placeholder={t("Search")}
                className="searchInput"
              />
            </Box>
          </Box>
          <Divider className="dividerLine" />
          <TableContainer >
            <Table className={""} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="contractListingTableHeading">
                    #
                  </TableCell>
                  <TableCell className="contractListingTableHeading">
                    {t("Owner Name")}
                  </TableCell>
                  <TableCell className="contractListingTableHeading">
                    {t("Complex")}
                  </TableCell>
                  <TableCell className="contractListingTableHeading">
                    {t("Building")}
                  </TableCell>
                  <TableCell className="contractListingTableHeading">
                    {t("Unit Number")}
                  </TableCell>
                  <TableCell className="contractListingTableHeading">
                    {t("Lease Issuer")}
                  </TableCell>
                  <TableCell className="contractListingTableHeading">
                    {t("Tenant Name")}
                  </TableCell>
                  <TableCell className="contractListingTableHeading">
                    {t("Days to Expire")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.contractListData.map((contractItem, index) => (
                  <TableRow key={index}>
                    <TableCell key={index}>{index + 1}</TableCell>
                    <TableCell component="th" scope="row">
                      {contractItem.attributes.owner_name === null ? "-" : contractItem.attributes.owner.full_name}
                    </TableCell>
                    <TableCell>
                      {contractItem.attributes.society_management.name === null ? "-" :contractItem.attributes.society_management.name}
                      </TableCell>
                    <TableCell>
                      {contractItem.attributes.building_name === null ? "-" :contractItem.attributes.building_name}
                      </TableCell>
                    <TableCell>
                      {contractItem.attributes.unit_name === null ? "-" :contractItem.attributes.unit_name === null}
                      </TableCell>
                    <TableCell>
                      {contractItem.attributes.unit_name === null ? "-" :contractItem.attributes.unit_name}
                      </TableCell>
                    <TableCell>
                      {contractItem.attributes.tenant_name === null ? "-" : contractItem.attributes.tenant_name}
                    </TableCell>
                    <TableCell>
                      <Box className="contractDaystoExploreContainer">
                        <Typography>{contractItem.attributes.expires_on}</Typography>
                        <IconButton onClick={this.handleClick} data-testId={`contractMenu${index}`}>
                          <MoreVertIcon />
                        </IconButton>
                        <ContractListPopover
                          open={open}
                          anchorEl={this.state.anchorEl}
                          onClose={this.handleClose}
                          data-testId={`menuPopoverCloseTestId${index}`}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          elevation={1}
                        >
                          <MenuItem onClick={()=>this.handleViewContract(contractItem.id)} data-testId={`viewContract${index}`}>
                            {t("View Lease")}
                          </MenuItem>
                          <Divider className="dividerLine" />
                          <MenuItem>
                            {t("View building on map")}
                          </MenuItem>
                        </ContractListPopover>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="tablePaginationContainer">
            <Typography className="paginationDescription">
              {t("Showing")}{" "}
              <span className="totalItemInOnePage"> {5} {" "}</span>
              {t("of")}{" "}
              <span className="totalItems">
                {this.state.contractListData.length}{" "}
              </span>
              {t("results")}
            </Typography>
            <Pagination
              count={this.state.contractListTotalPage}
              className="tablePagination"
              variant="outlined"
              shape="rounded"
              onChange={this.handleContractListPagination}
              data-testId={"contractListPagintionTestId"}
            />
          </Box>
        </Box>
      </>
    );
  };
  renderDraftListing = () => {
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    const { t }: { t: TFunction } = this.props;

    return (
      <>
        <Box className="contractListingContainer">
          <Box className="contractListHeadingContainer">
            <Typography className="contractListingHeading">
              {t("Listing of Drafts")}
            </Typography>
            <Box className="contractListHeadingSearchContainer">
              <TextField
                variant="outlined"
                InputProps={{
                  startAdornment: <SearchIcon style={{ color: "#8b8b8b" }} />,
                }}
                placeholder="Search"
                className="searchInputDraft"
              ></TextField>
            </Box>
          </Box>
          <Divider />
          <TableContainer component={Paper}>
            <Table className={""} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="draftListingTableHeading">
                    #
                  </TableCell>
                  <TableCell className="draftListingTableHeading">
                    {t("Owner Name")}
                  </TableCell>
                  <TableCell className="draftListingTableHeading">
                    {t("Complex")}
                  </TableCell>
                  <TableCell className="draftListingTableHeading">
                    {t("Building")}
                  </TableCell>
                  <TableCell className="draftListingTableHeading">
                    {t("Unit Number")}
                  </TableCell>
                  <TableCell className="draftListingTableHeading">
                    {t("Last Updated On")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.draftListData.map((draftItem: DraftListItem, index: number) => (
                  <TableRow key={index}>
                    <TableCell key={index}>{index + 1}</TableCell>
                    <TableCell component="th" scope="row">
                      {draftItem.attributes.owner.full_name}
                    </TableCell>
                    <TableCell>{draftItem.attributes.society_management.name}</TableCell>
                    <TableCell>{draftItem.attributes.building_name}</TableCell>
                    <TableCell>{draftItem.attributes.unit_name}</TableCell>

                    <TableCell>
                      <Box className="contractDaystoExploreContainer">
                        <Typography>
                          {draftItem.attributes.updated_at}
                        </Typography>
                        <IconButton onClick={this.handleClick}>
                          <MoreVertIcon />
                        </IconButton>
                        <ContractListPopover
                          data-testId={`closeTestID${index}`}
                          id={id}
                          open={open}
                          anchorEl={this.state.anchorEl}
                          onClose={this.handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          elevation={1}
                        >
                          <MenuItem
                            data-testId="openMenuITemTestID"
                            className="popoverMenuItem"
                          >
                            {t("Use")}
                          </MenuItem>
                          <Divider />
                          <MenuItem className="popoverMenuItem" onClick={()=>this.handleViewDraft(draftItem.id)}>
                            {t("View")}
                          </MenuItem>
                          <Divider />
                          <MenuItem className="popoverMenuItem">
                            {t("Delete")}
                          </MenuItem>
                        </ContractListPopover>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="tablePaginationContainer">
            <Typography className="paginationDescription">
              {t("Showing")}{" "}
              <span className="totalItemInOnePage"> {10}{" "}</span>
              {t("of")}{" "}
              <span className="totalItems">
                {this.state.draftListData.length}{" "}
              </span>
              {t("results")}
            </Typography>
            <Pagination
              data-testId={`draftListPagintionTestId`}
              count={this.state.draftListTotalPage}
              className="tablePagination"
              variant="outlined"
              shape="rounded"
              onChange={this.handleDraftListPagination}
            />
          </Box>
        </Box>

      </>
    );
  };
  renderSavedTemplatesListing = () => {
    const { t }: { t: TFunction } = this.props;
    return (
      <>
        <Box className="contractListingContainer">
          <Box className="contractListHeadingContainer">
            <Typography className="contractListingHeading">
              {t("Saved Templates")}
            </Typography>
            <Box className="contractListHeadingSearchContainer">
              <TextField
                variant="outlined"
                InputProps={{
                  startAdornment: <SearchIcon style={{ color: "#8b8b8b" }} />,
                }}
                placeholder={t("Search")}
                className="searchInputDraft"
              />
            </Box>
          </Box>
          <Divider className="dividerLine" />
          <TableContainer component={Paper}>
            <Table className={""} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="savedTemplateListingTableHeading">
                    #
                  </TableCell>
                  <TableCell className="savedTemplateListingTableHeading">
                    {t("Owner Name")}
                  </TableCell>
                  <TableCell className="savedTemplateListingTableHeading">
                    {t("Complex")}
                  </TableCell>
                  <TableCell className="savedTemplateListingTableHeading">
                    {t("Building")}
                  </TableCell>
                  <TableCell className="savedTemplateListingTableHeading">
                    {t("Unit Number")}
                  </TableCell>
                  <TableCell className="savedTemplateListingTableHeading">
                    {t("Templates")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.savedTemplatesListData.map((contractItem: SavedTemplateItem, index: number) => (
                  <TableRow key={index}>
                    <TableCell key={index}>{index + 1}</TableCell>
                    <TableCell component="th" scope="row">
                      {contractItem.attributes.owner_name}
                    </TableCell>
                    <TableCell>
                      {contractItem.attributes.complex.map((item, index) => (
                        <MenuItem>{item.name}</MenuItem>
                      ))}
                    </TableCell>
                    <TableCell>
                      {contractItem.attributes.building.map((item, index) => (
                        <MenuItem>{item.name}</MenuItem>
                      ))}
                    </TableCell>
                    <TableCell>{contractItem.attributes.unit.map((item, index) => (
                      <MenuItem>{item.apartment_name}</MenuItem>
                    ))}</TableCell>
                    <TableCell>
                      <Typography><Box className="templatesCountWrapper"> <img src={TemplateIcon} alt="" className="templateIcon" /><span> {contractItem.attributes.templates}{" "} Templates</span></Box></Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="tablePaginationContainer">
            <Typography className="paginationDescription">
              {t("Showing")}{" "}
              <span className="totalItemInOnePage"> {5} {" "}</span> of{" "}
              <span className="totalItems">
                {this.state.savedTemplatesListData.length}{" "}
              </span>
              {t("results")}
            </Typography>
            <Pagination
              data-testId={"savedTempaltesListPagintionTestId"}
              count={this.state.draftListTotalPage}
              variant="outlined"
              shape="rounded"
              onChange={this.handleSavedTemplateListPagination}
              className="tablePagination"
            />
          </Box>
        </Box>
      </>
    );
  };
  renderNoData = () => {
    const { t }: { t: TFunction } = this.props;

    return (
      <Box className="noDataContainer">
        <img src={NoDataImg} alt="" className="notDataICon" />
        <Typography className="noDataHeading">
          {t("You dont't have access to lease management. Request your manager")}{" "}
          <br /> {t("for access")}
        </Typography>
        <Button className="requestAccessBtn">REQUEST ACCESS</Button>
      </Box>
    );
  };
  renderLeaseContentContainer = () => {
    return (
      <Box>
        {this.renderTopNav()}
        <Paper elevation={3} className="leaseContentContainer">
          {this.state.tabItem === "Contract Listing" &&
            this.renderContractListing()}
          {this.state.tabItem === "Saved Templates" &&
            this.renderSavedTemplatesListing()}
          {this.state.tabItem === "Drafts" && this.renderDraftListing()}
        </Paper>
      </Box>
    );
  };
  renderLeaseMainSnackbar = () => {
    return (
      <>
        {this.state.hasSuccessError.isOpen && (<>
          <Snackbar
            open={this.state.hasSuccessError.isOpen}
            data-testid="snackBarCloseTestId"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={this.handleCloseSnack}
            autoHideDuration={3000}
          >
            <Alert severity={this.state.hasSuccessError.isSeverity} onClose={this.handleCloseSnack} >
              {this.state.hasSuccessError.isMessage}
            </Alert>
          </Snackbar>
        </>
        )}
      </>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <MainScreen>
        <LeaseManagementContainer>
          {this.renderLeaseContentContainer()}
        </LeaseManagementContainer>
        {this.renderLeaseMainSnackbar()}
      </MainScreen>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withTranslation()(LeaseManagementMain);
export { LeaseManagementMain };

const LeaseManagementContainer = styled(Box)({
  padding: "50px",
  backgroundColor: "#f4f7ff",
  "& .topNavContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  "& .topNavtitle": {
    color: "#5b86e2",
    fontSize: "18px",
  },
  "& .topNavHeading": {
    color: "#191b24",
    fontSize: "30px",
    fontWeight: 800,
  },
  "& .topNavTabContainer": {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
  },
  "& .topNavTabItem": {
    padding: "10px 35px",
    color: "#adb1b7",
    backgroundColor: "#edf0f7",
    borderRadius: "50px",
    fontWeight: 700,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .topNavTabItemActive": {
    padding: "10px 35px",
    color: "#fff",
    backgroundColor: "#2c70e7",
    borderRadius: "50px",
    fontWeight: 700,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .topFiltersContainer": {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    padding: "10px 0px",
    alignItems: "center",
  },

  "& .complexSelect": {
    width: "130px",
    border: "1px solid #f3f2f2",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      background: "#fff",
      border: "1px solid #f3f2f2",

    },
  },
  "& .selectCompanySelect": {
    width: "200px",
    border: "1px solid #f3f2f2",
    borderRadius: "8px",
    background: "#fff",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      background: "#fff",
      border: "1px solid #f3f2f2",

    },
  },
  "& .complexSelectLabel": {
    color: "#2a2b2d",
    fontWeight: 900,
  },
  "& .complexSelectLabel2": {
    color: "#b6b9ba",
  },
  "& .selectCountry": {
    width: "180px",
    border: "1px solid #f3f2f2",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      background: "#fff",
      border: "1px solid #f3f2f2",
    },
  },
  "& .selectCity": {
    width: "140px",
    border: "1px solid #f3f2f2",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      background: "#fff",
      border: "1px solid #f3f2f2",
    },
  },
  "& .selectBuilding": {
    width: "170px",
    border: "1px solid #f3f2f2",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      background: "#fff",
      border: "1px solid #f3f2f2",
    },
  },
  "& .selectUnitNumber": {
    width: "230px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      background: "#fff"

    },
  },
  "& .selectContractStatus": {
    width: "230px",
    border: "1px solid #f3f2f2",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      background: "#fff",
      border: "1px solid #f3f2f2",
    },
  },
  "& .topNavFilterSearchBtn": {
    backgroundColor: "#2b6ef0",
    color: "#fff",
    height: "50px",
    borderRadius: "8px",
  },
  "& .leaseContentContainer": {
    backgroundColor: "#fff",
    padding: "20px ",
    marginTop: "20px",
  },
  "& .contractListingContainer": {
    display: "flex",
    flexWrap: "wrap",
  },
  "& .contractListingHeading": {
    flex: 1,
    color: "#26272c",
    fontSize: "25px",
    fontWeight: 800,
    padding: "15px 20px",
  },
  "& .dividerLine": {
    width: "100%"
  },
  "& .contractListingTableHeading": {
    fontWeight: 800,
    fontSize: "16px",
    color: "#272a2b"
  },
  "& .savedTemplateListingTableHeading": {
    fontWeight: 800,
    fontSize: "16px",
    color: "#272a2b"
  },
  "& .draftListingTableHeading": {
    fontWeight: 800,
    fontSize: "16px",
    color: "#272a2b"
  },
  "& .contractDaystoExploreContainer": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .tabIssueLeaseContainer": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .issueaNewLeaseBtn": {
    color: "#fff !important",
    fontWeight: 800,
    backgroundColor: "#f88538",
    height: "50px",
    padding: "0px 20px",
    borderRadius: "8px",
    width: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "uppercase"
  },
  "& .contractListHeadingContainer": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "20px",
  },
  "& .contractListHeadingSearchContainer": {
    display: "flex",
    gap: "20px",
  },
  "& .searchInput": {
    borderRadius: "8px",
    border: "1px solid #f3f2f2",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      border: "1px solid #f3f2f2"
    },
  },
  "& .searchInputDraft": {
    width: "300px",
    border: "1px solid #f4f5f8",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      border: "1px solid #f4f5f8"
    },
  },
  "& .noDataContainer": {
    height: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "20px",
  },
  "& .requestAccessBtn": {
    width: "250px",
    backgroundColor: "#2b6fec",
    borderRadius: "8px",
    color: "#fff",
    fontWeight: 600,
  },
  "& .tablePaginationContainer": {
    paddingTop: "20px ",
    paddingLeft: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  "& .totalItemInOnePage": {
    color: "#f0a36e",
    fontWeight: 700,
  },
  "& .totalItems": {
    color: "#32333a",
    fontWeight: 900,
  },
  "& .paginationDescription": {
    fontSize: "18px",
    fontWeight: 400,
  },
  "& .tablePagination": {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#f0a36e",
      color: "#fff",
      fontWeight: 700,
      margin: "0px 5px",
    },
    "& .MuiPaginationItem-rounded": {
      color: "#f4f3f3",
      margin: "0px 5px",
    },
    "& .MuiPaginationItem-outlined": {
      border: "1px solid #f4f3f3",
    },
    "& .MuiPaginationItem-page.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26)",
    },
    "& .MuiPaginationItem-previous, & .MuiPaginationItem-next": {
      border: "1px solid #637cbb",
      color: "#637cbb",
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&.Mui-disabled": {
        borderColor: "transparent",
        color: "rgba(0, 0, 0, 0.26)",
      },
    },
  },

  "& .contractListPopover": {
    boxShadow: "0px 0px 5px 1px #f4f7f8 !important",
    "& .MuiPaper-elevation1": {
    }
  },
  "& .noDataHeading": {
    textAlign: "center",
    color: "#a2a2a5",
    fontSize: "18px",
  },
  "& .notDataICon": {
    width: "60px",
    height: "60px",
  },
  "& .templatesCountWrapper": {
    display: "flex",
    gap: "10px",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  "& .templateIcon": {
    width: "20px",
    height: "20px"
  }
});
const ContractListPopover = styled(Popover)({
  "& .MuiPaper-elevation1": {
    boxShadow: "0px 0px 5px 1px #f4f7f8 !important",
  },
  "& .popoverMenuItem": {
    width: "130px"
  }
})
// Customizable Area End