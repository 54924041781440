import React from "react";
// Customizable Area Start
//components
import {
  Box,
  Button,
  Typography,
  Grid,
  Container,
  Select,
  MenuItem,
  withStyles,
  Link,
} from "@material-ui/core";
import { buildingImg, userBlue} from "./assets";
import { withRouter } from 'react-router';
import Loader from "../../../components/src/Loader.web";
import ManagerController, { Props } from "./ManagerController.web";
import { withTranslation } from 'react-i18next';
import { ReportsStyleWeb } from "../../StoreCredits/src/ReportsStyle.web";
import { SearchIconImage } from "../../ExpenseTracking/src/assets";
import GeneralHeader from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import {languageCondition} from "../../../components/src/ConditionManager.web";
class ManagerList extends ManagerController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    this.getBuildingName();
    this.getVehicle();
  }

  render() {
    const { t, classes }: any = this.props;
    // @ts-ignore
    const language = this.props.i18n.language;
    return (
      <>
        <GeneralHeader>
              <Box className={classes.reportList} id="managerVehicleList">
              <Container className="top-bar" style={{ display: 'block' }} id="managerVehicleList">
                <Box style={vehicleList.navigation} id="managerVehicleList">
                  <Box id="managerVehicleList">
                    <Typography variant="body1" id="managerVehicleList">
                      {t("My Dashboard")} / <Link href="/DashboardGeneral">{t("General Dashboard")}</Link> / <Link href="/mv"><Box component="span" style={{ color: "blue" }}>{t('Vehicles')}</Box></Link>
                    </Typography>
                    <Typography variant="h5" id="managerVehicleList" style={vehicleList.subHeading} >{t("Vehicles")}</Typography>
                  </Box>
                </Box>
                <Box className="filter" id="managerVehicleList">
                  <Select
                    id="demo-simple-select-outlined"
                    name="status" labelId="demo-simple-select-outlined-label"
                    className="select-input selectVehicelStatus" displayEmpty
                    onChange={(e) => {
                      (e.target.value != " ") && this.handleChange(e);
                    }}
                    value={this.state.status}
                  >
                    <MenuItem value="" className={languageCondition(language, "select-arabic-menu", "selectIncidentStatus")}>
                      {t("Status")}
                    </MenuItem>
                    <MenuItem value="All" className={languageCondition(language, "select-arabic-menu", "selectIncidentStatus")}>
                      {t("All")}
                    </MenuItem>
                    <MenuItem value="Pending Approval" className={languageCondition(language, "select-arabic-menu", "selectIncidentStatus")}>
                      {t("Pending")}
                    </MenuItem>
                    <MenuItem value="Approved" className={languageCondition(language, "select-arabic-menu", "selectIncidentStatus")}>
                      {t("Approved")}
                    </MenuItem>
                    <MenuItem value="Rejected" className={languageCondition(language, "select-arabic-menu", "selectIncidentStatus")}>
                      {t("Rejected")}
                    </MenuItem>

                  </Select>
                  <Select
                    name="selectBuilding" labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined" displayEmpty className="select-input selectVehicleBuilding"
                    onChange={(e) => {(e.target.value != " ") && this.handleChange(e);}}
                    value={this.state.buildingName}
                  >
                    <MenuItem disabled value="" className={languageCondition(language, "select-arabic-menu", "selectBuilding")}>
                      {t("Select Building")}
                    </MenuItem>
                    <MenuItem value="All" className={languageCondition(language, "select-arabic-menu", "selectBuilding")}>
                      {t("All")}
                    </MenuItem>
                    {
                      this.state.buildingNameData?.map((val: any, index: any) => (
                        <MenuItem
                          key={index}
                          className={languageCondition(language, "select-arabic-menu", "selectBuilding")}
                          value={`${val.id} ${val.attributes.name}`}
                        >
                          {val.attributes.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                  <Select
                    name="unit" labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined" displayEmpty
                    className="select-input selectVehicleUnit"
                    onChange={(e) => {
                      (e.target.value != " ") && this.handleChange(e);
                    }}
                    value={this.state.unit}
                  >
                    <MenuItem disabled value="" className={languageCondition(language, "select-arabic-menu", "selectUnit")}>
                      {t("Select Unit")}
                    </MenuItem>
                    <MenuItem value="All" className={languageCondition(language, "select-arabic-menu", "selectUnit")}>
                      {t("All")}
                    </MenuItem>
                    {this.state.allUnit?.map((val: any, index: any) => (
                      <MenuItem
                        key={index}
                        className={languageCondition(language, "select-arabic-menu", "selectUnit")}
                        value={val.apartment_name}
                      >
                        {val.apartment_name}
                      </MenuItem>
                    ))}


                  </Select>
                  <Button className="SearchClick" startIcon={<img src={SearchIconImage} style={{marginLeft:"10px"}} />} onClick={() => this.getVehicle2(this.state.status, this.state.selectBuilding, this.state.unit)}>
                    {t("Search")}
                  </Button>
                </Box>
                <Grid container id="managerVehicleList">
                  <Grid xs={12} id="managerVehicleList">
                    {
                      <>
                        <Grid container spacing={2} style={{ marginLeft: "0px" }} id="managerVehicleList">
                          {
                            this.state.allVehcile.map((item, i) => <>
                              <Grid md={4} sm={6} xs={12} key={i} id="managerVehicleList">
                                <div className="card cardClick" style={{ cursor: 'pointer', width: "96%", background: 'white' }} onClick={() => this.addVehicle(item)} id="managerVehicleList">
                                  <div className="customButton status1" style={{ width: 'fit-content', margin: '1rem' }} id="managerVehicleList">
                                    <Button variant="contained" className={item.attributes.status === 'Pending Approval' ? "contain warning" : item.attributes.status === 'Approved' ? 'contain success' : 'contain danger'} type="submit" id="managerVehicleList">
                                      {item.attributes.status == 'Pending Approval' ? t('Pending') : t(item.attributes.status)}</Button>
                                  </div>
                                  <div className="card-content" style={{ paddingTop: "5px", paddingBottom: "10px" }} id="managerVehicleList">
                                    <Box id="managerVehicleList" style={{ marginRight: "10px", border: ".1px solid #80808042", display: "flex", alignItems: "center", justifyContent: 'center', borderRadius: "8px" }}>
                                      <img id="managerVehicleList" src={item.attributes?.car_manufacturer?.attributes?.image?.url} style={{ height: "40px", padding: "10px", width: "60px" }} />
                                    </Box>
                                    <div id="managerVehicleList" className="content" style={{ padding: 0,marginRight:"10px" }}>
                                      <p id="managerVehicleList" className="title bold-text" style={{ padding: '12px 0px 0px 0px', marginBottom: '1px' }}>
                                        {item.attributes.car_manufacturer.attributes.title}
                                      </p>
                                      <p id="managerVehicleList" className="sub-title">
                                        {item.attributes.car_model.title}
                                      </p>
                                    </div>
                                  </div>
                                  <div id="managerVehicleList" className="vehicle-details" style={{ paddingTop: "5px" }}>
                                    <div>
                                      <div id="managerVehicleList" style={{ display: 'flex', fontWeight: 500 }}>
                                        <img id="managerVehicleList" src={userBlue.default} width='25' height='25' style={{ marginRight: 10 }} />
                                        <p  id="managerVehicleList" style={{marginRight:"10px"}}>   {item.attributes.owner_name}
                                        </p>
                                      </div>
                                    </div>
                                    <div id="managerVehicleList">
                                      <div style={{ display: 'flex', fontWeight: 500, marginTop: '0.5rem' }} id="managerVehicleList">
                                        <img src={buildingImg} width='20' height='20' style={{ marginRight: 10, marginLeft: "2px" }} id="managerVehicleList"/>
                                        <p id="managerVehicleList" style={{marginRight:"10px"}}> {item?.attributes?.apartment_management?.apartment_name || 'N/A'}, {item.attributes?.building_management?.name}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Grid>
                            </>)
                          }
                          {
                            this.state.allVehcile.length === 0 && <Typography variant="h6" id="managerVehicleList" color="textSecondary" style={{ margin: "auto",marginTop:"50px" }}>{t("No Data Found")}</Typography>
                          }
                        </Grid>
                      </>
                    }
                  </Grid>
                </Grid>
              </Container>
              </Box>
        </GeneralHeader>
        <Loader loading={this.state.loading} />
      </>
    );
  }
}

// @ts-ignore
export default withTranslation()(withStyles(ReportsStyleWeb)(withRouter(ManagerList)));

const vehicleList = {
  navigation: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeading: {
    fontWeight: 600,
    marginTop: 15,
    marginBottom: "1rem"
  }
};
// Customizable Area End
