import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { ApiCatchErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { userProfile } from "../assets";
import {
    Box,
    Button
} from "@material-ui/core";


export interface Props {
    navigation: any;
    id: string;
    companyId?:any;
    t?: any
    i18n?: any
    match?: any
    location?: any
    // Customizable Area Start
    history?: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    selectedImage: null,
    previewImage: string,
    imagesComplex: any,
    previewImagesComplex: any,
    imagesBuilding: any,
    leasePopup:any,
    previewImagesBuilding:any,
    imagesFacility: any,
    previewImagesFacility:any,
    selectedFile: null,
    accountType:string,
    selectedFileBuliding: null,
    selectedFilePdfComplex: any,
    selectedFilePdfGuidelinesComplex: any,
    selectedFilePdfFloorPlanComplex: any,
    userValues:any,
    imageURLComplex: string,
    imageURLBuilding: string,
    selectPdf: string,
    deleteBuildingPopup:any,
    deleteFacilityPopup:any,
    deleteMemberPopup:any,
    img: string,
    memberValues:any,
    savedMembers:any,
    stepComplex: number,
    complexId:any;
    tempStepComplex:number,
    multiPleButtonComplex: any
    selectedFilePdfRolesComplex: any
    selectedFilePdfResolutionsComplex: any
    selectedFilePdfBuildingPlansComplex: any
    sampleData: any
    page: number,
    rowsPerPage: number,
    isPopupOpen: any,
    findPopupOpen: any,
    facilityPopupComplex: any,
    imagesSharedFacilityComplex: any,
    coreMembers: any,
    createNewMember: any,
    jurisdictionSelected: any,
    variants: any,
    selectedVariants: any
    coreMemberManager: any
    open: any
    openBuilding: any,
    jurisdictionList:any,
    currencyList: any,
    currencySelected: any,
    leaseList:any;
    userList:any,
    roleList:any,
    userSelect:any,
    leaseSelected:any,
    rentalSelected:any,
    complexValues: any,
    buildingValues: any,
    savedBuildings: any,
    facilityValues:any,
    savedFacility:any;
    currencyError: boolean,
    imagesError: boolean,
    deleteBuildingIndex:any,
    deleteFacilityIndex:any,
    deleteMemberIndex:any,
    imagesBuildingError: boolean,
    imagesFacilityError: boolean,
    logoError: boolean,
    logoBuildingError: boolean,
    countriesList: any,
    countrySelected: string,
    createConfirmModal:boolean,
    locationDetails: any,
    totalAreaUnit:any,
    accountManagerList:any,
    societyId:any;
    buildingList:any;
    buildingListData:any;

    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CompEmpComplexBlockController extends CommonApiCallForBlockComponent<Props, S, SS> {
    exampleAPICallId: string = "";
    GetJurisdictionList: any = "";
    GetCurrencyList: any = "";
    GetCountryList: any = "";
    GetLease:any = "";
    GetUserList:any="";
    GetRoleList:any = "";
    GetAccountManagerList:any="";
    SaveComplex:any = "";
    addJurisdictionCall:any;
    getUploadDocumentList:any;
    addLocationApi:any;
    addBuildingApiCallId:any;
    addSharedApiCallId:any;
    GetBuildingListData:any;
    addCoreMemberApiCallId:any;
    getCoreMemberApi:any;
   

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

        this.state = {
            // Customizable Area Start
            loading: false,
            coreMemberManager: [
                { id: 0, label: "Vice Chairman", name: "Marleah Eagleston Slavcho", position: "Vice Chairman" },
                { id: 1, label: "Manager", name: "Marleah Slavcho Eagleston", position: "Vice " },
                { id: 2, label: "Auditor", name: "Slavcho Marleah Eagleston", position: "Vice Chairman" },
                { id: 3, label: "Real Estate Developer", name: "Marleah Slavcho Eagleston", position: "Vice " },
                { id: 4, label: "Slavcho Vice Chairman", name: "Marleah Eagleston Slavcho", position: "Vice Chairman" },
                { id: 5, label: "Vice Slavcho Chairman", name: "Marleah Eagleston", position: "Vice Chairman" },
                { id: 6, label: "Vice Chairman Slavcho", name: "Marleah Eagleston", position: "Vice Chairman" }
            ],
            selectedImage: null,
            previewImage: "",
            imagesComplex: [],
            previewImagesComplex:[],
            imagesBuilding: [],
            accountType:'',
            previewImagesBuilding: [],
            imagesFacility: [],
            previewImagesFacility: [],
            selectedFile: null,
            selectedFileBuliding: null,
            selectedFilePdfFloorPlanComplex: [],
            selectedFilePdfComplex: [],
            selectedFilePdfGuidelinesComplex: [],
            selectedFilePdfRolesComplex: [],
            selectedFilePdfResolutionsComplex: [],
            selectedFilePdfBuildingPlansComplex: [],
            imagesSharedFacilityComplex: [],
            leaseList:[],
            userList:[],
            roleList:[],
            userSelect:'',
            leaseSelected:'',
            rentalSelected:'',
            jurisdictionSelected: '',
            imageURLComplex: '',
            imageURLBuilding: '',
            selectPdf: '',
            img: userProfile,
            selectedVariants: [],
            memberValues:{
                email:'',
                phone:'',
                name:'',
                role:'',
                buildingName:'',
                unit:''
            },
            savedMembers:[],
            createConfirmModal:false,
            stepComplex: 0,
            complexId:null,
            tempStepComplex: 0,
            deleteBuildingIndex: null,
            deleteFacilityIndex:null,
            deleteMemberIndex:null,
            multiPleButtonComplex: ['Jurisdiction', 'Basic Details', 'Documents', 'Location', 'Buildings', 'Shared Facilities', 'Core Members'],
            page: 0,
            rowsPerPage: 10,
            isPopupOpen: false,
            variants: [
                { id: 0, name: 'User Name' },
                {
                    id: 3,
                    name: 'Slavcho',
                },
                {
                    id: 1,
                    name: 'Top Slavcho',
                },
                {
                    id: 2,
                    name: 'Slavcho Key',
                },
                {
                    id: 12,
                    name: 'Other Slavcho',
                },
                {
                    id: 11,
                    name: 'Slavcho Nintendo',
                },
                {
                    id: 10,
                    name: 'Xbox Slavcho',
                },
            ],
            jurisdictionList: [],
            findPopupOpen: false,
            leasePopup:false,
            facilityPopupComplex: false,
            deleteBuildingPopup: false,
            deleteFacilityPopup: false,
            deleteMemberPopup: false,
            coreMembers: false,
            createNewMember: false,
            sampleData: [
                {
                    id: 1,
                    UnitNumber: "A202",
                    FloorNumber: 25,
                    Configuration: "10 BHK",
                    Owner: "Andries Grootoonk",
                    ResidentName: "Anaru Hakopa",
                },
                {
                    id: 2,
                    UnitNumber: "A203",
                    FloorNumber: 15,
                    Configuration: "5 BHK",
                    Owner: "Florieke Krebber",
                    ResidentName: "Beatriz Brito",
                },
                {
                    id: 3,
                    UnitNumber: "A204",
                    FloorNumber: 8,
                    Configuration: "1 BHK",
                    Owner: "Gabriel Soares",
                    ResidentName: "Sakane Gabriel",
                },
                {
                    id: 4,
                    UnitNumber: "A205",
                    FloorNumber: 7,
                    Configuration: "2 BHK",
                    Owner: "Miriam de Jes�s",
                    ResidentName: "Sakane Miikoes",
                },
                {
                    id: 5,
                    UnitNumber: "A206",
                    FloorNumber: 5,
                    Configuration: "3 BHK",
                    Owner: "Slavcho Karbashewski",
                    ResidentName: "Miiko",
                },
                {
                    id: 6,
                    UnitNumber: "A207",
                    FloorNumber: 1,
                    Configuration: "4 BHK",
                    Owner: "Somun Ae-Ri",
                    ResidentName: "Sakane",
                },
                {
                    id: 7,
                    UnitNumber: "A208",
                    FloorNumber: 10,
                    Configuration: "5 BHK",
                    Owner: "Andries",
                    ResidentName: "-",
                },
                {
                    id: 8,
                    UnitNumber: "A209",
                    FloorNumber: 14,
                    Configuration: "9 BHK",
                    Owner: "Sarah Davis ",
                    ResidentName: "Dowan Towan",
                },
                {
                    id: 9,
                    UnitNumber: "A2010",
                    FloorNumber: 19,
                    Configuration: "6 BHK",
                    Owner: "Miiko",
                    ResidentName: "Milanio",
                },
                {
                    id: 10,
                    UnitNumber: "A209",
                    FloorNumber: 20,
                    Configuration: "1 BHK",
                    Owner: "Miiko Sarah ",
                    ResidentName: "Rivera",
                },
                {
                    id: 11,
                    UnitNumber: "A2011",
                    FloorNumber: 11,
                    Configuration: "3 BHK",
                    Owner: "Grootoonk Sakane",
                    ResidentName: "Sakane Swapn",
                },
                {
                    id: 12,
                    UnitNumber: "A2012",
                    FloorNumber: 17,
                    Configuration: "4 BHK",
                    Owner: "Andries Grootoonk",
                    ResidentName: "Sakane Miiko",
                },
            ],
            open: false,
            openBuilding: false,
            currencyList: [],
            currencySelected: '',
            complexValues: {
                complexName: "",
                totalArea: "",
                totalFloors: '',
                totalUnits: '',
                aboutUs:'',
                currency:'',
                logo:null,
                images: [],
                accountManager:'',
            },
            totalAreaUnit:'Sqft',
            locationDetails:{
                locationLink: '',
                zipCode:'',
                landmark:'',
                streetName:'',
                officialAddress:'',
                longitude:'',
                latitude:'',
                country:'',
                city:'',
                state:''
            },
            userValues:{
                userName:'',
                role:''
            },
            accountManagerList:[],
            buildingValues: {
                aboutUs: '',
                buildingName: '',
                buildingArea: '',
                totalFloors: '',
                googleMapLocation:'',
                country: '',
                logo:null,
                images: []
            },
            savedBuildings:[],
            facilityValues:{
                facilityName: '',
                details: '',
                totalArea: '',
                fees: '',
                images:[],
                floorDocument:null,
            },
            savedFacility:[],
            currencyError: false,
            imagesError: false,
            imagesBuildingError: false,
            imagesFacilityError: false,
            logoError: false,
            logoBuildingError: false,
            countriesList: [],
            countrySelected:'',
            societyId:null,
            buildingList:null,
            buildingListData:[]
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount(): Promise<void> {
        // Customizable Area Start

        const accountRole = localStorage.getItem("role")

        if (accountRole) {
            const accountRoleName = accountRole.replace(/"/g, '')
            this.setState({ accountType:  accountRoleName})
        }

        this.exampleAPICall()
        await this.getJurisdictionList();
        await this.getCountryList()
        // Customizable Area End
    }

    async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
        if (this.state.stepComplex !== prevState.stepComplex) {
            if (this.state.stepComplex === 1) {
                if (this.state.accountType === 'coo') {
                    await this.getAccountManagerList()
                }
               await this.getCurrencyList();
               await this.getLease()
            }
            if (this.state.stepComplex === 3) {
                await this.getCountryList();
            }
            if (this.state.stepComplex === 4) {
                await this.getCountryList();
            }
            if (this.state.stepComplex === 6) {
                await this.getUserList();
                await this.getRoleList();
                await this.getBuildingListData()
            }
        }
    }

    // Customizable Area Start

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
    
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
          let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
          switch (apiRequestCallId) {
            case this.GetJurisdictionList:
              this.handleJurisdictionList(responseJson);
              break;
            case this.GetCurrencyList:
                this.handleCurrencyList(responseJson);
                break;
            case this.GetCountryList:
                this.handleCountriesList(responseJson);
                break;
            case this.SaveComplex:
                this.handleSaveComplex(responseJson);
                break;
            case this.GetLease:
                this.handleLeaseList(responseJson);
                break;
            case this.GetUserList:
                this.handleUserList(responseJson);
                break;
            case this.GetRoleList:
                this.handleRoleList(responseJson);
                break;
            case this.GetAccountManagerList:
                this.handleAccountManagerList(responseJson)
                break;
            case this.addJurisdictionCall:
                return this.handleAddJurisdiction(responseJson)
            case this.getUploadDocumentList:
                return this.handleDocumentSucc(responseJson)
            case this.addLocationApi:
                return this.handleLocationSucc(responseJson)
            case this.addBuildingApiCallId:
                return this.handleBuildingSuccess(responseJson)
            case this.addSharedApiCallId:
                return this.handleSharedSucc(responseJson)
            case this.GetBuildingListData:
                return this.handleBuildingListSuccess(responseJson)
            case this.addCoreMemberApiCallId:
                return this.handleSucc(responseJson)
            case this.getCoreMemberApi:
                return this.UserListSucc(responseJson)
            
            default:
              break;
          }
        
          this.setState({loading:false})
    
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
          } else {
            ApiErrorResponse(responseJson);
          }
          ApiCatchErrorResponse(errorResponse);
        }
        // Customizable Area End
      }

      handleCloseCreateModal = () => {
        this.setState({createConfirmModal: false}, () => {
            this.props.navigation?.history?.push('/CompanyEmployee/complexlisting')
        })
      }

      handleAccountManagerList = (responseJson:any) => {
        if (responseJson.account_managers) {
            this.setState({accountManagerList: responseJson.account_managers})
          }
      }
      handleDocumentSucc=(responseJson:any)=>{
        // this.handleNextComplexData()
        if(responseJson){
           
            this.setState({stepComplex:3})
        }
      }
      handleLocationSucc=(responseJson:any)=>{
       
        if(responseJson){
            this.handleNextComplexData()
            this.setState({stepComplex:4})
        }
       
      }
      handleSharedSucc=(responseJson:any)=>{
        
      }
  
      handleBuildingSuccess=(responseJson:any)=>{
      
        if(responseJson.data){
            this.setState({buildingValues: {
                aboutUs: '',
                buildingName: '',
                buildingArea: '',
                totalFloors: '',
                googleMapLocation:'',
                country: '',
                logo:null,
                images:[]
            }}, () => {
                this.handleClickOpenBuilding()
               
            })
        

        }
       
        
      }
      handleBuildingListSuccess=(response:any)=>{
        this.setState({buildingListData:response.buildings})
      }
      handleSucc=(response:any)=>{
        this.setState({ memberValues:{
            email:'',
            phone:'',
            name:'',
            role:'',
            buildingName:'',
            unit:''
        },})
      }
    UserListSucc=(response:any)=>{
        this.setState({savedMembers:[...this.state.savedMembers,response.data]})
    }
      handleAddJurisdiction=(responseJson:any)=>{
        if(responseJson.data){  
           
            this.setState({societyId:responseJson.data.id,}) 
            // this.handleNextComplexData()
            this.setState({stepComplex:2})
    }
      }

      getJurisdictionList = async () => {
        this.GetJurisdictionList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_society_management/company_employee/jurisdictions`,
          });
    }

    getCurrencyList = async () => {
        this.GetCurrencyList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_posts/classifieds/currency_list`,
          });
    }

    getLease = async () => {
        this.GetLease = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_contract/lease_templates`,
          });
    }
    
    getUserList = async () => {
       
        this.GetUserList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_my_team/team_members/user_name_list`,
          });
    }

    getRoleList = async () => {
        this.GetRoleList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `bx_block_roles_permissions/roles`,
          });
    }
    getBuildingListData=async()=>{
        this.GetBuildingListData = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_announcement/announcements/building_list?society_management_id=43`,
          });
    }
    getCountryList = async () => {
        this.GetCountryList = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_real_estate_companies/list_country_name`,
          });
    }

    getAccountManagerList = async () => {
        this.GetAccountManagerList = await apiCall({
          contentType: "application/json",
          method: "GET",
          endPoint: '/bx_block_real_estate_companies/account_manager_list',
        });
      }

    handleSaveComplex = (responseJson:any) => {
        if (responseJson.data) {
            this.setState({createConfirmModal: true})
        }
    }

    handleLeaseList = (responseJson:any) => {
        if (responseJson?.data.length) {
            this.setState({leaseList: responseJson.data, rentalSelected: responseJson.data[0].attributes.id})
        }
    }

    handleUserList = (responseJson:any) => {
        if(responseJson?.status.length) {
            this.setState({userList: responseJson?.status})
        }
    }

    handleRoleList = (responseJson:any) => {
        if(responseJson?.data.length) {

            let value= responseJson?.data.map((data:any)=>({
             "label" : data.attributes.name,
             "value" : data.id
            }
             
             ))
             this.setState({roleList: value})
         }
    }

    handleUserSelect = (val:any) => {
        this.setState({userSelect: val})
    }

    handleSelectLease = (id:any) => {
        this.setState({rentalSelected: id, leasePopup: false})
    }

    handleOpenDeleteBuildingPopup = (i:any) => {
        this.setState({
            deleteBuildingPopup: true,
            deleteBuildingIndex: i
        })
    }

    handleCloseDeleteBuildingPopup = () => {
        this.setState({
            deleteBuildingPopup: false
        })
    }

    handleOpenDeleteFacilityPopup = (i:any) => {
        this.setState({
            deleteFacilityPopup: true,
            deleteFacilityIndex: i
        })
    }

    handleOpenDeleteMemberPopup = (i:any) => {
        this.setState({
            deleteMemberPopup: true,
            deleteMemberIndex: i
        })
    }

    handleCloseDeleteFacilityPopup = () => {
        this.setState({
            deleteFacilityPopup: false
        })
    }

    handleCloseDeleteMemberPopup = () => {
        this.setState({
            deleteMemberPopup: false
        })
    }

    handleOpenLeasePopup = (index:number) => {
        this.setState({leasePopup: true, leaseSelected:index})
    }

    handleCloseLeasePopup = () => {
        this.setState({leasePopup: false})
    }

    handleSave = async () => {
        const formData = new FormData();
        
        // step 1 data
        formData.append('jurisdiction_id', this.state.jurisdictionSelected.attributes.id)

        if (this.props.companyId) {
            formData.append('society_management[real_estate_company_id]', this.props.companyId)
        }

        // step 2 data
        formData.append('society_management[name]', this.state.complexValues.complexName)
        formData.append('society_management[description]', this.state.complexValues.aboutUs)
        formData.append('society_management[complex_area]', this.state.complexValues.totalArea)

        //@ts-ignore
        formData.append('society_management[logo]', this.state.complexValues.logo)
        
        this.state.complexValues.images.length && this.state.complexValues.images.forEach((image:any) => {
            formData.append('society_management[photos][]', image)
        })
        
        // step 3 data
        

        // step 4 data
       

        // step 5 data
        this.state.savedBuildings.length && this.state.savedBuildings.forEach((building:any, i:number) => {
            formData.append(`society_management[building_managements_attributes][${i}][name]`, building.buildingName)
            formData.append(`society_management[building_managements_attributes][${i}][number_of_floor]`, building.totalFloors)
            formData.append(`society_management[building_managements_attributes][${i}][building_area]`, building.buildingArea)

            formData.append(`society_management[building_managements_attributes][${i}][logo]`, building.logo)

            formData.append(`society_management[building_managements_attributes][${i}][per_floor_unit]`, '2')

            formData.append(`society_management[building_managements_attributes][${i}][generation_methods]`, '1, 2, 3')

            building.images.length && building.images.forEach((photo:any) => {
                formData.append(`society_management[building_managements_attributes][${i}][photos][]`, photo)
            })

        })

        // step 6 data
        this.state.savedFacility.length && this.state.savedFacility.forEach((facility:any, i:number) => {
            formData.append(`society_management[common_areas_attributes][${i}][name]`, facility.facilityName)
            formData.append(`society_management[common_areas_attributes][${i}][details]`, facility.details)
            formData.append(`society_management[common_areas_attributes][${i}][total_area]`, facility.totalArea)
            formData.append(`society_management[common_areas_attributes][${i}][reservation_fee]`, facility.fees)
            // formData.append(`society_management[common_areas_attributes][floor_plan]`, facility.floorDocument)

            facility.images.length && facility.images.forEach((photo:any) => {
                formData.append(`society_management[common_areas_attributes][${i}][photos][]`, photo)
            })
        })

        
       
    };

    handleCountriesList = (responseJson: any) => {
        if (responseJson?.country_list) {
            this.setState({countriesList: responseJson.country_list})
        }
      };

    handleJurisdictionList = (responseJson:any) => {
        if (responseJson?.jurisdictions?.data.length) {
            this.setState({jurisdictionList: responseJson.jurisdictions.data})
        }
    }

    handleCurrencyList = (responseJson:any) => {
        if (responseJson?.data.length) {
            
             this.setState({currencyList: responseJson.data})
        }
    }
    handleNextComplexUpload=async()=>{
      
      
        const formData = new FormData();

        formData.append("policy",this.state.selectedFilePdfComplex[0] );
        formData.append("roles",this.state.selectedFilePdfRolesComplex[0]);
       
        formData.append("building_plan", this.state.selectedFilePdfBuildingPlansComplex[0]);
        formData.append("guideline", this.state.selectedFilePdfGuidelinesComplex[0]);
        formData.append("resolution", this.state.selectedFilePdfResolutionsComplex[0]);
      
          this.getUploadDocumentList = await apiCall({
            
            method: "POST",
            endPoint: `/society_managements/23/bx_block_my_document/upload_document`,
            body:formData
          });
    }
    handleNextComplexLocation=async()=>{
        const formData = new FormData();
        formData.append('address[locationlink]',this.state.locationDetails.locationLink)
        formData.append('address[latitude]', this.state.locationDetails.latitude)
        formData.append('address[longitude]', this.state.locationDetails.longitude)
        formData.append('address[country]', this.state.locationDetails.country)
        formData.append('address[address]', this.state.locationDetails.officialAddress)
        formData.append('address[state]', this.state.locationDetails.state)
        formData.append('address[city]', this.state.locationDetails.city)
        formData.append('address[zipcode]', this.state.locationDetails.zipCode)
        formData.append('address[landmark]', this.state.locationDetails.landmark)
        formData.append('address[name]', this.state.locationDetails.streetName)
        formData.append('address[society_management_id]', this.state.societyId)
     


        this.addLocationApi = await apiCall({
     
            method: "POST",
            endPoint: `/bx_block_address/create_address`,
            body:formData
          });
    }
    handleNextComplex = async() => {
        const formData = new FormData();
        // step 1 data
        formData.append('jurisdiction_id', this.state.jurisdictionSelected.attributes.id)
        formData.append('society_management[name]', this.state.complexValues.complexName)
        formData.append('society_management[description]', this.state.complexValues.aboutUs)
        formData.append('society_management[complex_area]', this.state.complexValues.totalArea)
        formData.append('society_management[total_floor]', this.state.complexValues.totalFloors)
        formData.append('society_management[total_unit]', this.state.complexValues.totalUnits)
        formData.append('society_management[currency_id]', this.state.complexValues.currency)
        
        //@ts-ignore
        formData.append('society_management[logo]', this.state.complexValues.logo)
        
        this.state.complexValues.images.length && this.state.complexValues.images.forEach((image:any) => {
            formData.append('society_management[photos][]', image)
        })
        
        this.addJurisdictionCall = await apiCall({
            method: "POST",
            endPoint: `/bx_block_society_management/company_employee/complexes`,
            body:formData
          });
    };
    // handleNextDocumentData=()=>{

    // }
    handleNextComplexData=()=>{
       
        this.setState({ stepComplex: this.state.stepComplex+1,}, () => {
            if (this.state.tempStepComplex < this.state.stepComplex) {
                this.setState({tempStepComplex: this.state.stepComplex})
            }
        });
   
    }

    handleNavigateBuildingBlock = () => {
        this.props.navigation?.history?.push('/CompanyEmployee/ComplexBuildingMainBlock')
    }

    handleBackComplex = () => {
        this.setState({ stepComplex: this.state.stepComplex - 1 });
    };
   

    handleLocationDetails = (values:any) => {
       
        
        this.setState({locationDetails: values}, () => {
            this.handleNextComplexLocation()
           
        })
    }

    handleBasicDetails = (values: any) => {
        let error = false
       
        if(!error) {
           
            this.setState({complexValues: values}, () => {
                this.handleNextComplex()
               
            })
        }
    }
    handleBasicDetailsData=async(values:any)=>{
        this.handleBasicUserListData(values)
    }
    handleBasicUserListData=async(values:any)=>{
        this.sharedCoreMembers()
        this.getCoreMemberApi = await apiCall({
            contentType: "application/json",
            method: "GET",
            endPoint: `/bx_block_my_team/company_employee/employee_team_members/employee_details?employee_id=${values.userName.id}`,
          });
    }
    handleFieldChange = (fieldName:any, value:any) => {
        this.setState((prevState:any) => ({
            complexValues: {
                ...prevState.complexValues,
                [fieldName]: value
            }
        }))
    }

    handleTotalAreaUnit=(e:any) => {
        this.setState({totalAreaUnit: e.target.value})
    }

    handleLocationFieldChange = (fieldName:any, value:any) => {
        this.setState((prevState:any) => ({
            locationDetails: {
                ...prevState.locationDetails,
                [fieldName]: value
            }
        }))
    }

    handleRemoveBuildingDetails = (index:number) => {
        const buildingArr = [...this.state.savedBuildings]
        buildingArr.splice(index, 1)
        this.setState({savedBuildings: buildingArr, deleteBuildingPopup: false})
    }

    handleRemoveFacilityDetails = (index:number) => {
        const facilityArr = [...this.state.savedFacility]
        facilityArr.splice(index, 1)
        this.setState({savedFacility: facilityArr, deleteFacilityPopup: false})
    }

    handleRemoveMember = (index:number) => {
        const memberArr = [...this.state.savedMembers]
        memberArr.splice(index, 1)
        this.setState({savedMembers: memberArr, deleteMemberPopup: false})
    }
    
    handleBuildingDetails = async(values: any) => {
        let error = false

        if(!error) {
            const savedBuildingObj = {
                ...values
            }
            const formData = new FormData();
            formData.append("society_management_id",this.state.societyId)
            formData.append("building_management[name]",values.buildingName );
           
            formData.append("building_management[description]", values.aboutUs);
            formData.append("building_management[number_of_floor]", values.totalFloors);
            formData.append("building_management[country]", values.country);
            formData.append("building_management[building_area]", values.buildingArea);
            formData.append("building_management[logo]", values.logo);
            values.images.length && values.images.forEach((photo:any) => {
                formData.append(`building_management[photos][]`, photo)
            })
            formData.append("building_management[generation_methods]", '1, 2, 3');
            formData.append("building_management[googlemaplocation]", values.googleMapLocation);
          
          
            this.addBuildingApiCallId = await apiCall({
              body:formData,
              method: "POST",
              endPoint: `bx_block_society_management/company_employee/complexes/create_complex_building`,
            });
            this.setState({savedBuildings: [...this.state.savedBuildings, savedBuildingObj]})
           
        }
    }

    handleSharedDetails = (values: any) => {
        let error = false

        if(!error) {
            const savedFacilityObj = {
                ...values,
            }

            this.setState({facilityValues: values, savedFacility: [...this.state.savedFacility, savedFacilityObj]}, () => {
                this.handleSaveSharedFacility()
                this.AddSharedFacility()
            })
        }
    }
    AddSharedFacility=async()=>{
        const dataForm = new FormData();
        this.state.savedFacility.length && this.state.savedFacility.forEach((facility:any, i:number) => {
            dataForm.append(`common_area[name]`, facility.facilityName)
            dataForm.append(`common_area[details]`, facility.details)
            dataForm.append(`common_area[total_area]`, facility.totalArea)
            dataForm.append(`common_area[reservation_fee]`, facility.fees)
            dataForm.append(`common_area[floor_plan]`, facility.floorDocument)
            dataForm.append('common_area[society_management_id]',this.state.societyId)
            facility.images.length && facility.images.forEach((photo:any) => {
                dataForm.append(`common_area[photos][]`, photo)
            })
        })
  
    this.addSharedApiCallId = await apiCall({
      body:dataForm,
      method: "POST",
      endPoint: `/bx_block_society_management/common_areas`,
    });
    }

    handleMemberDetails = (values:any) => {
        if (values) {
            const savedMember = {...values}
            this.setState({savedMembers: [...this.state.savedMembers,     savedMember]}, () => {
                this.sharedCoreMembers()
                this.sharedCreateNewMember()
                this.createNewMember(values)
            })
        }
    }
    createNewMember=async(values:any)=>{
        const data = new FormData();
        data.append(`team_member[society_management_id]`, this.state.societyId)
            data.append(`team_member[role_id]`, values.role.value)
            data.append(`team_member[name]`,values.name)
            data.append(`team_member[email]`, values.email)
           
            data.append(`team_member[phone_number]`, values.phone)
            data.append(`team_member[unit]`, values.unit)
            data.append(`team_member[account_type]`, "true")
            data.append('team_member[building_management_id]', values.buildingName.id)
            this.addCoreMemberApiCallId = await apiCall({
                body:data,
                method: "POST",
                endPoint: `/bx_block_my_team/team_members/create_team_member`,
              });
        
        
    }

    exampleAPICall = async () => {
        // this.exampleAPICallId = await this.apiCallForFileUpload({
        //     method: "POST",
        //     endPoint: `endPoint`,
        //     body: { data: {} }
        // });
    }

    handleAddMember = () => {
        this.setState({coreMembers: false})
    }

    picherPortablesComplexBuilding = (event: any) => {
        const fileComplex = event.target.files[0];
        if (fileComplex) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                this.setState({
                    selectedFileBuliding: fileComplex,
                    imageURLBuilding: event.target.result,
                    logoBuildingError: false
                });
            };
            reader.readAsDataURL(fileComplex);
        }
    }

    handleFileChangesComplex = (event: any) => {
        const inputPicherPortableComplex = document.createElement('input');
        inputPicherPortableComplex.type = 'file';
        inputPicherPortableComplex.accept = "application/pdf"
        inputPicherPortableComplex.multiple = true
        inputPicherPortableComplex.click();
        inputPicherPortableComplex.onchange = (event: any) => {
            this.handleFilePortableComplex(event)
        }
    };

    handleFilePortableComplex = (event: any) => {
        const file = event.target.files[0];
        const imagesArrays = [...this.state.selectedFilePdfComplex];
        imagesArrays.push(file);
        this.setState({ selectedFilePdfComplex: imagesArrays });
    }

    handleRemoveFileComplex = (fileIndex: any) => {
        const updatedFilesComplex = this.state.selectedFilePdfComplex.filter((value: any, index: any) => index !== fileIndex);
        this.setState({ selectedFilePdfComplex: updatedFilesComplex });
    };

    handleFileChangesGuidelinesComplex = (event: any) => {
        const inputPicherGuidelinesComplex = document.createElement('input');
        inputPicherGuidelinesComplex.type = 'file';
        inputPicherGuidelinesComplex.accept = "application/pdf"
        inputPicherGuidelinesComplex.multiple = true
        inputPicherGuidelinesComplex.click();
        inputPicherGuidelinesComplex.onchange = (event: any) => {
            this.handleFileGuidelinesComplex(event)
        }
    };

    handleFileGuidelinesComplex = (event: any) => {
        const GuidelinesFile = event.target.files[0];
        const imagesArraysGuidelines = [...this.state.selectedFilePdfGuidelinesComplex];
        imagesArraysGuidelines.push(GuidelinesFile);
        this.setState({ selectedFilePdfGuidelinesComplex: imagesArraysGuidelines });
    }

    handleRemoveFileGuidelinesComplex = (fileIndex: any) => {
        const updatedFilesGuidelines = this.state.selectedFilePdfGuidelinesComplex.filter((value: any, index: any) => index !== fileIndex);
        this.setState({ selectedFilePdfGuidelinesComplex: updatedFilesGuidelines });
    };

    handleFileChangesRolesComplex = (event: any) => {
        const inputPicherPortableRolesComplex = document.createElement('input');
        inputPicherPortableRolesComplex.type = 'file';
        inputPicherPortableRolesComplex.accept = "application/pdf"
        inputPicherPortableRolesComplex.multiple = true
        inputPicherPortableRolesComplex.click();
        inputPicherPortableRolesComplex.onchange = (event: any) => {
            this.handleFileRolesComplex(event)
        }
    };

    handleFileRolesComplex = (event: any) => {
        const RolesFile = event.target.files[0];
        const imagesArraysRoles = [...this.state.selectedFilePdfRolesComplex];
        imagesArraysRoles.push(RolesFile);
        this.setState({ selectedFilePdfRolesComplex: imagesArraysRoles });
    }

    handleRemoveFileRolesComplex = (fileIndex: any) => {
        const updatedFilesRoles = this.state.selectedFilePdfRolesComplex.filter((value: any, index: any) => index !== fileIndex);
        this.setState({ selectedFilePdfRolesComplex: updatedFilesRoles });
    };
    handleFileChangesResolutionsComplex = (event: any) => {
        const inputPicherPortableResolutionsComplex = document.createElement('input');
        inputPicherPortableResolutionsComplex.type = 'file';
        inputPicherPortableResolutionsComplex.accept = "application/pdf"
        inputPicherPortableResolutionsComplex.multiple = true
        inputPicherPortableResolutionsComplex.click();
        inputPicherPortableResolutionsComplex.onchange = (event: any) => {
            this.handleFileResolutionsComplex(event)
        }
    };

    handleFileResolutionsComplex = (event: any) => {
        const ResolutionsFile = event.target.files[0];
        const imagesArraysResolutions = [...this.state.selectedFilePdfResolutionsComplex];
        imagesArraysResolutions.push(ResolutionsFile);
        this.setState({ selectedFilePdfResolutionsComplex: imagesArraysResolutions });
    }

    handleRemoveFileResolutionsComplex = (fileIndex: any) => {
        const updatedFilesResolutions = this.state.selectedFilePdfResolutionsComplex.filter((value: any, index: any) => index !== fileIndex);
        this.setState({ selectedFilePdfResolutionsComplex: updatedFilesResolutions });
    };

    handleFileChangesBuildingPlansComplex = (event: any) => {
        const inputPicherPortableBuildingPlansComplex = document.createElement('input');
        inputPicherPortableBuildingPlansComplex.type = 'file';
        inputPicherPortableBuildingPlansComplex.accept = "application/pdf"
        inputPicherPortableBuildingPlansComplex.multiple = true
        inputPicherPortableBuildingPlansComplex.click();
        inputPicherPortableBuildingPlansComplex.onchange = (event: any) => {
            this.handleFileBuildingPlansComplex(event)
        }
    };

    handleFileBuildingPlansComplex = (event: any) => {
        const BuildingPlansFile = event.target.files[0];
        const imagesArraysBuildingPlans = [...this.state.selectedFilePdfBuildingPlansComplex];
        imagesArraysBuildingPlans.push(BuildingPlansFile);
        this.setState({ selectedFilePdfBuildingPlansComplex: imagesArraysBuildingPlans });
    }

    handleRemoveFileBuildingPlansComplex = (fileIndex: any) => {
        const updatedFilesBuildingPlans = this.state.selectedFilePdfBuildingPlansComplex.filter((value: any, index: any) => index !== fileIndex);
        this.setState({ selectedFilePdfBuildingPlansComplex: updatedFilesBuildingPlans });
    };

    // CoreMembers
    sharedCoreMembers = () => {
        this.setState(prevState => ({
            coreMembers: !prevState.coreMembers,
        }));
    };

    findPersonPopup = () => {
        this.setState(prevState => ({
            findPopupOpen: !prevState.findPopupOpen,
        }));
    };
    // createNewMember

    sharedCreateNewMember = () => {
        this.setState(prevState => ({
            createNewMember: !prevState.createNewMember,
        }));
    };

    handleChangePage = (event: any, newPage: any) => {
        this.setState({ page: newPage - 1 });
    };

    // FloorPlan
    handleFileChangesFloorPlanComplex = (event: any) => {
        const inputPicherPortableComplex = document.createElement('input');
        inputPicherPortableComplex.type = 'file';
        inputPicherPortableComplex.accept = "application/pdf"
        inputPicherPortableComplex.multiple = true
        inputPicherPortableComplex.click();
        inputPicherPortableComplex.onchange = (event: any) => {
            this.handleFileFloorPlanComplex(event)
        }
    };
    sharedFacilityPopupComplex = () => {
        this.setState(prevState => ({
            facilityPopupComplex: !prevState.facilityPopupComplex,
        }));
    };

    handleChangeComplex = (event: any) => {
        const selectedVariants = event?.target.value;
        this.setState({ jurisdictionSelected: selectedVariants, complexValues: {
            ...this.state.complexValues,
            currency: selectedVariants.attributes?.currency
        } });
    };

    handleChangeCurrency = (e: any) => {
        this.setState({ currencySelected: e.target.value, currencyError: false });
    }

    handleCountryChange = (e:any) => {
        this.setState({countrySelected: e.target.value})
    }

    handleFileFloorPlanComplex = (event: any) => {
        const file = event.target.files[0];
        const imagesArrays = [...this.state.selectedFilePdfFloorPlanComplex];
        imagesArrays.push(file);
        this.setState({ selectedFilePdfFloorPlanComplex: imagesArrays });
    }

    handleRemoveFileFloorPlanComplex = (fileIndex: any) => {
        const updatedFiles = this.state.selectedFilePdfFloorPlanComplex.filter((value: any, index: any) => index !== fileIndex);
        this.setState({ selectedFilePdfFloorPlanComplex: updatedFiles });
    };




    handleCloseComplex = () => {
        this.setState({ open: false });
    };



    handleCloseBuilding = () => {
        this.setState({ openBuilding: false });
       
    };

    handleClickOpenBuilding = () => {
        this.setState({ openBuilding: true ,findPopupOpen:false});
        this.findPersonPopup()
        
    };

    handleSaveSharedFacility = () => {
        this.setState({facilityPopupComplex: false})
    }

    uploadName = () => {
        return (
            <>
                {this.state.selectedFilePdfRolesComplex == 0 && "Upload Roles Documents"}
            </>
        )
    }

    uploadResolutions = () => {
        return (
            <>
                {this.state.selectedFilePdfResolutionsComplex == 0 && "Upload Resolutions Documents"}
            </>
        )
    }


    handleClickOpenComplex = () => {
        this.setState({ open: true });
    };

    handleStepClick = (index:number) => {
        index <= this.state.tempStepComplex && this.setState({ stepComplex: index })
    }

    multiPleButtonHaderComplex = () => {
        return (
            <>
                {this.state.multiPleButtonComplex.map((val: any, index: number) => {
                    return (<Box key={index} className="bold-text" style={{ width: 'max-content', height: '43px', marginRight: '15px', backgroundColor: this.state.stepComplex == index ? "#2B6FED" : "#C8C8C81F", borderRadius: "21.5px", display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                        {
                             this.state.stepComplex === index ? <Button 
                             onClick={() => this.handleStepClick(index)}
                             className="bold-text higlight-selected"
                             data-testid="stepComplexBtn"
                             style={{ color: "#FFFFFF", fontWeight: 800, padding: '0px 20.5px', textTransform: 'capitalize' }}>{val}</Button> : <Button 
                             onClick={() => this.handleStepClick(index)}
                             className="higlight-selected"
                             data-testid="stepComplexBtn"
                             style={{ color: this.state.tempStepComplex < index ? 'grey' : "#2B6FED",  fontWeight: 800, padding: '0px 20.5px', textTransform: 'capitalize' }}>{val}</Button>
                        }
                    </Box>)
                })
                }
            </>
        )
    }

    imgUplodeAvtarComplex = () => {
        return (
            <Box style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                 {
                    this.state.imageURLComplex !== "" ? <img style={{ border: '1px solid black', borderRadius: '100%', width: '100px', height: '100px' }} src={this.state.imageURLComplex} /> : <Box style={{
                        width: '66px',
                        height: '66px',
                        borderRadius: '50%',
                        background: 'rgb(220, 220, 220) none repeat scroll 0% 0%'
                    }} />
                }
            </Box>
        )
    }
    // Add New Building


    uploadGuidelinesComplex = () => {
        return (
            <Box style={{ paddingLeft: "16px", color: 'rgb(141, 141, 141)', fontSize: '16px', fontWeight: 400 }}>{this.state.selectedFilePdfGuidelinesComplex == 0 ? "Upload Guidelines Documents" : ""}</Box>
        )
    }

    imgUplodeAvtar = () => {
        return (
            <Box style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                {
                    this.state.imageURLBuilding !== "" ? <img style={{ border: '1px solid black', borderRadius: '100%', width: '100px', height: '100px' }} src={this.state.imageURLBuilding} /> : <Box style={{
                        width: '66px',
                        height: '66px',
                        borderRadius: '50%',
                        background: 'rgb(220, 220, 220) none repeat scroll 0% 0%'
                    }} />
                }
            </Box>
        )
    }
    // Customizable Area End
}
