export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

//Image
export const Lock_Icon = require("../assets/password_icon.png");
export const Building_Logo = require("../assets/BuildingLogo.png");
export const Building1 = require("../assets/building1.png");
export const Building2 = require("../assets/building2.png");
export const Tenant_Logo = require("../assets/TenantLogo.png");
export const Landing_Banner = require("../assets/landing_banner.png");
export const Grid_Icon = require("../assets/grid-icon.png");
export const Filter_Icon = require("../assets/filter-icon.png");
export const Back_Icon = require("../assets/back-icon.png");
export const Upload_Icon = require("../assets/upload-icon.png");
export const Calender_Icon = require("../assets/calendar_icon.png");
export const User_Icon = require("../assets/user-icon.png");
export const Info_Icon = require("../assets/info-icon.png");
export const Tick_Circle_Icon = require("../assets/tick-circle-icon.png");
export const Tick_Icon = require("../assets/tick-icon1.png");
export const Clipboard_Icon = require("../assets/clipboard-icon.svg");
export const Warning_Icon = require("../assets/warning-icon.svg");
export const House_Icon = require("../assets/house-icon.svg");
export const Users_Icon = require("../assets/users-icon.png");
export const Bank_Icon = require("../assets/bank-icon.svg");
export const Box_Icon = require("../assets/box-icon.svg");
export const Checkmark_Icon = require("../assets/checkmark-icon.png");
export const Error_Icon = require("../assets/error-icon.png");
export const Close_Icon = require("../assets/icon-close.png");
export const Dollar_Icon = require("../assets/dollar_icon.png");
export const Setting_Icon = require("../assets/setting_icon.png");
export const Classified_CorrectIcon = require("../assets/classified_correct_icon.png");
export const BuyIcon = require("../assets/buy_icon.png");
export const GenericIcon = require("../assets/generic_icon.png");
export const SellerIcon = require("../assets/seller_icon.png");
export const BuyIconSelected = require("../assets/buy_selected.png");
export const GenericIconSelected = require("../assets/generic_selected.png");
export const SellerIconSelected = require("../assets/seller_selected.png");
export const EmailIcon = require("../assets/email_icon.png");
export const DescIcon = require("../assets/desc_icon.png");
export const TitleIcon = require("../assets/title_icon.png");
export const CurrencyIcon = require("../assets/currency_icon.png");
export const CloseIcon = require("../assets/close_icon.png");
export const PayDetailIcon = require("../assets/payment_detail_icon.png");
export const TimeIcon = require("../assets/time_icon.png");
export const DeleteIcon = require("../assets/delete_icon.png");
export const NoClassifiedIcon = require("../assets/no-classified-icon.png");
export const PdfImage = require("../assets/pdf.png");
export const BuildingLogo = require("../assets/building1.png");
export const incedentBuilding = require("../assets/incedentBuilding.png");
export const incedentUnit = require("../assets/incedentUnit.png");
export const incedentUser = require("../assets/incedentUser.png");
export const cartBlue = require("../assets/CartBlue.png");
export const GenericBlue = require("../assets/GenericRequestblue.png");
export const SellerBlue = require("../assets/sellerAccountBlue.png");
export const calenderBlueIcon = require("../assets/calender-icon-blue.png");
export const VideoIcon = require("../assets/video.jpg");
export const imgIcon = require("../assets/imgIcon.png");
export const NoDataImg = require("../assets/noData.png");
export const TanentProfileIcon = require("../assets/profileIcon.png");
export const LocationIcon = require("../assets/location.png");
export const TemplateIcon = require("../assets/template.png");
export const DownloadIcon = require("../assets/downloadIcon.png");
export const ComplexIcon = require("../assets/complex.png");
export const BuildingIcon = require("../assets/buildingGray.png");
export const ProfileIcon = require("../assets/profileIcon.png");
export const CalenderIcon = require("../assets/calendar_icon.png");
export const UnitIcon = require("../assets/unit.png");
export const InfoIcon = require("../assets/info-icon.png");
export const TickIcon = require("../assets/tick-circle-icon.png");
export const CalenderIconGray = require("../assets/calenderGray.png");
export const InfoIcon2 = require("../assets/inforIcon.png");
export const user_icon = require("../assets/user.png");
export const email_icon = require("../assets/email-icon.png");
export const CircleCrossIcon = require("../assets/circleCross.png");
export const mapLocation = require("../assets/map-location.png");
export const uploadDocIcon = require("../assets/upload-doc.png");
