import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";


export interface Props {
    stepBuilding:any,
    selectedFilePdfComplex:any,
    handleRemoveFileBuilding:any,
    handleFileChangesBuilding:any,
    selectedFilePdfGuidelinesComplex: any,
    handleRemoveFileGuidelinesBuilding:any,
    uploadGuidelinesBuilding:any,
    handleFileChangesGuidelinesBuilding:any,
    selectedFilePdfRolesComplex:any,
    handleRemoveFileRolesBuilding:any,
    multipleButton:any,
    uploadNameBuilding:any,
    handleFileChangesRolesBuilding:any,
    selectedFilePdfResolutionsComplex:any,
    handleRemoveFileResolutionsBuilding:any,
    uploadResolutionsBuilding:any,
    handleFileChangesResolutionsBuilding:any,
    selectedFilePdfBuildingPlansComplex:any,
    handleRemoveFileBuildingPlansBuilding:any,
    handleFileChangesBuildingPlansBuilding:any,
    t?: any
    i18n?: any
    match?: any
    location?: any
    // Customizable Area Start
    history?: any
    handleNext:any;
    handleBackBuilding:any;
    language?: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CompEmpBuildingBlockStep3Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
    exampleAPICallId: string = "";

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

        this.state = {
            // Customizable Area Start
            loading: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    //   }

    async componentDidMount(): Promise<void> {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
}
