//Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { toast } from "react-hot-toast";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
// @ts-ignore
import {baseURL} from "../../../../framework/src/config";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
  companyId?: number
}

interface S {
  countriesList:any,
  cityList:any,
  regionList:any,
  logoImage: any,
  previewLogoUrl: any,
  proofDoc:any,
  logoError:boolean,
  proofError:boolean,
  initialValues:any;
  fileName:string;
  editCheck:boolean;
  createConfirmModal: boolean;
  editConfirmModal: boolean;
  loading: boolean;
  accountType: string;
  accountManager: any;
  salesManager: any;
  samePhoneValidation:boolean;
  sameEmailValidation:boolean;
}

interface SS {
  id: any;
}

export default class RealEstateCompanyFormController extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetCountriesFilterList: any;
  GetRegionsFilterList: any;
  GetCityFilterList:any;
  GetAccountFilterList:any;
  GetSalesFilterList:any;
  CreateCompany: any;
  GetCompanyDetails:any;
  UpdateCompany:any;
  
  constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
      this.handleCompanyData = this.handleCompanyData.bind(this)
      this.handleFileRemove=this.handleFileRemove.bind(this)
      this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.SessionResponseMessage),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionSaveMessage),
      ];
      this.state = {
        createConfirmModal: false,
        editConfirmModal: false,
        samePhoneValidation: false,
        sameEmailValidation: false,
        editCheck: false,
        countriesList:[],
        cityList:[],
        regionList:[],
        accountManager: [],
        salesManager: [],
        logoImage: null,
        previewLogoUrl: null,
        proofDoc:null,
        logoError:false,
        proofError:false,
        loading:false,
        fileName: '',
        accountType: '',
        initialValues: {
          companyName: '',
          primaryContact: {
            contactName: '',
            emailAddress: '',
            phoneNumber: '',
          },
          secondaryContact: {
            contactName: '',
            emailAddress: '',
            phoneNumber: '',
          },
          country: '',
          region: '',
          city: '',
          zipCode: '',
          managedBy:'',
          googleMapLocation: '',
          hqLocationAddress: '',
          salesManager: '',
          accountManager: '',
          commercialRegistrationNumber: '',
        }
      };
      runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  handleImageChange = (event:any) => {
    const selectedImage = event.target.files[0];
    
    this.setState({
      logoImage:selectedImage,
      previewLogoUrl: URL.createObjectURL(selectedImage),
      logoError: false
    });
  };

  handlePhoneValidation = (phone1:string, phone2:string) => {
    if (phone1 && phone2) {
      if (phone1 === phone2) {
        this.setState({samePhoneValidation: true})
      } else {
        this.setState({samePhoneValidation: false})
      }
    }
  }

  handleEmailValidation = (email1:string, email2:string) => {
    if (email1 && email2) {
      if (email1 === email2) {
        this.setState({sameEmailValidation: true})
      } else {
        this.setState({sameEmailValidation: false})
      }
    }
  }

  handleCloseCreateModal = () => {
    this.setState({createConfirmModal: false})
    this.props.navigation.history.push('/CompanyEmployee/realestate-companies')
  }

  handleCloseEditModal = () => {
    this.setState({editConfirmModal: false})
    this.props.navigation.history.push('/CompanyEmployee/realestate-companies')
  }

  async componentDidMount() {
    const filterCall = [
      {
        name: 'GetCountriesFilterList',
        endPoint: 'list_country_name',
      },
      {
        name: 'GetCityFilterList',
        endPoint: `region_city_by_country?country=${this.state.initialValues.country}`,
      }
    ]

    const accountRole = localStorage.getItem("role")

    if (accountRole) {
      const accountRoleName = accountRole.replace(/"/g, '')
      this.setState({ accountType:  accountRoleName})

      if (accountRoleName === "coo") {
        const accountManagerObj = {
          name: 'GetAccountFilterList',
          endPoint: 'account_manager_list'
        }
        filterCall.push(accountManagerObj)
        const salesManagerObj = {
          name: 'GetSalesFilterList',
          endPoint: 'sales_manager_list'
        }
        filterCall.push(salesManagerObj)
      }
    }

    await Promise.all(
      filterCall.map((filter) =>
        this.getFilterListForm(filter.name, `/bx_block_real_estate_companies/${filter.endPoint}`)
      )
    )

    if (this.props.companyId) {
      this.fetchData(this.props.companyId);
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetCountriesFilterList:
          this.handleCountriesFilterList(responseJson)
          break;
        case this.GetCityFilterList:
          this.handleCitiesFilterList(responseJson)
          break;
        case this.GetAccountFilterList:
          this.handleAccountFilterList(responseJson)
          break;
        case this.GetSalesFilterList:
          this.handleSalesFilterList(responseJson)
          break;
        // case this.GetRegionsFilterList:
        //   this.handleRegionsFilterList(responseJson)
        //   break;
        case this.CreateCompany:
          this.handleCreateSuccess(responseJson)
          break;
        case this.GetCompanyDetails:
          this.handleCompanyData(responseJson)
        case this.UpdateCompany:
          this.handleUpdateCompany(responseJson)
        default:
          break;
      }
      this.setState({loading: false})

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  fetchData = async (companyId: number) => {
    this.setState({loading: true})
    this.GetCompanyDetails = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_real_estate_companies/real_estate_companies/${companyId}`,
    });
  }

  handleCountryChange = (countryName:string) => {
    this.getFilterListForm('GetCityFilterList', `/bx_block_real_estate_companies/region_city_by_country?country=${countryName}`)
  }

  handleSubmit = (values:any) => {
    let error=false

    if (!this.props.companyId) {
      if(!this.state.logoImage) {
        this.setState({ logoError: true })
        error=true
      } else {
        this.setState({logoError: false})
      }

      if(!this.state.proofDoc) {
        this.setState({ proofError: true })
        error=true
      } else {
        this.setState({proofError: false})
      }
    }

    if (error) {
      console.log('error occured')
    } else {
      const formData = new FormData();
      formData.append('company_name', values.companyName)
      formData.append('managed_by', values.managedBy)
      if (this.state.logoImage) {
        formData.append('logo', this.state.logoImage)
      }
      formData.append('country', values.country)
      formData.append('region', values.region)
      formData.append('city', values.city)
      formData.append('zipcode', values.zipCode)
      formData.append('sales_manager_id', values.salesManager)
      formData.append('account_manager_id', values.accountManager)
      formData.append('google_map_location', values.googleMapLocation)
      formData.append('hq_location_address', values.hqLocationAddress)
      formData.append('commercial_registration_number', values.commercialRegistrationNumber)
      if (this.state.accountType==="coo") {
        formData.append('status', 'approved')
      } else {
        formData.append('status', 'pending_approval')
      }
      formData.append('contact_name', values.primaryContact.contactName)
      formData.append('email_address', values.primaryContact.emailAddress)
      formData.append('phone_number', values.primaryContact.phoneNumber)

      formData.append('[point_of_contacts][contact_name]', values.secondaryContact.contactName)
      formData.append('[point_of_contacts][email_address]', values.secondaryContact.emailAddress)
      formData.append('[point_of_contacts][phone_number]', values.secondaryContact.phoneNumber)
      formData.append('employee_account_id', JSON.parse(localStorage.getItem('user_id') as string))

      if (this.state.proofDoc) {
        formData.append('registration_certificate', this.state.proofDoc)
      }

      if (this.props.companyId) {
        this.setState({editCheck: true},() => {
          this.updateCompanyDetails(formData)
        })
      } else {
        this.submitCompanyDetails(formData)
      }
    }
  };

  handleCompanyData = async (responseJson:any) => {
    if (responseJson) {
      const data = responseJson.data.attributes

      await this.handleCountryChange(data.country)

      const initialValues = {
        companyName: data.company_name,
        primaryContact: {
          contactName: data.contact_name,
          emailAddress: data.email_address,
          phoneNumber: data.phone_number,
        },
        secondaryContact: {
          contactName: data.point_of_contacts_secondary.contact_name,
          emailAddress: data.point_of_contacts_secondary.email_address,
          phoneNumber: data.point_of_contacts_secondary.phone_number,
        },
        country: data.country,
        region: data.region,
        city: data.city,
        zipCode: data.zipcode,
        managedBy:data.managed_by,
        googleMapLocation: data.google_map_location,
        hqLocationAddress: data.hq_location_address,
        commercialRegistrationNumber: data.commercial_registration_number,
        salesManager: data.sales_manager_id,
        accountManager: data.account_manager_id
      }

      if (data.registration_certificate?.url) {
        const splitName = data.registration_certificate?.url.split("/")
        this.setState({ fileName: splitName[splitName.length - 1] })
      }

      this.setState({
        initialValues,
        previewLogoUrl: `${baseURL}${data.logo?.url}`
      })

    }
  }

  handleFileRemove = () => {
    this.setState({
      proofDoc: null,
      fileName:''
    })
  }

  handleProofDoc = (event:any) => {
    const file = event.target.files[0];
    this.setState({ proofDoc: file, proofError: false, fileName: file.name })
  }

  handleCreateSuccess = (responseJson: any) => {
    if (responseJson) {
      const initialValues = {
        companyName: '',
        primaryContact: {
          contactName: '',
          emailAddress: '',
          phoneNumber: '',
        },
        secondaryContact: {
          contactName: '',
          emailAddress: '',
          phoneNumber: '',
        },
        country: '',
        region: '',
        city: '',
        zipCode: '',
        managedBy:'',
        googleMapLocation: '',
        hqLocationAddress: '',
        commercialRegistrationNumber: '',
      }
      this.setState({createConfirmModal: true, 
        initialValues: initialValues,
        logoImage: null,
        previewLogoUrl: null,
        proofDoc: null,
        logoError: false,
        proofError: false
      })
    }
  };

  handleCancel = () => {
    this.props.navigation.history.push('/CompanyEmployee/realestate-companies')
  }

  handleUpdateCompany = (responseJson:any) => {
    if (responseJson && this.state.editCheck===true) {
      this.setState({editConfirmModal: true})
    }
  }

  handleCountriesFilterList = (responseJson: any) => {
    if (responseJson) {
      this.setState({ countriesList: responseJson.country_list })
    }
  };

  handleCitiesFilterList = (responseJson: any) => {
    if (responseJson.region_city_list) {
      this.setState({ cityList: responseJson.region_city_list })
    }
  };

  handleAccountFilterList = (responseJson:any) => {
    if (responseJson) {
      this.setState({accountManager: responseJson.account_managers})
    }
  }

  handleSalesFilterList = (responseJson: any) => {
    if (responseJson) {
      this.setState({salesManager: responseJson.sales_managers})
    }
  }

  // handleRegionsFilterList = (responseJson: any) => {
  //   if (responseJson) {
  //     this.setState({ regionList: responseJson.region_list })
  //   }
  // };

  submitCompanyDetails = async (data: any) => {
    this.setState({loading: true})
    this.CreateCompany = await apiCall({
      method: "POST",
      body:data,
      endPoint: `/bx_block_real_estate_companies/real_estate_companies`,
    });
  }

  updateCompanyDetails = async (data: any) => {
    this.setState({loading: true})
    this.UpdateCompany = await apiCall({
      method: "PUT",
      body:data,
      endPoint: `/bx_block_real_estate_companies/real_estate_companies/${this.props.companyId}`,
    });
  }

  getFilterListForm = async (method: string, endPoint: string) => {
    this.setState({loading: true})
    //@ts-ignore
    this[method] = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint,
    });
  }
}
// Customizable Area End

