import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import { Message } from "../../../../framework/src/Message";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { ApiErrorResponse, ApiCatchErrorResponse } from "../../../../components/src/APIErrorResponse";
import { runEngine } from "../../../../framework/src/RunEngine";
import { userProfile } from "../assets";
import {
  Box,
  Button,
  Grid,
} from "@material-ui/core";
import { blanckImg } from "../assets"
import * as Yup from 'yup'
export const configJSON = require("../config.js");


export interface Props {
  navigation: any;
  id: string;
  t?: any
  i18n?: any
  match?: any
  location?: any
  // Customizable Area Start
  history?: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  purchaseDate: string,
  loading: boolean;
  selectedImage: null,
  previewImage: string,
  images: any,
  selectedFile: any,
  selectedFilePdf: any,
  selectedFilePdfGuidelines: any,
  selectedFilePdfFloorPlan: any,
  imageURL: string,
  userValues:any,
  selectPdf: string,
  img: string,
  step: number,
  multiPleButton: any
  selectedFilePdfRoles: any
  selectedFilePdfResolutions: any
  selectedFilePdfBuildingPlans: any
  sampleData: any,
  savedFacility:any,
  page: number,
  rowsPerPage: number,
  createNewMember:any,
  isPopupOpen: any,
  findPopupOpen: any,
  facilityPopup: any,
  imagesSharedFacility: any,
  coreMembers: any,
  variants: any,
  selectedVariants: any
  coreMemberManager: any,
  unitRecords:any,
  open: any
  profilePhoto: boolean
  profilePhotoError: string
  buildingName: string
  totalArea: string
  uploadPhotos: boolean
  uploadPhotosError: string
  totalFloors: string
  totalUnits: string
  locationLink: string
  latitude: string
  longitude: string
  aboutUs: string
  unitNumber: string
  policyDocuments: boolean
  userList:any,
  guidelinesDocuments: boolean
  rolesDocuments: boolean
  resolutionsDocuments: boolean
  buildingPlansDocuments: boolean
  policyDocumentsError: string
  guidelinesDocumentsError: string
  rolesDocumentsError: string
  resolutionsDocumentsError: string,
  facilityValues:any,
  buildingPlansDocumentsError: string,
  buildingValues:any,
  unitValues:any,
  roleList:any,
    savedMembers:any,
    deleteMemberPopup:any,
    deleteMemberIndex:any,
    memberValues:any,
    buildingId:any;
    sid:any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CompEmpComplexBuildingBlockController extends CommonApiCallForBlockComponent<Props, S, SS> {
  exampleAPICallId: string = "";
  GetUserList:any="";
    GetRoleList:any = "";
    getUploadDocumentApiCall="";
    addBasicApiCall="";
    getUserCoreMember="";
    createCoreMemberApiId="";
    addUnitsApiCallId="";
    addSharedApiCallId="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      // Customizable Area Start
      purchaseDate: '',
      policyDocuments: true,
      guidelinesDocuments: true,
      rolesDocuments: true,
      resolutionsDocuments: true,
      buildingPlansDocuments: true,
      policyDocumentsError: "Policy Documents is required",
      guidelinesDocumentsError: "Guidelines Documents is required",
      rolesDocumentsError: "Roles Documents is required",
      resolutionsDocumentsError: "Resolutions Documents is required",
      buildingPlansDocumentsError: "Building Plans Documents is required",
      aboutUs: "",
      unitNumber: "",
      longitude: "",
      latitude: "",
      locationLink: "",
      profilePhoto: true,
      profilePhotoError: "Logo is required",
      totalUnits: "",
      totalFloors: "",
      uploadPhotos: true,
      uploadPhotosError: "Image is required",
      totalArea: "",
      buildingName: "",
      loading: false,
      selectedImage: null,
      previewImage: "",
      roleList:[],
      images: [],
      selectedFile: [],
      selectedFilePdfFloorPlan: [],
      selectedFilePdf: [],
      selectedFilePdfGuidelines: [],
      selectedFilePdfRoles: [],
      selectedFilePdfResolutions: [],
      selectedFilePdfBuildingPlans: [],
      imagesSharedFacility: [],
      savedFacility:[],
      userList:[],
      userValues:{
        userName:'',
        role:''
    },
      imageURL: '',
      selectPdf: '',
      img: userProfile,
      step: 0,
      createNewMember:false,
      deleteMemberIndex:null,
      deleteMemberPopup:false,
      buildingValues:{
        logo:'',
        images:[],
        buildingName: '',
        totalFloors: '',
        totalArea: '',
        totalUnits:'',
        locationLink:'',
        latitude:'',
        longitude:'',
        aboutUs:''
      },
      facilityValues:{
        Details: '',
        sharedFacility: '',
        totalArea: '',
        fees: '',
        images:[],
        floorDocument:null,
    },
      unitValues:{
        UnitNumber: '',
        FloorNumber: '',
        ResidentName: '',
        Configuration: '',
        Size: '',
        landmark: '',
        streetName: '',
    },
    unitRecords:[],
    memberValues:{
      email:'',
      phone:'',
      name:'',
      role:'',
      buildingName:'',
      unit:''
  },
  savedMembers:[],
      multiPleButton: ['Basic Details', 'Documents', 'Units', 'Shared Facilities', 'Core Members'],
      page: 0,
      rowsPerPage: 10,
      isPopupOpen: false,
      findPopupOpen: false,
      facilityPopup: false,
      coreMembers: false,
      open: false,
      coreMemberManager: [
        { id: 0, label: "Vice Chairman", name: "Marleah Eagleston", position: "Vice Chairman" },
        { id: 1, label: "Manager", name: "Marleah Eagleston", position: "Vice " },
        { id: 2, label: "Auditor", name: "Marleah Eagleston", position: "Vice Chairman" },
        { id: 3, label: "Real Estate Developer", name: "Marleah Eagleston", position: "Vice " },
        { id: 4, label: "Vice Chairman", name: "Marleah Eagleston", position: "Vice Chairman" },
        { id: 5, label: "Vice Chairman", name: "Marleah Eagleston", position: "Vice Chairman" },
        { id: 6, label: "Vice Chairman", name: "Marleah Eagleston", position: "Vice Chairman" }
      ],
      variants: [
        { id: 0, name: 'User Name' },
        {
          id: 3,
          name: 'Voucher',
        },
        {
          id: 1,
          name: 'Top Up',
        },
        {
          id: 2,
          name: 'Game Key',
        },
        {
          id: 12,
          name: 'Other',
        },
        {
          id: 11,
          name: 'Nintendo',
        },
        {
          id: 10,
          name: 'Xbox',
        },
      ],
      sampleData: [
        {
          id: 1,
          UnitNumber: "A202",
          FloorNumber: 25,
          Configuration: "2 BHK",
          Owner: "Andries Grootoonk",
          ResidentName: "Anaru Hakopa"
        },
        {
          id: 2,
          UnitNumber: "A203",
          FloorNumber: 15,
          Configuration: "2 BHK",
          Owner: "Florieke Krebber",
          ResidentName: "Beatriz Brito"
        },
        {
          id: 3,
          UnitNumber: "A204",
          FloorNumber: 8,
          Configuration: "2 BHK",
          Owner: "Gabriel Soares",
          ResidentName: "Sakane Gabriel"
        },
        {
          id: 4,
          UnitNumber: "A205",
          FloorNumber: 7,
          Configuration: "2 BHK",
          Owner: "Miriam de Jes�s",
          ResidentName: "Sakane Miikoes"
        },
        {
          id: 5,
          UnitNumber: "A206",
          FloorNumber: 5,
          Configuration: "2 BHK",
          Owner: "Slavcho Karbashewski",
          ResidentName: "Miiko"
        },
        {
          id: 6,
          UnitNumber: "A207",
          FloorNumber: 1,
          Configuration: "2 BHK",
          Owner: "Somun Ae-Ri",
          ResidentName: "Sakane"
        },
        {
          id: 7,
          UnitNumber: "A208",
          FloorNumber: 10,
          Configuration: "2 BHK",
          Owner: "Andries",
          ResidentName: "-"
        },
        {
          id: 8,
          UnitNumber: "A209",
          FloorNumber: 12,
          Configuration: "2 BHK",
          Owner: "Andries Grootoonk",
          ResidentName: "Sakane Miiko"
        },
        {
          id: 9,
          UnitNumber: "A209",
          FloorNumber: 12,
          Configuration: "2 BHK",
          Owner: "Andries Grootoonk",
          ResidentName: "Sakane Miiko"
        },
        {
          id: 10,
          UnitNumber: "A209",
          FloorNumber: 12,
          Configuration: "2 BHK",
          Owner: "Andries Grootoonk",
          ResidentName: "Sakane Miiko"
        },
        {
          id: 11,
          UnitNumber: "A209",
          FloorNumber: 12,
          Configuration: "2 BHK",
          Owner: "Andries Grootoonk",
          ResidentName: "Sakane Miiko"
        },
        {
          id: 12,
          UnitNumber: "A209",
          FloorNumber: 12,
          Configuration: "2 BHK",
          Owner: "Andries Grootoonk",
          ResidentName: "Sakane Miiko"
        },
      ],
      selectedVariants: [],
      buildingId:null,
      sid:null,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    // Customizable Area Start
    let id= location.pathname.split('/').pop();
    this.setState({sid:id})
    this.exampleAPICall()
  
    // Customizable Area End
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (this.state.step !== prevState.step) {
        if (this.state.step === 4) {
            await this.getRoleList()
            await this.getUserList();
        }
    }
}

async receive(from: string, message: Message) {
  runEngine.debugLog("Message Recived", message);

  // Customizable Area Start
  if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    let responseJsonBlock = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    let errorResponseBlock = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    switch (apiRequestCallId) {
      case this.GetUserList:
          this.handleUserList(responseJsonBlock);
          break;
      case this.GetRoleList:
          this.handleRoleList(responseJsonBlock);
          break;
      case this.getUploadDocumentApiCall:
      return this.handleDocumentSucc(responseJsonBlock);
      case this.addBasicApiCall:
        return this.basicApiCallSucc(responseJsonBlock);
        case this.getUserCoreMember:
          return this.handleUserCoreSucc(responseJsonBlock)
        case this.createCoreMemberApiId:
          return this.handleCoreMemberSucc(responseJsonBlock)
        case this.addUnitsApiCallId:
          return this.handleUnitSucc(responseJsonBlock);
        case this.addSharedApiCallId:
          return this.handleSharedFaclitySucc(responseJsonBlock)
      default:
        break;
    }
  
    this.setState({loading:false})

    if (responseJsonBlock && responseJsonBlock.meta && responseJsonBlock.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
    } else {
      ApiErrorResponse(responseJsonBlock);
    }
    ApiCatchErrorResponse(errorResponseBlock);
  }
  // Customizable Area End
}

handleUserList = (responseJson:any) => {
  if(responseJson?.status.length) {
      this.setState({userList: responseJson?.status})
  }
}

handleRoleList = (responseJson:any) => {
  if(responseJson?.data.length) {
    let status= responseJson?.data.map((data:any)=>({
      "label" : data.attributes.name,
      "value" : data.id
     }
      
      ))
     
      this.setState({roleList: status})
  }
}
handleBackDoc=()=>{}
basicApiCallSucc=(responseJson:any)=>{
  if(responseJson.data){
      this.setState({buildingId:responseJson.data.id,step:1})
  }
}
handleUserCoreSucc=(response:any)=>{
  this.setState({savedMembers:[...this.state.savedMembers,response.data]})
        
}
handleCoreMemberSucc=(response:any)=>{


}
handleUnitSucc=(responseJson:any)=>{
  this.setState({})
  }
  handleSharedFaclitySucc=(response:any)=>{}
handleNextCore=()=>{
  this.handleClickOpen()
}

handleMemberDetails = (values:any) => {
        if (values) {
            const savedMember = {...values}
            this.setState({savedMembers: [...this.state.savedMembers,     savedMember]}, () => {
                this.sharedCoreMembers()
                this.sharedCreateNewMember()
                this.createCoreMember(values)
            })
        }
    }

getUserList = async () => {
  this.GetUserList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_my_team/team_members/user_name_list`,
    });
}
createCoreMember=async(values:any)=>{
        
  const data = new FormData();
  data.append(`team_member[society_management_id]`, "43")
      data.append(`team_member[role_id]`, values.role.value)
      data.append(`team_member[name]`,values.name)
      data.append(`team_member[email]`, values.email)
     
      data.append(`team_member[phone_number]`, values.phone)
      data.append(`team_member[unit]`, values.unit)
      data.append(`team_member[account_type]`, "true")
      data.append('team_member[building_management_id]', values.buildingName.id)
      this.createCoreMemberApiId = await apiCall({
          body:data,
          method: "POST",
          endPoint: `/bx_block_my_team/team_members/create_team_member`,
        });
  
}
handleDocumentSucc=(responseJson:any)=>{
  this.setState({step:2})
}
handleNextDoc=async()=>{
  // this.setState({step:2})
  const formData = new FormData();
  formData.append("policy",this.state.selectedFilePdf[0] );
  formData.append("roles",this.state.selectedFilePdfRoles[0]);
  formData.append("building_plan", this.state.selectedFilePdfBuildingPlans[0]);
  formData.append("guideline", this.state.selectedFilePdfGuidelines[0]);
  formData.append("resolution", this.state.selectedFilePdfResolutions[0]);
  this.getUploadDocumentApiCall= await apiCall({
          
          method: "POST",
          endPoint: `/society_managements/23/bx_block_my_document/upload_document`,
          body:formData
        });
}
getRoleList = async () => {
  this.GetRoleList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_roles_permissions/roles`,
    });
}

  // Customizable Area Start
  exampleAPICall = async () => {
    this.exampleAPICallId = await this.apiCallForFileUpload({
      method: "POST",
      endPoint: `endPoint`,
      body: { data: {} }
    });
  }
  addSharedFacility=async()=>{
    const formData = new FormData();
   
    this.state.savedFacility.length && this.state.savedFacility.forEach((facility:any, i:number) => {
      formData.append(`common_area[name]`, "test")
      formData.append('common_area[society_management_id]',"64")
      formData.append(`common_area[details]`, facility.Details)
      formData.append(`common_area[total_area]`, facility.totalArea)
      formData.append(`common_area[reservation_fee]`, facility.fees)
      formData.append(`common_area[floor_plan]`, facility.floorDocument)
    
      facility.images.length && facility.images.forEach((photo:any) => {
            formData.append(`common_area[photos][]`, photo)
        
    })
    })
        this.addSharedApiCallId = await apiCall({
        body:formData,
        method: "POST",
        endPoint: `/bx_block_society_management/common_areas`,
        });
  }
  addUnits=async()=>{
         
    const formData = new FormData();
    this.state.unitRecords.length && this.state.unitRecords.forEach((unit:any, i: number) =>{   
      formData.append(`apartment_managements[floor_number][]`, unit.FloorNumber)
      formData.append(`apartment_managements[configuration][]`, unit.Configuration)
      formData.append(`apartment_managements[size][]`, unit.Size)
      formData.append(`apartment_managements[purchase_price][]`, unit.PurchasePrice)
      formData.append(`apartment_managements[purchase_date][]`, unit.PurchaseDate)
      formData.append(`apartment_managements[current_valuation][]`, unit.CurrentValuation)
      formData.append('apartment_management[resident_name]',unit.ResidentName)
      formData.append('apartment_management[owner_name]',unit.OwnerName)
      formData.append('apartment_management[apartment_name]',unit.UnitNumber)
      formData.append(`apartment_management[current_valuation][]`, unit.CurrentValuation)
      formData.append('apartment_management[society_management_id]',"43")
  }) 
    this.addUnitsApiCallId = await apiCall({
  
        method: "POST",
        endPoint: `/bx_block_settings/apartment_managements`,
        body:formData
      });
  }
  handleDateChange = (e: any) => {
    this.setState({
      purchaseDate: e.target.value,
    });
  }

  BuildingName = ({e,data,handleChange,type}:any) => {
    const inputValue = (e && e.target && e.target.value) || ''; // Ensure e.target.value is defined
    const sanitizedValue = inputValue.replace(type, '').slice(0, 50);
    handleChange(data)(sanitizedValue);  
  }

  convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleImageChange = (event: any) => {
    const inputPicherPortable = document.createElement('input');
    inputPicherPortable.type = 'file';
    inputPicherPortable.accept = "image/png, image/jpeg, iamge/jpg"
    inputPicherPortable.multiple = true
    inputPicherPortable.click();
    inputPicherPortable.onchange = (event: any) => {
      this.PicherPortable(event)
    }
  };

  handleCloseDeleteMemberPopup = () => {
    this.setState({
        deleteMemberPopup: false
    })
}

handleRemoveMember = (index:number) => {
  const memberArr = [...this.state.savedMembers]
  memberArr.splice(index, 1)
  this.setState({savedMembers: memberArr, deleteMemberPopup: false})
}

  PicherPortable = (event: any) => {
    const uplodeFilesArray = Array.from(event.target.files);
    uplodeFilesArray.forEach(async (file: any) => {
      if (file.size < 2000000) {
        const base64 = await this.convertBase64(file)
        this.setState({
          images: [...this.state.images, base64],
          uploadPhotos: true
        })
      }
    })
  }

  handleFileChange = (event: any) => {
    const inputPicherPortable = document.createElement('input');
    inputPicherPortable.type = 'file';
    inputPicherPortable.accept = "image/png, image/jpeg, image/jpg"
    inputPicherPortable.multiple = true
    inputPicherPortable.click();
    inputPicherPortable.onchange = (event: any) => {
      this.picherPortables(event)
    }
  };

  picherPortables = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.setState({
          selectedFile: file,
          imageURL: event.target.result,
          profilePhoto: true
        });
      };
      reader.readAsDataURL(file);
    }
  }

  handleFileChanges = (event: any) => {
    const inputPicherPortable = document.createElement('input');
    inputPicherPortable.type = 'file';
    inputPicherPortable.accept = ".pdf, .doc, .docx"
    inputPicherPortable.multiple = true
    inputPicherPortable.click();
    inputPicherPortable.onchange = (event: any) => {
      this.handleFilePortable(event)
    }
  };

  handleRemoveFacilityDetails = (index:number) => {
    const facilityArr = [...this.state.savedFacility]
    facilityArr.splice(index, 1)
    this.setState({savedFacility: facilityArr})
}

handleOpenDeleteMemberPopup = (i:any) => {
  this.setState({
      deleteMemberPopup: true,
      deleteMemberIndex: i
  })
}

handleSharedDetails = (values: any) => {
  let error=false

  if(!error) {
      const savedFacilityObj = {
          ...values
      }

      this.setState({facilityValues: values, savedFacility: [...this.state.savedFacility, savedFacilityObj]}, () => {
          this.handleSaveSharedFacility()
          this.addSharedFacility()
      })
  }
}

handleSaveSharedFacility = () => {
  this.setState({facilityPopup: false})
}

  handleFilePortable = (event: any) => {
    const file = event.target.files[0];
    const imagesArrays = [...this.state.selectedFilePdf];
    imagesArrays.push(file);
    this.setState({
      selectedFilePdf: imagesArrays,
      policyDocuments: true
    });
  }

  handleRemoveFile = (fileIndex: any) => {
    const updatedFiles = this.state.selectedFilePdf.filter((value: any, index: any) => index !== fileIndex);
    this.setState({ selectedFilePdf: updatedFiles });
  };

  handleFileChangesGuidelines = (event: any) => {
    const inputPicherGuidelines = document.createElement('input');
    inputPicherGuidelines.type = 'file';
    inputPicherGuidelines.accept = ".pdf, .doc, .docx"
    inputPicherGuidelines.multiple = true
    inputPicherGuidelines.click();
    inputPicherGuidelines.onchange = (event: any) => {
      this.handleFileGuidelines(event)
    }
  };

  sharedCreateNewMember = () => {
    this.setState(prevState => ({
        createNewMember: !prevState.createNewMember,
    }));
};

  handleFileGuidelines = (event: any) => {
    const GuidelinesFile = event.target.files[0];
    const imagesArraysGuidelines = [...this.state.selectedFilePdfGuidelines];
    imagesArraysGuidelines.push(GuidelinesFile);
    this.setState({
      selectedFilePdfGuidelines: imagesArraysGuidelines,
      guidelinesDocuments: true
    });
  }

  handleRemoveFileGuidelines = (fileIndex: any) => {
    const updatedFilesGuidelines = this.state.selectedFilePdfGuidelines.filter((value: any, index: any) => index !== fileIndex);
    this.setState({ selectedFilePdfGuidelines: updatedFilesGuidelines });
  };

  handleFileChangesRoles = (event: any) => {
    const inputPicherPortableRoles = document.createElement('input');
    inputPicherPortableRoles.type = 'file';
    inputPicherPortableRoles.accept = ".pdf, .doc, .docx"
    inputPicherPortableRoles.multiple = true
    inputPicherPortableRoles.click();
    inputPicherPortableRoles.onchange = (event: any) => {
      this.handleFileRoles(event)
    }
  };

  handleFileRoles = (event: any) => {
    const RolesFile = event.target.files[0];
    const imagesArraysRoles = [...this.state.selectedFilePdfRoles];
    imagesArraysRoles.push(RolesFile);
    this.setState({
      selectedFilePdfRoles: imagesArraysRoles,
      rolesDocuments: true
    });
  }

  handleUnitDetails = (values: any) => {
    this.setState({ unitRecords: [...this.state.unitRecords,  values] }, () => {
        this.togglePopup()
        this.addUnits()
    })
}

  handleRemoveFileRoles = (fileIndex: any) => {
    const updatedFilesRoles = this.state.selectedFilePdfRoles.filter((value: any, index: any) => index !== fileIndex);
    this.setState({ selectedFilePdfRoles: updatedFilesRoles });
  };
  handleFileChangesResolutions = (event: any) => {
    const inputPicherPortableResolutions = document.createElement('input');
    inputPicherPortableResolutions.type = 'file';
    inputPicherPortableResolutions.accept = ".pdf, .doc, .docx"
    inputPicherPortableResolutions.multiple = true
    inputPicherPortableResolutions.click();
    inputPicherPortableResolutions.onchange = (event: any) => {
      this.handleFileResolutions(event)
    }
  };

  handleFileResolutions = (event: any) => {
    const ResolutionsFile = event.target.files[0];
    const imagesArraysResolutions = [...this.state.selectedFilePdfResolutions];
    imagesArraysResolutions.push(ResolutionsFile);
    this.setState({
      selectedFilePdfResolutions: imagesArraysResolutions,
      resolutionsDocuments: true
    });
  }

  handleRemoveFileResolutions = (fileIndex: any) => {
    const updatedFilesResolutions = this.state.selectedFilePdfResolutions.filter((value: any, index: any) => index !== fileIndex);
    this.setState({ selectedFilePdfResolutions: updatedFilesResolutions });
  };

  handleFileChangesBuildingPlans = (event: any) => {
    const inputPicherPortableBuildingPlans = document.createElement('input');
    inputPicherPortableBuildingPlans.type = 'file';
    inputPicherPortableBuildingPlans.accept = ".pdf, .doc, .docx"
    inputPicherPortableBuildingPlans.multiple = true
    inputPicherPortableBuildingPlans.click();
    inputPicherPortableBuildingPlans.onchange = (event: any) => {
      this.handleFileBuildingPlans(event)
    }
  };

  handleFileBuildingPlans = (event: any) => {
    const BuildingPlansFile = event.target.files[0];
    const imagesArraysBuildingPlans = [...this.state.selectedFilePdfBuildingPlans];
    imagesArraysBuildingPlans.push(BuildingPlansFile);
    this.setState({
      selectedFilePdfBuildingPlans: imagesArraysBuildingPlans,
      buildingPlansDocuments: true
    });
  }

  handleRemoveFileBuildingPlans = (fileIndex: any) => {
    const updatedFilesBuildingPlans = this.state.selectedFilePdfBuildingPlans.filter((value: any, index: any) => index !== fileIndex);
    this.setState({ selectedFilePdfBuildingPlans: updatedFilesBuildingPlans });
  };


  handleChangePage = (event: any, newPage: any) => {
    this.setState({ page: newPage - 1 });
  };

  handleChangeRowsPerPage = (event: any) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  togglePopup = () => {
    this.setState(prevState => ({
      isPopupOpen: !prevState.isPopupOpen,
    }));
  };

  findPersonPopup = () => {
    this.setState(prevState => ({
      findPopupOpen: !prevState.findPopupOpen,
    }));
  };

  sharedFacilityPopup = () => {
    this.setState(prevState => ({
      facilityPopup: !prevState.facilityPopup,
    }));
  };

  sharedCoreMembers = () => {
    this.setState(prevState => ({
      coreMembers: !prevState.coreMembers,
    }));
  };

  handleImageSharedChange = (event: any) => {
    const inputPicherPortable = document.createElement('input');
    inputPicherPortable.type = 'file';
    inputPicherPortable.accept = "image/png, image/jpeg, iamge/jpg"
    inputPicherPortable.multiple = true
    inputPicherPortable.click();
    inputPicherPortable.onchange = (event: any) => {
      this.picherPortableShared(event)
    }
  };

  picherPortableShared = (event: any) => {
    const uplodeFilesArray = Array.from(event.target.files);
    uplodeFilesArray.forEach(async (file: any) => {
      if (file.size < 2000000) {
        const base64 = await this.convertBase64(file)
        this.setState({
          imagesSharedFacility: [...this.state.imagesSharedFacility, base64],
        })
      }
    })
  }

  handleFileChangesFloorPlan = (event: any) => {
    const inputPicherPortable = document.createElement('input');
    inputPicherPortable.type = 'file';
    inputPicherPortable.accept = ".pdf, .doc, .docx"
    inputPicherPortable.multiple = true
    inputPicherPortable.click();
    inputPicherPortable.onchange = (event: any) => {
      this.handleFileFloorPlan(event)
    }
  };

  handleFileFloorPlan = (event: any) => {
    const file = event.target.files[0];
    const imagesArrays = [...this.state.selectedFilePdfFloorPlan];
    imagesArrays.push(file);
    this.setState({ selectedFilePdfFloorPlan: imagesArrays });
  }

  handleRemoveFileFloorPlan = (fileIndex: any) => {
    const updatedFiles = this.state.selectedFilePdfFloorPlan.filter((value: any, index: any) => index !== fileIndex);
    this.setState({ selectedFilePdfFloorPlan: updatedFiles });
  };

  handleChangeSelect = (event: any) => {
    const selectedVariants = event?.target.value;
    this.setState({ selectedVariants });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  getColor = (index: number, step: number)=>  {
    if (step === index) {
      return "#FFFFFF";
    } else if (step < index) {
      return '#83878e';
    } else {
      return "#2B6FED";
    }
  }


  multiPleButtonHader = () => {
    const { t } = this.props;
    return (
      <>
        {this.state.multiPleButton.map((val: any, index: number) => {
          return (<Box key={index} style={{ width: 'max-content', height: '43px', marginRight: '15px', backgroundColor: this.state.step == index ? "#2B6FED" : "#C8C8C81F", borderRadius: "21.5px", display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            <Button data-test-id="multiPleButtonHader" onClick={() => this.setState({ step: index })} style={{ color: this.getColor(index,this.state.step) , fontWeight: 'bold', padding: '0px 20.5px', textTransform: 'capitalize' }}>{t(val)}</Button>
          </Box>)
        })
        }
      </>
    )
  }

  imgUplodeAvtar = () => {
    return (
      <Box>
        <img style={{ border: '2px solid black', borderRadius: '100%', width: '100px', height: '100px' }} src={!this.state.imageURL ? blanckImg : this.state.imageURL} />
      </Box>
    )
  }

  uploadGuidelines = () => {
    return (
      <Box style={{ paddingLeft: "16px", color: '#8d8d8d', fontSize: '16px', fontWeight: 400 }}>{this.state.selectedFilePdfGuidelines == 0 ? "Upload Guidelines Documents" : ""}</Box>
    )
  }

  handleBasicDetails = (values:any) => {
    this.setState({buildingValues: values}, () => {
      // this.handleNext()
      this.handleAddBasic(values)
    })
  }
  handleBasicDetailsCore=async(values:any)=>{
    this.sharedCoreMembers()
    this.getUserCoreMember = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_my_team/company_employee/employee_team_members/employee_details?employee_id=${values.userName.id}`,
    });
  }
  handleAddBasic=async(values:any)=>{
    const data = new FormData();
    data.append('society_management_id',this.state.sid)
    data.append('building_management[name]', values.buildingName)
    data.append('building_management[description]', values.aboutUs)
    data.append('building_management[total_area]', values.totalArea)
    data.append('building_management[total_floor]', values.totalFloors)
    data.append('building_management[total_units]', values.totalUnits)
    data.append('building_management[googlemaplocation]', values.locationLink)
    data.append('building_management[logo]', values.logo)
  
    values.images.length && values.images.forEach((image:any) => {
        data.append('building_management[photos][]', image)
    })
   
    this.addBasicApiCall = await apiCall({
 
        method: "POST",
        endPoint: `/bx_block_society_management/company_employee/complexes/create_complex_building`,
        body:data
      });
}
  handleBack = () => {
    this.setState({ step: this.state.step - 1 });
  };

  handleSave = () => {
    console.log('Data saved');
  };

  firstStep = (values: any) => {
    if (values.BuildingName !== '' && values.TotalArea !== '' && values.TotalFloors !== '' && values.TotalUnits !== ''
      && values.Latitude !== '' && values.Longitude !== '' && values.Abo3utUs !== '' && this.state.images.length > 0 && this.state.imageURL.length > 0) {
      this.setState({ step: this.state.step + 1, uploadPhotos: true, profilePhoto: true });
    } else {
      this.setState({ uploadPhotos: this.state.images.length > 0, profilePhoto: this.state.imageURL.length > 0 });
    }
  }

  secondStep = (values: any) => {
    if (this.state.selectedFilePdf.length > 0
      && this.state.selectedFilePdfGuidelines.length > 0
      && this.state.selectedFilePdfRoles.length > 0
      && this.state.selectedFilePdfResolutions.length > 0
      && this.state.selectedFilePdfBuildingPlans.length > 0) {
      this.setState({
        step: this.state.step + 1
      });
    }
    this.setState({
      policyDocuments: this.state.selectedFilePdf.length > 0,
      guidelinesDocuments: this.state.selectedFilePdfGuidelines.length > 0,
      rolesDocuments: this.state.selectedFilePdfRoles.length > 0,
      resolutionsDocuments: this.state.selectedFilePdfResolutions.length > 0,
      buildingPlansDocuments: this.state.selectedFilePdfBuildingPlans.length > 0
    });
  }

  thirdStep = (values: any) => {
    this.setState({ step: this.state.step + 1 });
  }

  fourstep = (values: any) => {
    this.setState({ step: this.state.step + 1 });
  }

  handleNext = () => {
    this.setState({step: this.state.step + 1})
  };

  multipleButton = () => {

    return (
      <Box style={{ display: 'flex', justifyContent: "end", flexWrap: 'wrap' }}>
        {this.state.step > 0 && <Grid style={{ marginTop: "30px", marginRight: '20px' }}>
          <Box style={{}}>
            <Button style={{ color: "#2B6FED", fontWeight: 'bold', padding: '0px 20.5px', textTransform: 'capitalize', width: '177px', height: '56px', border: '1px solid #2B6FED', borderRadius: "8px", display: 'flex', justifyContent: 'center', textAlign: 'center' }} onClick={this.handleBack}><span className="bold-text">PREV</span></Button>
          </Box>
        </Grid>}
        <Box >
          {this.state.step < 4 ?
            <Grid style={{ marginTop: "30px", }}>
              <Button type="submit" style={{ width: '177px', height: '56px', backgroundColor: '#2B6FED', borderRadius: "8px", display: 'flex', justifyContent: 'center', textAlign: 'center', color: "#FFFFFF", fontWeight: 'bold', padding: '0px 20.5px', textTransform: 'capitalize' }} data-test-id="handleNext" onClick={() => this.handleNext()}><span className="bold-text">NEXT</span></Button>
            </Grid> : <Grid style={{ marginTop: "30px", }}>
              <Button onClick={this.handleClickOpen} type="submit" style={{ width: '177px', height: '56px', backgroundColor: '#2B6FED', borderRadius: "8px", display: 'flex', justifyContent: 'center', textAlign: 'center', color: "#FFFFFF", fontWeight: 'bold', padding: '0px 20.5px', textTransform: 'capitalize' }} ><span className="bold-text">Save</span></Button>
            </Grid>}
        </Box>
      </Box>
    )
  }

  finalErrorMessage(error: any) {
    if (error) {
      return error
    } else {
      return;
    }
  }

  codeSchema = Yup.object().shape({
    BuildingName: Yup.string().required(configJSON.buildingName),
    TotalArea: Yup.string().required(configJSON.totalArea),
    TotalFloors: Yup.string().required(configJSON.totalFloors),
    TotalUnits: Yup.string().required(configJSON.totalUnits),
    Latitude: Yup.string().required(configJSON.latitude),
    Longitude: Yup.string().required(configJSON.longitude),
    AboutUs: Yup.string().required(configJSON.aboutUs),
    UnitNumber: Yup.string().required(configJSON.unitNumber),
  });

  HandleSubmit = (values: any) => {
    console.log("values", values);

  }
  // Customizable Area End
}
