import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
} from "@material-ui/core";
import CompEmpComplexBuildingBlockController from "./CompEmpComplexBuildingBlockController.web";
import CompEmpComplexBuildingBlockStep1 from './CompEmpComplexBuildingBlockStep1.web'
import CompEmpComplexBuildingBlockStep2 from './CompEmpComplexBuildingBlockStep2.web'
import CompEmpComplexBuildingBlockStep3 from './CompEmpComplexBuildingBlockStep3.web'
import CompEmpComplexBuildingBlockStep4 from './CompEmpComplexBuildingBlockStep4.web'
import CompEmpComplexBuildingBlockStep5 from './CompEmpComplexBuildingBlockStep5.web'
import {Formik} from "formik";
import "../../assets/commonForm.css";
import {withTranslation} from "react-i18next";

// Customizable Area End

class CompEmpComplexBuildingBlock extends CompEmpComplexBuildingBlockController {
    render() {
        // Customizable Area Start
        const {page, rowsPerPage, sampleData} = this.state;
        const startIndex = page * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        const displayedData = sampleData.slice(startIndex, endIndex);
        const {t,i18n} = this.props;
        const {language} = i18n;
        // Customizable Area End

        return (
            // Customizable Area Start
            <Box style={{padding: '40px', paddingRight: '40px', backgroundColor: 'rgb(244, 247, 255)'}}>
                <Typography className="complex"
                            style={{fontSize: '16px', display: 'flex'}}> {t("Complex / Buildings /")} <p
                    style={{color: '#2B6FED'}}>  {t("Create New Building")} </p></Typography>
                <br/>
                <Typography className="bold-text" style={{fontSize: '32px', fontWeight: 'bold'}}> {t("Create New Complex Building")}</Typography>
                <Formik
                    initialValues={{
                        BuildingName: "",
                        TotalArea: "",
                        TotalFloors: "",
                        TotalUnits: "",
                        LocationLink: "",
                        Latitude: "",
                        Longitude: "",
                        AboutUs: "",
                        UnitNumber: "",
                    }}
                    validationSchema={this.codeSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={(values: any) => this.HandleSubmit(values)}

                >
                    {({values, touched, errors, isValid, setFieldValue, handleSubmit, handleChange}: any) => (
                        <form className="commonForms" onSubmit={handleSubmit}>
                            <Box style={{marginTop: "25px", display: 'flex', flexWrap: 'wrap'}}>
                                {(this.multiPleButtonHader())}
                            </Box>
                            <Grid className="topBarFormik">
                                {this.state.step === 0 && <CompEmpComplexBuildingBlockStep1
                                    step={this.state.step}
                                    imgUplodeAvtar={this.imgUplodeAvtar}
                                    handleFileChange={this.handleFileChange}
                                    buildingValues={this.state.buildingValues}
                                    handleNext={this.handleNext}
                                    handleBasicDetails={this.handleBasicDetails}
                                    t={t}
                                    language={language}
                                />}
                                {this.state.step === 1 && <CompEmpComplexBuildingBlockStep2
                                    step={this.state.step}
                                    selectedFilePdf={this.state.selectedFilePdf}
                                    handleRemoveFile={this.handleRemoveFile}
                                    handleFileChanges={this.handleFileChanges}
                                    selectedFilePdfGuidelines={this.state.selectedFilePdfGuidelines}
                                    handleRemoveFileGuidelines={this.handleRemoveFileGuidelines}
                                    uploadGuidelines={this.uploadGuidelines}
                                    handleFileChangesGuidelines={this.handleFileChangesGuidelines}
                                    selectedFilePdfRoles={this.state.selectedFilePdfRoles}
                                    handleRemoveFileRoles={this.handleRemoveFileRoles}
                                    handleFileChangesRoles={this.handleFileChangesRoles}
                                    selectedFilePdfResolutions={this.state.selectedFilePdfResolutions}
                                    handleRemoveFileResolutions={this.handleRemoveFileResolutions}
                                    handleFileChangesResolutions={this.handleFileChangesResolutions}
                                    selectedFilePdfBuildingPlans={this.state.selectedFilePdfBuildingPlans}
                                    handleRemoveFileBuildingPlans={this.handleRemoveFileBuildingPlans}
                                    handleFileChangesBuildingPlans={this.handleFileChangesBuildingPlans}
                                    multipleButton={this.multipleButton}
                                    handleBackDoc={this.handleBackDoc}
                                    handleNextDoc={this.handleNextDoc}
                                />
                                }
                                {this.state.step === 2 && <CompEmpComplexBuildingBlockStep3
                                    stepBuilding={this.state.step}
                                    togglePopupBuilding={this.togglePopup}
                                    isPopupOpen={this.state.isPopupOpen}
                                    unitValues={this.state.unitValues}
                                    unitRecords={this.state.unitRecords}
                                    sampleData={this.state.sampleData}
                                    multipleButton={this.multipleButton}
                                    handleUnitDetails={this.handleUnitDetails}
                                />}
                                {this.state.step === 3 && <CompEmpComplexBuildingBlockStep4
                                    facilityValues={this.state.facilityValues}
                                    stepBuilding={this.state.step}
                                    multipleButton={this.multipleButton}
                                    facilityPopupBuilding={this.state.facilityPopup}
                                    savedFacility={this.state.savedFacility}
                                    handleRemoveFacilityDetails={this.handleRemoveFacilityDetails}
                                    handleSharedDetails={this.handleSharedDetails}
                                    sharedFacilityPopupBuilding={this.sharedFacilityPopup}
                                />}
                                {this.state.step === 4 && <CompEmpComplexBuildingBlockStep5
                                    stepBuilding={this.state.step}
                                    savedMembers={this.state.savedMembers}
                                    handleOpenDeleteMemberPopup={this.handleOpenDeleteMemberPopup}
                                    sharedCoreMembers={this.sharedCoreMembers}
                                    coreMembers={this.state.coreMembers}
                                    userValues={this.state.userValues}
                                    userList={this.state.userList}
                                    roleList={this.state.roleList}
                                    memberValues={this.state.memberValues}
                                    createNewMember={this.state.createNewMember}
                                    sharedCreateNewMember={this.sharedCreateNewMember}
                                    handleMemberDetails={this.handleMemberDetails}
                                    deleteMemberPopup={this.state.deleteMemberPopup}
                                    deleteMemberIndex={this.state.deleteMemberIndex}
                                    handleRemoveMember={this.handleRemoveMember}
                                    handleCloseDeleteMemberPopup={this.handleCloseDeleteMemberPopup}
                                    handleBasicDetailsCore={this.handleBasicDetailsCore}
                                    handleBack={this.handleBack}
                                    handleNextCore={this.handleNextCore}
                                />}
                            </Grid>
                        </form>
                    )}
                </Formik>


                <Dialog
                    open={this.state.open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <Box style={{padding: '50px 0px'}}>
                        <DialogTitle id="alert-dialog-title" style={{textAlign: 'center'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61"
                                 fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M6.03853 30.0835C6.03853 16.5273 17.0278 5.53804 30.584 5.53804C44.1402 5.53804 55.1294 16.5273 55.1294 30.0835C55.1294 43.6397 44.1402 54.6289 30.584 54.6289C17.0278 54.6289 6.03853 43.6397 6.03853 30.0835ZM30.584 0.0834961C14.0153 0.0834961 0.583984 13.5148 0.583984 30.0835C0.583984 46.6522 14.0153 60.0835 30.584 60.0835C47.1527 60.0835 60.584 46.6522 60.584 30.0835C60.584 13.5148 47.1527 0.0834961 30.584 0.0834961ZM42.7706 25.8643C41.6857 24.8232 39.9268 24.8232 38.8418 25.8643L29.6951 34.6456L23.3262 28.531C22.2412 27.4899 20.4823 27.4899 19.3973 28.531C18.3129 29.5726 18.3129 31.2611 19.3973 32.3027L27.7307 40.3027C28.8156 41.3438 30.5745 41.3438 31.6595 40.3027L42.7706 29.636C43.8551 28.5944 43.8551 26.9059 42.7706 25.8643Z"
                                      fill="#FC8434"/>
                            </svg>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText
                                style={{textAlign: 'center', color: 'black', fontSize: '22px', fontWeight: 700}}
                                id="alert-dialog-description">
                                New Building Created
                            </DialogContentText>
                            <DialogContentText
                                style={{textAlign: 'center', color: 'black', fontSize: '16px', fontWeight: 400}}
                                id="alert-dialog-description">
                                Approval Request has been sent to the COO. Once the request is approved the building
                                will be added to the platform
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="buttonCancel" onClick={this.handleClose}>Okay</Button>
                        </DialogActions>
                    </Box>
                </Dialog>
            </Box>

            // Customizable Area End
        );
    }
}

// export default withTranslation()(withRouter(CompEmpComplexBuildingBlock));
// Customizable Area Start

export default withTranslation()(CompEmpComplexBuildingBlock);
const webStyle = {
    buttonSave: {
        width: '177px',
        height: '56px',
        backgroundColor: '#2B6FED',
        borderRadius: "8px",
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        color: "#FFFFFF",
        fontWeight: 'bold',
        padding: '0px 20.5px',
        textTransform: 'capitalize'
    }
}
// Customizable Area End