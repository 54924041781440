//Customizable Area Start
import React from "react";
import {
    Container,
    Box,
    Grid,
    Hidden,
} from "@material-ui/core";
import RealEstateCompanyDetailsController, {
    Props,
} from './RealEstateCompanyDetailsController.web'
import { withRouter } from 'react-router-dom';
import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";
import RealEstateCompanyDetailsCard from "./RealEstateCompanyDetailsCard.web";

class RealEstateCompanyDetails extends RealEstateCompanyDetailsController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { navigation } = this.props;
        const match:any= this.props.match;
        const id = match?.params?.id;

        return (
            <>
                <MainScreen>
                    <RealEstateCompanyDetailsCard navigation={navigation} id={"company-details-card"} companyId={id} />
                </MainScreen>
            </>
        );
    }
}

const webStyle = {
};

export default withRouter(RealEstateCompanyDetails)
//Customizable Area End


