// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  t:(value:string)=> string
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  addMoreConditionData:Array<AddMoreConditionData>,
  addMoreConditionModal:boolean,
  addMoreTermsModal:boolean
  fixedPercentageOfRentAmount:string;
  // Customizable Area End
}

// Customizable Area Start
export interface AddMoreConditionData{
  title:string,
  options:Array<string>,
}
// Customizable Area End

interface SS {
  id: string;
}
export default class LeaseManagementIssueNewContractAddTermsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      addMoreConditionData:[
        {
          title:"Personal Conditions",
          options:[
            "No pets allowed",
            "No party on weekdays",
            "No noise after 10pm on weekdays",
            "No noise after 12am on weekends",
            "Rent payments to be made between 1st and 5th of every month",
            "Rent payments to be made between 1st and 5th of every quarter",
            "Rent payments to be made between 1st and 5th January of  every year upfornt for that perticular year",
          ]
        },
        {
          title:"Payment Terms",
          options:[
            "No pets allowed",
            "No party on week days",
            "No noise after 10pm on weekdays",
            "No noise after 12am on weekends",
            "Rent payments to be made between 1st and 5th of every month",
            "Rent payments to be made between 1st and 5th of every quarter",
            "Rent payments to be made between 1st and 5th January of  every year upfornt for that perticular year",
          ]
        },
      ],
      addMoreConditionModal:false,
      addMoreTermsModal:false,
      fixedPercentageOfRentAmount:"",
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount()
    // Customizable Area End
  }
  
  // Customizable Area Start
  handleBack=()=>{
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "IssueNewContractTemplate"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  }
  handleNext=()=>{
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "IssueNewContractForm"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  }
  handleOpenAddMoreCondition=()=>{
    this.setState({addMoreConditionModal:true})
  }
  handleCloseAddMoreCondition=()=>{
    this.setState({addMoreConditionModal:false})
  }
handleOpenMoreTermsModal=()=>{
  this.setState({addMoreTermsModal:true,addMoreConditionModal:false})
}
handleCloseMoreTermsModal=()=>{
  this.setState({addMoreTermsModal:false,addMoreConditionModal:true})
}
  // Customizable Area End
}