// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
const navigation = require("react-navigation")
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: typeof navigation;
    t:(value:string)=> string
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    reviewDraftDetails: ContractDetailsSuccessResponse;
    successError: HasSuccessErrorType;
    hasSuccessError: HasSuccessErrorType;
    // Customizable Area End
}

// Customizable Area Start
interface HasSuccessErrorType {
  isOpen: boolean,
  isSeverity: "success" | "info" | "warning" | "error",
  isMessage: string,
};
export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
    baseURL?: string;
  };

  export interface ContractDetailsSuccessResponse {
    contract: {
      data: {
        id: string;
        type: string;
        attributes: {
          apartment_management_id: number;
          account_id: number;
          building_management_id: number;
          society_management_id: number;
          agreement_duration: string;
          region: string | null;
          country: string | null;
          landlord_name: string;
          start_date: string;
          city: string | null;
          currency: string;
          state: string | null;
          expires_on: string;
          rent_amount: number;
          duration: string | null;
          tenant_name: string;
          penanlty_late_payment: boolean;
          lease_template_id: number;
          owner: {
            first_name: string | null;
            phone_number: number | null;
            id: number;
            email: string;
            last_name: string | null;
            device_id: string | null;
            country_code: string | null;
            created_at: string;
            activated: boolean;
            full_phone_number: string;
            unique_auth_id: string | null;
            updated_at: string;
            user_name: string | null;
            last_visit_at: string | null;
            user_type: string | null;
            suspend_until: string | null;
            app_language_id: number | null;
            platform: string | null;
            stripe_id: string | null;
            role_id: number | null;
            status: string;
            is_blacklisted: boolean;
            stripe_subscription_id: string | null;
            stripe_subscription_date: string | null;
            full_name: string;
            date_of_birth: string | null;
            is_paid: boolean;
            gender: string | null;
            age: number | null;
            owner_phone_number: string | null;
            company_name: string | null;
            manager_full_name: string | null;
            owner_full_name: string | null;
            owner_email: string | null;
            public_field: string | null;
            building_management_id: number | null;
            disable_chat: boolean;
            language_type: string | null;
            ticket_days_configuration: number;
          };
          contract_template: string;
          custom_contract: boolean;
          created_at: string;
          updated_at: string;
          status: string;
          owner_name: string | null;
          
          
          lease_template_text: string;
          building_name: string;
          contract_type: string;
          expire_on: number;
          lease_template_pdf: {
            url: string;
          };
          contract_template_pdf: {
            url: string;
          };
          
          society_management: {
            name: string;
            created_at: string;
            updated_at: string;
            description: string;
            complex_area: string;
            ticket_days_configuration: number;
            maintenance_per_square_feet: number;
            measurement_unit: string;
            id: number;
            zone_multiplier: number;
            contigency: number;
            latitude: string | null;
            late_charge_percentage: string;
            real_estate_company_id: number | null;
            value_added_tax: string;
            longitude: string | null;
            account_manager_id: number | null;
            is_building: boolean;
            total_unit: number | null;
            total_floor: number | null;
            language: string | null;
            status: string | null;
            currency_id: number | null;
          };
          tenant: {
            id: number;
            first_name: string | null;
            last_name: string | null;
            full_phone_number: string;
            country_code: string | null;
            phone_number: number | null;
            email: string;
            activated: boolean;
            device_id: string | null;
            unique_auth_id: string | null;
            user_name: string | null;
            created_at: string;
            user_type: string | null;
            updated_at: string;
            platform: string | null;
            app_language_id: number | null;
            suspend_until: string | null;
            last_visit_at: string | null;
            stripe_id: string | null;
            status: string;
            is_blacklisted: boolean;
            stripe_subscription_date: string | null;
            stripe_subscription_id: string | null;
            role_id: number | null;
            full_name: string;
            age: number | null;
            gender: string | null;
            company_name: string | null;
            date_of_birth: string | null;
            owner_full_name: string | null;
            is_paid: boolean;
            owner_phone_number: string | null;
            manager_full_name: string | null;
            disable_chat: boolean;
            owner_email: string | null;
            language_type: string | null;
            building_management_id: number | null;
            public_field: string | null;
            ticket_days_configuration: number;
          };
          penalty_late_payments: {
            penanlty_type: string | null;
            id: number;
            penanlty_counted: string;
            tenant_id: number;
            account_id: number;
            amount: string;
          };
          terms: null;
          custom_term_condition: null;
          conditions: null;
          unit_name: string;
          custom_contract_image: string | null;
          custom_term_condition_added: boolean;
        };
      };
    };
    message: string;
    code: number;
  };
  export interface ApiFailureResponseError {
    token: string
  };
  export interface ApiFailureResponse {
    errors: Array<ApiFailureResponseError>
  };
  
  

  interface HasSuccessErrorType {
    isOpen: boolean,
    isSeverity: "success" | "info" | "warning" | "error",
    isMessage: string,
  };
// Customizable Area End

interface SS {
    id: string;
}
export default class LeaseManagementReviewDraftController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    reviewDraftDetailsApiCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        // Customizable Area End
        this.state = {
            // Customizable Area Start
            successError: {
                isOpen: false,
                isSeverity: "success",
                isMessage: ""
              },
              hasSuccessError: {
                isOpen: false,
                isSeverity: "success",
                isMessage: ""
            },
            reviewDraftDetails: {
        
              "code": 200,
                "message": "Successfully updated",
                "contract": {
                  "data": {
                    "type": "contract",
                    "id": "3",
                    "attributes": {
                      "account_id": 25,
                      "building_management_id": 25,
                      "apartment_management_id": 489,
                      "landlord_name": "friya",
                      "country": null,
                      "region": null,
                      "society_management_id": 31,
                      "agreement_duration": "24 month",
                      "start_date": "2024-01-08",
                      "state": null,
                      "rent_amount": 10000.0,
                      "currency": "USD",
                      "expires_on": "2026-07-20",
                      "status": "Active",
                      "tenant_name": "freny",
                      "city": null,
                      "duration": null,
                      "created_at": "2024-01-08T07:42:20.477Z",
                      "penanlty_late_payment": true,
                      "custom_contract": false,
                      "lease_template_id": 1,
                      "owner_name": null,
                      "updated_at": "2024-01-08T07:42:20.477Z",
                      "contract_template": "<p>Tenant name :-&nbsp;<strong>freny</strong></p><p>This agreement made on this&nbsp;<strong>January 08, 2024</strong>&nbsp;between&nbsp;<strong>friya</strong>, residing&nbsp;<strong>Emaar Creek B</strong>,&nbsp;<strong>A-202</strong>&nbsp;on duration of&nbsp;<strong>24 month</strong>,&nbsp;<strong>July 20, 2026</strong>&nbsp;on rent amount&nbsp;<strong>USD 10000</strong>.</p><p><br></p><p>WHEREAS the Lessor is the lawful owner of, and otherwise well sufficiently entitled to [Lease Property Address Line 1, Address Line 2, City, State, Pin Code] falling in the category, [Independent House / Apartment / Farm House / Residential Property] and comprising of with an extent of [ Square Feet] hereinafter referred to as the 'said premises';</p><p><br></p><p>AND WHEREAS at the request of the Lessee, the Lessor has agreed to let the said premises to the tenant for a term of [Lease Term] commencing from [Lease Start Date] in the manner hereinafter appearing.</p><p><strong>Emaar Creek Harbour Breeze</strong></p><p><strong> Al Khazai Al Khazai, 3173, Al Olaya, Riyadh 12611, Saudi Arabia</strong></p><p><br></p><p>Thanks,</p><p><strong>friya</strong></p>",
                      "owner": {
                        "first_name": null,
                        "last_name": null,
                        "id": 25,
                        "full_phone_number": "+966576109275",
                        "email": "friya@yopmail.com",
                        "phone_number": 576109275,
                        "device_id": null,
                        "country_code": null,
                        "unique_auth_id": null,
                        "created_at": "2024-01-08T07:22:35.151Z",
                        "updated_at": "2024-01-08T07:23:24.694Z",
                        "platform": null,
                        "activated": true,
                        "user_type": null,
                        "app_language_id": null,
                        "last_visit_at": null,
                        "is_blacklisted": false,
                        "user_name": null,
                        "suspend_until": null,
                        "stripe_id": null,
                        "stripe_subscription_id": null,
                        "stripe_subscription_date": null,
                        "status": "regular",
                        "full_name": "friya",
                        "gender": null,
                        "date_of_birth": null,
                        "age": null,
                        "company_name": null,
                        "owner_full_name": null,
                        "role_id": null,
                        "is_paid": false,
                        "manager_full_name": null,
                        "owner_phone_number": null,
                        "disable_chat": false,
                        "owner_email": null,
                        "public_field": null,
                        "language_type": null,
                        "ticket_days_configuration": 0,
                        "building_management_id": null,
                      },
                      "contract_template_pdf": {
                        "url": "https://ti1finalleap-158677-ruby.b158677.dev.eastus.az.svc.builder.cafe/society_managements/31/bx_block_contract/contracts/3/contract_template.pdf"
                      },
                      "lease_template_pdf": {
                        "url": "https://ti1finalleap-158677-ruby.b158677.dev.eastus.az.svc.builder.cafe/bx_block_contract/lease_templates/1/lease_template.pdf"
                      },
                      
                      
                      "contract_type": "Individual",
                      "expire_on": 12,
                      "lease_template_text": "<p>Tenant name :-&nbsp;<strong>{{TENANT_NAME}}</strong></p><p>This agreement made on this&nbsp;<strong>{{START_DATE}}</strong>&nbsp;between&nbsp;<strong>{{LANDLORD_NAME}}</strong>, residing&nbsp;<strong>{{BUILDING_NAME}}</strong>,&nbsp;<strong>{{UNIT_NAME}}</strong>&nbsp;on duration of&nbsp;<strong>{{DURATION}}</strong>,&nbsp;<strong>{{END_DATE}}</strong>&nbsp;on rent amount&nbsp;<strong>{{AMOUNT}}</strong>.</p><p><br></p><p>WHEREAS the Lessor is the lawful owner of, and otherwise well sufficiently entitled to [Lease Property Address Line 1, Address Line 2, City, State, Pin Code] falling in the category, [Independent House / Apartment / Farm House / Residential Property] and comprising of with an extent of [ Square Feet] hereinafter referred to as the 'said premises';</p><p><br></p><p>AND WHEREAS at the request of the Lessee, the Lessor has agreed to let the said premises to the tenant for a term of [Lease Term] commencing from [Lease Start Date] in the manner hereinafter appearing.</p><p><strong>{{COMPLEX_NAME}}</strong></p><p><strong>{{COMPLEX_ADDRESS}}</strong></p><p><br></p><p>Thanks,</p><p><strong>{{LANDLORD_NAME}}</strong></p>",
                      "building_name": "Emaar Creek B",
                      "society_management": {
                        "description": "Emirates Crown is a 63-floor residential tower in Dubai, United Arab Emirates, developed by Bin Shafar Holding and designed by Design & Architecture Bureau. The tower has a structural height of 296 m (971 ft). Construction of the Emirates Crown began in 2005, and was completed in 2008. Upon completion, it stood as the sixth-tallest building in Dubai, and 45th-tallest building in the world. As of 2022, it is the 26th-tallest building in Dubai.",
                        "maintenance_per_square_feet": 100.0,
                        "id": 31,
                        "created_at": "2024-01-05T07:27:47.192Z",
                        "ticket_days_configuration": 0,
                        "complex_area": "10000000",
                        "name": "Emirates Crown",
                        "real_estate_company_id": null,
                        "updated_at": "2024-01-05T07:27:47.290Z",
                        "zone_multiplier": 7.0,
                        "contigency": 7.0,
                        "latitude": null,
                        "late_charge_percentage": "10.0",
                        "measurement_unit": "Sqft",
                        "value_added_tax": "10.0",
                        "longitude": null,
                        "total_unit": null,
                        "is_building": false,
                        "account_manager_id": null,
                        "total_floor": null,
                        "currency_id": null,
                        "language": null,
                        "status": null,
                      },
                      "unit_name": "A-202",
                      "custom_term_condition_added": false,
                      "conditions": null,
                      "custom_term_condition": null,
                      "terms": null,
                      "tenant": {
                        "first_name": null,
                        "id": 26,
                        "full_phone_number": "+966-576987100",
                        "last_name": null,
                        "company_name": null,
                        "manager_full_name": null,
                        "owner_full_name": null,
                        "owner_email": null,
                        "public_field": null,
                        "phone_number": null,
                        "owner_phone_number": null,
                        "disable_chat": false,
                        "ticket_days_configuration": 0,
                        "language_type": null,
                        "email": "freny@yopmail.com",
                        "country_code": null,
                        "building_management_id": null,
                        "unique_auth_id": null,
                        "device_id": null,
                        "suspend_until": null,
                        "status": "regular",
                        "activated": true,
                        "stripe_subscription_id": null,
                        "stripe_id": null,
                        "stripe_subscription_date": null,
                        "full_name": "freny",
                        "gender": null,
                        "date_of_birth": null,
                        "role_id": null,
                        "is_paid": false,
                        "age": null,
                        "platform": null,
                        "user_type": null,
                        "app_language_id": null,
                        "updated_at": "2024-01-08T07:40:49.084Z",
                        "created_at": "2024-01-08T07:40:49.084Z",
                        "last_visit_at": null,
                        "user_name": null,
                        "is_blacklisted": false,
                        
                      },
                      "custom_contract_image": null,
                      "penalty_late_payments": {
                        "id": 1,
                        "penanlty_counted": "Fixed Amount",
                        "account_id": 25,
                        "penanlty_type": null,
                        "tenant_id": 26,
                        "amount": "10",
                      }
                    }
                  }
                },
              }
            // Customizable Area End
        };
        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();
        this.getContractDetails();
        // Customizable Area End
    }
    // Customizable Area Start
      draftReviewContractDetailsApiCall = async (data: APIPayloadType) => {
        let token = localStorage.getItem("loginSuccessToken")
        let { method, endPoint, body, type = "", contentType } = data;
        const header = {
          token: token,
          "Content-Type": contentType,
        };
        let contractDetailsrequestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        contractDetailsrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        contractDetailsrequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        contractDetailsrequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        contractDetailsrequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        body && type !== "formData"
          ? contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          )
          : contractDetailsrequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
          );
        runEngine.sendMessage(contractDetailsrequestMessage.id, contractDetailsrequestMessage);
        return contractDetailsrequestMessage.messageId;
      };

      async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (responseJson && !responseJson.errors) {
            this.contractDetailsApiResponseSucessCell(apiRequestCallId, responseJson);
          } else if (responseJson && responseJson.errors) {
            this.contractDetailsApiResponseFailureCall(apiRequestCallId, responseJson);
          }
        }
      };
  
      contractDetailsApiResponseSucessCell = async (
        apiRequestCallId: string,
        responseJson: ContractDetailsSuccessResponse) => {
        if (apiRequestCallId === this.reviewDraftDetailsApiCallId) {
          this.reviewDraftDetailsSucessCallBack(responseJson);
        }
      };
    
      contractDetailsApiResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
        if (apiRequestCallId === this.reviewDraftDetailsApiCallId) {
          this.reviewDraftDetailsFailureCallBack(responseJson);
        }
      };
    
      getContractDetails = async () => {
        const constractId = this.props.navigation.getParam("id");    
        this.reviewDraftDetailsApiCallId = await this.draftReviewContractDetailsApiCall({
          method: configJSON.contractDetailsMethod,
          endPoint: `${configJSON.contractDetailsEndPoint}/${constractId}`,
        });
      };
    
      reviewDraftDetailsSucessCallBack = (response: ContractDetailsSuccessResponse) => {
        this.setState({reviewDraftDetails:response});
      };
    
      reviewDraftDetailsFailureCallBack = (response: ApiFailureResponse) => {
        this.setState({
          successError: {
            isOpen: true,
            isSeverity: "error",
            isMessage: response.errors[0].token
          },
        });
      };

      handleCloseSnack = () => {
        this.setState({
            hasSuccessError: {
                isOpen: false,
                isSeverity: "success",
                isMessage: ""
            }
        });
    };
    // Customizable Area End
}