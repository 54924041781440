import React from "react";
// Customizable Area Start
import {
    Box, Typography, Grid,Button, Table, TableContainer, TableHead, TableRow, TableCell, TableBody,
    TextField, InputAdornment
} from "@material-ui/core";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup';
//@ts-ignore
import CompEmpComplexBuildingBlockStep3Controller from './CompEmpComplexBuildingBlockStep3Controller.web'
import "../../assets/commonForm.css"
// Customizable Area End

const validationSchema = Yup.object().shape({
    UnitNumber: Yup.string().required('Unit Number is required'),
    FloorNumber: Yup.string().required('Floor Numer is required'),
    ResidentName: Yup.string().required('Resident Name is required'),
    Configuration:Yup.string().required('Configuration is required'),
    Size: Yup.string().required('Size is required'),
  });

export default class CompEmpComplexBuildingBlockStep3 extends CompEmpComplexBuildingBlockStep3Controller {
    render() {
        // Customizable Area Start
        
        
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
            <Box>
                                    <Box>
                                        <Box className="complex_building_block_step3" style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                                            <Box className="complex_building_block_step3">
                                                <Typography className="Units complex_building_block_step3 bold-text" style={{ fontSize: '22px', fontWeight: 'bold' }}>Units</Typography>
                                            </Box>
                                            <Box className="complex_building_block_step3">
                                                <Typography className="stepUnits complex_building_block_step3" style={{ fontSize: '16px' }}>Step {this.props.stepBuilding + 1} of 7</Typography>
                                            </Box>
                                        </Box>
                                        <Box style={{ width: '100%', display: 'flex', marginTop: '30px', justifyContent: 'space-between', flexWrap: 'wrap', }} className="complex_building_block_step3">
                                            <TableContainer className="complex_building_block_step3">
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow className="complex_building_block_step3">
                                                            <TableCell className="# complex_building_block_step3" style={{ fontWeight: 'bold' }}>#</TableCell>
                                                            <TableCell className="Unit complex_building_block_step3" style={{ fontWeight: 'bold' }}>Unit Number</TableCell>
                                                            <TableCell className="Floor complex_building_block_step3" style={{ fontWeight: 'bold' }}>Floor Number</TableCell>
                                                            <TableCell className="Resident complex_building_block_step3" style={{ fontWeight: 'bold' }}>Resident Name</TableCell>
                                                            <TableCell className="Owner complex_building_block_step3" style={{ fontWeight: 'bold' }}>Owner</TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                    {this.props.unitRecords.map((index: any, i:number) => (
                                                            <TableRow key={i}>
                                                                <TableCell className="# step3Formik">{i+1}</TableCell>
                                                                <TableCell className="Unit step3Formik">{index.UnitNumber}</TableCell>
                                                                <TableCell className="Floor step3Formik">{index.FloorNumber}</TableCell>
                                                                <TableCell className="Resident step3Formik">{index.ResidentName}</TableCell>
                                                                <TableCell className="Owner step3Formik">{index.OwnerName}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Box style={{ borderRadius: '8px', marginTop: '20px', border: '1px solid #2B6FED', }} className="complex_building_block_step3">
                                                <Box style={{ color: '#2B6FED', padding: '10px 25px', cursor: "grabbing", fontSize: '15px', fontWeight: 'bold', }} className="complex_building_block_step3 bold-text" onClick={this.props.togglePopupBuilding}>+ ADD NEW UNIT</Box>
                                            </Box>
                                            <Box>
                                                <Box style={{
                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                    display: this.props.isPopupOpen ? 'block' : 'none',
                                                    top: 0,
                                                    left: 0,
                                                    position: 'fixed',
                                                    width: '100%',
                                                    zIndex: 9999,
                                                    height: '100%',
                                                }} className="complex_building_block_step3">
                                                    <Box className="complex_building_block_step3" style={{
                                                        top: '50%',
                                                        width: '50%',
                                                        position: 'absolute',
                                                        left: '50%',
                                                        borderRadius: '5px',
                                                        height: '90%',
                                                        backgroundColor: '#fff',
                                                        overflow: 'auto',
                                                        transform: 'translate(-50%, -50%)',
                                                    }}>
                                                        <Box>
                                                            <Box className="topBarName NewUnit complex_building_block_step3">
                                                                <Box className="complex_building_block_step3" style={{ fontSize: '22px', fontWeight: 'bold' }}>Add New Unit</Box>
                                                                <Box className="complex_building_block_step3" onClick={this.props.togglePopupBuilding} style={{ cursor: "grabbing" }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                        <path d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z" fill="black" />
                                                                    </svg>
                                                                </Box>
                                                            </Box>
                                                            <Box className="complex_building_block_step3" style={{ borderBottom: '1px solid #e7e7e7', }}></Box>
                                                            <Formik
                                                            data-testid="step3Form"
                        initialValues={this.props.unitValues}
                        validationSchema={validationSchema}
                        validateOnMount={true}
                        onSubmit={(values, actions) => {
                            this.props.handleUnitDetails(values)
                            actions.resetForm();
                        }}
                    >
                        {({ values, touched, errors, isValid, setFieldValue, handleChange }) => (
                            <Form translate="yes" className="commonForms">
                                                            <Box className="complex_building_block_step3" style={{ padding: '25px', }}>
                                                                <Box>
                                                                    <Box className="complex_building_block_step3" style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                                                        <Box className="complex_building_block_step3" style={{ marginRight: '25px', width: '50%' }}>
                                                                            <Typography className="typographyLable Unit complex_building_block_step3">Unit Number</Typography>
                                                                            <Box className="formGroups complex_building_block_step3" style={{ marginBottom: "0px", border: "1px solid #F0F0F0", borderRadius: "8px", backgroundColor: "#F0F0F0" }}>
                                                                                <Field name="UnitNumber" type="text" placeholder="Unit Number" className="formInputs complex_building_block_step3" />
                                                                            </Box>
                                                                            <Box style={webStyle.errorMsg}>
                                                        <ErrorMessage name="UnitNumber" component="div" data-testid="UnitNumber-error" /></Box>
                                                                        </Box>
                                                                        <Box style={{ width: '50%' }} className="complex_building_block_step3">
                                                                            <Typography className="typographyLable Floor complex_building_block_step3">Floor Number</Typography>
                                                                            <Box className="formGroups complex_building_block_step3" style={{ border: "1px solid #F0F0F0", marginBottom: "0px", borderRadius: "8px", backgroundColor: "#F0F0F0" }}>
                                                                                <Field name="FloorNumber" type="text" placeholder="Floor Number" className="formInputs complex_building_block_step3" />
                                                                            </Box>
                                                                            <Box className="complex_building_block_step3" style={webStyle.errorMsg}>
                                                        <ErrorMessage name="FloorNumber" component="div" data-testid="FloorNumber-error" /></Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box className="complex_building_block_step3" style={{ marginTop: '20px' }}>
                                                                    <Box className="complex_building_block_step3" style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                                                        <Box className="complex_building_block_step3" style={{ width: '50%', marginRight: '25px' }}>
                                                                            <Typography className="typographyLable Owner complex_building_block_step3">Owner Name (Optional)</Typography>
                                                                            <Box className="formGroups Owner" style={{ marginBottom: "0px", borderRadius: "8px", backgroundColor: "#F0F0F0", border: "1px solid #F0F0F0", }}>
                                                                                <Field name="OwnerName" type="text" placeholder="Owner Name" className="formInputs complex_building_block_step3" />
                                                                            </Box>
                                                                        </Box>
                                                                        <Box className="complex_building_block_step3" style={{ width: '50%', }}>
                                                                            <Typography className="typographyLable Resident complex_building_block_step3">Resident Name</Typography>
                                                                            <Box className="formGroups Resident complex_building_block_step3" style={{ backgroundColor: "#F0F0F0", marginBottom: "0px", border: "1px solid #F0F0F0", borderRadius: "8px", }}>
                                                                                <Field name="ResidentName" type="text" placeholder="Resident Name" className="formInputs complex_building_block_step3" />
                                                                            </Box>
                                                                            <Box style={webStyle.errorMsg}>
                                                        <ErrorMessage name="ResidentNumber" component="div" data-testid="ResidentNumber-error" /></Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box style={{ marginTop: '20px', }} className="complex_building_block_step3">
                                                                    <Box className="complex_building_block_step3" style={{ justifyContent: 'space-between', display: 'flex', width: '100%', }}>
                                                                        <Box className="complex_building_block_step3" style={{ marginRight: '25px', width: '50%', }}>
                                                                            <Typography className="typographyLable Configuration complex_building_block_step3">Configuration</Typography>
                                                                            <Box className="formGroups Configuration complex_building_block_step3" style={{ marginBottom: "0px", borderRadius: "8px", backgroundColor: "#F0F0F0", border: "1px solid #F0F0F0", }}>
                                                                                <Field name="Configuration" type="text" placeholder="Configuration (BHK)" className="formInputs complex_building_block_step3" />
                                                                            </Box>
                                                                            <Box style={webStyle.errorMsg}>
                                                        <ErrorMessage name="Configuration" component="div" data-testid="Configuration-error" /></Box>
                                                                        </Box>
                                                                        <Box className="complex_building_block_step3" style={{ width: '50%' }}>
                                                                            <Typography className="typographyLable Size complex_building_block_step3">Size</Typography>
                                                                            <Box className="formGroups complex_building_block_step3" style={{ marginBottom: "0px", borderRadius: "8px", backgroundColor: "#F0F0F0", border: "1px solid #F0F0F0", }}>
                                                                            <Field
    name="Size"
    type="text"
    placeholder="Size"
    className="formInputs complex_building_block_step3"
    validate={(value:any) => {
        // Add any validation logic here if needed
    }}
>
    {({ field, form }: {field:any, form:any}) => (
        <TextField
        {...field}
        name="Size"
        type="text"
        placeholder="Size"
        className="formInputs complex_building_block_step3"
        InputProps={{
            endAdornment: <InputAdornment position="end">sqft</InputAdornment>
        }}
        inputProps={{
            maxLength: 20
        }}
         />
    )}
</Field>
                                                                                
                                                                            </Box>
                                                                            <Box style={webStyle.errorMsg}>
                                                        <ErrorMessage name="Size" component="div" data-testid="Size-error" /></Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box className="complex_building_block_step3" style={{ marginTop: '20px', }}>
                                                                    <Box className="complex_building_block_step3" style={{ justifyContent: 'space-between', display: 'flex', width: '100%', }}>
                                                                        <Box className="complex_building_block_step3" style={{ width: '50%', marginRight: '25px' }}>
                                                                            <Typography className="typographyLable Purchase complex_building_block_step3">Purchase Price (Optional)</Typography>
                                                                            <Box className="formGroups complex_building_block_step3" style={{ borderRadius: "8px", marginBottom: "0px", border: "1px solid #F0F0F0", backgroundColor: "#F0F0F0" }}>
                                                                                <Field type="text" name="PurchasePrice" placeholder="Purchase Price" className="formInputs complex_building_block_step3" />
                                                                            </Box>
                                                                        </Box>
                                                                        <Box style={{ width: '50%', }}>
                                                                            <Typography className="typographyLable Purchase complex_building_block_step3">Purchase Date (Optional)</Typography>
                                                                            <Box className="formGroups complex_building_block_step3" style={{ border: "1px solid #F0F0F0", borderRadius: "8px", backgroundColor: "#F0F0F0", marginBottom: "0px", }}>
                                                                                <Field name="PurchaseDate" placeholder="Purchase Date" className="formInputs complex_building_block_step3" type="date" />
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box style={{ marginTop: '20px', }}>
                                                                    <Box style={{ width: '100%', }}>
                                                                        <Box>
                                                                            <Typography className="typographyLable complex_building_block_step3">Current Valuation (Optional)</Typography>
                                                                            <Box className="formGroups complex_building_block_step3" style={{ marginBottom: "0px", borderRadius: "8px", border: "1px solid #F0F0F0", backgroundColor: "#F0F0F0" }}>
                                                                                <Field placeholder="Current Valuation" name="CurrentValuation" type="text" className="formInputs complex_building_block_step3" />
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box className="complex_building_block_step3" style={{ marginTop: '15px', display: 'flex', justifyContent: 'end' }}>
                                                                    <Grid className="complex_building_block_step3" style={{ marginRight: '20px', marginTop: "30px", }}>
                                                                        <Button onClick={this.props.togglePopupBuilding} className="buttonCancel complex_building_block_step3" ><span className="bold-text">CANCEL</span></Button>
                                                                    </Grid>
                                                                    <Grid style={{ marginTop: "30px"}}>
                                                                        <Button 
                                                                        type="submit"
                                                                        className="buttonSave complex_building_block_step3" ><span className="bold-text">SAVE</span></Button>
                                                                    </Grid>
                                                                </Box>
                                                            </Box>
                                                            </Form>)}
                                </Formik>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                {this.props.multipleButton()}
                                </>

            // Customizable Area End
        );
    }
}

// export default withTranslation()(withRouter(CompEmpComplexBuildingBlock));
// Customizable Area Start
const webStyle = {
    errorMsg:{
        color:'red',
        fontSize: '12px',
        marginTop:'5px'
      },
    }
// Customizable Area End