Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.PatchAPiMethod = "PATCH"
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContentManagement";
exports.labelBodyText = "ContentManagement Body";
exports.createIncident="bx_block_custom_form/incidents"
exports.updateIncident="bx_block_custom_form/incidents/"
exports.incidentRelated="bx_block_custom_form/incidents/incident_related_list"
exports.check="/:(.*?);/"

exports.btnExampleTitle = "CLICK ME";

exports.contractListingMethod = "GET";
exports.contractListingEndPoint = "society_managements/3/bx_block_contract/employee_contracts";
exports.saveTemplatesMethod = "GET";
exports.saveTemplatesEndPoint = "society_managements/1/bx_block_contract/employee_contracts/saved_template"
exports.draftListMethod = "GET";
exports.draftListEndPoint = "society_managements/1/bx_block_contract/employee_contracts/draft_lease_list";
exports.countryListMethod = "GET";
exports.countryEndPoint = "society_managements/3/bx_block_contract/saved_template_countries";
exports.cityListMethod = "GET";
exports.cityEndPoint = "society_managements/3/bx_block_contract/saved_template_cities";
exports.buildingListMethod = "GET";
exports.buildingListEndPoint = "bx_block_custom_form/incident_managements/list_of_building";
exports.complexListMethod = "GET";
exports.complexListEndPoint = "bx_block_society_management/company_employee/complexes";
exports.unitNumberListMethod = "GET";
exports.unitNumberListEndPoint = "bx_block_settings/apartment_managements";
exports.countryCodeAndFlagMethod = "GET";
exports.countryCodeAndFlagEndPoint = "account/accounts/country_code_and_flag";
exports.contractDetailsMethod = "Get";
exports.contractDetailsEndPoint = "society_managements/1/bx_block_contract/employee_contracts"
exports.terminateContractMethod = "PUT";
exports.terminateContractEndPoint = "society_managements/1/bx_block_contract/employee_contracts";
exports.draftContractDetailsMethod = "GET";
exports.draftContractDetailstEndPoint = "";
exports.deleteDraftContractDetailsMethod = "GET";
exports.deleteDraftContractDetailstEndPoint = "";
exports.downloadContractMethod = "GET";
exports.downloadContractEndPoint = "";
exports.shareContractMethod = "GET";
exports.shareContractEndPoint = "";
exports.templateListingMethod = "GET";
exports.templateListingEndPoint = "";
exports.searchTemplateListingMethod = "GET";
exports.searchTemplateListingEndPoint = "";
exports.deleteTemplateMethod = "";
exports.deleteTemplateEndPoint = "";
exports.complexTab = "Complex";
exports.SelectCountry = "Select Country";
exports.SelectCity = "Select City";
exports.SelectComplex = "Select Complex";
exports.SelectBuilding = "Select Building";
exports.SelectUnitNumber = "Select Unit Number";
exports.SelectContractStatus = "Select Contract Status";
exports.Owner = "Owner";
exports.ContractIssuer = "Contract Issuer";
exports.Search = "Search";
exports.LeaseManagement = "Lease Management";
exports.ContractListing = "Contracts Listing";
exports.SavedTemplates = "Saved Templates";
exports.Drafts = "Drafts";
exports.SortBy = "Sort By";
exports.OwnerName = "Owner Name";

// Customizable Area End