import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";

export interface Props {
    stepBuilding: number,
    handleBasicDetails:any,
    buildingValues:any,
    imgUplodeAvtarBuilding:any,
    multipleButton:any,
    handleBackBuilding:any,
    handleNextBuilding:any,
    uploadImageName:any,
    imagesComplex:any,
    handleChangeBuilding:any,
    desigenImg:any,
    currencyList:any,
    handleChangeCurrency:any,
    leaseList:any,
    leaseSelected:any,
    rentalSelected:any,
    handleSelectLease:any,
    handleOpenLeasePopup:any,
    handleCloseLeasePopup:any,
    leasePopup:any,
    currencySelected:any,
    measurementList:any,
    handleChangeMeasurement:any,
    measurementSelected:any,
    timezonelist:any,
    handleChangeTimezone:any
    timezoneSelected:any,
    formulaList:any,
    handleChangeFormula:any,
    formulaSelected:any,
    handleFieldChange:any,
    handleTotalAreaUnit:any,
    totalAreaUnit:any,
    accountType:any,
    accountManagerList:any,
    t?: any
    i18n?: any
    match?: any
    location?: any
    // Customizable Area Start
    history?: any
    language?: string
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CompEmpBuildingBlockStep2Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
    exampleAPICallId: string = "";

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

        this.state = {
            // Customizable Area Start
            loading: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    //   }

    async componentDidMount(): Promise<void> {
        // Customizable Area Start
        // await this.getJurisdictionList();
        // Customizable Area End
    }

    // Customizable Area Start

    
    // Customizable Area End
}
