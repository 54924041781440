import React from "react";
// Customizable Area Start
import {
    Box, Typography, MenuItem, FormControl, Select, Grid, Button, TextField, InputAdornment
} from "@material-ui/core";
//@ts-ignore
import CompEmpBuildingBlockStep2Controller from './CompEmpBuildingBlockStep2Controller.web'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {Formik, Form, Field, ErrorMessage} from "formik";
import {numbersOnly, alphaNumericOnly} from "../../../../components/src/KeyBlockFunctions";
import * as Yup from 'yup';
import "../../assets/commonForm.css"
// Customizable Area End

export default class CompEmpBuildingBlockStep2 extends CompEmpBuildingBlockStep2Controller {

    validationSchema = Yup.object().shape({
        buildingName: Yup.string().min(3, 'Building name must be at least 3 characters')
            .max(50, 'Building name must be at most 50 characters').required('Building Name is required'),
        totalArea: Yup.string().required('Total Area is required'),
        totalFloors: Yup.string().required('Total Floor is required'),
        totalUnits: Yup.string().required('Total Units is required'),
        aboutUs: Yup.string().required('About Us is required'),
        currency: Yup.string().required('Currency is required'),
        timezone: Yup.string().required('Timezone is required'),
        formula: Yup.string().required('Formula is required'),
        measurement: Yup.string().required('Measurement is required'),
        language: Yup.string().required('Language is required'),
        logo: Yup.mixed().required('Please upload a logo'),
        images: Yup.array()
            .min(1, 'At least one image is required')
            .max(6, 'Maximum of 6 images allowed')
            .required('Please upload images'),
        ...(this.props.accountType !== "coo"
            ? {
                accountManager: Yup.string().required('Account Manager is required'),
            }
            : {}),
    });

    logoDisplayBuilding = (values: any) => {
        const {t} = this.props;
        return (
            <>
                {
                    values.logo ? <img className="buildingLogoStep" data-testid="preview_img"
                                       src={URL.createObjectURL(values.logo)}
                                       alt="Preview" style={{
                        width: '66px',
                        height: '66px',
                        borderRadius: '50%',
                        background: '#dcdcdc'
                    }}/> : <Box className="buildingLogoStep" style={{
                        width: '66px',
                        height: '66px',
                        borderRadius: '50%',
                        background: '#dcdcdc'
                    }}>
                    </Box>
                }

                <label htmlFor="logoChange" className="buildingLogoStep"><span style={{
                    color: '#FC8434'
                }} className="bold-text buildingLogoStep">
                          {values.logo ? <>{t("Change Logo")}</> : <>{t("Add Logo")}</>}
                        </span></label>
            </>
        )
    }

    photosDisplayBuilding = (values: any, setFieldValue: any) => {
        return (
            <>
                {values.images.length < 6 && (
                    <>
                        <label id="label-file-upload" className="buildingLogoStep" htmlFor="input-file-upload"
                               style={{marginRight: '23px', flexWrap: 'wrap'}}>
                            <div className="buildingLogoStep">
                                <div className="upload-button buildingLogoStep">
                                    <svg className="buildingLogoStep" xmlns="http://www.w3.org/2000/svg" width="20"
                                         height="20" viewBox="0 0 20 20" fill="none">
                                        <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd"
                                              d="M9 3.40501L6.71 5.70501C6.45634 5.95867 6.08663 6.05774 5.74012 5.96489C5.39362 5.87205 5.12297 5.6014 5.03012 5.25489C4.93728 4.90839 5.03634 4.53867 5.29 4.28501L9.29 0.285014C9.3851 0.193973 9.49725 0.122608 9.62 0.0750135C9.86346 -0.0250045 10.1365 -0.0250045 10.38 0.0750135C10.5028 0.122608 10.6149 0.193973 10.71 0.285014L14.71 4.28501C14.8993 4.47278 15.0058 4.72838 15.0058 4.99501C15.0058 5.26165 14.8993 5.51725 14.71 5.70501C14.5222 5.89433 14.2666 6.00081 14 6.00081C13.7334 6.00081 13.4778 5.89433 13.29 5.70501L11 3.40501V12.995C11 13.5473 10.5523 13.995 10 13.995C9.44771 13.995 9 13.5473 9 12.995V3.40501ZM18 12.995C18 12.4427 18.4477 11.995 19 11.995C19.5523 11.995 20 12.4427 20 12.995V16.995C20 18.6519 18.6569 19.995 17 19.995H3C1.34315 19.995 0 18.6519 0 16.995V12.995C0 12.4427 0.447715 11.995 1 11.995C1.55228 11.995 2 12.4427 2 12.995V16.995C2 17.5473 2.44772 17.995 3 17.995H17C17.5523 17.995 18 17.5473 18 16.995V12.995Z"
                                              fill="black"/>
                                    </svg>
                                </div>
                            </div>
                        </label>

                        <input type="file"
                               accept="image/*"
                               className="buildingLogoStep"
                               onChange={(event: any) => {
                                   const images = [...values.images, ...event.currentTarget.files]
                                   setFieldValue('images', images)
                                   this.props.handleFieldChange('images', images)
                               }}
                               style={{display: 'none'}}
                               id="input-file-upload"
                               name="images"
                               data-testid="step2Images"
                               multiple
                        />
                    </>
                )}
            </>
        )
    }

    styleCardBuilding = (lease: any) => {
        return this.props.rentalSelected === lease.attributes.id ? {
            marginRight: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
            height: "127",
            width: "168",
            border: '2px solid #2b6fed',
        } : {
            marginRight: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
            height: "127",
            width: "168",
            border: '1px dashed #e4e4e4',
            boxShadow: '0px 6px 14px rgba(43,111,237,0.12)'
        }
    }

    styleTitleBuilding = (lease: any) => {
        return this.props.rentalSelected === lease.attributes.id ? {
            marginBottom: '10px',
            color: '#2B6FED',
            textAlign: 'center',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal'
        } : {
            marginBottom: '10px',
            color: '#181d25',
            textAlign: 'center',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal'
        }
    }

    accountManagerBox = (values: any, handleChange: any) => {
        const {t} = this.props;
        return (
            <>
            {
                                        this.props.accountType !== "coo" ? <Box className="formGroups customSelect customSelect_building" style={{ width: '49%', marginTop: '20px' }}>
                                        <Typography className="typographyLable bilding_step2 step2BuildingFormik">
                                            {t("Select Account Manager")}
                                            </Typography>
                                            <FormControl variant="outlined" className="bilding_step2 step2BuildingFormik">
                                            <Field as={Select} name="accountManager"
                                    id="country" className={`${values.accountManager !== '' ? 'estate_select_color_building_value' : 'estate_select_color_building'} estate_select countries_form_real_estate_grid_field step2BuildingFormik`}
                                    labelId="demo-simple-select-label"
                                    style={{backgroundColor:"#F0F0F0",
                                                   borderRadius:'8px'}}
                                                   data-testid="fieldChange"
                                    displayEmpty
                                    renderValue={(val:any) => val.name}
                                    onChange={(e: any) => {
                                      handleChange(e);
                                      this.props.handleFieldChange('accountManager',e.target.value);
                                    }}
                                  >
                                                <MenuItem disabled value="">{t("Select Account Manager")}</MenuItem>
                                                {
                                                    this.props?.accountManagerList?.length && this.props?.accountManagerList.map((manager:any, i:number) => {
                                                        return(<MenuItem key={i} value={manager} className="bilding_step2 step2BuildingFormik">
                                                            {manager.name}
                                                        </MenuItem>)
                                                    })
                                                }
                                                    </Field>
                                                <Box style={webStyle.errorMsg} className="step2BuildingFormik"><ErrorMessage name="accountManager" component="div" data-testid="accountManager-error" /></Box>
                                            </FormControl>
                                        </Box>
                                         : <></>
                                    }
            </>
        )
    }

    render() {
        // Customizable Area Start

        const {t} = this.props;

        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <Formik
                    data-testid="basicFormik"
                    initialValues={this.props.buildingValues}
                    validationSchema={this.validationSchema}
                    validateOnMount={true}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        this.props.handleBasicDetails(values)
                    }}
                >
                    {({values, touched, errors, isValid, setFieldValue, handleChange}) => (
                        <Form translate="yes" className="commonForms step2forms">
                            <Box className="step2forms">
                                <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                                     className="step2forms">
                                    <Box className="step2forms">
                                        <Typography className="step2forms bold-text"
                                                    style={{fontSize: '22px', fontWeight: 'bold'}}>{t("Basic Details")}</Typography>
                                    </Box>
                                    <Box className="step2forms">
                                        <Typography className="Step step2forms"
                                                    style={{fontSize: '16px'}}>{t("Step")} {this.props.stepBuilding + 1} {t("of 7")}</Typography>
                                    </Box>
                                </Box>
                                <Box className="step2forms" style={{
                                    marginTop: '28px', textAlign: 'center', display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    {this.logoDisplayBuilding(values)}
                                    <input type="file"
                                           accept="image/*"
                                           id="logoChange"
                                           className="newLogo"
                                           name="logo" data-testid="logoUpload" onChange={(event: any) => {
                                        setFieldValue('logo', event.currentTarget.files[0])
                                        this.props.handleFieldChange('logo', event.currentTarget.files[0])
                                    }}
                                           style={{display: 'none'}}/>
                                    <Box style={webStyle.errorMsg} className="newLogo"><ErrorMessage name="logo"
                                                                                                     component="div"
                                                                                                     data-testid="logo-error"/></Box>
                                </Box>
                                <Box style={{marginTop: '30px'}}>
                                    <Box className="boxDisplayFlex">
                                        <Box style={{width: '49%', marginRight: '10px'}}>
                                            <Typography className="typographyLable">{t("Building Name")}</Typography>
                                            <Box>
                                                <Box className="formGroups" style={{
                                                    marginBottom: "0px",
                                                    border: "1px solid #F0F0F0",
                                                    borderRadius: "8px",
                                                    backgroundColor: "#F0F0F0"
                                                }}>
                                                    <Field name="buildingName" type="text" placeholder={t("Building Name")}
                                                           onKeyDown={alphaNumericOnly}
                                                           className="formInputs"
                                                           data-testid="fieldChange"
                                                           onChange={
                                                               (e: any) => {
                                                                   handleChange(e)
                                                                   this.props.handleFieldChange('buildingName', e.target.value)
                                                               }
                                                           }/>
                                                    <span className="frmLeftIcons step2">
                                                        </span>
                                                </Box>
                                            </Box>
                                            <Box style={webStyle.errorMsg}><ErrorMessage name="buildingName"
                                                                                         component="div"
                                                                                         data-testid="buildingName-error"/></Box>
                                        </Box>
                                        <Box style={{width: '49%',}}>
                                            <Typography className="typographyLable Area">{t("Total Area")}</Typography>
                                            <Box className="formGroups" style={{
                                                backgroundColor: "#F0F0F0",
                                                border: "1px solid #F0F0F0",
                                                marginBottom: "0px",
                                                borderRadius: "8px",
                                            }}>
                                                <Field name="totalArea" className="newLogo">
                                                    {({field}: { field: any }) => (
                                                        <TextField
                                                            {...field}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Select style={{
                                                                            color: '#BEBEBE'
                                                                        }} className="newLogo"
                                                                                value={this.props.totalAreaUnit}
                                                                                onChange={this.props.handleTotalAreaUnit}>
                                                                            <MenuItem value="Sqft"
                                                                                      className="bilding_step2 step2Formik"
                                                                                      selected>
                                                                                Sqft
                                                                            </MenuItem>
                                                                            <MenuItem value="m2"
                                                                                      className="bilding_step2 step2Formik">
                                                                                m<sup>2</sup>
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                            onChange={(e: any) => {
                                                                handleChange(e);
                                                                this.props.handleFieldChange('totalArea', e.target.value);
                                                            }}
                                                            type="number"
                                                            placeholder={t("Total Area")}
                                                            className="formInputs step3 newLogo"
                                                            id="totalArea"
                                                        />
                                                    )}
                                                </Field>
                                                <span className="frmLeftIcons step2">
                                                    </span>
                                            </Box>
                                            <Box style={webStyle.errorMsg}><ErrorMessage name="totalArea"
                                                                                         component="div"
                                                                                         data-testid="totalArea-error"/></Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box style={{marginTop: '20px'}}>
                                    <Typography className="typographyLable">{t("Upload Photos")}</Typography>
                                    <Box style={{display: 'flex'}}>
                                        {this.photosDisplayBuilding(values, setFieldValue)}
                                        <div className="image-preview" style={{
                                            display: 'flex'
                                        }}>
                                            {values.images.map((image: any, index: number) => (
                                                <div style={{
                                                    position: 'relative'
                                                }}>
                                                    <img
                                                        style={{
                                                            height: '104px',
                                                            width: '172px',
                                                            marginRight: '20px',
                                                            objectFit: 'cover',
                                                            border: '1px solid #ececec'
                                                        }}
                                                        key={index}
                                                        src={URL.createObjectURL(image)}
                                                        alt={`Image ${index}`}
                                                    />
                                                    <HighlightOffIcon
                                                        className="faq_image_close_icon faq_image_close_icon2 step2forms"
                                                        style={{
                                                            right: '25px',
                                                            position: 'absolute',
                                                            fill: 'red',
                                                            top: '5px',
                                                            cursor: 'pointer'
                                                        }}
                                                        data-testid="step2ImageRemove"
                                                        onClick={() => {
                                                            const newImages = [...values.images];
                                                            newImages.splice(index, 1);
                                                            setFieldValue('images', newImages);
                                                            this.props.handleFieldChange('images', newImages)
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </Box>
                                    <Box style={webStyle.errorMsg}><ErrorMessage name="images" component="div"
                                                                                 data-testid="images-error"/></Box>
                                </Box>
                                <Box style={{marginTop: '30px',}}>
                                    <Box className="boxDisplayFlex">
                                        <Box style={{marginRight: '10px', width: '49%',}}>
                                            <Typography className="typographyLable Floors">{t("Total Floors")}</Typography>
                                            <Box>
                                                <Box className="formGroups" style={{
                                                    backgroundColor: "#F0F0F0",
                                                    marginBottom: "0px",
                                                    borderRadius: "8px",
                                                    border: "1px solid #F0F0F0",
                                                }}>
                                                    <Field className="formInputs" type="number"
                                                           placeholder={t("Total Floors")} name="totalFloors"
                                                           data-testid="fieldChange"
                                                           onChange={
                                                               (e: any) => {
                                                                   handleChange(e)
                                                                   this.props.handleFieldChange('totalFloors', e.target.value)
                                                               }
                                                           }/>
                                                    <span className="frmLeftIcons">
                                                        </span>
                                                </Box>
                                                <Box style={webStyle.errorMsg}><ErrorMessage name="totalFloors"
                                                                                             component="div"
                                                                                             data-testid="totalFloors-error"/></Box>
                                            </Box>
                                        </Box>
                                        <Box style={{width: '49%'}}>
                                            <Typography className="typographyLable Units">{t("Total Units")}</Typography>
                                            <Box className="formGroups" style={{
                                                border: "1px solid #F0F0F0",
                                                marginBottom: "0px",
                                                borderRadius: "8px",
                                                backgroundColor: "#F0F0F0"
                                            }}>
                                                <Field onKeyDown={numbersOnly} name="totalUnits"
                                                       placeholder="Total Units" className="formInputs"
                                                       data-testid="fieldChange"
                                                       type="text" onChange={
                                                    (e: any) => {
                                                        handleChange(e)
                                                        this.props.handleFieldChange('totalUnits', e.target.value)
                                                    }
                                                }/>
                                                <span className="frmLeftIcons step2">
                                                    </span>
                                            </Box>
                                            <Box style={webStyle.errorMsg}><ErrorMessage name="totalUnits"
                                                                                         component="div"
                                                                                         data-testid="totalUnits-error"/></Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box style={{marginTop: '20px',}}>
                                    <Box style={{width: '100%'}}>
                                        <Typography className="typographyLable About">{t("About Us")}</Typography>
                                        <Box className="formGroups step2" style={{
                                            marginBottom: "0px",
                                            border: "1px solid #F0F0F0",
                                            borderRadius: "8px",
                                            backgroundColor: "#F0F0F0"
                                        }}>
                                            <Field placeholder={t("About Us")} name="aboutUs" type="address"
                                                   className="formInputs1" as="textarea"
                                                   data-testid="fieldChange"
                                                   onChange={
                                                       (e: any) => {
                                                           handleChange(e)
                                                           this.props.handleFieldChange('aboutUs', e.target.value)
                                                       }
                                                   }/>
                                            <span className="frmLeftIcons step2">
                                                </span>
                                        </Box>
                                        <Box style={webStyle.errorMsg}><ErrorMessage name="aboutUs" component="div"
                                                                                     data-testid="aboutUs-error"/></Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography style={{fontSize: '20px', fontWeight: 'bold', marginTop: '30px'}}
                                                className="bold-text">{t("Define Jurisdiction")}</Typography>
                                </Box>
                                <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Box className="formGroups customSelect customSelect_building bilding_step2"
                                         style={{marginRight: '25px', width: '50%', marginTop: '20px'}}>
                                        <Typography className="typographyLable bilding_step2">
                                            {t("Currency")}
                                        </Typography>
                                        <FormControl variant="outlined" className="bilding_step2">
                                            <Field as={Select} name="currency"
                                                   id="country"
                                                   className={`${values.currency !== '' ? 'estate_select_color_building_value' : 'estate_select_color_building'} estate_select countries_form_real_estate_grid_field`}
                                                   labelId="demo-simple-select-label"
                                                   style={{
                                                       backgroundColor: "#F0F0F0",
                                                       borderRadius: '8px'
                                                   }}
                                                   data-testid="selectCurrency"
                                                   displayEmpty
                                                   onChange={(e: any) => {
                                                       handleChange(e);
                                                       this.props.handleFieldChange('currency', e.target.value)
                                                   }}
                                                   disabled
                                            >

                                                <MenuItem disabled value="">{t("Select Currency")}</MenuItem>
                                                <MenuItem value={values.currency} className="bilding_step2">
                                                    {values.currency}
                                                </MenuItem>
                                            </Field>
                                            <Box style={webStyle.errorMsg}><ErrorMessage name="currency" component="div"
                                                                                         data-testid="currency-error"/></Box>
                                        </FormControl>
                                    </Box>
                                    <Box className="formGroups customSelect customSelect_building"
                                         style={{marginTop: '20px', width: '49%',}}>
                                        <Typography className="typographyLable">
                                            {t("Timezone")}
                                        </Typography>
                                        <FormControl variant="outlined">
                                            <Field as={Select} name="timezone"
                                                   id="country"
                                                   className={`${values.timezone !== '' ? 'estate_select_color_building_value' : 'estate_select_color_building'} estate_select countries_form_real_estate_grid_field`}
                                                   labelId="demo-simple-select-label"
                                                   style={{
                                                       backgroundColor: "#F0F0F0",
                                                       borderRadius: '8px'
                                                   }}
                                                   displayEmpty
                                                   data-testid="selectTimezone"
                                                   onChange={(e: any) => {
                                                       handleChange(e);
                                                       this.props.handleFieldChange('timezone', e.target.value)
                                                   }}
                                                   disabled
                                            >
                                                <MenuItem disabled value="">{t("Select Timezone")}</MenuItem>
                                                <MenuItem className="bilding_step2" value={values.timezone}>
                                                    {values.timezone}
                                                </MenuItem>
                                            </Field>
                                            <Box style={webStyle.errorMsg}><ErrorMessage name="timezone" component="div"
                                                                                         data-testid="timezone-error"/></Box>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box style={{justifyContent: 'space-between', display: 'flex',}}>
                                    <Box className="formGroups customSelect customSelect_building"
                                         style={{marginTop: '20px', width: '49%',}}>
                                        <Typography className="typographyLable Measurement">
                                            {t("Unit of Measurement")}
                                        </Typography>
                                        <FormControl variant="outlined">
                                            <Field as={Select} name="measurement"
                                                   id="measurement"
                                                   className={`${values.measurement !== '' ? 'estate_select_color_building_value' : 'estate_select_color_building'} estate_select countries_form_real_estate_grid_field`}
                                                   labelId="demo-simple-select-label"
                                                   style={{
                                                       backgroundColor: "#F0F0F0",
                                                       borderRadius: '8px'
                                                   }}
                                                   displayEmpty
                                                   data-testid="selectList"
                                                   onChange={(e: any) => {
                                                       handleChange(e);
                                                       this.props.handleFieldChange('measurement', e.target.value)
                                                   }}
                                                   disabled
                                            >
                                                <MenuItem disabled value="">{t("Select Measurement")}</MenuItem>
                                                <MenuItem className="bilding_step2" value={values.measurement}>
                                                    {values.measurement}
                                                </MenuItem>
                                            </Field>
                                            <Box style={webStyle.errorMsg}><ErrorMessage name="measurement"
                                                                                         component="div"
                                                                                         data-testid="measurement-error"/></Box>
                                        </FormControl>
                                    </Box>
                                    <Box className="formGroups customSelect customSelect_building"
                                         style={{marginTop: '20px', width: '49%',}}>
                                        <Typography className="typographyLable Language">
                                            {t("Main Language")}
                                        </Typography>
                                        <FormControl variant="outlined">
                                            <Field as={Select} name="language"
                                                   id="language"
                                                   className={`${values.language !== '' ? 'estate_select_color_building_value' : 'estate_select_color_building'} estate_select countries_form_real_estate_grid_field`}
                                                   labelId="demo-simple-select-label"
                                                   style={{
                                                       backgroundColor: "#F0F0F0",
                                                       borderRadius: '8px'
                                                   }}
                                                   displayEmpty
                                                   data-testid="selectList"
                                                   onChange={(e: any) => {
                                                       handleChange(e);
                                                       this.props.handleFieldChange('language', e.target.value)
                                                       //   this.props.(e);
                                                   }}
                                            >
                                                <MenuItem disabled value="">{t("Select Language")}</MenuItem>
                                                <MenuItem value="en">English</MenuItem>
                                                <MenuItem value="ar">Arabic</MenuItem>
                                            </Field>
                                            <Box style={webStyle.errorMsg}><ErrorMessage name="language" component="div"
                                                                                         data-testid="language-error"/></Box>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Box className="formGroups customSelect customSelect_building"
                                         style={{width: '49%', marginTop: '20px'}}>
                                        <Typography className="typographyLable Formula">
                                            {t("Management Fee Calculation Formula")}
                                        </Typography>
                                        <FormControl variant="outlined">
                                            <Field as={Select} name="formula"
                                                   id="language"
                                                   className={`${values.formula !== '' ? 'estate_select_color_building_value' : 'estate_select_color_building'} estate_select countries_form_real_estate_grid_field`}
                                                   labelId="demo-simple-select-label"
                                                   style={{
                                                       backgroundColor: "#F0F0F0",
                                                       borderRadius: '8px'
                                                   }}
                                                   displayEmpty
                                                   data-testid="selectFormula"
                                                   onChange={(e: any) => {
                                                       handleChange(e);
                                                       this.props.handleFieldChange('formula', e.target.value)
                                                   }}
                                                   disabled
                                            >
                                                <MenuItem value="" disabled>{t("Select Formula")}</MenuItem>
                                                <MenuItem className="bilding_step2" value={values.formula}>
                                                    {values.formula}
                                                </MenuItem>
                                            </Field>
                                            <Box style={webStyle.errorMsg}><ErrorMessage name="formula" component="div"
                                                                                         data-testid="formula-error"/></Box>
                                        </FormControl>
                                    </Box>

                                    {this.props.accountType === "coo" && this.accountManagerBox(values, handleChange)}
                                </Box>

                                <Box style={{marginTop: '20px',}}>
                                    <Box style={{width: '100%',}}>
                                        <Typography className="typographyLable Contract">Default Renting
                                            Contract</Typography>
                                        <Box style={{display: 'flex', flexWrap: 'wrap'}}>
                                            {
                                                this.props.leaseList.length && this.props.leaseList.map((lease: any, i: number) => {
                                                    return (
                                                        <Box key={i} style={{
                                                            cursor: 'pointer'
                                                        }} data-testid="leaseOpen"
                                                             onClick={() => this.props.handleOpenLeasePopup(i)}>
                                                            <Box
                                                                //@ts-ignore
                                                                style={this.styleCardBuilding(lease)}
                                                            >
                                                                <Box style={{
                                                                    marginBottom: '10px',
                                                                    border: '5px solid #f7f9fe',
                                                                    padding: "20px",
                                                                    width: 'max-content',
                                                                    borderRadius: '100%'
                                                                }}>
                                                                    <img src={this.props.desigenImg}/>
                                                                </Box>
                                                                <Box
                                                                    //@ts-ignore
                                                                    style={this.styleTitleBuilding(lease)}>
                                                                    {lease.attributes?.title}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{display: 'flex', justifyContent: "end", flexWrap: 'wrap'}}><Grid
                                style={{marginTop: "30px", marginRight: '20px'}}>
                                <Box style={{}}>
                                    <Button style={{
                                        color: "#2B6FED",
                                        fontWeight: 'bold',
                                        padding: '0px 20.5px',
                                        textTransform: 'capitalize',
                                        width: '177px',
                                        height: '56px',
                                        border: '1px solid #2B6FED',
                                        borderRadius: "8px",
                                        display: 'flex',
                                        justifyContent: 'center',
                                        textAlign: 'center'
                                    }} onClick={this.props.handleBackBuilding}><span
                                        className="bold-text">{t("PREV")}</span></Button>
                                </Box>
                            </Grid>
                                <Box><Grid style={{marginTop: "30px",}}>
                                    <Button style={{
                                        width: '177px',
                                        height: '56px',
                                        backgroundColor: '#2B6FED',
                                        borderRadius: "8px",
                                        display: 'flex',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        color: "#FFFFFF",
                                        fontWeight: 'bold',
                                        padding: '0px 20.5px',
                                        textTransform: 'capitalize'
                                    }} data-test-id="handleNextComplex" type="submit"><span
                                        className="bold-text">{t("NEXT")}</span></Button>
                                </Grid>
                                </Box>
                            </Box>
                        </Form>)}
                </Formik>
                <Box>
                    <Box style={{
                        zIndex: 9999,
                        display: this.props.leasePopup ? 'block' : 'none',
                        position: 'fixed',
                        top: 0,
                        width: '100%',
                        left: 0,
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    }}>
                        <Box style={{
                            width: '50%',
                            backgroundColor: '#fff',
                            top: '50%',
                            borderRadius: '5px',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            height: '90%',
                            overflow: 'auto',
                            position: 'absolute',
                        }}>
                            <Box>
                                <Box className="topBarName">
                                    <Box className="bold-text" style={{fontSize: '22px', fontWeight: 400}}>Lease
                                        Template Preview</Box>
                                    <Box onClick={this.props.handleCloseLeasePopup} style={{cursor: "grabbing"}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14"
                                             viewBox="0 0 14 14" fill="none">
                                            <path
                                                d="M7.8171 7.00033L13.8155 1.00194C14.047 0.778352 14.0534 0.409435 13.8298 0.177923C13.6062 -0.0535893 13.2373 -0.0600088 13.0058 0.163581C13.001 0.16826 12.9962 0.17304 12.9915 0.177923L6.99304 6.17631L0.994657 0.177889C0.763145 -0.0457015 0.394228 -0.0392819 0.170638 0.19223C-0.047489 0.418074 -0.047489 0.776098 0.170638 1.00194L6.16902 7.00033L0.170638 12.9987C-0.0568793 13.2263 -0.0568793 13.5952 0.170638 13.8227C0.398189 14.0502 0.767106 14.0502 0.994657 13.8227L6.99304 7.82435L12.9914 13.8227C13.2229 14.0463 13.5919 14.0399 13.8154 13.8084C14.0335 13.5825 14.0335 13.2245 13.8154 12.9987L7.8171 7.00033Z"
                                                fill="black"/>
                                        </svg>
                                    </Box>
                                </Box>
                                <Box style={{borderBottom: '1px solid #e7e7e7'}}></Box>
                                <Box style={{padding: '25px'}}>
                                    <Box style={{padding: '30px', border: '1px solid #dcdcdc', borderRadius: '4px'}}>
                                        <div
                                            dangerouslySetInnerHTML={{__html: this.props.leaseList[this.props.leaseSelected]?.attributes?.template}}
                                        />
                                    </Box>
                                    <Box style={{display: 'flex', marginTop: '15px', justifyContent: 'end'}}>
                                        <Grid style={{marginTop: "30px", marginRight: '20px'}}>
                                            <Button onClick={this.props.handleCloseLeasePopup} className="buttonCancel"><span
                                                className="bold-text">CANCEL</span></Button>
                                        </Grid>
                                        <Grid style={{marginTop: "30px",}}>
                                            <Button data-testid="selectLease" className="buttonSave"
                                                    type="submit"
                                                    onClick={() => this.props.handleSelectLease(this.props.leaseList[this.props.leaseSelected]?.attributes?.id)}
                                            ><span className="bold-text">SELECT</span></Button>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// export default withTranslation()(withRouter(CompEmpComplexBuildingBlock));
// Customizable Area Start
const webStyle = {
    errorMsg: {
        color: 'red',
        fontSize: '12px',
        marginTop: '5px'
    },
}
// Customizable Area End