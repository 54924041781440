//Customizable Area Start
import React from 'react'
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { RouteComponentProps } from 'react-router-dom';
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";

export interface Props extends RouteComponentProps {
  navigation: any;
  id: string;
  classes?: any;
}

interface S {
  companies:any,
  companiesList:any,
  countriesList:any,
  cityList:any,
  regionList:any,
  statusList:any,
  accountManagerList:any,
  salesManagerList:any,
  page:number,
  companyFilter:string,
  countryFilter:string,
  cityFilter:string,
  regionFilter:string,
  statusFilter:string,
  accountManagerFilter:any,
  salesManagerFilter:any,
  openOptions:number,
  pagination:any,
  searchQuery:string,
  loading:boolean,
  accountType: string,
  anchorEl: any,
  activeId: number,
}

interface SS {
  id: any;
}

export default class RealEstateCompaniesTableController extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetCompaniesList: any;
  GetCompaniesFilterList: any;
  GetCountriesFilterList: any;
  GetRegionsFilterList: any;
  GetCityFilterList:any;
  GetStatusFilterList:any;
  GetFilterApi:any;
  GetSearchApi:any;
  GetAccountManagerFilterList:any;
  GetSalesManagerFilterList:any;
  timeout:any;
  menuRef:any;
  dotRef:any;
  
  constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
      this.handleFilterChange = this.handleFilterChange.bind(this)
      this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.SessionResponseMessage),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionSaveMessage),
      ];
      this.state = {
        loading:false,
        page:1,
        companies:[],
        companiesList:[],
        countriesList:[],
        cityList:[],
        regionList:[],
        statusList:[],
        accountManagerList:[],
        salesManagerList:[],
        anchorEl: null,
        companyFilter:'',
        countryFilter:'',
        cityFilter:'',
        regionFilter:'',
        statusFilter:'',
        accountManagerFilter:'',
        salesManagerFilter:'',
        openOptions: 0,
        activeId: 0,
        accountType:'',
        searchQuery: '',
        pagination:{
          current_page:1,
          total_count:1,
          total_pages:1,
        }
      };
      this.timeout = null;
      this.menuRef = React.createRef();
      this.dotRef = React.createRef()
      runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getCompaniesList()

    let filterCallTable:any = [
      {
        name: 'GetCompaniesFilterList',
        endPoint: 'all_company_name'
      },
      {
        name: 'GetCountriesFilterList',
        endPoint: 'list_country_name',
      },
      {
        name: 'GetCityFilterList',
        endPoint: 'list_cities',
      },
      {
        name: 'GetRegionsFilterList',
        endPoint: 'list_region',
      },
      {
        name: 'GetStatusFilterList',
        endPoint: 'list_status',
      }
    ]

    const accountRole = localStorage.getItem("role")

    if (accountRole) {
      const accountRoleName = accountRole.replace(/"/g, '')
      this.setState({ accountType: accountRoleName })

      if (accountRoleName==='coo') {
        filterCallTable = [...filterCallTable, 
          {
            name: 'GetAccountManagerFilterList',
            endPoint: 'account_manager_list',
          },
          {
            name: 'GetSalesManagerFilterList',
            endPoint: 'sales_manager_list',
          }
        ]
      }
    }

    filterCallTable.forEach((filter:any) => {
      this.getFilterListTable(filter.name, `/bx_block_real_estate_companies/${filter.endPoint}`)
    })
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetCompaniesList:
          this.handleCompaniesList(responseJson);
          break;
        case this.GetCompaniesFilterList:
          this.handleFiltersList(responseJson, 'companiesList', 'company_list')
          break;
        case this.GetCountriesFilterList:
          this.handleFiltersList(responseJson, 'countriesList', 'country_list')
          break;
        case this.GetCityFilterList:
          this.handleFiltersList(responseJson, 'cityList', 'city_list')
          break;
        case this.GetRegionsFilterList:
          this.handleFiltersList(responseJson, 'regionList', 'region_list')
          break;
        case this.GetStatusFilterList:
          this.handleFiltersList(responseJson, 'statusList', 'status')
          break;
        case this.GetAccountManagerFilterList:
          this.handleFiltersList(responseJson, 'accountManagerList', 'account_managers')
          break;
        case this.GetSalesManagerFilterList:
          this.handleFiltersList(responseJson, 'salesManagerList', 'sales_managers')
          break;
        case this.GetFilterApi:
          this.handleCompaniesFilterSearch(responseJson)
          break;
        case this.GetSearchApi:
          this.handleCompaniesSearch(responseJson)
          break;
        default:
          break;
      }
      this.setState({loading:false})

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  handleVistorPagination = (e:any,value:any) => {
    this.setState({
      page:value
    })
  }

  handleFilterChange = (event:any, stateParam: string) => {
    if (typeof event.target.value === "string") {
      if (stateParam==="countryFilter") {
        this.setState({ ...this.state, countryFilter : event.target.value.trim(), cityFilter:'', regionFilter:''})
      } else {
        this.setState({ ...this.state, [stateParam]: event.target.value.trim()})
      }
    } else {
      this.setState({ ...this.state, [stateParam]: event.target.value})
    }
  }

  handleFilterSubmit = (event:any) => {
    event.preventDefault()
    this.getCompaniesSearchFilter()
  }

  handlePopover = (event:any, activeId: number) => {
    this.setState({ anchorEl: event?.currentTarget, activeId });
  }

  handleClosePopover = () => {
    this.setState({ anchorEl: null });
  };

  handleSearchKeyUp = (e:any) => {
    const searchQuery = e.target.value;

      this.setState({ searchQuery }, () => {
        this.getSearchResults();
      });
  };

  handleCompaniesSearch = (responseJson:any) => {
    if (responseJson.real_estate_companies.data) {
      this.setState({ companies: responseJson.real_estate_companies.data,
        pagination:{
          current_page:responseJson.meta.pagination?.current_page ? responseJson.meta.pagination?.current_page : 1,
          total_count:responseJson.meta.pagination?.total_count ? responseJson.meta.pagination?.total_count : 1 ,
          total_pages:responseJson.meta.pagination?.total_pages ? responseJson.meta.pagination?.total_pages : 1,
        },
      })
    }
  }

  handleCompaniesList = (responseJson: any) => {
    if (responseJson.real_estate_companies.data) {
      this.setState({ companies: responseJson.real_estate_companies.data,
        pagination:{
          current_page:responseJson.meta.pagination.current_page,
          total_count:responseJson.meta.pagination.total_count,
          total_pages:responseJson.meta.pagination.total_pages,
        },
      })
    }
  };

  handleCompaniesFilterSearch = (responseJson: any) => {
    if (responseJson.real_estate_companies.data) {
      this.setState({ companies: responseJson.real_estate_companies.data,
        pagination:{
          current_page:responseJson.meta.pagination?.current_page,
          total_count:responseJson.meta.pagination?.total_count,
          total_pages:responseJson.meta.pagination?.total_pages,
        },
      })
    }
  };

  handleSearch = (e:any) => {
    const searchQuery = e.target.value;

    this.setState({ searchQuery }, () => {
      this.getSearchResults();    
    });
  };

  handleFiltersList = (responseJson:any, stateParam: string, resParam: string) => {
    if (responseJson[resParam]) {
      this.setState({ ...this.state, [stateParam]: responseJson[resParam] })
    }
  }

  getSearchResults = async () => {
    this.setState({loading: true})

    let url = `/bx_block_real_estate_companies/search?query=${this.state.searchQuery}
    &page=${this.state.page}&per_page=10`

    if (/^\d+$/.test(this.state.searchQuery)) {
      url = `/bx_block_real_estate_companies/search?id=${this.state.searchQuery}
      &page=${this.state.page}&per_page=10`
    }

    this.GetSearchApi = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: url,
    });
  }

  getCompaniesSearchFilter = async () => {
    this.setState({loading: true})

    const companyFilter = this.state.companyFilter.trim();
    const countryFilter = this.state.countryFilter.trim();
    const cityFilter = this.state.cityFilter.trim();
    const regionFilter = this.state.regionFilter.trim();
    const statusFilter = this.state.statusFilter.trim();
    const salesManagerFilter = this.state.salesManagerFilter
    const accountManagerFilter = this.state.accountManagerFilter

    let url = `bx_block_real_estate_companies/filter?company_name=${companyFilter === "all" ? "" : companyFilter}&country=${countryFilter === "all" ? "" : countryFilter}&city=${cityFilter}&region=${regionFilter}&status=${statusFilter === "all" ? "" : statusFilter}&sales_manager_id=${salesManagerFilter === "all" ? "" : salesManagerFilter}&account_manager_id=${accountManagerFilter === "all" ? "" : accountManagerFilter}`

    this.GetFilterApi = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: url,
    });
  };

  getCompaniesList = async () => {
    this.setState({loading: true})
    this.GetCompaniesList = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_real_estate_companies/real_estate_companies?page=${this.state.page}&per_page=10`,
    });
  };

  getFilterListTable = async (method: string, endPoint: string) => {
    this.setState({loading: true})
    //@ts-ignore
    this[method] = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint,
    });
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.page !== this.state.page) {
      await this.getCompaniesList();
    }
  }
}
// Customizable Area End

