//Customizable Area Start
import React from "react";
import ComplexBuildingMainBlockController, {
    Props,
} from "./ComplexBuildingMainBlockController.web";
import '../../../dashboard/src/Company Employee/global.css';
import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";
import CompEmpComplexBuildingBlock from "./CompEmpComplexBuildingBlock.web"

export default class ComplexBuildingMainBlock extends ComplexBuildingMainBlockController {
    constructor(props: Props) {
        super(props);
    }
    render() {

        if (localStorage.loginSuccessToken) {
            return (
                <>
                    <MainScreen>
                        <CompEmpComplexBuildingBlock navigation={undefined} id={""} location={undefined} history={undefined} />
                    </MainScreen>
                </>
            );
        }
        else {
            window.location.href = '/CompanyEmployee/Login'
            return null
        }

    }
}

const webStyle = {
};
//Customizable Area End


