// Customizable Area Start
export const imgAdd = require("../assets/ic_add.png");
export const imgCancel = require("../assets/ic_cancel.png");
export const imgGstin = require("../assets/ic_gstin.png");
export const imgMapIcon = require("../assets/ic_map_icon.png");
export const imgSuccessModal = require("../assets/success_modal_image.png");
export const backIcon = require("../assets/backIcon.png");
export const Building1 = require("../assets/building1.png");
export const ListCopy = require("../assets/List Copy.png");
export const Car = require("../assets/Car Copy.png");
export const user = require("../assets/user.svg");
export const palette = require("../assets/palette.png");
export const upload = require("../assets/Upload.png");
export const NoVehicles = require("../assets/NoVehicles.png");
export const edit = require("../assets/edit.png");
export const deleteIcon = require("../assets/delete.png");
export const deleteI = require("../assets/deleteIcon.png");
export const info = require("../assets/info.png");
export const userBlue = require("../assets/IconlyLightProfile.png");
export const CarBlue = require("../assets/CarBlue.png");
export const List = require("../assets/List.png");
export const paletteBlue = require("../assets/paletteBlue.png");
export const Rc = require("../assets/Registration-Certificate.png");
export const CarFront = require("../assets/carFront.png");
export const Tick = require("../assets/tick.png");
export const Building = require("../assets/building.png");
export const Hash = require("../assets/Hash.png");
export const Hyperlink = require("../assets/Hyperlink.png");
export const User3 = require("../assets/User 3.png");
export const Search = require("../assets/search.png");
export const NoChat = require("../assets/noChat.png");
export const Send = require("../assets/send.png");
export const DoubleTick = require("../assets/doubleTick.png");
export const Sendbutton = require("../assets/Submit_with_Round.png");
export const buildingImg = require("../assets/buildingImg.png");
export const CarLogo = require("../assets/carLogo.png");
export const EditIcon = require("../assets/edit-icon.png");
export const visitorIcon = require("../assets/visitor.png");
export const unitIcon = require("../assets/unit.png");
export const teamIcon = require("../assets/team.png");
export const complexIcon = require("../assets/complex.png");
export const faqIcon = require("../assets/faq.png");
export const chatIcon = require("../assets/chat.png");
export const reportIcon = require("../assets/report.png");
export const CrossIcon = require("../assets/x-mark.png");
export const CheckIcon = require("../assets/check.png");
export const DeleteIcon = require("../assets/delete-icon.png");
export const userIcon = require("../assets/user.png");
export const emailIcon = require("../assets/email-icon.png");
export const phoneIcon = require("../assets/phone-icon.png");
export const buildingIcon = require("../assets/building-icon.png");
export const unitsIcon = require("../assets/unit-icon.png");
export const ceRealEstateIcon = require("../assets/real-estate.png");
export const ceBuildingIcon = require("../assets/ce-building.png");
// Customizable Area End