// Customizable Area Start
import React from "react";
import {
  Container,
  Typography,
  withStyles,
  TextField,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  Link,
  IconButton,
  MenuItem,
  Select,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import CEActionsProgressController, { Props } from "./CEActionsProgressController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { CEActionsMeStyleWeb } from "./CEActionsMe.web";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import { LanguageAttributeHandler } from "../../../../components/src/UtilFunction.web";
import { Menu } from "@szhsin/react-menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Customizable Area End

class CEActionsProgress extends CEActionsProgressController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />
        <CEHeaderComponentWeb>
          <Container className={classes.generalDashboard}>
            <Box className="navigation action-header-box">
              <Typography variant="body1">
                <Link href="/CompanyEmployee/dashboard">{t("My Dashboards")}</Link> /{" "}
                <Link href="/CompanyEmployee/ActionAssignTeam">{t("Action assigned to my team")}</Link> /{" "}
                <Box component="span" style={{ color: "blue" }}>
                  {t("View Actions Progress")}
                </Box>
              </Typography>
              <Box className="sub-heading-box">
                <Typography variant="h5" className="bold-text">
                  {t("View Actions Progress")}
                </Typography>
                <Box className="select-box filter-head-box">
                  <Select
                    value={this.state.year}
                    IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                    onChange={(e: any) => this.setState({ year: e.target.value })}
                    className="select-year"
                    displayEmpty
                  >
                    <MenuItem value="">{t("Year")}</MenuItem>
                    {this.state.yearList.map((year: any) => {
                      return (
                        <MenuItem value={year} key={year}>
                          {year}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              </Box>
            </Box>
            <Box className="content-boxes action-progress-table">
              <Box className="top-content dashboard-ticket-table-head">
                <Box className="heading dashboard-ticket-heading">
                  <h2 className="bold-text">{t("View Actions Progress")}</h2>
                </Box>
                <Box className="right-content dashboard-ticket-right-content-box">
                  <TextField
                    placeholder={t("Search by Name")}
                    onChange={(e: any) => this.setState({ search: e.target.value })}
                    value={this.state.search}
                    className="search-unit dashboard-ticket-search-resident"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
              <Divider />
              <TableContainer>
                <Table className="unit-table table-box">
                  <TableHead>
                    <TableRow>
                      <TableCell align={languageCondition(language, "right", "left")}>{t("#")}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>{t("Account Manager")}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {t("Assigned Actions")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>
                        {t("Completed Actions")}
                      </TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>{t("Pending Actions")}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>{t("Delayed Actions")}</TableCell>
                      <TableCell align={languageCondition(language, "right", "left")}>{t("")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.actionProgressList.map((user: any, index: any) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align={languageCondition(language, "right", "left")}>{index + 1}</TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>{user.full_name}</TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {user.Assigned_action}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {user.Completed_action}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {user.pending_action}
                          </TableCell>
                          <TableCell align={languageCondition(language, "right", "left")}>
                            {user.delayed_action}
                          </TableCell>
                          <TableCell
                            align={languageCondition(language, "right", "left")}
                            className={LanguageAttributeHandler(language, "action-view-menu", "")}
                          >
                            <Menu
                              menuButton={
                                <IconButton>
                                  <MoreVertIcon />
                                </IconButton>
                              }
                            >
                              <MenuItem>
                                <Link href={`/CompanyEmployee/ActionAssignTeam/Progress/${user.user_id}`}>
                                  {t("View Details")}
                                </Link>
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Container>
        </CEHeaderComponentWeb>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// @ts-ignore
export default withTranslation()(withStyles(CEActionsMeStyleWeb)(withRouter(CEActionsProgress)));
// Customizable Area End
