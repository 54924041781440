import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { t } from "i18next";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  isRejectDialogOpen: boolean;
  isAcceptDialogOpen:boolean;
  pendingJoinRequestList:any;
  filter:any;
  complexBuildingFilterList:any[];
  countryList:any[];
  complexList:any[];
  unitList:any[];
  buildingsList:any[];
  cityList:any[];
  userList:any[];
  pendingJoinRequest:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CEPendingJoinRequestController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getPendingJoinRequestList:any;
  getInvitationResponse:any;
  GetCountryList:any;
  GetComplexListId:any;
  GetCityList:any;
  GetAllUnitDataCallId:any;
  GetAllBuildingsDataCallId:any;
  showAllUserAPICallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.state = {
      loading: false,
      isRejectDialogOpen: false,
      isAcceptDialogOpen:false,
      pendingJoinRequestList:[],
      filter:"",
      countryList:[],
      complexList:[],
      unitList:[],
      buildingsList:[],
      cityList:[],
      userList:[],
      pendingJoinRequest:{
        id:"",
        name:"",
        unti:""
      },
      complexBuildingFilterList: [
        {
          label: t("Complex"),
          value: 'complex'
        },
        {
          label: t("Indiviual Building"),
          value: 'indiviualBuilding'
        },
      ],
    };

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      switch (apiRequestCallId) {
        case this.getPendingJoinRequestList:
          this.handlegetPendingJoinRequestList(responseJson);
          break;
        case this.showAllUserAPICallId:
          this.handleFiltersList(responseJson)
          break;
        case this.getInvitationResponse:
          this.handlegetPendingJoinStatus(responseJson);
          break;
        case this.GetCountryList:
          this.handleCountryResponse(responseJson);
          break;
        case this.GetCityList:
          this.handleCityResponse(responseJson)
          break;
        case this.GetComplexListId:
          this.handleComplexList(responseJson);
          break;
        case this.GetAllUnitDataCallId:
            this.handleUnitList(responseJson)
            break;
        case this.GetAllBuildingsDataCallId:
            this.handleBuildingList(responseJson)
            break;
        default:
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getPandingList();
    this.getAllUser();
    this.getCountryListTable();
    this.getComplexList();
  }
  getAllUser = async () => {
    this.showAllUserAPICallId = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getUserTypeEndPoint
    });
  };
  handleFiltersList = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({  userList: responseJson.data.map((item: any) => 
        ({ label: item.attributes.title, value: item.id }))})
    }
  }
  handleComplexList=(responseJson:any)=>{
    if (responseJson && responseJson.data) {
      this.setState({ complexList: responseJson.data.map((item: any) =>
         ({ label: item.attributes.name, value: item.id }))}
      )
    }
  }
  handleCountryResponse = (responseJson: any) => {
    if (responseJson && responseJson.country_list) {
      this.setState({ countryList: responseJson.country_list.map((item: any) => 
        ({ label: item, value: item })) });
    }
  };
  handleCityResponse = (responseJson: any) => {
    if (responseJson && responseJson.region_city_list) {
      this.setState({ cityList: responseJson.region_city_list.map((item: any) => 
        ({ label: item.city, value: item.city })) });
    }
  };
  handleUnitList=(responseJson:any)=>{
    if (responseJson && responseJson.data.apartments) {
      this.setState({ unitList: responseJson.data.apartments.map((item: any) => ({
        label: item.apartment_name,
        value: {
          id: item?.id,
          society_management_id: item?.society_management_id,
          building_management_id: item?.building_management_id
        }
      })
      )})
    }
  }
  handleBuildingList=(responseJson:any)=>{
    if (responseJson && responseJson.buildings) {
      this.setState({ buildingsList: responseJson.buildings.map((item: any) => ({ label: item.name, value: item.id }))}
      )
    }
  }
  getComplexList = async ( ) => {
    this.GetComplexListId = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getComplexListEndPoint
    });
  };
  getCountryListTable = async () => {
    this.setState({ loading: true })
    this.GetCountryList = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.createMemberCountryListEndPoint,
    });
  }
  getPandingFilterList = async () => {
    this.setState({ loading: true })
    this.getPendingJoinRequestList = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getPendingJoinRequestListEndPoint + `?role=${this.state.filter.selectedType}&country=${this.state.filter.selectedCountry}&city=${this.state.filter.selectCity}&complex_id=${this.state.filter.selectedComplex}&building_id=${this.state.filter.selectedBuilding}&apartment_management_id=${this.state.filter.selectedBuilding}`,
    })
  }
  getPandingList = async () => {
    this.setState({ loading: true })
    this.getPendingJoinRequestList = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: configJSON.getPendingJoinRequestListEndPoint,
    });
  }
  getCityList = async (country: any) => {
    this.GetCityList = await apiCall({
      contentType:configJSON.sellerDetailsApiContentType,
      endPoint:configJSON.getCityListEndPoint+`?country=${country}`,
      method: configJSON.getSellersAPIMethod,
    })
  }
  getUnitDataList = async (id:any) => {
    this.GetAllUnitDataCallId= await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.getSellersAPIMethod,
      endPoint: `bx_block_custom_form/incident_managements/unit_list?building_management_id=${id}`,
    });
  };
  getBuildingsDataList = async (id:any) => {
    this.GetAllBuildingsDataCallId = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      endPoint: `society_managements/${id}/bx_block_meeting/find_building`,
      method: configJSON.getSellersAPIMethod,
    });
  };
 
  handleChangeValue=(event:any,keyState:any)=>{
    if(keyState==="selectedCountry"){
      this.getCityList(event.target.value)
    }else if(keyState==="selectedComplex"){
      const id=event.target.value;
      this.getBuildingsDataList(id);
      this.getUnitDataList(id)
    }
    
    this.setState({ filter: { ...this.state.filter, [keyState]:  event.target.value} })
  }
  handleAccept=(value?:any)=>{
    this.setState({ isAcceptDialogOpen: !this.state.isAcceptDialogOpen, pendingJoinRequest:{
      id:value?.id,
      name: value?.attributes?.name,
      unit: value?.attributes?.unit_number
    } });
  }
  handleReject = (value?:any) => {
    this.setState({ isRejectDialogOpen: !this.state.isRejectDialogOpen, pendingJoinRequest:{
      id:value?.id,
      name: value?.attributes?.name,
      unit: value?.attributes?.unit_number
    } });
  };
  handleSubmit=async(status:any, id:any)=>{
    this.getInvitationResponse = await apiCall({
      contentType: configJSON.sellerDetailsApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.acceptRejectJoineRequestEndPoint+`?id=${id}&status=${status}`,
    });
  }
  handlegetPendingJoinStatus=(responseJson:any)=>{
    this.setState({isAcceptDialogOpen:false,isRejectDialogOpen:false},()=>{
      this.getPandingList()
    })
  }
  handlegetPendingJoinRequestList=(responseJson:any)=>{
    this.setState({loading:false})
    if (responseJson) {
      this.setState({
        pendingJoinRequestList: responseJson.data ||[]
      })
    }
  }
  // Customizable Area End
}
