import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Grid, Typography, MenuItem, FormControl, Select
} from "@material-ui/core";
//@ts-ignore
import CompEmpComplexBlockStep4Controller from './CompEmpComplexBlockStep4Controller.web'
import {Formik, Form, Field, ErrorMessage} from "formik";
import {numbersOnly, alphaNumericOnly} from "../../../../components/src/KeyBlockFunctions";
import "../../assets/commonForm.css"
import * as Yup from 'yup';
// Customizable Area End

export default class CompEmpComplexBlockStep4 extends CompEmpComplexBlockStep4Controller {
    render() {
        // Customizable Area Start

        const {t} = this.props;

        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <Formik
                    initialValues={this.props.locationDetails}
                    validationSchema={this.validationSchema}
                    validateOnMount={true}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        this.props.handleLocationDetails(values)
                    }}
                >
                    {({values, touched, errors, isValid, setFieldValue, handleChange}) => (
                        <Form translate="yes" className="commonForms">
                            <Box>
                                <Box style={{marginTop: '20px'}}>
                                    <Box style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <Box>
                                            <Typography style={{fontSize: '22px', fontWeight: 'bold'}}
                                                        className="Location bold-text">{t("Location")}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography style={{fontSize: '16px'}}
                                                        className="steplocation">{t("Step")} {this.props.stepComplex + 1} {t("of 7")}</Typography>
                                        </Box>
                                    </Box>
                                    <Box style={{marginTop: '30px'}}>
                                        <Box style={{display: 'flex'}}>
                                            <Box className="formGroups customSelect Location"
                                                 style={{marginRight: '25px', width: '50%'}}>
                                                <Typography className="typographyLable Location">{t("Location Link")}</Typography>
                                                <Box>
                                                    <Box className="formGroups Location" style={{
                                                        marginBottom: "0px",
                                                        border: "1px solid #F0F0F0",
                                                        borderRadius: "8px",
                                                        backgroundColor: "#F0F0F0"
                                                    }}>
                                                        <Field name="locationLink" type="text"
                                                               placeholder={t("Location Link")} className="formInputs"
                                                               data-testid="fieldChange"
                                                               onChange={(e: any) => {
                                                                   handleChange(e)
                                                                   this.props.handleLocationFieldChange('locationLink', e.target.value)
                                                               }}/>
                                                        <span className="frmLeftIcons">
                                                            </span>
                                                    </Box>
                                                    <Box style={webStyle.errorMsg}><ErrorMessage name="locationLink"
                                                                                                 component="div"
                                                                                                 data-testid="locationLink-error"/></Box>
                                                </Box>
                                            </Box>
                                            <Box className="formGroups customSelect Location" style={{width: '50%'}}>
                                                <Typography className="typographyLable Location">{t("Longitude")}</Typography>
                                                <Box>
                                                    <Box className="formGroups Location" style={{
                                                        marginBottom: "0px",
                                                        border: "1px solid #F0F0F0",
                                                        borderRadius: "8px",
                                                        backgroundColor: "#F0F0F0"
                                                    }}>
                                                        <Field name="longitude" type="text" placeholder={t("Longitude")}
                                                               onKeyDown={numbersOnly}
                                                               className="formInputs"
                                                               data-testid="fieldChange"
                                                               onChange={(e: any) => {
                                                                   handleChange(e)
                                                                   this.props.handleLocationFieldChange('longitude', e.target.value)
                                                               }}/>
                                                        <span className="frmLeftIcons">
                                                            </span>
                                                    </Box>
                                                    <Box style={webStyle.errorMsg}><ErrorMessage name="longitude"
                                                                                                 component="div"
                                                                                                 data-testid="longitude-error"/></Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box style={{marginTop: '20px', display: 'flex'}}>
                                            <Box className="formGroups customSelect Location"
                                                 style={{marginRight: '25px', width: '50%'}}>
                                                <Typography className="typographyLable Location">{t("Latitude")}</Typography>
                                                <Box>
                                                    <Box className="formGroups Location" style={{
                                                        marginBottom: "0px",
                                                        border: "1px solid #F0F0F0",
                                                        borderRadius: "8px",
                                                        backgroundColor: "#F0F0F0"
                                                    }}>
                                                        <Field name="latitude" type="number" placeholder={t("Latitude")}
                                                               className="formInputs"
                                                               data-testid="fieldChange"
                                                               onChange={(e: any) => {
                                                                   handleChange(e)
                                                                   this.props.handleLocationFieldChange('latitude', e.target.value)
                                                               }}/>
                                                        <span className="frmLeftIcons">
                                                            </span>
                                                    </Box>
                                                    <Box style={webStyle.errorMsg}><ErrorMessage name="latitude"
                                                                                                 component="div"
                                                                                                 data-testid="latitude-error"/></Box>
                                                </Box>
                                            </Box>
                                            <Box className="formGroups customSelect step4CountrySelect"
                                                 style={{marginRight: '', width: '50%'}}>
                                                <Typography className="typographyLable City step4CountryLabel">
                                                    {t("Country")}
                                                </Typography>
                                                <FormControl variant="outlined" className="step4CountryFormControl">
                                                    <Field as={Select} name="country"
                                                           labelId="demo-simple-select-outlined-label"
                                                           className={`${values.country !== '' ? 'estate_select_color_building_value' : 'estate_select_color_building'} estate_select  City step4CountryField customSelect fieldChange`}
                                                           style={{backgroundColor: "#F0F0F0", borderRadius: '8px'}}
                                                           data-testid="fieldChange"
                                                           onChange={(e: any) => {
                                                               handleChange(e)
                                                               this.props.handleLocationFieldChange('country', e.target.value)
                                                           }}
                                                           displayEmpty
                                                    >
                                                        <MenuItem disabled value="">{t("Select Country")}</MenuItem>
                                                        {this.props.countriesList.length && this.props.countriesList.map((variant: any, i: number) => (
                                                            <MenuItem className="step4CountryMenu" value={variant}
                                                                      key={i}>
                                                                {variant}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </FormControl>
                                                <Box style={webStyle.errorMsg}
                                                     className="step4CountryErrorBox"><ErrorMessage name="country"
                                                                                                    component="div"
                                                                                                    data-testid="country-error"
                                                                                                    className="step4CountryError"/></Box>
                                            </Box>
                                        </Box>
                                        <Box style={{marginTop: '20px', display: 'flex'}}>
                                            <Box className="formGroups customSelect step4StateSelect"
                                                 style={{width: '50%', marginRight: '25px'}}>
                                                <Typography className="typographyLable Sateor step4StateLabel">
                                                    {t("Sate or Area")}
                                                </Typography>
                                                <FormControl variant="outlined" className="step4StateForm">
                                                    <Field as={Select} name="state"
                                                           labelId="demo-simple-select-outlined-label"
                                                           className={`${values.state !== '' ? 'estate_select_color_building_value' : 'estate_select_color_building'} estate_select City step4StateField fieldChange1`}
                                                           displayEmpty
                                                           style={{backgroundColor: "#F0F0F0", borderRadius: '8px'}}
                                                           data-testid="fieldChange"
                                                           onChange={(e: any) => {
                                                               handleChange(e)
                                                               this.props.handleLocationFieldChange('state', e.target.value)
                                                           }}
                                                    >
                                                        <MenuItem value="" disabled>{t("Select State")}</MenuItem>
                                                        {this.props.countriesList.length && this.props.countriesList.map((variant: any, i: number) => (
                                                            <MenuItem className="step4StateMenu" value={variant}
                                                                      key={i}>
                                                                {variant}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </FormControl>
                                                <Box style={webStyle.errorMsg}
                                                     className="step4StateErrorBox"><ErrorMessage name="state"
                                                                                                  className="step4StateError"
                                                                                                  component="div"
                                                                                                  data-testid="state-error"/></Box>
                                            </Box>
                                            <Box className="formGroups customSelect step4CitySelect"
                                                 style={{marginRight: '', width: '50%'}}>
                                                <Typography className="typographyLable City step4CityLabel">
                                                    {t("City")}
                                                </Typography>
                                                <FormControl variant="outlined" className="step4CityForm">
                                                    <Field as={Select} name="city"
                                                           labelId="demo-simple-select-outlined-label"
                                                           className={`${values.city !== '' ? 'estate_select_color_building_value' : 'estate_select_color_building'} estate_select City step4CityField customSelect fieldChange2`}
                                                           displayEmpty
                                                           data-testid="fieldChange"
                                                           onChange={(e: any) => {
                                                               handleChange(e)
                                                               this.props.handleLocationFieldChange('city', e.target.value)
                                                           }}
                                                           style={{backgroundColor: "#F0F0F0", borderRadius: '8px'}}
                                                    >
                                                        <MenuItem disabled value="">{t("Select City")}</MenuItem>
                                                        {this.props.countriesList.length && this.props.countriesList.map((variant: any, i: number) => (
                                                            <MenuItem className="step4CityMenu" value={variant} key={i}>
                                                                {variant}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </FormControl>
                                                <Box style={webStyle.errorMsg}
                                                     className="step4CityErrorBox"><ErrorMessage name="city"
                                                                                                 className="step4CityError"
                                                                                                 component="div"
                                                                                                 data-testid="city-error"/></Box>
                                            </Box>
                                        </Box>
                                        <Box className="step8" style={{display: 'flex', marginTop: '20px'}}>
                                            <Box className="formGroups customSelect"
                                                 style={{width: '50%', marginRight: '25px'}}>
                                                <Typography className="typographyLable Zip">{t("Zip Code")}</Typography>
                                                <Box>
                                                    <Box className="formGroups Zip" style={{
                                                        marginBottom: "0px",
                                                        border: "1px solid #F0F0F0",
                                                        borderRadius: "8px",
                                                        backgroundColor: "#F0F0F0"
                                                    }}>
                                                        <Field name="zipCode" type="text" placeholder={t("Zip Code")}
                                                               onKeyDown={alphaNumericOnly}
                                                               className="formInputs"
                                                               data-testid="fieldChange"
                                                               onChange={(e: any) => {
                                                                   handleChange(e)
                                                                   this.props.handleLocationFieldChange('zipCode', e.target.value)
                                                               }}/>
                                                        <span className="frmLeftIcons Zip">
                                                            </span>
                                                    </Box>
                                                    <Box style={webStyle.errorMsg}><ErrorMessage name="zipCode"
                                                                                                 component="div"
                                                                                                 data-testid="zipCode-error"/></Box>
                                                </Box>
                                            </Box>
                                            <Box className="formGroups customSelect"
                                                 style={{marginRight: '', width: '50%'}}>
                                                <Typography className="typographyLable Landmark">{t("Landmark")}</Typography>
                                                <Box>
                                                    <Box className="formGroups Landmark" style={{
                                                        marginBottom: "0px",
                                                        border: "1px solid #F0F0F0",
                                                        borderRadius: "8px",
                                                        backgroundColor: "#F0F0F0"
                                                    }}>
                                                        <Field name="landmark" type="text" placeholder={t("Landmark")}
                                                               onKeyDown={alphaNumericOnly}
                                                               className="formInputs Landmark"
                                                               data-testid="fieldChange"
                                                               onChange={(e: any) => {
                                                                   handleChange(e)
                                                                   this.props.handleLocationFieldChange('landmark', e.target.value)
                                                               }}/>
                                                        <span className="frmLeftIcons">
                                                            </span>
                                                    </Box>
                                                    <Box style={webStyle.errorMsg}><ErrorMessage name="landmark"
                                                                                                 component="div"
                                                                                                 data-testid="landmark-error"/></Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box style={{width: '100%', marginTop: '20px'}}>
                                            <Typography className="typographyLable Street">{t('Street Name')}</Typography>
                                            <Box className="formGroups Street " style={{
                                                marginBottom: "0px",
                                                border: "1px solid #F0F0F0",
                                                borderRadius: "8px",
                                                backgroundColor: "#F0F0F0"
                                            }}>
                                                <Field name="streetName" type="text" placeholder={t("Street Name")}
                                                       onKeyDown={alphaNumericOnly}
                                                       className="formInputs"
                                                       data-testid="fieldChange"
                                                       onChange={(e: any) => {
                                                           handleChange(e)
                                                           this.props.handleLocationFieldChange('streetName', e.target.value)
                                                       }}/>
                                                <span className="frmLeftIcons Street">
                                                    </span>
                                            </Box>
                                            <Box style={webStyle.errorMsg}><ErrorMessage name="streetName"
                                                                                         component="div"
                                                                                         data-testid="streetName-error"/></Box>
                                        </Box>
                                        <Box style={{width: '100%', marginTop: '20px'}}>
                                            <Typography className="typographyLable">{t("Official Address")}</Typography>
                                            <Box className="formGroups Official" style={{
                                                marginBottom: "0px",
                                                border: "1px solid #F0F0F0",
                                                borderRadius: "8px",
                                                backgroundColor: "#F0F0F0"
                                            }}>
                                                <Field name="officialAddress" type="text" placeholder={t("Official Address")}
                                                       className="formInputs Official"
                                                       data-testid="fieldChange"
                                                       onChange={(e: any) => {
                                                           handleChange(e)
                                                           this.props.handleLocationFieldChange('officialAddress', e.target.value)
                                                       }}/>
                                                <span className="frmLeftIcons Official">
                                                    </span>
                                            </Box>
                                            <Box style={webStyle.errorMsg}><ErrorMessage name="officialAddress"
                                                                                         component="div"
                                                                                         data-testid="officialAddress-error"/></Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="step4BtnBox"
                                 style={{display: 'flex', justifyContent: "end", flexWrap: 'wrap'}}>
                                <Grid className="step4Grid" style={{marginTop: "30px", marginRight: '20px'}}>
                                    <Box style={{}}>
                                        <Button className="step4Prev" style={{
                                            color: "#2B6FED",
                                            fontWeight: 'bold',
                                            padding: '0px 20.5px',
                                            textTransform: 'capitalize',
                                            width: '177px',
                                            height: '56px',
                                            border: '1px solid #2B6FED',
                                            borderRadius: "8px",
                                            display: 'flex',
                                            justifyContent: 'center',
                                            textAlign: 'center'
                                        }} onClick={this.props.handleBack}><span
                                            className="bold-text">{t("PREV")}</span></Button>
                                    </Box>
                                </Grid>
                                <Box>
                                    <Grid className="step4Grid" style={{marginTop: "30px",}}>
                                        <Button className="step4Next" style={{
                                            width: '177px',
                                            height: '56px',
                                            backgroundColor: '#2B6FED',
                                            borderRadius: "8px",
                                            display: 'flex',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            color: "#FFFFFF",
                                            fontWeight: 'bold',
                                            padding: '0px 20.5px',
                                            textTransform: 'capitalize'
                                        }} data-test-id="handleNextComplex" type="submit"><span
                                            className="bold-text">{t("NEXT")}</span></Button>
                                    </Grid>
                                </Box>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </>

            // Customizable Area End
        );
    }
}

// export default withTranslation()(withRouter(CompEmpComplexBuildingBlock));
// Customizable Area Start
const webStyle = {
    errorMsg: {
        color: 'red',
        fontSize: '12px',
        marginTop: '5px'
    },
    buttonSave: {
        width: '177px',
        height: '56px',
        backgroundColor: '#2B6FED',
        borderRadius: "8px",
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        color: "#FFFFFF",
        fontWeight: 'bold',
        padding: '0px 20.5px',
        textTransform: 'capitalize'
    },
    step6: {
        margin: '11px 0px',
        color: 'black',
        fontSize: '16px',
        fontWeight: 400
    }
}
// Customizable Area End