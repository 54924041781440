//Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  InputLabel,
  Select,
  Menu,
  MenuItem,
  Divider,
  FormControl,
  CardContent,
  Card,
  CardActions,
  Modal,
  TextField,
  InputAdornment,
  withStyles,
  FormLabel
} from "@material-ui/core";
import ViewJurisdictionController, {
  Props,
} from "./ViewJurisdictionController.web";

import SearchIcon from "@material-ui/icons/Search";
import {ModalPopup} from '../../../../components/src/ModalPopup/index'

import { Formik } from "formik";
import CloseIcon from "@material-ui/icons/Close";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PaginationModule from "../../../StoreCredits/src/PaginationModule.web";
import "./jurisdiction.css";
import GenericModal from "./GenericModal.web";
import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { ReportsStyleWeb } from "../../../StoreCredits/src/ReportsStyle.web";
import AlertErrorWeb from "../../../../components/src/AlertError.web";
// @ts-ignore
import TickIcon from '../../assets/tick-circle-icon.png'
import { t } from "i18next";
import { TabsComponent } from "../../../../components/src/TabsComponent";



 export class ViewJurisdiction extends ViewJurisdictionController {
  handleAddComplex = () => {
    this.setState({ addBuildingOpen: true });
  };
  handleVertMore = (e: any) => {
   
    this.setState({ isDetailsMenu: e?.currentTarget });
  };
  handleDetailsMenuClose = () => {
    this.setState({ isDetailsMenu: null });
  };
  changeJurisdiction = () => {
   
    this.setState({ changejurisdictionModal: true,isDetailsMenu:null });
    this.getJurisdiction()
    this.getComplexDetails()
  };

  handleDetails = () => (
  
      <Menu
        id="simple-menu1"
        anchorEl={this.state.isDetailsMenu}
        keepMounted
        open={this.state.isDetailsMenu != null ? true : false}
        onClose={this.handleDetailsMenuClose}
        className="menu-field closedetails"
      >
        <MenuItem className="menu-field">{t("View location on Map")}</MenuItem>
        <Link to={`/CompanyEmployee/ComplexDetails/${this.state.switchId}`}>
        <MenuItem className="menu-field" >{t("Go to Complex Page")}</MenuItem>
        </Link>
        <MenuItem
          className="menu-field changeJuri"
          onClick={(e)=>{ this.setState({changejurisdictionModal:true},()=>{this.changeJurisdiction()})}}
          id="change_jur"
        >
          {t("Change Jurisdiction")}
        </MenuItem>
      </Menu>
    
  );
  tableData = () => (
    <>
      <div style={{ overflowX: "scroll" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="maintablehead">
            <TableRow>
              <TableCell className="tableheader">{t("Complex Name")}</TableCell>
              <TableCell className="tableheader">{t("Account Manager")}</TableCell>
              <TableCell className="tableheader">{t("Total Buildings")}</TableCell>
              <TableCell className="tableheader">{t("Total Units")}</TableCell>
              <TableCell className="tableheader">
                {" "}
                {t("Subscription Ends in")}
              </TableCell>
              <TableCell className="tableheader">{t("Subscription Type")}</TableCell>
              <TableCell className="tableheader">{t("Paying Month")}</TableCell>
              <TableCell className="tableheader  optiontable" />
            </TableRow>
          </TableHead>
          <TableBody>
            {
              this.state.complexList?.length > 0 ? (
            
            this.state.complexList?.map((item:any, index:any) => {
              return (
                <>
                  <TableRow>
                    <TableCell className="tablecell">{item.attributes.name}</TableCell>
                    <TableCell className="tablecell">{item.attributes.name}</TableCell>
                    <TableCell className="tablecell">
                      {item.attributes.total_buildings}
                    </TableCell>
                    <TableCell className="tablecell">{item.attributes.total_units[0]}</TableCell>
                    <TableCell className="tablecell">{item.attributes.subscription_end_in}</TableCell>
                    <TableCell className="tablecell">{item.attributes.subscription_type}</TableCell>
                    <TableCell className="tablecell">{item.attributes.paying_month}</TableCell>

                    <TableCell className="tablecell" >
                      <MoreVertIcon
                      onClick={(e) => {
                        this.setState({ switchId: item.id });
                        this.handleVertMore(e);
                      }}
                      
                     
                        id="moreDetails"
                      />
                    </TableCell>
                  </TableRow>
                </>
              );
            }) ) :(
              <><TableRow>
                          <TableCell colSpan={6} style={webStyle.noData}>
                            {t("There are no complexes added in the jurisdiction")}
                          </TableCell>
                        </TableRow>
              </>
            )} 
          </TableBody>
        </Table>
      </div>
    </>
  );
  
  handleEdit = () => {
    this.setState({ editModal: true });
  };

  viewHeader = (t: any,classes:any) => (
    <Box style={{ padding: "40px", paddingRight: "40px" }}>
      <div className="viewheading-data">
        <Typography className="subhead">
          <Link to={""} color="inherit">
          {t("My Dashboards")}/
          </Link>
          <Link to="/CompanyEmployee/jurisdication" color="inherit">{t("Manage Jurisdiction/")}</Link>
          <Link to={""}  className="minhead" style={{ color: "#2c6fed" }}>
          {t("Manage Jurisdiction")}
          </Link>
        </Typography>
       
       
      </div>

      <br />
      <div className="main-head">
        <Typography
          variant="h5"
          className="sub-heading bold-text reports-BudgetReportmain"
          style={{ fontSize: "28px", display: "flex", whiteSpace: "nowrap" }}
        >
          {t("Jurisdiction Details")}
        </Typography>

        <div className="add-complex">
          <div>
            <div>
            <Box className="sorting-header ">
          <Box className="formGroup customSelect">
           
            <FormControl variant="outlined">
              <Select
                displayEmpty
                className="select-input budgetReportStatusListSelect changeJurS"
                value={this.state.changeId}
                onChange={(e)=>{this.setState({changeId:e.target?.value},()=>{this.changeJurisdictionById(e.target.value)})}}
                id="filterCountry"
                // placeholder={`${this.state.countryDisplay}`}
              >
                {/* this.props.history.push("/CompanyEmployee/ViewJurisdiction") */}
                <MenuItem value="" disabled id="selectStatusBudgetReport">
                {this.state.countryDisplay}({this.state?.detailsData?.jurisdiction_id})
                </MenuItem>
                <MenuItem value=" " id="selectStatusBudgetReportAll">
                  {t("All")}
                </MenuItem>
                {this.state.jurisdictionList?.length &&
                        this.state.jurisdictionList?.map((item: any) => {
                          return (
                            <MenuItem key={item} value={item.id}>
                              {item.attributes.jurisdiction_name}({item.attributes.jurisdiction_id})
                            </MenuItem>
                          );
                        })}
              </Select>
            </FormControl>
          </Box>
          </Box>
            </div>
          </div>
          <div>
            <Button
              style={webStyle.addComplexBtn}
              onClick={this.handleAddComplex}
              id="addBuilding"
            >
              {t("ADD NEW COMPLEX/BUILDING")}
            </Button>
          </div>
        </div>
      </div>

      <Card className="card-jurisdiction">
        <CardActions className="card_sub">
          <Typography className="mainheading">{this.state.detailsData?.jurisdiction_name.length ? <>{`${this.state.detailsData?.jurisdiction_name}(${this.state.detailsData.jurisdiction_id})`}</>:"NA"}</Typography>
          <div>
            <Button
              className="delete-btn-view delete-btn"
              onClick={(e) => { this.setState({ confirmModal: true ,deleteId:e})}}
            >
              {t("DELETE")}
            </Button>
            <Button className="edit-btn edit-btn-jur" onClick={this.handleEdit} style={{borderRadius:"10px"}}>
              {t("EDIT JURISDICTION")}
            </Button>
          </div>
        </CardActions>
        
          <Box style={{paddingTop:"20px",paddingBottom:"20px"}}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography className="view-heading">
                  {t("Currency")}
                </Typography>
                <Typography className="view-subvalue">
                  {this.state.detailsData?.currency.length ? this.state.detailsData?.currency.length:"NA"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="view-heading">
                  {t("Timezone")}
                </Typography>
                <Typography className="view-subvalue">
                  {this.state.detailsData?.timezone.length ? this.state.detailsData?.timezone :"NA"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="view-heading">
                  {t("Unit of Measurement")}
                </Typography>
                <Typography className="view-subvalue">
                  {this.state.detailsData?.unit_of_measurement.length ? this.state.detailsData?.unit_of_measurement :"NA"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography className="view-heading">
                  {t("Management Fee calculation Formula")}
                </Typography>
                <Typography className="view-subvalue">
                  {this.state.detailsData?.formula.length ?this.state.detailsData?.formula:"NA"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="view-heading">
                  {t("Mobile number length")}
                </Typography>
                <Typography className="view-subvalue">
                  {this.state.detailsData?.mobile_number_length.length ?this.state.detailsData?.mobile_number_length:"NA"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="view-heading">
                  {t("Default Renting Contract")}
                </Typography>
                <Typography className="view-subvalue">NA</Typography>
              </Grid>
            </Grid>
          </Box>
       
      </Card>
      <Box style={{ marginTop: "25px", marginBottom: "25px" }}>
      <TabsComponent
                            tabNo={this.state.tabNo}
                            handleChange={this.handleTabChange}
                            tabs={
                                [
                                    {
                                        label:t('Complex')
                                    },
                                    {
                                        label: t('Individual Buildings'),
                                        testId: 'tabcheck'
                                    }
                                ]
                            }
                        />
       
      </Box>
      <div className={classes.reportList}>
      <Box className= "top-bar ChairmanInvoices" id="ChairmanInvoices">
        <Box className="filter" id="ChairmanInvoices" style={{display:"flex",flexWrap:"wrap"}}>
        <Select displayEmpty 
         className="select-input selectBuildingForChairmanInvoices countryFilter"
         value={this.state.country}
         onChange={(e)=>{this.setState({country:e.target.value},()=>this.getCitiesList(e.target.value))}}
         id="filterCountry"
         >
          <MenuItem value="" disabled id="selectStatusBudgetReport">
            {t("Country")}
          </MenuItem>
          <MenuItem value="all" id="selectStatusBudgetReportAll">
            {t("All")}
          </MenuItem>
                {this.state.countryListdata.length &&
                        this.state.countryListdata.map((item: any) => {
                          return (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}            
                                  
        </Select>
        <Select displayEmpty 
         className="select-input selectBuildingForChairmanInvoices "
        
          value={this.state.region}
          onChange={(e)=>this.setState({region:e.target.value})}
          id="regionFilter"
         >
          <MenuItem value="" disabled id="selectStatusBudgetReport">
                  {t("Region")}
                </MenuItem>
                <MenuItem value="all" id="selectStatusBudgetReportAll">
                  {t("All")}
                </MenuItem>
                {this.state.regionList.region_city_list?.length &&
                        this.state.regionList.region_city_list.map((item: any) => {
                          return (
                            <MenuItem key={item} value={item.region}>
                              {item.region}
                            </MenuItem>
                          );
                        })}          
                                  
        </Select>
        <Select displayEmpty 
         className="select-input selectBuildingForChairmanInvoices countryFilter"
         value={this.state.city}
         onChange={(e)=>this.setState({city:e.target.value})}
         id="cityFilter"
         >
          <MenuItem value="" disabled id="selectStatusBudgetReport">
                  {t("City")}
                </MenuItem>
                <MenuItem value=" " id="selectStatusBudgetReportAll">
                  {t("All")}
                </MenuItem>
                {this.state.cityList.region_city_list?.length &&
                  this.state.cityList.region_city_list.map((item: any) => {
                    return (
                      <MenuItem key={item} value={item.city}>
                        {item.city}
                      </MenuItem>
                    );
                  })}
                       
                                  
        </Select>
        <Select displayEmpty 
         className="select-input selectBuildingForChairmanInvoices countryFilter"
         value={this.state.status}
                onChange={(e)=>this.setState({status:e.target.value})}
                id="statusFilter"
         >
          <MenuItem value="" disabled id="selectStatusBudgetReport">
                  {t("Status")}
                </MenuItem>
                <MenuItem value=" " id="selectStatusBudgetReportAll">
                  {t("All")}
                </MenuItem>
                {this.state.statusList.length &&
                  this.state.statusList.map((item: any) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}          
                                  
        </Select>
        <Select displayEmpty 
         className="select-input selectBuildingForChairmanInvoices countryFilter"
         value={this.state.accountManager}
                onChange={(e)=>this.setState({accountManager:e.target.value})}
                id="amFilter"
         >
           <MenuItem value="" disabled id="selectStatusBudgetReport">
                  {t("Account Manager")}
                </MenuItem>
                <MenuItem value=" " id="selectStatusBudgetReportAll">
                  {t("All")}
                </MenuItem>
                {this.state.amList.length &&
                  this.state.amList.map((item: any) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}  
        </Select>
        <Button className="handleSearchFilterForInvoices"
        
         
        startIcon={<SearchIcon />}
        onClick={this.handleFilterSubmit}
        id="searchFilter"
        >
          {t("Search")}</Button>
        </Box>
      </Box>
      </div>
      
      {this.state.complexList?.length ? (
        <Box className="view-details">
          <Card>
            
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "20px",
                    alignItems:"center"
                  }}
                >
                  <h3 className="bold-text reports-BudgetReportmain" style={{fontSize:"22px"}}>
                    {t("List of Complex")}
                  </h3>

                  <TextField
                    placeholder={t("Search by complex name")}
                    type="text"
                    variant="outlined"
                    fullWidth
                    size="small"
                    className="text-jur test-search-jur"
                    style={{ border: "1px solid lightgray" }}
                    id="searchValue"
                  
                    onKeyUp={this.handleSearchKeyUp}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Divider />
              {this.tableData()}
              <div className="pagination">
                <PaginationModule 
                pagination={this.state.pagination}
                handlePagination={this.handlePagination}
                page={this.state.page}
                />
              </div>
            
          </Card>
        </Box>
      ) : (
        <>
          <Card
            style={{
              height: "300px",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <CardContent>
              <Typography>
                {" "}
                {t("There are no complexes added in the jurisdiction")}{" "}
              </Typography>
            </CardContent>
          </Card>
        </>
      )}
    </Box>
  );
  handleBuildingClose = () => {
    this.setState({ addBuildingOpen: false ,});
  };
  buildingBody = (t:any) =>(
    <div>
     
      <Formik
        validateOnBlur={false}
        initialValues={{
          complex: "",
        }}
        onSubmit={(values: Record<string, string>) => {}}
      >
         
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit}>
            <Box className="modal-content">
              <Box className="headingData">
                <Typography className="mainheading">
                  {t("Add New Complex/Building")}
                </Typography>
                <CloseIcon
                  onClick={this.handleBuildingClose}
                  className="closeicon closeB"
                />
              </Box>
              <Divider />
              <Box>
             <Grid container spacing={2} style={{ marginTop: "10px" }}> 
            
                      <Grid item xs={6}>
                        <FormLabel
                          component="legend"
                          className="addTeamModal jur-text"
                          style={webStyle.labelsStyle}
                        >
                          {t("Country")}
                        </FormLabel>
                        <Select
                          fullWidth
                          id="task-type-select1"
                          variant="outlined"
                          displayEmpty
                          style={{
                            border: "1px solid #ECECEC",
                            borderRadius: "10px",
                            backgroundColor: "#f9f9f9",
                            marginRight: "10px",
                          }}
                          value={this.state.complex_countryselected}
                          className="select-input inputForAssinged timeSelect countryBuilding task-type-select1"
                          placeholder="Select Country"
                          onChange={(e:any)=>{this.setState({complex_countryselected:e.target.value},()=>this.getCitiesList(e.target.value))}}
                         
                        >
                        <MenuItem value="" disabled id="selectStatusBudgetReport ">
                      {t("Country")}
                    </MenuItem>
                         

                      {this.state.countryListdata.length &&
                        this.state.countryListdata.map((item: any) => {
                          return (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                        </Select>
                        {/* <Typography color="error" variant="subtitle2">{errors.assignedTo}</Typography> */}
                      </Grid>
                      <Grid item xs={6}>
                        <FormLabel
                          component="legend"
                          className="addTeamModal jur-text"
                          style={webStyle.labelsStyle}
                        >
                         {t("City")} 
                        </FormLabel>
                        <Select
                          fullWidth
                          // id="task-type-city1"
                          id="task-type-select1"
                          variant="outlined"
                          displayEmpty
                          value={this.state.complex_cityselected}
                          style={{
                            border: "1px solid #ECECEC",
                            borderRadius: "10px",
                            backgroundColor: "#f9f9f9",
                            marginRight: "10px",
                          }}
                          className="select-input inputForAssinged unitSelect"
                          onChange={(e: any) => {
                            this.setState({ complex_cityselected: e.target.value }, () => {
                              this.handleSelectComplex();
                            });
                          }}
                         
                        >
                          <MenuItem value="" disabled>
                            {t("Select City")}
                          </MenuItem>
                         
                          {this.state.cityList.region_city_list?.length &&
                  this.state.cityList.region_city_list.map((item: any) => {
                    return (
                      <MenuItem key={item} value={item.city}>
                        {item.city}
                      </MenuItem>
                    );
                  })}
                        </Select>
                        {/* <Typography color="error" variant="subtitle2">{errors.roleId}</Typography> */}
                      </Grid>
             </Grid>
             <Grid item xs={12}>
                        <FormLabel
                          component="legend"
                          className="addTeamModal jur-text"
                          style={webStyle.labelsStyle}
                        >
                          {t("Select Complex")}
                        </FormLabel>
                        <Select
                          fullWidth
                          id="task-type-select1"
                          // id="task-type-complex1"
                          variant="outlined"
                          displayEmpty
                          style={{
                            border: "1px solid #ECECEC",
                            borderRadius: "10px",
                            backgroundColor: "#f9f9f9",
                            marginRight: "10px",
                          }}
                          value={this.state.complex_complexSelected}
                          className="select-input inputForAssinged timeSelect complexSelect"
                         
                          onChange={(e:any) => this.setState({complex_complexSelected:e.target.value})}
                        >
                          <MenuItem value="" disabled>
                            {t("Select Complex")}
                          </MenuItem>
                          {this.state.complexBuildingList?.length &&
                        this.state.complexBuildingList.map((item: any) => {
                          return (
                            <MenuItem key={item} value={item.id}>
                              {item.attributes.name}
                            </MenuItem>
                          );
                        })}
                         
                        </Select>
                     
                      </Grid>
              
              </Box>
              <div className="adjustBtn">
                <Button
                  onClick={this.handleBuildingClose}
                  className="btn-cancel"
                >
                  {t("CANCEL")}
                </Button>
                <Button className="btn-add" onClick={this.handleAddBuilding} id="addBuild">{t("ADD")}</Button>
              </div>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
  changeJurisdictionBody = () => (
    <div>
      <form>
        <Box overflow={"scroll"} className="modal-content">
          <Box className="headingData">
            <Typography className="mainheading">{t("Change Jurisdiction")}</Typography>
            <CloseIcon  id="closeIcon" onClick={()=>this.setState({changejurisdictionModal:false,newDetailsData:null,complex:""})} />
          </Box>
          <Divider />
          <Box className="box-div-modal" style={{paddingRight:"25px"}}>
            <Box>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                <FormLabel
                          component="legend"
                          className="addTeamModal jur-text"
                          style={webStyle.labelsStyle}
                        >
                          {t("Select Complex")}
                        </FormLabel>
                        
                        <Select
                          fullWidth
                          id="task-type-select1"
                          // id="complex_body"
                          variant="outlined"
                          
                          disabled
                          style={{
                            border: "1px solid #ECECEC",
                            borderRadius: "10px",
                            backgroundColor: "#f9f9f9",
                            marginRight: "10px",
                          }}
                          placeholder="Select Complex"
                          // renderValue={(selectedValue:any)=>{
                          //   return  selectedValue.name
                          // }}
                          value={this.state.complexById}
                          onChange={(e:any)=>this.setState({complex:e.target.value})}
                          // value={this.state.complex_countryselected}
                          className="select-input inputForAssinged timeSelect  complex_body countryBuilding task-type-select1"
                          // placeholder="Select Country"
                          // onChange={(e:any)=>{this.setState({complex_countryselected:e.target.value},()=>this.getCitiesList(e.target.value))}}
                          
                        >
                        <MenuItem value={this.state.complexById} disabled id="selectStatusBudgetReport ">
                    {this.state.complexById}
                    </MenuItem>
                  
                        </Select>
                 
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                <FormLabel
                          component="legend"
                          className="addTeamModal jur-text"
                          style={webStyle.labelsStyle}
                        >
                         {t("Current Jurisdiction")} 
                        </FormLabel>
                 
                   <TextField
                          label={t("Jurisdiction Name")}
                          fullWidth
                          id="task-type-select1"
                          variant="outlined"
                          style={{
                            border: "1px solid #ECECEC",
                            borderRadius: "10px",
                            backgroundColor: "#f9f9f9",
                            marginRight: "10px",
                          }}
                          name="jname"
                           value={this.state.detailsData?.jurisdiction_name}
                          className="firstname add-valueJuri"
                          onChange={(e: any) => {
                            this.setState({newJur:e.target.value.name,newJurId:e.target.value.id},()=>{
                             this.getNewJurisdictionById(e.target.value.id)
                            })
                               
                           
                           }}
                          
                        />
                  
                </Grid>
                <Grid item sm={6}>
                <FormLabel
                          component="legend"
                          className="addTeamModal jur-text"
                          style={webStyle.labelsStyle}
                        >
                          {t("New Jurisdiction")}
                        </FormLabel>
                        <Select
                          fullWidth
                          id="task-type-select1"
                          // id="complex_body"
                          variant="outlined"
                          displayEmpty
                          style={{
                            border: "1px solid #ECECEC",
                            borderRadius: "10px",
                            backgroundColor: "#f9f9f9",
                            marginRight: "10px",
                          }}
                          placeholder="New jurisdiction"
                         
                          onChange={(e: any) => {
                            this.setState({newJur:e.target.value.name,newJurId:e.target.value.id},()=>{
                             this.getNewJurisdictionById(e.target.value.id)
                            })
                               
                           
                           }}
                           renderValue={(selectedValue:any) => {
                           return selectedValue.name
                          }}
                          //  value={this.state.newJur}
                           value={{name:this.state.newJur,id:this.state.newJurId}}
                          // value={this.state.complex_countryselected}
                          className="select-input inputForAssinged timeSelect new_jur1 countryBuilding task-type-select1"
                          // placeholder="Select Country"
                          // onChange={(e:any)=>{this.setState({complex_countryselected:e.target.value},()=>this.getCitiesList(e.target.value))}}
                          
                        >
                        <MenuItem value="" disabled id="selectStatusBudgetReport ">
                     {t("Select New Jurisdiction")}
                    </MenuItem>
                         

                   
                      {this.state.jurisdictionList.length >0 &&
                        this.state.jurisdictionList.map((item: any) => {
                          return (
                            //@ts-ignore
                            <MenuItem key={item} value={{name:item.attributes.jurisdiction_name,id:item.id}}>
                              {item.attributes.jurisdiction_name}
                            </MenuItem>
                          );
                        })}
                        </Select>
                
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className="changeLabel">{t("Curreny")}</Typography>
                  <Typography className="inputLabel1">{this.state.detailsData?.currency.length ? this.state.detailsData?.currency.length:"-"}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography className="changeLabel">{t("Currency")}</Typography>
                  <Typography className="inputLabel1">{this.state.newDetailsData?.currency.length ? this.state.newDetailsData?.currency.length:"-"}</Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className="changeLabel">{t("Timezone")}</Typography>
                  <Typography className="inputLabel1">
                  {this.state.detailsData?.timezone.length ? this.state.detailsData?.timezone :"-"}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography className="changeLabel">{t("Timezone")}</Typography>
                  <Typography className="inputLabel1">
                  {this.state.newDetailsData?.timezone.length ? this.state.newDetailsData?.timezone :"-"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className="changeLabel">
                    {t("Unit of Measurement")}
                  </Typography>
                  <Typography className="inputLabel1">{this.state.detailsData?.unit_of_measurement.length ? this.state.detailsData?.unit_of_measurement :"-"}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography className="changeLabel">
                   {t("Unit of Measurement")}
                  </Typography>
                  <Typography className="inputLabel1">{this.state.newDetailsData?.unit_of_measurement.length ? this.state.newDetailsData?.unit_of_measurement :"-"}</Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className="changeLabel">
                    {t("Management Fee Calculation Formula")}
                  </Typography>
                  <Typography className="inputLabel1">{this.state.detailsData?.formula.length ? this.state.detailsData?.formula :"-"}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography className="changeLabel">
                    {t("Management Fee Calculation Formula")}
                  </Typography>
                  <Typography className="inputLabel1">{this.state.newDetailsData?.formula.length ? this.state.newDetailsData?.formula :"-"}</Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className="changeLabel">
                    {t("Mobile No length")}
                  </Typography>
                  <Typography className="inputLabel1">{this.state.detailsData?.mobile_number_length.length ? this.state.detailsData.mobile_number_length :"-"}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography className="changeLabel">
                    {t("Mobile No length")}
                  </Typography>
                  <Typography className="inputLabel1">{this.state.newDetailsData?.mobile_number_length.length ? this.state.newDetailsData.mobile_number_length :"-"}</Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className="changeLabel">
                    {t("Deafult Renting Contract")}
                  </Typography>
                  <Typography className="inputLabel1">
                    rentdocument.pdf
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography className="changeLabel">
                    {t("Deafult Renting Contract")}
                  </Typography>
                  <Typography className="inputLabel1">
                    rentdocument_1.pdf
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <div className="adjustBtn">
            <Button onClick={()=>this.setState({changejurisdictionModal:false,newDetailsData:null,complex:""})} className="btn-cancel">
              {t("CANCEL")}
            </Button>
            <Button className="btn-add" onClick={()=>this.complexChangeJurisdiction()} id="Change_val">{t("ADD")}</Button>
          </div>
        </Box>
      </form>
    </div>
  );
  handleModalClose = () => {
    this.setState({ editModal: false });
  };

  handleConfirmModalClose=()=>{
    this.setState({confirmModal:false})
  }
   static WrrappedComponent: any;
  render() {
    //@ts-ignore
    const { t,classes }: any = this.props;
   
    return (
      <>
        <Modal
          open={this.state.editModal}
          onClose={this.handleModalClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal-backdrop"
        >
          <GenericModal
          formRef={this.formRef}
            handleButton={"SAVE"}
            onClose={this.handleModalClose}
            heading={"Edit Jurisdiction"}
            formData={(values) => this.editJurisdiction(values)}
            currencyList={this.state.currencyList}
            timezoneList={this.state.timezoneList}
            measurementList={this.state.measurementList}
            formulaList={this.state.formulaList}
            detailsData={this.state.detailsData}
            t={t}
            countryList={this.state.countryListdata}
          />
        </Modal>

        {this.handleDetails()}
        <Modal
          open={this.state.addBuildingOpen}
          onClose={this.handleBuildingClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal-backdrop"
          id="building_body"
        >
          {this.buildingBody(t)}
        </Modal>
       
         <ModalPopup
        
                    title={t("Delete Jurisdiction?")}
                    description={
                         t("Are you sure you want to delete the jurisdiction?")
                        
                    }
                    open={this.state.confirmModal}
                    closeFn={this.handleConfirmModalClose}
                    primaryAction={this.deleteJurisidiction}
                    secondaryAction={this.handleConfirmModalClose}
                    primaryLabel={t("Confirm")}
                    secondaryLabel={t("Close")}
                    icon={TickIcon}
                    id="delete-jur"
                /> 
       
        <Modal
          open={this.state.changejurisdictionModal}
          onClose={this.handleBuildingClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal-backdrop"
        >
          {this.changeJurisdictionBody()}
        </Modal>
        <AlertErrorWeb show={this.state.showError} handleClose={() => this.setState({ showError: false, error: null })} message={this.state.error} />
        <MainScreen>{this.viewHeader(t,classes)}</MainScreen>
      </>
    );
  }
}
const webStyle: any = {
  labelsStyle: {
    color: "#212121",
    margin: "10px 0px 10px 0px",
  },
  noData:{
    alignItem:"center",
    textAlign:"center"
  },
  viewSearch: {
    color: "white",
    backgroundColor: "#2b6fed",
    fontWeight: "bold",
    borderRadius: "10px",
    padding: "10px 20px",
    height: "55px",
    width: "150px",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#2b6fed",
    },
  },
  addComplexBtn: {
    color: "white",
    backgroundColor: "#fc8434",
    fontWeight: "bold",
    borderRadius: "10px",
    padding: "10px 20px",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#fc8434",
    },
  },
  commonBtn: {
    color: "white",
    backgroundColor: "#2b6fed",
    fontWeight: "bold",
    borderRadius: "25px",
    padding: "10px 20px",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#fc8434",
    },
  } as React.CSSProperties,
  deleteJurBtn: {
    color: "red",
    backgroundColor: "white",
    fontWeight: "bold",
    borderRadius: "10px",
    padding: "10px 20px",
    fontSize: "15px",
    "&:hover": {
      backgroundColor: "#fc8434",
    },
  },
};
// ViewJurisdiction.propTypes = {
//   genericModalOpen: PropTypes.bool,
//   // Define other propTypes here
// };
// export const EnhancedViewJurisdiction = withTranslation()(withStyles(ReportsStyleWeb)(withRouter(ViewJurisdiction)));
//@ts-ignore
export default withTranslation()( withStyles(ReportsStyleWeb)(withRouter(ViewJurisdiction)));
//Customizable Area End
