// Customizable Area Start
import React from "react";
import {
  Container,
  Typography,
  Link,
  Button,
  withStyles,
  InputAdornment,
  TextField,
  TextareaAutosize,
  Card,
  Tabs,
  Tab,
  Divider,
  Dialog,
  IconButton,
  DialogContent,
  InputLabel,
  DialogActions,
  Input,
  Box,
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import "../../dashboard/src/Dashboard.web.css";
import ComplexController, { Props } from "./ComplexController.web";
import { withTranslation } from "react-i18next";
import "./style.css";
//@ts-ignore
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {
  upload,
  Document,
  sizebw,
  unitbw,
  mapLocation,
  location,
  del_image,
  uploadbw,
  floorIcon,
  nextIcon,
  previousIcon,
} from "./assets";
import { BuildingApartmentStyle } from "./BuildingApartmentStyle.web";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { Formik, Form } from "formik";
import Loader from "../../../components/src/Loader.web";
import GoogleMapReact from "google-map-react";
import ItemBox from "../../../components/src/DocumentComponent/ItemBox.web";
import { ErrorHandler } from "../../../components/src/UtilFunction.web";
import ComplexDropdown from "../../../components/src/ComplexDropdown.web";
import GeneralHeaderComponentWeb from "../../../components/src/HeaderComponent/GeneralHeaderComponent.web";
import { languageCondition } from "../../../components/src/ConditionManager.web";

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const settings = {
  swipeToSlide: true,
  slidesToShow: 5,
  infinite: false,
};

const LocationPin = ({}: any) => <img src={mapLocation} />;

class Complex extends ComplexController {
  constructor(props: Props) {
    super(props);
  }

  DocumentsSharedArea = (t: any) => {
    return (
      <>
        <Box className="content-boxes complex-bottom-tabs">
          <Tabs value={this.state.currentTab} onChange={this.handleTabChange}>
            <Tab label={t("Documents")} />
            <Tab label={t("Shared Area")} />
          </Tabs>
          <Box className="tab-content complex-tab-content-box">
            <TabPanel value={this.state.currentTab} index={0}>
              <>
                <Box className="top-content complex-document-box">
                  <Box className="heading">
                    <h2 className="bold-text complex-document-heading">{t("Documents")}</h2>
                  </Box>
                  <Link href="/DocumentChairman">
                    <Box className="right-content">
                      <img src={upload} alt="|" />
                      <span>{t("Upload")}</span>
                    </Box>
                  </Link>
                </Box>
                <Divider />
                <Box className="document-box-box">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                      <Link href="/DocumentChairman/Policy">
                        <ItemBox image={Document} heading={t("Policy")} value={this.state.documentCount.policy} />
                      </Link>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <Link href="/DocumentChairman/Guidelines">
                        <ItemBox
                          image={Document}
                          heading={t("Guidelines")}
                          value={this.state.documentCount.guidelines}
                        />
                      </Link>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <Link href="/DocumentChairman/Roles">
                        <ItemBox image={Document} heading={t("Roles")} value={this.state.documentCount.roles} />
                      </Link>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <Link href="/DocumentChairman/Resolutions">
                        <ItemBox
                          image={Document}
                          heading={t("Resolution")}
                          value={this.state.documentCount.resolution}
                        />
                      </Link>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <Link href="/DocumentChairman/Building-Plans">
                        <ItemBox
                          image={Document}
                          value={this.state.documentCount.buildingPlans}
                          heading={t("Building Plans")}
                        />
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </>
            </TabPanel>
            <TabPanel value={this.state.currentTab} index={1}>
              <>
                <Box className="top-content">
                  <Box className="heading">
                    <h2 className="bold-text complex-shared-area-content">{t("Shared Area")}</h2>
                  </Box>
                </Box>
                <Divider />
                <Box className="document-box-box complex-shared-area-box">
                  <Grid container spacing={2}>
                    {this.state.complexData.sharedAreaList.map((sharedArea: any) => {
                      return (
                        <Grid item xs={12} md={6} lg={4} key={sharedArea.id}>
                          <Box
                            className="item shared-area-item"
                            style={dashBoard.cursorPointer}
                            onClick={() => this.props.navigation.navigate("SharedArea", { id: sharedArea.id })}
                          >
                            <div className="heading" style={{ gap:"10px" }}>
                              <img src={Document} />
                              <h4 className="bold-text" style={{ margin:"0px" }}>{sharedArea.name}</h4>
                            </div>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </>
            </TabPanel>
          </Box>
        </Box>
      </>
    );
  };

  ComplexArea = (t: any) => {
    return (
      <>
        <Box className="stat-boxes">
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Card>
                <p>{t("Complex Area")}</p>
                <h2 className="bold-text">
                  {Number(this.state.complexData.complexArea).toLocaleString() || ""}{" "}
                  {t(this.state.complexData.measurement || "")}
                </h2>
              </Card>
            </Grid>
            <Grid item sm={4}>
              <Card>
                <p>{t("Total Buildings")}</p>
                <h2 className="bold-text">{this.state.complexData.totalBuilding}</h2>
              </Card>
            </Grid>
            <Grid item sm={4}>
              <Card>
                <p>{t("Total Units")}</p>
                <h2 className="bold-text">{this.state.complexData.totalUnits}</h2>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  Building = (t: any) => {
    const userType = localStorage.getItem("userType");
    return (
      <>
        <Box className="building-list">
          <Card>
            <Box className="top-content">
              <Box className="heading">
                <h4 className="bold-text">{t("Buildings")}</h4>
              </Box>
              <TextField
                className="search-unit search-building"
                value={this.state.dataSearch}
                placeholder={t("Search by building name")}
                onChange={(e: any) => {
                  this.setState({ dataSearch: e.target.value }, () => {
                    this.handleBuildingSearch();
                  });
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Divider />
            <Box className="bottom-content">
              <Grid container spacing={2}>
                {this.state.searchData.length === 0 && <p>{t("No Building Available")}</p>}
                {this.state.searchData.map((building: any) => {
                  return (
                    <Grid item xs={4} key={building.building_management_id}>
                      <Link href={userType === "Security" ? "#" : `/Building/${building.building_management_id}`}>
                        <Box className="building-box">
                          <h5 className="bold-text">{building.building_name}</h5>
                        </Box>
                      </Link>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Card>
        </Box>
      </>
    );
  };

  MapDialog = (language: any, t: any) => {
    return (
      <>
        <Dialog
          className="edit-profile chairman-map-modal"
          open={this.state.isOpenMapModalOpen}
          scroll="paper"
          dir={languageCondition(language, "rtl", "ltr")}
          fullWidth
          maxWidth="sm"
        >
          <MuiDialogTitle disableTypography className="dialog-heading">
            <Typography variant="h6" className="bold-text">
              {t("Location")}
            </Typography>
            <IconButton onClick={() => this.handleMapModal()}>
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          {this.state.complexData.lat && this.state.complexData.long ? (
            <Box className="google-map-box">
              <GoogleMapReact
                defaultCenter={{
                  lat: this.state.complexData.lat,
                  lng: this.state.complexData.long,
                }}
                defaultZoom={15}
              >
                <LocationPin lat={this.state.complexData.lat} lng={this.state.complexData.long} />
              </GoogleMapReact>
            </Box>
          ) : (
            <Box className="no-google-map-box">{t("No Location Available")}</Box>
          )}
        </Dialog>
      </>
    );
  };

  ComplexDialog = (language: any, t: any) => {
    return (
      <>
        <Dialog
          className="edit-profile edit-complex-details"
          open={this.state.isEditBuildingModalOpen}
          scroll="paper"
          dir={languageCondition(language, "rtl", "ltr")}
          fullWidth
          maxWidth="md"
        >
          <MuiDialogTitle disableTypography className="dialog-heading">
            <Typography variant="h6" className="bold-text">
              {t("Edit Details")}
            </Typography>
            <IconButton onClick={() => this.handleEditComplexModal()}>
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <Formik
            enableReinitialize={true}
            initialValues={this.state.editForm}
            validationSchema={this.editComplexDetailValidation}
            onSubmit={(values, { resetForm }) => {
              this.handleEditComplexModal();
              this.handleSaveComplexDetails(values);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit} translate className="edit-complex-form">
                  <DialogContent dividers>
                    <Box className="profile-picture edit-complex-logo-box">
                      <img src={values.displayLogo} alt="profile-image" className="picture building" />
                      <p className="logo-text bold-text" onClick={() => this.uploadLogo.click()}>
                        {t("Change Logo")}
                      </p>
                      <input
                        style={{ display: "none" }}
                        ref={(ref: any) => (this.uploadLogo = ref)}
                        type="file"
                        accept="image/*"
                        className="complex-details-logo"
                        onChange={(e: any) => {
                          const file = e.target.files[0];
                          let reader = new FileReader();
                          reader.onloadend = () => {
                            setFieldValue("displayLogo", reader.result);
                            setFieldValue("logo", file);
                          };
                          reader.readAsDataURL(file);
                        }}
                        onBlur={handleBlur}
                        name="logo"
                      />
                      {ErrorHandler(t, touched.logo, errors.logo)}
                    </Box>
                    <Grid container spacing={2} className="edit-building edit-complex-upload-box">
                      <Grid item md={12}>
                        <InputLabel className="photo-label edit-complex-upload-label">{t("Upload Photos")}</InputLabel>
                        <Grid container spacing={4}>
                          <Grid item md={3} style={{ marginBottom: "5px" }}>
                            <Box className="upload-photo" onClick={() => this.uploadImages.click()}>
                              <img src={uploadbw} alt="edit-complex-upload-icon" />
                            </Box>
                            <input
                              multiple
                              className="complex-details-image"
                              onBlur={handleBlur}
                              name="photos"
                              ref={(ref: any) => (this.uploadImages = ref)}
                              style={{ display: "none" }}
                              type="file"
                              onChange={(e: any) => {
                                for (let file of e.target.files) {
                                  let reader = new FileReader();
                                  reader.onloadend = () => {
                                    values.photos = [...values.photos, reader.result];
                                    setFieldValue("photos", values.photos);
                                  };
                                  reader.readAsDataURL(file);
                                }
                              }}
                              accept="image/*"
                            />
                          </Grid>
                          {values.photos.map((image: any, index: number) => {
                            return (
                              <Grid item md={3} key={index} className="edit-complex-preview-box">
                                <Box className="building-image edit-complex-preview-images">
                                  <img
                                    src={del_image}
                                    className="delete-image"
                                    onClick={() => {
                                      const remainImage = values.photos.filter(
                                        (img: any, idx: number) => idx !== index
                                      );
                                      setFieldValue("photos", remainImage);
                                    }}
                                  />
                                  <img src={image} alt="" />
                                </Box>
                              </Grid>
                            );
                          })}
                        </Grid>
                        {ErrorHandler(t, touched.photos, errors.photos)}
                      </Grid>
                      <Grid item md={12}>
                        <InputLabel>{t("About Us")}</InputLabel>
                        <TextareaAutosize
                          className="about-us"
                          placeholder={t("About Us")}
                          value={values.aboutUs}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="aboutUs"
                        />
                        {ErrorHandler(t, touched.aboutUs, errors.aboutUs)}
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Complex Area")}</InputLabel>
                        <Input
                          className="input-with-icon"
                          fullWidth
                          placeholder={t("Complex Area")}
                          value={values.complexArea}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="complexArea"
                          startAdornment={
                            <InputAdornment position="start">
                              <img src={sizebw} alt="icon" />
                            </InputAdornment>
                          }
                        />
                        {ErrorHandler(t, touched.complexArea, errors.complexArea)}
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Area Measurement")}</InputLabel>
                        <Box className="measurement-box">
                          <img src={sizebw} alt="" />
                          <Select
                            className="select-with-icon"
                            fullWidth
                            value={values.measurement}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="measurement"
                          >
                            <MenuItem value="" disabled>
                              {t("Area Measurement")}
                            </MenuItem>
                            <MenuItem value="Sqft">{t("Sqft")}</MenuItem>
                            <MenuItem value="Sqm">{t("Sq m")}</MenuItem>
                          </Select>
                        </Box>
                        {ErrorHandler(t, touched.measurement, errors.measurement)}
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Location Latitude")}</InputLabel>
                        <Input
                          className="input-with-icon"
                          fullWidth
                          placeholder={t("Location Latitude")}
                          value={values.lat}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="lat"
                          startAdornment={
                            <InputAdornment position="start">
                              <img src={sizebw} alt="icon" />
                            </InputAdornment>
                          }
                        />
                        {ErrorHandler(t, touched.lat, errors.lat)}
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Location Longitude")}</InputLabel>
                        <Input
                          className="input-with-icon"
                          fullWidth
                          placeholder={t("Location Longitude")}
                          value={values.long}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="long"
                          startAdornment={
                            <InputAdornment position="start">
                              <img src={sizebw} alt="icon" />
                            </InputAdornment>
                          }
                        />
                        {ErrorHandler(t, touched.long, errors.long)}
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Total Buildings")}</InputLabel>
                        <Input
                          className="input-with-icon"
                          fullWidth
                          value={values.totalBuilding}
                          placeholder={t("Total Buildings")}
                          startAdornment={
                            <InputAdornment position="start">
                              <img src={floorIcon} alt="icon" />
                            </InputAdornment>
                          }
                          readOnly
                        />
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Total Units")}</InputLabel>
                        <Input
                          className="input-with-icon"
                          fullWidth
                          value={values.totalUnits}
                          placeholder={t("Total Units")}
                          startAdornment={
                            <InputAdornment position="start">
                              <img src={unitbw} alt="icon" />
                            </InputAdornment>
                          }
                          readOnly
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                    <Button className="cancel-button" onClick={() => this.handleEditComplexModal()}>
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" className="add-button" style={{ margin: "0" }}>
                      {t("Save")}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>
      </>
    );
  };

  handleEditButton = (userType: any, t: any) => {
    if(userType === "Security" || userType === "Auditor" || userType === "ServiceProvider") {
      if (userType === "ServiceProvider") {
        return (
          <ComplexDropdown />
        )
      }
    } else {
      return (
        <Grid item xs={12} sm={2}>
          <Button
            className="edit-button"
            variant="contained"
            color="primary"
            onClick={() => this.openEditBuildingModal()}
          >
            {t("Edit Details")}
          </Button>
        </Grid>
      )
    }
  }

  render() {
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    const userType = localStorage.getItem("userType");

    return (
      <>
        <Loader loading={this.state.loading} />

        <GeneralHeaderComponentWeb>
              <Container className={classes.building}>
                <Box style={dashBoard.navigation}>
                  <Box>
                    <Typography variant="body1">
                      <Link href={`/Complex`}>{t("Complex & Apartments")}</Link> /{" "}
                      <Link href={`/Complex`}>
                        <Box component="span" style={{ color: "blue" }}>
                          {t("Complex")}
                        </Box>
                      </Link>
                    </Typography>
                  </Box>
                </Box>

                <Box>
                  <Grid container style={dashBoard.gaMemberMain}>
                    <Grid item xs={6}>
                      <Typography variant="h5" style={dashBoard.subHeading} className="bold-text">
                        {t("Complex")}
                      </Typography>
                    </Grid>
                    {this.handleEditButton(userType, t)}
                  </Grid>
                </Box>

                <Box className="building-info">
                  <Card>
                    <Box className="building-info-top">
                      <Box className="building-info-left">
                        <img src={this.state.complexData.logo} alt="" />
                        <Box className="building-name-country">
                          <h4 className="bold-text">{this.state.complexData.complexName}</h4>
                          <p>{this.state.complexData.city || "-"}</p>
                        </Box>
                      </Box>
                      <Box className="building-info-right" onClick={() => this.handleMapModal()}>
                        <img src={location} alt="|" />
                        <span className="bold-text">{t("See building on map")}</span>
                      </Box>
                    </Box>
                    <Box className="building-info-bottom">
                      {this.state.complexData.photos.length > 0 && (
                        <>
                          <Slider ref={(c: any) => (this.slider = c)} {...settings}>
                            {this.state.complexData.photos.map((image: any, index: number) => {
                              return (
                                <div
                                  className="slider-image-box"
                                  key={index}
                                  onClick={() => this.setState({ imageBox: true, photoIndex: index })}
                                >
                                  <img src={image.url} alt="" />
                                </div>
                              );
                            })}
                          </Slider>
                          <Box className="slick-bottom">
                            <Box className="button prev" onClick={this.previousImage}>
                              <img src={previousIcon} alt="" />
                            </Box>
                            <Box className="button next" onClick={this.nextImage}>
                              <img src={nextIcon} alt="" />
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Card>
                </Box>

                {this.state.imageBox && this.state.complexData.photos.length > 0 && (
                  <Lightbox
                    imagePadding={120}
                    mainSrc={this.state.complexData.photos[this.state.photoIndex].url}
                    nextSrc={
                      this.state.complexData.photos[(this.state.photoIndex + 1) % this.state.complexData.photos.length]
                        .url
                    }
                    prevSrc={
                      this.state.complexData.photos[
                        (this.state.photoIndex + this.state.complexData.photos.length - 1) %
                          this.state.complexData.photos.length
                      ].url
                    }
                    onCloseRequest={() => this.setState({ imageBox: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex:
                          (this.state.photoIndex + this.state.complexData.photos.length - 1) %
                          this.state.complexData.photos.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (this.state.photoIndex + 1) % this.state.complexData.photos.length,
                      })
                    }
                  />
                )}

                <Box className="about-building">
                  <Card>
                    <h4 className="bold-text">{t("About Complex")}</h4>
                    <p>{this.state.complexData.aboutUs || "-"}</p>
                  </Card>
                </Box>

                {this.Building(t)}

                {this.ComplexArea(t)}

                {this.DocumentsSharedArea(t)}
              </Container>
        </GeneralHeaderComponentWeb>

        {this.ComplexDialog(language, t)}

        {this.MapDialog(language, t)}
      </>
    );
  }
}

//@ts-ignore
export default withTranslation()(withStyles(BuildingApartmentStyle)(Complex));

const dashBoard = {
  navigation: {
    display: "flex",
    justifyContent: "space-between",
  },
  subHeading: {
    fontWeight: 600,
    margin: 0,
  },
  buildingCount: {
    color: "#FC8434",
    fontWeight: 600,
    marginTop: 15,
  },
  buildingCard: {
    color: "#FC8434",
    fontWeight: 600,
    marginTop: 15,
    border: "1px solid #E4E4E4",
    borderRadius: 10,
    padding: 12,
  },
  tabLabel: {
    color: "#FC8434",
    fontWeight: 600,
  },
  YearMain: {
    background: "#fff",
    border: "none",
    borderRadius: 5,
    padding: 5,
  },
  SideBar: {
    background: "#f9f6f6",
    position: "relative",
    paddingBottom: 150,
  },
  searchButton: {
    margin: 8,
  },
  backColor: {
    backgroundColor: "#2D6EED",
    padding: "9px 16px",
  },
  boxStyling: {
    display: "flex",
    alignItems: "center",
    marginTop: 20,
  },
  gaMemberMain: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    justifyContent: "space-between",
  },
  viewMore: {
    marginTop: 15,
    textDecoration: "underline",
    color: "#E5B08D",
    fontWeight: 600,
  },
  relatedMemberCard: {
    display: "grid",
    gridTemplateColumns: "3fr 3fr 3fr",
    gap: 20,
  },
  BuildingListCard: {
    display: "grid",
    gridTemplateColumns: "3fr 3fr 3fr",
    gap: 20,
    padding: 25,
  },
  profileImage: {
    borderRadius: "100%",
    width: 70,
    height: 70,
    margin: "35px auto",
  },
  userType: {
    backgroundColor: "aliceblue",
    borderRadius: 30,
    display: "inline-block",
    padding: "3px 20px",
    color: "#2D6EED",
    fontWeight: 600,
  },
  unitno: {
    marginTop: 15,
    fontWeight: 600,
    textAlign: "center",
  },
  contactIcon: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: 15,
  },
  commonDisplay: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardStyle: {
    borderRadius: 10,
    maxWidth: 345,
  },
  cursorPointer: {
    cursor: "pointer",
  },
  managementPaper: {
    padding: 20,
    borderRadius: 10,
  },
  TableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0px 20px 0px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  labelsStyle: {
    color: "#212121",
    margin: "10px 0px 10px 0px",
  },
  paper: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    // boxShadow: theme.shadows[5],
    padding: "16px 32px 24px",
    width: "700px",
  },
  commonFont: {
    fontWeight: 600,
  },
  inviteInput: {
    padding: "18px 18px 18px 50px",
    color: "#b5b5b5",
    borderRadius: "10px",
    border: "1px solid #e9dede",
    backgroundColor: "#f9f9f9",
    fontSize: "16px",
    outline: 0,
    width: "100%",
  },
  formLeftIcn: {
    position: "absolute",
    left: 20,
    top: 44,
    color: "#b9b9b9",
  },
};
// Customizable Area End
