import React from "react";
// Customizable Area Start
import {
    Box, Typography,
    FormControl, Select, MenuItem, Grid, Button
} from "@material-ui/core";
import {Formik, Form, Field, ErrorMessage} from "formik";
import {alphaNumericOnly} from "../../../../components/src/KeyBlockFunctions";
import * as Yup from 'yup';
//@ts-ignore
import CompEmpBuildingBlockStep4Controller from "./CompEmpBuildingBlockStep4Controller.web";
import "../../assets/commonForm.css"
// Customizable Area End

export default class CompEmpBuildingBlockStep4 extends CompEmpBuildingBlockStep4Controller {
    validationSchema = Yup.object().shape({
        locationLink: Yup.string().required('Location Link is required'),
        country: Yup.string().required('Country is required'),
        state: Yup.string().required('State is required'),
        city: Yup.string().required('City is required'),
        zipCode: Yup.string().min(6, 'Zip Code must be at least 6 characters')
            .max(7, 'Zip Code must be at most 7 characters').required('Zip Code is required'),
        landmark: Yup.string().required('Landmark is required'),
        streetName: Yup.string().required('Street Name is required'),
        ...(this.props.accountType !== "account_manager"
            ? {
                address: Yup.string().required('Adress is required'),
            }
            : {}),
    });

    render() {
        // Customizable Area Start
        const {t} = this.props;

        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <Formik
                    initialValues={this.props.locationValues}
                    validationSchema={this.validationSchema}
                    validateOnMount={true}
                    enableReinitialize={true}
                    data-testid="locationFormik"
                    onSubmit={(values) => {
                        this.props.handleLocationDetails(values)
                    }}
                >
                    {({values, touched, errors, isValid, setFieldValue, handleChange}) => (
                        <Form translate="yes" className="commonForms">
                            <Box>
                                <Box>
                                    <Box style={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}>
                                        <Box>
                                            <Typography style={{fontWeight: 'bold', fontSize: '22px',}}
                                                        className="Location bold-text">{t("Location")}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography style={{fontSize: '16px'}}
                                                        className="steplocation">{t("Step")} {this.props.stepBuilding + 1} {t("of 7")}</Typography>
                                        </Box>
                                    </Box>
                                    <Box style={{marginTop: '30px'}}>
                                        <Box style={{display: 'flex'}}>
                                            <Box className="formGroups customSelect "
                                                 style={{marginRight: '25px', width: '50%'}}>
                                                <Typography className="typographyLable ">{t("Location Link")}</Typography>
                                                <Box>
                                                    <Box className="formGroups " style={{
                                                        border: "1px solid #F0F0F0",
                                                        marginBottom: "0px",
                                                        borderRadius: "8px",
                                                        backgroundColor: "#F0F0F0"
                                                    }}>
                                                        <Field type="text" name="locationLink"
                                                               placeholder="Location Link" className="formInputs"
                                                               data-testid="fieldChange"
                                                               onChange={
                                                                   (e: any) => {
                                                                       handleChange(e)
                                                                       this.props.handleLocationFieldChange('locationLink', e.target.value)
                                                                   }
                                                               }/>
                                                    </Box>
                                                    <Box style={webStyle.errorMsg}>
                                                        <ErrorMessage name="locationLink" component="div"
                                                                      data-testid="locationLink-error"/></Box>
                                                </Box>
                                            </Box>
                                            <Box className="customSelect selectFieldNew1"
                                                 style={{marginRight: '', width: '50%'}}>
                                                <Typography className="typographyLable City selectFieldNew1">
                                                    {t("Country")}
                                                </Typography>
                                                <FormControl variant="outlined">
                                                    <Field as={Select} name="country"
                                                           labelId="demo-simple-select-outlined-label"
                                                           className={`${values.country !== '' ? 'estate_select_color_building_value' : 'estate_select_color_building'} City step4CountryField customSelect selectFieldNew1 fieldChange`}
                                                           style={{backgroundColor: "#F0F0F0", borderRadius: '8px'}}
                                                           data-testid="fieldChange"
                                                           displayEmpty
                                                           onChange={(e: any) => {
                                                               handleChange(e)
                                                               this.props.handleLocationFieldChange('country', e.target.value)
                                                           }}
                                                    >
                                                        <MenuItem disabled value="">{t("Select Country")}</MenuItem>
                                                        {this.props.countriesList.length && this.props.countriesList.map((variant: any, i: number) => (
                                                            <MenuItem className="step4CountryMenu selectFieldNew1"
                                                                      value={variant} key={i}>
                                                                {variant}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </FormControl>
                                                <Box style={webStyle.errorMsg}>
                                                    <ErrorMessage name="country" component="div"
                                                                  data-testid="country-error"/></Box>
                                            </Box>
                                        </Box>
                                        <Box style={{marginTop: '20px', display: 'flex'}}>
                                            <Box className="formGroups customSelect selectFieldNew2"
                                                 style={{marginRight: '25px', width: '50%',}}>
                                                <Typography className="typographyLable Sateor selectFieldNew2">
                                                    {t("State")}
                                                </Typography>
                                                <FormControl variant="outlined">
                                                    <Field as={Select} name="state"
                                                           labelId="demo-simple-select-outlined-label"
                                                           className={`${values.state !== '' ? 'estate_select_color_building_value' : 'estate_select_color_building'} City step4CountryField customSelect selectFieldNew2 fieldChange1`}
                                                           data-testid="fieldChange"
                                                           style={{backgroundColor: "#F0F0F0", borderRadius: '8px'}}
                                                           displayEmpty
                                                           onChange={(e: any) => {
                                                               handleChange(e)
                                                               this.props.handleLocationFieldChange('state', e.target.value)
                                                           }}
                                                    >
                                                        <MenuItem disabled value="">{t("Select State")}</MenuItem>
                                                        {this.props.countriesList.length && this.props.countriesList.map((variant: any, i: number) => (
                                                            <MenuItem className="step4CountryMenu selectFieldNew2"
                                                                      value={variant} key={i}>
                                                                {variant}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </FormControl>
                                                <Box style={webStyle.errorMsg}>
                                                    <ErrorMessage name="state" component="div"
                                                                  data-testid="state-error"/></Box>
                                            </Box>
                                            <Box className="formGroups customSelect selectFieldNew3"
                                                 style={{width: '50%'}}>
                                                <Typography className="typographyLable selectFieldNew3 ">
                                                    City
                                                </Typography>
                                                <FormControl variant="outlined">
                                                    <Field as={Select} name="city"
                                                           labelId="demo-simple-select-outlined-label"
                                                           className={`${values.city !== '' ? 'estate_select_color_building_value' : 'estate_select_color_building'} City step4CountryField customSelect selectFieldNew3 fieldChange2`}
                                                           style={{backgroundColor: "#F0F0F0", borderRadius: '8px'}}
                                                           data-testid="fieldChange"
                                                           displayEmpty
                                                           onChange={(e: any) => {
                                                               handleChange(e)
                                                               this.props.handleLocationFieldChange('city', e.target.value)
                                                           }}
                                                    >
                                                        <MenuItem disabled value="">Select City</MenuItem>
                                                        {this.props.countriesList.length && this.props.countriesList.map((variant: any, i: number) => (
                                                            <MenuItem className="step4CountryMenu selectFieldNew3"
                                                                      value={variant} key={i}>
                                                                {variant}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </FormControl>
                                                <Box style={webStyle.errorMsg}>
                                                    <ErrorMessage name="city" component="div" data-testid="city-error"/></Box>
                                            </Box>
                                        </Box>
                                        <Box className="step4" style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            marginTop: '20px',
                                        }}>
                                            <Box className="formGroups customSelect" style={{width: '49%',}}>
                                                <Typography className="typographyLable ZipCode">{t("Zip Code")}</Typography>
                                                <Box>
                                                    <Box className="formGroups ZipCode" style={{
                                                        marginBottom: "0px",
                                                        border: "1px solid #F0F0F0",
                                                        borderRadius: "8px",
                                                        backgroundColor: "#F0F0F0"
                                                    }}>
                                                        <Field type="text" placeholder="Zip Code" name="zipCode"
                                                               className="formInputs"
                                                               data-testid="fieldChange"
                                                               onChange={
                                                                   (e: any) => {
                                                                       handleChange(e)
                                                                       this.props.handleLocationFieldChange('zipCode', e.target.value)
                                                                   }
                                                               }/>
                                                    </Box>
                                                    <Box style={webStyle.errorMsg}>
                                                        <ErrorMessage name="zipCode" component="div"
                                                                      data-testid="zipCode-error"/>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box className="formGroups customSelect" style={{width: '49%'}}>
                                                <Typography className="typographyLable Landmark">{t("Landmark")}</Typography>
                                                <Box>
                                                    <Box className="formGroups Landmark" style={{
                                                        marginBottom: "0px",
                                                        borderRadius: "8px",
                                                        backgroundColor: "#F0F0F0",
                                                        border: "1px solid #F0F0F0",
                                                    }}>
                                                        <Field
                                                            onKeyDown={alphaNumericOnly}
                                                            className="formInputs Landmark" name="landmark" type="text"
                                                            placeholder={t("Landmark")}
                                                            data-testid="fieldChange"
                                                            onChange={
                                                                (e: any) => {
                                                                    handleChange(e)
                                                                    this.props.handleLocationFieldChange('landmark', e.target.value)
                                                                }
                                                            }/>
                                                    </Box>
                                                    <Box style={webStyle.errorMsg}>
                                                        <ErrorMessage name="landmark" component="div"
                                                                      data-testid="landmark-error"/>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box style={{width: '100%', marginTop: '20px'}}>
                                            <Typography className="typographyLable Street">{t("Street Name")}</Typography>
                                            <Box className="formGroups Street " style={{
                                                marginBottom: "0px",
                                                border: "1px solid #F0F0F0",
                                                borderRadius: "8px",
                                                backgroundColor: "#F0F0F0"
                                            }}>
                                                <Field onKeyDown={alphaNumericOnly} name="streetName" type="text"
                                                       placeholder={t("Street Name")} className="formInputs"
                                                       data-testid="fieldChange"
                                                       onChange={
                                                           (e: any) => {
                                                               handleChange(e)
                                                               this.props.handleLocationFieldChange('streetName', e.target.value)
                                                           }
                                                       }/>
                                            </Box>
                                            <Box style={webStyle.errorMsg}>
                                                <ErrorMessage name="streetName" component="div"
                                                              data-testid="streetName-error"/></Box>
                                        </Box>

                                        {
                                            this.props.accountType !== "account_manager" ?
                                                <Box style={{width: '100%', marginTop: '20px'}}>
                                                    <Typography className="typographyLable Street">{t("Official Address")}</Typography>
                                                    <Box className="formGroups Street " style={{
                                                        marginBottom: "0px",
                                                        border: "1px solid #F0F0F0",
                                                        borderRadius: "8px",
                                                        backgroundColor: "#F0F0F0"
                                                    }}>
                                                        <Field onKeyDown={alphaNumericOnly} name="address" type="text"
                                                               placeholder="Official Address" className="formInputs"
                                                               data-testid="fieldChange"
                                                               onChange={
                                                                   (e: any) => {
                                                                       handleChange(e)
                                                                       this.props.handleLocationFieldChange('address', e.target.value)
                                                                   }
                                                               }/>
                                                    </Box>
                                                    <Box style={webStyle.errorMsg}>
                                                        <ErrorMessage name="address" component="div"
                                                                      data-testid="address-error"/></Box>
                                                </Box> : <></>
                                        }

                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{display: 'flex', justifyContent: "end", flexWrap: 'wrap'}}
                                 className="buildingBlockStep4"><Grid style={{marginTop: "30px", marginRight: '20px'}}
                                                                      className="buildingBlockStep4">
                                <Box style={{}} className="buildingBlockStep4">
                                    <Button className="buildingBlockStep4" style={{
                                        color: "#2B6FED",
                                        fontWeight: 'bold',
                                        padding: '0px 20.5px',
                                        textTransform: 'capitalize',
                                        width: '177px',
                                        height: '56px',
                                        border: '1px solid #2B6FED',
                                        borderRadius: "8px",
                                        display: 'flex',
                                        justifyContent: 'center',
                                        textAlign: 'center'
                                    }} onClick={this.props.handleBackBuilding}><span
                                        className="bold-text">{t("PREV")}</span></Button>
                                </Box>
                            </Grid>
                                <Box className="buildingBlockStep4"><Grid style={{marginTop: "30px",}}
                                                                          className="buildingBlockStep4">
                                    <Button className="buildingBlockStep4" style={{
                                        width: '177px',
                                        height: '56px',
                                        backgroundColor: '#2B6FED',
                                        borderRadius: "8px",
                                        display: 'flex',
                                        justifyContent: 'center',
                                        textAlign: 'center',
                                        color: "#FFFFFF",
                                        fontWeight: 'bold',
                                        padding: '0px 20.5px',
                                        textTransform: 'capitalize'
                                    }} data-test-id="handleNextComplex" type="submit"><span
                                        className="bold-text">{t("NEXT")}</span></Button>
                                </Grid>
                                </Box>
                            </Box>
                        </Form>)}
                </Formik>
            </>

            // Customizable Area End
        );
    }
}

// export default withTranslation()(withRouter(CompEmpComplexBuildingBlock));
// Customizable Area Start
const webStyle = {
    errorMsg: {
        color: 'red',
        fontSize: '12px',
        marginTop: '5px'
    },
}
// Customizable Area End