import React from "react";
// Customizable Area Start
import {
    Box,
    InputLabel,
    Button,
    Grid, Typography, MenuItem, FormControl, Select,
} from "@material-ui/core";
import { profile } from "../assets"
//@ts-ignore
import CompEmpComplexBlockStep1Controller from './CompEmpComplexBlockStep1Controller.web'
import "../../assets/commonForm.css"
// Customizable Area End

export default class CompEmpComplexBlockStep1 extends CompEmpComplexBlockStep1Controller {
    render() {
        // Customizable Area Start
        const {t} = this.props;
        
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
            <Box>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box >
                                            <Typography className="Basic bold-text" style={{ fontSize: '22px', fontWeight: 'bold' }}>{t("Jurisdiction")}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography style={{ fontSize: '16px' }}>{t("Step")} {this.props.stepComplex + 1} {t("of 7")}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="formGroups customSelect juridNewSelect" style={{ marginRight: '25px', width: '100%', marginTop: '20px', }}>
                                        <Typography className="typographyLable">
                                            {t("Jurisdiction")}
                                        </Typography>
                                        <FormControl variant="outlined"  >
                                        <InputLabel id="demo-simple-select-label">{t("Select Jurisdiction")}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                onChange={this.props.handleChangeComplex}
                                                data-testid="juridselect"
                                                className="djhdhd "
                                                renderValue={(selected: any) => selected?.attributes.jurisdiction_name}
                                                value={this.props.jurisdictionSelected}
                                                id="juridselect"
                                               style={{backgroundColor:"#F0F0F0"}}
                                                defaultValue={{ id: 0,attributes:{ jurisdiction_name: t("Select Jurisdiction")} }}
                                                
                                            >
                                                <MenuItem disabled value="">
                                                    {t("Select Jurisdiction")}
                                                </MenuItem>
                                                {this.props.jurisdictionList.map((variant: any) => (
                                                    <MenuItem key={variant.attributes.id} 
                                                    //@ts-ignore
                                                    value={variant}>
                                                        {variant.attributes.jurisdiction_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', width: '80%', marginTop: '25px' }}>
                                            <Grid container spacing={3}>
                                                <Grid item xs>
                                                    <Typography className="typographyLable">{t("Currency")}</Typography>
                                                    <Box className="fontSizeBox">
                                                        {
                                                            this.props.jurisdictionSelected ? this.props.jurisdictionSelected.attributes?.currency : 'N/A'
                                                        }
                                                    </Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography className="typographyLable">{t("Timezone")}</Typography>
                                                    <Box className="fontSizeBox">{
                                                            this.props.jurisdictionSelected ? this.props.jurisdictionSelected.attributes?.timezone : 'N/A'
                                                        }</Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography className="typographyLable">{t("Unit of Measurement")}</Typography>
                                                    <Box className="fontSizeBox">{
                                                            this.props.jurisdictionSelected ? this.props.jurisdictionSelected.attributes?.unit_of_measurement : 'N/A'
                                                        }</Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', width: '80%', marginTop: '25px' }}>
                                            <Grid container spacing={3}>
                                                <Grid item xs>
                                                    <Typography className="typographyLable">{t("Management Fee Calculation Formula")}</Typography>
                                                    <Box className="fontSizeBox">{
                                                            this.props.jurisdictionSelected ? this.props.jurisdictionSelected.attributes?.formula : 'N/A'
                                                        }</Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography className="typographyLable">{t("Mobile number length")}</Typography>
                                                    <Box className="fontSizeBox">{
                                                            this.props.jurisdictionSelected ? <>{this.props.jurisdictionSelected.attributes?.mobile_number_length} {t("Digits")}</> : 'N/A'
                                                        }</Box>
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography className="typographyLable">{t("Default Renting Contract")}</Typography>
                                                    <Box className="" style={{ color: '#2B6FED', fontSize: '16px' }}>{
                                                            this.props.jurisdictionSelected ? this.props.jurisdictionSelected.attributes.currency : 'N/A'
                                                        }</Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box style={{ display: 'flex', justifyContent: "end", flexWrap: 'wrap' }}>
                <Box>
                <Grid className="step1Grid" style={{ marginTop: "30px", }}>
                            <Button className="step1Next" style={{ width: '177px', height: '56px', backgroundColor: '#2B6FED', borderRadius: "8px", display: 'flex', justifyContent: 'center', textAlign: 'center', color: "#FFFFFF", fontWeight: 'bold', padding: '0px 20.5px', textTransform: 'capitalize' }} disabled={!this.props.jurisdictionSelected} data-testid="step1next" onClick={this.props.handleNext}><span className="bold-text">{t("NEXT")}</span></Button>
                        </Grid>
                </Box>
            </Box>
                                </>

            // Customizable Area End
        );
    }
}

// export default withTranslation()(withRouter(CompEmpComplexBuildingBlock));
// Customizable Area Start
const webStyle = {
    buttonSave: {
        width: '177px',
        height: '56px',
        backgroundColor: '#2B6FED',
        borderRadius: "8px",
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        color: "#FFFFFF",
        fontWeight: 'bold',
        padding: '0px 20.5px',
        textTransform: 'capitalize'
    },
    step6:{
        margin: '11px 0px',
        color: 'black',
        fontSize: '16px', 
        fontWeight: 400
    }
}
// Customizable Area End