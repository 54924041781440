//Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { Message } from "../../../../framework/src/Message";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
  companyId: number
}

interface S {
  companyDetails:any,
  companiesList:any,
  countriesList:any,
  loading:boolean,
  cityList:any,
  regionList:any,
  statusList:any,
  companyFilter:string,
  countryFilter:string,
  cityFilter:string,
  regionFilter:string,
  statusFilter:string,
  deleteConfirmModal: boolean,
  tabNo: number,
  proofFileName: any,
  reasonFlag: boolean,
  registerModal:any,
  page:number,
  pagination:any,
  mapSwitchEstate: boolean,
  accountType:string,
  reasonConfirmModal: boolean;
  reasonText: string;
  accountManagerList:any,
  accountManagerFilter:any,
}

interface SS {
  id: any;
}

export default class RealEstateCompanyDetailsCardController extends CommonApiCallForBlockComponent<Props, S, SS> {
  GetCompaniesFilterList: any;
  GetCountriesFilterList: any;
  GetRegionsFilterList: any;
  GetCityFilterList:any;
  GetStatusFilterList:any;
  GetAccountManagerFilterCardList:any;
  // GetFilterApi:any;
  GetCompanyDetails:any;
  DeleteCompany: any;
  
  constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
      this.handleDeleteModal = this.handleDeleteModal.bind(this);
      this.handleTabChange = this.handleTabChange.bind(this)
      this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this)
      this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.SessionResponseMessage),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.SessionSaveMessage),
      ];
      this.state = {
        companyDetails:null,
        proofFileName: null,
        loading:false,
        companiesList:[],
        countriesList:[],
        cityList:[],
        regionList:[],
        statusList:[],
        accountManagerList:[],
        registerModal: false,
        accountManagerFilter:'',
        companyFilter:'',
        countryFilter:'',
        cityFilter:'',
        regionFilter:'',
        statusFilter:'',
        accountType: '',
        reasonText:'',
        reasonFlag:false,
        page:1,
        pagination:{
          current_page:1,
          total_count:1,
          total_pages:1,
        },
        mapSwitchEstate: false,
        deleteConfirmModal: false,
        reasonConfirmModal:false,
        tabNo: 0
      };
      runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async componentDidMount() {
    let filterTable = [
      {
        name: 'GetCompaniesFilterList',
        endPoint: 'all_company_name'
      },
      {
        name: 'GetCountriesFilterList',
        endPoint: 'list_country_name',
      },
      {
        name: 'GetCityFilterList',
        endPoint: 'list_cities',
      },
      {
        name: 'GetRegionsFilterList',
        endPoint: 'list_region',
      },
      {
        name: 'GetStatusFilterList',
        endPoint: 'list_status',
      }
    ]

    const accountRole = localStorage.getItem("role")

    if (accountRole) {
      const accountRoleNameCard = accountRole.replace(/"/g, '')
      this.setState({ accountType: accountRoleNameCard })

      if (accountRoleNameCard==='coo') {
        filterTable = [...filterTable, 
          {
            name: 'GetAccountManagerFilterCardList',
            endPoint: 'account_manager_list',
          }
        ]
      }
    }

    filterTable.forEach((filter) => {
      this.getFilterList(filter.name, `/bx_block_real_estate_companies/${filter.endPoint}`)
    })
    this.getCompanyDetails()
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      let errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      switch (apiRequestCallId) {
        case this.GetCompaniesFilterList:
          this.handleFiltersList(responseJson, 'companiesList', 'company_list')
          break;
        case this.GetCountriesFilterList:
          this.handleFiltersList(responseJson, 'countriesList', 'country_list')
          break;
        case this.GetCityFilterList:
          this.handleFiltersList(responseJson, 'cityList', 'city_list')
          break;
        case this.GetRegionsFilterList:
          this.handleFiltersList(responseJson, 'regionList', 'region_list')
          break;
        case this.GetStatusFilterList:
          this.handleFiltersList(responseJson, 'statusList', 'status')
          break;
        case this.GetAccountManagerFilterCardList:
          this.handleFiltersList(responseJson, 'accountManagerList', 'account_managers')
          break;
        // case this.GetFilterApi:
        //   this.handleCompaniesFilterSearch(responseJson)
        //   break;
        case this.GetCompanyDetails:
          this.handleCompanyDetails(responseJson)
          break;
        case this.DeleteCompany:
          this.handleDeleteSuccess(responseJson)
          break;
        default:
          break;
      }
      this.setState({loading:false})

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  handleVistorPaginationEstate = (e:any,value:any) => {
    this.setState({
      page:value
    })
  }

  handleMapSwitchEstate = (e:any, val:boolean) => {
    this.setState({
      mapSwitchEstate: val
    })
  }

  handleEditClick = () => {
    this.props.navigation.history.push(`/CompanyEmployee/realestate-edit-company/${this.props.companyId}`)
  }

  handleTabChange = (event: any, newValue: number) => {
    this.setState({tabNo: newValue})
  }

  handleOpenReasonPopup = () => {
    this.setState({
      deleteConfirmModal: false,
      reasonConfirmModal: true
    })
  }

  handleReasonText = (e:any) => {
    this.setState({
      reasonText: e.target.value,
      reasonFlag:false
    })
  }

  handleCloseReasonPopup = () => {
    this.setState({
      reasonConfirmModal: false
    })
  }

  handleDeleteModal (open: boolean) {
    this.setState({
      deleteConfirmModal:open
    })
  }

  handleCloseRegisterModal = () => {
    this.setState({registerModal: false})
  }

  handleOpenRegisterModal = () => {
    this.setState({registerModal: true})
  }

  handleCloseDeleteModal = () => {
    this.setState({
      deleteConfirmModal:false
    })
  }

  // handleCompaniesFilterSearch = (responseJson: any) => {
    // console.log('search', responseJson)
    // if (responseJson) {
      // this.setState({ companies: responseJson.real_estate_companies.data, 
      //   currentPage: responseJson.meta.pagination.current_page,
      //   totalCount: responseJson.meta.pagination.total_count,
      //   totalPages: responseJson.meta.pagination.total_pages
      // })
    // }
  // };

  handleCompanyDetails = (responseJson: any) => {
    if (responseJson) {
      const fileName = responseJson.data.attributes.registration_certificate?.url
      if (fileName) {
        const splitFlieName = fileName.split("/")
        this.setState({ proofFileName: splitFlieName[splitFlieName.length - 1] })
      }
      this.setState({ companyDetails: responseJson.data })
    }
  };

  handleFilterSubmit = (event:any) => {
    // event.preventDefault()
    // this.getCompaniesSearchFilter()
  }

  handleDeleteSuccess = (responseJson:any) => {
    if (responseJson) {
      this.setState({deleteConfirmModal: false})
      setTimeout(() => {
        this.props.navigation?.history?.push('/CompanyEmployee/realestate-companies')
      }, 2000)
    }
  }

  handleFiltersList = (responseJson:any, stateParam: string, resParam: string) => {
    if (responseJson) {
      this.setState({ ...this.state, [stateParam]: responseJson[resParam] })
    }
  }

  handleFilterChange = (event:any, stateParam: string) => {
    this.setState({ ...this.state, [stateParam]: event.target.value})
  }

  handleDelete = async () => {
    if (!this.state.reasonText) {
      this.setState({ reasonFlag: true })
    } else {
      const companyId = this.props.companyId;
      this.setState({loading:true})
      this.DeleteCompany = await apiCall({
        contentType: "application/json",
        method: "DELETE",
        endPoint: `/bx_block_real_estate_companies/real_estate_companies/${companyId}?deletion_reason=${this.state.reasonText}`,
      });
    }
  }

  getCompanyDetails = async () => {
    const companyId = this.props.companyId;
    this.setState({loading:true})
    this.GetCompanyDetails = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `/bx_block_real_estate_companies/real_estate_companies/${companyId}`,
    });
  };

  // getCompaniesSearchFilter = async () => {
  //   this.setState({loading:true})
  //   this.GetFilterApi = await apiCall({
  //     contentType: "application/json",
  //     method: "GET",
  //     endPoint: `bx_block_real_estate_companies/filter?company_name=${this.state.companyFilter.trim()}
  //     &country=${this.state.countryFilter.trim()}&city=${this.state.cityFilter.trim()}&region=${this.state.regionFilter.trim()}
  //     &status=${this.state.statusFilter.trim()}`,
  //   });
  // };

  getFilterList = async (method: string, endPoint: string) => {
    this.setState({loading:true})
    //@ts-ignore
    this[method] = await apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint,
    });
  }
}
// Customizable Area End

