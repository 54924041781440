// Customizable Area Start
import React from "react";
import {
  Container,
  Typography,
  Button,
  withStyles,
  Card,
  Link,
  Dialog,
  IconButton,
  DialogContent,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  InputAdornment,
  DialogActions,
  Input,
  TextareaAutosize,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CEActionsTeamController, { Props } from "./CEActionsTeamController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SearchIcon from "@material-ui/icons/Search";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { CEActionsMeStyleWeb } from "./CEActionsMe.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { Form, Formik } from "formik";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import { CEBuildingIcon, CECalendarIcon, CERealEstateIcon, CEUserIcon } from "../assets";
import moment from "moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Customizable Area End

class CEActionsTeam extends CEActionsTeamController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    const is_mobile = !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />
        <CEHeaderComponentWeb>
          <Container className={classes.generalDashboard}>
            <Box className="navigation action-header-box">
              <Typography variant="body1">
                <Link href="/CompanyEmployee/dashboard">{t("My Dashboards")}</Link> /{" "}
                <Box component="span" style={{ color: "blue" }}>
                  {t("Actions assigned to my team")}
                </Box>
              </Typography>
              <Box className="sub-heading-box">
                <Typography variant="h5" className="bold-text">
                  {t("Actions assigned to my team")}
                </Typography>
                <Box className="action-heading-btn-box">
                  {localStorage.getItem("role") !== "account_manager" && (
                    <Button className="action-progress-btn" onClick={() => this.props.navigation.navigate("ActionAssignProgress")}>
                      {t("View action progress")}
                    </Button>
                  )}
                  <Button className="action-assign-btn" onClick={() => this.handleActionAssignModal()}>
                    {t("Assign action")}
                  </Button>
                </Box>
              </Box>
              <Box className="filter-head-box action-team-filter-box">
                <Select
                  displayEmpty
                  className="select-year action-select-real-estate"
                  onChange={(e: any) => this.setState({ reactEstate: e.target.value })}
                  value={this.state.reactEstate}
                  IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                >
                  <MenuItem value="">{t("Select Real Estate Company")}</MenuItem>
                  {this.state.teamReactStateList.map((realEstate: any) => {
                    return (
                      <MenuItem value={realEstate.id} key={realEstate.id}>
                        {realEstate.company_name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Select
                  value={this.state.complex}
                  IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                  onChange={(e: any) => this.setState({ complex: e.target.value })}
                  displayEmpty
                  className="select-year action-select-complex"
                >
                  <MenuItem value="">{t("Select Complex")}</MenuItem>
                  {this.state.teamFilterComplexList.map((complex: any) => {
                    return (
                      <MenuItem value={complex.id} key={complex.id}>
                        {complex.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Select
                  value={this.state.building}
                  displayEmpty
                  IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                  className="select-year action-select-building"
                  onChange={(e: any) => this.setState({ building: e.target.value })}
                >
                  <MenuItem value="">{t("Select Building")}</MenuItem>
                  {this.state.teamBuildingList.map((building: any) => {
                    return (
                      <MenuItem value={building.id} key={building.id}>
                        {building.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Select
                  onChange={(e: any) => this.setState({ assign: e.target.value })}
                  value={this.state.assign}
                  className="select-year action-select-assign-by"
                  IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                  displayEmpty
                >
                  <MenuItem value="">{t("Assign By")}</MenuItem>
                  {this.state.teamAssignList.map((user: any) => {
                    return (
                      <MenuItem value={user.id} key={user.id}>
                        {user.full_name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Button
                  className="action-search-btn action-team-search-filter"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      filterValue: {
                        building: this.state.building,
                        reactEstate: this.state.reactEstate,
                        complex: this.state.complex,
                        assign: this.state.assign,
                      },
                    });
                  }}
                  startIcon={<SearchIcon />}
                >
                  {t("Search")}
                </Button>
              </Box>
            </Box>
            <Box className="action-content-main-box">
              <Grid container spacing={2}>
                {this.state.actionAssignTeamTaskList.map((task: any) => {
                  return (
                    <Grid item sm={12} className="action-team-content-box" key={task.id}>
                      <Card className="action-card">
                        <h4 className="bold-text">{task.attributes.title}</h4>
                        <p className="description">{task.attributes.description}</p>
                        <Box className="action-info-box action-team-info-content">
                          <Box className="action-content-box">
                            <Box className="action-column-box">
                              <Box className="action-info action-team-action-box">
                                <img src={CERealEstateIcon} alt="" />
                                <Box>
                                  <span>{t("Real Estate Company")}</span>
                                  <p className="bold-text">{this.handleTeamCompanyName(task.attributes)}</p>
                                </Box>
                              </Box>
                              <Box className="action-info action-team-action-box">
                                <img src={CEUserIcon} alt="" />
                                <Box>
                                  <span>{t("Assigned to")}</span>
                                  <p className="bold-text">{task.attributes.assign_to}</p>
                                </Box>
                              </Box>
                            </Box>
                            <Box className="action-column-box">
                              <Box className="action-info action-team-action-box">
                                <img src={CEBuildingIcon} alt="" />
                                <Box>
                                  <span>{t("Complex")}</span>
                                  <p className="bold-text">{task.attributes.society_management?.name}</p>
                                </Box>
                              </Box>
                              <Box className="action-info action-team-action-box">
                                <img src={CECalendarIcon} alt="" />
                                <Box>
                                  <span>{t("Assigned on")}</span>
                                  <p className="bold-text">{moment(task.attributes.assign_on, "MMMM DD, YYYY").format("DD-MM-YYYY")}</p>
                                </Box>
                              </Box>
                            </Box>
                            <Box className="action-column-box action-team-action-box">
                              <Box className="action-info">
                                <img src={CEBuildingIcon} alt="" />
                                <Box>
                                  <span>{t("Building")}</span>
                                  <p className="bold-text">{task.attributes.building_management ? task.attributes.building_management.name : "-"}</p>
                                </Box>
                              </Box>
                              <Box className="action-info action-team-action-box">
                                <img src={CECalendarIcon} alt="" />
                                <Box>
                                  <span>{t("Due on")}</span>
                                  <p className="bold-text">{moment(task.attributes.due_on, "MMMM DD, YYYY").format("DD-MM-YYYY")}</p>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Container>
        </CEHeaderComponentWeb>

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          open={this.state.isActionAssignOpen}
          className="edit-profile edit-unit action-assign-create-modal"
          maxWidth="md"
          scroll="paper"
          fullWidth
        >
          <MuiDialogTitle disableTypography className="dialog-heading">
            <Typography variant="h6" className="bold-text">
              {t("Action Assign")}
            </Typography>
            <IconButton className="admin-invitation-close-icon" onClick={() => this.handleActionAssignModal()}>
              <CloseIcon />
            </IconButton>
          </MuiDialogTitle>
          <Formik
            onSubmit={(values, { resetForm }) => {
              this.setState({ loading: true }, () => {
                this.handleSubmitAssignForm(values);
                this.handleActionAssignModal();
                resetForm();
              });
            }}
            initialValues={this.state.actionForm}
            enableReinitialize={true}
            validationSchema={this.actionAssignValidation}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit} translate>
                  <DialogContent dividers className={languageCondition(language, "arabic-grid", "")}>
                    <Grid container spacing={3} className="edit-building">
                      <Grid item md={12}>
                        <InputLabel>{t("Title")}</InputLabel>
                        <Box className="measurement-modal-box">
                          <Input
                            onChange={handleChange}
                            value={values.title}
                            className="input-with-icon"
                            placeholder={t("Title")}
                            onBlur={handleBlur}
                            name="title"
                            fullWidth
                          />
                        </Box>
                        {ErrorHandler(t, touched.title, errors.title)}
                      </Grid>
                      <Grid item md={12}>
                        <InputLabel>{t("Description")}</InputLabel>
                        <Box className="measurement-modal-box add-meeting">
                          <TextareaAutosize
                            onChange={handleChange}
                            className="reject-note"
                            placeholder={t("Description")}
                            value={values.description}
                            onBlur={handleBlur}
                            name="description"
                          />
                        </Box>
                        {ErrorHandler(t, touched.description, errors.description)}
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Real Estate Company")}</InputLabel>
                        <Box className="edit-unit-modal-config">
                          <Select
                            onBlur={handleBlur}
                            value={values.realStateCompany}
                            displayEmpty
                            name="realStateCompany"
                            variant="filled"
                            onChange={async (e: any) => {
                              const realEstate = e.target.value;
                              await this.getActionTeamComplex(realEstate);
                              setFieldValue("realStateCompany", realEstate);
                            }}
                            input={<OutlinedInput />}
                            IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                            className="select-input action-select-real-estate"
                          >
                            <MenuItem className={languageCondition(language, "select-arabic-menu", "")} value="">
                              {t("Real Estate Company")}
                            </MenuItem>
                            {this.state.teamReactStateList.map((realEstate: any) => {
                              return (
                                <MenuItem className={languageCondition(language, "select-arabic-menu", "")} value={realEstate.id} key={realEstate.id}>
                                  {realEstate.company_name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {ErrorHandler(t, touched.realStateCompany, errors.realStateCompany)}
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Complex/Individual Building")}</InputLabel>
                        <Box className="edit-unit-modal-config">
                          <Select
                            onBlur={handleBlur}
                            value={values.isComplex}
                            displayEmpty
                            IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                            name="isComplex"
                            variant="filled"
                            onChange={async (e: any) => {
                              const isComplex = e.target.value;
                              setFieldValue("isComplex", isComplex);

                              if (isComplex === "complex") {
                                const filterComplexList = this.state.complexList.filter((complex) => !complex.is_building);
                                this.setState({ teamComplexList: filterComplexList });
                              } else {
                                const filterComplexList = this.state.complexList.filter((complex) => complex.is_building);
                                this.setState({ teamComplexList: filterComplexList });
                              }
                            }}
                            input={<OutlinedInput />}
                            className="select-input action-select-is-building"
                          >
                            <MenuItem className={languageCondition(language, "select-arabic-menu", "")} value="complex">
                              {t("Complex")}
                            </MenuItem>
                            <MenuItem className={languageCondition(language, "select-arabic-menu", "")} value="individual">
                              {t("Individual Building")}
                            </MenuItem>
                          </Select>
                          {ErrorHandler(t, touched.isComplex, errors.isComplex)}
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{values.isComplex === "complex" ? t("Complex") : t("Individual Building")}</InputLabel>
                        <Box className="edit-unit-modal-config">
                          <Select
                            onBlur={handleBlur}
                            value={values.complex}
                            displayEmpty
                            name="complex"
                            variant="filled"
                            IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                            onChange={async (e: any) => {
                              const complex = e.target.value;
                              await this.getActionTeamBuilding(complex);
                              await this.getActionTeamAssignTo(complex);
                              setFieldValue("complex", complex);
                            }}
                            input={<OutlinedInput />}
                            className="select-input action-select-complex"
                          >
                            <MenuItem className={languageCondition(language, "select-arabic-menu", "")} value="">
                              {values.isComplex === "complex" ? t("Complex") : t("Individual Building")}
                            </MenuItem>
                            {this.state.teamComplexList.map((complex: any) => (
                              <MenuItem className={languageCondition(language, "select-arabic-menu", "")} value={complex.id} key={complex.id}>
                                {complex.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {ErrorHandler(t, touched.complex, errors.complex)}
                        </Box>
                      </Grid>
                      {values.isComplex === "complex" && (
                        <Grid item md={6}>
                          <InputLabel>{t("Building")}</InputLabel>
                          <Box className="edit-unit-modal-config">
                            <Select
                              onBlur={handleBlur}
                              value={values.building}
                              displayEmpty
                              name="building"
                              variant="filled"
                              onChange={handleChange}
                              IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                              input={<OutlinedInput />}
                              className="select-input"
                            >
                              <MenuItem className={languageCondition(language, "select-arabic-menu", "")} value="">
                                {t("Building")}
                              </MenuItem>
                              {this.state.teamBuildingList.map((building: any) => (
                                <MenuItem className={languageCondition(language, "select-arabic-menu", "")} value={building.id} key={building.id}>
                                  {building.name}
                                </MenuItem>
                              ))}
                            </Select>
                            {ErrorHandler(t, touched.building, errors.building)}
                          </Box>
                        </Grid>
                      )}
                      <Grid item md={6}>
                        <InputLabel>{t("Assigned To")}</InputLabel>
                        <Box className="edit-unit-modal-config">
                          <Select
                            IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                            onBlur={handleBlur}
                            value={values.assignTo}
                            displayEmpty
                            name="assignTo"
                            variant="filled"
                            onChange={handleChange}
                            input={<OutlinedInput />}
                            className="select-input"
                          >
                            <MenuItem className={languageCondition(language, "select-arabic-menu", "")} value="">
                              {t("Assigned To")}
                            </MenuItem>
                            {this.state.teamAssignToList.map((user: any) => {
                              return (
                                <MenuItem className={languageCondition(language, "select-arabic-menu", "")} value={user.id} key={user.id}>
                                  {user.first_name} {user.last_name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {ErrorHandler(t, touched.assignTo, errors.assignTo)}
                        </Box>
                      </Grid>
                      <Grid item md={6}>
                        <InputLabel>{t("Due On")}</InputLabel>
                        <Box className="edit-unit-modal-config action-assign-due-box">
                          <Input
                            value={values.dueOn}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="dueOn"
                            className={!values.dueOn && is_mobile ? "select-input input placeHolderForDate" : "select-input input"}
                            placeholder={t("Due On")}
                            type="date"
                            startAdornment={
                              <InputAdornment position="start">
                                <img src={CECalendarIcon} alt="" />
                              </InputAdornment>
                            }
                          />
                          {ErrorHandler(t, touched.dueOn, errors.dueOn)}
                        </Box>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                    <Button style={{ height: "50px" }} onClick={() => this.handleActionAssignModal()} className="cancel-button">
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" className="add-button" style={{ height: "50px", margin: "0" }}>
                      {t("Save")}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Dialog>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// @ts-ignore
export default withTranslation()(withStyles(CEActionsMeStyleWeb)(withRouter(CEActionsTeam)));
// Customizable Area End
