//Customizable area start
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import React from "react";
import { apiCallWithToken } from "../../../../components/src/APICallComponent/index.web";
import { Message } from "../../../../framework/src/Message";

export interface Props {
  navigation?: any;
  id?: string;
  openModal: boolean;
  handleModalClose: any;
  modalType: string;
  dataId: string | number;
  name?: string;
  isBuilding?: boolean;
  buildingComplexName?: string;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
}

interface S {
  initialValues: any;
  empformModal: any;
}

interface SS {
  id: any;
}

export default class DeleteRevokeMemberController extends CommonApiCallForBlockComponent<Props, S, SS> {
  DeleteRevokeLoadDataCallID: any;
  DeleteRevokePostCallId: any;
  deleteProfile: any;
  revokePermissionCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {
      empformModal: false,
      initialValues: {
        EmpName: "",
        CRP: "",
        NRP: "",
      },
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentDidMount() {
    await super.componentDidMount();

    this.setState({ empformModal: this.props.openModal });
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId && responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.deleteProfile) {
          this.props.handleModalClose();
          this.props.navigation.navigate("CEMyTeam");
        } else if (apiRequestCallId === this.revokePermissionCallId) {
          this.props.handleModalClose();
        }
      }
    }
  }

  getRevokeEndpoint = () => {
    const id = this.props.dataId;
    if (this.props.isBuilding) {
      return `bx_block_settings/building_managements/${id}`;
    } else {
      return `bx_block_society_management/society_managements/${id}`;
    }
  };

  handleDelete = async () => {
    const profileId = this.props.dataId;
    if (this.props.modalType === "delete") {
      this.deleteProfile = await apiCallWithToken({
        contentType: "application/json",
        method: "DELETE",
        endPoint: `bx_block_my_team/company_employee/employee_team_members/${profileId}`,
      });
    } else {
      this.revokePermissionCallId = await apiCallWithToken({
        contentType: "application/json",
        method: "DELETE",
        endPoint: this.getRevokeEndpoint(),
      });
    }
  };

  getType = () => (this.props.isBuilding ? "Building" : "Complex");
}
// Customizable Area End
