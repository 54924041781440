// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  Tabs,
  Tab,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Link,
  Chip,
  Divider,
  DialogContent,
  DialogActions,
  Dialog,
  Input,
} from "@material-ui/core";
import { CSSProperties, withStyles } from "@material-ui/styles";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { withTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import CloseIcon from "@material-ui/icons/Close";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import "./incidents.css";
import PaginationModule from "../../../StoreCredits/src/PaginationModule.web";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Close, uploadbw, UploadIcon, nextIcon, previousIcon } from "./assets";
import { Close_Icon, CloseIcon as DeleteImageIcon, TickIcon, mapLocation, uploadDocIcon } from "../assets";
import Slider from "react-slick";
import BuildingDetailsPageController, { Props } from "./BuildingDetailsPageController.web";
import { ModalWrapper } from "../../../../components/src/ModalWrapper/index";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import SearchInput from "../../../../components/src/CommonComponents/SearchInput.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import { ErrorHandler, LanguageAttributeHandler } from "../../../../components/src/UtilFunction.web";
import NormalTextArea from "../../../../components/src/CommonComponents/NormalTextArea.web";
import { orange } from "@material-ui/core/colors";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import AlertDialog from "../../../../components/src/MuiDialog/AlertDialog.web";
import GoogleMapReact from "google-map-react";
import Loader from "../../../../components/src/Loader.web";

const LocationPin: React.FC<{ mapLocation: any; lat: string | number; lng: string | number }> = ({
  mapLocation,
  lat,
  lng,
}) => <img src={mapLocation} alt="Location Pin" />;

const validationSchema = Yup.object().shape({
  roles: Yup.string(),
});

const settingsBuild = {
  swipeToSlide: true,
  slidesToShow: 5,
  infinite: false,
};

const OrangeRadio: any = withStyles({
  root: {
    "&$checked": {
      color: orange[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} checkedIcon={<CircleCheckedFilled style={{ color: "#FC8434" }} />} />);

interface StyledBuildTabProps {
  label: string;
}

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "rgb(43, 111, 237);",
    background: "#fff",
    borderRadius: "8px 8px 0px 0px",
    fontWeight: 400,
    fontSize: "15px",
    width: "fit-content",
    opacity: 1,
    marginRight: "0px",
    "&:focus": {
      opacity: 1,
    },
    "&.Mui-selected": {
      background: "#2B6FED",
      color: "#fff",
    },
  },
}))((props: StyledBuildTabProps) => <Tab disableRipple {...props} />);

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const validationSharedSchema = Yup.object().shape({
  BuildDetails: Yup.string().required("Details are required"),
  sharedFacilityBuild: Yup.string().required("Shared Facility required"),
  totalAreaBuild: Yup.string().required("Total Area is required"),
  feesBuild: Yup.string().required("Reservation fees is required"),
  floorDocument: Yup.mixed().required("Please upload a Floor Document File"),
});

class BuildingDetailsPage extends BuildingDetailsPageController {
  constructor(props: Props) {
    super(props);
  }

  menuOptions = [
    this.state.subscriptionId && {
      title: "Extend Subscription Duration",
      clickFn: this.handleBuildOpenSubscription,
      testId: "subscription",
    },
    this.state.subscriptionId && {
      title: "Upgrade Plan",
      clickFn: this.handleBuildUpgradePlan,
    },
    {
      title: "View Plan Details",
      clickFn: this.handleBuildOpenJurisdication,
    },
    {
      title: "Deactivate Complex",
      clickFn: this.handleDeactivateComplexDialog,
    },
    {
      title: "Manage Jurisdiction",
      clickFn: this.handleBuildOpenJurisdication,
      testId: "jurid",
    },
  ].filter(Boolean);

  sharedArea = (t: (val: string) => string) => (
    <Grid container spacing={4}>
      {this.state.buildingDetails?.shared_area?.map((document: any, i: any) => {
        return (
          <Grid item xs={4} key={i}>
            <Box
              className="sharedDetailPage"
              style={webStyle.docInfoBox}
              onClick={() => this.handleSharedAreaNavigation(document.id)}
            >
              <Box style={webStyle.docTitle}>
                <Typography className="bold-text">
                  {document.name}-{document.id}
                </Typography>
              </Box>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );

  handleUnitTab = (t: (val: string) => string) => (
    <>
      <Table className="table-box">
        <TableHead>
          <TableRow>
            <TableCell className="bold-text">{t("#")}</TableCell>
            <TableCell className="bold-text">{t("Unit Number")}</TableCell>
            <TableCell className="bold-text">{t("Floor Number")}</TableCell>
            <TableCell className="bold-text">{t("Resident Name")}</TableCell>
            <TableCell className="bold-text">{t("Owner")}</TableCell>
            <TableCell className="bold-text">{t("Status")}</TableCell>
            <TableCell className="bold-text"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.unitList.length > 0 ? (
            this.state.unitList.map((item: any, index: any) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.attributes.apartment_name}</TableCell>
                  <TableCell>{item.attributes.floor_number}</TableCell>
                  <TableCell>{item.attributes.resident?.full_name || "-"}</TableCell>
                  <TableCell>{item.attributes.owner?.full_name || "-"}</TableCell>
                  <TableCell>
                    <span className={item.attributes.status}>{item.attributes.status}</span>
                  </TableCell>
                  <TableCell>
                    <Menu menuButton={<MoreVertIcon />}>
                      <MenuItem>
                        <Link href={``}>{t("View Unit")}</Link>
                      </MenuItem>
                      <MenuItem>
                        <Link href={``}>{t("Edit Unit")}</Link>
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={6}>{t("No Record Found")}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Divider />
      <Box className="table-bottom" style={webStyle.tableFooter}>
        <PaginationModule
          pagination={this.state.unitPagination}
          handlePagination={this.handleIncidentPagination}
          page={this.state.page}
        />
      </Box>
    </>
  );

  renderBuildManageJurisdiction = (t: (val: string) => string, language: string) => (
    <ModalWrapper open={this.state.jurisdicationModal} closeFn={this.handleCloseJurisdication}>
      <Box style={webStyle.juridBox}>
        <Box style={webStyle.juridHeading}>
          <Typography style={webStyle.juridText} className="bold-text">
            {t("Manage Jurisdiction")}
          </Typography>
          <CloseIcon data-testid="close_jurid" style={webStyle.closeIcon} onClick={this.handleCloseJurisdication} />
        </Box>
        <Box style={{ marginTop: "14px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <NormalTextField
                name="title"
                language={language}
                label="Current Jurisdiction"
                t={t}
                isLabel={true}
                value={this.state.currentBuildingJurisdiction.name}
              />
            </Grid>
            <Grid item xs={6}>
              <NormalSelect
                label="New Jurisdiction"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({ jId: e.target.value }, () => this.getBuildJurisdictionById(e.target.value))
                }
                language={language}
                name="country"
                t={t}
                option={this.state.allJurisdiction}
                value={this.state.jId}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography style={webStyle.juridTitle}>{t("Currency")}</Typography>
              <Typography style={webStyle.juridTitleVal} className="jurisdiction-value">
                {this.state.currentBuildingJurisdiction.currency}
              </Typography>
              <Typography style={webStyle.juridTitle}>{t("Timezone")}</Typography>
              <Typography style={webStyle.juridTitleVal} className="jurisdiction-value">
                {this.state.currentBuildingJurisdiction.timezone}
              </Typography>
              <Typography style={webStyle.juridTitle}>{t("Unit of Measurement")}</Typography>
              <Typography style={webStyle.juridTitleVal} className="jurisdiction-value">
                {this.state.currentBuildingJurisdiction.measurement}
              </Typography>
              <Typography style={webStyle.juridTitle}>{t("Management Fee Calculation Formula")}</Typography>
              <Typography style={webStyle.juridTitleVal} className="jurisdiction-value">
                {this.state.currentBuildingJurisdiction.formula}
              </Typography>
              <Typography style={webStyle.juridTitle}>{t("Mobile No length")}</Typography>
              <Typography style={webStyle.juridTitleVal} className="jurisdiction-value">
                {this.state.currentBuildingJurisdiction.mobileLength}
              </Typography>
              <Typography style={webStyle.juridTitle}>{t("Default Rent Contract")}</Typography>
              <Typography style={webStyle.juridTitleVal} className="jurisdiction-value">
                {this.state.currentBuildingJurisdiction.contract}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={webStyle.juridTitle} className="jurisdiction-title">
                {t("Currency")}
              </Typography>
              <Typography style={webStyle.juridTitleVal}>
                {this.state.newBuildingJurisdiction.currency || "-"}
              </Typography>
              <Typography style={webStyle.juridTitle} className="jurisdiction-title">
                {t("Timezone")}
              </Typography>
              <Typography style={webStyle.juridTitleVal}>
                {this.state.newBuildingJurisdiction.timezone || "-"}
              </Typography>
              <Typography style={webStyle.juridTitle} className="jurisdiction-title">
                {t("Unit of Measurement")}
              </Typography>
              <Typography style={webStyle.juridTitleVal}>
                {this.state.newBuildingJurisdiction.measurement || "-"}
              </Typography>
              <Typography style={webStyle.juridTitle} className="jurisdiction-title">
                {t("Management Fee Calculation Formula")}
              </Typography>
              <Typography style={webStyle.juridTitleVal}>
                {this.state.newBuildingJurisdiction.formula || "-"}
              </Typography>
              <Typography style={webStyle.juridTitle} className="jurisdiction-title">
                {t("Mobile No length")}
              </Typography>
              <Typography style={webStyle.juridTitleVal}>
                {this.state.newBuildingJurisdiction.mobileLength || "-"}
              </Typography>
              <Typography style={webStyle.juridTitle} className="jurisdiction-title">
                {t("Default Rent Contract")}
              </Typography>
              <Typography style={webStyle.juridTitleVal}>
                {this.state.newBuildingJurisdiction.contract || "-"}
              </Typography>
            </Grid>
          </Grid>
          <Box style={webStyle.juridActions} className="jurid_modal_actions">
            <Button
              style={webStyle.closeDeleteBtn}
              variant="outlined"
              data-testid="secondary_btn"
              className="jurid_modal_cancel_btn"
              color="primary"
              onClick={this.handleCloseJurisdication}
            >
              <span className="bold-text">CANCEL</span>
            </Button>
            <Button
              style={webStyle.confirmDeleteBtn}
              color="primary"
              data-testid="primary_btn"
              onClick={this.handleBuildingManageJurisdiction}
              variant="contained"
              className="jurid_modal_save_btn"
            >
              <span className="bold-text">SAVE</span>
            </Button>
          </Box>
        </Box>
      </Box>
    </ModalWrapper>
  );

  formValue = {};

  renderBuildSharedArea = (t: (val: string) => string, language: string) => {
    return (
      <Dialog
        dir={languageCondition(language, "rtl", "ltr")}
        scroll="paper"
        open={this.state.openShared}
        fullWidth
        maxWidth="md"
        className="edit-profile building-shared-area-dialog"
      >
        <MuiDialogTitle disableTypography className="dialog-heading">
          <Typography variant="h6" className="bold-text">
            {t("Add New Shared Area")}
          </Typography>
          <IconButton onClick={this.handleShareClose}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <Formik
          enableReinitialize={true}
          initialValues={this.state.sharedAreaBuildingList}
          data-testid="facilityFormik"
          validationSchema={validationSharedSchema}
          onSubmit={(values, { resetForm }) => {
            this.handleShareClose();
            this.handleSharedDetails(values, resetForm);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit} translate>
                <DialogContent dividers>
                  <Grid container spacing={2} className="edit-building">
                    <Grid item md={12}>
                      <NormalTextField
                        name="sharedFacilityBuild"
                        t={t}
                        language={language}
                        onChange={handleChange}
                        value={values.sharedFacilityBuild}
                        isLabel={true}
                        label="Shared Facility's Name"
                        onBlur={handleBlur}
                      />
                      {ErrorHandler(t, touched.sharedFacilityBuild, errors.sharedFacilityBuild)}
                    </Grid>
                    <Grid item md={12}>
                      <InputLabel>{t("Upload Photos")}</InputLabel>
                      <Grid container spacing={4}>
                        <Grid item md={3}>
                          <Box
                            onClick={() => this.uploadFile.click()}
                            className="upload-photo shared-area-upload-images"
                          >
                            <img src={uploadbw} alt="area-images" />
                          </Box>
                          <input
                            onBlur={handleBlur}
                            style={{ display: "none" }}
                            accept="image/*"
                            type="file"
                            ref={(ref: any) => (this.uploadFile = ref)}
                            className="building-shred-area-images"
                            onChange={(e: any) => {
                              for (let file of e.target.files) {
                                let reader = new FileReader();
                                reader.onloadend = () => {
                                  values.images = [...values.images, reader.result];
                                  setFieldValue("images", values.images);
                                };
                                reader.readAsDataURL(file);
                              }
                            }}
                            name="photos"
                            multiple
                          />
                        </Grid>
                        {values?.images?.map((image: any, index: number) => {
                          return (
                            <Grid item md={3} key={index} className="shared-area-images-grid">
                              <Box className="building-image shared-area-image-box">
                                <img
                                  onClick={() => {
                                    const remainImage = values.images.filter((_: any, idx: number) => idx !== index);
                                    setFieldValue("images", remainImage);
                                  }}
                                  className="delete-image shared-area-delete-cross-image"
                                  src={DeleteImageIcon}
                                />
                                <img src={image} alt="" />
                              </Box>
                            </Grid>
                          );
                        })}
                      </Grid>
                      {ErrorHandler(t, touched.images, errors.images)}
                    </Grid>
                    <Grid item md={12}>
                      <NormalTextArea
                        name="BuildDetails"
                        t={t}
                        isLabel={true}
                        onBlur={handleBlur}
                        label={t("Details")}
                        value={values.BuildDetails}
                        onChange={handleChange}
                        language={language}
                      />
                      {ErrorHandler(t, touched.BuildDetails, errors.BuildDetails)}
                    </Grid>
                    <Grid item md={12}>
                      <NormalTextField
                        name="totalAreaBuild"
                        language={language}
                        value={values.totalAreaBuild}
                        t={t}
                        isLabel={true}
                        label={t("Total Area")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {ErrorHandler(t, touched.totalAreaBuild, errors.totalAreaBuild)}
                    </Grid>
                    <Grid item md={12}>
                      <InputLabel>{t("Reservation Fees (Per hour)")}</InputLabel>
                      <Box className="measurement-modal-box">
                        <Input
                          onChange={handleChange}
                          className="input-with-icon"
                          placeholder={t("Reservation Fees (Per hour)")}
                          value={values.feesBuild}
                          onBlur={handleBlur}
                          fullWidth
                          name="feesBuild"
                        />
                        <Box
                          className={`${languageCondition(
                            language,
                            "measurement-arabic-value",
                            "measurement-modal-value"
                          )}`}
                        >
                          {LanguageAttributeHandler(
                            language,
                            this.state.newJurisdictionDetails?.currency,
                            this.state.newJurisdictionDetails?.currency_ar
                          )}
                        </Box>
                      </Box>
                      {ErrorHandler(t, touched.feesBuild, errors.feesBuild)}
                    </Grid>
                    <Grid item md={12}>
                      <InputLabel>{t("Floor Plan")}</InputLabel>
                      <Box className="floor-plan-box">
                        <input
                          className="floor-plan-pdf"
                          style={{ display: "none" }}
                          type="file"
                          onBlur={handleBlur}
                          name="floorPlan"
                          accept=".pdf"
                          ref={(ref: any) => (this.uploadFile = ref)}
                          onChange={(e: any) => {
                            const file = e.target.files[0];
                            setFieldValue("floorDocument", file);
                          }}
                        />
                        {!values.floorDocument ? (
                          <span className="placeholder">{t("Floor Plan")}</span>
                        ) : (
                          <Chip
                            deleteIcon={<img src={Close_Icon} style={{ margin: "0 12px 0 12px" }} />}
                            onDelete={() => {
                              setFieldValue("floorDocument", null);
                            }}
                            label={(values as any).floorDocument?.name}
                          />
                        )}
                        <img className="shared-floor-upload" onClick={() => this.uploadFile.click()} src={uploadbw} />
                      </Box>
                      {ErrorHandler(t, touched.floorDocument, errors.floorDocument)}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions className="dialog-button-group building-shared-btn-group" style={{ gap: "10px" }}>
                  <Button onClick={this.handleShareClose} className="cancel-button building-shared-cancel-btn">
                    {t("Cancel")}
                  </Button>
                  <Button type="submit" style={{ margin: "0" }} className="add-button building-shared-add-btn">
                    {t("Save")}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    );
  };

  renderBuildingDocumentTab = () => {
    return (
      <Grid container spacing={4}>
        {this.state.document.map((document: any, i: any) => {
          return (
            <Grid item xs={4} key={i}>
              <Box style={webStyle.docInfoBox}>
                <Box style={webStyle.docTitle}>
                  <DescriptionOutlinedIcon style={webStyle.docIcon} />
                  <Typography className="bold-text">{document.title}</Typography>
                </Box>
                <Box style={webStyle.docCount}>
                  <Typography className="bold-text" style={webStyle.docCountText}>
                    {document.count}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  renderBuildingTabComponent = (t: (val: string) => string) => {
    const { tabNo } = this.state;
    switch (tabNo) {
      case 0:
        return this.renderBuildingDocumentTab();
      case 1:
        return this.handleUnitTab(t);
      default:
        return this.sharedArea(t);
    }
  };

  renderBuildingExtendedSub = (t: (val: string) => string, language: string) => (
    <ModalWrapper
      className="building-extend-sub-modal"
      open={this.state.subscriptionModal}
      closeFn={this.handleBuildCloseSubscription}
    >
      <Box style={webStyle.subBox} className="sub-build-close">
        <Box style={webStyle.juridHeading}>
          <Typography style={webStyle.juridText} className="bold-text tab-doc">
            {t("Extend Subscription Duration")}
          </Typography>
          <CloseIcon
            style={webStyle.closeIcon}
            onClick={this.handleBuildCloseSubscription}
            data-testid="close_subscription"
          />
        </Box>
        <Box style={webStyle.subContent}>
          <Typography style={webStyle.subExtraInfo}>
            The request will be submitted to the COO for his review and approval
          </Typography>
          <Formik
            enableReinitialize={true}
            initialValues={this.state.subBuildingExtendDetails}
            onSubmit={(values, { resetForm }) => {
              this.setState({ loading: true, subscriptionModal: false }, () => {
                this.upgradeBuildingSubscription(values);
                resetForm();
              });
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit} translate>
                  <Grid container spacing={2}>
                    {this.commonExtendedSubscription()}
                    <Grid item xs={12} style={webStyle.selectGrid}>
                      <NormalTextField
                        t={t}
                        language={language}
                        value={values.date}
                        isLabel={true}
                        onFocus={(e: React.ChangeEvent<{ type: string }>) => (e.target.type = "date")}
                        label="Extend Duration to"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="date"
                        placeholder="Select Date"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <RadioGroup
                          onBlur={handleBlur}
                          value={values.isPaid}
                          style={{ flexDirection: "row" }}
                          onChange={handleChange}
                          name="isPaid"
                        >
                          <FormControlLabel
                            value="female"
                            control={<OrangeRadio />}
                            label="Paid Extension"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="male"
                            control={<OrangeRadio />}
                            label="Free Extension"
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} style={webStyle.selectGrid}>
                      {values.isPaid === "true" ? (
                        <NormalTextField
                          t={t}
                          label={t("Payment Reference Number")}
                          value={values.refNumber}
                          onChange={handleChange}
                          name="refNumber"
                          onBlur={handleBlur}
                          isLabel={true}
                          type="text"
                          language={language}
                        />
                      ) : (
                        <NormalTextArea
                          t={t}
                          onBlur={handleBlur}
                          value={values.reason}
                          onChange={handleChange}
                          name="reason"
                          label={t("Justifications for free extension")}
                          isLabel={true}
                          type="text"
                          language={language}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Box style={webStyle.juridActions} className="sub_modal_actions">
                    <Button
                      variant="outlined"
                      color="primary"
                      data-testid="secondary_btn"
                      style={webStyle.closeDeleteBtn}
                      onClick={this.handleCloseJurisdication}
                      className="sub_modal_cancel_btn doc-sub"
                    >
                      <span className="bold-text">CANCEL</span>
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      data-testid="primary_btn"
                      style={webStyle.confirmDeleteBtn}
                      className="sub_modal_save_btn doc-sub"
                    >
                      <span className="bold-text">SAVE</span>
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </ModalWrapper>
  );

  renderBuildingUpgradeSub = (t: (val: string) => string, language: string) => (
    <ModalWrapper
      open={this.state.upgradesubscriptionModal}
      className="building-upgrade-sub-modal"
      closeFn={this.handleBuildCloseUpgradeSubscription}
    >
      <Box style={webStyle.subBox} className="main-upgrade-sub">
        <Box style={webStyle.juridHeading}>
          <Typography style={webStyle.juridText} className="bold-text">
            {t("Upgrade Plan")}
          </Typography>
          <CloseIcon
            style={webStyle.closeIcon}
            onClick={this.handleBuildCloseUpgradeSubscription}
            data-testid="close_subscription"
            className="upagrade-sub-doc"
          />
        </Box>
        <Box style={webStyle.subContent}>
          <Typography style={webStyle.subExtraInfo} className="upagrade-sub-doc">
            The request will be submitted to the COO for his review and approval
          </Typography>
          <Formik
            enableReinitialize={true}
            initialValues={this.state.buildingPlanDetail}
            onSubmit={(values, { resetForm }) => {
              this.setState({ loading: true, upgradesubscriptionModal: false }, () => {
                this.handleBuildingExtendSubscription(values);
                resetForm();
              });
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              return (
                <Form onSubmit={handleSubmit} translate>
                  <Grid container spacing={2}>
                    {this.commonExtendedSubscription()}
                    <Grid item xs={12} style={webStyle.selectGrid}>
                      <NormalSelect
                        label="Upgrade Plan to"
                        language={language}
                        value={values.planId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        option={[]}
                        t={t}
                        name="planId"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl>
                        <RadioGroup
                          onBlur={handleBlur}
                          value={values.isPaid}
                          onChange={handleChange}
                          style={{ flexDirection: "row" }}
                          name="isPaid"
                        >
                          <FormControlLabel
                            value="true"
                            control={<OrangeRadio />}
                            label={t("Paid")}
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="false"
                            control={<OrangeRadio />}
                            label={t("Free")}
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} style={webStyle.selectGrid}>
                      <NormalTextField
                        language={language}
                        label={t("Payment Reference Number")}
                        name="refNumber"
                        isLabel={true}
                        value={values.refNumber}
                        t={t}
                        onChange={handleChange}
                        type="text"
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                  <Box style={webStyle.juridActions} className="sub_modal_actions">
                    <Button
                      variant="outlined"
                      onClick={this.handleBuildCloseUpgradeSubscription}
                      color="primary"
                      data-testid="secondary_btn"
                      className="sub_modal_cancel_btn upgrade-doc"
                      style={webStyle.closeDeleteBtn}
                    >
                      <span className="bold-text">CANCEL</span>
                    </Button>
                    <Button
                      variant="contained"
                      className="sub_modal_save_btn upgrade-doc"
                      color="primary"
                      data-testid="primary_btn"
                      style={webStyle.confirmDeleteBtn}
                      type="submit"
                    >
                      <span className="bold-text">SUBMIT</span>
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </ModalWrapper>
  );

  renderUploadDocumentsData = (t: (val: string) => string) => (
    <Dialog
      className="edit-profile building-all-document-modal edit-share-area-modal"
      open={this.state.uploadModal}
      scroll="paper"
      fullWidth
      maxWidth="md"
    >
      <MuiDialogTitle disableTypography className="dialog-heading">
        <Typography variant="h6" className="bold-text">
          {"Upload Documents"}
        </Typography>
        <IconButton id="closeUploadModal" onClick={() => this.setState({ uploadModal: false })}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <Formik
        enableReinitialize={true}
        initialValues={this.state.initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          this.setState({ uploadModal: false }, () => {
            this.handleSubmit(values, resetForm);
          });
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit} translate>
              <DialogContent dividers className="predocData">
                <Grid container spacing={2} className="edit-building predocData">
                  <Grid item md={12} className="predocData">
                    <InputLabel>{t("Policy")}</InputLabel>
                    <Box className="floor-plan-box predocData">
                      <input
                        type="file"
                        ref={(ref: any) => (this.uploadFile = ref)}
                        style={{ display: "none" }}
                        accept=".pdf"
                        className="testPolicyLoad predocData"
                        onChange={(e: any) => {
                          const file = e.target.files[0];
                          setFieldValue("policyFileData", file);
                          setFieldValue("policyNameData", file.name);
                        }}
                        onBlur={handleBlur}
                        name="policyNameData"
                      />
                      {!values.policyFileData ? (
                        <span className="placeholder">{t("Upload Policy Document")}</span>
                      ) : (
                        <Chip
                          label={values.policyNameData}
                          onDelete={() => {
                            setFieldValue("policyFileData", null);
                            setFieldValue("policyNameData", "");
                          }}
                          deleteIcon={<img src={Close} style={{ margin: "0 12px 0 12px" }} />}
                        />
                      )}
                      <img onClick={() => this.uploadFile.click()} src={UploadIcon} id="policyUp" />
                    </Box>
                  </Grid>
                  <Grid item md={12} className="predocData">
                    <InputLabel>{t("Guidelines")}</InputLabel>
                    <Box className="floor-plan-box predocData">
                      <input
                        type="file"
                        ref={(ref: any) => (this.uploadGuideFile = ref)}
                        style={{ display: "none" }}
                        accept=".pdf"
                        className="guide-upload-data predocData"
                        onChange={(e: any) => {
                          const file = e.target.files[0];
                          setFieldValue("guideFileData", file);
                          setFieldValue("guideNameData", file.name);
                        }}
                        onBlur={handleBlur}
                        name="guideNameData"
                      />
                      {!values.guideFileData ? (
                        <span className="placeholder">{t("Upload Guidelines Document")}</span>
                      ) : (
                        <Chip
                          label={values.guideNameData}
                          onDelete={() => {
                            setFieldValue("guideFileData", null);
                            setFieldValue("guideNameData", "");
                          }}
                          deleteIcon={<img src={Close} style={{ margin: "0 12px 0 12px" }} />}
                        />
                      )}
                      <img onClick={() => this.uploadGuideFile.click()} src={UploadIcon} id="guideUp" />
                    </Box>
                  </Grid>
                  <Grid item md={12} className="predocData">
                    <InputLabel>{t("Roles")}</InputLabel>
                    <Box className="floor-plan-box predocData">
                      <input
                        type="file"
                        ref={(ref: any) => (this.uploadRoleFile = ref)}
                        style={{ display: "none" }}
                        accept=".pdf"
                        className="role-plan-pdf"
                        onChange={(e: any) => {
                          const file = e.target.files[0];
                          setFieldValue("roleFileData", file);
                          setFieldValue("roleNameData", file.name);
                        }}
                        onBlur={handleBlur}
                        name="roleNameData"
                      />
                      {!values.roleFileData ? (
                        <span className="placeholder">{t(" Upload Role Document")}</span>
                      ) : (
                        <Chip
                          label={values.roleNameData}
                          onDelete={() => {
                            setFieldValue("roleFileData", null);
                            setFieldValue("roleNameData", "");
                          }}
                          deleteIcon={<img src={Close} style={{ margin: "0 12px 0 12px" }} />}
                        />
                      )}
                      <img onClick={() => this.uploadRoleFile.click()} src={UploadIcon} id="roleUp" />
                    </Box>
                  </Grid>
                  <Grid item md={12} className="predocData">
                    <InputLabel>{t("Resolutions")}</InputLabel>
                    <Box className="floor-plan-box predocData">
                      <input
                        type="file"
                        ref={(ref: any) => (this.uploadResolveFile = ref)}
                        style={{ display: "none" }}
                        accept=".pdf"
                        className="floor-plan-pdf resolve-plan-pdf"
                        onChange={(e: any) => {
                          const file = e.target.files[0];
                          setFieldValue("resolveFileData", file);
                          setFieldValue("resolveNameData", file.name);
                        }}
                        onBlur={handleBlur}
                        name="resolveNameData"
                      />
                      {!values.resolveFileData ? (
                        <span className="placeholder">{t("Upload Resolution Document")}</span>
                      ) : (
                        <Chip
                          label={values.resolveNameData}
                          onDelete={() => {
                            setFieldValue("resolveFileData", null);
                            setFieldValue("resolveNameData", "");
                          }}
                          deleteIcon={<img src={Close} style={{ margin: "0 12px 0 12px" }} />}
                        />
                      )}
                      <img onClick={() => this.uploadResolveFile.click()} src={UploadIcon} id="resolveUp" />
                    </Box>
                  </Grid>
                  <Grid item md={12} className="predocData">
                    <InputLabel>{t("Building Plans")}</InputLabel>
                    <Box className="floor-plan-box predocData">
                      <input
                        type="file"
                        ref={(ref: any) => (this.uploadBuildingFile = ref)}
                        style={{ display: "none" }}
                        accept=".pdf"
                        className="floor-plan-pdf build-plan-pdf"
                        onChange={(e: any) => {
                          const file = e.target.files[0];
                          setFieldValue("buildingFileData", file);
                          setFieldValue("buildingNameData", file.name);
                        }}
                        onBlur={handleBlur}
                        name="buildingNameData"
                      />
                      {!values.buildingFileData ? (
                        <span className="placeholder">{t("Upload Building Plan Document")}</span>
                      ) : (
                        <Chip
                          label={values.buildingNameData}
                          onDelete={() => {
                            setFieldValue("buildingFileData", null);
                            setFieldValue("buildingNameData", "");
                          }}
                          deleteIcon={<img src={Close} style={{ margin: "0 12px 0 12px" }} />}
                        />
                      )}
                      <img onClick={() => this.uploadBuildingFile.click()} src={UploadIcon} id="buildUp" />
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className="dialog-button-group predocData" style={{ gap: "10px" }}>
                <Button type="submit" className="add-button predocData" style={{ margin: "0" }} id="uploadSaveData">
                  {t("Save")}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );

  returnBuildingTabHeading = (t: (val: string) => string, language: string) => {
    const { tabNo } = this.state;
    let headingText, buttonContent, buttonAction;

    switch (tabNo) {
      case 0:
        headingText = t("Documents");
        buttonContent = t("Upload");
        buttonAction = this.handleBOpenUploadModal;
        break;
      case 1:
        headingText = t("List of Units");
        buttonContent = t("Search by Unit Number or Owner's Name");
        break;
      default:
        headingText = t("List of Shared Facilities");
        buttonContent = t("+ ADD NEW SHARED FACILITY");
        buttonAction = this.handleAddBuildingList;
        break;
    }

    return (
      <Box style={webStyle.documentBoxHeading}>
        <Typography style={webStyle.buildingHeading} className="bold-text">
          {headingText}
        </Typography>
        <Box style={webStyle.uploadButton} onClick={buttonAction} data-testid="upload_doc">
          {tabNo === 1 && (
            <div style={{ display: "flex", gap: "5px", margin: "0" }} className="filter-head-box">
              <Button
                style={webStyle.addSharedBtn}
                color="primary"
                variant="contained"
                onClick={this.handleUnits}
                className="addUnitsData"
              >
                <span className="bold-text">{t("+ Add New Units")}</span>
              </Button>
              <FilterSelect
                t={t}
                language={language}
                label="Status"
                option={[
                  { label: "all", value: "all" },
                  { label: "Empty", value: "Empty" },
                  { label: "Rented", value: "rented" },
                  { label: "Occupied", value: "Occupied" },
                  { label: "Not Owned", value: "No-Own" },
                ]}
                value={this.state.status}
                onChange={(e: any) => this.setState({ status: e.target.value, page: 1 })}
                data-test-id="sortby-dropdown-filter"
              />
              <SearchInput t={t} language={language} label={buttonContent} onChange={this.handleFilterDataKeyUp} />
            </div>
          )}
          {tabNo !== 1 && (
            <>
              {tabNo === 0 && <img src={uploadDocIcon} />}
              {tabNo === 2 ? (
                <Button style={webStyle.addSharedBtn} color="primary" variant="contained">
                  <span className="bold-text" style={webStyle.btnHigh}>
                    {buttonContent}
                  </span>
                </Button>
              ) : (
                <Typography style={webStyle.uploadText} className="bold-text">
                  {buttonContent}
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
    );
  };

  commonExtendedSubscription = () => (
    <>
      <Grid style={webStyle.juridGrid} item xs={4}>
        <Typography style={webStyle.juridTitle}>Subscription Expiration Date:</Typography>
        <Typography style={webStyle.subValue} className="bold-text">
          12 January 2023
        </Typography>
      </Grid>
      <Grid style={webStyle.juridGrid} item xs={4}>
        <Typography style={webStyle.juridTitle}>Subscription Status:</Typography>
        <Typography style={webStyle.subValue} className="bold-text">
          Active
        </Typography>
      </Grid>
      <Grid style={webStyle.juridGrid} item xs={4}>
        <Typography style={webStyle.juridTitle}>Days to expiration:</Typography>
        <Typography style={webStyle.subValue} className="bold-text">
          12 Days
        </Typography>
      </Grid>
    </>
  );

  buildingDetailMapDialog = (t: (val: string) => string, language: string) => {
    return (
      <Dialog
        className="edit-profile chairman-map-modal"
        scroll="paper"
        dir={languageCondition(language, "rtl", "ltr")}
        maxWidth="sm"
        open={this.state.isOpenMapOpen}
        fullWidth
      >
        <MuiDialogTitle disableTypography className="dialog-heading">
          <Typography variant="h6" className="bold-text">
            {t("Location")}
          </Typography>
          <IconButton onClick={this.handleToggleBuildingMapDialog}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        {this.state.buildingLocation.lat && this.state.buildingLocation.long ? (
          <Box className="google-map-box">
            <GoogleMapReact
              defaultCenter={{
                lng: this.state.buildingLocation.long,
                lat: this.state.buildingLocation.lat,
              }}
              defaultZoom={15}
            >
              <LocationPin
                mapLocation={mapLocation}
                lat={this.state.buildingLocation.lat}
                lng={this.state.buildingLocation.long}
              />
            </GoogleMapReact>
          </Box>
        ) : (
          <Box className="no-google-map-box">{t("No Location Available")}</Box>
        )}
      </Dialog>
    );
  };

  render() {
    const { t, i18n } = this.props;
    const language = i18n.language;

    return (
      <>
        {this.renderBuildSharedArea(t, language)}

        <Box maxWidth="xl" style={webStyle.detailsBox}>
          <Box style={{ display: "flex" }}>
            <Link href="/CompanyEmployee/complexlisting">
              <Typography style={{ fontSize: "16px" }}>
                {t("Complex")} / {t("Buildings")} / {t("Complex Details")} /
              </Typography>
            </Link>
            <Typography style={webStyle.headColor}> {t("Buildings")}</Typography>
          </Box>
          <Box style={webStyle.titleBox}>
            <Typography
              style={webStyle.companyTitleComplex}
              className="bold-text"
            >{`Jurisdiction Id: ${this.state.jId}`}</Typography>
            <Box display="flex">
              <Box style={webStyle.moreList}>
                <Button
                  style={webStyle.deleteBtn}
                  data-testid="open_options"
                  id="open_options1"
                  className="bold-text"
                  onClick={this.handleBuildingOpenOptions}
                >
                  <span className="bold-text" style={webStyle.moreOptions}>
                    {t("MORE OPTIONS")} <ExpandMoreIcon style={this.state.openOptions ? webStyle.rotateIcon : {}} />{" "}
                  </span>
                </Button>
                {this.state.openOptions && (
                  <Box style={webStyle.moreListOptions}>
                    {this.menuOptions.map((option, i) => {
                      return (
                        <Box
                          style={webStyle.optionBox}
                          key={i}
                          onClick={option.clickFn}
                          data-testid={option.testId || ""}
                        >
                          <Typography style={webStyle.optionTitle}>{option.title}</Typography>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
              <Button variant="contained" style={webStyle.editBtnComplex} className="bold-text" color="primary">
                <span className="bold-text">{t("EDIT DETAILS")}</span>
              </Button>
            </Box>
          </Box>
          <Box style={webStyle.detailsContainerComplex} className="complex_details_container">
            <Box style={webStyle.companyDetailsHeaderComplex} className="complex_details_container_header">
              <Box style={webStyle.companyTextComplex} className="complex_details_container_company_text">
                <Box style={webStyle.logoHolderComplex}>
                  <img src={this.state.buildingDetails?.logo?.url} alt="" style={webStyle.logoHolderComplex} />
                </Box>
                <Box style={webStyle.companyInfoComplex} className="complex_details_container_company_info">
                  <Box style={webStyle.companyLocationComplex} className="complex_details_container_company_location">
                    <Typography variant="h4" style={webStyle.companyInfoTitleComplex} className="bold-text">
                      {this.state.buildingDetails?.name}
                    </Typography>
                  </Box>
                  <Typography variant="body1" style={webStyle.companyInfoLocationComplex}>
                    {this.state.buildingDetails?.city}
                  </Typography>
                </Box>
              </Box>
              <Box
                onClick={this.handleToggleBuildingMapDialog}
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <LocationOnOutlinedIcon style={webStyle.locationIconComplex} />
                <Typography style={webStyle.locationLabelComplex} className="bold-text">
                  {t("See building on map")}
                </Typography>
              </Box>
            </Box>
            <Box className="complex_page_slider">
              <Slider ref={(c: any) => (this.slider = c)} {...settingsBuild}>
                {this.state.buildingDetails?.photos.map((image: any, index: number) => {
                  return (
                    <div className="slider-image-box" key={index}>
                      <img src={image.url} style={webStyle.sliderImage} alt="" />
                    </div>
                  );
                })}
              </Slider>
              {this.state.buildingDetails?.photos.length > 0 && (
                <Box className="slick-bottom" style={webStyle.slickButtons}>
                  <Box className="button prev" style={webStyle.prevBtn} onClick={this.previousBuildingImage}>
                    <img src={previousIcon} alt="" />
                  </Box>
                  <Box className="button next" style={webStyle.nextBtn} onClick={this.nextBuildingImage}>
                    <img src={nextIcon} alt="" />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box style={webStyle.infoBoxes}>
            <Grid container spacing={4}>
              {this.state.gridBuildingDetails?.map((data: any, i: number) => {
                return (
                  <Grid item xs={4} key={i}>
                    <Box style={webStyle.gridInfo}>
                      <Typography style={webStyle.gridTitle}>{data.titles}</Typography>
                      {data.values && (
                        <Box style={webStyle.gridValueBox}>
                          <Typography style={webStyle.gridValue} className="bold-text">
                            {data.values}
                          </Typography>
                          {data.extraTags && <Typography style={webStyle.gridTag}>({data.extraTags})</Typography>}
                        </Box>
                      )}
                      {data.tags && (
                        <Box style={webStyle.tagBoxes}>
                          {data.tags.map((tag: string, i: number) => {
                            return (
                              <Box key={i} style={webStyle.tagBox}>
                                <Typography style={webStyle.tagName}>{tag}</Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Box style={webStyle.aboutBox}>
            <Typography style={webStyle.aboutHeading} className="bold-text">
              {t("About Building Name")}
            </Typography>
            <Box style={webStyle.aboutPara}>{this.state.buildingDetails?.description || "-"}</Box>
          </Box>

          <Box style={webStyle.extraInfoBox}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Box style={webStyle.gridInfo}>
                  <Typography style={webStyle.gridTitle}>Building Area</Typography>
                  <Box style={webStyle.gridValueBox}>
                    <Typography style={webStyle.gridExtraValue} className="bold-text">
                      {this.state.buildingDetails?.building_area || "-"}{" "}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box style={webStyle.gridInfo}>
                  <Typography style={webStyle.gridTitle}>Total Floor</Typography>
                  <Box style={webStyle.gridValueBox}>
                    <Typography style={webStyle.gridExtraValue} className="bold-text">
                      {this.state.buildingDetails?.total_floors || "-"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box style={webStyle.gridInfo}>
                  <Typography style={webStyle.gridTitle}>Total Units</Typography>
                  <Box style={webStyle.gridValueBox}>
                    <Typography style={webStyle.gridExtraValue} className="bold-text">
                      {this.state.buildingDetails?.total_units || "-"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box style={webStyle.documentTabs} className="tabs-selected">
            <StyledTabs
              aria-label="styled tabs example"
              //@ts-ignore
              value={this.state.tabNo}
              onChange={this.handleBTabChange}
            >
              <StyledTab label={t("Documents")} />
              <StyledTab label={t("Units")} />
              <StyledTab label={t("Shared facilities")} />
            </StyledTabs>

            <Box style={webStyle.documentBox}>
              {this.returnBuildingTabHeading(t, language)}
              <Box style={webStyle.documentList}>{this.renderBuildingTabComponent(t)}</Box>
            </Box>
          </Box>
        </Box>

        <AlertDialog
          IsOpen={this.state.isDeleteComplexOpen}
          CloseDialog={this.handleDeactivateComplexDialog}
          Image={TickIcon}
          Header={t("Delete Complex")}
          Content={`<p>Upon confirming the deletion of the complex, the request will be sent to the COO for his approval</p>`}
          AcceptText={t("Confirm")}
          AcceptFun={this.handleDeleteIndividualBuilding}
          DeclineText={t("Close")}
          DeclineFun={this.handleDeactivateComplexDialog}
        />

        {this.renderBuildManageJurisdiction(t, language)}
        {this.renderBuildingExtendedSub(t, language)}
        {this.renderBuildingUpgradeSub(t, language)}
        {this.buildingDetailMapDialog(t, language)}
        {this.renderUploadDocumentsData(t)}

        <Loader loading={this.state.loading} />
      </>
    );
  }
}

const webStyle: any = {
  approved: {
    background: "rgb(30,198,91,0.2)",
    color: "rgb(30 198 91)",
  },
  headColor: {
    color: "rgb(43, 111, 237)",
    fontSize: "16px",
  },
  uploadField: {
    background: "rgb(243, 243, 243) none repeat scroll 0% 0%",
    border: "1px solid #F0F0F0",
    padding: "5px",
    borderRadius: "8px",
  },
  juridActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px",
  },
  juridFormControl: {
    width: "100%",
    marginBottom: "15px",
    marginTop: "30px",
  },
  selectGrid: {
    marginTop: "15px",
  },
  subFormControl: {
    width: "100%",
    marginBottom: "15px",
  },
  cloudIcon: {
    color: "#848484",
  },
  closeDeleteBtn: {
    borderColor: "rgb(43, 111, 237) none repeat scroll 0% 0%",
    color: "rgb(43, 111, 237)",
    width: "150px",
    height: "45px",
    borderRdaius: "12px",
    fontWeight: "700" as CSSProperties["fontWeight"],
    marginRight: "15px",
  },
  selectText: {
    fontSize: "14px",
    marginBottom: "10px",
  },
  subContent: {
    marginTop: "15px",
  },
  uploadContent: {
    marginTop: "15px",
  },
  subValue: {
    fontSize: "14px",
    marginTop: "10px",
  },
  subExtraInfo: {
    color: "rgb(252, 132, 52)",
    fontSize: "14px",
  },
  btnHigh: {
    fontFamily: "Gotham_Bold",
  },
  confirmDeleteBtn: {
    background: "rgb(43, 111, 237)",
    borderColor: "rgb(43, 111, 237)",
    color: "#fff",
    boxShadow: "unset",
    width: "150px",
    height: "45px",
    borderRadius: "8px",
    fontWeight: "700" as CSSProperties["fontWeight"],
  },
  juridGrid: {
    paddingTop: "25px",
  },
  juridTitle: {
    color: "rgb(138, 138, 138)",
    fontSize: "14px",
    marginTop: "5px",
  },
  juridTitleVal: {
    color: "black",
    fontSize: "14px",
    marginTop: "5px",
    marginBottom: "10px",
    fontWeight: "bold",
  },
  slickButtons: {
    position: "absolute",
    width: "100%",
    top: "40%",
  },
  juridDoc: {
    color: "rgb(43, 111, 237)",
  },
  statusUnit: {
    border: "1px solid lightgray",
    padding: "10px 30px 10px 10px",
    background: "#FFFFFF",
    borderRadius: "8px",
    width: "100%",
  },
  rejected: {
    background: "rgb(242,23,23, 0.2)",
    color: "#F21717",
  },
  pending_approval: {
    background: "rgb(252, 132, 52, 0.2)",
    color: "rgb(252, 132, 52)",
  },
  aboutBox: {
    background: "#fff",
    borderRadius: "8px",
    padding: "20px 15px",
  },
  juridHeading: {
    display: "flex",
    justifyContent: "space-between",
  },
  closeIcon: {
    cursor: "pointer",
  },
  moreListOptions: {
    background: "#fff",
    borderRadius: "8px",
    position: "absolute",
    zIndex: "9999999",
    width: "250px",
    boxShadow: "0px 3px 10px 0px rgba(204,204,204,1)",
  },
  optionTitle: {
    fontSize: "14px",
  },
  optionBox: {
    padding: "10px 15px",
    borderBottom: "1px solid #ececec",
    cursor: "pointer",
  },
  extraInfoBox: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  uploadButton: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    gap: "6px",
  },
  uploadIcon: {
    color: "rgb(252, 132, 52)",
    marginRight: "10px",
  },
  uploadText: {
    color: "rgb(252, 132, 52)",
  },
  errorMsg: {
    color: "red",
    fontSize: "12px",
    marginTop: "5px",
  },
  tableFooter: {
    padding: "15px 0px 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buildingsInfo: {
    padding: "20px",
  },
  documentList: {
    padding: "20px 15px",
  },
  docIcon: {
    color: "rgb(43, 111, 237)",
    marginRight: "5px",
  },
  addBuildingBtn: {
    background: "rgb(43, 111, 237)",
    marginRight: "15px",
    borderRadius: "8px",
    paddingLeft: "30px",
    paddingRight: "30px",
  },
  addSharedBtn: {
    background: "rgb(252, 132, 52)",
    marginRight: "15px",
    borderRadius: "8px",
    paddingLeft: "30px",
    paddingRight: "30px",
    boxShadow: "unset",
  },
  juridBox: {
    width: "70%",
    marginTop: "15px",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
  },
  uploadBox: {
    width: "60%",
    marginTop: "15px",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
  },
  subBox: {
    width: "50%",
    marginTop: "15px",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
  },
  juridText: {
    fontSize: "20px",
  },
  docInfoBox: {
    border: "1px solid rgb(225, 225, 225)",
    padding: "15px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
  },
  moreList: {
    position: "relative",
  },
  docTitle: {
    display: "flex",
  },
  buildingBox: {
    background: "#fff",
    marginTop: "30px",
    borderRadius: "8px",
  },
  buildingHeadingBox: {
    padding: "20px 15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid rgb(225, 225, 225)",
  },
  documentBox: {
    background: "#fff",
  },
  documentBoxHeading: {
    padding: "20px 15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid rgb(225, 225, 225)",
  },
  buildingName: {
    color: "rgb(252, 132, 52)",
    fontSize: "18px",
  },
  docCount: {
    background: "rgba(252, 132, 52, 0.1)",
    borderRadius: "10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  prevBtn: {
    background: "rgba(0,0,0,0.3)",
    height: "30px",
    width: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    cursor: "pointer",
  },
  nextBtn: {
    background: "rgba(0,0,0,0.3)",
    height: "30px",
    width: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    right: "6px",
    cursor: "pointer",
  },
  docCountText: {
    color: "rgb(252, 132, 52)",
    fontSize: "12px",
    paddingRight: "0px",
  },
  buildingInfoBox: {
    border: "1px solid rgb(225, 225, 225)",
    padding: "15px",
    borderRadius: "8px",
    textAlign: "center",
  },
  buildingHeading: {
    fontSize: "20px",
  },
  aboutHeading: {
    fontSize: "20px",
    marginBottom: "10px",
  },
  buildingActions: {
    display: "flex",
    alignItems: "center",
  },
  detailsBox: {
    padding: "30px",
  },
  locationTag: {
    display: "flex",
  },
  searchInput: {
    border: "1px solid rgba(0,0,0,0.2)",
    borderRadius: "8px",
    height: "40px",
    padding: "0px",
    color: "#212121",
  },
  infoBoxes: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  companyTitleComplex: {
    fontSize: "32px",
  },
  companyInfoLocationComplex: {
    fontSize: "16px",
    fontWeight: "400" as CSSProperties["fontWeight"],
  },
  rotateIcon: {
    transform: "rotate(180deg)",
  },
  gridInfo: {
    background: "#fff",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px",
  },
  tagBoxes: {
    marginTop: "5px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  tagBox: {
    background: "rgba(43, 111, 237, 0.2)",
    borderRadius: "25px",
    padding: "2px 10px",
    textAlign: "center",
  },
  tagName: {
    fontSize: "12px",
    color: "rgb(43, 111, 237)",
    paddingRight: "0px",
  },
  gridValue: {
    color: "rgb(43, 111, 237)",
    fontSize: "24px",
    marginTop: "5px",
  },
  gridExtraValue: {
    color: "rgb(252, 132, 52)",
    fontSize: "24px",
    marginTop: "5px",
  },
  gridTag: {
    color: "rgb(164, 164, 164)",
  },
  companyLocationComplex: {
    display: "flex",
    alignItems: "center",
  },
  companyTagComplex: {
    color: "#FC8434",
    background: "rgb(252, 132, 52, 0.1)",
    padding: "5px 15px",
    borderRadius: "8px",
  },
  moreIcon: {
    color: "rgba(136,136,136,0.6)",
  },
  titleBox: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sliderImage: {
    height: "170px",
    width: "100px",
    borderRadius: "8px",
    marginRight: "15px",
    objectFit: "cover",
  },
  deleteBtn: {
    color: "rgb(43, 111, 237)",
    marginRight: "15px",
    fontWeight: 700,
  },
  moreOptions: {
    display: "flex",
    alignItems: "center",
  },
  companyTextComplex: {
    display: "flex",
    alignItems: "center",
  },
  companyInfoComplex: {
    marginLeft: "30px",
  },
  companyInfoTitleComplex: {
    fontSize: "22px",
    fontWeight: "400" as CSSProperties["fontWeight"],
  },
  editBtnComplex: {
    borderRadius: 8,
    background: "#2B6FED",
    borderColor: "#2B6FED",
    fontWeight: 700,
    boxShadow: "none",
  },
  locationIconComplex: {
    color: "#fc8434",
    height: "22px",
    marginLeft: "10px",
  },
  locationLabelComplex: {
    color: "#FC8434",
    fontSize: "16px",
    fontWeight: "400" as CSSProperties["fontWeight"],
    marginLeft: "10px",
  },
  gridValueBox: {
    display: "flex",
    alignItems: "center",
  },
  detailsContainerComplex: {
    background: "#fff",
    padding: "15px",
    marginTop: "30px",
    borderRadius: "8px",
  },
  companyDetailsHeaderComplex: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
    alignItems: "center",
  },
  logoHolderComplex: {
    background: "#dcdcdc",
    width: "70px",
    height: "70px",
    borderRadius: "50%",
    objectFit: "cover",
  },
};

export default withTranslation()(BuildingDetailsPage);
//Customizable Area End
