import React from "react";
// Customizable Area Start
import {
    Box,
    InputLabel,
    Button, 
    Grid, Typography, MenuItem, FormControl, Select
} from "@material-ui/core";
//@ts-ignore
import CompEmpComplexBlockStep1Controller from "./CompEmpBuildingBlockStep1Controller.web";
import "../../assets/commonForm.css"
// Customizable Area End

export default class CompEmpBuildingBlockStep1 extends CompEmpComplexBlockStep1Controller {
    render() {
        // Customizable Area Start
        const { t } = this.props;
        
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
            <Box className="building_topbox">
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="building_topbox_flex">
                                        <Box className="building_topbox_heading">
                                            <Typography className="Basic bold-text" style={{ fontSize: '22px', fontWeight: 'bold' }}>{t("Jurisdiction")}</Typography>
                                        </Box>
                                        <Box className="building_topbox_heading">
                                            <Typography style={{ fontSize: '16px' }}>{t("Step")}{this.props.stepBuilding + 1} {t("of 7")}</Typography>
                                        </Box>
                                    </Box>

                                    <Box className="formGroups customSelect juridNewSelect building_select" style={{ marginRight: '25px', width: '100%', marginTop: '20px', }}>
                                        <Typography className="typographyLable building_select_typo">
                                            {t("Jurisdiction")}
                                        </Typography>
                                        <FormControl variant="outlined"  >
                                        <InputLabel id="demo-simple-select-label" className="building_select_label">{t("Select Jurisdiction")}</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-outlined-label"
                                                onChange={this.props.handleChangeBuilding}
                                                data-testid="juridselect"
                                                className="djhdhd building_select_select"
                                                renderValue={(selected: any) => selected?.attributes.jurisdiction_name}
                                                value={this.props.jurisdictionSelected}
                                                id="juridselect"
                                               style={{backgroundColor:"#F0F0F0"}}
                                                defaultValue={{ id: 0,attributes:{ jurisdiction_name: "Select Jurisidiction"} }}
                                                
                                            >
                                                <MenuItem className="building_select_menu" disabled value="">{t("Select Jurisdiction")}</MenuItem>
                                                {
                                                this.props.jurisdictionList.length && this.props.jurisdictionList.map((variant: any) => (
                                                    <MenuItem className="building_select_menu" key={variant.attributes.id} 
                                                    //@ts-ignore
                                                    value={variant}>
                                                        {variant.attributes.jurisdiction_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', width: '80%', marginTop: '25px' }} className="building_info">
                                            <Grid container spacing={3} className="building_grid">
                                                <Grid item xs className="building_grid_item">
                                                    <Typography className="typographyLable building_info_typo">{t("Currency")}</Typography>
                                                    <Box className="fontSizeBox building_select_menu">
                                                        {
                                                            this.props.jurisdictionSelected ? this.props.jurisdictionSelected.attributes?.currency : 'N/A'
                                                        }
                                                    </Box>
                                                </Grid>
                                                <Grid item xs className="building_grid_item">
                                                    <Typography className="typographyLable building_info_typo">{t("Timezone")}</Typography>
                                                    <Box className="fontSizeBox building_select_menu">{
                                                            this.props.jurisdictionSelected ? this.props.jurisdictionSelected.attributes?.timezone : 'N/A'
                                                        }</Box>
                                                </Grid>
                                                <Grid item xs className="building_grid_item">
                                                    <Typography className="typographyLable building_info_typo">{t("Unit of Measurement")}</Typography>
                                                    <Box className="fontSizeBox building_select_menu">{
                                                            this.props.jurisdictionSelected ? this.props.jurisdictionSelected.attributes?.unit_of_measurement : 'N/A'
                                                        }</Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', width: '80%', marginTop: '25px' }} className="building_select_menu">
                                            <Grid container spacing={3} className="building_grid_item">
                                                <Grid item xs className="building_grid_item">
                                                    <Typography className="typographyLable building_info_typo">{t("Management Fee Calculation Formula")}</Typography>
                                                    <Box className="fontSizeBox building_select_menu">{
                                                            this.props.jurisdictionSelected ? this.props.jurisdictionSelected.attributes?.formula : 'N/A'
                                                        }</Box>
                                                </Grid>
                                                <Grid item xs className="building_grid_item">
                                                    <Typography className="typographyLable building_info_typo">{t("Mobile number length")}</Typography>
                                                    <Box className="fontSizeBox building_select_menu">{
                                                            this.props.jurisdictionSelected ? <>{this.props.jurisdictionSelected.attributes?.mobile_number_length} Digits</> : 'N/A'
                                                        }</Box>
                                                </Grid>
                                                <Grid item xs className="building_grid_item">
                                                    <Typography className="typographyLable building_info_typo">{t("Default Renting Contract")}</Typography>
                                                    <Box className="building_select_menu" style={{ color: '#2B6FED', fontSize: '16px' }}>{
                                                            this.props.jurisdictionSelected ? this.props.jurisdictionSelected.attributes.currency : 'N/A'
                                                        }</Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Box>
                                {this.props.multipleButton()}
                                </>

            // Customizable Area End
        );
    }
}

// export default withTranslation()(withRouter(CompEmpComplexBuildingBlock));
// Customizable Area Start
// Customizable Area End