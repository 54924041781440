// Customizable Area Start
import React from "react";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

export const LanguageAttributeHandler = (language: any, normalValue: any, arValue: any) => {
  if (language === "ar") {
    return arValue;
  } else {
    return normalValue;
  }
};

export const ErrorHandler = (t: any, touched: any, errors: any) => {
  if (touched && errors) {
    return <small className="error">{t(errors)}</small>;
  }
};

export const handleFilterComplexList = (value: number, complexList: any[]) => {
  if (value === 2) {
    return complexList
      .filter((complex) => complex.attributes.is_building)
      .map((item: any) => ({ label: item.attributes.name, value: item.id }));
  } else if (value === 1) {
    return complexList
      .filter((complex) => !complex.attributes.is_building)
      .map((item: any) => ({ label: item.attributes.name, value: item.id }));
  } else {
    return complexList.map((item: any) => ({
      label: item.attributes.name,
      value: item.id,
    }));
  }
};

export const handleFilterMainComplexList = (value: number, complexList: any[]) => {
  if (value === 0) {
    return complexList.map((item: any) => ({
      label: item.name,
      value: item.id,
    }));
  } else if (value === 1) {
    return complexList
      .filter((complex) => !complex.is_building)
      .map((item: any) => ({ label: item.name, value: item.id }));
  } else {
    return complexList
      .filter((complex) => complex.is_building)
      .map((item: any) => ({ label: item.name, value: item.id }));
  }
};

export const dataURLtoFile = (dataurl: any) => {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], "imageName.jpg", { type: mime });
};

export const toDataURL = (url: any) =>
  fetch(url)
    .then((response: any) => response.blob())
    .then(
      (blob: any) =>
        new Promise((resolve: any, reject: any) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

export const mockAPICall = (apiData: object, instance: any, apiCallID: string) => {
  const msgSuccessRestAPI = new Message(getName(MessageEnum.RestAPIResponceMessage));
  msgSuccessRestAPI.addData(getName(MessageEnum.RestAPIResponceDataMessage), msgSuccessRestAPI.messageId);
  msgSuccessRestAPI.addData(getName(MessageEnum.RestAPIResponceSuccessMessage), apiData);
  instance[apiCallID] = msgSuccessRestAPI.messageId;
  runEngine.sendMessage("Unit Test", msgSuccessRestAPI);
};

// Customizable Area End
