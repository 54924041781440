// Customizable Area Start
import React from "react";
import { Box, Button, Container, Divider, Grid, Link, Tab, Typography, withStyles, Dialog, DialogActions,
  DialogContent } from "@material-ui/core";
import {  buildingIcon, emailIcon, phoneIcon, userIcon } from "../assets";
import NormalTextField from "../../../../components/src/CommonComponents/NormalTextField.web";
import PhoneInput from "react-phone-input-2";
import NormalSelect from "../../../../components/src/CommonComponents/NormalSelect.web";
import { Form, Formik } from "formik";
import CERequestManagementController, { Props } from "./CERequestManagementController.web";
import { withRouter, Link as NavLink } from "react-router-dom";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
import DialogHeader from "../../../../components/src/MuiDialog/DialogHeader.web";
import { withTranslation } from "react-i18next";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import FilterSelect from "../../../../components/src/CommonComponents/FilterSelect.web";
import SearchIcon from "@material-ui/icons/Search";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import { ceBuildingIcon, ceRealEstateIcon } from "../assets";
// Customizable Area End

class CERequestManagement extends CERequestManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderInviteMember = (t: any,language:any) => {
    return (
      <Dialog
        dir={languageCondition(language, "rtl", "ltr")}
        open={this.state.inviteMemberModelOpen}
        scroll="paper"
        fullWidth
        maxWidth="md"
      >
        <DialogHeader t={t} title="Invite Member" onClose={this.handleClose} />
        <Formik
          initialValues={this.state.formValue} // Ensure `formValue` is properly initialized
          enableReinitialize={true}
          validationSchema={this.meetingValidation}
          onSubmit={(values, { resetForm }) => {
            this.handleFormSubmit(values);
            resetForm();
          }}
        >
            {({ values, handleBlur, handleSubmit, setFieldValue,errors, touched, }) => {
              return (
                <Form translate onSubmit={handleSubmit}>
                  <DialogContent dividers 
                  className={languageCondition(language, "arabic-grid", "")}>
                    <Grid spacing={3} container>
                      <Grid md={6} item>
                        <NormalSelect
                          t={t}
                          language={language}
                          icon={userIcon}
                          label="User Type"
                          name="type"
                          value={values.type}
                          option={this.state.userList}
                          data-testid="user-type"
                          onChange={(e: any) => {
                            setFieldValue("type", e.target.value);
                          }}
                        />
                        {ErrorHandler(t, touched.type, errors.type)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalTextField
                          t={t}
                          language={language}
                          icon={userIcon}
                          isLabel={true}
                          value={values.fullName}
                          data-testid="member-full-name"
                          label="Member Full Name"
                          id="fullName"
                          onChange={(e: any) => {
                            setFieldValue("fullName", e.target.value);
                          }}
                        />
                        {ErrorHandler(t, touched.fullName, errors.fullName)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalTextField
                          t={t}
                          language={language}
                          icon={emailIcon}
                          isLabel={true}
                          value={values.emailID}
                          label="Email Address"
                          data-testid="email-address"
                          id="emailId"
                          onChange={(e: any) => {
                            setFieldValue("emailID", e.target.value);
                          }}
                        />
                        {ErrorHandler(t, touched.emailID, errors.emailID)}
                      </Grid>
                      <Grid item md={6}>
                        <Box className="form__phone_number" display="flex" alignItems="center">
                          <PhoneInput
                            // value={values.countryCode}
                            inputProps={{ name: "selectCode" }}
                            country="us"
                            enableSearch={true}
                            data-testid="country-code"
                            onChange={(value: any) => {
                              setFieldValue("countryCode", value);
                            }}
                          />
                          <NormalTextField
                            t={t}
                            language={language}
                            icon={phoneIcon}
                            isLabel={true}
                            value={values.phoneNumber}
                            label="Phone Number"
                            data-testid="phone-number"
                            id="phoneNumber"
                            onChange={(e: any) => {
                              setFieldValue("phoneNumber", e.target.value);
                            }}
                          />
                        </Box>
                        {ErrorHandler(t, touched.phoneNumber, errors.phoneNumber)}
                      </Grid>
                      <Grid item md={6}>
                         <NormalSelect
                            t={t}
                            language={language}
                            value={values.country}
                            name="country"
                            data-testid="select-country"
                            label="Country"
                            onBlur={handleBlur}
                            onChange={(e: any) => {
                              const value = e.target.value;
                              setFieldValue("country", value);
                              this.getCityList(value);
                            }}
                            option={[...this.state.countryList]}
                          />
                          {ErrorHandler(t, touched.country, errors.country)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          value={values.city}
                          label="City"
                          key="city"
                          data-testid="select-city"
                          option={this.state.cityList}
                          onChange={(e: any) => {
                            const value = e.target.value;
                            setFieldValue("city", value);
                          }}
                        />
                        {ErrorHandler(t, touched.city, errors.city)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          value={values.individualBuilding}
                          label="Select Complex/Individual Building"
                          data-testid="individual-building"
                          option={this.state.complexBuildingFilterList}
                          id="selectedIndividualBuilding"
                          onChange={(e: any) => {
                            const value = e.target.value;
                            setFieldValue("individualBuilding", value);
                          }}
                        />
                        {ErrorHandler(t, touched.individualBuilding, errors.individualBuilding)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          icon={buildingIcon}
                          value={values.complex}
                          label="Select Complex"
                          data-testid="select-complex"
                          option={this.state.complexList}
                          id="selectedComplex"
                          onChange={(e: any) => {
                            const value = e.target.value;
                            setFieldValue("complex", value);
                             this.getBuildingsList(value);
                             this.getUnitList(value)
                          }}
                        />
                        {ErrorHandler(t, touched.complex, errors.complex)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          icon={buildingIcon}
                          value={values.building}
                          label="Select Building"
                          data-testid="select-building"
                          option={this.state.buildingsList}
                          id="selectedBuilding"
                          onChange={(e: any) => {
                            const value = e.target.value;
                            setFieldValue("building", value);
                          }}
                        />
                        {ErrorHandler(t, touched.building, errors.building)}
                      </Grid>
                      <Grid item md={6}>
                        <NormalSelect
                          t={t}
                          language={language}
                          icon={buildingIcon}
                          label="Select Unit"
                          value={values.unit}
                          data-testid="select-unit"
                          option={this.state.unitList}
                          id="selectedUnit"
                          onChange={(e: any) => {
                            const value = e.target.value;
                            setFieldValue("unit", value);
                          }}
                        />
                        {ErrorHandler(t, touched.unit, errors.unit)}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions className="dialog__button_group">
                    <Button className="cancel-button" data-testid="cancel-button" onClick={() => { this.handleClose() }}>
                      {t("Cancel")}
                    </Button>
                    <Button type="submit" className="add-button" data-testid="add-button">
                      {t("Send Invitation")}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
        </Formik>
      </Dialog>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes, i18n }: any = this.props;
    const language = i18n.language;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />
        {
          this.renderInviteMember(t,language)
        }
        <CEHeaderComponentWeb>
          <Container className={classes.RequestManagement}>
            <Box className="navigation">
              <Typography variant="body1" className="">
                <Link href="/CompanyEmployee/dashboard">{t("Community Management")}</Link> /{" "}
                <Box component="span" style={{ color: "blue" }}>
                  {t("Requests Management")}
                </Box>
              </Typography>
              <Box className="sub-heading-box">
                <Typography variant="h5" className="bold-text">
                  {t("Requests Management")}
                </Typography>
              </Box>
            </Box>

            <Box className="request-tab-box">
              <Tab label="Complex" data-testId="Complex-tab" className={this.state.tabComplex ? "active" : ""} onClick={()=>{this.handleTab()}} />
              <Tab label="Individual Buildings" data-testId="Buildings-tab" className={!this.state.tabComplex ? "active" : ""}  onClick={()=>{this.handleTab()}}/>
            </Box>

            <Box className="filter-head-box">
              <FilterSelect
                t={t}
                language={language}
                label="Select Country"
                value={this.state.filter.country}
                onChange={(e: any) => this.handleFilterChange(e, "country")}
                option={this.state.countryList}
              />
              <FilterSelect
                t={t}
                language={language}
                label="Select City"
                value={this.state.filter.city}
                onChange={(e: any) => this.handleFilterChange(e, "city")}
                option={this.state.cityList}
              />
              <FilterSelect t={t} language={language} label="Company Name" value="" />
              <FilterSelect
                  t={t}
                  value={this.state.filter.complex}
                  language={language}
                  label={"Select Complex" }
                  option={this.state.complexList}
                  onChange={(e: any) => this.handleFilterChange(e, "complex")}
                />
              <Button className="action-search-btn" startIcon={<SearchIcon />} onClick={()=>{this.getRequestsManagementFilterList()}}>
                {t("Search")}
              </Button>
            </Box>

            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Box className="action-card">
                  {
                   this.state.requestsManagementList.map((item: any, key: any) => {
                      return (
                        <Box className="action-info-box">
                          <Box className="action-content-box">
                            <Box className="content-box">
                              <Box className="action-info">
                                <img src={ceRealEstateIcon} alt="" />
                                <Box>
                                  <span>{t("Real Estate Company")}</span>
                                  <p className="bold-text">{item?.attributes?.compnay_name?.company_name||"-"}</p>
                                </Box>
                              </Box>

                              <Box className="action-info">
                                <img src={ceBuildingIcon} alt="" />
                                <Box>
                                  <span>{this.state.tabComplex ? t("Complex") : t("Building")}</span>
                                  <p className="bold-text">{item?.attributes?.name}</p>
                                </Box>
                              </Box>
                              <Box>
                                <Button id="handleInviteMember" data-testid="invite-member" onClick={()=>{this.handleInviteMember()}}>{t("Invite a new member")}</Button>
                              </Box>
                            </Box>
                          </Box>
                          <Divider />
                          <Box className="action-info-body">
                            <Box className="info-body-box">
                              <Box className="table-row-heading">
                                <span>Title</span>
                                <span>Count</span>
                              </Box>
                              <NavLink to={"/CompanyEmployee/PendingJoinRequest"} >
                                <Box className="table-row-body">
                                  <span>Pending join requests</span>
                                  <span>{item.attributes.pending_join_request}</span>
                                </Box>
                              </NavLink>
                              <NavLink to={"/CompanyEmployee/AwaitAccept"} >
                                <Box className="table-row-body">
                                  <span>Sent invitations awaiting acceptance</span>
                                  <span>{item.attributes.sent_invitaion_awiting_acceptance}</span>
                                </Box>
                              </NavLink>
                              <NavLink to={"/CompanyEmployee/TotalSentInvite"} >
                                <Box className="table-row-body">
                                  <span>Total sent invitations</span>
                                  <span>{item.attributes.totle_sent_invitaion}</span>
                                </Box>
                              </NavLink>
                              <Box className="table-row-body">
                                <span>Accepted invitations by user</span>
                                <span>{item.attributes.accepted_invitaion_by_user}</span>
                              </Box>
                              <Box className="table-row-body">
                                <span>Rejected invitations by user</span>
                                <span>{item.attributes.reject_invitaion_by_user}</span>
                              </Box>
                              <Box className="table-row-body">
                                <span>Total received join requests</span>
                                <span>{item.attributes.totle_recevied_join_request}</span>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )
                    })
                  }
                </Box>
              </Grid>
            </Grid>
          </Container>
        </CEHeaderComponentWeb>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const CERequestManagementStyle: any = {
  RequestManagement: {
    background: "rgb(244, 247, 255)",
    "& .navigation": {
      flexDirection: "column",
      alignItems: "flex-start",
      display: "flex",
      "& .sub-heading-box": {
        justifyContent: "space-between",
        display: "flex",
        width: "100%",
        alignItems: "center",
        marginBottom: "10px",
        marginTop: "10px",
        "& h5": {
          fontSize: "28px",
          fontWeight: "600",
        },
      },
    },
    "& .request-user-filter": {
      alignItems: "center",
      width: "100%",
      flexWrap: "wrap",
      display: "flex",
      margin: "10px 0 15px 0",
      gap: "18px",
      "& .select-year": {
        padding: "12px 12px",
        background: "#fff",
        border: "1px solid lightgrey",
        fontSize: "14px",
        minWidth: "110px",
        borderRadius: 5,
        fontWeight: "bold",
      },
      "& button": {
        color: "white",
        borderRadius: "8px",
        fontWeight: "600",
        background: "#2B6FED",
        padding: "7px 18px",
        height: "40px",
        "& .MuiButton-startIcon": {
          marginLeft: "0",
        },
      },
    },
    "& .request-tab-box": {
      marginTop: "15px",
      marginBottom: "25px",
      alignItems: "center",
      display: "flex",
      gap: "10px",
      "& .MuiTab-root": {
        borderRadius: "25px",
        minWidth: "auto",
        minHeight: "auto",
        background: "#EDF0F9",
        textTransform: "capitalize",
        fontSize: "16px",
        marginRight: "10px",
        padding: "6px 20px",
        color: "gray",
      },
      "& .MuiTab-root.active": {
        color: "white",
        background: "#2C6DF3",
        fontWeight: "600",
        opacity: 1,
      },
      "& Button": {
        color: "white",
        textTransform: "capitalize",
        fontSize: "16px",
        background: "#FE8335",
        minHeight: "auto",
        padding: "6px 20px",
        minWidth: "auto",
        borderRadius: "25px",
        boxShadow: "none",
      },
    },
    "& .action-card": {
      background: "#fff",
      borderRadius: 8,
      boxShadow: "4px 0px 14px #e9e9e9",
      "& .action-info-box": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        "& .action-info": {
          display: "flex",
          alignItems: "center",
          gap: "15px",
          "& span": {
            fontSize: "14px",
            color: "#FC8434",
          },
          "& p": {
            fontWeight: "600",
          },
        },
        "& button": {
          background: "#fc8434",
          color: "white",
          borderRadius: "8px",
          fontWeight: "600",
          padding: "8px 20px",
          fontSize: "14px",
          textTransform: "uppercase",
        },
        "& .action-content-box": {
          width: "100%",
          "& .content-box": {
            display: "flex",
            alignItems: "center",
            gap: "50px",
            padding: "20px",
            justifyContent: "space-between",
          },
        },
      },
      "& hr": {
        width: "100%",
      },
      "& .action-info-body": {
        width: "100%",
        "& .info-body-box": {
          padding: "10px 20px",
        },
        "& .table-row-heading": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: "15px",
          color: "#c7c7c7",
          padding: "10px 0",
          borderBottom: "1px solid #f9f9f9",
        },
        "& .table-row-body": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: "16px",
          padding: "10px 0",
          borderBottom: "1px solid #f9f9f9",
          "& span:last-child": {
            fontWeight: "600",
          },
        },
        "& .table-row-body:last-child": {
          borderBottom: "0",
        },
      },
    },
  },
};
// @ts-ignore
export default withTranslation()(withStyles(CERequestManagementStyle)(withRouter(CERequestManagement)));
// Customizable Area End
