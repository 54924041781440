//Customizable Area Start
import React from "react";

import ComplexBuildingDetailsController, {
    Props,
} from './ComplexBuildingDetailsController.web'
import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";

import {withRouter} from 'react-router-dom'
//@ts-ignore

import BuildingDetailsPage from './BuildingDetailsPage.web'

class ComplexBuildingDetails extends ComplexBuildingDetailsController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { navigation } = this.props;
        const match:any= this.props.match;
        const id = match?.params?.id;

        return (
           <div>
            <MainScreen>
                <BuildingDetailsPage companyId={id} navigation={navigation} id={"complex_listings"} history={history} />
            </MainScreen>
            </div>
           
        );
    }
}

const webStyle = {
};

export default withRouter(ComplexBuildingDetails)
//Customizable Area End