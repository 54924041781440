import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { CEApiCall } from "../../../../components/src/APICallComponent/index.web";
import { ApiCatchErrorResponse, ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { handleFilterComplexList } from "../../../../components/src/UtilFunction.web";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  history: any;
  match: any;
  location: any;
  t: (val: string) => string;
  i18n: {
    language: string;
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;

  pmReportList: any[];
  pagination: any;

  activePM: number | string;
  avgPMUnit: number | string;

  countriesList: any[];
  citiesList: any[];
  complexList: any[];
  filterReportComplexList: any[];
  buildingList: any[];
  salesManagerList: any[];
  accountManagerList: any[];
  propertyManagerList: any[];

  filter: {
    isComplex: number;
    country: string;
    city: string;
    complex: string;
    building: string;
    propertyManager: string;
    salesManager: string;
    accountManager: string;
  };

  sortBy: string;
  search: string;
  page: number;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CEPropertyManagerReportController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  GetPropertyManagerReportListCallId: any;
  GetReportCountryCallId: any;
  GetReportCitiesCallId: any;
  GetReportComplexCallId: any;
  GetReportBuildingCallId: any;
  GetSalesManagerCallId: any;
  GetAccountManagerCallId: any;
  GetPropertyManagerCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      loading: false,

      pmReportList: [],
      pagination: null,

      avgPMUnit: 0,
      activePM: 0,

      countriesList: [],
      citiesList: [],
      complexList: [],
      filterReportComplexList: [],
      buildingList: [],
      salesManagerList: [],
      accountManagerList: [],
      propertyManagerList: [],

      filter: {
        isComplex: 1,
        country: "",
        city: "",
        complex: "",
        building: "",
        propertyManager: "",
        salesManager: "",
        accountManager: "",
      },

      sortBy: "",
      search: "",
      page: 1,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      switch (apiRequestCallId) {
        case this.GetPropertyManagerReportListCallId:
          return this.handlePropertyManagerReportResponse(responseJson);
        case this.GetReportCountryCallId:
          return this.handleCountryResponse(responseJson);
        case this.GetReportCitiesCallId:
          return this.handleCityListResponse(responseJson);
        case this.GetReportComplexCallId:
          return this.handleComplexListResponse(responseJson);
        case this.GetReportBuildingCallId:
          return this.handleBuildingListResponse(responseJson);
        case this.GetSalesManagerCallId:
          return this.handleSalesManagerResponse(responseJson);
        case this.GetAccountManagerCallId:
          return this.handleAccountManagerResponse(responseJson);
        case this.GetPropertyManagerCallId:
          return this.handlePropertyManagerResponse(responseJson);
        default:
          break;
      }

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      } else {
        ApiErrorResponse(responseJson);
      }
      ApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getPropertyManagerReportList();

    this.getReportCountryList();
    this.getReportComplexList("", "");

    this.getSalesManagerList();
    this.getAccountManagerList();
    this.getPropertyManagerList();
  }

  async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    if (prevState.page !== this.state.page) {
      this.getPropertyManagerReportList();
    }
  }

  handleAllEmptyFilter = (value: string) => (value === "all" ? "" : value);

  getPropertyManagerReportList = () => {
    this.setState({ loading: true }, async () => {
      const { country, city, complex, building, accountManager, salesManager, propertyManager } = this.state.filter;
      const { search, page, sortBy } = this.state;
      this.GetPropertyManagerReportListCallId = await CEApiCall({
        contentType: "application/json",
        method: "GET",
        endPoint: `bx_block_report/property_manager_report/property_manager_report_listing?search=${search}&account_manager=${this.handleAllEmptyFilter(
          accountManager
        )}&sales_manager=${this.handleAllEmptyFilter(salesManager)}&complex=${this.handleAllEmptyFilter(
          complex
        )}&country=${this.handleAllEmptyFilter(country)}&city=${this.handleAllEmptyFilter(
          city
        )}&page=${page}&per_page=10&building=${this.handleAllEmptyFilter(
          building
        )}&sort_by=${sortBy}&property_manager=${this.handleAllEmptyFilter(propertyManager)}`,
      });
    });
  };

  handlePropertyManagerReportResponse = (responseJson: any) => {
    this.setState({ loading: false }, () => {
      if (responseJson && responseJson.data) {
        this.setState({
          pmReportList: responseJson.data,
          pagination: responseJson.meta.pagination,
          avgPMUnit: responseJson.meta.avarege_unit_per_manager,
          activePM: responseJson.meta.manager_living_rate,
        });
      }
    });
  };

  getReportCountryList = async () => {
    this.GetReportCountryCallId = await CEApiCall({
      method: "get",
      endPoint: `bx_block_address/country_list`,
    });
  };

  handleCountryResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.setState({ countriesList: responseJson.data.countries.map((item: any) => ({ label: item, value: item })) });
    }
  };

  getReportCityList = async (country: any) => {
    this.GetReportCitiesCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_custom_form/incident_managements/city_list?country=${this.handleAllEmptyFilter(country)}`,
    });
  };

  handleCityListResponse = (responseJson: any) => {
    if (responseJson && responseJson.city_list) {
      this.setState({
        citiesList: responseJson.city_list.map((item: any) => ({ label: item.city, value: item.city })),
      });
    }
  };

  getReportComplexList = async (country?: string, city?: string) => {
    this.GetReportComplexCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_interactive_faqs/complex_list?city=${city}&country=${country}`,
    });
  };

  handleComplexListResponse = (responseJson: any) => {
    if (responseJson && responseJson.complexes) {
      this.setState({ complexList: responseJson.complexes.data }, () => {
        this.setState({
          filterReportComplexList: handleFilterComplexList(1, this.state.complexList),
        });
      });
    }
  };

  getReportBuildingList = async (complexId: any) => {
    this.GetReportBuildingCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_announcement/announcements/building_list?society_management_id=${complexId}`,
    });
  };

  handleBuildingListResponse = (responseJson: any) => {
    if (responseJson && responseJson.buildings) {
      this.setState({
        buildingList: responseJson.buildings.map((item: any) => ({ label: item.name, value: item.id })),
      });
    }
  };

  getSalesManagerList = async () => {
    this.GetSalesManagerCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_report/property_manager_report/sales_manager_listing`,
    });
  };

  handleSalesManagerResponse = (responseJson: any) => {
    if (responseJson && responseJson.sales_manager_listing) {
      this.setState({
        salesManagerList: responseJson.sales_manager_listing.map((item: any) => ({
          label: item.first_name + " " + item.last_name,
          value: item.id,
        })),
      });
    }
  };

  getAccountManagerList = async () => {
    this.GetAccountManagerCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_report/property_manager_report/account_manager_listing`,
    });
  };

  handleAccountManagerResponse = (responseJson: any) => {
    if (responseJson && responseJson.account_manager_listing) {
      this.setState({
        accountManagerList: responseJson.account_manager_listing.map((item: any) => ({
          label: item.first_name + " " + item.last_name,
          value: item.id,
        })),
      });
    }
  };

  getPropertyManagerList = async () => {
    this.GetPropertyManagerCallId = await CEApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: `bx_block_report/property_manager_report/property_manager_listing`,
    });
  };

  handlePropertyManagerResponse = (responseJson: any) => {
    if (responseJson && responseJson.property_manager_listing) {
      this.setState({
        propertyManagerList: responseJson.property_manager_listing.map((item: any) => ({
          label: item.full_name,
          value: item.id,
        })),
      });
    }
  };

  handleReportFilterChange = (event: any, stateParam: string) => {
    const value = event.target.value;

    if (stateParam === "country") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            country: value,
            city: "",
            complex: "",
            building: "",
            propertyManager: "",
            salesManager: "",
            accountManager: "",
          },
        },
        () => this.getReportCityList(value)
      );
    } else if (stateParam === "city") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            city: value,
            complex: "",
            building: "",
            propertyManager: "",
            salesManager: "",
            accountManager: "",
          },
        },
        () => {
          const { country, city } = this.state.filter;
          this.getReportComplexList(country, city);
        }
      );
    } else if (stateParam === "complex") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            complex: value,
            building: "",
            propertyManager: "",
            salesManager: "",
            accountManager: "",
          },
        },
        () => this.getReportBuildingList(value)
      );
    } else if (stateParam === "building") {
      this.setState({
        filter: {
          ...this.state.filter,
          building: value,
          propertyManager: "",
          salesManager: "",
          accountManager: "",
        },
      });
    } else if (stateParam === "propertyManager") {
      this.setState({
        filter: {
          ...this.state.filter,
          propertyManager: value,
          salesManager: "",
          accountManager: "",
        },
      });
    } else if (stateParam === "salesManager") {
      this.setState({
        filter: {
          ...this.state.filter,
          salesManager: value,
          accountManager: "",
        },
      });
    } else if (stateParam === "accountManager") {
      this.setState({
        filter: {
          ...this.state.filter,
          accountManager: value,
        },
      });
    } else if (stateParam === "isComplex") {
      this.setState(
        {
          filter: {
            ...this.state.filter,
            isComplex: value,
          },
        },
        () =>
          this.setState({
            filterReportComplexList: handleFilterComplexList(value, this.state.complexList),
          })
      );
    }
  };

  handleReportPagination = (e: any, value: any) => {
    this.setState({ page: value });
  };
  // Customizable Area End
}
