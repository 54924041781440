// Customizable Area Start
import React from "react";
import CompEmpFaqController, { Props } from "./CompEmpFaqController.web";
import "./CompEmpFaq.web.css";
import {
  Backdrop,
  Button,
  Fade,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Link,
  withStyles,
  Container,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import { Upload, Down, Close, Msg, Bin, Edit, SearchIcon, QuestionImage } from "../assets";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AlertError from "../../../../components/src/AlertError.web";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loader from "../../../../components/src/Loader.web";
export const configJSON = require("../config");
// @ts-ignore
import { baseURL } from "../../../../framework/src/config";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { FaqChairmanStyleWeb } from "../FaqChairmanStyle.web";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { Form, Formik } from "formik";
import { ErrorHandler } from "../../../../components/src/UtilFunction.web";
import { languageCondition } from "../../../../components/src/ConditionManager.web";
// Customizable Area End

export class CompEmpFaq extends CompEmpFaqController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }

  countrySelect = () => {
    return (
      <>
        <Select
          itemID="specificCountryID"
          onChange={(e: any) => this.specificCountyData(e)}
          value={this.state.specificCountry}
          className="select-input"
          name="Dropdown"
          id="Dropdown"
          displayEmpty
        >
          <MenuItem value="" disabled>
            Select Country
          </MenuItem>
          <MenuItem value="all">All</MenuItem>
          {this.state.countryList.map((country: any, i: number) => {
            return (
              <MenuItem value={country} key={i}>
                {country}
              </MenuItem>
            );
          })}
        </Select>
      </>
    );
  };

  citySelect = () => {
    return (
      <>
        <Select
          itemID="specificCityDataID"
          onChange={this.specificCityData}
          className="select-input"
          value={this.state.specificCity}
          name="Dropdown"
          id="Dropdown"
          displayEmpty
        >
          <MenuItem value="" disabled>
            Select City
          </MenuItem>
          <MenuItem value="all">All</MenuItem>
          {this.state.cityList.map((city: any, i: number) => {
            return (
              <MenuItem value={city.city} key={i}>
                {city.city}
              </MenuItem>
            );
          })}
        </Select>
      </>
    );
  };

  complexSelect = () => {
    return (
      <>
        <Select
          itemID="specificComplexDataID"
          onChange={(e: any) => this.specificComplexData(e)}
          className="select-input"
          name="Dropdown"
          id="Dropdown"
          value={this.state.specificComplex}
          displayEmpty
        >
          <MenuItem value="" disabled>
            Select Complex
          </MenuItem>
          <MenuItem value="all">All</MenuItem>
          {this.state.complexDataForDropdown.map((item: any) => {
            return (
              <MenuItem value={item.attributes.name} key={item.id}>
                {item.attributes.name}
              </MenuItem>
            );
          })}
        </Select>
      </>
    );
  };

  userTypeSelect = () => {
    return (
      <>
        <Select
          itemID="specificUserTypeDataID"
          onChange={(e: any) => this.specificUserTypeData(e)}
          className="select-input"
          name="Dropdown"
          id="Dropdown"
          displayEmpty
          value={this.state.specificUserType}
        >
          <MenuItem value="" disabled>
            Select User Type
          </MenuItem>
          <MenuItem value="all">All</MenuItem>
          {this.state.userData.map((item: any, index: any) => {
            return (
              <MenuItem value={item.attributes.title} key={index}>
                {item.attributes.title}
              </MenuItem>
            );
          })}
        </Select>
      </>
    );
  };

  categoryList = () => {
    return (
      <>
        {this.state.categoryData.length > 0 &&
          this.state.categoryData.map((item: any) => {
            return (
              <button
                itemID="showAllQuestionsID"
                className={`ManageFAQ_Button ${
                  this.state.faqCategoryId === item.attributes.id ? "ManageFAQ_Button_active" : ""
                }`}
                data-testid="handlecategorybutton"
                id="handlecategorybutton"
                onClick={() =>
                  this.showAllQuestions(
                    item.attributes.id,
                    item.attributes.name,
                    item.attributes.society_management,
                    item.attributes.society_id
                  )
                }
              >
                {item.attributes.name}
              </button>
            );
          })}
      </>
    );
  };

  questionList = () => {
    return (
      <>
        {this.state.questionData.length > 0 &&
          this.state.questionData.map((item: any) => {
            return (
              <>
                <div className="Accordion_Container">
                  <Accordion className="Accordion_Border">
                    <div className="Accordion_Settings">
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} className="Accordion_All_Component">
                        <p className="Question_Format bold-text">{item.attributes.title}</p>
                      </AccordionSummary>
                      <div className="Edit_Delete">
                        <img
                          id="deleteBtn"
                          data-testid="deleteBtn"
                          src={Bin}
                          alt="Bin"
                          className="Bin_Component"
                          onClick={() => this.deleteQueOpen(item.attributes.id)}
                        />
                        <img
                          id="editBtn"
                          src={Edit}
                          alt="Edit"
                          className="Edit_Component"
                          onClick={() =>
                            this.editQueOpen(item.attributes.title, item.attributes.content, item.attributes.id)
                          }
                        />
                      </div>
                    </div>
                    <AccordionDetails className="faq_answer_accordion">
                      <p className="Answer_Format">{item.attributes.content}</p>
                      <Box display="flex">
                        {item.attributes.images.length > 0 &&
                          item.attributes.images.map((image: any, i: number) => {
                            return (
                              <div className="faq_image_pad_accordion" key={i}>
                                <img src={`${baseURL}${image.url}`} className="faq_preview_image" />
                              </div>
                            );
                          })}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="QueHR" />
              </>
            );
          })}
      </>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, i18n }: any = this.props;
    const language = i18n.language;

    return (
      <>
        <Loader loading={this.state.loading} />
        <CEHeaderComponentWeb>
          <Container>
            <div className="ManageFAQ_Container">
              <div>
                <p className="ManageFAQ_Link">
                  <Link href="/">
                    <span className="ManageFAQ_Link_Grey">Home</span>
                  </Link>{" "}
                  / <span className="ManageFAQ_Link_Blue">Manage FAQ</span>
                </p>
                <p className="ManageFAQ_Tag bold-text ">Manage FAQ</p>
              </div>
              <div className="top-bar-filter" style={{ marginBottom: "15px" }}>
                <div className="filter-filter">{this.countrySelect()}</div>
                <div className="filter-filter">{this.citySelect()}</div>
                <div className="filter-filter">{this.complexSelect()}</div>
                <div className="filter-filter">{this.userTypeSelect()}</div>
                <Box className="filter-filter">
                  <Button
                    className="handleSearchFilterForInvoices search-btn-estate"
                    onClick={this.showAllCategoryData}
                    startIcon={<img src={SearchIcon} />}
                  >
                    {"Search"}
                  </Button>
                </Box>
              </div>
              <div className="ManageFAQ_Button_Container ">
                <div className="ManageFAQ_Button_DIV">{this.categoryList()}</div>
                <div className="">
                  <div
                    data-testid="addNewCategory"
                    className="ManageFAQ_ADD_Button bold-text"
                    onClick={this.handleOpen}
                    id="addNewCategory"
                  >
                    + ADD NEW CATEGORY
                  </div>
                </div>
              </div>
              <div
                className={
                  this.state.Show ? "ManageFAQ_Question_Show" : "ManageFAQ_Question_Show ManageFAQ_Question_empty"
                }
              >
                <div className={this.state.Show ? "Hide" : "Show"}>
                  <div className="Question_Img_div">
                    <img src={QuestionImage} alt="Question" />
                  </div>
                  <p className="ManageFAQ_Empty_Text bold-text">No question added</p>
                </div>
                <div className={this.state.Show ? "Show" : "Hide"}>{this.questionList()}</div>
              </div>
              {this.state.categoryData.length > 0 && (
                <div className="ManageFAQ_Final_button_Container ">
                  <div id="removeVehiclesBtn" className="Remove_Vehicles_FAQ bold-text" onClick={this.deleteCateOpen}>
                    Remove {this.state.categoryName} FAQ
                  </div>
                  <div id="addQuestionBtn" className="ADD_QUESTION_Btt bold-text" onClick={this.addQueOpen}>
                    ADD QUESTION
                  </div>
                </div>
              )}
            </div>
          </Container>
        </CEHeaderComponentWeb>

        <AlertError
          show={this.state.alertError}
          handleClose={this.handleAlertErrorClose}
          message={this.state.alertMessage}
        />

        <Modal className="modal" open={this.state.setOpen} onClose={this.handleClose}>
          <Fade in={this.state.setOpen}>
            <div className="paper">
              <Formik
                initialValues={{
                  society_id: "",
                  dashboard_type: "",
                  name: "",
                }}
                validationSchema={this.categoryFormSchema}
                enableReinitialize
                onSubmit={(values: any, { resetForm }) => {
                  this.addCategoryList(values);
                  resetForm();
                  this.handleClose();
                }}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => {
                  return (
                    <Form onSubmit={handleSubmit} translate="yes" className="edit-property-form">
                      <div className="modal_Heading_Container">
                        <p className="modalHeading bold-text">Add Category</p>
                        <img
                          id="categoryCloseBtn"
                          src={Close}
                          alt="Close"
                          className="Close_Button"
                          onClick={this.handleClose}
                        />
                      </div>
                      <div className="HR" />
                      <div className="Modal_Field_Container">
                        <FormControl variant="outlined" className="Modal_Select_Field">
                          <Select
                            displayEmpty
                            itemID="showAllComplexID"
                            className="Modal_Select_Field"
                            value={values.society_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="society_id"
                          >
                            <MenuItem value="">Select Complex / Building</MenuItem>
                            {this.state.complexData.map((item: any) => {
                              return (
                                <MenuItem id="complexId" value={item.id} key={item.id}>
                                  {item.attributes.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <img id="dropDownopenBtn" src={Down} alt="Down" className="Down" />
                        </FormControl>
                        {ErrorHandler(t, touched.society_id, errors.society_id)}
                      </div>
                      <div className="Modal_Field_Container">
                        <FormControl variant="outlined" className="Modal_Select_Field">
                          <Select
                            displayEmpty
                            itemID="closeCategoryID"
                            className="Modal_Select_Field"
                            label="Select Category"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.dashboard_type}
                            name="dashboard_type"
                          >
                            <MenuItem value="">Select Category</MenuItem>
                            {this.state.userData.map((item: any, index: any) => {
                              return (
                                <MenuItem id="categoryDashboardID" key={index} value={item.attributes.title}>
                                  {item.attributes.title}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <img id="dropDownOpenBtn1" src={Down} alt="Down" className="Down" />
                        </FormControl>
                        {ErrorHandler(t, touched.dashboard_type, errors.dashboard_type)}
                      </div>
                      <div className="Modal_Field_Container">
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            itemID="categoryTitleID"
                            id="outlined-basic"
                            variant="outlined"
                            className="Modal_Text_Field"
                            placeholder="Category Title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            name="name"
                          />
                        </FormControl>
                        {ErrorHandler(t, touched.name, errors.name)}
                      </div>
                      <div className="Modal_Button_Container">
                        <div id="cancelBtn" className="ModalCancelDiv bold-text" onClick={this.handleClose}>
                          Cancel
                        </div>
                        <Button id="categryConfirmBtn" className="ModalConfirmDiv bold-text" type="submit">
                          Confirm
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modal"
          open={this.state.addQue}
          onClose={this.addQueClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.addQue}>
            <div className="addQuePaper">
              <div className="modal_Heading_Container">
                <p className="modalHeading bold-text">Add Question</p>
                <img
                  id="questionCloseBtn"
                  src={Close}
                  alt="Close"
                  className="Close_Button"
                  onClick={this.addQueClose}
                />
              </div>
              <div className="HR" />
              <div className="modal_Heading_Container">
                <FormControl variant="outlined" className="Modal_Select_Field">
                  <InputLabel className="Modal_Select_Field_Text" id="1">
                    {this.state.questionData[0]?.attributes?.society_name}
                  </InputLabel>
                  <Select
                    itemID="showallComplexId"
                    className="Modal_Select_Field2"
                    id="1"
                    labelId="1"
                    disabled
                    open={this.state.complex}
                    onClick={() => this.showAllComplex()}
                    onOpen={(edd: any) => this.complexOpen2(edd)}
                    value={this.state.activeComplex}
                    onClose={this.complexClose}
                    label="Select Complex / Building"
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      Select Complex / Building
                    </MenuItem>
                    {this.state.complexData.map((item: any, index: any) => {
                      return (
                        <MenuItem
                          id="complexquestionID"
                          value={item.attributes.name}
                          onClick={() => this.complexquestionid(item.id)}
                        >
                          {item.attributes.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <img className="Down2" id="openDropDownBtn" alt="Down" src={Down} />
                </FormControl>
              </div>
              <div className="modal_Heading_Container">
                <FormControl variant="outlined" className="Modal_Select_Field">
                  <InputLabel className="Modal_Select_Field_Text" id="2">
                    {this.state.questionData[0]?.attributes?.category_name}
                  </InputLabel>
                  <Select
                    itemID="showAllCategoryID"
                    className="Modal_Select_Field"
                    id="2"
                    disabled
                    data-testid="selectCategoryFaq"
                    open={this.state.category}
                    onOpen={(erw) => this.categoryOpen1(erw)}
                    onClick={() => this.showAllCategory}
                    onClose={this.categoryClose}
                    value={this.state.categoryName}
                    label="Select Category"
                  >
                    {this.state.categoryData.map((item: any) => {
                        return (
                          <MenuItem
                            id="categoryQuestionId"
                            value={item.attributes.name}
                            onClick={() => this.categoryquestionid(item.id)}
                          >
                            {item.attributes.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <img id="questionCategoryOpenBtn1" src={Down} alt="Down" className="Down" />
                </FormControl>
              </div>
              <div>
                <form autoComplete="off" className="modal_Heading_Container">
                  <TextField
                    itemID="titleQuestionID"
                    id="outlined-basic"
                    variant="outlined"
                    className="Modal_Text_Field"
                    placeholder="Title Question"
                    onChange={(text: any) => this.setState({ questionTitle: text?.target.value })}
                  />
                </form>
              </div>
              <div>
                <form autoComplete="off" className="modal_Heading_Container">
                  <textarea
                    itemID="answerID"
                    className="Add_Que_Text_Area"
                    placeholder="Answer"
                    onChange={(text: any) => this.setState({ answerTitle: text?.target.value })}
                  />
                </form>
              </div>
              <div>
                {this.state.previewImage.length > 0 ? (
                  <>
                    {this.state.previewImage.length < 4 ? (
                      <Box display="flex" className="faq_image_box faq_image_box_preview1">
                        <form autoComplete="off" className="modal_Heading_Container modal_Heading_Container1">
                          <input
                            type="file"
                            name="myfile"
                            accept="image/*"
                            data-testid="previewImageFaq"
                            onChange={this.uploadImage}
                            id="faq_image_upload"
                          />
                          <label htmlFor="faq_image_upload" className="faq_preview_label faq_preview_label1">
                            <div className="Add_Que_Imge_Div_upload Add_Que_Imge_Div_upload1">
                              <div>
                                <div className="Question_Img_div Question_Img_div1">
                                  <img src={Upload} alt="Upload" className="Upload" />
                                </div>
                                <p className="Upload_Image_Text Upload_Image_Text1">Upload Image</p>
                              </div>
                            </div>
                          </label>
                        </form>
                        {this.state.previewImage.map((image: any, i: number) => {
                          return (
                            <div className="faq_image_pad faq_image_pad1" key={i}>
                              <img src={image} className="faq_preview_image faq_preview_image1" />
                              <HighlightOffIcon
                                data-testid="removePreviewFaq"
                                className="faq_image_close_icon faq_image_close_icon1"
                                onClick={() => this.handleImageRemove(i)}
                              />
                            </div>
                          );
                        })}
                      </Box>
                    ) : (
                      <Box display="flex" className="faq_image_box faq_image_box2">
                        {this.state.previewImage.map((image: any, i: number) => {
                          return (
                            <div className="faq_image_pad faq_image_pad2" key={i}>
                              <img src={image} className="faq_preview_image faq_preview_image2" />
                              <HighlightOffIcon
                                data-testid="removePreviewFaq"
                                className="faq_image_close_icon faq_image_close_icon2"
                                onClick={() => this.handleImageRemove(i)}
                              />
                            </div>
                          );
                        })}
                      </Box>
                    )}
                  </>
                ) : (
                  <form autoComplete="off" className="modal_Heading_Container modal_Heading_Container2">
                    <input
                      type="file"
                      name="myfile"
                      accept="image/*"
                      data-testid="previewImageFaq"
                      onChange={this.uploadImage}
                      id="faq_image_upload"
                    />
                    <label htmlFor="faq_image_upload" className="faq_upload_label faq_upload_label2">
                      <div className="Add_Que_Imge_Div Add_Que_Imge_Div2">
                        <div>
                          <div className="Question_Img_div Question_Img_div2">
                            <img src={Upload} alt="Upload" className="Upload" />
                          </div>
                          <p className="Upload_Image_Text Upload_Image_Text2">Upload Image (Optional)</p>
                        </div>
                      </div>
                    </label>
                  </form>
                )}
              </div>
              <div className="Modal_Button_Container">
                <div id="questionCancelBtn" className="ModalCancelDiv bold-text" onClick={this.addQueClose}>
                  Cancel
                </div>
                <div
                  className="ModalConfirmDiv bold-text"
                  itemID="updateAddQuestionID"
                  onClick={() => {
                    if (this.handleCheckAddForm()) {
                      this.addFAQList();
                    }
                  }}
                >
                  ADD
                </div>
              </div>
            </div>
          </Fade>
        </Modal>

        <Dialog
          className="delete-dialog"
          fullWidth
          dir={languageCondition(language, "rtl", "ltr")}
          onClose={() => this.deleteCateClose()}
          open={this.state.deleteCate}
        >
          <DialogContent style={{ margin: "15px 0" }}>
            <Box textAlign="center">
              <img className="comment-image" src={Msg} alt="comment" />
              <Typography variant="h6" className="bold-text">
                {t("Do you want to delete the category?")}
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "0px" }}>
                {t("Are you sure you want to delete the category")} {this.state.categoryName}?
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "15px" }}>
                {t("All FAQs related to this category will be deleted permanently.")}
              </Typography>
              <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                <Button onClick={() => this.deleteCateClose()} className="cancel-button" style={{ width: "200px" }}>
                  {t("No, Don't Delete")}
                </Button>
                <Button
                  onClick={() => this.deleteCategoryAPI()}
                  itemID="deleteFAQAPIID"
                  style={{ width: "200px" }}
                  className="add-button"
                >
                  {t("Yes Delete")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>

        <Dialog
          dir={languageCondition(language, "rtl", "ltr")}
          className="delete-dialog"
          fullWidth
          onClose={() => this.deleteQueClose()}
          open={this.state.deleteQue}
        >
          <DialogContent style={{ margin: "15px 0" }}>
            <Box textAlign="center">
              <img className="comment-image" src={Msg} alt="comment" />
              <Typography variant="h6" className="bold-text">
                {t("Do you want to delete the question?")}
              </Typography>
              <Typography variant="body1" style={{ marginBottom: "15px" }}>
                {t("Are you sure that you want to delete this question?")}
              </Typography>
              <DialogActions className="dialog-button-group" style={{ gap: "10px" }}>
                <Button onClick={() => this.deleteQueClose()} className="cancel-button" style={{ width: "200px" }}>
                  {t("No, Don't Delete")}
                </Button>
                <Button onClick={() => this.deleteFAQAPI()} className="add-button" style={{ width: "200px" }}>
                  {t("Yes Delete")}
                </Button>
              </DialogActions>
            </Box>
          </DialogContent>
        </Dialog>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="modal"
          open={this.state.editQue}
          onClose={this.editQueClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.editQue}>
            <div className="addQuePaper">
              <div className="modal_Heading_Container">
                <p className="modalHeading bold-text">Edit Question</p>
                <img
                  id="editQuestionBtn"
                  src={Close}
                  alt="Close"
                  className="Close_Button"
                  onClick={this.editQueClose}
                />
              </div>
              <div className="HR" />
              <div className="modal_Heading_Container">
                <FormControl variant="outlined" className="Modal_Select_Field">
                  <InputLabel className="Modal_Select_Field_Text" id="1">
                    {this.state.editQuestionData?.attributes.society_name}
                  </InputLabel>
                  <Select
                    className="Modal_Select_Field"
                    open={this.state.complex}
                    onClose={this.complexClose}
                    onOpen={this.complexOpen}
                    value={this.state.editQuestionData?.attributes.society_name}
                  >
                    {this.state.complexData.map((item: any, index: any) => {
                      return (
                        <MenuItem
                          id="complexquestionID"
                          value={item.attributes.name}
                          onClick={() => this.complexquestionid(item.id)}
                        >
                          {item.attributes.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <img id="editDropDownBtn" src={Down} alt="Down" className="Down" />
                </FormControl>
              </div>
              <div className="modal_Heading_Container">
                <FormControl variant="outlined" className="Modal_Select_Field">
                  <InputLabel className="Modal_Select_Field_Text" id="2">
                    {this.state.editQuestionData?.attributes.category_name}
                  </InputLabel>
                  <Select
                    className="Modal_Select_Field"
                    open={this.state.category}
                    onClose={this.categoryClose}
                    onOpen={this.categoryOpen}
                    value={this.state.editQuestionData?.attributes.category_name}
                  >
                    {this.state.categoryData.map((item: any, index: any) => {
                        return (
                          <MenuItem
                            id="categoryQuestionId"
                            value={item.attributes.name}
                            onClick={() => this.categoryquestionid(item.id)}
                          >
                            {item.attributes.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <img id="editDropDownBtn" src={Down} alt="Down" className="Down" />
                </FormControl>
              </div>
              <div>
                <form autoComplete="off" className="modal_Heading_Container">
                  <TextField
                    itemID="questionEditID"
                    id="outlined-basic"
                    variant="outlined"
                    className="Modal_Text_Field"
                    value={this.state.questionEdit}
                    placeholder={"Please add your questions"}
                    onChange={(e) => this.editQuestion(e)}
                  />
                </form>
              </div>
              <div>
                <form autoComplete="off" className="modal_Heading_Container">
                  <textarea
                    className="Add_Que_Text_Area"
                    itemID="questionContentID"
                    value={this.state.questionContent}
                    placeholder={"Please add your questions"}
                    onChange={(e) => this.editContent(e)}
                  />
                </form>
              </div>
              {this.state.editPreviewImage.length ? (
                <>
                  {this.state.editPreviewImage.length < 4 ? (
                    <Box display="flex" className="faq_image_box faq_image_box3">
                      <form autoComplete="off" className="modal_Heading_Container modal_Heading_Container3">
                        <input
                          type="file"
                          name="myfile"
                          accept="image/*"
                          data-testid="previewImageFaqEdit"
                          onChange={this.uploadEditImage}
                          id="faq_image_upload"
                        />
                        <label htmlFor="faq_image_upload" className="faq_preview_label faq_preview_label3">
                          <div className="Add_Que_Imge_Div_upload Add_Que_Imge_Div_upload3">
                            <div>
                              <div className="Question_Img_div Question_Img_div3">
                                <img src={Upload} alt="Upload" className="Upload" />
                              </div>
                              <p className="Upload_Image_Text Upload_Image_Text3">Upload Image</p>
                            </div>
                          </div>
                        </label>
                      </form>
                      {this.state.editPreviewImage.map((image: any, i: number) => {
                        return (
                          <div className="faq_image_pad faq_image_pad4" key={i}>
                            <img src={image.preview} className="faq_preview_image faq_preview_image4" />
                            <HighlightOffIcon
                              data-testid="removePreviewFaqEdit"
                              className="faq_image_close_icon faq_image_close_icon4"
                              onClick={() => this.handleEditImageRemove(image.id, i)}
                            />
                          </div>
                        );
                      })}
                    </Box>
                  ) : (
                    <Box display="flex" className="faq_image_box faq_image_box5">
                      {this.state.editPreviewImage.map((image: any, i: number) => {
                        return (
                          <div className="faq_image_pad faq_image_pad5" key={i}>
                            <img src={image.preview} className="faq_preview_image faq_preview_image5" />
                            <HighlightOffIcon
                              data-testid="removePreviewFaqEdit"
                              className="faq_image_close_icon faq_image_close_icon5"
                              onClick={() => this.handleEditImageRemove(image.id, i)}
                            />
                          </div>
                        );
                      })}
                    </Box>
                  )}
                </>
              ) : (
                <form autoComplete="off" className="modal_Heading_Container modal_Heading_Container5">
                  <input
                    type="file"
                    name="myfile"
                    accept="image/*"
                    data-testid="previewImageFaqEdit"
                    onChange={this.uploadEditImage}
                    id="faq_image_upload"
                  />
                  <label htmlFor="faq_image_upload" className="faq_upload_label faq_upload_label5">
                    <div className="Add_Que_Imge_Div Add_Que_Imge_Div5">
                      <div>
                        <div className="Question_Img_div Question_Img_div5">
                          <img src={Upload} alt="Upload" className="Upload" />
                        </div>
                        <p className="Upload_Image_Text Upload_Image_Text5">Upload Image (Optional)</p>
                      </div>
                    </div>
                  </label>
                </form>
              )}
              <div className="Modal_Button_Container">
                <div id="editCancelBtn" className="ModalCancelDiv bold-text" onClick={this.editQueClose}>
                  Cancel
                </div>
                <div
                  className="ModalConfirmDiv bold-text"
                  itemID="updateQuestionID"
                  onClick={() => {
                    if (this.handleCheckEditForm()) {
                      this.updateQuestionData();
                    }
                  }}
                >
                  SAVE
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withTranslation()(withStyles(FaqChairmanStyleWeb)(withRouter(CompEmpFaq)));
// Customizable Area End
