// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const TenantLogo = require("../assets/TenantLogo.png");
export const globalIcon = require("../assets/global.png");
export const buildingLogo = require("../assets/BuildingLogo.png");
export const chairmanUser = require("../assets/ChairmanUser.jpg");
export const notification = require("../assets/notification.png");
export const keyhand = require("../assets/key.png");
export const keyrented = require("../assets/keyrented.png");
export const money = require("../assets/money.png");
export const registered = require("../assets/D-registered.png");
export const activemembers = require("../assets/activemembers.png");
export const members = require("../assets/members.png");
export const overdue = require("../assets/D-overdue.png");
export const Cardcalendar = require("../assets/Card-calendar.png");
export const awated = require("../assets/awated.png");
export const xmark = require("../assets/x-mark.png");
export const Check_Mark = require("../assets/Check_Mark.png");
export const account = require("../assets/D-account.png");
export const location = require("../assets/location.png");
export const ticket_calendar = require("../assets/D-ticket_calendar.png");
export const ticket = require("../assets/D-ticket.png");
export const ticketclock = require("../assets/D-ticketclock.png");
export const riyal = require("../assets/riyal.png");
export const expense = require("../assets/expense.png");
export const statistic = require("../assets/statistic.png");
export const removeuser = require("../assets/remove-user.png");
export const approvedbudget = require("../assets/approvedbudget.png");
export const chatIcon = require("../assets/chat.png");
export const ProfileIcon = require("../assets/profile.png");
export const LogoutIcon = require("../assets/logout.png");
export const LogoutDialogIcon = require("../assets/logout-dialog.png");
export const BuildingLogo = require("../assets/building1.png");
export const hamburgerIcon = require("../assets/hamburger.png");
export const myTeam = require("../assets/myteam.png");
export const communityManagement = require("../assets/community.png");
export const meetings = require("../assets/meetings.png");
export const complex = require("../assets/B&A.png");
// Sidebar
export const SidebarEmail = require("../assets/side-email.png");
export const SidebarFaq = require("../assets/side-faq.png");
export const SidebarFee = require("../assets/side-fee.png");
export const SidebarLogout = require("../assets/side-logout.png");
export const SidebarNeighbor = require("../assets/side-neighbor.png");
export const SidebarProfile = require("../assets/side-profile.png");
export const SidebarUnit = require("../assets/side-unit.png");
export const SidebarIncident = require("../assets/side-incident.png");
export const SidebarLogoutDialog = require("../assets/side-logout-dialog.png");
// Dashboard
export const DashboardAnnouncement = require("../assets/dash-announcement.png");
export const DashboardContract = require("../assets/dash-contract.png");
export const DashboardDocument = require("../assets/dash-document.png");
export const DashboardExpenseDollar = require("../assets/dash-expense-dollar.png");
export const DashboardExpense = require("../assets/dash-expense.png");
export const DashboardIncident = require("../assets/dash-incident.png");
export const DashboardInfo = require("../assets/dash-info.png");
export const DashboardInvoice = require("../assets/dash-invoice.png");
export const DashboardManager = require("../assets/dash-manager.png");
export const DashboardMeeting = require("../assets/dash-meeting.png");
export const DashboardPoll = require("../assets/dash-poll.png");
export const DashboardReport = require("../assets/dash-report.png");
export const DashboardSuggestion = require("../assets/dash-suggestion.png");
export const DashboardTenant = require("../assets/dash-tenant.png");
export const DashboardUnit = require("../assets/dash-unit.png");
export const DashboardVehicle = require("../assets/dash-vehicle.png");
export const DashboardVisitor = require("../assets/dash-visitor.png");
export const DashboardVs = require("../assets/dash-vs.png");
export const DashboardFee = require("../assets/dash-fee.png");
export const DashboardClass = require("../assets/dash-class.png");
export const DashboardLease = require("../assets/dash-lease.png");
export const DashboardFamily = require("../assets/dash-family.png");
export const DashboardBudget = require("../assets/dash-budget.png");
export const DashboardFacility = require("../assets/dash-facility.png");
export const DashboardRent = require("../assets/dash-rent-payment.png");
export const voting = require("../assets/voting.png");
export const NotificationGreen = require("../assets/notification-green.png");
export const NotificationRed = require("../assets/notification-red.png");
export const ExclamationIcon = require("../assets/request.png");
export const RPaymentIcon = require("../assets/RPayment.png");
export const RSuggestionIcon = require("../assets/RSuggestion.png");
export const RVisitorIcon = require("../assets/RVisitor.png");
// Manager Dashboard
export const ManagerClassified = require("../assets/chair-classified.png");
export const ManagerCollection = require("../assets/chair-collection.png");
export const ManagerIncident = require("../assets/chair-incident.png");
export const ManagerVehicle = require("../assets/chair-vehicle.png");
export const ManagerFacility = require("../assets/chair-facility.png");
export const NewNotification = require("../assets/active-notification.png");
export const ActionBuilding = require("../assets/action-building.png");
export const ActionAssign = require("../assets/action-assign.png");
export const ActionCalender = require("../assets/action-calender.png");
export const InfoIcon = require("../assets/info.png");
export const CloseIcon = require("../assets/cross.png");
export const RentIcon = require("../assets/rentD.png");
export const ProfitLossIcon = require("../assets/ProfitLoss.png");
export const moneySpendIcon = require("../assets/MoneySpend.png");
export const rentLedgerIcon = require("../assets/RentLedger.png");
export const delete_icon = require("../assets/delete-icon.png");
export const Dashboard = require("../assets/Dashboard.png");
export const buildingMenu = require("../assets/buildings.png");
export const UserProfile = require("../assets/user-profile.svg");
export const message = require("../assets/message.svg");
export const chat = require("../assets/chat.svg");
export const phone = require("../assets/phone.svg");
export const facebook = require("../assets/facebook.svg");
export const twiter = require("../assets/twiter.svg");
export const snap = require("../assets/snap.svg");
export const instagram = require("../assets/instagram.svg");
export const uploadImage = require("../assets/uploadImage.svg");
export const deleteIcon = require("../assets/deleteIcon.svg")
export const successIcon = require("../assets/download.jpeg")




export const infoIcon = require("../assets/info_icon.svg");
// Customizable Area End
