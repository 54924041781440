Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";
exports.cardApiEndPoint = "bx_block_my_document/report_dashboard_count/";
exports.btnExampleTitle = "CLICK ME";
exports.countryApiEndpoint = "bx_block_profile/profiles/country_list/"
exports.cityApiEndpoint = "bx_block_my_team/company_employee_nominations/city_list/"
exports.complexApiEndpoin = "bx_block_my_team/company_employee_nominations/complex_list/"
exports.buildingApiEndpoin = "bx_block_profile/profiles/building_list"
exports.loginCardApiEndPoint = "bx_block_my_document/login_report_dashboard";
exports.lastLoginApiEndPoint = "bx_block_my_document/last_login_list/";
exports.neverLoggedApiEndPoint = "bx_block_my_document/never_logged_user/";
exports.loginWithMonthApiEndPoint = "bx_block_my_document/login_with_specific_month/"
exports.downloadReportsEndPoin = "bx_block_my_document/building_documents/download_login_report.pdf/"
exports.roleApiEndPoint = "bx_block_roles_permissions/roles"
exports.salesManagerApiEndPoint = "bx_block_report/property_manager_report/sales_manager_listing"
exports.userSubscriptionTypeApiEndPoint = "bx_block_custom_user_subs/plans"
exports.ownerReportsApiEndPoint="bx_block_report/owner_reports/owner_report_listing/"
exports.ownerReportDetailsApiEndPoint = "bx_block_report/owner_reports/owner_report_details/"

// Customizable Area End