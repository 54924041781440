// Customizable Area Start
import React from "react";
import LeaseManagementIssueNewContractTemplateController,{Props} from "./LeaseManagementIssueNewContractTemplateController.web";
import MainScreen from "../../dashboard/src/Company Employee/MainScreen.web";
import { Box, Breadcrumbs, Button, Divider, Link, Paper, Snackbar, Typography, styled } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { withTranslation } from "react-i18next";
// Customizable Area End

// Customizable Area Start
type TFunction = (value: string) => string;
// Customizable Area End

class LeaseManagementIssueNewContractTemplate extends LeaseManagementIssueNewContractTemplateController {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area Start
    }

    // Customizable Area Start 
    renderSnackbar = () => {
        return (
          <>
            {this.state.hasSuccessError.isOpen && (<>
              <Snackbar
                data-testid="snackBarCloseTestId"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={this.state.hasSuccessError.isOpen}
                autoHideDuration={3000}
                onClose={this.handleCloseSnack}
              >
                <Alert onClose={this.handleCloseSnack} severity={this.state.hasSuccessError.isSeverity}>
                  {this.state.hasSuccessError.isMessage}
                </Alert>
              </Snackbar>
            </>
            )}
          </>
        );
      };
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { t }: { t: TFunction } = this.props;
        return (
            <>
            <MainScreen>
                <IssueNewContractTemplateContainer>
                    <Box>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link color="inherit" href="/CompanyEmployee/LeaseManagment" className="notActiveBreadLink" data-testId={"templateListingHeadingTestId"}>
                                {t("Lease Management")}
                            </Link>
                            <Link
                                color="inherit"
                                href="/CompanyEmployee/IssueNewLease"
                                className="notActiveBreadLink"
                            >
                                {t("Issue New Lease")}
                            </Link>
                            <Typography color="textPrimary" className="activeBreadLink">
                                {t("Issue a New Contract")}
                            </Typography>
                        </Breadcrumbs>
                        <Typography className="issueNewcontractHeading" data-testId={"templateListingHeadingTestId"}>
                            {t("Issue a New Contract")}
                        </Typography>
                    </Box>
                    <Paper elevation={1} className="issueNewContractContentContainer">
                        <Box className="issueNewContractHeaderContainer">
                            <Typography className="issueNewContractHeaderHeading">
                            Template 10
                            </Typography>
                            
                        </Box>
                        <Divider />
                        <Box className="templateDetailsContainer">
                            <Typography className="templateHeading">Lorem Ipsum</Typography>
                        </Box>
                        
                    </Paper>
                    <Box className="bottomButtonContainer">
                        <Button className="cancelBtn">Back</Button>
                        <Button variant="contained" className="continueBtn">
                        {t("Next")}
                        </Button>
                    </Box>
                    {this.renderSnackbar()}
                </IssueNewContractTemplateContainer>
            </MainScreen>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withTranslation()(LeaseManagementIssueNewContractTemplate);
export { LeaseManagementIssueNewContractTemplate };
const IssueNewContractTemplateContainer = styled(Box)({
    backgroundColor: "#f4f7ff",
    padding: "50px",

    "& .activeBreadLink": {
        color: "#729be0",
    },
    "& .notActiveBreadLink": {
        color: "#40434c",
    },
    "& .issueNewcontractHeading": {
        color: "#40434c",
        fontWeight: 900,
        margin: "10px 0px",
        fontSize: "32px",
    },
    "& .issueNewContractContentContainer": {
        borderRadius: "10px",
        backgroundColor: "#fff",
        margin: "20px 0px",
        overflow: "scroll",
        height: "63vh",
    },
    "& .issueNewContractHeaderContainer": {
        justifyContent: "space-between",
        display: "flex",
        padding: "20px",
        alignItems: "center",
        "@media(max-width:576px)": {
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
        },
    },
    "& .issueNewContractHeaderHeading": {
        fontWeight: 900,
        width: "100%",
        color: "#40434c",
        fontSize: "20px",
    },
    
    "& .templateName": {
        fontWeight: 800,
        color: "#242426",
        fontSize: "14px",
    },

    "& .bottomButtonContainer": {
        display: "flex",
        justifyContent: "flex-end",
        gap: "30px",
        padding: "20px 0px",
    },
    "& .cancelBtn": {
        width: "200px",
        padding: "10px 30px",
        border: "1px solid #6686bb",
        color:"#3573eb",
        fontWeight:900
    },
    "& .continueBtn": {
        width: "200px",
        padding: "10px 30px",
        backgroundColor: "#2b6fec",
        color: "#fff",
        fontWeight: 900,
    },
    "& .templateHeading":{
        fontSize:"17px",
        color:"#40434c",
        fontWeight:900
    },
    "& .templateDetailsContainer":{
        padding:"20px"
    }
   
});
// Customizable Area End