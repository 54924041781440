// Customizable Area Start
import React from "react";
import { Container, Typography, Button, withStyles, Card, Link, Select, MenuItem } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import CEActionsUserController, { Props } from "./CEActionsUserController.web";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SearchIcon from "@material-ui/icons/Search";
import Loader from "../../../../components/src/Loader.web";
import CEHeaderComponentWeb from "../../../../components/src/HeaderComponent/CEHeaderComponent.web";
import { CEActionsMeStyleWeb } from "./CEActionsMe.web";
import { CEBuildingIcon, CECalendarIcon, CERealEstateIcon, CEUserIcon } from "../assets";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Customizable Area End

class CEActionsUser extends CEActionsUserController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { t, classes }: any = this.props;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Loader loading={this.state.loading} />
        <CEHeaderComponentWeb>
          <Container className={classes.generalDashboard}>
            <Box className="navigation action-user-header-box">
              <Typography variant="body1">
                <Link href="/CompanyEmployee/dashboard">{t("My Dashboards")}</Link> /{" "}
                <Link href="/CompanyEmployee/ActionAssignTeam">{t("Action assigned to my team")}</Link> /{" "}
                <Box component="span" style={{ color: "blue" }}>
                  {t("View Actions Progress")}
                </Box>
              </Typography>
              <Box className="sub-heading-box action-user-name-head">
                <Typography variant="h5" className="bold-text">
                  {this.state.userName}' {t("Tasks")}
                </Typography>
              </Box>
              <Box className="filter-head-box action-user-filter-box">
                <Select
                  displayEmpty
                  IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                  className="select-year action-select-complex"
                  value={this.state.complex}
                  onChange={(e: any) => this.setState({ complex: e.target.value })}
                >
                  <MenuItem value="">{t("Select Complex")}</MenuItem>
                  {this.state.meComplexList.map((complex: any) => {
                    return (
                      <MenuItem value={complex.id} key={complex.id}>
                        {complex.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Select
                  onChange={(e: any) => this.setState({ building: e.target.value })}
                  displayEmpty
                  className="select-year action-select-building"
                  IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                  value={this.state.building}
                >
                  <MenuItem value="">{t("Select Building")}</MenuItem>
                  {this.state.meBuildingList.map((building: any) => {
                    return (
                      <MenuItem value={building.id} key={building.id}>
                        {building.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Select
                  className="select-year action-select-assign-by"
                  onChange={(e: any) => this.setState({ status: e.target.value })}
                  displayEmpty
                  value={this.state.status}
                  IconComponent={() => <ExpandMoreIcon className="select-icon" />}
                >
                  <MenuItem value="">{t("Status")}</MenuItem>
                  <MenuItem value="pending">{t("Pending")}</MenuItem>
                  <MenuItem value="completed">{t("Completed")}</MenuItem>
                  <MenuItem value="delayed">{t("Delayed")}</MenuItem>
                </Select>
                <Button
                  className="action-search-btn action-user-search"
                  startIcon={<SearchIcon />}
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      filterValue: {
                        complex: this.state.complex,
                        status: this.state.status,
                        building: this.state.building,
                      },
                    });
                  }}
                >
                  {t("Search")}
                </Button>
              </Box>
            </Box>
            <Box className="action-content-main-box">
              <Grid container spacing={2} className="action-user-content-box">
                {this.state.userActionProgressList.map((task: any, index: number) => {
                  return (
                    <Grid item sm={12} key={index}>
                      <Card className="action-card">
                        <h4 className="bold-text">{task.task_name}</h4>
                        <p className="description">{task.task_description}</p>
                        <Box className="action-info-box">
                          <Box className="action-content-box action-user-action-box">
                            <Box className="action-column-box">
                              <Box className="action-info action-user-info-box">
                                <img src={CERealEstateIcon} alt="" />
                                <Box>
                                  <span>{t("Real Estate Company")}</span>
                                  <p className="bold-text">{this.handleUserCompanyName(task)}</p>
                                </Box>
                              </Box>
                              <Box className="action-info action-user-info-box">
                                <img src={CEUserIcon} alt="" />
                                <Box>
                                  <span>{t("Assigned to")}</span>
                                  <p className="bold-text">{task.assigned_to}</p>
                                </Box>
                              </Box>
                            </Box>
                            <Box className="action-column-box action-user-info-box">
                              <Box className="action-info">
                                <img src={CEBuildingIcon} alt="" />
                                <Box>
                                  <span>{t("Complex")}</span>
                                  <p className="bold-text">{task.complex_name}</p>
                                </Box>
                              </Box>
                              <Box className="action-info action-user-info-box">
                                <img src={CECalendarIcon} alt="" />
                                <Box>
                                  <span>{t("Assigned on")}</span>
                                  <p className="bold-text">{task.assigned_on}</p>
                                </Box>
                              </Box>
                            </Box>
                            <Box className="action-column-box ">
                              <Box className="action-info action-user-info-box">
                                <img src={CEBuildingIcon} alt="" />
                                <Box>
                                  <span>{t("Building")}</span>
                                  <p className="bold-text">{task.building_name}</p>
                                </Box>
                              </Box>
                              <Box className="action-info action-user-info-box">
                                <img src={CECalendarIcon} alt="" />
                                <Box>
                                  <span>{t("Due on")}</span>
                                  <p className="bold-text">{task.due_on}</p>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Container>
        </CEHeaderComponentWeb>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// @ts-ignore
export default withTranslation()(withStyles(CEActionsMeStyleWeb)(withRouter(CEActionsUser)));
// Customizable Area End
