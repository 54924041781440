Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.validationApiPUTMethodType = "PUT";
exports.getSubscriptionAPiEndPoint =
  "customisable_user_subscriptions/subscriptions";
exports.getSubscriptionAPiMethod = "GET";
exports.subscriptionApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableusersubscriptions";
exports.labelBodyText = "customisableusersubscriptions Body";

exports.btnExampleTitle = "CLICK ME";
exports.subscriptions = "Subscriptions";
exports.chooseSubscription = "Choose from the list of subscriptions";
exports.subscriptionDetailsScreenName = "SubscriptionDetails";
exports.goldCoinImageUrl =
  "https://akm-img-a-in.tosshub.com/sites/btmt/images/stories/goldcoin_660_102616075839.jpg";
exports.currencySymbol = "₹";
exports.zero = "0";
exports.configureNotificationEndpoint="bx_block_custom_user_subs/configure_notification/"
exports.subscribed = "Subscribed";
exports.subscribe = "Subscribe";
exports.subscriptionDetails = "Subscription Details";
exports.confirmSubscription = "Confirm Subscription";
exports.noSubscriptionAvailable = "No subscriptions available";
exports.planDeactivatedfirstModel = 'Plan Deactivated';
exports.planDeactivatedfirstModelDisc='Deactivate plan request has been sent to the COO. Once the  request get approved, plan will be deactivated'
exports.planDeactivatePlanSecondModel ='Deactivate Plan';
exports.planDeactivatePlanSecondModelDisc='Are you sure you want to deactivate the plan?';
exports.unfreezeSubscription='Unfreeze Subscription';
exports.freezeSubscription='Freeze Subscription';
exports.unfreezDetails= 'Are you sure you want to unfreeze the subscription?';
exports.freezDetails= 'Are you sure you want to freeze the subscription?';
exports.setConfigureNotificationEndPoint="bx_block_custom_user_subs/configure_notification_all_user"
exports.getPlanDetailsEndPoint="bx_block_custom_user_subs/plans"
exports.getPropertyManagerEndPoint="bx_block_custom_user_subs/property_manager_list"
// Customizable Area End
