//Customizable Area Start
import React from "react";
import {
    Container,
    Box,
    Grid,
    Hidden,
} from "@material-ui/core";
import ComplexListingController, {
    Props,
} from './ComplexListingController.web'
import RealEstateCompaniesTable from './RealEstateCompaniesTable.web'
import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";
import {withRouter} from 'react-router-dom'
//@ts-ignore
import ComplexListingTable from './ComplexListingTable.web'

class ComplexListing extends ComplexListingController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { navigation } = this.props;

        return (
            <>
            <MainScreen>
                <ComplexListingTable navigation={navigation} id={"complex_listings"} />
            </MainScreen>
            </>
        );
    }
}

const webStyle = {
};

export default withRouter(ComplexListing)
//Customizable Area End