import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { ApiCatchErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";
import * as Yup from "yup";


export interface Props {
    countriesList:any,
    countrySelected: string,
    locationDetails:any,
    handleCountryChange: any,
    stepComplex: number,
    handleNext:any,
    handleBack:any,
    handleLocationDetails:any,
    handleLocationFieldChange:any,
    t?: any
    i18n?: any
    match?: any
    location?: any
    // Customizable Area Start
    history?: any
    language?: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CompEmpComplexBlockStep4Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
    exampleAPICallId: string = "";

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

        this.state = {
            // Customizable Area Start
            loading: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // async componentDidUpdate(prevProps: any, prevState: any): Promise<void> {
    //   }

    async componentDidMount(): Promise<void> {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    validationSchema = Yup.object().shape({
        zipCode: Yup.string().min(6, this.props.t('Zip Code must be at least 6 characters'))
            .max(7, this.props.t('Zip Code must be at most 7 characters')).required(this.props.t('Zip Code is required')),
        locationLink: Yup.string().required(this.props.t('Location Link is required')),
        landmark: Yup.string().required(this.props.t('Landmark is required')),
        streetName: Yup.string().required(this.props.t('Street Name is required')),
        officialAddress: Yup.string().required(this.props.t('Official Address is required')),
        longitude: Yup.string().required(this.props.t('Longitude is required')),
        latitude: Yup.string().required(this.props.t('Latitude is required')),
        country: Yup.string().required(this.props.t('Country is required')),
        city: Yup.string().required(this.props.t('City is required')),
        state: Yup.string().required(this.props.t('State is required'))
    });
    // Customizable Area End
}
