//Customizable Area Start
import React from "react";

import { Box, Typography, Grid, } from "@material-ui/core";

import DashboardCompanyEmployeeController, {
    Props,
} from "./DashboardCompanyEmployeeController.web";
import './global.css';
import MainScreen from "./MainScreen.web";
export const image_realEstateCompany = require("../../assets/pollsurvey.png");
export const image_complexBuilding = require("../../assets/B&A.png");

export default class DashboardCompanyEmployee extends DashboardCompanyEmployeeController {
    constructor(props: Props) {
        super(props);
    }
    render() {


        if (localStorage.loginSuccessToken) {
            return (
                <MainScreen >
                    <Box style={{ padding: '40px', paddingRight: '40px' }}>
                        <Typography style={{ fontSize: '18px' }}> My Dashboard/General Dashboard</Typography>
                        <br />
                        <Typography style={{ fontSize: '32px' }}> Dashboard</Typography>
                        <br />

                        <div>
                            <Grid container spacing={3}>
                                <Grid item xs={4} md={4} >

                                    <Box className="dashboard_card" p={3}>
                                        <img src={image_realEstateCompany} />
                                        <br /> <br />
                                        <Typography style={{ fontSize: '16px' }}> Real Estate companies I am managing</Typography>
                                        <br />
                                        <Typography style={{ fontSize: '16px' }}> 43</Typography>
                                    </Box>

                                </Grid>
                                <Grid item xs={4} md={4} >

                                    <Box className="dashboard_card" p={3}>
                                        <img src={image_complexBuilding} />
                                        <br /> <br />
                                        <Typography style={{ fontSize: '16px' }}> Managed Complexes</Typography>
                                        <br />
                                        <Typography style={{ fontSize: '16px' }}> 6</Typography>
                                    </Box>

                                </Grid>
                                <Grid item xs={4} md={4} >

                                    <Box className="dashboard_card" p={3}>
                                        <img src={image_complexBuilding} />
                                        <br /> <br />
                                        <Typography style={{ fontSize: '16px' }}> Managed Individual Buildings</Typography>
                                        <br />
                                        <Typography style={{ fontSize: '16px' }}> 27</Typography>
                                    </Box>

                                </Grid>

                            </Grid>
                        </div>

                    </Box>
                </MainScreen>

            );
        }
        else {
            window.location.href = '/CompanyEmployee/Login'
            return null
        }

    }
}

const webStyle = {
};
//Customizable Area End


