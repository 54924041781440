import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { ChangeEvent } from "react";
import { getStorageData } from "../../../framework/src/Utilities";
const { baseURL } = require("../../../framework/src/config");

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}
// Customizable Area End

export interface S {
    // Customizable Area Start
    loginCardDetails :{
        active_users_percentage:number | null
        onboding_users: number | null
    }
    tableData: any
    tags:string
    loginMonth : number | null
    open:boolean
    anchorEl: HTMLButtonElement | null;
    viwePage:boolean
    reportsDownload:any
    individualbuilding: string
    selectCountry: string[]
    selectedCountry: string
    selectCity: string[]
    selectComplex: string[]
    selectBuilding: string[]
    selectUnit: string[]
    selectedUnit: string
    selectUserType: string
    token: string;
    selectCompany: string[]
    selectedCompany: string
    selectedCity: string
    selectedComplex: string
    selectedBuilding: string
    topMenu: string[]
    title: string;
    countPage:number | undefined
    totalPages:number
    totalCount: number
    role: string[]
    selectedRole:string
    salseManage:string[]
    selectedSalse:string
    userSubscription:string[]
    selectSubscription:string

    // Customizable Area End
}

export interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class LoginReportsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    loginCardApiId: string = ""
    lastLoginApiId: string = ""
    neverLoggedApiId: string =""
    loginWithMonthApiId: string =""
    downloadReportsApiId: string =""
    getCountryApiId: string = ""
    getCityApiId: string = ""
    getComplexApiId: string = ""
    getBuildingApiId: string = ""
    getRoleApiId: string = ""
    getSalesManagerApiId: string = ""
    getuserSubscriptionTypeId: string =""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            loginCardDetails:{
                active_users_percentage:null,
                onboding_users:null
            },
            tableData:[],
            tags:"Last Login",
            loginMonth: null,
            open: false,
            anchorEl: null,
            viwePage:false,
            reportsDownload:"",
            individualbuilding: "",
            selectCountry: [],
            selectedCountry: "",
            selectCity: [],
            selectComplex: [],
            selectBuilding: [],
            selectUnit: [],
            selectUserType: "",
            token: "",
            selectCompany: [],
            selectedCompany: "",
            selectedCity: "",
            selectedComplex: "",
            selectedBuilding: "",
            selectedUnit: "",
            topMenu: ["Documents & Reports", "Reports","Login Reports", "Last Login"],
            title: "",
            countPage:1,
            totalPages:1,
            totalCount:0,
            role: [],
            selectedRole:"",
            salseManage:[],
            selectedSalse: "",
            userSubscription:[],
            selectSubscription:""
        };
        // Customizable Area End


    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId === this.loginCardApiId) {
                this.setState({ loginCardDetails: responseJson.data })
            } else if (apiRequestCallId === this.lastLoginApiId) {
                this.setState({ tableData: responseJson.data ,totalPages:responseJson?.meta?.pagination.total_pages,totalCount:responseJson?.meta?.pagination.total_count})
            } else if (apiRequestCallId === this.neverLoggedApiId) {
                this.setState({ tableData: responseJson.data,totalPages:responseJson.meta.pagination.total_pages,totalCount:responseJson.meta.pagination.total_count })
            } else if (apiRequestCallId === this.loginWithMonthApiId){
                this.setState({ tableData: responseJson.data,totalPages:responseJson.meta.pagination.total_pages,totalCount:responseJson.meta.pagination.total_count })
            } else if (apiRequestCallId === this.getBuildingApiId) {
                this.setState({ selectBuilding: responseJson.data.building_list })
            } else if (apiRequestCallId === this.getCityApiId) {
                this.setState({ selectCity: responseJson.data.cities })
            } else if (apiRequestCallId === this.getCountryApiId) {
                this.setState({ selectCountry: responseJson.data.countries })
            } else if (apiRequestCallId === this.getComplexApiId) {
                this.setState({ selectComplex: responseJson.societies })
            } else if (apiRequestCallId === this.getRoleApiId) {
                this.setState({role:responseJson.data})
            } else if (apiRequestCallId === this.getSalesManagerApiId){
                this.setState({salseManage:responseJson.sales_manager_listing})
            } else if (apiRequestCallId === this.getuserSubscriptionTypeId){
                this.setState({userSubscription:responseJson.data})
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        let token  = await getStorageData("loginSuccessToken");
        this.setState({token:token})
        this.handleLoginCardApi()
        this.handleBuildingApi()
        this.handleLastLoginApi(1)
        this.handleComplexApi()
        this.handleCityApi()
        this.handleCountryApi()
        this.handleRoleApi()
        this.handleSalseApi()
        this.userSubscriptionTypeApi()
      }

      handleMenuBar = (index: number) => {
        if (index) {
            this.props.navigation.navigate("ReportsStatistics")
        }
    
      }

    handleLoginCardApi = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.loginCardApiId = requestMessage.messageId;
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.loginCardApiEndPoint}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleLastLoginApi = (page:number) => {
        const { selectedCity, selectedCountry, selectedComplex,selectedBuilding,selectedRole,selectedSalse  } = this.state
        let userFilters: any = {
            city: selectedCity,
            complex: selectedComplex,
            building: selectedBuilding,
            country: selectedCountry,
            sales_mamber_id: selectedSalse,
            role:selectedRole,
            page:page,
            per_page:"10",
            listing:true
        }

        let initialEndPoint = `${configJSON.lastLoginApiEndPoint}?`;
        let isFirstParam = true;

        for (let filter in userFilters) {
            if (userFilters[filter]) {
                if (!isFirstParam) {
                    initialEndPoint += '&';
                }
                initialEndPoint += `${filter}=${userFilters[filter]}`;
                isFirstParam = false;
            }
        }
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.lastLoginApiId = requestMessage.messageId;
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            initialEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleNeverLoggedApi = (page:number) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.neverLoggedApiId = requestMessage.messageId;
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.neverLoggedApiEndPoint}?listing=true&page=${page}&per_page=10`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleLoginMonthApi = (page:number) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.loginWithMonthApiId = requestMessage.messageId;
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.loginWithMonthApiEndPoint}?listing=true&months_count=${this.state.loginMonth}&page=${page}&per_page=10`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }


    handleMonth = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ loginMonth: value as number })
    }
    handleConfirm = ()=> {
        this.setState({open:false})
        this.handleLoginMonthApi(1)
    }

    handleTag = (name:string) => {
        this.setState({tags:name})
        if(name === "Last Login"){
            this.handleLastLoginApi(1)
            const dummyData = [...this.state.topMenu]
            dummyData.splice(3,1,name)
            this.setState({topMenu:dummyData})
        } else if (name === "Never Logged In Users"){
            const dummyData = [...this.state.topMenu]
            dummyData.splice(3,1,name)
            this.setState({topMenu:dummyData})
            this.handleNeverLoggedApi(1)
        } else if (name === "Never Logged In Users In Last 30 Months") {
            const dummyData = [...this.state.topMenu]
            dummyData.splice(3,1,name)
            this.setState({topMenu:dummyData})
            this.setState({open:true})
        }
        else if (name === "Days since Last Login") {
            const dummyData = [...this.state.topMenu]
            dummyData.splice(3,1,name)
            this.setState({topMenu:dummyData})
            this.handleLastLoginApi(1)
        }
    }
    handleClick = (event: any) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };
    handleClose = () => {
        this.setState({ anchorEl: null, });
    };

    handleView = () => {
        this.setState({viwePage:true})
    }

    
    handleSelectCountryChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ selectedCountry: value as string })
    };
    
    handleCountryFilterChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ individualbuilding: value as string })
    };

    handleSelectComplexChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ selectedComplex: value as string })
    };
    
    handleSelectCityChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ selectedCity: value as string })
    };

    handleSelectBuildingChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ selectedBuilding: value as string })
    };

    handleRoleChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ selectedRole: value as string })
    };

    handleCountryApi = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessaged = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCountryApiId = requestMessaged.messageId;

        requestMessaged.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.countryApiEndpoint
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessaged.id, requestMessaged);
        return true;
    }

    handleCityApi = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };

        const requestMessaged = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCityApiId = requestMessaged.messageId;

        requestMessaged.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.cityApiEndpoint
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessaged.id, requestMessaged);
        return true;
    }

    handleComplexApi = () => {

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessages = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getComplexApiId = requestMessages.messageId;

        requestMessages.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.complexApiEndpoin}`
        );
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessages.id, requestMessages);
        return true;
    }

    handleBuildingApi = () => {

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };

        const requestMessages = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBuildingApiId = requestMessages.messageId;

        requestMessages.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.buildingApiEndpoin
        );
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessages.id, requestMessages);
        return true;
    }

    handlePageChange = (_event:any,page:number) => {
        const {tags} = this.state
        this.setState({countPage:page})
        if(tags === "Last Login"){
            this.handleLastLoginApi(page)
        } else if (tags === "Never Logged In Users"){
            this.handleNeverLoggedApi(page)
        } else if (tags === "Never Logged In Users In Last 30 Months") {
            this.handleLoginMonthApi(page)
        } else if (tags === "Days since Last Login") {
            this.handleLastLoginApi(page)
        }
    }

    handleRoleApi = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessaged = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getRoleApiId = requestMessaged.messageId;

        requestMessaged.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.roleApiEndPoint
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessaged.id, requestMessaged);
        return true;
    }

    handleAccountChange = (event: ChangeEvent<{ value: unknown | string }>) => {
        const { value } = event.target;
        this.setState({ selectedSalse: value as string })
    }

    handleSalseApi = () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessaged = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSalesManagerApiId = requestMessaged.messageId;

        requestMessaged.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.salesManagerApiEndPoint
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessaged.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessaged.id, requestMessaged);
        return true;
    }

    handleDownload = () => {
        this.setState({viwePage:true})
    }

    userSubscriptionTypeApi = () => {

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.token
        };
        const requestMessages = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getuserSubscriptionTypeId = requestMessages.messageId;

        requestMessages.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.userSubscriptionTypeApiEndPoint}`
        );
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessages.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessages.id, requestMessages);
        return true;
    }

    handleUserrrSubscription = (event: ChangeEvent<{ value: unknown | string }>) => {
        const {value} = event.target
        this.setState({selectSubscription:value as string})
    }

    handleSearch = () => {
        if(this.state.tags === "Last Login"){
            this.handleLastLoginApi(1)
        } else if (this.state.tags === "Never Logged In Users"){
            this.handleNeverLoggedApi(1)
        } else if (this.state.tags === "Never Logged In Users In Last 30 Months") {
            this.handleLoginMonthApi(1)
        } else if (this.state.tags === "Days since Last Login") {
            this.handleLastLoginApi(1)
        }
    }

    handleDownloadReport = () => {
        this.downloadPdf(
            `/bx_block_my_document/building_documents/download_login_report.pdf?listing=false&report_type=never_logged_user`, `Login_report.pdf`
          );
    }
    downloadPdf = async (path: string, fileName: string) => {
        const myHeaders = new Headers();
        myHeaders.append("token", this.state.token);
        let requestOptions = {
          method: "GET",
          headers: myHeaders,
        };
        const response = await fetch(`${baseURL}/${path}`, requestOptions);
        const resBlob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([resBlob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        // @ts-ignore
        link.parentNode.removeChild(link);
      };

    // Customizable Area End
}
