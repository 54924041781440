import React from "react";
// Customizable Area Start
import {

    Box,
    Grid,
    MenuItem,
    Typography,
    Select,
    InputAdornment,
    Button,
    Table,
    TableCell,
    TableRow,
    TableBody,
    Divider,
    styled,
    IconButton,
    Link,
    TextField,
    TableHead,
    TableContainer,
    Paper,
} from "@material-ui/core";
import { Menu } from "@szhsin/react-menu";

import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import CEHeaderComponentWeb from "../../../components/src/HeaderComponent/CEHeaderComponent.web";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { languageCondition } from "../../../components/src/ConditionManager.web";
import SearchIcon from '@material-ui/icons/Search';
import Pagination from "@material-ui/lab/Pagination";
import { Owner } from "./assets";

// Customizable Area End

import OwnersReportsController, {
    Props,
} from "./OwnersReportsController.web";

// Customizable Area Start
export class OwnersReports extends OwnersReportsController {
    constructor(props: Props) {
        super(props);
    }

// Customizable Area End

    render() {
        // Customizable Area Start
        
        const BoxView = Box as any
        const { t,i18n }: any = this.props;
        const language = i18n.language; 
        
        // Customizable Area End
            return (
                // Customizable Area Start
                <CEHeaderComponentWeb>
                    <OwnerReports>
                        <BoxView className="containerBox">
                            <BoxView className="navigation">
                                <Typography variant="body1" className="">
                                    <Link href="/CompanyEmployee/dashboard">
                                        {t("Documents & Reports")} / 
                                    </Link>{" "}
                                    <Link href="/CompanyEmployee/ReportsStatistics">
                                        {t("Reports")}
                                    </Link>{" "}
                                    /{" "}
                                    <BoxView component="span" style={{ color: "rgb(43, 111, 237)" }}>
                                        {t("Owners' Reports")}
                                    </BoxView>
                                </Typography>

                            </BoxView>
                            <BoxView>
                                <br />
                                <BoxView style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography variant='h4' className="bold-text">Owners' Reports</Typography>
                                </BoxView>
                                <BoxView >
                                    <Grid container xs={12} spacing={2}>
                                        <Grid item xs={6} sm={6} md={4} lg={4} >
                                            <BoxView
                                                className="Box"
                                            >
                                                <img src={Owner} />
                                                <BoxView>
                                                    <Typography variant='h6' className="bold-text">Average units per owner</Typography>
                                                    <Typography className="bold-text lable">10</Typography>
                                                </BoxView>
                                            </BoxView>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={4} lg={4} >
                                            <BoxView
                                                className="Box"
                                            >
                                                <img src={Owner} />
                                                <BoxView>
                                                    <Typography variant='h6' className="bold-text">Owners Living rate</Typography>
                                                    <Typography className="bold-text lable" >20%</Typography>
                                                    <Grid container xs={12} spacing={2}>

                                                    </Grid>
                                                </BoxView>
                                            </BoxView>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={2} style={{ marginTop: "25px" }}>

                                        <Grid item>
                                            <BoxView>
                                                <Select
                                                    disableUnderline
                                                    displayEmpty
                                                    data-test-id="selectCountryId"
                                                    className="selectmenu"
                                                    value={this.state.country}
                                                    placeholder="Select Country"
                                                    id="select-Country"
                                                    onChange={(event) => this.handleCountryChange(event)}
                                                >
                                                    <MenuItem
                                                        value=""
                                                        id="selectStatusBudgetReport"
                                                        disabled
                                                    >
                                                        {t("Select Country")}
                                                    </MenuItem>
                                                    {this.state.selectCountry?.length > 0 &&
                                                        this.state.selectCountry?.map((item: string) => {
                                                            return (
                                                                <MenuItem key={item} value={item}>
                                                                    {item}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    <MenuItem
                                                        id="selectStatusBudgetReportAll"
                                                        value="All"
                                                    >
                                                        {t("All")}
                                                    </MenuItem>
                                                </Select>
                                            </BoxView>
                                        </Grid>
                                        <Grid item>
                                            <Select displayEmpty
                                                data-test-id="selectCityId"
                                                value={this.state.city}
                                                disableUnderline
                                                className="selectmenu"
                                                id="select-city1"
                                                onChange={(event) => this.handleCityChange(event)}
                                            >
                                                <MenuItem
                                                    disabled
                                                    value=""
                                                    id="selectStatusBudgetReport"
                                                >
                                                    {t("Select City")}
                                                </MenuItem>
                                                {this.state.selectCity?.length > 0 &&
                                                    this.state.selectCity?.map((item: string) => {
                                                        return (
                                                            <MenuItem key={item} value={item}>
                                                                {item}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </Grid>
                                        <Grid item>
                                            <Select
                                                displayEmpty
                                                className="selectmenu"
                                                disableUnderline
                                                value={this.state.individualbuilding}
                                                id="select-city1"
                                                data-test-id="buildingsdropId"
                                                onChange={(event) => this.handleIndividualChange(event)}
                                            >
                                                <MenuItem disabled value="" id="selectStatusBudgetReport">
                                                    {t("Complex/Individual Buildings")}
                                                </MenuItem>
                                                <MenuItem value="Complex" id="selectStatusBudgetReport">
                                                    {t("Complex")}
                                                </MenuItem>
                                                <MenuItem value="Individualbuilding" id="selectStatusBudgetReportAll">
                                                    {t("Individual building")}
                                                </MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item>
                                            <Select displayEmpty
                                                value={this.state.complex}
                                                disableUnderline
                                                id="select-city1"
                                                onChange={(event) => this.handleComplexChange(event)}
                                                className="selectmenu"
                                                data-test-id="selectComplexId"
                                            >
                                                <MenuItem
                                                    disabled
                                                    value=""
                                                    id="selectStatusBudgetReport"
                                                >
                                                    {t("Select Complex")}
                                                </MenuItem>
                                                {this.state.selectComplex?.length > 0 &&
                                                    this.state.selectComplex?.map((item: any) => {
                                                        return (
                                                            <MenuItem key={item} value={item.id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </Grid>
                                        <Grid item>
                                            <Select displayEmpty
                                                id="selectBuilding"
                                                disableUnderline
                                                className="selectmenu"
                                                value={this.state.building}
                                                onChange={(event) => this.handleBuildingChange(event)}
                                                data-test-id="slctBuildingId"
                                            >
                                                <MenuItem
                                                    value=""
                                                    id="selectStatusBudgetReport"
                                                    disabled
                                                >
                                                    {t("Select Building")}
                                                </MenuItem>
                                                {this.state.selectBuilding?.length > 0 &&
                                                    this.state.selectBuilding?.map((item: any) => {
                                                        return (
                                                            <MenuItem key={item} value={item.id}>
                                                                {item.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </Grid>
                                        <Grid item>
                                            <BoxView>
                                                <Select
                                                    disableUnderline
                                                    displayEmpty
                                                    data-test-id="selectStatusBudgetId"
                                                    className="selectmenu"
                                                    value={this.state.sales}
                                                    onChange={(event) => this.handleSalesChange(event)}
                                                    id="select-Country"
                                                >
                                                    <MenuItem
                                                        disabled
                                                        id="selectStatusBudgetReport"
                                                        value=""
                                                    >
                                                        {t("Sales Manager")}
                                                    </MenuItem>
                                                    {this.state.selectSales?.length > 0 &&
                                                        this.state.selectSales?.map((item: any) => {
                                                            return (
                                                                <MenuItem key={item.id} value={item.id}>
                                                                    {item.first_name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                            </BoxView>
                                        </Grid>
                                        <Grid item>
                                            <BoxView>
                                                <Select
                                                    disableUnderline
                                                    displayEmpty
                                                    data-test-id="selectAccountManagerId"
                                                    className="selectmenu"
                                                    value={this.state.accountManager}
                                                    onChange={(event) => this.handleAccountChange(event)}
                                                    id="select-Country"
                                                >
                                                    <MenuItem
                                                        disabled
                                                        id="selectStatusBudgetReport"
                                                        value=""
                                                    >
                                                        {t("Account Manager")}
                                                    </MenuItem>
                                                </Select>
                                            </BoxView>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                className="mybtn"
                                                data-test-id="saveButton"
                                                onClick={() => this.ownerReportApi()}
                                            >
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                                {t("Search")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid className="table-box-card">
                                        <Grid item sm={12} md={12} xs={12}>
                                            <BoxView className="table-top">
                                                <h4 className="bold-text">{t("List of Owners")}</h4>
                                                <BoxView className="filter-head-box" style={{ margin: "0" }}>
                                                    <BoxView className="sort-by">
                                                        <Grid item>
                                                            <BoxView>
                                                                <Select
                                                                    disableUnderline
                                                                    displayEmpty
                                                                    data-test-id="filterId"
                                                                    className="filter"
                                                                    value={this.state.sort}
                                                                    onChange={(event) => this.handleSortChange(event)}
                                                                    id="select-Country"
                                                                >
                                                                    <MenuItem
                                                                        disabled
                                                                        id="selectStatusBudgetReport"
                                                                        value=""
                                                                    >
                                                                        {t("Sort By")}
                                                                    </MenuItem>
                                                                </Select>
                                                            </BoxView>
                                                        </Grid>
                                                        <TextField
                                                            className="searchButtons"
                                                            variant="outlined"
                                                            placeholder={t("Search by name")}
                                                            data-test-id="tableFilterId"
                                                            onChange={(event)=>this.handleFilter(event)}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <SearchIcon />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </BoxView>
                                                </BoxView>
                                            </BoxView>
                                            <Divider />
                                            <TableContainer component={Paper} style={{boxShadow:"none"}}>
                                            <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align={languageCondition(language, "right", "left")}>#</TableCell>
                                                            <TableCell
                                                                align={languageCondition(language, "right", "left")}
                                                                className="bold-text"
                                                            >
                                                                {t("Unique ID")}
                                                            </TableCell>
                                                            <TableCell
                                                                className="bold-text"
                                                                align={languageCondition(language, "right", "left")}
                                                            >
                                                                {t("Owner Name")}
                                                            </TableCell>
                                                            <TableCell
                                                                className="bold-text"
                                                                align={languageCondition(language, "right", "left")}
                                                            >
                                                                {t("Phone Number")}
                                                            </TableCell>
                                                            <TableCell
                                                                align={languageCondition(language, "right", "left")}
                                                                className="bold-text"
                                                            >
                                                                {t("Email ID")}
                                                            </TableCell>
                                                            <TableCell
                                                                className="bold-text"
                                                                align={languageCondition(language, "right", "left")}
                                                            >
                                                                {t("Total Units Owned")}
                                                            </TableCell>
                                                            <TableCell align={languageCondition(language, "right", "left")} />
                                                        </TableRow>
                                                    </TableHead>
                                                <TableBody>

                                                    {this.state.ownerReports.map((item: any, index: number) => (
                                                        <TableRow>
                                                            <TableCell align={languageCondition(language, "right", "left")}>{index + 1}</TableCell>
                                                            <TableCell align={languageCondition(language, "right", "left")}>{item.attributes.id}</TableCell>
                                                            <TableCell align={languageCondition(language, "right", "left")} className="ellipse-one">
                                                                {item.attributes.full_name}
                                                            </TableCell>
                                                            <TableCell align={languageCondition(language, "right", "left")}>
                                                                {item.attributes.full_phone_number}
                                                            </TableCell>
                                                            <TableCell align={languageCondition(language, "right", "left")}>
                                                                {item.attributes.email}
                                                            </TableCell>
                                                            <TableCell align={languageCondition(language, "right", "left")}>
                                                                {item.attributes.total_owned_unit}
                                                            </TableCell>
                                                            <TableCell align={languageCondition(language, "right", "left")} className="ce-pm-report-menu">
                                                                <Menu menuButton={<IconButton><MoreVertIcon /></IconButton>}>
                                                                    <MenuItem
                                                                        className="ce-pm-view-report"
                                                                        data-test-id="viewProfileId"
                                                                        onClick={() => this.props.navigation.navigate("CEOwnersReportsDetails", { id: item.id })}
                                                                    >
                                                                        {t("View Details")}
                                                                    </MenuItem>
                                                                </Menu>

                                                            </TableCell>
                                                        </TableRow>
                                                    ))}

                                                </TableBody>
                                            </Table>
                                            </TableContainer>
                                            <BoxView className="table-bottom">
                                                <p>
                                                    {t("Showing")} <span className="current-page">{this.state.ownerReports?.length}</span> {t("of")}{" "}
                                                    <span className="total-page">{this.state.pagination ? this.state.pagination.total_count : 0}</span>{" "}
                                                    {t("results")}
                                                </p>
                                                <BoxView>
                                                    <Pagination count={this.state.totalPage}
                                                        page={this.state.count} variant="outlined" shape="rounded" data-test-id="paginationID" onChange={this.handlePageChange} />
                                                </BoxView>

                                            </BoxView>
                                        </Grid>
                                    </Grid>
                                </BoxView>
                            </BoxView>
                        </BoxView>
                    </OwnerReports>
                </CEHeaderComponentWeb>
                // Customizable Area End
            );
        }
}

// Customizable Area Start
const OwnerReports = styled(Box)({
    '& .containerBox': {
        padding:"0px 40px"
    },
    '& .subcontainer': {
        padding: '0px 40px',
    },
    '& .selectmenu': {
        padding: '7px',
        border: '1px solid #F0F0F0',
        borderRadius: '15px',
        background: '#fff',
        width: '100%',
        borderBottom: 'none',
        marginTop: '10px',
        gap: '10px',
    },
    '& .titleBox': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    '& .ButtonBox': {
        padding: 20,
        justifyContent: 'flex-end',
        display: 'flex',
        gap: 15,
    },
    '& .cancleButton': {
        padding: '5px 40px',
        border: '1px solid #2B6FED',
        color: '#2B6FED',
        fontWeight: 900,
    },
    '& .confirmButton': {
        padding: '5px 40px',
        background: '#2B6FED',
        color: '#fff',
        fontWeight: 900,
    },
    '& .inputType': {
        border: '1px solid darkgray',
        padding: '7px',
        borderRadius: '15px',
        background: '#fff',
        gap: '10px',
        width: '100%',
        marginTop: '10px',
    },
    '& .mybtn': {
        color: 'white',
        backgroundColor: '#2D6EED',
        textTransform: 'capitalize',
        padding: '8px 16px',
        marginTop: '11px',
    },
    '& .Box': {
        height: '200px',
        borderRadius: '10px',
        backgroundColor: '#fff',
        flexDirection: 'column',
        display: 'flex',
        paddingLeft: '30px',
        justifyContent: 'space-evenly',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        alignItems: 'flex-start',
        marginTop: '25px',
    },
    '& .lable': {
        padding: '5px 15px',
        borderRadius: '20px',
        backgroundColor: 'rgba(252, 132, 52, 0.1)',
        color: '#EBA370',
        width: 'fit-content',
        fontWeight: 700,
        marginTop: '5px',
    },
    '& .searchButtons': {
        border: '1px solid #f0f0f0',
        borderRadius: '5px',
        background: 'white',
        marginLeft:"20px",
        color:"black",
        height:"47px"
    },
    '& .main': {
        padding: '15px',
        height: '85%',
        backgroundColor: '#fff',
    },
    '& .heading': {
        justifyContent: 'space-between',
        display: 'flex',
    },
    '& .detail': {
        marginTop: '35px',
        backgroundColor: 'rgba(221, 221, 221, 0.38)',
        color: '#191E26',
        textAlign: 'center',
        padding: '10px 50px',
        borderRadius: '8px',
    },
    '& .userData': {
        flexDirection: 'column',
        justifyContent: 'space-between',
        display: 'flex',
        height: '83%',
    },
    '& .tblData': {
        boxShadow: 'none',
        marginTop: '20px',
    },
    '& .tblpage': {
        justifyContent: 'space-between',
        display: 'flex',
    },
    '& .selectTag': {
        background: '#2B6FED',
        padding: '10px 20px',
        marginTop: '35px',
        color: '#fff',
        borderRadius: '20px',
        cursor: 'pointer',
    },
    '& .unSelectTage': {
        background: '#EEF0F8',
        marginTop: '35px',
        color: '#8D8F98',
        padding: '10px 20px',
        borderRadius: '20px',
        cursor: 'pointer',
    },
    '& .downloadReports': {
        color: '#fff',
        padding: '10px 20px',
        background: '#FC8434',
        borderRadius: '8px',
        cursor: 'pointer',
    },
    '& .table-header': {
        background: "#fff", 
        padding: "20", 
        display: "flex", 
        justifyContent: "space-between", 
        alignItems: "center", 
        border: "1px solid rgba(224, 224, 224, 1)"
    },
    '& .pagination': {
        '& .MuiPaginationItem-page':{
        },
        '& .Mui-selected' : {
            background:'red'
        }
        
    },
    '& .sort-by': {
        display:"flex",
    },
    '& .filter': {
        fontSize: "14px",
        backgroundColor: "white",
        borderRadius: "8px",
        border: "1px solid #F0F0F0",
        padding: "8px 12px",
        fontWeight: 500,
        color: "darkgray",
        minWidth: "168px",
        maxWidth: "168px",
    },
});
export default withTranslation()(
    //@ts-ignore
    (withRouter(OwnersReports))
);
// Customizable Area End