// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  t:(value:string)=> string
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  generateContractModal: boolean,
  saveTemplateModal: boolean,
  activateContract: boolean
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface SS {
  id: string;
}
export default class LeaseManagementIssueNewContractReviewController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      generateContractModal: false,
      saveTemplateModal: false,
      activateContract: false
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount()
    // Customizable Area End
  }

  // Customizable Area Start
  handleOpenGenerateModal=()=>{
    this.setState({generateContractModal:true})
  }
  handleCloseGenerateModal=()=>{
    this.setState({generateContractModal:false})
  }
  handleOpenSaveTemplateModal=()=>{
    this.setState({saveTemplateModal:true})
  }
  handleCloseSaveTemplateModal=()=>{
    this.setState({saveTemplateModal:false})
  }
  handleOpenContractActivatedModal=()=>{
    this.setState({activateContract:true,generateContractModal:false})
  }
  handleCloseContractActivatedModal=()=>{
    this.setState({activateContract:false})
  }
  // Customizable Area End
}