//Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    Fade,
    Modal,
    Backdrop,
    TableHead,
    Divider,
    TableRow,
    TextField,
    IconButton,
} from "@material-ui/core";
import { CSSProperties } from '@material-ui/styles';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from "react-i18next";
import {Link} from 'react-router-dom'
import Loader from "../../../../components/src/Loader.web";
import ListIcon from '@material-ui/icons/List';
//@ts-ignore
import {ModalPopup} from '../../../../components/src/ModalPopup/index'
import { ModalWrapper } from "../../../../components/src/ModalWrapper";
import PaginationModule from "../../../StoreCredits/src/PaginationModule.web";
import CloseIcon from '@material-ui/icons/Close';
import RoomIcon from '@material-ui/icons/Room';
//@ts-ignore
import {TabsComponent} from '../../../../components/src/TabsComponent/index'
import RealEstateCompanyDetailsCardController, {
    Props,
} from './RealEstateCompanyDetailsCardController.web'
import { statusMapping } from "../../../../components/src/RealEstateFilter/index";
// @ts-ignore
import TickIcon from '../../assets/tick-circle-icon.png'
// @ts-ignore
import PdfImg from '../../assets/image_Bitmap.png'
// @ts-ignore
import DownloadIcon from '../../assets/image_Bitmap.svg'
//@ts-ignore
import {RealEstateFilter} from '../../../../components/src/RealEstateFilter/index'
//@ts-ignore
import {MapComponent} from '../../../../components/src/MapComponent/index'
// @ts-ignore
import {baseURL} from "../../../../framework/src/config";
//@ts-ignore
import {complexIcon, buildingIcon} from './assets'

const registerOptions = [
    {
        icon: complexIcon,
        title:'Complex',
        link: '/CompanyEmployee/ComplexMainBlock'
    },
    {
        icon:buildingIcon,
        title:'Individual Building',
        link:'/CompanyEmployee/BuildingMainBlock'
    }
]

class RealEstateCompanyDetailsCard extends RealEstateCompanyDetailsCardController {
    constructor(props: Props) {
        super(props);
    }

    cooCompanyDetails(t:any) {
        return <>
        {
                                    this.state.accountType === "coo" ? <React.Fragment data-testid="cooInfo">
                                    <Box style={webStyle.detailBox}>
                                    <Typography style={webStyle.detailLabel}>{t("Sales Manager")}:</Typography>
                                    <Typography style={webStyle.detailValue} className="bold-text">{this.state.companyDetails?.attributes?.sales_manager ? this.state.companyDetails.attributes.sales_manager : 'N/A'}</Typography>
                                </Box>
                                <Box style={webStyle.detailBox}>
                                    <Typography style={webStyle.detailLabel}>{t("Account Manager")}:</Typography>
                                    <Typography style={webStyle.detailValue} className="bold-text">{this.state.companyDetails?.attributes?.account_manager ? this.state.companyDetails.attributes.account_manager : 'N/A'}</Typography>
                                </Box>
                                    </React.Fragment> : <></>
                                }
        </>
    }

    render() {
        //@ts-ignore
        const {t} = this.props

        return (
            <>
                <Box maxWidth='xl' style={webStyle.detailsBox} >
                <Box>
                <Typography style={{ fontSize: '18px' }}><Link to="/CompanyEmployee/realestate-companies">{t("Real Estate Companies")} </Link>/ 
                <span style={webStyle.linkColor}> {t("Company Details")}</span></Typography>
                </Box>
                <Box style={webStyle.titleBox}>
                    <Typography style={webStyle.companyTitle} className="bold-text">{this.state.companyDetails?.attributes?.company_name}</Typography>
                    <Box>
                        <Button variant="outlined" style={webStyle.deleteBtn} className="bold-text"  onClick={() => this.handleDeleteModal(true)}><span className="bold-text" data-testid="delete_btn">
                            {t("DELETE COMPANY")}</span></Button>
                        <Button variant="contained" style={webStyle.editBtn} className="bold-text" color="primary" data-testid="edit_btn" onClick={this.handleEditClick}>
                            <span className="bold-text">
                                {t("EDIT DETAILS")}
                            </span></Button>
                    </Box>
                </Box>
                <Box style={webStyle.detailsContainer}>
                    <Box style={webStyle.companyDetailsHeader}>
                    <Box style={webStyle.companyText}>
                        {
                            this.state.companyDetails?.attributes?.logo?.url ? <img style={webStyle.logoHolder} src={`
                            ${baseURL}${this.state.companyDetails?.attributes?.logo?.url}`} /> : 
                            <Box style={webStyle.logoHolder}></Box>
                        }
                        <Box style={webStyle.companyInfo}>
                            <Box style={webStyle.companyLocation}>
                                <Typography variant="h4" style={webStyle.companyInfoTitle} className="bold-text">{this.state.companyDetails?.attributes?.company_name}</Typography>
                                <a style={webStyle.locationTag} href={this.state.companyDetails?.attributes?.google_map_location} target="_blank">
                                <LocationOnOutlinedIcon style={webStyle.locationIcon} />
                                <Typography style={webStyle.locationLabel} className="bold-text">
                                    {t("See Company on map")}</Typography>
                                </a>
                            </Box>
                            <Typography variant="body1" style={webStyle.companyInfoLocation}>{this.state.companyDetails?.attributes?.city}</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <span className="bold-text" style={{
                            ...webStyle.companyTag,
                            ...webStyle[this.state.companyDetails?.attributes?.status],
                        }}>
                            {statusMapping[this.state.companyDetails?.attributes?.status]}
                        </span>
                    </Box>
                    </Box>
                    <Box>
                    <Box style={webStyle.detailBox}>
                                    <Typography style={webStyle.detailLabel}>{t("Company ID")}:</Typography>
                                    <Typography style={webStyle.detailValue} className="bold-text">#{this.state.companyDetails?.id}</Typography>
                                </Box>
                                <Box style={webStyle.detailBox}>
                                    <Typography style={webStyle.detailLabel}>{t("Point of Contact")} ({t("Primary")}):</Typography>
                                    <Typography style={webStyle.detailValue} className="bold-text">{this.state.companyDetails?.attributes?.contact_name}
                                     | {this.state.companyDetails?.attributes?.phone_number} | 
                                     {this.state.companyDetails?.attributes?.email_address}</Typography>
                                </Box>
                                <Box style={webStyle.detailBox}>
                                    <Typography style={webStyle.detailLabel}>{t("Point of Contact")} ({t("Secondary")}):</Typography>
                                    <Typography style={webStyle.detailValue} className="bold-text">{this.state.companyDetails?.attributes?.point_of_contacts_secondary?.contact_name}
                                     | {this.state.companyDetails?.attributes?.point_of_contacts_secondary?.phone_number} | 
                                     {this.state.companyDetails?.attributes?.point_of_contacts_secondary?.email_address}</Typography>
                                </Box>
                                {this.cooCompanyDetails(t)}
                                <Box style={webStyle.detailBox}>
                                    <Typography style={webStyle.detailLabel}>{t("Managed By")}: </Typography>
                                    <Typography style={webStyle.detailValue} className="bold-text">{this.state.companyDetails?.attributes?.managed_by}</Typography>
                                </Box>
                                <Box style={webStyle.detailBox}>
                                    <Typography style={webStyle.detailLabel}>{t("Country")}: </Typography>
                                    <Typography style={webStyle.detailValue} className="bold-text">{this.state.companyDetails?.attributes?.country}</Typography>
                                </Box>
                                <Box style={webStyle.detailBox}>
                                    <Typography style={webStyle.detailLabel}>{t("Region")}: </Typography>
                                    <Typography style={webStyle.detailValue} className="bold-text">{this.state.companyDetails?.attributes?.region}</Typography>
                                </Box>
                                <Box style={webStyle.detailBox}>
                                    <Typography style={webStyle.detailLabel}>{t("City")}: </Typography>
                                    <Typography style={webStyle.detailValue} className="bold-text">{this.state.companyDetails?.attributes?.city}</Typography>
                                </Box>
                                <Box style={webStyle.detailBox}>
                                    <Typography style={webStyle.detailLabel}>{t("Zipcode")}: </Typography>
                                    <Typography style={webStyle.detailValue} className="bold-text">{this.state.companyDetails?.attributes?.zipcode}</Typography>
                                </Box>
                                <Box style={webStyle.detailBox}>
                                    <Typography style={webStyle.detailLabel}>{t("Commercial Registration Number")}: </Typography>
                                    <Typography style={webStyle.detailValue} className="bold-text">{this.state.companyDetails?.attributes?.commercial_registration_number}</Typography>
                                </Box>
                                <Box style={webStyle.detailBox}>
                                    <Typography style={webStyle.detailLabel}>{t("HQ Location Address")}: </Typography>
                                    <Typography style={webStyle.detailValue} className="bold-text">{this.state.companyDetails?.attributes?.hq_location_address}</Typography>
                                </Box>
                                {
                                    this.state.proofFileName && <Box style={webStyle.proofDetailBox}>
                                        <Typography style={webStyle.detailLabel}>{t("Registration Certificate(Government Proof)")}: </Typography>
                                        <Box style={webStyle.proofBox}>
                                        <Box style={webStyle.pdfBox}>
                                            <img src={PdfImg} style={webStyle.pdfImg} />
                                            <Typography style={webStyle.detailValue}>{this.state.proofFileName}</Typography>
                                        </Box>
                                        <Box>
                                            <a href={`${baseURL}${this.state.companyDetails?.attributes?.registration_certificate?.url}`} download>
                                                <img src={DownloadIcon} data-testid="documentTag"/></a>
                                        </Box>
                                    </Box>
                                    </Box>
                                }
                    </Box>
                </Box>
                <Box style={webStyle.tabsBox}>
                    <Box>
                        <TabsComponent
                            tabNo={this.state.tabNo}
                            handleChange={this.handleTabChange}
                            tabs={
                                [
                                    {
                                        label:t('Complex')
                                    },
                                    {
                                        label: t('Individual Buildings'),
                                        testId: 'tabcheck'
                                    }
                                ]
                            }
                        />
                    </Box>
                    <Button style={webStyle.registerBtn} onClick={this.handleOpenRegisterModal}><span className="bold-text">
                        {t("Register New Complex / Building")}</span></Button>
                </Box>
                <Box style={webStyle.selectBoxes}>
                <Box style={webStyle.selectBoxLeft}>
                <Box className={"top-bar-filter ChairmanInvoices"} id="ChairmanInvoices">
                    {
                        this.state.accountType === "coo" ? <React.Fragment data-testid="check_coo"><RealEstateFilter t={t} classComponent={this} filterList="coo_complex_filter" /></React.Fragment>:<RealEstateFilter  classComponent={this} t={t} filterList="other_real_estate_filter" />
                    }
                    <Box className="filter-filter">

                    <Button className="handleSearchFilterForInvoices search-btn-estate" onClick={this.handleFilterSubmit} startIcon={<SearchIcon style={{marginLeft:"10px"}} />}>{t("Search")}</Button>
                    </Box>
                    </Box>
                </Box>
            </Box>
            <Box style={webStyle.tableContainer}>
                <Box style={webStyle.tableHeader}>
                    <h2 style={webStyle.tableHeading} className="bold-text">
                        {
                            this.state.tabNo === 0 ? <>{t('List of Managed Complexes')}</> : <>
                            {t('List of Individual Buildings')}</>
                        }
                    <Box style={webStyle.switchIcons}>
                        <Box className={this.state.mapSwitchEstate ? 
                        'estate_map_switch' : "active_switch estate_map_switch"}
                        style={webStyle.listIcon}
                        onClick={(e) => this.handleMapSwitchEstate(e, false)}
                        data-testid="map_click_false"
                        ><ListIcon className={ this.state.mapSwitchEstate ? "estate_map_switch_icon" : "estate_map_switch_icon active_switch_icon"} /></Box>
                        <Box 
                        className={this.state.mapSwitchEstate ? 
                            "active_switch estate_map_switch_room" : "estate_map_switch_room"}
                        style={webStyle.roomIcon}
                        data-testid="map_click"
                        onClick={(e) => this.handleMapSwitchEstate(e, true)}
                        ><RoomIcon className={ this.state.mapSwitchEstate ? "active_switch_icon estate_map_switch_icons":"estate_map_switch_icons"} /></Box>
                    </Box>
                    </h2>
                    <TextField
                        label="Search"
                        variant="outlined"
                        className="table_detail_search estate_detail_search_new"
                        style={webStyle.searchInput}
                        placeholder={`${t("Search by complex name")}`}
                        InputProps={{
                            startAdornment: (
                                <IconButton 
                                >
                                    <SearchIcon />
                                </IconButton>
                            ),
                            style: webStyle.innerInput
                        }}
                    />
                </Box>
                {
                    this.state.mapSwitchEstate ? <Box style={webStyle.mapBox} className="estate_map_new_swicth">
                        <MapComponent defaultCenter={{
                        lat: 10.99835602,
                        lng: 77.01502627
                    }} 
                    defaultZoom={11}
                    mapPoints={
                        [
                            {
                                lat: 10.99,
                                lng: 77.0150,
                                society: 'New Moon',
                                societyId: 50
                            }
                        ]
                    }
                    />
                    </Box> : <>
                    <Table className="table-box">
                      <TableHead>
                        <TableRow>
                          <TableCell><b>{t("Complex Name")}</b></TableCell>
                          <TableCell><b>{t("Total Buildings")}</b></TableCell>
                          <TableCell><b>{t("Total Units")}</b></TableCell>
                          <TableCell><b>{t("Subscription Ends in")}</b></TableCell>
                          <TableCell><b>{t("Paying Month")}</b></TableCell>
                          <TableCell><b>{t("Subscription Type")}</b></TableCell>
                          <TableCell><b>{t("Joining Date")}</b></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      <TableRow>
                              <TableCell>Ainara Vergara</TableCell>
                              <TableCell>454</TableCell>
                              <TableCell>454</TableCell>
                              <TableCell>5 days</TableCell>
                              <TableCell>12 Months</TableCell>
                              <TableCell>Platinum</TableCell>
                              <TableCell>12 Aug 2022</TableCell>
                              <TableCell><MoreVertIcon style={webStyle.moreIcon} /></TableCell>
                            </TableRow>
                      </TableBody>
                    </Table>
                    <Divider />
                    <Box style={webStyle.tableFooter} className="paginate_details">
                    <PaginationModule pagination={this.state.pagination} handlePagination={this.handleVistorPaginationEstate} page={this.state.page} />
                    </Box>                                               
                    </>
                }
            </Box>
                </Box>
                <ModalPopup
                    title={t("Delete Company?")}
                    description={
                        this.state.accountType === "coo" ? t("Are you sure you want to delete the company from the platform?") :
                        t("Approval request has been sent to the COO once the request get approved. Company will be deleted")
                    }
                    open={this.state.deleteConfirmModal}
                    closeFn={this.handleCloseDeleteModal}
                    primaryAction={this.handleOpenReasonPopup}
                    secondaryAction={this.handleCloseDeleteModal}
                    primaryLabel={t("Confirm")}
                    secondaryLabel={t("Close")}
                    icon={TickIcon}
                />
                <ModalWrapper open={this.state.reasonConfirmModal} closeFn={this.handleCloseReasonPopup}>
                <Box style={{width:"600px",marginTop:'15px',backgroundColor:"white",padding:'0px 00px 20px 0px',borderRadius:"20px"}}>
            <Box style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                <Box style={webStyle.modalHeading}>
                <Typography className="bold-text" variant="h6" style={{color:"black",fontWeight:"bold",marginTop:"15px",marginBottom:"10px",textAlign:"center",fontSize:"20px"}}>
                    {t("Reasons for deletion")}
                </Typography>
                <Box>
                <IconButton onClick={this.handleCloseReasonPopup}>
                          <CloseIcon />
                    </IconButton>
                </Box>
                </Box>
                <Box style={webStyle.modalInputs}>
                    <TextField
                        label="Please provide detailed justification why are you deleting the company"
                        variant="outlined"
                        multiline
                        minRows={6}
                        className="delete_reason_field"
                        data-testid="reasonText"
                        style={webStyle.reasonField}
                        value={this.state.reasonText}
                        onChange={this.handleReasonText}
                    />
                    {
                        this.state.reasonFlag && <p style={webStyle.errorMsg}>*{t("Please enter valid reason")}</p>
                    }
                </Box>
                <Box style={webStyle.modalButtons}>
                <Button variant="outlined" color="primary"
                        data-testid="secondary_btn"
                        style={webStyle.closeDeleteBtn} 
                        onClick={this.handleCloseReasonPopup}
                        ><span className="bold-text">{t('CANCEL')}</span></Button>
                    <Button variant="contained" color="primary"
                    data-testid="primary_btn"
                    style={webStyle.confirmDeleteBtn} 
                    onClick={this.handleDelete}  
                    ><span className="bold-text">{t("Submit")}</span></Button>
                </Box>
            </Box>
        </Box>
                </ModalWrapper>

                <Modal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    className="modalStyle real_estate_create_complex"
    // @ts-ignore
    open={this.state.registerModal}
    onClose={this.handleCloseRegisterModal}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{timeout: 500}}
>
    {/*@ts-ignore*/}
    <Fade in={this.state.registerModal}>
        <Box style={{width:"25%",marginTop:'15px',backgroundColor:"white",padding:'20px',borderRadius:"20px"}} className="real_estate_create_complex">
            <Box className="real_estate_create_complex" style={{display:"flex",flexDirection:"column",alignItems:"center",position: 'relative',
justifyContent: 'center'}}>
                <Typography className="bold-text real_estate_create_complex" style={webStyle.modalRegisterHeading}>Register New</Typography>
                <CloseIcon className="real_estate_create_complex" style={webStyle.closeIcon} data-testid="close_register" onClick={this.handleCloseRegisterModal} />
            </Box>
            <Box className="real_estate_create_complex" style={webStyle.buildingOptions}>
                {
                    registerOptions.map((icon, i) => {
                        return <Box className="real_estate_create_complex" style={webStyle.buildingOption} key={i}>
                        <Link className="real_estate_create_complex" to={`${icon.link}/${this.props.companyId}`} style={webStyle.buildingLink}>
                        <Box className="real_estate_create_complex" style={webStyle.buildingCircle}>
                            <img src={icon.icon} style={webStyle.iconSize} />
                        </Box>
                        <Typography className="real_estate_create_complex">{icon.title}</Typography>
                        </Link>
                    </Box>
                    })
                }
            </Box>
        </Box>
    </Fade>
</Modal>

                <Loader loading={this.state.loading} />
            </>
        );
    }
}

const webStyle:any = {
    searchIcon:{
        fontSize: '14px'
    },
    switchIcons:{
        display:'flex',
        marginLeft: '30px',
        cursor:'pointer'
    },
    buildingOption:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        width:'150px'
    },
    buildingLink:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        width:'150px'
    },
    listIcon:{
        color:'#dcdcdc',
        border: '1px solid #dcdcdc',
        borderRadius: '4px 0px 0px 4px',
        padding: '4px',
        fontSize: '18px'
    },
    buildingOptions:{
        display:'flex',
        marginTop: '30px',
        justifyContent:'space-evenly'
    },
    buildingCircle:{
        border:'1px solid #ececec',
        borderRadius:'50%',
        height:'80px',
        width:'80px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        marginBottom:'15px'
    },
    roomIcon:{
        color:'#dcdcdc',
        border: '1px solid #dcdcdc',
        borderRadius: '0px 4px 4px 0px',
        padding:'4px',
        borderLeft:'0px',
        fontSize:'18px'
    },
    whiteLink:{
        color:'#fff'
    },
    modalRegisterHeading:{
        fontSize:'20px'
    },
    mapBox:{
        width:'100%',
        height: '400px',
        overflow:'hidden'
    },
    approved: {
        background:'rgb(30,198,91,0.2)',
        color:'rgb(30 198 91)'
    },
    closeIcon:{
        position:'absolute',
        right: '0px',
        cursor:'pointer'
    },
    rejected: {
        background: 'rgb(242,23,23, 0.2)',
        color: '#F21717',
    },
    pending_approval:{
        background: 'rgb(252, 132, 52, 0.2)',
        color: 'rgb(252, 132, 52)',
    },
    closeDeleteBtn:{
        borderColor: 'rgb(43, 111, 237) none repeat scroll 0% 0%',
        color: 'rgb(43, 111, 237)',
        width:'180px',
        height: '44px',
    borderRadius: '8px',
    fontWeight: '700' as CSSProperties['fontWeight']
    }, 
    confirmDeleteBtn:{
        background: 'rgb(43, 111, 237)',
        borderColor:'rgb(43, 111, 237)',
        color:'#fff',
        width:'180px',
        height: '44px',
    borderRadius: '8px',
    marginLeft:'15px',
    fontWeight: '700' as CSSProperties['fontWeight'],
    boxShadow: '0px 6px 14px rgba(43,111,237,0.22)'
    },
    selectBoxLeft:{
        width: '100%',
        display:'flex',
        flexWrap:'wrap'
    },
    selectBoxRight:{
        width: '25%',
        display:'flex',
        justifyContent:'flex-end',
    },
    selectBoxes:{
        marginTop:'30px',
        marginBottom:'15px',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    tickIcon:{
        color: 'rgb(252, 132, 52) none repeat scroll 0% 0%',
        fontSize:'45px'
    },
    currentRecords:{
        color:'#FC8434'
    },
    modalInputs:{
        width:'calc(100% - 40px)',
        marginTop:'30px',
        marginBottom:'30px',
        paddingLeft:'20px',
        paddingRight:'20px',
        borderRadius:'8px',
    },
    modalHeading:{
        width: 'calc(100% - 40px)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems:'center',
        borderBottom: '1px solid #ececec',
        paddingLeft:'20px',
        paddingRight:'20px'
    },
    reasonField:{
        width:'100%'
    },
    modalButtons:{
        width: 'calc(100% - 40px)',
        paddingLeft: '20px',
        paddingRight: '20px',
        textAlign:'right'
    },
    searchBtn:{
        height:'35px',
        minHeight:'35px',
        marginTop:'5px',
        marginLeft:'15px',
        background:'#2B6FED',
        borderColor:'#2B6FED',
        fontSize:'14px',
        boxShadow:'unset'
    },
    errorMsg: {
        color: 'red',
        fontSize: '12px'
      },
    searchText:{
        marginLeft:'10px'
    },
    detailsBox:{
        padding:'30px'
    },
    tabsBox:{
        marginTop:'30px',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    proofBox:{
        border: '1px solid #E4E4E4',
        borderRadius: '4px',
        padding: '10px',
        marginTop:'10px',
        display:'flex',
        justifyContent: 'space-between',
        alignItems:'center'
    },
    pdfImg:{
        marginRight: '10px'
    },
    pdfBox:{
        display:'flex',
        alignItems:'center'
    },
    selectFormControl:{
        width:'15%'
    },
    selectBigFormControl:{
        width:'20%'
    },
    registerBtn:{
        background:'#FC8434',
        color:'#fff',
        height:'40px',
        paddingLeft:'20px',
        paddingRight:'20px',
        borderRadius:'8px'
    },
    searchInput:{
        border:'1px solid rgba(0,0,0,0.2)',
        borderRadius:'8px',
        height:'40px',
        padding:'0px',
        color:'#212121'
    },
    detailBox:{
        display:'flex',
        paddingBottom:'10px'
    },
    selectBigForm:{
        width:'20%',
        color:'#181d25',
        border:'1px solid #f0f0f0'
    },
    locationTag:{
        display:'flex'
    },
    moreIcon:{
        color:'rgba(136,136,136,0.6)'
    },
    linkColor:{
        color: 'rgb(43, 111, 237)'
      },
    tableHeader:{
        padding:'30px',
        borderBottom:'1px solid rgba(0,0,0,0.1)',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    tableContainer:{
        background:'#fff'
    },
    tableFooter:{
        padding:'30px',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    tableHeading:{
        fontSize:'22px',
        display:'flex',
        alignItems:'center'
    },
    selectForm:{
        width: '15%',
        minWidth:'135px',
        color:'#181d25',
        border:'1px solid #f0f0f0'
    },
    menuOption:{
        width:'100%',
        overflow:'hidden',
        textOverflow: 'ellipsis', 
        whiteSpace: 'nowrap'
    },
    innerInput:{
        padding:'0px',
        color:'#212121'
    },
    detailLabel:{
        fontSize:'15px',
        fontWeight:'400' as CSSProperties['fontWeight']
    },
    detailValue:{
        fontSize:'15px',
        fontWeight:'400' as CSSProperties['fontWeight'],
        marginLeft:'5px',
        textTransform:'capitalize'
    },
    titleBox:{
        marginTop:'15px',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    deleteBtn:{
        borderColor:'red',
        color:'red',
        marginRight:'15px',
        fontWeight: 700,
        height:'44px',
        width:'184px',
        borderRadius:'8px'
    },
    editBtn:{
        background:'#2B6FED',
        borderColor:'#2B6FED',
        fontWeight: 700,
        height:'44px',
        width:'184px',
        borderRadius:'8px',
        boxShadow: '0px 6px 14px rgba(43,111,237,0.22)'
    },
    companyTitle:{
        fontSize:'32px',
        textTransform: 'capitalize'
    },
    companyText:{
        display:'flex',
        alignItems:'center'
    },
    companyInfo:{
        marginLeft:'30px'
    },
    companyInfoTitle:{
        fontSize:'22px',
        fontWeight:'400' as CSSProperties['fontWeight'],
        textTransform: 'capitalize'
    },
    companyInfoLocation:{
        fontSize:'16px',
        fontWeight:'400' as CSSProperties['fontWeight']
    },
    companyLocation:{
        display:'flex',
        alignItems:'center'
    },
    companyTag:{
        color:'#FC8434',
        background:'rgb(252, 132, 52, 0.1)',
        padding:'5px 15px',
        borderRadius:'12px',
        marginTop:'15px'
    },
    locationIcon:{
        color:'#fc8434',
        height:'22px',
        marginLeft:'10px'
    },
    locationLabel:{
        color:'#FC8434',
        fontSize:'16px',
        fontWeight:'400' as CSSProperties['fontWeight'],
        marginLeft:'10px'
    },
    detailsContainer:{
        background:'#fff',
        padding:'15px',
        marginTop:'30px',
        borderRadius:'8px',
    },
    companyDetailsHeader:{
        display:'flex',
        justifyContent:'space-between',
        marginBottom:'30px'
    },
    logoHolder:{
        background:'#dcdcdc',
        width:'70px',
        height:'70px',
        borderRadius:'50%',
        objectFit:'cover'
    }
};

export default withTranslation()(RealEstateCompanyDetailsCard)
//Customizable Area End