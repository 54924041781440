import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { Message } from "../../../../framework/src/Message";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";
import { ApiErrorResponse } from "../../../../components/src/APIErrorResponse";
import { ApiCatchErrorResponse } from "../../../../components/src/APIErrorResponse";
import { apiCallWithToken as apiCall } from "../../../../components/src/APICallComponent/index.web";


export interface Props {
    stepComplex: number,
    handleNext:any,
    handleBack:any,
    coreMemberManager:any,
    sharedCoreMembers:any,
    coreMembers:any,
    sharedCreateNewMember:any,
    handleAddMember:  any,
    handleSave:any,
    createNewMember:any,
    userList:any,
    roleList:any,
    userValues:any,
    memberValues:any,
    handleUserSelect:any,
    handleMemberDetails:any,
    savedMembers:any,
    handleRemoveMember:any,
    deleteMemberPopup:any,
    handleCloseDeleteMemberPopup:any,
    handleOpenDeleteMemberPopup:any,
    deleteMemberIndex:any,
    t?: any
    i18n?: any
    match?: any
    location?: any
    // Customizable Area Start
    history?: any
    buildingListData:any;
    handleBasicDetailsData:any;
    language?:any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CompEmpComplexBlockStep7Controller extends CommonApiCallForBlockComponent<Props, S, SS> {
    exampleAPICallId: string = "";

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

        this.state = {
            // Customizable Area Start
            loading: false,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    // Customizable Area End
}
