import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";

export interface Props {
    jurisdictionList: any,
    jurisdictionSelected: any,
    handleChangeBuilding: any,
    stepBuilding: number,
    handleNext:any,
    handleBack:any,
    multipleButton:any,
    t?: any
    i18n?: any
    match?: any
    location?: any
    // Customizable Area Start
    history?: any
    language?: string
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CompEmpComplexBlockStep1Controller extends CommonApiCallForBlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
        this.state = {
        };
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
}
