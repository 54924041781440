Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.httpPost = "POST"
exports.httpPut = "PUT"
exports.httpDelete = "DELETE"
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.countryApiEndpoint = "bx_block_profile/profiles/country_list/"
exports.compnyApiEndpoint = "bx_block_my_team/company_employee_nominations/compney_list"
exports.cityApiEndpoint = "bx_block_my_team/company_employee_nominations/city_list/"
exports.complexApiEndpoin = "bx_block_my_team/company_employee_nominations/complex_list/"
exports.buildingApiEndpoin = "bx_block_profile/profiles/building_list"
exports.unitApiEndPoint = "bx_block_profile/profiles/apartment_list/"
exports.userProfileApiEndPoint = "bx_block_profile/profiles/company_community_member_list"
exports.addUserProfileApiEndPoint = "bx_block_request_management/create_request"
exports.familyApiEndPoint = "bx_block_family/families"
exports.relationsApiEndPoint="bx_block_family/relations"
exports.idProofApiEndPoint = "bx_block_family/id_proofs"
exports.editFamilyEndPoint = "bx_block_family/families/"
exports.deleteFamilyApiEndPoint = "bx_block_family/families/"
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.checkLogin = "/bx_block_rent_payment/rent_payments/month_list";
exports.addTenantApiEndPoint = "society_managements/1/bx_block_contract/employee_contracts/find_person/"
exports.findPersonApiEndPoit = "society_managements/1/bx_block_contract/employee_contracts/find_person_details/"
exports.addAnotherUnitEndPoint = "bx_block_request_management/create_request"
exports.suspendUserApiEndPoint = "bx_block_profile/profiles/company_suspend_user/"
// Customizable Area End