//Customizable Area Start
import React from "react";
import {
    Container,
    Box,
    Grid,
    Hidden,
} from "@material-ui/core";
import ComplexDetailsController, {
    Props,
} from './ComplexDetailsController.web'
import MainScreen from "../../../dashboard/src/Company Employee/MainScreen.web";
import {withRouter} from 'react-router-dom'
//@ts-ignore
import ComplexListingTable from './ComplexListingTable.web'
import ComplexDetailsPage from './ComplexDetailsPage.web'

class ComplexDetails extends ComplexDetailsController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { navigation } = this.props;
        const match:any= this.props.match;
        const id = match?.params?.id;

        return (
            <>
            <MainScreen>
                <ComplexDetailsPage companyId={id} navigation={navigation} id={"complex_listings"} />
            </MainScreen>
            </>
        );
    }
}

const webStyle = {
};

export default withRouter(ComplexDetails)
//Customizable Area End