import { IBlock } from "../../../../framework/src/IBlock";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import CommonApiCallForBlockComponent from "../../../../components/src/ApiCallCommon.web";


export interface Props {
    step:any,
    selectedFilePdf:any,
    handleRemoveFile:any,
    handleFileChanges:any,
    selectedFilePdfGuidelines:any,
    handleRemoveFileGuidelines:any,
    uploadGuidelines:any,
    handleFileChangesGuidelines:any,
    multipleButton:any,
    selectedFilePdfRoles:any,
    handleRemoveFileRoles:any,
    handleFileChangesRoles:any,
    selectedFilePdfResolutions:any,
    handleRemoveFileResolutions:any,
    handleFileChangesResolutions:any,
    selectedFilePdfBuildingPlans:any,
    handleRemoveFileBuildingPlans:any,
    handleFileChangesBuildingPlans:any,
    handleBackDoc:any;
    handleNextDoc:any;

    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class CompEmpComplexBlockStep3Controller extends CommonApiCallForBlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);

        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIRequestMessage)];

        this.state = {
            // Customizable Area Start
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    // Customizable Area End
}
