// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  t:(value:string)=> string
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  hasSuccessError: HasSuccessErrorType;

  // Customizable Area End
}

// Customizable Area Start
export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}
export interface TemplateSuccessResponse {
  message: string
}
export interface ApiFailureResponse {
  errors: Array<ApiFailureResponseError>
}

export interface ApiFailureResponseError{
  token:string
}
interface HasSuccessErrorType {
  isOpen: boolean,
  isSeverity: "success" | "info" | "warning" | "error",
  isMessage: string,
}
// Customizable Area End

interface SS {
  id: string;
}
export default class LeaseManagementIssueNewContractTemplateController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  templateListingApiCallId: string = "";
  searchTemplatesApiCallId: string = "";
  deleteTemplateApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      hasSuccessError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      // Customizable Area End
    };
    // Customizable Area Start

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount()
    this.getTemplatesListing()
    // Customizable Area End
  }

  // Customizable Area Start
  templateListingApiCall = async (data: APIPayloadType) => {
    let { method, endPoint, body, type = "", contentType } = data;
    let token = localStorage.getItem("loginSuccessToken")
    
    let leaseManagmentMainrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    leaseManagmentMainrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    const header = {
      token: token,
      "Content-Type": contentType,
    };
    leaseManagmentMainrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    leaseManagmentMainrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    leaseManagmentMainrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    
    body && type !== "formData"
      ? leaseManagmentMainrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : leaseManagmentMainrequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(leaseManagmentMainrequestMessage.id, leaseManagmentMainrequestMessage);
    return leaseManagmentMainrequestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.templateListingResponseSucessCell(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.leaseManagmentMainResponseFailureCall(apiRequestCallId, responseJson);
      }
    }
  }

  templateListingResponseSucessCell = async (
    apiRequestCallId: string,
    responseJson: TemplateSuccessResponse) => {
    if (apiRequestCallId === this.templateListingApiCallId) {
      this.templatesListingSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.searchTemplatesApiCallId) {
      this.searchTemplatesSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteTemplateApiCallId) {
      this.deleteTemplateSucessCallBack(responseJson);
    }

  };

  leaseManagmentMainResponseFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureResponse) => {
    if (apiRequestCallId === this.templateListingApiCallId) {
      this.templatesListingFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.searchTemplatesApiCallId) {
      this.searchTemplatesFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteTemplateApiCallId) {
      this.deleteTemplatFailureCallBack(responseJson);
    }
  };
  getTemplatesListing = async () => {
    this.templateListingApiCallId = await this.templateListingApiCall({
      method: configJSON.templateListingMethod,
      endPoint: configJSON.templateListingEndPoint,
    });
  };

  templatesListingSucessCallBack = (response: TemplateSuccessResponse) => {

  };

  templatesListingFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: response.errors[0].token
      },
    })
  };
  searchTemplatesListing = async () => {
    this.searchTemplatesApiCallId = await this.templateListingApiCall({
      method: configJSON.searchTemplateListingMethod,
      endPoint: configJSON.searchTemplateListingEndPoint,
    });
  };

  searchTemplatesSucessCallBack = (response: TemplateSuccessResponse) => {

  };

  searchTemplatesFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: response.errors[0].token
      },
    })
  };
  deleteTemplate = async () => {
    this.deleteTemplateApiCallId = await this.templateListingApiCall({
      method: configJSON.deleteTemplateMethod,
      endPoint: configJSON.deleteTemplateEndPoint,
    });
  };

  deleteTemplateSucessCallBack = (response: TemplateSuccessResponse) => {

  };

  deleteTemplatFailureCallBack = (response: ApiFailureResponse) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: response.errors[0].token
      },
    })
  };
  handleCloseSnack = () => {
    this.setState({
        hasSuccessError: {
            isOpen: false,
            isSeverity: "success",
            isMessage: ""
        }
    })
};
  // Customizable Area End
}